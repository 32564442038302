import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'
import {
  useOverlayVisibilityContext,
  useOverlayActionsContext,
} from '../../SettingsOverlayContext'
import IconList from './IconList'
import IconPickerContentUi from './ui/IconPickerContentUi'
import IconPickerTitleUi from './ui/IconPickerTitleUi'
import IconPopoverUi from './ui/IconPopoverUi'
import IconSearchInputUi from './ui/IconSearchInputUi'
import IconUi from './ui/IconUi'
import InputAddonSpanUi from './ui/InputAddonSpanUi'
import InputBeforeAddonUi from './ui/InputBeforeAddonUi'

type IconPickerProps = {
  update: (option: string) => void
  value?: string
  iconList?: string[]
  labelText: string
  searchPlaceholder: string
}

function IconPicker({
  update,
  value,
  iconList,
  labelText,
  searchPlaceholder,
}: IconPickerProps) {
  const [showPicker, setShowPicker] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const overlayVisible = useOverlayVisibilityContext()
  const { toggle } = useOverlayActionsContext()
  const { t } = useTranslation()

  function handleChangeIcon(className: string): void {
    toggle(false)
    update(className)
    setShowPicker(false)
  }

  function filterIcons(e: React.ChangeEvent<HTMLInputElement>): void {
    setSearchValue(e.target.value)
  }

  function openPopover() {
    toggle(true)
    setShowPicker(true)
  }

  useEffect(() => {
    if (!overlayVisible && showPicker) {
      setShowPicker(false)
    }
  }, [overlayVisible, showPicker])

  return (
    <OptionWrapper labelText={labelText}>
      <InputBeforeAddonUi onClick={openPopover} value={value?.replace(/fa\w\s\w*-/, '')} readOnly />
      <InputAddonSpanUi>
        <IconUi onClick={openPopover} className={value} title={value?.replace(/fa\w\s\w*-/, '')} />
      </InputAddonSpanUi>
      {showPicker && (
        <IconPopoverUi>
          <IconPickerTitleUi>
            <IconSearchInputUi
              type="search"
              placeholder={t(searchPlaceholder)}
              onChange={filterIcons}
            />
          </IconPickerTitleUi>
          <IconPickerContentUi>
            <IconList
              popularIconsList={iconList}
              select={handleChangeIcon}
              searchValue={searchValue}
            />
          </IconPickerContentUi>
        </IconPopoverUi>
      )}
    </OptionWrapper>
  )
}
export default IconPicker
