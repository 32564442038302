import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import React, { useEffect, memo } from 'react'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { NotFound } from 'common/errors'
import { GatewayError } from 'common/errors/GatewayError'
import { NetworkError } from 'common/errors/NetworkError'
import { useActions } from 'common/hooks/useActions'
import * as paymentActions from 'client/actions/paymentActions'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import ActivePopup from './components/ActivePopup'
import PageUi from './ui/PageUi'

function PaymentPage({ children }) {
  const dispatch = useDispatch()
  const globalFontProperties = usePage(pageSelectors.getGlobalFontProperties)
  const fetchPaymentData = useActions(paymentActions.fetchPaymentData)

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await fetchPaymentData()
        dispatch(paymentActions.fetchPaymentDataSuccess(data))
      } catch (error) {
        if (error instanceof NotFound) {
          // do nothing
        } else if (error instanceof NetworkError) {
          // do nothing, we have stub data
        } else if (error instanceof GatewayError) {
          window.Rollbar.error(error)
        } else {
          // do nothing
        }
      }
    })()
  }, [])

  return (
    <PageUi
      fontFamily={wrapFontFamily(globalFontProperties.fontFamily)}
      fontWeight={globalFontProperties.fontWeight}
      fontStyle={globalFontProperties.fontStyle}
    >
      {children}
      <ActivePopup />
    </PageUi>
  )
}

PaymentPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(PaymentPage)
