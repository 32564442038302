import React from 'react'
import CommonWebinarRegistrationDate, {
  IntervalTypes,
} from 'common/components/entities/WebinarRegistrationDate/WebinarRegistrationDate'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import WebinarRegistrationDateInterface from 'common/types/entities/WebinarRegistrationDateInterface'
import { usePage, pageSelectors } from 'client/store/index'
import BaseEntityNew from '../../../components/core/BaseEntity/BaseEntityNew'
import { generateBaseEntity } from '../../../utils/createStructureUtils'

type WebinarRegistrationDateProps = {
  entity: WebinarRegistrationDateInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

function WebinarRegistrationDate({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: WebinarRegistrationDateProps) {
  const pageLocale = usePage(pageSelectors.getPageLocale)

  function changeTime() {
    return
  }

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonWebinarRegistrationDate
        attrId={entity.htmlAttrId}
        scheduledHours={entity.scheduledHours}
        timeFormat={entity.timeFormat}
        spaceBetween={entity.spaceBetween}
        interval={entity.interval}
        locale={pageLocale}
        changeTime={changeTime}
      />
    </BaseEntityNew>
  )
}

export function createWebinarRegistrationDate(
  parentId: string,
  masterBlockId?: string,
): WebinarRegistrationDateInterface {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.WebinarRegistrationDate,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.WebinarRegistrationDate,
    timeFormat: 24,
    spaceBetween: 20,
    scheduledHours: [],
    interval: IntervalTypes.hour,
  }
}

export default WebinarRegistrationDate
