import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SelectUi from 'common/components/core/Select/ui/Select'
import CommonField from 'common/components/entities/Field'
import FieldIconUi from 'common/components/entities/Field/FieldIconUi'
import FieldWrapperUi from 'common/components/entities/Field/FieldWrapperUi'
import PhoneNumberFieldUi from 'common/components/entities/Field/PhoneNumberFieldUi'
import { slugs } from 'common/constants/inputTypes'
import * as types from 'common/types'
import BaseEntity from 'client/components/core/BaseEntity'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import { getTypeNameBySlug } from 'client/reducers/inputTypesReducer'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'

function Field({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
  isRemoveAllowed,
  isSaveAllowed,
  isCopyAllowed,
  dropBoxAllowedEntityIds,
}) {
  const { t } = useTranslation()
  let inputTypeName = useSelector(state =>
    getTypeNameBySlug(state.inputTypes, entity.options.slug),
  )
  const globalFontFamily = usePage(pageSelectors.getGlobalFontFamily)

  if (
    entity.options.slug === slugs.PASSWORD ||
    entity.options.slug === slugs.CONFIRM_PASSWORD
  ) {
    inputTypeName = 'PASSWORD'
  }

  const fontFamily =
    globalFontFamily === 'inherit' ? 'initial' : globalFontFamily // otherwise it inherits Editor's UI Averta

  const iconColor = entity.styles.color ? entity.styles.color : 'initial'
  const mobileIconColor =
    entity.mobileStyles && entity.mobileStyles.color
      ? entity.mobileStyles.color
      : iconColor

  if (entity.options.slug === slugs.COUNTRY) {
    return (
      <BaseEntity
        entity={entity}
        isMoveUpAllowed={isMoveUpAllowed}
        isMoveDownAllowed={isMoveDownAllowed}
        isRemoveAllowed={isRemoveAllowed}
        isSaveAllowed={isSaveAllowed}
        isCopyAllowed={isCopyAllowed}
        dropBoxAllowedEntityIds={dropBoxAllowedEntityIds}
      >
        {emulatedEntity => (
          <SelectUi
            styles={{
              ...emulatedEntity.styles,
              width: '100%',
              pointerEvents: 'none',
            }}
          >
            <option>{t('entities.field.country.placeholder')}</option>
          </SelectUi>
        )}
      </BaseEntity>
    )
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
      isRemoveAllowed={isRemoveAllowed}
      isSaveAllowed={isSaveAllowed}
      isCopyAllowed={isCopyAllowed}
      dropBoxAllowedEntityIds={dropBoxAllowedEntityIds}
    >
      {emulatedEntity => (
        <EntityExampleWrapper
          label={inputTypeName || 'entities.field.warnings.no_input_name'}
          warning={Boolean(inputTypeName) === false}
          needTranslate={Boolean(inputTypeName) === false}
        >
          <FieldWrapperUi>
            {entity.options.iconClassName && (
              <FieldIconUi
                color={iconColor}
                fontSize={entity.styles.fontSize}
                mobileFontSize={
                  entity.mobileStyles && entity.mobileStyles.fontSize
                }
                mobileColor={mobileIconColor}
                className={entity.options.iconClassName}
              />
            )}
            {entity.options.slug === slugs.PHONE_NUMBER ? (
              <PhoneNumberFieldUi
                placeholder={emulatedEntity.options.placeholder}
                placeholderColor={emulatedEntity.options.placeholderColor}
                mobilePlaceHolderColor={
                  emulatedEntity.mobileOptions.placeholder
                }
                styles={{ fontFamily, ...emulatedEntity.styles }}
                borderType={entity.options.borderType}
                mobileBorderType={entity.mobileOptions.borderType}
                mobileStyles={emulatedEntity.mobileStyles}
                hasIcon={!!entity.options.iconClassName}
                inputProps={{ readOnly: true }}
                countrySelectorProps={{
                  buttonProps: { onClick: undefined },
                  buttonStyle: { cursor: 'default' },
                }}
              />
            ) : (
              <CommonField
                placeholder={emulatedEntity.options.placeholder}
                placeholderColor={emulatedEntity.options.placeholderColor}
                mobilePlaceHolderColor={
                  emulatedEntity.mobileOptions.placeholder
                }
                styles={{ fontFamily, ...emulatedEntity.styles }}
                borderType={entity.options.borderType}
                mobileBorderType={entity.mobileOptions.borderType}
                mobileStyles={emulatedEntity.mobileStyles}
                hasIcon={!!entity.options.iconClassName}
                readOnly
              />
            )}
          </FieldWrapperUi>
        </EntityExampleWrapper>
      )}
    </BaseEntity>
  )
}

Field.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
  isSaveAllowed: PropTypes.bool,
  isRemoveAllowed: PropTypes.bool,
  isCopyAllowed: PropTypes.bool,
  dropBoxAllowedEntityIds: PropTypes.arrayOf(PropTypes.string),
}

Field.defaultProps = {
  isRemoveAllowed: true,
  isSaveAllowed: true,
  isCopyAllowed: true,
}

export default memo(Field)
