import React, { useEffect, useRef, memo } from 'react'
import DeviceSwitcher from 'client/components/core/DeviceSwitcher'
import LanguageSwitcher from 'client/components/core/LanguageSwitcher'
import PopupManagement from 'client/components/core/Sidebar/components/PopupManagement'
import { SIDEBAR_CONTAINER_ID } from 'client/constants/editorIds'
import CustomFontsProvider from 'client/context/CustomFontsContext'
import IconsProvider from 'client/context/IconsContext'
import { useSidebarVisibilityActionsContext } from 'client/context/SidebarVisibilityContext'
import UserResourcesProvider from 'client/context/UserResourcesContext'
import useManagement from 'client/hooks/useManagement'
import usePresentPage, {
  selectors as pageSelectors,
} from 'client/hooks/usePresentPage'
import { selectors } from 'client/reducers/managementReducer'
import EntitySettings from './components/Settings/EntitySettings'
import PageSettings from './components/Settings/PageSettings'
import SettingsOverlayProvider from './components/Settings/SettingsOverlayContext'
import Toolbox from './components/Toolbox'
import SidebarContainerUi from './ui/SidebarContainerUi'
import SidebarFooterUi from './ui/SidebarFooterUi'
import SidebarTogglerUi from './ui/SidebarTogglerUi'
import SidebarUi from './ui/SidebarUi'

function Sidebar() {
  const { toggleSidebar } = useSidebarVisibilityActionsContext()
  const sidebarContainerRef = useRef(null)
  const editingId = useManagement(selectors.getEditingId)
  const isPopupActive = useManagement(selectors.isPopupActive)
  const isPopupManagement = useManagement(selectors.isPopupManagement)
  const isPageEditing = useManagement(selectors.isPageEditing)
  const entity = usePresentPage(p => pageSelectors.getEntityById(p, editingId))
  useEffect(() => {
    if (sidebarContainerRef.current) {
      sidebarContainerRef.current.scrollTop = 0
    }
  }, [editingId])

  let sidebar
  if (entity) {
    sidebar = (
      <SettingsOverlayProvider>
        <EntitySettings entity={entity} />
      </SettingsOverlayProvider>
    )
  } else if (isPageEditing) {
    sidebar = (
      <SettingsOverlayProvider>
        <PageSettings />
      </SettingsOverlayProvider>
    )
  } else if (isPopupManagement && !isPopupActive) {
    sidebar = <PopupManagement />
  } else {
    sidebar = <Toolbox />
  }

  return (
    <SidebarUi>
      <SidebarContainerUi ref={sidebarContainerRef} id={SIDEBAR_CONTAINER_ID}>
        <SidebarTogglerUi onClick={toggleSidebar} />
        <IconsProvider>
          <UserResourcesProvider>
            <CustomFontsProvider>{sidebar}</CustomFontsProvider>
          </UserResourcesProvider>
        </IconsProvider>
      </SidebarContainerUi>
      <SidebarFooterUi>
        <LanguageSwitcher />
        <DeviceSwitcher />
      </SidebarFooterUi>
    </SidebarUi>
  )
}

export default memo(Sidebar)
