import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Translation } from 'react-i18next'
import React, { PureComponent } from 'react'
import { buttonActionsTypes } from 'common/constants/settings'
import structureTypes from 'common/constants/structureTypes'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import NewCheckbox from 'client/components/core/Sidebar/components/Settings/components/NewCheckbox'
import { findDescendantsByType } from 'client/reducers/pageReducer'
import { DeviceModeContext } from '../../../../../../context/DeviceModeContext'
import { Range, Boolean, Checkbox } from '../components'
import RangeNew from '../components/Range/Range'
import { Attributes } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { PaddingStyle, ShadowStyle, BorderStyle, CornerStyle } from '../styles'

class PopupSettings extends PureComponent {
  static contextType = DeviceModeContext

  toggleMobileOpenAutomatically = value => {
    // merging strategy
    const options = {}
    if (!value) {
      options.openOnMobileAutomatically = '0'
    } else {
      options.openOnMobileAutomatically = '1'
    }
    this.props.updateOptions(options)
  }

  toggleOpenOnExit = value => {
    // merging strategy
    const options = {}
    if (value === '0') {
      options.openOnMobileAutomatically = false
      options.openOnExit = value
    } else {
      options.openOnExit = value
    }
    this.props.updateOptions(options)
  }

  toggleDelayOption = value => {
    const newEntity = { ...this.props.entity }
    if (value) {
      newEntity.options.closedDays = 1
    } else {
      delete newEntity.options.closedDays
    }

    this.props.update(newEntity)
  }

  render() {
    const {
      toggleOverlay,
      overlayVisible,
      entity,
      updateStyle,
      updateMobileStyle,
      updateOption,
      updateMobileOption,
    } = this.props

    return (
      <Translation>
        {t => (
          <React.Fragment>
            <Boolean
              update={updateOption('showClose')}
              labelText="show close button"
              value={entity.options.showClose}
            />
            {entity.options.showClose === '1' && (
              <ColorPicker
                label={t('entity_settings.popup.color_button_close')}
                update={updateStyle('color')}
                mobileUpdate={updateMobileStyle('color')}
                color={entity.styles.color}
                mobileColor={entity.mobileStyles.color}
              />
            )}
            {entity.options.showClose === '1' && (
              <NewCheckbox
                checked={!!entity.options.closedDays}
                update={this.toggleDelayOption}
                labelText="entity_settings.popup.delay.label"
                helpMessage="entity_settings.popup.delay.help_message"
              />
            )}
            {!!entity.options.closedDays && (
              <Range
                labelText={t('entity_settings.popup.reopen_in_x_days', {
                  days: entity.options.closedDays || 0,
                })}
                update={updateOption('closedDays')}
                value={entity.options.closedDays}
                min={1}
                max={365}
                allowNegative={false}
                needTranslate={false}
              />
            )}
            <Boolean
              value={entity.options.openAutomatically}
              update={updateOption('openAutomatically')}
              labelText="entity_settings.popup.open_automatically"
            />
            {entity.options.openAutomatically === '1' && (
              <Range
                labelText={t('entity_settings.popup.open_in_x_seconds', {
                  seconds: entity.options.delay,
                })}
                value={entity.options.delay}
                update={updateOption('delay')}
                min={0}
                max={120}
              />
            )}
            <Boolean
              value={entity.options.openOnExit}
              update={this.toggleOpenOnExit}
              labelText="entity_settings.popup.open_on_exit"
            />
            {entity.options.openOnExit === '1' && (
              <Checkbox
                labelText="entity_settings.popup.mobile_open_on_exit"
                value={entity.options.openOnMobileAutomatically === '1'}
                update={this.toggleMobileOpenAutomatically}
              />
            )}
            {!this.context.isMobile && (
              <RangeNew
                allowNegative={false}
                label={t('width')}
                value={entity.styles.width || 600}
                change={updateStyle('width')}
                min={300}
                max={1200}
              />
            )}
            {entity.options.openOnMobileAutomatically === '1' && (
              <Range
                labelText={t('entity_settings.popup.open_in_x_seconds', {
                  seconds: entity.options.mobileDelay,
                })}
                value={entity.options.mobileDelay}
                update={updateOption('mobileDelay')}
                min={0}
                max={120}
              />
            )}
            <GroupTitle>color</GroupTitle>
            <ColorPicker
              label="bg color"
              update={updateStyle('backgroundColor')}
              mobileUpdate={updateMobileStyle('backgroundColor')}
              color={entity.styles.backgroundColor}
              mobileColor={entity.mobileStyles.backgroundColor}
            />
            <PaddingStyle
              mobilePadding={entity.mobileStyles.padding}
              padding={entity.styles.padding}
              update={updateStyle('padding')}
              mobileUpdate={updateMobileStyle('padding')}
            />
            <BorderStyle
              updateOption={updateOption}
              updateMobileOption={updateMobileOption}
              updateStyle={updateStyle}
              updateMobileStyle={updateMobileStyle}
              type={entity.options.borderType}
              style={entity.styles.borderStyle}
              width={entity.styles.borderWidth}
              color={entity.styles.borderColor}
              mobileType={entity.mobileOptions.borderType}
              mobileStyle={entity.mobileStyles.borderStyle}
              mobileWidth={entity.mobileStyles.borderWidth}
              mobileColor={entity.mobileStyles.borderColor}
              toggleOverlay={toggleOverlay}
              overlayVisible={overlayVisible}
            />
            <ShadowStyle
              shadow={entity.styles.boxShadow}
              mobileShadow={entity.mobileStyles.boxShadow}
              update={updateStyle('boxShadow')}
              mobileUpdate={updateMobileStyle('boxShadow')}
            />
            <CornerStyle
              topLeft={entity.styles.borderTopLeftRadius}
              topRight={entity.styles.borderTopRightRadius}
              bottomRight={entity.styles.borderBottomRightRadius}
              bottomLeft={entity.styles.borderBottomLeftRadius}
              mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
              mobileTopRight={entity.mobileStyles.borderTopRightRadius}
              mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
              mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
              update={updateStyle}
              mobileUpdate={updateMobileStyle}
            />
            <Attributes update={updateOption} entity={entity} />
          </React.Fragment>
        )}
      </Translation>
    )
  }
}

PopupSettings.propTypes = {
  overlayVisible: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
}

PopupSettings.defaultProps = {
  button: null,
}

const findSubmitButton = (page, ascendantId) =>
  findDescendantsByType(page, ascendantId, structureTypes.BUTTON).find(
    entity => entity.options.action === buttonActionsTypes.sendForm,
  )

const mapStateToProps = (
  { page: { present: presentPage } },
  { entity: { id } },
) => ({
  button: findSubmitButton(presentPage, id),
})

export default connect(mapStateToProps)(PopupSettings)
