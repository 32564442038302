import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { InternalError } from 'common/errors'
import { useActions } from 'common/hooks/useActions'
import { fetchBlocksSuccess } from 'client/actions/blockActions'
import * as blocksApi from 'client/api/blockApi'
import useBlocks, { selectors } from 'client/hooks/useBlocks'
import Blocks from './Blocks'
import BlockGroupUi from './ui/BlockGroupUi'
import BlockGroupsUi from './ui/BlockGroupsUi'

function WrappedWithSkeletonBlocks({ pageType }) {
  const isFetched = useBlocks(selectors.isFetched)
  const dispatchFetchBlocksSuccess = useActions(fetchBlocksSuccess)

  async function fetchBlocks() {
    try {
      const { data } = await blocksApi.fetchBlocks()
      dispatchFetchBlocksSuccess(data)
    } catch (error) {
      if (error instanceof InternalError) {
        window.Rollbar.error(error)
      } else {
        // do nothing
      }
    }
  }

  useEffect(() => {
    if (!isFetched) {
      fetchBlocks()
    }
  }, [isFetched])

  return (
    <BlockGroupsUi>
      {!isFetched ? (
        Array(8)
          .fill(0)
          .map((_, i) => (
            <BlockGroupUi key={`block-${i}`}>
              <Skeleton width={i % 2 == 0 ? 150 : 200} />
            </BlockGroupUi>
          ))
      ) : (
        <Blocks pageType={pageType} />
      )}
    </BlockGroupsUi>
  )
}

export default WrappedWithSkeletonBlocks
