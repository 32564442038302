import styled from 'styled-components'

const CheckboxUi = styled.input.attrs(() => ({ type: 'checkbox' }))`
  ${p => p.theme.phone} {
    ${({ mobileStyles = {} }) => mobileStyles}
  }
  ${({ styles = {} }) => styles}
`

export default CheckboxUi
