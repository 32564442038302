import PropTypes from 'prop-types'
import React from 'react'
import { Trans } from 'react-i18next'
import BlueButton from 'client/components/core/BlueButton'
import { Dialog } from 'client/components/core/Dialog'
import TransparentButton from 'client/components/core/WhiteButton'
import DialogContentUi from './ui/DialogContentUi'
import DialogTitleUi from './ui/DialogTitleUi'
import DialogWrapperUi from './ui/DialogWrapperUi'

const ConfirmationDialog = ({ close, exit, save, isLoading, title }) => (
  <Dialog close={close} show small>
    <DialogWrapperUi>
      <DialogTitleUi>
        <Trans parent="span">{title}</Trans>
      </DialogTitleUi>
      <DialogContentUi>
        <TransparentButton onClick={exit} disabled={isLoading} shadow wide>
          <Trans parent="span">No</Trans>
        </TransparentButton>
        <BlueButton
          type="success"
          onClick={save}
          disabled={isLoading}
          shadow
          wide
        >
          <Trans>Yes</Trans>
        </BlueButton>
      </DialogContentUi>
    </DialogWrapperUi>
  </Dialog>
)

ConfirmationDialog.propTypes = {
  exit: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default ConfirmationDialog
