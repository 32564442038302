import styled from 'styled-components'

const ManagementItemUi = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  text-align: left;
  letter-spacing: 2px;
  padding: 20px 15px 20px 25px;
  border-bottom: 1px solid #ebedf0;
  color: #6b7c93;
  text-transform: capitalize;
  font-weight: bold;
  transition-property: background-color, opacity;
  transition-duration: .1s;
  transition-timing-function: ease;
`

export default ManagementItemUi
