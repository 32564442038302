import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

const BadgeUi = styled.span`
  position: absolute;
  z-index: 10;
  top: auto;
  bottom: -7px;
  left: 10px;
  font-size: 8px;
  text-transform: uppercase;
  color: #fff !important;
  background-color: rgba(36, 36, 36, 0.9);
  padding: 2px 6px;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  font-weight: bold;
  opacity: 0.85;
  ${p =>
    p.noBackground &&
    css`
      background-color: transparent;
    `}
  ${p =>
    p.warning &&
    css`
      background-color: #dd2925;
    `}
  ${p =>
    p.wide &&
    css`
      max-width: 100%;
      left: 0;
    `}
`

const Badge = ({ children, noBackground, warning, wide }) => (
  <BadgeUi noBackground={noBackground} warning={warning} wide={wide}>
    {children}
  </BadgeUi>
)

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  noBackground: PropTypes.bool,
  warning: PropTypes.bool,
  wide: PropTypes.bool,
}

Badge.defaultProps = {
  noBackground: false,
  text: '',
  warning: false,
  wide: false,
}

export default Badge
