import React from 'react'
import ProductEntityInterface from 'common/types/entities/ProductInterface'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import NewCheckbox from 'client/components/core/Sidebar/components/Settings/components/NewCheckbox'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearance from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { typedPaymentSelectors, usePayment } from 'client/store'

function ProductSettings({ entity }: { entity: ProductEntityInterface }) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<ProductEntityInterface>()
  const product = usePayment(typedPaymentSelectors.getProduct)

  return (
    <>
      <NewCheckbox
        checked={entity.showQuantity}
        update={updateProp('showQuantity')}
        labelText="entity_settings.physical_product.show_quantity.label"
      />
      {product?.limitStock && (
        <NewCheckbox
          checked={entity.showStockLimit}
          update={updateProp('showStockLimit')}
          labelText="entity_settings.physical_product.show_stock_limit.label"
        />
      )}
      <TextFontUpdater<ProductEntityInterface>
        entity={entity}
        update={updateEntity}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={appearance =>
          updateEntity({
            ...entity,
            appearance,
          })
        }
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default ProductSettings
