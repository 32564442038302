import PropTypes from 'prop-types'
import React from 'react'
import * as types from 'common/types'
import { Input } from '../components'
import { DeviceAppearance } from '../options'
import { FlexAlignStyle, MarginsStyle } from '../styles'

function TwitterTweetButtonSettingsOld(props) {
  const { entity, updateStyle, updateMobileStyle, updateOption, update } = props

  return (
    <React.Fragment>
      <Input
        labelText="entity_settings.twitter_tweet_button.text.label"
        value={entity.options.text}
        update={updateOption('text')}
      />
      <FlexAlignStyle
        align={entity.styles.alignSelf}
        mobileAlign={entity.mobileStyles.alignSelf}
        update={updateStyle('alignSelf')}
        mobileUpdate={updateMobileStyle('alignSelf')}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <DeviceAppearance entity={entity} update={update} />
    </React.Fragment>
  )
}

TwitterTweetButtonSettingsOld.propTypes = {
  entity: types.entity.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}

export default TwitterTweetButtonSettingsOld