import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CommonOfferPrice from 'common/components/entities/OfferPriceNew'
import { ResourceTypeEnum } from 'common/enums/PricingEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { PricePlan } from 'common/types/OfferInterface'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import { OfferPricing } from 'common/types/pricingTypes'
import { CalculatePriceData } from 'common/types/shippingType'
import BaseEntity from 'client/components/core/BaseEntity/BaseEntityNew'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import { pricePlansStub } from 'client/constants/editorSettings'
import usePayment from 'client/hooks/usePayment'
import { fetchOfferPricing } from 'client/pages/offer/utils/fetchOfferPricing'
import { getIsFetching, getPricePlans } from 'client/reducers/paymentReducer'
import { usePage } from 'client/store'
import { usePayment as useTypedPayment } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import typedPaymentSelectors from 'client/store/payment/paymentSelectors'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import translatePricePlans from 'client/utils/translatePriceStub'

const OfferPrice = ({
  entity,
  isMoveUpAllowed,
  isMoveDownAllowed,
}: EntityProps<OfferPriceInterface>) => {
  const isFetching = usePayment(getIsFetching)
  const pricePlans: PricePlan[] = usePayment(getPricePlans)
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const isTemplate = usePage(pageSelectors.isPageTemplate)
  const product = useTypedPayment(typedPaymentSelectors.getProduct)
  const offer = useTypedPayment(typedPaymentSelectors.getOffer)
  const { i18n } = useTranslation()
  const fixedT = i18n.getFixedT(pageLocale, ['client', 'common'])

  const [offerPricing, setOfferPricing] = useState<OfferPricing[]>([])

  useEffect(() => {
    if (!offer.id || (!product?.id && !pricePlans.length)) return

    const fetchPriceData = async () => {
      try {
        const offers: CalculatePriceData[] = pricePlans.map(pricePlan => ({
          quantity: 1,
          offer: offer.id,
          pricePlan: pricePlan.id,
        }))

        if (product?.id) {
          offers.push({
            product: product?.id,
            quantity: 1,
            offer: offer.id,
          })
        }

        const pricing = await fetchOfferPricing(offers)

        setOfferPricing(pricing)
      } catch (err) {
        // do nothing
      }
    }

    fetchPriceData()
  }, [product?.id, pricePlans])

  if (isFetching && !isTemplate) {
    return null
  }

  const isExample = pricePlans.length === 0 && !product
  const calculatedPricePlans: PricePlan[] = isExample
    ? translatePricePlans(pricePlansStub, fixedT)
    : pricePlans

  const mainOfferPricing = offerPricing?.find(
    ({ entityId, entityType }) =>
      product?.id === entityId && entityType === ResourceTypeEnum.Product,
  )

  const pricePlansPricing = offerPricing?.filter(
    ({ entityId, entityType }) =>
      calculatedPricePlans.findIndex(
        pricePlan =>
          pricePlan.id === entityId &&
          entityType === ResourceTypeEnum.PricePlan,
      ) !== -1,
  )

  const renderOfferPrice = (entity: OfferPriceInterface) => {
    return (
      <CommonOfferPrice
        attrId={entity.htmlAttrId}
        mainOfferPricing={mainOfferPricing}
        pricePlansPricing={pricePlansPricing}
        plans={calculatedPricePlans}
        activePlanId={calculatedPricePlans[0]?.id}
        isVatNotChargeable={false}
        customDescription={entity.customDescription}
        product={product}
        togglePricePlan={() => null}
        locale={pageLocale}
        nameFontSize={entity.nameFontSize}
        mobileNameFontSize={entity.mobileNameFontSize}
        nameFontStyle={entity.nameFontStyle}
        mobileNameFontStyle={entity.mobileNameFontStyle}
        nameFontWeight={entity.nameFontWeight}
        mobileNameFontWeight={entity.mobileNameFontWeight}
        nameFontFamily={entity.nameFontFamily}
        mobileNameFontFamily={entity.mobileNameFontFamily}
        nameLineHeight={entity.nameLineHeight}
        mobileNameLineHeight={entity.mobileNameLineHeight}
        nameColor={entity.nameColor}
        mobileNameColor={entity.mobileNameColor}
        amountFontSize={entity.amountFontSize}
        mobileAmountFontSize={entity.mobileAmountFontSize}
        amountFontStyle={entity.amountFontStyle}
        mobileAmountFontStyle={entity.mobileAmountFontStyle}
        amountFontWeight={entity.amountFontWeight}
        mobileAmountFontWeight={entity.mobileAmountFontWeight}
        amountFontFamily={entity.amountFontFamily}
        mobileAmountFontFamily={entity.mobileAmountFontFamily}
        amountLineHeight={entity.amountLineHeight}
        mobileAmountLineHeight={entity.mobileAmountLineHeight}
        amountColor={entity.amountColor}
        mobileAmountColor={entity.mobileAmountColor}
        descriptionFontSize={entity.descriptionFontSize}
        mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
        descriptionFontStyle={entity.descriptionFontStyle}
        mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
        descriptionFontWeight={entity.descriptionFontWeight}
        mobileDescriptionFontWeight={entity.mobileDescriptionFontWeight}
        descriptionFontFamily={entity.descriptionFontFamily}
        mobileDescriptionFontFamily={entity.mobileDescriptionFontFamily}
        descriptionLineHeight={entity.descriptionLineHeight}
        mobileDescriptionLineHeight={entity.mobileDescriptionLineHeight}
        descriptionColor={entity.descriptionColor}
        mobileDescriptionColor={entity.mobileDescriptionColor}
      />
    )
  }

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {isExample ? (
        <EntityExampleWrapper label="entities.offer_price.example_warning">
          {renderOfferPrice(entity)}
        </EntityExampleWrapper>
      ) : (
        renderOfferPrice(entity)
      )}
    </BaseEntity>
  )
}

export function createOfferPrice(
  parentId: string,
  masterBlockId?: string,
): OfferPriceInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.OfferPrice, parentId, masterBlockId),
    type: EntityTypeEnum.OfferPrice,
    nameFontSize: 17,
    nameLineHeight: 16,
    nameColor: 'rgba(0,0,0,1)',
    amountFontSize: 17,
    amountLineHeight: 16,
    amountColor: 'rgba(0,0,0,1)',
    descriptionFontSize: 14,
    descriptionLineHeight: 32,
    descriptionColor: 'rgba(0,0,0,1)',
  }
}

export function getOfferPriceFontProperties(entity: OfferPriceInterface) {
  const fontProperties = entity.nameFontFamily
    ? [
        {
          fontFamily: entity.nameFontFamily,
          fontStyle: entity.nameFontStyle,
          fontWeight: entity.nameFontWeight,
        },
      ]
    : []

  if (entity.mobileNameFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileNameFontFamily,
      fontStyle: entity.mobileNameFontStyle,
      fontWeight: entity.mobileNameFontWeight,
    })
  }

  if (entity.descriptionFontFamily) {
    fontProperties.push({
      fontFamily: entity.descriptionFontFamily,
      fontStyle: entity.descriptionFontStyle,
      fontWeight: entity.descriptionFontWeight,
    })
  }

  if (entity.mobileDescriptionFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileDescriptionFontFamily,
      fontStyle: entity.mobileDescriptionFontStyle,
      fontWeight: entity.mobileDescriptionFontWeight,
    })
  }

  if (entity.amountFontFamily) {
    fontProperties.push({
      fontFamily: entity.amountFontFamily,
      fontStyle: entity.amountFontStyle,
      fontWeight: entity.amountFontWeight,
    })
  }

  if (entity.mobileAmountFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileAmountFontFamily,
      fontStyle: entity.mobileAmountFontStyle,
      fontWeight: entity.mobileAmountFontWeight,
    })
  }

  return fontProperties
}

export function getOfferPriceCustomFontProperties(entity: OfferPriceInterface) {
  const fontProperties = []
  if (entity.nameFontFamily) {
    fontProperties.push({
      fontFamily: entity.nameFontFamily,
      fontFileId: entity.nameFontFileId,
    })
  }

  if (entity.mobileNameFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileNameFontFamily,
      fontFileId: entity.mobileNameFontFileId,
    })
  }

  if (entity.descriptionFontFamily) {
    fontProperties.push({
      fontFamily: entity.descriptionFontFamily,
      fontFileId: entity.descriptionFontFileId,
    })
  }

  if (entity.mobileDescriptionFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileDescriptionFontFamily,
      fontFileId: entity.mobileDescriptionFontFileId,
    })
  }

  if (entity.amountFontFamily) {
    fontProperties.push({
      fontFamily: entity.amountFontFamily,
      fontFileId: entity.amountFontFileId,
    })
  }

  if (entity.mobileAmountFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileAmountFontFamily,
      fontFileId: entity.mobileAmountFontFileId,
    })
  }

  return fontProperties
}

export default OfferPrice
