import EntityTypeEnum from 'common/enums/entityTypeEnum'

export const isEntityLocked = (entity: EntityTypeEnum): boolean => {
  switch (entity) {
    case EntityTypeEnum.CustomLoginForgotPassword:
    case EntityTypeEnum.ContactUs:
    case EntityTypeEnum.ContactUsField:
    case EntityTypeEnum.ContactUsRecaptcha:
      return true
    default:
      return false
  }
}
