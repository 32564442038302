const getTimeOption = (scheduledHours: number[], language: string) => {
  if (language === 'fr') {
    return scheduledHours.reduce((acc, curr) => {
      if (curr === 24) {
        acc.push(`${curr}:00`)
      } else {
        acc.push(`${curr}:00`)
        acc.push(`${curr}:30`)
      }
      return acc
    }, [] as string[])
  } else {
    return scheduledHours.reduce((acc, curr) => {
      const label = get12HoursFormatLabel(curr)
      if (curr === 24) {
        acc.push(`${label.hour}:00 ${label.suffix}`)
      } else {
        acc.push(`${label.hour}:00 ${label.suffix}`)
        acc.push(`${label.hour}:30 ${label.suffix}`)
      }
      return acc
    }, [] as string[])
  }
}
export const timeOptions = (language: string) =>
  getTimeOption([...Array(24).keys()], language)

function get12HoursFormatLabel(hour: number) {
  const suffix = hour >= 12 ? 'PM' : 'AM'
  let formattedHour = hour % 12 || 12
  //if 00 then it is 12 am

  formattedHour = hour === 0 ? 12 : formattedHour
  return { hour: formattedHour, suffix }
}

export const getDefaultTime = (date = new Date(), language: string) => {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  if (language === 'fr') {
    return `${hours}: ${minutes ? minutes : '00'}`
  } else {
    const suffix = hours >= 12 ? 'PM' : 'AM'
    let formattedHour = hours % 12 || 12
    //if 00 then it is 12 am
    formattedHour = hours === 0 ? 12 : formattedHour
    return `${formattedHour}: ${minutes ? minutes : '00'} ${suffix}`
  }
}
