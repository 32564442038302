import React from 'react'
import { HoverType } from 'common/types/styleTypes'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import ColorPicker from '../../components/ColorPicker'

interface HoverColorStyleProps {
  textColor: string
  backgroundColor: string
  subTextColor?: string
  hover?: HoverType
  mobileHover?: HoverType
  update: (hover: HoverType) => void
  mobileUpdate: (hover: HoverType) => void
}

function HoverColorStyle({
  hover,
  mobileHover,
  update,
  mobileUpdate,
  textColor,
  backgroundColor,
  subTextColor,
}: HoverColorStyleProps) {
  const { isMobile } = useDeviceModeContext()

  const updateColorText = (color: string) => {
    if (isMobile) {
      mobileUpdate({ ...mobileHover, textColor: color })
    } else {
      update({ ...hover, textColor: color })
    }
  }

  const updateColorSubText = (color: string) => {
    if (isMobile) {
      mobileUpdate({ ...mobileHover, subTextColor: color })
    } else {
      update({ ...hover, subTextColor: color })
    }
  }

  const updateColorBackground = (color: string) => {
    if (isMobile) {
      mobileUpdate({ ...mobileHover, backgroundColor: color })
    } else {
      update({ ...hover, backgroundColor: color })
    }
  }

  return (
    <>
      <ColorPicker
        label={'settings_styles.hover_color.label.hover_text'}
        update={updateColorText}
        mobileUpdate={updateColorText}
        color={hover?.textColor || textColor}
        mobileColor={mobileHover?.textColor}
      />
      <ColorPicker
        label={'settings_styles.hover_color.label.hover_background'}
        update={updateColorBackground}
        mobileUpdate={updateColorBackground}
        color={hover?.backgroundColor || backgroundColor}
        mobileColor={mobileHover?.backgroundColor}
      />
      <ColorPicker
        label={'settings_styles.hover_color.label.hover_sub_text'}
        update={updateColorSubText}
        mobileUpdate={updateColorSubText}
        color={hover?.subTextColor || subTextColor}
        mobileColor={mobileHover?.subTextColor}
      />
    </>
  )
}

export default HoverColorStyle
