import React from 'react'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import { CheckboxUi, CheckBoxLabel, CheckboxWrapperUi } from './ui/CheckboxUi'

type NewCheckboxProps = {
  checked?: boolean
  update: (value: boolean) => void
  labelText?: string
  helpMessage?: string
}

function NewCheckbox({
  checked = false,
  update,
  labelText,
  helpMessage,
}: NewCheckboxProps) {
  return (
    <>
      <OptionWrapper labelText={labelText} helpMessage={helpMessage}>
        <CheckboxWrapperUi>
          <CheckboxUi
            id={labelText}
            checked={checked}
            onChange={() => update(!checked)}
          />
          <CheckBoxLabel htmlFor={labelText} />
        </CheckboxWrapperUi>
      </OptionWrapper>
    </>
  )
}

export default NewCheckbox
