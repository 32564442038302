import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonBreadcrumbs from 'common/components/entities/Breadcrumbs'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import {
  Breadcrumb,
  BreadcrumbsInterface,
} from 'common/types/entities/BreadcrumbsInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { useBlogPost } from 'client/store'
import { blogPostSelectors } from 'client/store/blogPost/blogPostReducer'
import { EntityProps } from 'client/types'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'

function Breadcrumbs({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BreadcrumbsInterface>) {
  const { t } = useTranslation('common')
  const categories = useBlogPost(blogPostSelectors.getBlogPostCategories) || []
  const title = useBlogPost(blogPostSelectors.getBlogPostTitle)
  const breadcrumbs: Breadcrumb[] = [
    { name: t('components.breadcrumbs.items.home'), url: '' },
  ]

  if (categories.length > 0) {
    breadcrumbs.push({
      name: categories[0],
      url: '',
    })
  }
  breadcrumbs.push({ name: title })

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonBreadcrumbs
        items={breadcrumbs}
        itemColor={entity.itemColor}
        lastItemColor={entity.lastItemColor}
        fontSize={entity.fontSize}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        fontFamily={entity.fontFamily}
        mobileFontSize={entity.mobileFontSize}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        mobileFontFamily={entity.mobileFontFamily}
      />
    </BaseEntityNew>
  )
}

export function getBreadcrumbsFontProperties(entity: BreadcrumbsInterface) {
  const fontProperties = entity.fontFamily
    ? [
        {
          fontFamily: entity.fontFamily,
          fontStyle: entity.fontStyle,
          fontWeight: entity.fontWeight,
        },
      ]
    : []

  if (entity.mobileFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileFontFamily,
      fontStyle: entity.mobileFontStyle,
      fontWeight: entity.mobileFontWeight,
    })
  }

  return fontProperties
}

export function createBreadcrumbs(parentId: string, masterBlockId?: string) {
  const breadcrumbs: BreadcrumbsInterface = {
    id: generateId(),
    type: EntityTypeEnum.Breadcrumbs,
    parentId: parentId,
    fontSize: 20,
    itemColor: 'rgba(124, 124, 124, 1)',
    lastItemColor: 'rgba(20, 45, 99, 1)',
    margin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 10,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.Breadcrumbs),
  }

  if (masterBlockId) {
    breadcrumbs.masterBlockId = masterBlockId
  }

  return breadcrumbs
}

export default Breadcrumbs
