import styled from 'styled-components'

const MenuSubItemsWrapperUi = styled.div`
  display: flex;
  padding: 0 0 0 20px;
  flex-direction: column;
  gap: 20px;
`

export default MenuSubItemsWrapperUi
