import { useStore } from 'react-redux'
import { AnyAction, Reducer } from 'redux'
import { RootState } from '../store'

type DynamicStoreType<T, A extends AnyAction> = {
  key: string
  reducer: Reducer<T, A>
}

export default function useDynamicStore<T, A extends AnyAction>({
  key,
  reducer,
}: DynamicStoreType<T, A>) {
  const store = useStore<RootState>()
  // @ts-ignore
  store.reducerManager.add(key, reducer)
}
