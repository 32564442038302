import React, { memo } from 'react'
import { useDragLayer } from 'react-dnd'
import { useSelector, shallowEqual } from 'react-redux'
import CommonBody from 'common/components/entities/Body/index'
import * as types from 'common/types'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { getFileById } from 'client/reducers/filesReducer'
import { getBlockFile } from '../../../reducers/blocksReducer'

function Body({ entity }) {
  const { isDragging } = useDragLayer(monitor => ({
    isDragging: monitor.isDragging(),
    itemType: monitor.getItemType(),
  }))
  const { isMobile } = useDeviceModeContext()
  const backgroundFile = useSelector(
    state =>
      getFileById(state.files, entity.options.backgroundFileId) ||
      getBlockFile(state.blocks, entity.options.backgroundFileId),
    shallowEqual,
  )
  const videoFile = useSelector(
    state =>
      getFileById(state.files, entity.options.videoFileId) ||
      getBlockFile(state.blocks, entity.options.videoFileId),
    shallowEqual,
  )
  const mobileBackgroundFile = useSelector(
    state =>
      getFileById(state.files, entity.mobileOptions.backgroundFileId) ||
      getBlockFile(state.blocks, entity.mobileOptions.backgroundFileId),
    shallowEqual,
  )

  const calculatedMobileBackgroundFile =
    typeof entity.mobileOptions.backgroundFileId === 'undefined'
      ? backgroundFile
      : mobileBackgroundFile

  const { id, type, options, mobileOptions, childIds, styles, mobileStyles } =
    entity

  return (
    <CommonBody
      styles={styles}
      // Width of rendered client
      mobileStyles={{ ...mobileStyles, maxWidth: '350px' }}
      options={{
        ...(isMobile ? { ...options, ...mobileOptions } : options),
        videoFile,
      }}
      backgroundFile={
        isMobile ? calculatedMobileBackgroundFile : backgroundFile
      }
    >
      <ChildrenEntities
        id={id}
        childIds={childIds}
        type={type}
        isReadOnly={entity.isReadOnly}
        placeholderText="entities.body.empty_dropbox_text"
        expandedDropZone={isDragging}
      />
    </CommonBody>
  )
}

Body.propTypes = {
  entity: types.entity.isRequired,
}

export default memo(Body)
