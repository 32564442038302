import React from 'react'
import PropTypes from 'prop-types'
import { Translation } from 'react-i18next'
import SelectUi from './ui/Select'

const Select = ({
  value,
  styles,
  choices,
  handleChange,
  firstChoice,
  needTranslate,
  ...rest
}) => (
  <Translation ns={['client', 'publisher']}>
    {t => (
      <SelectUi
        {...rest}
        styles={styles}
        onChange={e => {
          handleChange(e.target.value)
        }}
        value={value}
      >
        {firstChoice && <option value="">{t(firstChoice)}</option>}
        {Array.isArray(choices)
          ? choices.map(({ code, name }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))
          : Object.keys(choices).map(key => (
              <option key={key} value={key}>
                {needTranslate
                  ? t([choices[key]])
                  : choices[key]}
              </option>
            ))}
      </SelectUi>
    )}
  </Translation>
)

Select.propTypes = {
  firstChoice: PropTypes.string,
  choices: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  styles: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func,
  value: PropTypes.string,
  needTranslate: PropTypes.bool,
}

Select.defaultProps = {
  handleChange: () => {},
  choices: {},
  styles: {},
  value: '',
  firstChoice: null,
  needTranslate: false,
}

export default Select
