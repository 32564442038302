export default function findMasterBlockIdsWithoutMasterRoot(entities) {
  const arrEntities = Object.values(entities)
  const masterBlockIds = arrEntities
    .filter(entity => entity.masterBlockId)
    .map(entity => entity.masterBlockId)
  const uniqueMasterBlockIds = [...new Set(masterBlockIds)]

  return uniqueMasterBlockIds.filter(masterBlockId =>
    arrEntities
      .filter(e => e.masterBlockId === masterBlockId)
      .every(e => !e.isMasterBlockRoot),
  )
}
