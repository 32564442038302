import { FetchBlogPostData, FETCH_BLOG_POST_DATA } from './blogPostActionTypes'
import { BlogPostState } from './blogPostReducer'

export function fetchBlogPostData(
  blogPostData: BlogPostState,
): FetchBlogPostData {
  return {
    type: FETCH_BLOG_POST_DATA,
    payload: blogPostData,
  }
}

export type BlogPostActions = FetchBlogPostData
