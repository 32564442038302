import React, { useEffect } from 'react'
import { stickyPositionLockKeys } from 'common/components/entities/Section/constants/StickyPositionLockKeys'
import { StickyPositionEnum } from 'common/components/entities/Section/enums/StickyPositionEnum'
import { SectionInterface } from 'common/components/entities/Section/types/SectionInterface'
import { Select } from 'client/components/core/Sidebar/components/Settings/components'
import Checkbox from 'client/components/core/Sidebar/components/Settings/components/Checkbox'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range/Range'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useUpdateGlobalSettings } from '../../../hooks/useUpdateGlobalSetting'

interface StickySettingProps {
  sticky: SectionInterface['sticky']
  entityHtmlAttrId: string
  update: (value: SectionInterface['sticky']) => void
}

export const StickySetting = ({ sticky, update, entityHtmlAttrId }: StickySettingProps) => {
  const { isMobile } = useDeviceModeContext()
  const updateGlobalSettings = useUpdateGlobalSettings()

  const targetEntity = document.querySelector<HTMLElement>(`#${entityHtmlAttrId}`)

  useEffect(() => {
    if(sticky?.position === StickyPositionEnum.top && targetEntity?.offsetHeight) {
      updateGlobalSettings({
        scrollPaddingTop: `${targetEntity.offsetHeight}px`,
      })
      return
    }
    updateGlobalSettings({ scrollPaddingTop: null })
  },[entityHtmlAttrId, sticky, updateGlobalSettings, targetEntity])

  return (
    <>
      <GroupTitle>settings_options.sticky.label</GroupTitle>
      <Checkbox
        value={!!sticky}
        update={value =>
          update(
            value ? { indent: 0, position: StickyPositionEnum.top } : undefined,
          )
        }
        labelText="settings_options.sticky.description"
        labelIcon={isMobile && <MobileIconUi />}
        helpMessage="settings_options.sticky.help_message"
        directionRow
      />
      {sticky && (
        <>
          <Select
            value={sticky.position}
            update={value => update({ ...sticky, position: value })}
            labelText="entity_settings.section.content_width.label"
            helpMessage="entity_settings.section.content_width.help_message"
            choiceList={stickyPositionLockKeys}
            needTranslate={true}
            labelIcon={isMobile && <MobileIconUi />}
          />
          <Range
            value={sticky.indent}
            change={value => update({ ...sticky, indent: value })}
            labelIcon={isMobile ? <MobileIconUi /> : null}
            label="settings_options.sticky.indent"
            max={200}
          />
        </>
      )}
    </>
  )
}
