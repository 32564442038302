import styled from 'styled-components'

const BlockTitleWrapperUi = styled.div`
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`

export default BlockTitleWrapperUi
