import styled, { css } from 'styled-components'

type EntityInnerItemRemoveIconUiProps = {
  disabled: boolean
}

const EntityInnerItemRemoveIconUi = styled.i.attrs(() => ({
  className: 'far fa-trash-alt',
}))<EntityInnerItemRemoveIconUiProps>`
  cursor: pointer;
  ${p =>
    p.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: default;
    `};
`

export default EntityInnerItemRemoveIconUi
