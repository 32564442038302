import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import * as types from 'common/types'
import { Attributes, DeviceAppearance } from '../../options'
import { MarginsStyle } from '../../styles'

class CustomerTypeSettings extends PureComponent {
  render() {
    const { entity, updateStyle, updateOption, updateMobileStyle, update } =
      this.props
    return (
      <React.Fragment>
        <MarginsStyle
          marginTop={entity.styles.marginTop}
          marginRight={entity.styles.marginRight}
          marginBottom={entity.styles.marginBottom}
          marginLeft={entity.styles.marginLeft}
          mobileMarginTop={entity.mobileStyles.marginTop}
          mobileMarginRight={entity.mobileStyles.marginRight}
          mobileMarginBottom={entity.mobileStyles.marginBottom}
          mobileMarginLeft={entity.mobileStyles.marginLeft}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <DeviceAppearance update={update} entity={entity} />
        <Attributes update={updateOption} entity={entity} />
      </React.Fragment>
    )
  }
}

CustomerTypeSettings.propTypes = {
  entity: types.entity.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
}

export default CustomerTypeSettings
