import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import EntityInterface, {
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { copyEntityWithSource } from './cloneStructure'
import { generateHtmlAttrId, generateId } from './createStructureUtils'

export function copyEntity(
  entity: EntityInterface | OldEntityInterface | EntityInnerItemInterface,
) {
  if (
    entity.type in EntityTypeEnum ||
    entity.type === EntityTypeEnum.Carousel
  ) {
    return {
      ...entity,
      oldId: entity.id,
      id: generateId(),
      htmlAttrId: generateHtmlAttrId(entity.type as EntityTypeEnum),
    }
  } else {
    return copyEntityWithSource(entity)
  }
}
