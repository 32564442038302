import styled from 'styled-components'

interface AutomationRuleSpanUiPropsUi {
  fontWeight?: number
  fontSize?: number
}
const AutomationRuleSpanUi = styled.span<AutomationRuleSpanUiPropsUi>`
  color: rgba(20, 45, 99, 1);
  font-size: ${p => p.fontSize}px;
  font-weight: ${p => p.fontWeight};
`

export default AutomationRuleSpanUi
