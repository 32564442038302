import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { BadRequest } from 'common/errors/BadRequest'
import { GatewayError } from 'common/errors/GatewayError'
import { NetworkError } from 'common/errors/NetworkError'
import { NotFound } from 'common/errors/NotFound'
import { PageValidationError } from 'common/errors/PageValidationError'
import { ServiceUnavailable } from 'common/errors/ServiceUnavailable'
import { useActions } from 'common/hooks/useActions'
import * as actions from 'client/actions'
import * as pageActions from 'client/actions/pageActions'
import ConfirmationDialog from 'client/components/core/ConfirmationDialog'
import useManagement from 'client/hooks/useManagement'
import usePresentPage from 'client/hooks/usePresentPage'
import { useUnsavedData } from 'client/hooks/useUnsavedData'
import { selectors as managementSelectors } from 'client/reducers/managementReducer'
import { selectors as pageSelectors } from 'client/reducers/pageReducer'
import { usePage } from 'client/store'
import GreenButton from '../../GreenButton'

function ExitButton() {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isDialogOpen, openDialog] = useState(false)
  const dispatch = useDispatch()
  const isPageModified = usePresentPage(pageSelectors.isPageModified)
  const exitUrl = useManagement(managementSelectors.getExitUrl)
  const isTemplate = usePage(pageSelectors.isTemplate)
  const savePage = useActions(pageActions.savePage)
  const exit = useActions(actions.exit)

  // we need it only to delete record from db
  const { deleteDbRecord } = useUnsavedData({ notExecute: true })

  async function handleSave() {
    setIsLoading(true)
    try {
      await savePage(isTemplate)
      await deleteDbRecord()
      dispatch(pageActions.savePageSuccess())
    } catch (e) {
      if (e instanceof PageValidationError) {
        dispatch(pageActions.pageValidationFail(e.messages))
      } else if (e instanceof NotFound) {
        dispatch(pageActions.savePageFail('core.errors.page_not_found'))
      } else if (e instanceof NetworkError) {
        // just show warning about data loss
        dispatch(pageActions.savePageFail('core.errors.save'))
      } else if (e instanceof GatewayError) {
        dispatch(pageActions.savePageFail('core.errors.save'))
        window.Rollbar.error(e)
      } else if (e instanceof ServiceUnavailable) {
        dispatch(pageActions.savePageFail('core.errors.save'))
        window.Rollbar.error(e)
      } else if (e instanceof BadRequest) {
        dispatch(pageActions.savePageFail('core.errors.save'))
      } else {
        dispatch(pageActions.savePageFail('core.errors.save'))
      }
    }
    setIsLoading(false)
  }

  const handleExit = () => {
    exit(exitUrl)
  }

  const checkAndExit = () => {
    if (isPageModified) {
      openDialog(true)
    } else {
      exit(exitUrl)
    }
  }

  const saveAndExit = async () => {
    await handleSave()
    closeDialog()
    handleExit()
  }

  const closeDialog = () => openDialog(false)

  return (
    <React.Fragment>
      {isDialogOpen && (
        <ConfirmationDialog
          save={saveAndExit}
          close={closeDialog}
          exit={handleExit}
          isLoading={isLoading}
          title={'core.header.exit_confirmation_title'}
        />
      )}
      <GreenButton
        onClick={checkAndExit}
        title={t('core.header.exit')}
        disabled={!i18n.language}
        data-test-id='editor-exit-button'
      >
        <span className="fas fa-sign-out-alt" />
      </GreenButton>
    </React.Fragment>
  )
}

export default ExitButton
