import React from 'react'
import useDynamicStore from '../../hooks/useDynamicStore'
import { usePage } from '../../store'
import customLoginReducer from '../../store/custom-login/customLoginReducer'
import { pageSelectors } from '../../store/page/pageSelectors'
import PageUi from '../ui/PageUi'

function CustomLoginPage({ children }: { children: React.ReactChildren }) {
  useDynamicStore({ key: 'customLogin', reducer: customLoginReducer })
  const globalFontProperties = usePage(pageSelectors.getGlobalFontProperties)

  return (
    <PageUi
      fontFamily={globalFontProperties.fontFamily}
      fontWeight={globalFontProperties.fontWeight}
      fontStyle={globalFontProperties.fontStyle}
    >
      {children}
    </PageUi>
  )
}

export default CustomLoginPage
