import React from 'react'
import styled from 'styled-components'

const QuestionListItemTitleWrapperUi = styled.div`
  flex: 1;
`

const QuestionListItemTitleUi = styled.span`
  border-bottom: 1px dashed var(--default-text-color);
  font-size: 20px;
  cursor: pointer;
`

type QuestionListItemTitleProps = {
  children: string
  onClick: (e: React.SyntheticEvent) => void
}

export default function QuestionListItemTitle({
  children,
  onClick,
}: QuestionListItemTitleProps) {
  return (
    <QuestionListItemTitleWrapperUi>
      <QuestionListItemTitleUi onClick={onClick}>
        {children}
      </QuestionListItemTitleUi>
    </QuestionListItemTitleWrapperUi>
  )
}
