import React from 'react'
import { gradientPaletteInterface } from '.'
import ColorStop from './ColorStop'
import ColorStopsHolderUi from './ui/ColorStopsHolderUi'

type ColorStopsHolderProps = {
  width: number
  stops: gradientPaletteInterface[]
  limits: { min: number; max: number; drop: number }
  onPosChange: ({ id, offset }: { id: number; offset: number }) => void
  onDragStart: (id: number) => void
  onDragEnd: ({ id, offset }: { id: number; offset: number }) => void
}
const ColorStopsHolder = ({ width, stops, ...rest }: ColorStopsHolderProps) => {
  return (
    <ColorStopsHolderUi width={width}>
      {stops.map(stop => (
        <ColorStop key={stop.id} stop={stop} {...rest} />
      ))}
    </ColorStopsHolderUi>
  )
}

export default ColorStopsHolder
