import PropTypes from 'prop-types'
import React from 'react'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { wrapFontFamily } from '../../common/components/entities/Text/utils'
import ActivePopup from './components/ActivePopup'
import PageUi from './ui/PageUi'

function CommonPage({ children }) {
  const globalFontProperties = usePage(pageSelectors.getGlobalFontProperties)

  return (
    <PageUi
      fontFamily={wrapFontFamily(globalFontProperties.fontFamily)}
      fontWeight={globalFontProperties.fontWeight}
      fontStyle={globalFontProperties.fontStyle}
    >
      {children}
      <ActivePopup />
    </PageUi>
  )
}

CommonPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CommonPage
