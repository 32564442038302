import React from 'react'
import useBlogData from 'client/hooks/useBlogData'
import { usePage } from 'client/store'
import { pageSelectors } from 'client/store/page/pageSelectors'
import ActivePopup from '../components/ActivePopup'
import PageUi from '../ui/PageUi'

function BlogPostLayoutPage({ children }: { children: React.ReactChildren }) {
  useBlogData()
  const globalFontProperties = usePage(pageSelectors.getGlobalFontProperties)

  return (
    <PageUi
      fontFamily={globalFontProperties.fontFamily}
      fontWeight={globalFontProperties.fontWeight}
      fontStyle={globalFontProperties.fontStyle}
    >
      {children}
      <ActivePopup />
    </PageUi>
  )
}

export default BlogPostLayoutPage
