import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { pageTypes } from 'common/constants/pageTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import {
  toggleActivePopup,
  togglePopupManagement,
  toggleSettings,
  showPageSettings,
  closePageSettings,
} from 'client/actions/actionsManagement'
import Breadcrumbs from 'client/components/core/Breadcrumbs'
import HeaderActionsWrapperUi from 'client/components/core/Header/ui/HeaderActionsWrapperUi'
import HeaderBrandWrapperUi from 'client/components/core/Header/ui/HeaderBrandWrapperUi'
import HeaderSettingsWrapperUi from 'client/components/core/Header/ui/HeaderSettingsWrapperUi'
import HeaderUi from 'client/components/core/Header/ui/HeaderUi'
import UndoRedo from 'client/components/core/UndoRedo'
import { SidebarVisibilityActionsContext } from 'client/context/SidebarVisibilityContext'
import {
  isPageEditing,
  isPopupManagement,
} from 'client/reducers/managementReducer'
import { getPageType } from 'client/reducers/pageReducer'
import ExitButton from './components/ExitButton'
import PreviewButton from './components/PreviewButton'
import SaveButton from './components/SaveButton'
import ToggleButton from './components/ToggleButton'

const pagesWithoutPopupAbility = [
  pageTypes.inline,
  pageTypes.popup,
  pageTypes.lecture,
  pageTypes.customLogin,
  PageTypeEnum.NotFound,
]

const pageWithoutSettings = [pageTypes.blogPostLayout]

class Header extends PureComponent {
  static contextType = SidebarVisibilityActionsContext

  showPopupManagement = () => {
    this.props.toggleSettings(null)
    this.props.closePageSettings()
    this.props.toggleActivePopup(null)
    this.props.togglePopupManagement(!this.props.isPopupManagement)
    this.context.showSidebar()
  }

  showPageSettings = () => {
    this.context.showSidebar()
    this.props.toggleActivePopup(null)
    this.props.togglePopupManagement(false)
    this.props.toggleSettings(null)
    this.props.showPageSettings()
  }

  render() {
    const { pageType, isPopupManagement, isPageEditing } = this.props

    return (
      <HeaderUi>
        <HeaderBrandWrapperUi>
          <img
            src={`${process.env.EDITOR_ASSETS_HOST}/assets/images/logo.png`}
            alt="systeme.io editor"
            height="30"
            data-test-element="editor-logo"
          />
        </HeaderBrandWrapperUi>
        <HeaderSettingsWrapperUi>
          <UndoRedo />
          {!pageWithoutSettings.includes(pageType) && (
            <ToggleButton
              title="core.header.page_settings.title"
              label="core.header.page_settings.label"
              active={isPageEditing}
              toggle={this.showPageSettings}
            />
          )}
          {!pagesWithoutPopupAbility.includes(pageType) && (
            <ToggleButton
              title="core.header.pop_up.title"
              label="core.header.pop_up.label"
              active={isPopupManagement}
              toggle={this.showPopupManagement}
            />
          )}
        </HeaderSettingsWrapperUi>
        <Breadcrumbs />
        <HeaderActionsWrapperUi>
          <PreviewButton />
          <SaveButton />
          <ExitButton />
        </HeaderActionsWrapperUi>
      </HeaderUi>
    )
  }
}

Header.propTypes = {
  isPopupManagement: PropTypes.bool.isRequired,
  isPageEditing: PropTypes.bool.isRequired,
  pageType: PropTypes.string,
  toggleActivePopup: PropTypes.func.isRequired,
  togglePopupManagement: PropTypes.func.isRequired,
  toggleSettings: PropTypes.func.isRequired,
  closePageSettings: PropTypes.func.isRequired,
  showPageSettings: PropTypes.func.isRequired,
}

Header.defaultProps = {
  pageType: null,
}

const mapStateToProps = ({ management, page: { present } }) => ({
  isPopupManagement: isPopupManagement(management),
  isPageEditing: isPageEditing(management),
  pageType: getPageType(present),
})

const mapDispatchToProps = {
  togglePopupManagement,
  toggleActivePopup,
  toggleSettings,
  showPageSettings,
  closePageSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
