import React from 'react'
import { useTranslation } from 'react-i18next'
import OptionWrapper from '../styleComponents/OptionWrapper'
import SelectUi from './Select/ui/SelectUi'

type BooleanProps = {
  labelText: string
  update: (value: boolean) => void
  value?: boolean
}

const Boolean = ({ labelText, update, value }: BooleanProps) => {
  const { t } = useTranslation()
  return (
    <OptionWrapper labelText={labelText}>
      <SelectUi
        onChange={e => update(e.target.value === 'on')}
        value={value ? 'on' : 'off'}
      >
        <option value="on">{t('on')}</option>
        <option value="off">{t('off')}</option>
      </SelectUi>
    </OptionWrapper>
  )
}

export default Boolean
