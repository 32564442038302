import styled from 'styled-components'

const BlockGroupItemsUi = styled.div`
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 200px);
`

export default BlockGroupItemsUi
