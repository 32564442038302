import React from 'react'
import { LanguageSelector } from '../options'
import Affiliate from './components/Affiliate'
import Typography from './components/Typography'
import Seo from './components/Seo'
import Tracking from './components/Tracking'
import Background from './components/Background'

function CommonPageSettings() {
  return (
    <React.Fragment>
      <Typography />
      <LanguageSelector />
      <Background />
      <Seo />
      <Tracking />
      <Affiliate />
    </React.Fragment>
  )
}

export default CommonPageSettings
