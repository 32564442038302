import React from 'react'
import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'
import AddTagIcon from '../icons/AddTagIcon'
import CampaignSubscribeIcon from '../icons/CampaignSubscribeIcon'
import CampaignUnsubscribeIcon from '../icons/CampaignUnsubscribeIcon'
import CommunityIcon from '../icons/CommunityIcon'
import CourseBundleEnrollIcon from '../icons/CourseBundleEnrollIcon'
import CommunityUnenrollIcon from '../icons/CommunityUnenrollIcon'
import CourseBundleUnenrollIcon from '../icons/CourseBundleUnenrollIcon'
import CourseIcon from '../icons/CourseIcon'
import CourseUnenrollIcon from '../icons/CourseUnenrollIcon'
import NewUserIcon from '../icons/NewUserIcon'
import RemoveTagIcon from '../icons/RemoveTagIcon'
import SendEmailIcon from '../icons/SendEmailIcon'

export const getActionIcon = (
  action: AutomationRuleActionsEnum,
): JSX.Element => {
  switch (action) {
    case AutomationRuleActionsEnum.add_tag: {
      return <AddTagIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.remove_tag: {
      return <RemoveTagIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.enroll_in_course: {
      return <CourseIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.revoke_access_to_course:
      return <CourseUnenrollIcon height={40} width={40} />
    case AutomationRuleActionsEnum.enroll_in_course_bundle: {
      return <CourseBundleEnrollIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
      return <CourseBundleUnenrollIcon height={40} width={40} />
    case AutomationRuleActionsEnum.send_email:
    case AutomationRuleActionsEnum.send_email_to_specific_address:
    case AutomationRuleActionsEnum.send_webhook: {
      return <SendEmailIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.subscribe_to_campaign: {
      return <CampaignSubscribeIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.unsubscribe_from_campaign: {
      return <CampaignUnsubscribeIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.grant_access_to_community: {
      return <CommunityIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.revoke_access_to_community: {
      return <CommunityUnenrollIcon height={40} width={40} />
    }
    case AutomationRuleActionsEnum.create_user: {
      return <NewUserIcon height={40} width={40} />
    }
    default:
      return <></>
  }
}
