import styled, { css } from 'styled-components'

const BlockUi = styled.div`
  &:first-of-type {
    margin-top: 10px;
  }
  cursor: pointer;
  padding-bottom: 20px;
  margin: 40px 2px 0 2px;
  border-bottom: 1px solid #dedcdc;
  font-size: 18px;
  text-align: center;
  position: relative;
  opacity: 1;
  ${p =>
    p.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

export default BlockUi
