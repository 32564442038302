import React from 'react'
import Affiliate from './components/Affiliate'
import Background from './components/Background'
import Title from './components/Title'
import Tracking from './components/Tracking'
import Typography from './components/Typography'

function LectureSettings() {
  return (
    <React.Fragment>
      <Title />
      <Typography skipFont />
      <Background />
      <Tracking />
      <Affiliate />
    </React.Fragment>
  )
}

export default LectureSettings
