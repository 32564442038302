import React from 'react'
import { ShippingFeesInterface } from 'common/types/entities/ShippingFeesInterface'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  FontSize,
  LineHeight,
} from 'client/components/core/Sidebar/components/Settings/styles'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

interface ShippingFeesSettingsProps {
  entity: ShippingFeesInterface
}

const ShippingFeesSettings = ({ entity }: ShippingFeesSettingsProps) => {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<ShippingFeesInterface>()

  return (
    <div>
      <GroupTitle>typography</GroupTitle>
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <LineHeight
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <TextFontUpdater entity={entity} update={updateEntity} />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity?.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </div>
  )
}

export default ShippingFeesSettings
