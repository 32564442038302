import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogContentPlaceholderInterface } from 'common/types/entities/BlogContentPlaceholderInterface'
import { BlogPostCategoriesInterface } from 'common/types/entities/BlogPostCategoriesInterface'
import { BlogPostContentPlaceholderInterface } from 'common/types/entities/BlogPostContentPlaceholderInterface'
import { BlogPostDateInterface } from 'common/types/entities/BlogPostDateInterface'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import { BlogPostTitleInterface } from 'common/types/entities/BlogPostTitleInterface'
import { BreadcrumbsInterface } from 'common/types/entities/BreadcrumbsInterface'
import { ButtonInterface } from 'common/types/entities/ButtonInterface'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { FieldInterface } from 'common/types/entities/FieldInterface'
import { LatestBlogPostsCarouselInterface } from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { LatestBlogPostsInterface } from 'common/types/entities/LatestBlogPostsInterface'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import BlogPostCategoriesSettingsNew from 'client/components/core/Sidebar/components/Settings/entities/BlogPostCategoriesSettingsNew'
import BlogPostDateSettingsNew from 'client/components/core/Sidebar/components/Settings/entities/BlogPostDateSettingsNew'
import BlogPostTitleSettingsNew from 'client/components/core/Sidebar/components/Settings/entities/BlogPostTitleSettingsNew'
import RecaptchaSettings from 'client/components/core/Sidebar/components/Settings/entities/RecaptchaSettings'
import BlogLatestPostsSettings from '../blog-page/settings/BlogLatestPostsSettings'
import BlogPostListingSettings from '../blog-page/settings/BlogPostListingSettings'
import LatestBlogPostsCarouselSettings from '../blog-page/settings/LatestBlogPostsCarouselSettings'
import { AttachmentsInterface } from '../contact-us/entities/Attachments'
import AttachmentsSettings from '../contact-us/settings/AttachmentsSettings'
import ButtonSettings from '../contact-us/settings/ButtonSettings'
import ContactUsFieldSettings from '../contact-us/settings/ContactUsFieldSettings'
import ContactUsSettings from '../contact-us/settings/ContactUsSettings'
import createCommonEntitySettings from '../createCommonEntitySettings'
import BlogContentPlaceholderSettings from './settings/BlogContentPlaceholderSettings'
import BlogPostContentPlaceholderSettings from './settings/BlogPostContentPlaceholderSettings'
import BreadcrumbsSettings from './settings/BreadcrumbsSettings'

function createSettingsForBlog(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.BlogPostListing:
      return (
        <BlogPostListingSettings entity={entity as BlogPostListingInterface} />
      )
    case EntityTypeEnum.LatestBlogPosts:
      return (
        <BlogLatestPostsSettings entity={entity as LatestBlogPostsInterface} />
      )
    case EntityTypeEnum.Breadcrumbs:
      return <BreadcrumbsSettings entity={entity as BreadcrumbsInterface} />
    case EntityTypeEnum.BlogPostDate:
      return (
        <BlogPostDateSettingsNew entity={entity as BlogPostDateInterface} />
      )
    case EntityTypeEnum.BlogPostCategories:
      return (
        <BlogPostCategoriesSettingsNew
          entity={entity as BlogPostCategoriesInterface}
        />
      )
    case EntityTypeEnum.BlogPostTitle:
      return (
        <BlogPostTitleSettingsNew entity={entity as BlogPostTitleInterface} />
      )
    case EntityTypeEnum.BlogContentPlaceholder:
      return (
        <BlogContentPlaceholderSettings
          entity={entity as BlogContentPlaceholderInterface}
        />
      )
    case EntityTypeEnum.BlogPostContentPlaceholder:
      return (
        <BlogPostContentPlaceholderSettings
          entity={entity as BlogPostContentPlaceholderInterface}
        />
      )
    case EntityTypeEnum.LatestBlogPostsCarousel:
      return (
        <LatestBlogPostsCarouselSettings
          entity={entity as LatestBlogPostsCarouselInterface}
        />
      )
    case EntityTypeEnum.ContactUs:
      return <ContactUsSettings entity={entity as ContactUsInterface} />
    case EntityTypeEnum.Button:
      return <ButtonSettings entity={entity as ButtonInterface} />
    case EntityTypeEnum.ContactUsField:
      return <ContactUsFieldSettings entity={entity as FieldInterface} />
    case EntityTypeEnum.Recaptcha:
    case EntityTypeEnum.ContactUsRecaptcha:
      return <RecaptchaSettings entity={entity as RecaptchaInterface} />
    case EntityTypeEnum.Attachments:
      return <AttachmentsSettings entity={entity as AttachmentsInterface} />
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForBlog
