import React from 'react'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import ReactSelect, {
  ChangeOptionValue,
} from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useUpdateNestedProp, useUpdateProp } from '../hooks/useUpdateProps'
import HtmlAttrId from '../options/HtmlAttrId'
import FlexAlignStyle from '../styles/FlexAlignStyle'

const captchaThemeChoices = [
  {
    label: 'light',
    value: 'light',
  },
  {
    label: 'dark',
    value: 'dark',
  },
]

const getCaptchaThemeOption = (theme: string) => {
  const currentCaptchaTheme = captchaThemeChoices.find(
    captchaTheme => captchaTheme.label === theme,
  )
  if (currentCaptchaTheme) {
    return currentCaptchaTheme
  } else return captchaThemeChoices[0]
}

function RecaptchaSettings({ entity }: { entity: RecaptchaInterface }) {
  const { isMobile } = useDeviceModeContext()
  const updateNestedProp = useUpdateNestedProp(entity)
  const updateProp = useUpdateProp(entity)
  function updateCaptchaTheme(option: ChangeOptionValue<string>) {
    if (option) {
      const update = updateProp('theme')
      update(option.value)
    }
  }
  return (
    <>
      <GroupTitle>settings_options.captcha.help_message</GroupTitle>
      <FlexAlignStyle
        align={entity.alignSelf}
        mobileAlign={entity.mobileAlignSelf}
        update={updateProp('alignSelf')}
        mobileUpdate={updateProp('mobileAlignSelf')}
      />
      <ReactSelect<string, false>
        labelText="captcha theme"
        options={captchaThemeChoices}
        selectedOption={
          entity.theme
            ? getCaptchaThemeOption(entity.theme)
            : captchaThemeChoices[0]
        }
        labelIcon={isMobile ? <MobileIconUi /> : undefined}
        update={updateCaptchaTheme}
        isMulti={false}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default RecaptchaSettings
