import PropTypes from 'prop-types'
import React, { forwardRef, useRef, useState, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { entityBorderColors } from 'client/constants/editorSettings'
import ActionsBoxItem from './ActionsBoxItem'
import ActionsBoxItemUi from './ui/ActionsBoxItemUi'
import ActionsBoxMasterIconUi from './ui/ActionsBoxMasterIconUi'
import ActionsBoxUi from './ui/ActionsBoxUi'

const maxOffset = 100

function ActionsBox(props, ref) {
  const {
    backgroundColor,
    remove,
    copy,
    toggleSettings,
    toggleSaveDialog,
    type,
    isSaveAllowed,
    isRemoveAllowed,
    isCopyAllowed,
    isMasterBlock,
    isMoveDownAllowed,
    isMoveUpAllowed,
    moveDown,
    moveUp,
  } = props
  const boxRef = useRef()
  const copyButtonRef = useRef()
  const removeButtonRef = useRef()
  const saveButtonRef = useRef()
  const settingsButtonRef = useRef()
  const { t } = useTranslation()
  const [outsideTop, setOutsideTop] = useState(true)
  const [isTitleVisible, setShowTitle] = useState(true)

  useImperativeHandle(ref, () => {
    if (boxRef.current) {
      let rect = boxRef.current.getBoundingClientRect()
      if (rect.top < maxOffset) {
        setOutsideTop(false)
      }

      if (rect.width === 0) {
        rect = boxRef.current.parentElement.getBoundingClientRect()
      }

      if (rect.width < 200) {
        setShowTitle(false)
      }
    }

    return {
      box: boxRef.current,
      copyButton: copyButtonRef.current,
      removeButton: removeButtonRef.current,
      saveButton: saveButtonRef.current,
      settingsButton: settingsButtonRef.current,
    }
  })

  const safeRemove = () =>
    confirm(
      t(
        isMasterBlock
          ? 'components.core.base_entity.action_box.safe_remove_master'
          : 'components.core.base_entity.action_box.safe_remove',
      ),
    ) && remove()

  return (
    <div ref={boxRef}>
      <ActionsBoxUi
        backgroundColor={backgroundColor}
        outsideTop={outsideTop}
        right
      >
        <ActionsBoxItem
          backgroundColor={backgroundColor}
          className="fas fa-cog"
          onClick={toggleSettings}
          ref={settingsButtonRef}
        >
          {t('components.core.actions.settings')}
        </ActionsBoxItem>
        {copy && isCopyAllowed && (
          <ActionsBoxItem
            backgroundColor={backgroundColor}
            className="far fa-copy"
            onClick={copy}
            ref={copyButtonRef}
          >
            {t('components.core.actions.copy')}
          </ActionsBoxItem>
        )}
        {!isMasterBlock && isSaveAllowed && (
          <ActionsBoxItem
            backgroundColor={backgroundColor}
            className="fas fa-save"
            onClick={toggleSaveDialog}
            ref={saveButtonRef}
          >
            {t('components.core.actions.save')}
          </ActionsBoxItem>
        )}
        {remove && isRemoveAllowed && (
          <ActionsBoxItem
            backgroundColor={backgroundColor}
            className="far fa-trash-alt"
            onClick={safeRemove}
            ref={removeButtonRef}
          >
            {t('components.core.actions.remove')}
          </ActionsBoxItem>
        )}
      </ActionsBoxUi>
      <ActionsBoxUi
        backgroundColor={backgroundColor}
        outsideTop={outsideTop}
        left
      >
        {isTitleVisible && (
          <ActionsBoxItemUi onClick={toggleSettings}>
            {t(`entity_settings.${type.toLowerCase()}.header`)}{' '}
            {isMasterBlock && (
              <ActionsBoxMasterIconUi
                title={t('components.core.actions.is_master')}
              />
            )}
          </ActionsBoxItemUi>
        )}
        {isMoveDownAllowed && (
          <ActionsBoxItemUi>
            <i onClick={moveDown} className="fas fa-arrow-down" />
          </ActionsBoxItemUi>
        )}
        {isMoveUpAllowed && (
          <ActionsBoxItemUi>
            <i onClick={moveUp} className="fas fa-arrow-up" />
          </ActionsBoxItemUi>
        )}
      </ActionsBoxUi>
    </div>
  )
}

const ForwardedActionsBox = forwardRef(ActionsBox)

ForwardedActionsBox.propTypes = {
  type: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  isSaveAllowed: PropTypes.bool.isRequired,
  isRemoveAllowed: PropTypes.bool,
  isCopyAllowed: PropTypes.bool,
  isMoveDownAllowed: PropTypes.bool.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  toggleSaveDialog: PropTypes.func.isRequired,
  toggleSettings: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
    .isRequired,
  copy: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  remove: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  isMasterBlock: PropTypes.bool.isRequired,
  moveDown: PropTypes.func.isRequired,
  moveUp: PropTypes.func.isRequired,
}

ForwardedActionsBox.defaultProps = {
  isSaveAllowed: false,
  isRemoveAllowed: false,
  isCopyAllowed: false,
  backgroundColor: entityBorderColors.orange,
}

export default ForwardedActionsBox
