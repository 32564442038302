import styled from 'styled-components'

const MenuItemInputUi = styled.input`
  width: 100%;
  height: 38px;
  border: solid 1px hsl(0, 0%, 80%);
  border-radius: 3px;
  color: #5c687e;
  text-indent: 10px;
  font-size: 12px;
  font-family: AvertaPE, Helvetica, Arial, sans-serif;
  outline: none;
  &::placeholder {
    color: #9ba9b4;
  }
`

export default MenuItemInputUi
