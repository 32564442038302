import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import {
  getBlogCategoryReplacement,
  getBlogPageReplacement,
  getBlogPostReplacement,
  isBlogPageUrlPlaceholder,
  isBlogPostUrlPlaceholder,
} from 'common/utils/blogReplacementsUtils'
import { blogSelectors, useBlog } from 'client/store'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    width: 200,
    borderBottom: '1px dotted pink',
    padding: 3,
  }),
  control: () => ({
    height: 28,
    minHeight: 28,
    width: 200,
    display: 'flex',
    fontSize: 13,
    alignItems: 'center',
  }),
  option: (provided: any) => ({
    ...provided,
    fontSize: 12,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: 25,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#fff',
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#fff',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#fff',
  }),
  indicatorsContainer: () => ({}),
  dropdownIndicator: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 28,
    color: '#fff',
  }),
}

const formatGroupLabel = (data: GroupType) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    {/*@ts-ignore*/}
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

type GroupType = {
  label: string
  options: OptionType[]
}

type OptionType = {
  value: string
  label: string
}

export default function LinkSourceBlog({
  url,
  update,
}: {
  url?: string
  update: (url: string) => void
}) {
  const { t } = useTranslation()
  const blogPages = useBlog(blogSelectors.getBlogPages)
  const blogPosts = useBlog(blogSelectors.getBlogPosts)
  const blogCategories = useBlog(blogSelectors.getBlogCategories)

  let valueOption
  if (url) {
    const urlPlaceholder = url.replace(/\d+/g, ' ')

    if (isBlogPageUrlPlaceholder(urlPlaceholder)) {
      const page = blogPages.find(
        page => getBlogPageReplacement(page.id) === url,
      )
      if (page) {
        valueOption = {
          label: page.name,
          value: getBlogPageReplacement(page.id),
        }
      }
    } else if (isBlogPostUrlPlaceholder(urlPlaceholder)) {
      const postPage = blogPosts.find(
        postPage => getBlogPostReplacement(postPage.id) === url,
      )
      if (postPage) {
        valueOption = {
          label: postPage.name,
          value: getBlogPostReplacement(postPage.id),
        }
      }
    } else {
      const category = blogCategories.find(
        category => getBlogCategoryReplacement(category.id) === url,
      )
      if (category) {
        valueOption = {
          label: category.name,
          value: getBlogCategoryReplacement(category.id),
        }
      }
    }
  }

  function setBlogLink(option: OptionType | null) {
    if (option) {
      update(option.value)
    }
  }

  const groupedOptions = [
    {
      label: t('entity_settings.button.blog_link.pages_group_title'),
      options: blogPages.map(page => ({
        label: page.name,
        value: getBlogPageReplacement(page.id),
      })),
    },
    {
      label: t('entity_settings.button.blog_link.categories_group_title'),
      options: blogCategories.map(category => ({
        label: category.name,
        value: getBlogCategoryReplacement(category.id),
      })),
    },
    {
      label: t('entity_settings.button.blog_link.posts_group_title'),
      options: blogPosts.map(postPage => ({
        label: postPage.name,
        value: getBlogPostReplacement(postPage.id),
      })),
    },
  ]

  return (
    <Select
      placeholder={t('entity_settings.button.blog_link.choose_blog_source')}
      styles={customStyles}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      onChange={setBlogLink}
      value={valueOption}
    />
  )
}
