import usePresentPage from 'client/hooks/usePresentPage'
import { getSeo } from 'client/reducers/pageReducer'
import React from 'react'
import { Input } from '../../components'
import useUpdateSeoProperty from '../../hooks/useUpdateSeoProperty'
import GroupTitle from '../../styleComponents/GroupTitle'

function Title() {
  const seo = usePresentPage(getSeo)
  const updateSeoProperty = useUpdateSeoProperty()

  return (
    <React.Fragment>
      <GroupTitle>page_settings.seo.title.label</GroupTitle>
      <Input update={updateSeoProperty('title')} value={seo.title} />
      <br />
    </React.Fragment>
  )
}

export default Title
