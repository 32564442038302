import React, { createContext, useContext } from 'react'

type CreateEntityElement = (value: string) => void

type CreateEntityElementContextType = {
  createEntityElement: CreateEntityElement
  excludedEntitiesIds: string[]
}
const CreateEntityElementContext = createContext(
  {} as CreateEntityElementContextType,
)

export const useCreateEntityElement = () =>
  useContext(CreateEntityElementContext)

type CreateEntityProviderProps = {
  createEntityElement: (value: string) => void
  children: React.ReactChildren
  excludedEntitiesIds: string[]
}

export function CreateEntityProvider({
  createEntityElement,
  children,
  excludedEntitiesIds,
}: CreateEntityProviderProps) {
  return (
    <CreateEntityElementContext.Provider
      value={{ createEntityElement, excludedEntitiesIds }}
    >
      {children}
    </CreateEntityElementContext.Provider>
  )
}
