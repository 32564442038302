import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Overlay from 'common/components/core/Overlay'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { useActions } from 'common/hooks/useActions'
import * as actions from 'client/actions/actionsManagement'
import * as entityActions from 'client/actions/entityActions'
import useManagement from 'client/hooks/useManagement'
import { createPage } from 'client/pages'
import { isSettingsOverlayVisible } from 'client/reducers/managementReducer'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import OldEntitySettings from './OldEntitySettings'
import {
  useOverlayVisibilityContext,
  useOverlayActionsContext,
} from './SettingsOverlayContext'
import useRemoveEntityMobileStyle from './hooks/useRemoveEntityMobileStyle'
import useRemoveEntityStyle from './hooks/useRemoveEntityStyle'
import useUpdateEntityStyle from './hooks/useUpdateEntityStyle'
import SettingsBackUi from './ui/SettingsBackUi'
import SettingsContainerIconsUi from './ui/SettingsContainerIconsUi'
import SettingsContainerUi from './ui/SettingsContainerUi'
import SettingsMasterEntityIconUi from './ui/SettingsMasterEntityIconUi'
import SettingsTitleIconUi from './ui/SettingsTitleIconUi'
import SettingsTitleUi from './ui/SettingsTitleUi'
import { isLockedDescendant } from './utils/isLockedDescendant'

function EntitySettings({ entity }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isColorPickerOverlay = useManagement(isSettingsOverlayVisible)
  const isOverlayVisible = useOverlayVisibilityContext()
  const { close, toggle } = useOverlayActionsContext()
  const updateEntityStyle = useUpdateEntityStyle(dispatch, entity)
  const removeEntityStyle = useRemoveEntityStyle(dispatch, entity)
  const removeEntityMobileStyle = useRemoveEntityMobileStyle(dispatch, entity)
  const moveDown = useActions(entityActions.moveDown)
  const moveUp = useActions(entityActions.moveUp)

  function handleMoveUp(e) {
    e.stopPropagation()
    moveUp(entity)
  }

  function handleMoveDown(e) {
    e.stopPropagation()
    moveDown(entity)
  }

  const [showCreateBlockDialog, togglePickerOverlay, toggleSettings] =
    useActions([
      actions.showCreateBlockDialog,
      actions.toggleSettingsOverlay,
      actions.toggleSettings,
    ])
  const copy = useActions(entityActions.copy)
  const remove = useActions(entityActions.remove)
  const pageType = usePage(pageSelectors.getPageType)
  const parentEntity = usePage(page =>
    pageSelectors.getEntityById(page, entity.parentId),
  )
  const [, , createEntitySettings] = createPage(pageType)
  const isMasterBlock = Boolean(entity.masterBlockId)
  const isLocked =
    parentEntity && isLockedDescendant(entity.type, parentEntity.type)
  const isSaveAllowed = entity.type !== structureTypes.POPUP && !isMasterBlock
  const isCopyAllowed =
    entity.type !== structureTypes.POPUP && !entity.isMasterBlockRoot
  const isRemoveAllowed = entity.type !== structureTypes.POPUP

  const safeRemove = () =>
    confirm(
      t(
        entity.isMasterBlock
          ? 'components.core.base_entity.action_box.safe_remove_master'
          : 'components.core.base_entity.action_box.safe_remove',
      ),
    ) && remove(entity)

  const hide = () => {
    close()
    togglePickerOverlay(false)
  }
  return (
    <React.Fragment>
      <SettingsTitleUi>
        <SettingsBackUi onClick={() => toggleSettings(null)}>←</SettingsBackUi>
        {t(`entity_settings.${entity.type}.header`.toLowerCase())}{' '}
        {entity.masterBlockId && (
          <SettingsMasterEntityIconUi
            title={t('entity_settings.master_block_warning')}
          />
        )}
        <SettingsContainerIconsUi>
          {entity.type !== structureTypes.POPUP && (
            <SettingsTitleIconUi
              className="fas fa-arrow-up"
              onClick={handleMoveUp}
            />
          )}
          {entity.type !== structureTypes.POPUP && (
            <SettingsTitleIconUi
              className="fas fa-arrow-down"
              onClick={handleMoveDown}
            />
          )}
          {!isLocked && (
            <>
              {isCopyAllowed && (
                <SettingsTitleIconUi
                  className="far fa-copy"
                  onClick={() => copy(entity)}
                />
              )}
              {isSaveAllowed && (
                <SettingsTitleIconUi
                  className="far fa-save"
                  onClick={() => showCreateBlockDialog(entity.id)}
                />
              )}
              {isRemoveAllowed && (
                <SettingsTitleIconUi
                  className="far fa-trash-alt"
                  onClick={safeRemove}
                />
              )}
            </>
          )}
        </SettingsContainerIconsUi>
      </SettingsTitleUi>
      <SettingsContainerUi>
        {entity.type in EntityTypeEnum ||
        entity.type === EntityTypeEnum.Carousel ||
        entity.type === EntityTypeEnum.CarouselItem ? (
          createEntitySettings(entity)
        ) : (
          <OldEntitySettings
            entity={entity}
            isOverlayActive={isOverlayVisible}
            toggleSettingsOverlay={toggle}
            updateEntityStyle={updateEntityStyle}
            removeEntityStyle={removeEntityStyle}
            removeEntityMobileStyle={removeEntityMobileStyle}
          />
        )}

        {(isOverlayVisible || isColorPickerOverlay) && (
          <Overlay hide={hide} zIndex={30} />
        )}
      </SettingsContainerUi>
    </React.Fragment>
  )
}

EntitySettings.propTypes = {
  entity: PropTypes.any.isRequired,
}

export default EntitySettings
