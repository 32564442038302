import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import { isRgba } from '../../../../common/utils/colorHelpers'

export const createLinkAndGetEntityKey = editorState => {
  const contentState = editorState.getCurrentContent()
  const data = { url: '' }
  const contentStateWithEntity = contentState.createEntity(
    'LINK',
    'MUTABLE',
    data,
  )

  return contentStateWithEntity.getLastCreatedEntityKey()
}

export const getRawContentState = editorState =>
  JSON.stringify(convertToRaw(editorState.getCurrentContent()))

export const createStateFromRaw = (rawContentState, decorator) =>
  EditorState.createWithContent(
    convertFromRaw(JSON.parse(rawContentState)),
    decorator,
  )

export const createLink = targetEditorState => {
  const linkKey = createLinkAndGetEntityKey(targetEditorState)

  const editorState = RichUtils.toggleLink(
    targetEditorState,
    targetEditorState.getSelection(),
    linkKey,
  )

  return { linkKey, editorState }
}

export const removeLink = (editorState, linkEntityKey) => {
  const selection = editorState.getSelection()
  const anchorKey = selection.getAnchorKey()
  const blockWithLinkAtBeginning = editorState
    .getCurrentContent()
    .getBlockForKey(anchorKey)

  let updatedEditorState = null

  blockWithLinkAtBeginning.findEntityRanges(
    character => character.getEntity() === linkEntityKey,
    (start, end) => {
      // removing link from editorState
      const editorStateWithSelectionAndWithoutLink = RichUtils.toggleLink(
        editorState,
        selection.merge({
          anchorOffset: start,
          focusOffset: end,
        }),
        null,
      )

      updatedEditorState = EditorState.forceSelection(
        editorStateWithSelectionAndWithoutLink,
        selection, // old selection
      )
    },
  )

  return updatedEditorState
}

export const getEntityData = (editorState, key) =>
  editorState
    .getCurrentContent()
    .getEntity(key)
    .getData()

export const getSelectionEntityKeyOrNull = editorState => {
  const selection = editorState.getSelection()
  const anchorOffset = selection.getAnchorOffset()
  const anchorKey = selection.getAnchorKey()
  const contentState = editorState.getCurrentContent()
  const blockWithLinkAtBeginning = contentState.getBlockForKey(anchorKey)

  return blockWithLinkAtBeginning.getEntityAt(anchorOffset)
}

export const toggleColor = (editorState, toggledColor) => {
  const selection = editorState.getSelection()
  const colorsNested = editorState
    .getCurrentContent()
    .getBlockMap()
    .toArray()
    .map(block =>
      block
        .getCharacterList()
        .toArray()
        .map(c =>
          c
            .getStyle()
            .toArray()
            .filter(isRgba),
        ),
    )

  const flatten = list =>
    list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), [])

  const flattenColors = flatten(colorsNested)
  // Let's just allow one color at a time. Turn off all active colors.
  const nextContentState = flattenColors.reduce(
    (contentState, color) =>
      Modifier.removeInlineStyle(contentState, selection, color),
    editorState.getCurrentContent(),
  )

  let nextEditorState = EditorState.push(
    editorState,
    nextContentState,
    'change-inline-style',
  )

  const currentStyle = editorState.getCurrentInlineStyle()

  // Unset style override for current color.
  if (selection.isCollapsed()) {
    nextEditorState = currentStyle.reduce(
      (state, color) => RichUtils.toggleInlineStyle(state, color),
      nextEditorState,
    )
  }

  // If the color is being toggled on, apply it.
  if (!currentStyle.has(toggledColor)) {
    nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor)
  }

  return nextEditorState
}

export const getCurrentColor = editorState =>
  editorState
    .getCurrentInlineStyle()
    .filter(isRgba)
    .first()

export const applyCustomStyle = inlineStyle => {
  const color = inlineStyle.filter(isRgba).first()

  return (color && { color }) || null
}

const getPopupIdFromChatEntityData = editorState => char =>
  !!char.getEntity() && getEntityData(editorState, char.getEntity()).popupId

export const getAllPopupIdsFromRaw = rawContentState => {
  const editorState = createStateFromRaw(rawContentState)
  const contentState = editorState.getCurrentContent()

  return contentState
    .getBlockMap()
    .map(block =>
      [
        ...new Set(
          block
            .getCharacterList()
            .map(getPopupIdFromChatEntityData(editorState))
            .toArray(),
        ),
      ].filter(Boolean),
    )
    .toArray()
    .reduce((acc, val) => acc.concat(val), [])
}

export const getCurrentBlockType = editorState => {
  const selection = editorState.getSelection()
  return editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
}

export const selectAnchorWord = editorState => {
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const anchorOffset = selection.getAnchorOffset()
  const focusOffset = selection.getFocusOffset()
  const blockKey = selection.getStartKey()
  const block = contentState.getBlockForKey(blockKey)
  let newAnchorOffset = anchorOffset
  let newFocusOffset = focusOffset
  block.findEntityRanges(
    () => {
      return true
    },
    (start, end) => {
      if (anchorOffset >= start && anchorOffset <= end) {
        newAnchorOffset = start
        newFocusOffset = end
      }
    },
  )
  const newSelectionState = SelectionState.createEmpty(blockKey)
    .set('focusOffset', newFocusOffset)
    .set('anchorOffset', newAnchorOffset)

  return EditorState.forceSelection(editorState, newSelectionState)
}

export const selectCurrentWord = editorState => {
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const startOffset = selection.getStartOffset()
  const endOffset = selection.getEndOffset()
  const blockKey = selection.getStartKey()
  const block = contentState.getBlockForKey(blockKey)

  if (startOffset === endOffset) {
    const word = getWordByPosition(block.getText(), startOffset)
    const anchorOffset = block.getText().indexOf(word)
    const newSelectionState = SelectionState.createEmpty(blockKey)
      .set('focusOffset', anchorOffset + word.length)
      .set('anchorOffset', anchorOffset)

    return EditorState.forceSelection(editorState, newSelectionState)
  }

  return editorState
}

function getWordByPosition(text, position) {
  let char
  let forthWordPart = ''
  let forthPosition = position

  const charTrueAndNotWhiteSpace = char => char && char !== ' '

  do {
    char = text.charAt(forthPosition)
    forthPosition++
    if (charTrueAndNotWhiteSpace(char)) {
      forthWordPart = forthWordPart + char
    }
  } while (charTrueAndNotWhiteSpace(char))

  let backPosition = position
  let backWordPart = ''
  do {
    --backPosition
    char = text.charAt(backPosition)
    if (charTrueAndNotWhiteSpace(char)) {
      backWordPart = backWordPart + char
    }
  } while (charTrueAndNotWhiteSpace(char))

  return (
    backWordPart
      .split('')
      .reverse()
      .join('') + forthWordPart
  )
}
