import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { changeLocale } from 'client/actions'
import { localeNames } from 'common/constants/localeTypes'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import Select from 'client/components/core/Sidebar/components/Settings/components/Select'

const LanguageSelector = ({ changeLocaleConnected, locale }) => (
    <div>
      <GroupTitle>language</GroupTitle>
      <Select
        update={changeLocaleConnected}
        choiceList={localeNames}
        labelText="choose language"
        value={locale}
        needTranslate={true}
      />
    </div>
)


LanguageSelector.propTypes = {
  changeLocaleConnected: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
}

const mapStateToProps = ({
  page: {
    present: { locale },
  },
}) => ({ locale })
const mapDispatchToProps = { changeLocaleConnected: changeLocale }

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
