import React from 'react'
import { useTranslation } from 'react-i18next'
import { GridChildComponentProps, FixedSizeGrid as Grid } from 'react-window'
import {
  defaultColumnCount,
  defaultColumnWidth,
  defaultRowHeight,
  defaultGridWidth,
  getRowCount,
  getGridHeight,
  getIconIndex,
} from 'common/constants/iconList'
import { useIconsContext } from 'client/context/IconsContext'
import IconListUi from './ui/IconListUi'
import LinkIconUi from './ui/LinkIconUi'
import LinkUi from './ui/LinkUi'

type RenderIconProps = {
  select: (value: string) => void
  iconList: string[]
}

const renderIcon =
  ({ select, iconList }: RenderIconProps) =>
  ({ columnIndex, rowIndex, style }: GridChildComponentProps) => {
    const index = getIconIndex(columnIndex, rowIndex, defaultColumnCount)
    const currentIconClassName = iconList[index]

    return (
      <>
        {currentIconClassName ? (
          // @ts-ignore
          <div style={style as React.CSSProperties}>
            <LinkUi onClick={() => select(currentIconClassName)}>
              <LinkIconUi className={currentIconClassName} />
            </LinkUi>
          </div>
        ) : (
          <div />
        )}
      </>
    )
  }

function filterBySearchValue(searchValue: string) {
  return (filterable: string) => {
    return !searchValue.length || filterable.indexOf(searchValue) > -1
  }
}

type IconListProps = {
  popularIconsList?: string[]
  select: (value: string) => void
  searchValue: string
}

const IconList = ({
  popularIconsList = [],
  select,
  searchValue,
}: IconListProps) => {
  const { t } = useTranslation()
  const icons = useIconsContext()
  const allIconsFilteredList = icons.filter(filterBySearchValue(searchValue))
  const isPopularIconsList = popularIconsList.length > 0

  return (
    <IconListUi>
      {isPopularIconsList && (
        <>
          <span>{t('entity_options.icon_picker.popular_icons')}</span>
          <Grid
            className="Grid"
            columnCount={defaultColumnCount}
            columnWidth={defaultColumnWidth}
            height={getGridHeight(popularIconsList.length, defaultColumnCount)}
            rowCount={getRowCount(popularIconsList.length, defaultColumnCount)}
            rowHeight={defaultRowHeight}
            width={defaultGridWidth}
          >
            {renderIcon({
              select,
              iconList: popularIconsList,
            })}
          </Grid>
        </>
      )}
      {allIconsFilteredList.length > 0 && (
        <>
          <span>{t('entity_options.icon_picker.all_icons')}</span>
          <Grid
            className="Grid"
            columnCount={defaultColumnCount}
            columnWidth={defaultColumnWidth}
            height={250}
            rowCount={getRowCount(
              allIconsFilteredList.length,
              defaultColumnCount,
            )}
            rowHeight={defaultRowHeight}
            width={defaultGridWidth}
          >
            {renderIcon({
              select,
              iconList: allIconsFilteredList,
            })}
          </Grid>
        </>
      )}
    </IconListUi>
  )
}

export default IconList
