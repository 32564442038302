import styled from 'styled-components'

const CustomFontPickerUploadContainerUi = styled.div`
  display: flex;
  align-items: center;
  width: 20%;
  align-self: center;
`

export default CustomFontPickerUploadContainerUi
