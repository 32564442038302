import { fetchIcons } from 'publisher/api/page'
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  memo,
} from 'react'

export const IconsContext = createContext<string[]>([])

export const useIconsContext = () => useContext(IconsContext)

function IconsProvider(props: React.PropsWithChildren<{}>) {
  const [icons, setIcons] = useState<string[]>([])

  useEffect(() => {
    async function fetchIconList() {
      const { data: icons } = await fetchIcons()
      setIcons(icons)
    }
    fetchIconList()
  }, [])

  return (
    <IconsContext.Provider value={icons}>
      {props.children}
    </IconsContext.Provider>
  )
}

export default memo(IconsProvider)
