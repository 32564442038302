import styled from 'styled-components'

const AngleInputUi = styled.input`
  border: none;
  text-align: center;
  width: 48px;
  color: #0c0c09;
  background: inherit;
`

export default AngleInputUi
