import styled from 'styled-components'

const AutomationRuleCloseButtonContainerUi = styled.div`
  svg {
    fill: #cbd5e1;

    &:hover {
      cursor: pointer;
      fill: #142d63;
    }
    &:focus {
      fill: #142d63;
    }
  }
`

export default AutomationRuleCloseButtonContainerUi
