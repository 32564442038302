import styled from 'styled-components'

const BlockImageWrapperUi = styled.div`
  width: 100%;
  height: auto;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    max-height: ${p => (p.isMobile ? '300px' : '100%')};
    max-width: ${p => (p.isMobile ? '150px' : '100%')};
  }
`

export default BlockImageWrapperUi
