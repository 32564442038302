import styled from 'styled-components'

const CreateBlogDialogHeaderUi = styled.div`
  text-align: center;
  font-size: 12px;
  color: #797f89;
  padding: 6px 0;
  text-transform: uppercase;
`

export default CreateBlogDialogHeaderUi
