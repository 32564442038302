import React, { useState, createContext, useContext } from 'react'

const OverlayVisibilityContext = createContext()
const OverlayToggleContext = createContext()

export const useOverlayVisibilityContext = () => useContext(OverlayVisibilityContext)
export const useOverlayActionsContext = () => useContext(OverlayToggleContext)

const SettingsOverlayContextProvider = ({ children }) => {
  const [isOverlayVisible, setOverlayVisible] = useState(false)

  return (
    <OverlayVisibilityContext.Provider value={isOverlayVisible}>
      <OverlayToggleContext.Provider value={{
        close: () => setOverlayVisible(false),
        show: () => setOverlayVisible(true),
        toggle: val => setOverlayVisible(val),
      }}>
        {children}
      </OverlayToggleContext.Provider>
    </OverlayVisibilityContext.Provider>
  )
}

export default SettingsOverlayContextProvider
