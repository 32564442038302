import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import WebinarRegistrationDateInterface from 'common/types/entities/WebinarRegistrationDateInterface'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import WebinarRegistrationDate from './entities/WebinarRegistrationDate'

export default function createEntityElementForWebinarRegistration(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.WebinarRegistrationDate:
      return (
        <WebinarRegistrationDate
          entity={entity as WebinarRegistrationDateInterface}
          {...additionalProps}
        />
      )
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
