import React, { useState } from 'react'
import { gradientPaletteInterface } from '..'
import useDragging from './useDragging'

type useGradientColorPickerDragging = {
  stop: gradientPaletteInterface
  limits: { min: number; max: number; drop: number }
  onPosChange: ({ id, offset }: { id: number; offset: number }) => void
  onDragStart: (id: number) => void
  onDragEnd: ({ id, offset }: { id: number; offset: number }) => void
  initialPos?: number
}

const limitPos = (offset: number, min: number, max: number) =>
  Math.max(Math.min(offset, max), min)

const useGradientColorPickerDragging = ({
  limits,
  stop,
  initialPos,
  onPosChange,
  onDragStart,
  onDragEnd,
}: useGradientColorPickerDragging) => {
  const [posStart, setPosStart] = useState(initialPos ? initialPos : 0)

  const handleDrag = ({ clientX }: { clientX: number }) => {
    const { id, offset } = stop
    const { min, max } = limits
    // Limit movements
    const dragOffset = offset - posStart
    const limitedPos = limitPos(dragOffset + clientX, min, max)
    onPosChange({ id, offset: limitedPos })
  }

  const handleEndDrag = ({ clientX }: { clientX: number }) => {
    const { id, offset } = stop
    const { min, max } = limits
    // Limit movements
    const dragOffset = offset - posStart
    const limitedPos = limitPos(dragOffset + clientX, min, max)
    onDragEnd({ id, offset: limitedPos })
  }

  const drag = useDragging({
    onDragStart: ({ clientX }) => {
      setPosStart(clientX)
      onDragStart(stop.id)
    },
    onDrag: handleDrag,
    onDragEnd: handleEndDrag,
  })

  return drag
}

export default useGradientColorPickerDragging
