import React from 'react'
import { Appearance } from 'common/types/entities/EntityInterface'
import WebinarSessionDateTimeInterface from 'common/types/entities/WebinarSessionDateTimeInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import {
  useUpdateProp,
  useUpdateEntity,
} from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearance from '../../../components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../../../components/core/Sidebar/components/Settings/options/HtmlAttrId'
import { ShadowStyle } from '../../../components/core/Sidebar/components/Settings/styles'
import FontSize from '../../../components/core/Sidebar/components/Settings/styles/FontSize'

function WebinarSessionDateTimeSettings({
  entity,
}: {
  entity: WebinarSessionDateTimeInterface
}) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<WebinarSessionDateTimeInterface>()

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  return (
    <>
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <FontSize
        update={updateProp('iconFontSize')}
        mobileUpdate={updateProp('mobileIconFontSize')}
        fontSize={entity.iconFontSize}
        mobileFontSize={entity.mobileIconFontSize}
        labelText="icon size"
      />
      <TextFontUpdater<WebinarSessionDateTimeInterface>
        entity={entity}
        update={updateEntity}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="settings_styles.background_color.label"
      />
      <ColorPicker
        label="content color"
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <Border
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
        border={isMobile ? entity.mobileBorder : entity.border}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default WebinarSessionDateTimeSettings
