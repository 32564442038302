import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import GroupTitle from '../styleComponents/GroupTitle'
import MobileIconUi from '../styles/ui/MobileIconUi'

const PADDING_LEFT = 3
const PADDING_RIGHT = 1
const PADDING_TOP = 0
const PADDING_BOTTOM = 2

function PaddingStyle({ padding, mobilePadding, ...restProps }) {
  const { isMobile } = useDeviceModeContext()
  const currentPadding = isMobile ? mobilePadding : padding
  const update = isMobile ? restProps.mobileUpdate : restProps.update

  const getPadding = position => {
    const padding = currentPadding.split(' ')

    return parseInt(padding[position], 10)
  }

  const change = positions => value => {
    const padding = currentPadding.split(' ')
    const calculatedValue = value < 0 ? 0 : value

    positions.forEach(position => {
      padding[position] = `${+calculatedValue}px`
    })

    update(padding.join(' '))
  }

  return (
    <div>
      <GroupTitle>settings_styles.padding.label</GroupTitle>
      <Range
        labelText="Top"
        labelIcon={isMobile && <MobileIconUi />}
        value={getPadding(PADDING_TOP)}
        update={change([PADDING_TOP])}
        max={300}
        allowNegative={false}
      />
      <Range
        labelText="Bottom"
        labelIcon={isMobile && <MobileIconUi />}
        value={getPadding(PADDING_BOTTOM)}
        update={change([PADDING_BOTTOM])}
        max={300}
        allowNegative={false}
      />
      <Range
        labelText="left/right"
        labelIcon={isMobile && <MobileIconUi />}
        value={getPadding(PADDING_LEFT)}
        update={change([PADDING_LEFT, PADDING_RIGHT])}
        max={300}
        allowNegative={false}
      />
    </div>
  )
}

PaddingStyle.propTypes = {
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
  padding: PropTypes.string,
  mobilePadding: PropTypes.string,
}

PaddingStyle.defaultProps = {
  padding: '0px 0px 0px 0px',
  mobilePadding: '0px 0px 0px 0px',
}

export default memo(PaddingStyle)
