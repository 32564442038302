import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuTargetEnum } from 'common/components/entities/Menu/enums/MenuTargetEnum'
import MenuInterface, {
  MenuItemInterface,
} from 'common/components/entities/Menu/types/MenuInterface'
import { hasSubMenu } from 'common/components/entities/Menu/utils/hasSubMenu'
import BlackButton from 'client/components/core/BlackButton'
import { Switcher } from 'client/components/core/Sidebar/components/Settings/components'
import BaseMenuItem from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/BaseMenuItem'
import MenuItemTitle from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/MenuItemTitle'
import MenuItemChevronUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemChevronUi'
import MenuItemControlsUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemControlsUi'
import MenuItemDetailsUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemDetailsUi'
import MenuItemLabelUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemLabelUi'
import MenuItemUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemUi'
import MenuSubItemUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuSubItemUi'
import MenuSubItemsWrapperUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuSubItemsWrapperUi'
import { useUpdateMenuItem } from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/hooks/useUpdateMenuItem'

interface MenuItemFlatProps {
  index: number
  item: MenuItemInterface
  entity: MenuInterface
  isAllowedBlogLinks: boolean
  itemsLength: number
}

const MenuItem = ({
  index,
  item,
  entity,
  isAllowedBlogLinks,
  itemsLength,
}: MenuItemFlatProps) => {
  const {
    updateMenuItem,
    removeMenuItem,
    updateSubMenuItem,
    removeSubMenuItem,
    addSubMenuItem,
    sortDown,
    sortUp,
  } = useUpdateMenuItem({ entity })

  const { t } = useTranslation()

  const updateItem = updateMenuItem(index)

  const updateText = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateItem({ ...item, text: e.target.value })

  const isSubMenuVisible = entity.menuItems[index].subMenuOpen || false

  const updateIsOpen = (isOpen: boolean) =>
    updateItem({ ...item, subMenuOpen: isOpen })

  const menuNumber = index + 1

  return (
    <MenuItemUi>
      <MenuItemDetailsUi>
        {hasSubMenu(item) ? (
          <MenuItemDetailsUi>
            <MenuItemTitle
              number={menuNumber}
              text={item.text}
              updateText={updateText}
              remove={() => removeMenuItem(index)}
            />
          </MenuItemDetailsUi>
        ) : (
          <BaseMenuItem
            item={item}
            number={menuNumber}
            updateText={updateText}
            updateLink={link => updateItem({ ...item, link })}
            remove={() => removeMenuItem(index)}
            isAllowedBlogLinks={isAllowedBlogLinks}
            updateTarget={e =>
              updateItem({
                ...item,
                target: e.target.checked
                  ? MenuTargetEnum._blank
                  : MenuTargetEnum._self,
              })
            }
          />
        )}
        <MenuItemLabelUi>
          <input
            onChange={() => {
              if (hasSubMenu(item)) {
                updateItem({
                  ...item,
                  subMenuItems: [],
                })
              } else {
                updateItem({
                  ...item,
                  link: '',
                  target: undefined,
                  subMenuItems: [
                    { text: '', link: '', target: MenuTargetEnum._self },
                  ],
                })
              }
            }}
            checked={hasSubMenu(item)}
            type="checkbox"
          />
          {t('entity_settings.menu.has_submenu')}
        </MenuItemLabelUi>
        {hasSubMenu(item) && (
          <Switcher
            wrapperStyle={{}}
            firstLabel="entity_settings.menu.item.emulate_drop_down_menu_opened"
            secondLabel="entity_settings.menu.item.emulate_drop_down_menu_closed"
            handleFirstButton={() => updateIsOpen(true)}
            handleSecondButton={() => updateIsOpen(false)}
            isFirstActive={isSubMenuVisible}
            isSecondActive={!isSubMenuVisible}
          />
        )}
        {hasSubMenu(item) && (
          <MenuSubItemsWrapperUi>
            {item.subMenuItems?.map((subMenuItem, subMenuItemIndex) => {
              const updateSubItem = updateSubMenuItem(index, subMenuItemIndex)

              return (
                <MenuSubItemUi key={subMenuItemIndex}>
                  <i
                    style={{ lineHeight: 'inherit' }}
                    className="fas fa-arrow-turn-down-right fa-lg"
                  />
                  <BaseMenuItem
                    item={subMenuItem}
                    number={subMenuItemIndex + 1}
                    updateText={e =>
                      updateSubItem({
                        ...subMenuItem,
                        text: e.target.value,
                      })
                    }
                    updateLink={link => updateSubItem({ ...subMenuItem, link })}
                    updateTarget={e =>
                      updateSubItem({
                        ...subMenuItem,
                        target: e.target.checked
                          ? MenuTargetEnum._blank
                          : MenuTargetEnum._self,
                      })
                    }
                    remove={() => removeSubMenuItem(index, subMenuItemIndex)}
                    isAllowedBlogLinks={isAllowedBlogLinks}
                  />
                </MenuSubItemUi>
              )
            })}
            <BlackButton onClick={() => addSubMenuItem(index)}>
              {t('entity_settings.menu.add_submenu_item')}
            </BlackButton>
          </MenuSubItemsWrapperUi>
        )}
      </MenuItemDetailsUi>
      <MenuItemControlsUi>
        {index !== 0 && (
          <MenuItemChevronUi
            className="fas fa-chevron-up"
            key="up"
            onClick={sortUp(index)}
          />
        )}
        {index + 1 !== itemsLength && (
          <MenuItemChevronUi
            className="fas fa-chevron-down"
            key="down"
            onClick={sortDown(index)}
          />
        )}
      </MenuItemControlsUi>
    </MenuItemUi>
  )
}

export default MenuItem
