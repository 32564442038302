import React from 'react'

function RemoveTagIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_329_10"
        maskUnits="userSpaceOnUse"
        x="1"
        y="4"
        width="39"
        height="32"
      >
        <path d="M40 4H1V36H40V4Z" fill="white" />
      </mask>
      <g mask="url(#mask0_329_10)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9777 35.1933L2.1244 18.4114C1.87552 18.078 1.74402 17.6822 1.74402 17.2665V6.36756C1.74402 5.85366 1.94487 5.37065 2.30956 5.0075C2.67504 4.64434 3.1601 4.44434 3.67539 4.44434H14.6206C15.2297 4.44512 15.8095 4.7364 16.1716 5.2235L25.6993 14.7115C23.7022 16.4246 22.5569 18.9107 22.5569 21.5333C22.5569 25.1348 24.7043 28.3815 28.0279 29.8048C27.8821 29.9588 27.7504 30.0934 27.6258 30.2175C25.2342 32.5983 22.2433 34.319 18.9777 35.1933ZM10.6665 9.72565C9.69996 9.72644 8.79147 10.1015 8.10832 10.7818C7.42517 11.462 7.04852 12.3667 7.04773 13.3291C7.04773 14.2911 7.42383 15.1964 8.10677 15.878C8.77975 16.5488 9.71187 16.934 10.6641 16.9348C11.633 16.9348 12.5434 16.5601 13.2277 15.8795C13.9118 15.1973 14.2882 14.2908 14.2875 13.3268C14.2867 12.3786 13.8999 11.4504 13.2261 10.7802C12.5424 10.1002 11.6334 9.72565 10.6665 9.72565Z"
          fill="#CBD5E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.1189 13.8608C27.8187 13.8608 24.4008 17.2105 24.4008 21.4251C24.4008 25.6397 27.8187 28.9895 32.1189 28.9895C36.4194 28.9895 39.8372 25.6397 39.8372 21.4251C39.8372 17.2105 36.4194 13.8608 32.1189 13.8608ZM36.5294 22.5057H33.2217H31.0163H27.7086V20.3446H31.0163H33.2217H36.5294V22.5057Z"
          fill="#CBD5E1"
        />
      </g>
    </svg>
  )
}

export default RemoveTagIcon
