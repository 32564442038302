import styled from 'styled-components'

const SettingsBackUi = styled.span`
  color: #414355;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
`

export default SettingsBackUi
