import React from 'react'
import CommonBlogPostDate from 'common/components/entities/BlogPostDate/BlogPostDateNew'
import { BlogPostDateInterface } from 'common/types/entities/BlogPostDateInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { typedPageSelectors, usePage, useBlogPost } from 'client/store'
import { blogPostSelectors } from 'client/store/blogPost/blogPostReducer'
import { EntityProps } from '../../../types'

function BlogPostDateNew({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogPostDateInterface>) {
  const blogPostDate = useBlogPost(blogPostSelectors.getBlogPostCreatedAt)
  const locale = usePage(typedPageSelectors.getPageLocale)

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonBlogPostDate
        date={blogPostDate as Date}
        locale={locale}
        attrId={entity.htmlAttrId}
        fontSize={entity.fontSize}
        fontFamily={entity.fontFamily}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        mobileFontSize={entity.mobileFontSize}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        color={entity.color}
        mobileColor={entity.mobileColor}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
      />
    </BaseEntityNew>
  )
}

export default BlogPostDateNew
