import styled from 'styled-components'

const FullBorderRadiusTypeContainerUi = styled.div`
  display: grid;
  grid-template-columns: 10px 10px;
  grid-template-rows: 10px 10px;
  gap: 5px;
  cursor: pointer;
`

export default FullBorderRadiusTypeContainerUi
