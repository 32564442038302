import { useCallback } from 'react'
import * as actions from 'client/actions/entityActions'

export default function useRemoveEntityStyle(dispatch, entity) {
  const updatedEntity = {
    ...entity,
    styles: {
      ...entity.styles,
    },
  }
  return useCallback(
    style => () => {
      delete updatedEntity.styles[style]
      dispatch(actions.update(updatedEntity))
    },
    [updatedEntity],
  )
}
