import React from 'react'
import PropTypes from 'prop-types'
import String from '../../components/String'
import GroupTitle from '../../styleComponents/GroupTitle'

function Attributes({ entity }) {
  return (
    <div>
      <GroupTitle>settings_options.attributes.label</GroupTitle>
      <String
        helpMessage="settings_options.attributes.id.help_message"
        labelText="settings_options.attributes.id.label"
        // update={update('attrId')}
        value={entity.options.attrId}
      />
    </div>
  )
}

Attributes.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      attrId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  update: PropTypes.func.isRequired,
}

export default Attributes
