import React from 'react'
import PropTypes from 'prop-types'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input'

const Width = ({ width, update }) => (
  <Input
    update={update}
    value={+width}
    placeholder={'PX'}
    labelText="width"
    type="number"
  />
)

Width.propTypes = {
  update: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Width.defaultProps = {
  width: '0',
}

export default Width
