import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

/* stylelint-disable */
const thumbStyle = css`
   {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #fff;
    margin-top: -7px;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background: ${p => (p.greyThumb ? '#ccc' : '#149efc')};
    cursor: pointer;
  }
`

const trackStyle = css`
   {
    animation: 0.2s;
    width: 100%;
    height: 3px;
    line-height: 40px;
    cursor: pointer;
    background: #ebedf0;
    border-radius: 2px;
    border: 0px solid #ccc;
  }
`

const Input = styled.input`
  width: 230px !important;
  float: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  margin: 15px 0;
  &::-webkit-slider-thumb
    ${thumbStyle},
    &::-webkit-slider-runnable-track
    ${trackStyle},
    &::-moz-slider-thumb
    ${thumbStyle},
    &::-moz-range-thumb
    ${thumbStyle},
    &::-moz-range-track
    ${trackStyle},
    &::-ms-thumb
    ${thumbStyle},
    &::-ms-track
    ${trackStyle},
    &::-moz-focus-outer {
    border: none;
  }
`

const TextInput = styled.input`
  width: 60px;
  height: 40px;
  float: right;
  text-align: center;
  padding: 0 5px;
  outline: none;
  border: solid 1px #ebedf0;
  font-size: 13px;
  border-radius: 3px;
  font-weight: bold;
  color: #5c687e;
  transition: all .25s ease;
  &::-webkit-inner-spin-button ${css`
     {
      appearance: none;
    }
  `}
  &::-webkit-textfield-decoration-container ${css`
     {
      margin-left: 0;
      transition: all 0.25s ease;
    }
  `}
  &:hover {
      &::-webkit-textfield-decoration-container ${css`
         {
          margin-left: -10px;
          transition: all 0.25s ease;
        }
      `}
      
    &::-webkit-inner-spin-button ${css`
       {
        appearance: auto;
        height: 27px;
        position: absolute;
        top: 6px;
        right: 5px;
        bottom: 0;
        cursor: pointer;
      }
    `}
  }
  
`

const StyledRange = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Range = ({
  min,
  max,
  value,
  change,
  allowNegative,
  greyThumb,
  ...rest
}) => (
  <StyledRange>
    <Input
      type="range"
      greyThumb={greyThumb}
      min={min}
      max={max}
      value={value}
      onChange={e => change(Number(e.target.value))}
      {...rest}
    />
    <TextInput
      type="number"
      value={value}
      onChange={e => change(Number(e.target.value))}
      {...(allowNegative ? {} : { min: 1, max })}
    />
  </StyledRange>
)

Range.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  greyThumb: PropTypes.bool,
  allowNegative: PropTypes.bool,
}

Range.defaultProps = {
  min: 0,
  max: 100,
  greyThumb: false,
}

export default Range
