import styled from 'styled-components'

const EntityInnerItemUi = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
`

export default EntityInnerItemUi
