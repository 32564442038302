import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IntervalTypes } from 'common/components/entities/WebinarRegistrationDate/WebinarRegistrationDate'
import { get12HoursFormatLabel } from 'common/components/entities/WebinarRegistrationDate/webinarRegistrationDateUtils'
import WebinarRegistrationDateInterface from 'common/types/entities/WebinarRegistrationDateInterface'
import Select, {
  ChangeOptionValue,
} from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Appearance } from '../../../../common/types/entities/EntityInterface'
import Range from '../../../components/core/Sidebar/components/Settings/components/Range/Range'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearance from '../../../components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../../../components/core/Sidebar/components/Settings/options/HtmlAttrId'

type WebinarRegistrationDateSettingsProps = {
  entity: WebinarRegistrationDateInterface
}

const intervalOptions: Record<number, string> = {
  [IntervalTypes.quarter]:
    'entity_settings.webinar_registration.start_in.next_quarter',
  [IntervalTypes.half]:
    'entity_settings.webinar_registration.start_in.next_half_hour',
  [IntervalTypes.hour]:
    'entity_settings.webinar_registration.start_in.next_hour',
}

const getIntervalOption = (type: number, t: TFunction) => ({
  value: type,
  label: t(intervalOptions[type]),
})

const getScheduledTimesOption = (t: TFunction) => ({
  value: null,
  label: t('entity_settings.webinar_registration.start_in.scheduled_times'),
})

const timeFormatOptions = [
  { value: 24, label: 'Hour:Minute' },
  { value: 12, label: 'Hour:Minute AM/PM' },
]

const getTimeOption = (scheduledHours: number[], timeFormat: number) => {
  if (timeFormat === timeFormatOptions[0].value) {
    return scheduledHours.map((hour: number) => ({
      value: hour,
      label: `${hour}﹕00`,
    }))
  } else {
    return scheduledHours.map((hour: number) => {
      const label = get12HoursFormatLabel(hour)
      return {
        value: hour,
        label: `${label.hour} ${label.suffix}`,
      }
    })
  }
}
const timeOptions = (timeFormat: number) =>
  getTimeOption([...Array(24).keys()], timeFormat)

const getTimeFormatOption = (format: number) => {
  const currentTimeFormat = timeFormatOptions.find(
    timeFormat => timeFormat.value === format,
  )
  if (currentTimeFormat) {
    return currentTimeFormat
  } else return timeFormatOptions[1]
}

function WebinarRegistrationDateSettings({
  entity,
}: WebinarRegistrationDateSettingsProps) {
  const { t } = useTranslation()
  const updateEntity = useUpdateEntity<WebinarRegistrationDateInterface>()
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)
  const { isMobile } = useDeviceModeContext()

  function updateInterval(option: ChangeOptionValue<number | null>) {
    if (option) {
      if (option.value === null) {
        const updatedEntity = {
          ...entity,
          interval: null,
        }
        // if it was Schedule Times interval we should reset scheduledHours
        if (entity.interval !== null) {
          updatedEntity.scheduledHours = []
        }

        updateEntity(updatedEntity)
      } else {
        updateEntity({
          ...entity,
          interval: option.value,
          scheduledHours: [],
        })
      }
    }
  }

  function updateScheduledTimes(timeOptions: ChangeOptionValue<number, true>) {
    if (timeOptions.length > 0) {
      const times = timeOptions
        .map(option => option.value)
        .sort((a, b) => a - b)
      updateProp('scheduledHours')(times)
    }
  }

  function updateTimeFormat(timeFormat: ChangeOptionValue<number | null>) {
    if (timeFormat) {
      if (timeFormat.value !== null) {
        updateProp('timeFormat')(timeFormat.value)
      }
    }
  }

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  return (
    <>
      <Select<number | null, false>
        labelText="entity_settings.webinar_registration.closest_intervals"
        selectedOption={
          entity.interval
            ? getIntervalOption(entity.interval, t)
            : getScheduledTimesOption(t)
        }
        update={updateInterval}
        options={[
          getScheduledTimesOption(t),
          getIntervalOption(IntervalTypes.quarter, t),
          getIntervalOption(IntervalTypes.half, t),
          getIntervalOption(IntervalTypes.hour, t),
        ]}
        isMulti={false}
      />
      {entity.interval === null && (
        <Select<number, true>
          labelText="entity_settings.webinar_registration.closest_intervals"
          selectedOption={getTimeOption(
            entity.scheduledHours,
            entity.timeFormat,
          )}
          update={updateScheduledTimes}
          options={timeOptions(entity.timeFormat)}
          isMulti={true}
        />
      )}
      <Select<number | null, false>
        labelText="entity_settings.webinar_registration.time_format"
        selectedOption={
          entity.timeFormat
            ? getTimeFormatOption(entity.timeFormat)
            : timeFormatOptions[0]
        }
        update={updateTimeFormat}
        options={timeFormatOptions}
        isMulti={false}
      />
      <Range
        label="entity_settings.webinar_registration.space_between"
        value={entity.spaceBetween}
        change={updateProp('spaceBetween')}
        max={100}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default WebinarRegistrationDateSettings
