import EntityInterface, {
  Appearance,
} from 'common/types/entities/EntityInterface'
import { useUpdateEntity } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'

export const useUpdateEntityAppearance = <T extends EntityInterface>(
  entity: T,
) => {
  const updateEntity = useUpdateEntity<T>()
  return (appearance: Appearance) =>
    updateEntity({
      ...entity,
      appearance,
    })
}
