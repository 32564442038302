import styled from 'styled-components'

type ColorPreviewInnerProps = {
  color: string
}

const ColorPreviewInner = styled.div<ColorPreviewInnerProps>`
  border-radius: 15px;
  border: solid 1px #ebedf0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: background-color 0.2s ease;
  background: ${({ color }) => (color ? color : 'transparent')};
`

export default ColorPreviewInner
