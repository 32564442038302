import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const LinkUi = styled.a`
  position: relative;
  cursor: pointer;
`

type LinkProps = {
  entityKey: string
  contentState: ContentState
  children: React.ReactChildren
  clickHandler: (entityKey: string) => void
}

function Link({ entityKey, contentState, children, clickHandler }: LinkProps) {
  const { url } = contentState.getEntity(entityKey).getData()
  return (
    <LinkUi href={url} onClick={() => clickHandler(entityKey)}>
      {children}
    </LinkUi>
  )
}

Link.propTypes = {
  entityKey: PropTypes.string.isRequired,
  contentState: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  clickHandler: PropTypes.func.isRequired,
}

function findLinkEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    )
  }, callback)
}

const createLinkDecorator = (clickHandler: (entityKey: string) => void) => {
  return new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
      props: {
        clickHandler,
      },
    },
  ])
}

export default createLinkDecorator
