import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

export const setDateWithTimezone = ({
  date,
  timeZone,
  subtract,
}: {
  date: Date
  timeZone: string
  subtract?: boolean
}) => {
  return subtract
    ? zonedTimeToUtc(date, timeZone)
    : utcToZonedTime(date, timeZone)
}
