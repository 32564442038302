import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { default as ReactDateTimePicker } from 'react-widgets/DatePicker'
import DropdownList from 'react-widgets/DropdownList'
import { DateLocalizer } from 'react-widgets/IntlLocalizer'
import Localization from 'react-widgets/Localization'
import 'react-widgets/styles.css'
import { generateDate } from 'client/components/core/Sidebar/components/Settings/entities/Countdown/utils/date-time-utils'
import 'client/components/core/Sidebar/components/Settings/options/DateTimePicker/styles.css'
import OptionWrapper from '../../styleComponents/OptionWrapper/index'
import { getDefaultTime, timeOptions } from './utils'

interface DateTimePickerProps {
  dateTime: Date
  updateDate: (date: Date) => void
  showDate?: boolean
}

const DateTimePicker = ({
  updateDate,
  dateTime,
  showDate = true,
}: DateTimePickerProps) => {
  const { i18n } = useTranslation()
  const fallbackLng = i18n.options.fallbackLng
    ? (i18n.options.fallbackLng as string[])[0]
    : 'en'
  const language = i18n.language || fallbackLng

  const [time, setTime] = useState<string>(getDefaultTime(dateTime, language))
  const [date, setDate] = useState<Date>(dateTime)

  return (
    <OptionWrapper>
      <Localization
        date={
          new DateLocalizer({
            culture: language,
            firstOfWeek: 1,
          })
        }
      >
        <div style={{ display: 'flex' }}>
          {showDate && (
            <div style={{ marginRight: '5px' }}>
              <ReactDateTimePicker
                lang={language}
                calendarProps={{ view: 'month' }}
                defaultValue={dateTime}
                onChange={date => {
                  if (date) {
                    setDate(date)
                    updateDate(generateDate({ date, time }))
                  }
                }}
              />
            </div>
          )}
          <div style={showDate ? { width: '50%' } : { width: '100%' }}>
            <DropdownList
              data={timeOptions(language)}
              onChange={time => {
                setTime(time)
                updateDate(generateDate({ date, time }))
              }}
              defaultValue={time}
            />
          </div>
        </div>
      </Localization>
    </OptionWrapper>
  )
}
export default DateTimePicker
