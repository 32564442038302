import React from 'react'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { FontUpdate } from './BaseFont'
import TextFont from './TextFont'

interface TextFontUpdaterProps<T> {
  entity: T
  update: (updatedEntity: T) => void
  fontFamilyKey?: keyof T
  fontWeightKey?: keyof T
  fontStyleKey?: keyof T
  mobileFontFamilyKey?: keyof T
  mobileFontWeightKey?: keyof T
  mobileFontStyleKey?: keyof T
  fontFileIdKey?: keyof T
  mobileFontFileIdKey?: keyof T
}

function getUpdatedEntity<T>(
  entity: T,
  update: FontUpdate,
  fontFamilyKey: keyof T,
  fontWeightKey: keyof T,
  fontStyleKey: keyof T,
  fontFileIdKey: keyof T,
) {
  const updatedEntity = { ...entity }
  const newFontProperties: Partial<Record<keyof T, string | number>> = {}
  if (update.fontFileId) {
    newFontProperties[fontFamilyKey] = update.fontFamily
    newFontProperties[fontFileIdKey] = update.fontFileId
    delete updatedEntity[fontStyleKey]
    delete updatedEntity[fontWeightKey]
  } else {
    newFontProperties[fontFamilyKey] = update.fontFamily
    if (update.fontFamily === 'inherit') {
      delete updatedEntity[fontStyleKey]
      delete updatedEntity[fontWeightKey]
    } else {
      if (update.fontStyle) {
        newFontProperties[fontStyleKey] = update.fontStyle
      }
      if (update.fontWeight) {
        newFontProperties[fontWeightKey] = update.fontWeight
      }
    }
    if (updatedEntity[fontFileIdKey]) {
      delete updatedEntity[fontFileIdKey]
    }
  }
  return {
    ...updatedEntity,
    ...newFontProperties,
  }
}

function TextFontUpdater<T extends Record<string, any>>({
  entity,
  update,
  fontFamilyKey = 'fontFamily',
  fontWeightKey = 'fontWeight',
  fontStyleKey = 'fontStyle',
  mobileFontFamilyKey = 'mobileFontFamily',
  mobileFontWeightKey = 'mobileFontWeight',
  mobileFontStyleKey = 'mobileFontStyle',
  fontFileIdKey = 'fontFileId',
  mobileFontFileIdKey = 'mobileFontFileId',
}: TextFontUpdaterProps<T>) {
  const { isMobile } = useDeviceModeContext()

  const fontFamily = isMobile
    ? entity[mobileFontFamilyKey] || entity[fontFamilyKey]
    : entity[fontFamilyKey]
  const fontWeight = isMobile
    ? entity[mobileFontWeightKey] || entity[fontWeightKey]
    : entity[fontWeightKey]
  const fontStyle = isMobile
    ? entity[mobileFontStyleKey] || entity[fontStyleKey]
    : entity[fontStyleKey]

  function handleFontChange(fontUpdate: FontUpdate) {
    if (isMobile) {
      update(
        getUpdatedEntity(
          entity,
          fontUpdate,
          mobileFontFamilyKey,
          mobileFontWeightKey,
          mobileFontStyleKey,
          mobileFontFileIdKey,
        ),
      )
    } else {
      update(
        getUpdatedEntity(
          entity,
          fontUpdate,
          fontFamilyKey,
          fontWeightKey,
          fontStyleKey,
          fontFileIdKey,
        ),
      )
    }
  }

  return (
    <TextFont
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      update={handleFontChange}
    />
  )
}
export default TextFontUpdater
