import {
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import React, { PropsWithChildren } from 'react'
import { TooltipUi } from './ui/TooltipUi'

type ControlledTooltipProps = {
  isOpen: boolean
  message: string
  onClose?: () => void
}

const ControlledTooltip = ({
  isOpen,
  message,
  children,
  onClose,
}: PropsWithChildren<ControlledTooltipProps>) => {
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(5)],
    onOpenChange: nextOpen => !nextOpen && onClose?.(),
  })

  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss])

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && (
        <TooltipUi
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {message}
        </TooltipUi>
      )}
    </>
  )
}

export default ControlledTooltip
