import React from 'react'
import Skeleton from 'react-loading-skeleton'
import ToolboxGroupTitleUi from '../components/Toolbox/ui/ToolboxGroupTitleUi'
import ToolboxGroupUi from '../components/Toolbox/ui/ToolboxGroupUi'
import ToolboxItemListUi from '../components/Toolbox/ui/ToolboxItemListUi'
import ToolboxItemTitleUi from '../components/Toolbox/ui/ToolboxItemTitleUi'
import ToolboxItemUi from '../components/Toolbox/ui/ToolboxItemUi'
import ToolboxUi from '../components/Toolbox/ui/ToolboxUi'
import SidebarContainerUi from './SidebarContainerUi'
import SidebarUi from './SidebarUi'

function SkeletonSidebar() {
  return (
    <SidebarUi>
      <SidebarContainerUi>
        <ToolboxUi>
          <ToolboxGroupUi>
            <ToolboxGroupTitleUi>
              <Skeleton width={50} />
            </ToolboxGroupTitleUi>
            <ToolboxItemListUi>
              {Array.from(Array(6).keys())
                .map(index => `${index}`)
                .map(key => (
                  <ToolboxItemUi key={key}>
                    <Skeleton circle width={50} height={50} />
                    <ToolboxItemTitleUi>
                      <Skeleton width={60} height={10} />
                    </ToolboxItemTitleUi>
                  </ToolboxItemUi>
                ))}
            </ToolboxItemListUi>
          </ToolboxGroupUi>
          <ToolboxGroupUi>
            <ToolboxGroupTitleUi>
              <Skeleton width={70} />
            </ToolboxGroupTitleUi>
            <ToolboxItemListUi>
              {Array.from(Array(4).keys())
                .map(index => `${index}`)
                .map(key => (
                  <ToolboxItemUi key={key}>
                    <Skeleton circle width={50} height={50} />
                    <ToolboxItemTitleUi>
                      <Skeleton width={60} height={10} />
                    </ToolboxItemTitleUi>
                  </ToolboxItemUi>
                ))}
            </ToolboxItemListUi>
          </ToolboxGroupUi>
        </ToolboxUi>
      </SidebarContainerUi>
    </SidebarUi>
  )
}

export default SkeletonSidebar
