import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { usePage, useManagement } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { pageSelectors } from 'client/store/page/pageSelectors'
import Tooltip from '../../../../../Tooltip'
import useUpdateEntityOption from '../../hooks/useUpdateEntityOption'
import GroupTitle from '../../styleComponents/GroupTitle'
import OptionWrapper from '../../styleComponents/OptionWrapper'

function Affiliate() {
  const { t } = useTranslation()
  const entity = usePage(pageSelectors.getReadableRootEntity)
  const isUserFreemium = useManagement(managementSelectors.isUserFreemium)
  const dispatch = useDispatch()
  const updateOption = useUpdateEntityOption(dispatch, entity)

  function toggleAffiliateBadge(e) {
    if (isUserFreemium) {
      return
    }

    updateOption('affiliateBadge')(!entity.options.affiliateBadge)
  }

  return (
    <React.Fragment>
      <GroupTitle>entity_settings.body.affiliate.title</GroupTitle>
      <OptionWrapper
        labelText="entity_settings.body.affiliate.badge.label"
        helpMessage="entity_settings.body.affiliate.badge.description"
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            onChange={toggleAffiliateBadge}
            checked={isUserFreemium || entity.options.affiliateBadge}
            type="checkbox"
            disabled={isUserFreemium}
            style={{ marginRight: 5 }}
          />
          {isUserFreemium && (
            <Tooltip
              message={t(
                'entity_settings.body.affiliate.badge.freemium_help_message',
              )}
            />
          )}
        </div>
      </OptionWrapper>
      <div style={{ marginBottom: 20 }} />
    </React.Fragment>
  )
}

export default Affiliate
