import styled from 'styled-components'

export const TooltipUi = styled.div`
  max-width: 200px;
  padding: 10px;
  background: white;
  text-transform: none;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
`
