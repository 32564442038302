import axios from 'axios'
import request from 'client/utils/request'

export const fetchBlocks = () => request.get('/blocks')

export const createMasterBlock = (content, desktopPreview, title) =>
  request.post(`/masters/blocks`, {
    block: {
      content: JSON.stringify(content),
      title,
      desktopPreview,
    },
  })

export const createUserBlock = (content, desktopPreview, title) =>
  request.post('/users/blocks', {
    block: {
      content: JSON.stringify(content),
      title,
      desktopPreview,
    },
  })

export const createCommonBlock = (
  locale,
  content,
  desktopPreview,
  mobilePreview,
  title,
  category,
) => {
  return request.post('/commons/blocks', {
    block: {
      locale,
      content: JSON.stringify(content),
      title,
      category,
      desktopPreview,
      mobilePreview,
    },
  })
}

export const deleteUserBlock = id => request.delete(`/users/${id}/block`)

export const deleteCommonBlock = id => request.delete(`/commons/${id}/block`)

export const deleteMasterBlock = id => request.delete(`/masters/${id}/block`)

export const screenshotCreate = (entities, rootEntityId, files, locale) =>
  axios.post('/block/screenshot/create', {
    entities,
    rootEntityId,
    files,
    locale,
  })
