import styled from 'styled-components'

const EmptyListWrapperUi = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3;
  background-color: white;
  padding-top: 25px;
`

export default EmptyListWrapperUi
