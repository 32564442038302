import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import usePresentPage from 'client/hooks/usePresentPage'
import {
  getGlobalMobileTextFontSize,
  getGlobalTextFontSize,
  getGlobalTextLineHeight,
  getGlobalMobileTextLineHeight,
} from 'client/reducers/pageReducer'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import MobileIconUi from '../styles/ui/MobileIconUi'
import ResetIcon from './ui/ResetIcon'

const browserDefaultLineHeight = 1.3

function TextLineHeight({
  fontSize,
  mobileFontSize,
  lineHeight,
  mobileLineHeight,
  ...rest
}) {
  const { t } = useTranslation()
  const { isMobile } = useDeviceModeContext()
  const globalTextFontSize = usePresentPage(getGlobalTextFontSize)
  const globalMobileTextFontSize = usePresentPage(getGlobalMobileTextFontSize)
  // global text size always exists
  const currentGlobalFontSizeParsed = parseInt(
    isMobile ? globalMobileTextFontSize : globalTextFontSize,
    0,
  )
  const globalTextLineHeight = usePresentPage(getGlobalTextLineHeight)
  const globalMobileTextLineHeight = usePresentPage(
    getGlobalMobileTextLineHeight,
  )

  // global line height could be undefined
  const currentGlobalLineHeightPx = isMobile
    ? globalMobileTextLineHeight
    : globalTextLineHeight
  const currentGlobalLineHeightParsed = currentGlobalLineHeightPx
    ? parseInt(currentGlobalLineHeightPx, 0)
    : null

  const currentLineHeightPx = isMobile ? mobileLineHeight : lineHeight
  const currentLineHeightParsed = currentLineHeightPx
    ? parseInt(currentLineHeightPx, 0)
    : null

  const currentFontSizePx = isMobile ? mobileFontSize : fontSize
  const currentFontSizeParsed = currentFontSizePx
    ? parseInt(currentFontSizePx, 0)
    : null

  const calculatedFontSize =
    currentFontSizeParsed || currentGlobalFontSizeParsed
  const computedLineHeight = Math.round(
    browserDefaultLineHeight * calculatedFontSize,
  )

  let calculatedLineHeight
  if (!currentLineHeightPx) {
    calculatedLineHeight = currentGlobalLineHeightParsed || computedLineHeight
  } else {
    calculatedLineHeight = currentLineHeightParsed
  }

  const isGlobalOrComputedFromGlobal = Boolean(
    !currentLineHeightParsed && !currentFontSizeParsed,
  )

  // we must show reset when NO local font size
  const isResetAllowed = !isGlobalOrComputedFromGlobal && !currentFontSizeParsed

  const reset = () =>
    isMobile ? rest.removeEntityMobileStyle() : rest.removeEntityStyle()

  const update = value => {
    if (value < 8) {
      return
    }

    isMobile ? rest.mobileUpdate(value) : rest.update(value)
  }

  return (
    <Range
      labelText="settings_styles.line_height.label"
      labelIcon={
        <React.Fragment>
          {isMobile && <MobileIconUi />}
          {isResetAllowed && (
            <ResetIcon
              onClick={reset}
              title={t('settings_styles.line_height.inherit')}
            />
          )}
        </React.Fragment>
      }
      greyThumb={isGlobalOrComputedFromGlobal}
      value={calculatedLineHeight}
      update={update}
      min={8}
    />
  )
}

TextLineHeight.propTypes = {
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileLineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
  removeEntityMobileStyle: PropTypes.func.isRequired,
  removeEntityStyle: PropTypes.func.isRequired,
}

TextLineHeight.defaultProps = {
  lineHeight: null,
  mobileLineHeight: null,
}

export default memo(TextLineHeight)
