import styled from 'styled-components'

const BreadcrumbsWrapperUi = styled.div`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default BreadcrumbsWrapperUi
