import React from 'react'
import { AlignSelfType } from 'common/types/styleTypes'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'
import { alignSelfVariants } from 'client/constants/alignSelfOptions'
import VerticalAlignButtonUi from './ui/VerticalAlignButtonUi'

interface VerticalAlignProps {
  align?: AlignSelfType
  update: (value: AlignSelfType) => void
  label?: string
}

function VerticalAlign({
  update,
  align = 'center',
  label = 'settings_styles.vertical_align.label',
}: VerticalAlignProps) {
  return (
    <OptionWrapper labelText={label}>
      <VerticalAlignButtonUi
        onClick={() => update(alignSelfVariants.flexStart)}
        active={align === alignSelfVariants.flexStart}
      >
        <span className={'fa-solid fa-objects-align-top'} />
      </VerticalAlignButtonUi>
      <VerticalAlignButtonUi
        onClick={() => update(alignSelfVariants.center)}
        active={align === alignSelfVariants.center}
      >
        <span className={'fa-solid fa-objects-align-center-vertical'} />
      </VerticalAlignButtonUi>
      <VerticalAlignButtonUi
        onClick={() => update(alignSelfVariants.flexEnd)}
        active={align === alignSelfVariants.flexEnd}
      >
        <span className={'fa-solid fa-objects-align-bottom'} />
      </VerticalAlignButtonUi>
    </OptionWrapper>
  )
}

export default VerticalAlign
