import styled from 'styled-components'

const DialogContentUi = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    margin-right: 10px;
  }
`

export default DialogContentUi
