import styled from 'styled-components'

const QuestionEditHeaderUi = styled.div`
  font-size: 20px;
  color: #797f89;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
`

export default QuestionEditHeaderUi
