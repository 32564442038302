import React from 'react'
import { useTranslation } from 'react-i18next'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { ContactUsInterface } from 'common/types/entities/ContactUsInterface'
import Checkbox from 'client/components/core/Sidebar/components/Settings/components/Checkbox'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import ShadowStyle from 'client/components/core/Sidebar/components/Settings/styles/ShadowStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { usePage, typedPageSelectors } from 'client/store'
import Input from '../../../components/core/Sidebar/components/Settings/components/Input/Input'
import { SettingsProps } from '../../../components/core/Sidebar/components/Settings/entities'
import { useUpdateProp } from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import HtmlAttrId from '../../../components/core/Sidebar/components/Settings/options/HtmlAttrId'
import Border from '../../../components/core/Sidebar/components/Settings/styles/Border'
import { useDeviceModeContext } from '../../../context/DeviceModeContext'
import { AttachmentsInterface } from '../entities/Attachments'

function ContactUsSettings({ entity }: SettingsProps<ContactUsInterface>) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const { t } = useTranslation()

  let editingAttachments
  const pageAttachments = usePage(page =>
    typedPageSelectors.getEntitiesByType(page, EntityTypeEnum.Attachments),
  )

  const contactUschildIds = entity.childIds
  if (contactUschildIds) {
    editingAttachments = pageAttachments.filter(attachmentsEntity =>
      contactUschildIds.includes(attachmentsEntity.id),
    )[0] as AttachmentsInterface
  }

  const updateAttachmentsProp = useUpdateProp(
    editingAttachments as AttachmentsInterface,
  )

  return (
    <>
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="settings_styles.background_color.label"
      />
      {editingAttachments && (
        <Checkbox
          labelText={t('entity_settings.contact_us.show_attachment')}
          labelIcon={isMobile && <MobileIconUi />}
          update={updateAttachmentsProp('show')}
          value={editingAttachments && editingAttachments.show}
          directionRow
          helpMessage={''}
          needTranslate={false}
          inverse={false}
        />
      )}
      <GroupTitle>
        entity_settings.contact_us.after_submit_text_title
      </GroupTitle>
      <Input
        label="entity_settings.contact_us.after_submit_message_title"
        value={
          entity.successMessageText ||
          t('components.contact_us.success_message')
        }
        update={updateProp('successMessageText')}
        type="text"
      />
      <ColorPicker
        update={updateProp('successMessageTextColor')}
        mobileUpdate={updateProp('mobileSuccessMessageTextColor')}
        color={entity.successMessageTextColor}
        mobileColor={entity.mobileSuccessMessageTextColor}
        label="entity_settings.contact_us.after_submit_text_color"
      />
      <Border
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
        border={isMobile ? entity.mobileBorder : entity.border}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default ContactUsSettings
