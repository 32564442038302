import PropTypes from 'prop-types'
import React from 'react'
import StyledRange from 'client/components/core/Sidebar/components/Settings/components/Range/StyledRange'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'

const Range = ({
  labelText = null,
  labelIcon = null,
  value = 0,
  update,
  min = 0,
  max = 100,
  greyThumb = false,
  needTranslate = true,
  allowNegative = true,
  ...rest
}) => (
  <OptionWrapper
    labelText={labelText}
    labelIcon={labelIcon}
    needTranslate={needTranslate}
  >
    <StyledRange
      greyThumb={greyThumb}
      change={update}
      value={parseInt(value, 0)}
      min={min}
      max={max}
      allowNegative={allowNegative}
      {...rest}
    />
  </OptionWrapper>
)

Range.propTypes = {
  labelText: PropTypes.string,
  labelIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  greyThumb: PropTypes.bool,
  update: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  needTranslate: PropTypes.bool,
  allowNegative: PropTypes.bool,
}

Range.defaultProps = {
  labelText: null,
  labelIcon: null,
  greyThumb: false,
  value: 0,
  min: 0,
  max: 100,
  needTranslate: true,
}

export default Range
