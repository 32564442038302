import React from 'react'
import { BreadcrumbsInterface } from 'common/types/entities/BreadcrumbsInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { SettingsProps } from '../../../components/core/Sidebar/components/Settings/entities'
import {
  useUpdateEntity,
  useUpdateProp,
} from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import HtmlAttrId from '../../../components/core/Sidebar/components/Settings/options/HtmlAttrId'
import FontSize from '../../../components/core/Sidebar/components/Settings/styles/FontSize'
import { useDeviceModeContext } from '../../../context/DeviceModeContext'

function BreadcrumbsSettings({ entity }: SettingsProps<BreadcrumbsInterface>) {
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<BreadcrumbsInterface>()
  const updateProp = useUpdateProp(entity)

  return (
    <>
      <ColorPicker
        update={updateProp('itemColor')}
        color={entity.itemColor}
        label="entity_settings.breadcrumbs.item_color.label"
        singleColorMode
      />
      <ColorPicker
        update={updateProp('lastItemColor')}
        color={entity.lastItemColor}
        label="entity_settings.breadcrumbs.last_item_color.label"
        singleColorMode
      />
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <TextFontUpdater<BreadcrumbsInterface>
        entity={entity}
        update={updateEntity}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default BreadcrumbsSettings
