import styled from 'styled-components'

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${({ needLiftUp }) => needLiftUp ? 50 : 10};
  background-color: ${({ dark }) => (dark ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
`

export default Overlay
