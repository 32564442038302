import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SwitcherButtonIconUi from './ui/SwitcherButtonIconUi'
import SwitcherButtonUi from './ui/SwitcherButtonUi'
import SwitcherDescriptionUi from './ui/SwitcherDescriptionUi'
import SwitcherUi from './ui/SwitcherUi'
import SwitcherWrapperUi from './ui/SwitcherWrapperUi'

function Switcher({
  handleFirstButton,
  isFirstActive,
  firstLabel,
  handleSecondButton,
  secondLabel,
  isSecondActive,
  description,
  wrapperStyle,
}) {
  const { t } = useTranslation()
  return (
    <SwitcherUi styles={wrapperStyle}>
      {description && (
        <SwitcherDescriptionUi>{t(description)}</SwitcherDescriptionUi>
      )}
      <SwitcherWrapperUi>
        <SwitcherButtonUi onClick={handleFirstButton} active={isFirstActive}>
          <SwitcherButtonIconUi
            className={`far fa-eye${isFirstActive ? '' : '-slash'}`}
          />
          {t(firstLabel)}
        </SwitcherButtonUi>
        <SwitcherButtonUi onClick={handleSecondButton} active={isSecondActive}>
          <SwitcherButtonIconUi
            className={`far fa-eye${isSecondActive ? '' : '-slash'}`}
          />
          {t(secondLabel)}
        </SwitcherButtonUi>
      </SwitcherWrapperUi>
    </SwitcherUi>
  )
}

Switcher.propTypes = {
  handleFirstButton: PropTypes.func.isRequired,
  isFirstActive: PropTypes.bool.isRequired,
  firstLabel: PropTypes.string.isRequired,
  handleSecondButton: PropTypes.func.isRequired,
  secondLabel: PropTypes.string.isRequired,
  isSecondActive: PropTypes.bool.isRequired,
  description: PropTypes.string,
  wrapperStyle: PropTypes.object,
}

Switcher.defaultProps = {
  description: '',
  wrapperStyle: '',
}

export default Switcher
