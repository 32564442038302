import styled from 'styled-components'

const ToolboxItemIconWrapperUi = styled.div`
  color: #acc9de;
  flex-basis: 60%;
  display: flex;
  align-items: center;
  ${({ styles }) => styles}
`

export default ToolboxItemIconWrapperUi
