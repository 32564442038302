import { EntitiesStructure } from 'common/types/Page'
import {
  BaseEntityInterface,
  EntityInnerItemInterface,
} from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { GoogleFontProperties } from 'common/utils/fontsUtils'
import { PagesInterface } from 'client/db/tables/pages'
import {
  ADD_STRUCTURE,
  AddStructureAction,
  FIX_ENTITIES,
  REMOVE_ENTITY,
  REMOVE_ENTITY_BY_ID_AND_PARENT_ID,
  RemoveEntityAction,
  RemoveEntityByIdAndPArentIdAction,
  TOGGLE_INSTANT_UPLOAD,
  TOGGLE_SETTINGS,
  ToggleInstantUpload,
  ToggleSettings,
  UPDATE_ENTITY,
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_PAGE_FROM_DB,
  UpdateEntityAction,
  UpdateGlobalSettings,
  UpdatePageFromDbAction,
} from './pageActionTypes'

export function updatePageFromDb(
  data: PagesInterface['page'],
): UpdatePageFromDbAction {
  return {
    type: UPDATE_PAGE_FROM_DB,
    payload: data,
  }
}

export function updateEntity<T>(entity: T): UpdateEntityAction<T> {
  return {
    type: UPDATE_ENTITY,
    payload: entity,
  }
}

export function updateEntityInnerItem<T>(entity: T): UpdateEntityAction<T> {
  return {
    type: UPDATE_ENTITY,
    payload: entity,
  }
}

export function addStructure(
  structure: Record<
    string,
    OldEntityInterface | EntityInnerItemInterface | BaseEntityInterface
  >,
): AddStructureAction {
  return {
    type: ADD_STRUCTURE,
    payload: structure,
  }
}

export function removeEntity<T>(entity: T): RemoveEntityAction<T> {
  return {
    type: REMOVE_ENTITY,
    payload: entity,
  }
}

export function removeEntityByIdAndParentId(
  id: string,
  parentId: string,
): RemoveEntityByIdAndPArentIdAction {
  return {
    type: REMOVE_ENTITY_BY_ID_AND_PARENT_ID,
    payload: {
      parentId,
      id,
    },
  }
}

export function updateGlobalSettings(
  globalSettings: GoogleFontProperties,
): UpdateGlobalSettings {
  return {
    type: UPDATE_GLOBAL_SETTINGS,
    payload: globalSettings,
  }
}

export function toggleInstantUpload(): ToggleInstantUpload {
  return {
    type: TOGGLE_INSTANT_UPLOAD,
    payload: true,
  }
}

export function toggleSettings(id: string): ToggleSettings {
  return {
    type: TOGGLE_SETTINGS,
    payload: { id },
  }
}

export function fixEntities(entities: EntitiesStructure) {
  return {
    type: FIX_ENTITIES,
    payload: entities,
  }
}
