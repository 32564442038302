import React from 'react'
import CommonWebinarSessionLink from 'common/components/entities/WebinarSessionLink/WebinarSessionLinkNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import WebinarSessionLinkInterface from 'common/types/entities/WebinarSessionLinkInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'

function WebinarSessionLink({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<WebinarSessionLinkInterface>) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonWebinarSessionLink
        htmlAttrId={entity.htmlAttrId}
        text={entity.text}
        redirectUrl="https://yoursubdomain.systeme.io/webinar/name/show/client-hash"
        color={entity.color}
        mobileColor={entity.mobileColor}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        textAlign={entity.textAlign}
        mobileTextAlign={entity.mobileTextAlign}
      />
    </BaseEntityNew>
  )
}

export function createWebinarSessionLink(
  parentId: string,
  masterBlockId?: string,
): WebinarSessionLinkInterface {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.WebinarSessionLink,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.WebinarSessionLink,
    color: 'rgba(0, 0, 238, 1)',
    fontSize: 30,
  }
}

export default WebinarSessionLink
