import React from 'react'
import { useTranslation } from 'react-i18next'
import { localeNames } from 'common/constants/localeTypes'
import {
  AutomationRuleActionsEnum,
  AutomationRuleActionsTitleEnum,
} from 'client/enums/AutomationRulesEnum'
import { ActionSaveType } from 'client/types/AutomationRulesActionsEditInterface'
import { AutomationRulesActionsType } from 'client/types/AutomationRulesActionsInterface'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import AutomationViewActionContainerUi from './ui/AutomationViewActionContainerUi'
import AutomationViewActionDescriptionContainerUi from './ui/AutomationViewActionDescriptionContainerUi'
import AutomationViewActionWrapperContainerUi from './ui/AutomationViewActionWrapperContainerUi'
import AutomationViewActionsContainerUi from './ui/AutomationViewActionsContainerUi'
import { getActionIcon } from './utils/get-rule-icon'

interface AutomationActionsListProps {
  actions: (AutomationRulesActionsType | ActionSaveType)[]
}

function AutomationActionsList({ actions }: AutomationActionsListProps) {
  const { t } = useTranslation()

  return (
    <OptionWrapper
      labelText="entity_settings.automation_rule.automations"
      itemStyles={{ marginBottom: '15px' }}
    >
      <AutomationViewActionsContainerUi>
        {actions.map(action => {
          switch (action.type) {
            case AutomationRuleActionsEnum.add_tag:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.tag.id}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>
                        <span>{action.tag.name}</span>
                      </span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.remove_tag:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.tag.id}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.tag.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.subscribe_to_campaign:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.campaign.id}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.campaign.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.unsubscribe_from_campaign:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.campaign.id}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.campaign.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.send_email:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${
                    action.id || action.systemeEmail.id
                  }`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.systemeEmail.subject}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.send_email_to_specific_address:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${
                    action.id || action.emailMessageWithRecipient.id
                  }`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      {action.emailMessageWithRecipient.mailing && (
                        <span>
                          {
                            action.emailMessageWithRecipient.mailing
                              .currentEmail.subject
                          }
                        </span>
                      )}
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.enroll_in_course:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.course.id}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.course.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.revoke_access_to_course:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.course.id}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.course.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${
                    action.id || action.courseBundle?.id
                  }`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.courseBundle.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.enroll_in_course_bundle:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${
                    action.id || action.courseBundle?.id
                  }`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.courseBundle?.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.send_webhook:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.webhookUrl}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.webhookUrl}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.grant_access_to_community:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.community.id}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.community.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.revoke_access_to_community:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.community.id}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      <span>{action.community.name}</span>
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            case AutomationRuleActionsEnum.create_user:
              return (
                <AutomationViewActionWrapperContainerUi
                  key={`${action.type} - ${action.id || action.locale}`}
                >
                  <AutomationViewActionContainerUi>
                    {getActionIcon(action.type)}
                    <AutomationViewActionDescriptionContainerUi>
                      <span>
                        {t(AutomationRuleActionsTitleEnum[action.type])}
                      </span>
                      {action.locale && (
                        <span>{localeNames[action.locale]}</span>
                      )}
                    </AutomationViewActionDescriptionContainerUi>
                  </AutomationViewActionContainerUi>
                </AutomationViewActionWrapperContainerUi>
              )
            default:
              break
          }
        })}
      </AutomationViewActionsContainerUi>
    </OptionWrapper>
  )
}

export default AutomationActionsList
