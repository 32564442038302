import React from 'react'

const RecaptchaIconRenderer = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 50 50"
    fill="#acc9de"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_323_2)">
      <path d="M37.7521 19.7417L38.125 20.6452L35.2351 23.5353H50V8.77031L46.5566 12.2137L45.5572 10.7726C41.1593 4.43076 34.1033 0.492187 26.4648 0.043457V11.287C31.4657 11.8141 35.8009 15.0145 37.7521 19.7417Z" />
      <path d="M0 41.2298L3.44336 37.7864L4.44277 39.2275C9.1206 45.9729 16.8056 50 25 50C31.627 50 37.9877 47.3523 42.6604 42.6924L34.7368 34.7688C32.1624 37.3427 28.6943 38.7913 25 38.7913C19.393 38.7913 14.3875 35.4419 12.2479 30.2584L11.875 29.3549L14.7649 26.4648H0L0 41.2298Z" />
      <path d="M8.77021 0L12.2137 3.44336L10.7725 4.44277C4.43057 8.84082 0.491504 15.8966 0.0429688 23.5352H11.2869C11.814 18.5343 15.0143 14.1991 19.7416 12.2479L20.6451 11.875L23.5352 14.765V0H8.77021Z" />
    </g>
    <defs>
      <clipPath id="clip0_323_2">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default RecaptchaIconRenderer
