import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ImagePlacementVariants,
  LatestBlogPostsCarouselInterface,
} from 'common/types/entities/LatestBlogPostsCarouselInterface'
import { Checkbox } from 'client/components/core/Sidebar/components/Settings/components'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range/Range'
import Select, {
  ChangeOptionValue,
  OptionType,
} from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  AlignStyle,
  FontSize,
  ShadowStyle,
} from 'client/components/core/Sidebar/components/Settings/styles'
import BorderRadius from 'client/components/core/Sidebar/components/Settings/styles/BorderRadius'
import LetterSpacing from 'client/components/core/Sidebar/components/Settings/styles/LetterSpacing'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import TextLineHeight from 'client/components/core/Sidebar/components/Settings/styles/TextLineHeight'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { blogSelectors, useBlog } from 'client/store'
import {
  LATEST_BLOG_POSTS_PLACEHOLDER,
  LATEST_CATEGORY_BLOG_POSTS_PLACEHOLDER,
} from '../entities/BlogLatestPosts'

function getLatestCategoryBlogPostsReplacement(categoryId: number) {
  return LATEST_CATEGORY_BLOG_POSTS_PLACEHOLDER.replace(' ', String(categoryId))
}

function LatestBlogPostsCarouselSettings({
  entity,
}: {
  entity: LatestBlogPostsCarouselInterface
}) {
  const { t } = useTranslation()
  const updateEntity = useUpdateEntity<LatestBlogPostsCarouselInterface>()
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  const categories = useBlog(blogSelectors.getBlogCategories)

  const categoriesOptions: OptionType<string>[] = [
    {
      label: t('entity_settings.blog_post_listing.all_categories'),
      value: LATEST_BLOG_POSTS_PLACEHOLDER,
    },
  ]
  categories.forEach(category => {
    categoriesOptions.push({
      label: category.name,
      value: getLatestCategoryBlogPostsReplacement(category.id),
    })
  })

  function updateCategories(option: ChangeOptionValue<string>) {
    if (option) {
      updateProp('postsReplacement')(option.value)
    }
  }

  const getDefaultCategoriesOption = (categoryReplacement?: string) => {
    if (!categoryReplacement) {
      return categoriesOptions[0]
    }

    const selectedCategoryOption = categoriesOptions.find(
      state => state.value === categoryReplacement,
    )

    if (selectedCategoryOption) {
      return selectedCategoryOption
    } else return categoriesOptions[0]
  }

  const imagePlacementOptions: OptionType<string>[] = [
    {
      label: t(
        'entity_settings.latest_blog_posts_carousel.image_placement.top',
      ),
      value: ImagePlacementVariants.Up,
    },
    {
      label: t(
        'entity_settings.latest_blog_posts_carousel.image_placement.left',
      ),
      value: ImagePlacementVariants.Left,
    },
    {
      label: t(
        'entity_settings.latest_blog_posts_carousel.image_placement.background',
      ),
      value: ImagePlacementVariants.Background,
    },
  ]
  function updateImagePlacement(option: ChangeOptionValue<string>) {
    if (option) {
      if (isMobile) {
        updateProp('mobileImagePlacement')(
          option.value as ImagePlacementVariants,
        )
      } else {
        updateProp('imagePlacement')(option.value as ImagePlacementVariants)
      }
    }
  }
  const getDefaultImagePlacement = (imagePlacement?: string) => {
    if (!imagePlacement) {
      return imagePlacementOptions[0]
    }

    const selectedCategoryOption = imagePlacementOptions.find(
      state => state.value === imagePlacement,
    )

    if (selectedCategoryOption) {
      return selectedCategoryOption
    } else return imagePlacementOptions[0]
  }
  return (
    <>
      <Range
        label="Number of posts"
        change={updateProp('maxPosts')}
        value={entity.maxPosts}
        min={1}
        max={10}
        allowNegative={false}
      />
      <Select<string>
        labelText="entity_settings.latest_blog_posts_carousel.image_placement.label"
        labelIcon={isMobile ? <MobileIconUi /> : undefined}
        selectedOption={
          isMobile
            ? getDefaultImagePlacement(entity.mobileImagePlacement)
            : getDefaultImagePlacement(entity.imagePlacement)
        }
        update={updateImagePlacement}
        options={imagePlacementOptions}
        isMulti={false}
      />
      <Select<string>
        labelText="entity_settings.blog_post_listing.category"
        selectedOption={getDefaultCategoriesOption(entity.postsReplacement)}
        update={updateCategories}
        options={categoriesOptions}
        isMulti={false}
      />
      {!isMobile && (
        <>
          <Range
            label="entity_settings.blog_post_listing.columns_gap"
            change={updateProp('columnsGap')}
            value={entity.columnsGap}
            min={1}
            max={50}
            allowNegative={false}
          />
          <Range
            label="entity_settings.blog_post_listing.maximum_posts_per_row"
            change={updateProp('maxPostsPerRow')}
            value={entity.maxPostsPerRow}
            min={1}
            max={3}
            allowNegative={false}
          />
        </>
      )}
      <Checkbox
        labelText="entity_settings.carousel.show_arrows.label"
        labelIcon={isMobile && <MobileIconUi />}
        update={updateProp(isMobile ? 'mobileShowArrows' : 'showArrows')}
        value={
          isMobile
            ? typeof entity.mobileShowArrows !== 'undefined'
              ? entity.mobileShowArrows
              : entity.showArrows
            : entity.showArrows
        }
        directionRow={false}
        helpMessage={''}
        needTranslate={true}
        inverse={false}
      />
      <ColorPicker
        update={updateProp('controlsColor')}
        color={entity.controlsColor}
        label="entity_settings.carousel.controls_color.label"
        singleColorMode
      />
      <ColorPicker
        update={updateProp('arrowsColor')}
        color={entity.arrowsColor}
        label="entity_settings.carousel.arrows_color.label"
        singleColorMode
      />
      <ColorPicker
        update={updateProp('postBackgroundColor')}
        mobileUpdate={updateProp('mobilePostBackgroundColor')}
        color={entity.postBackgroundColor}
        mobileColor={entity.mobilePostBackgroundColor}
        label="entity_settings.latest_blog_posts_carousel.post_content.background_color"
      />
      <GroupTitle>entity_settings.image.header</GroupTitle>
      <ShadowStyle
        shadow={entity.imageBoxShadow}
        mobileShadow={entity.mobileImageBoxShadow}
        update={updateProp('imageBoxShadow')}
        mobileUpdate={updateProp('mobileImageBoxShadow')}
        isTitleNeeded={false}
      />
      <BorderRadius
        label="settings_styles.border_radius.label"
        labelIcon={isMobile ? <MobileIconUi /> : null}
        border={isMobile ? entity.mobileImageBorder : entity.imageBorder}
        update={updateProp(isMobile ? 'mobileImageBorder' : 'imageBorder')}
      />
      <MarginStyle
        label="settings_styles.margin.label"
        margin={isMobile ? entity.mobileImageMargin : entity.imageMargin}
        update={updateProp(isMobile ? 'mobileImageMargin' : 'imageMargin')}
      />
      <GroupTitle>entity_settings.blog_post_listing.title</GroupTitle>
      <FontSize
        update={updateProp('titleFontSize')}
        mobileUpdate={updateProp('mobileTitleFontSize')}
        fontSize={entity.titleFontSize}
        mobileFontSize={entity.mobileTitleFontSize}
      />
      <TextLineHeight
        fontSize={entity.titleFontSize}
        mobileFontSize={entity.mobileTitleFontSize}
        update={updateProp('titleLineHeight')}
        mobileUpdate={updateProp('mobileTitleLineHeight')}
        lineHeight={entity.titleLineHeight}
        mobileLineHeight={entity.mobileTitleLineHeight}
      />
      <TextFontUpdater<LatestBlogPostsCarouselInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'titleFontFamily'}
        fontWeightKey={'titleFontWeight'}
        fontStyleKey={'titleFontStyle'}
        mobileFontFamilyKey={'mobileTitleFontFamily'}
        mobileFontWeightKey={'mobileTitleFontWeight'}
        mobileFontStyleKey={'mobileTitleFontStyle'}
        fontFileIdKey={'titleFontFileId'}
        mobileFontFileIdKey={'mobileTitleFontFileId'}
      />
      <LetterSpacing
        change={
          isMobile
            ? updateProp('mobileTitleLetterSpacing')
            : updateProp('titleLetterSpacing')
        }
        letterSpacing={
          isMobile ? entity.mobileTitleLetterSpacing : entity.titleLetterSpacing
        }
      />
      <AlignStyle
        align={isMobile ? entity.mobileTitleAlign : entity.titleAlign}
        update={
          isMobile ? updateProp('mobileTitleAlign') : updateProp('titleAlign')
        }
        isFlex={false}
      />
      <ColorPicker
        update={updateProp('titleColor')}
        mobileUpdate={updateProp('mobileTitleColor')}
        color={entity.titleColor}
        mobileColor={entity.mobileTitleColor}
      />
      <MarginStyle
        label="settings_styles.margin.label"
        margin={isMobile ? entity.mobileTitleMargin : entity.titleMargin}
        update={updateProp(isMobile ? 'mobileTitleMargin' : 'titleMargin')}
      />
      <GroupTitle>entity_settings.blog_post_listing.description</GroupTitle>
      <FontSize
        update={updateProp('descriptionFontSize')}
        mobileUpdate={updateProp('mobileDescriptionFontSize')}
        fontSize={entity.descriptionFontSize}
        mobileFontSize={entity.mobileDescriptionFontSize}
      />
      <TextLineHeight
        fontSize={entity.descriptionFontSize}
        mobileFontSize={entity.mobileDescriptionFontSize}
        update={updateProp('descriptionLineHeight')}
        mobileUpdate={updateProp('mobileDescriptionLineHeight')}
        lineHeight={entity.descriptionLineHeight}
        mobileLineHeight={entity.mobileDescriptionLineHeight}
      />
      <TextFontUpdater<LatestBlogPostsCarouselInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'descriptionFontFamily'}
        fontWeightKey={'descriptionFontWeight'}
        fontStyleKey={'descriptionFontStyle'}
        mobileFontFamilyKey={'mobileDescriptionFontFamily'}
        mobileFontWeightKey={'mobileDescriptionFontWeight'}
        mobileFontStyleKey={'mobileDescriptionFontStyle'}
        fontFileIdKey={'descriptionFontFileId'}
        mobileFontFileIdKey={'mobileDescriptionFontFileId'}
      />
      <AlignStyle
        align={
          isMobile ? entity.mobileDescriptionAlign : entity.descriptionAlign
        }
        update={
          isMobile
            ? updateProp('mobileDescriptionAlign')
            : updateProp('descriptionAlign')
        }
        isFlex={false}
      />
      <ColorPicker
        update={updateProp('descriptionColor')}
        mobileUpdate={updateProp('mobileDescriptionColor')}
        color={entity.descriptionColor}
        mobileColor={entity.mobileDescriptionColor}
      />
      <MarginStyle
        label="settings_styles.margin.label"
        margin={
          isMobile ? entity.mobileDescriptionMargin : entity.descriptionMargin
        }
        update={updateProp(
          isMobile ? 'mobileDescriptionMargin' : 'descriptionMargin',
        )}
      />
      <GroupTitle>entity_settings.blog_post_listing.categories</GroupTitle>
      <FontSize
        update={updateProp('categoriesFontSize')}
        mobileUpdate={updateProp('mobileCategoriesFontSize')}
        fontSize={entity.categoriesFontSize}
        mobileFontSize={entity.mobileCategoriesFontSize}
      />
      <TextFontUpdater<LatestBlogPostsCarouselInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'categoriesFontFamily'}
        fontWeightKey={'categoriesFontWeight'}
        fontStyleKey={'categoriesFontStyle'}
        mobileFontFamilyKey={'mobileCategoriesFontFamily'}
        mobileFontWeightKey={'mobileCategoriesFontWeight'}
        mobileFontStyleKey={'mobileCategoriesFontStyle'}
        fontFileIdKey={'categoriesFontFileId'}
        mobileFontFileIdKey={'mobileCategoriesFontFileId'}
      />
      <AlignStyle
        align={isMobile ? entity.mobileCategoriesAlign : entity.categoriesAlign}
        update={
          isMobile
            ? updateProp('mobileCategoriesAlign')
            : updateProp('categoriesAlign')
        }
        isFlex={false}
      />
      <ColorPicker
        update={updateProp('categoriesColor')}
        mobileUpdate={updateProp('mobileCategoriesColor')}
        color={entity.categoriesColor}
        mobileColor={entity.mobileCategoriesColor}
      />
      <MarginStyle
        label="settings_styles.margin.label"
        margin={
          isMobile ? entity.mobileCategoriesMargin : entity.categoriesMargin
        }
        update={updateProp(
          isMobile ? 'mobileCategoriesMargin' : 'categoriesMargin',
        )}
      />
      <GroupTitle>entity_settings.blog_post_listing.date</GroupTitle>
      <Checkbox
        labelText="entity_settings.blog_post_listing.show_date.label"
        labelIcon={isMobile && <MobileIconUi />}
        update={updateProp('showDate')}
        value={entity.showDate}
        directionRow={false}
        helpMessage={''}
        needTranslate={true}
        inverse={false}
      />
      {entity.showDate && (
        <>
          <FontSize
            update={updateProp('dateFontSize')}
            mobileUpdate={updateProp('mobileDateFontSize')}
            fontSize={entity.dateFontSize}
            mobileFontSize={entity.mobileDateFontSize}
          />
          <TextFontUpdater<LatestBlogPostsCarouselInterface>
            entity={entity}
            update={updateEntity}
            fontFamilyKey={'dateFontFamily'}
            fontWeightKey={'dateFontWeight'}
            fontStyleKey={'dateFontStyle'}
            mobileFontFamilyKey={'mobileDateFontFamily'}
            mobileFontWeightKey={'mobileDateFontWeight'}
            mobileFontStyleKey={'mobileDateFontStyle'}
            fontFileIdKey={'dateFontFileId'}
            mobileFontFileIdKey={'mobileDateFontFileId'}
          />
          <AlignStyle
            align={isMobile ? entity.mobileDateAlign : entity.dateAlign}
            update={
              isMobile ? updateProp('mobileDateAlign') : updateProp('dateAlign')
            }
            isFlex={false}
          />
          <ColorPicker
            update={updateProp('dateColor')}
            mobileUpdate={updateProp('mobileDateColor')}
            color={entity.dateColor}
            mobileColor={entity.mobileDateColor}
          />
          <MarginStyle
            label="settings_styles.margin.label"
            margin={isMobile ? entity.mobileDateMargin : entity.dateMargin}
            update={updateProp(isMobile ? 'mobileDateMargin' : 'dateMargin')}
          />
        </>
      )}
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default LatestBlogPostsCarouselSettings
