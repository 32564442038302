import styled from 'styled-components'

type ColorStopsHolderProps = {
  width: number
}

const ColorStopsHolderUi = styled.div<ColorStopsHolderProps>`
  height: 17px;
  position: relative;
  width: ${p => p.width}px;
`

export default ColorStopsHolderUi
