import { ContentState, convertToRaw } from 'draft-js'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createCheckbox = (
  parentId: string,
  defaultText: string,
  masterBlockId?: string,
): CheckboxInterface => {
  return {
    ...generateBaseEntity(EntityTypeEnum.Checkbox, parentId, masterBlockId),
    type: EntityTypeEnum.Checkbox,
    rawContentState: JSON.stringify(
      convertToRaw(ContentState.createFromText(defaultText)),
    ),
    padding: {
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    fontSize: 16,
    errorText: 'settings_options.checkbox_message',
    optional: false,
    textAlign: 'left',
  }
}
