import { AutomationRuleSaveTriggerInterface } from 'client/types/AutomationRulesActionsInterface'
import {
  AutomationRuleSaveInterface,
  AutomationRulesState,
} from './automationRulesReducer'

export const getEditedPageAutomationRules = (state: AutomationRulesState) => {
  return state.editedPageAutomationRules
}

export const getOriginalAutomationRulesByButtonIdId = (
  state: AutomationRulesState,
  buttonId: string,
) => {
  return (
    state.originalPageAutomationRules.find(
      (automationRule: AutomationRuleSaveInterface) =>
        automationRule.automationTriggers[0].buttonId === buttonId,
    )?.automationActions || []
  )
}

export const getDeletedAutomationRulesByEntityId = (
  state: AutomationRulesState,
  entityId: string,
  triggerKey: keyof AutomationRuleSaveTriggerInterface,
) => {
  return (
    state.deletedAutomationRules.find(
      (automationRule: AutomationRuleSaveInterface) =>
        automationRule.automationTriggers[0][triggerKey] === entityId,
    )?.automationActions || []
  )
}

export const getEditedAutomationRulesByEntityId = (
  state: AutomationRulesState,
  entityId: string,
  triggerKey: keyof AutomationRuleSaveTriggerInterface,
) => {
  return (
    state.editedPageAutomationRules.find(
      (automationRule: AutomationRuleSaveInterface) =>
        automationRule.automationTriggers[0][triggerKey] === entityId,
    )?.automationActions || []
  )
}

const automationRulesSelectors = {
  getEditedPageAutomationRules,
  getOriginalAutomationRulesByButtonIdId,
  getDeletedAutomationRulesByEntityId,
  getEditedAutomationRulesByEntityId,
}

export default automationRulesSelectors
