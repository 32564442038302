import React from 'react'
import CaptchaPlaceHolderUi from 'common/components/entities/Recaptcha/ui/CaptchaPlaceholderUi'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { EntityProps } from '../../../types'
import { useContactUsDescendantIds } from './ContactUs'

export default function Recaptcha({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<RecaptchaInterface>) {
  const entityIds = useContactUsDescendantIds()

  return (
    <>
      <BaseEntityNew
        entity={entity}
        isMoveDownAllowed={isMoveDownAllowed}
        isMoveUpAllowed={isMoveUpAllowed}
        isRemoveAllowed={false}
        isSaveAllowed={false}
        isCopyAllowed={false}
        dropBoxAllowedEntityIds={entityIds}
        flex
        flexColumn
      >
        <CaptchaPlaceHolderUi
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
          src={
            entity.theme === 'light'
              ? 'https://developers.google.com/recaptcha/images/light.png'
              : 'https://developers.google.com/recaptcha/images/dark.png'
          }
        />
      </BaseEntityNew>
    </>
  )
}
