import React from 'react'
import { EntityInterface } from 'common/types/entities/EntityInterface'
import { useUpdateEntityAppearance } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateEntityAppearance'
import Checkbox from '../../components/Checkbox'
import GroupTitle from '../../styleComponents/GroupTitle'

interface DeviceAppearanceNewProps<T extends EntityInterface> {
  entity: T
}

function DeviceAppearanceNew<T extends EntityInterface>({
  entity,
}: DeviceAppearanceNewProps<T>) {
  const updateAppearance = useUpdateEntityAppearance(entity)
  const toggleDesktop = () =>
    updateAppearance({
      desktop: !entity.appearance.desktop,
      mobile: entity.appearance.desktop ? true : entity.appearance.mobile,
    })

  const toggleMobile = () =>
    updateAppearance({
      desktop: entity.appearance.mobile ? true : entity.appearance.desktop,
      mobile: !entity.appearance.mobile,
    })

  return (
    <>
      <GroupTitle>settings_options.device_appearance.label</GroupTitle>
      <Checkbox
        labelText="settings_options.device_appearance.desktop"
        update={toggleDesktop}
        value={entity.appearance.desktop}
        directionRow
      />
      <Checkbox
        labelText="settings_options.device_appearance.mobile"
        update={toggleMobile}
        value={entity.appearance.mobile}
        directionRow
      />
    </>
  )
}

export default DeviceAppearanceNew
