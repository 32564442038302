import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BlockSearchInputUi from './ui/BlockSearchInputUi'

type BlockSearchInputProps = {
  setSearchValue: (value: React.SetStateAction<string>) => void
}
function BlockSearchInput({ setSearchValue }: BlockSearchInputProps) {
  const { t } = useTranslation()
  useEffect(() => {
    return () => setSearchValue('')
  }, [])

  return (
    <BlockSearchInputUi
      type="search"
      placeholder={t('components.core.blocks.search.placeholder')}
      onChange={e => setSearchValue(e.target.value)}
    />
  )
}

export default BlockSearchInput
