import React from 'react'
import Popup from 'client/components/entities/Popup'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import useManagement, {
  selectors as managementSelectors,
} from 'client/hooks/useManagement'
import usePresentPage, {
  selectors as pageSelectors,
} from 'client/hooks/usePresentPage'
import PopupWrapper from '../ui/PopupWrapper'

function ActivePopup() {
  const activePopupId = useManagement(managementSelectors.getActivePopupId)
  const { isMobile } = useDeviceModeContext()
  const popup = usePresentPage(page =>
    pageSelectors.getEntityById(page, activePopupId),
  )

  return (
    popup && (
      <PopupWrapper
        width={
          isMobile
            ? 'auto'
            : popup.styles.width
            ? `${popup.styles.width}px`
            : '600px'
        }
      >
        <Popup entity={popup} />
      </PopupWrapper>
    )
  )
}

export default ActivePopup
