import React, { useState } from 'react'
import { BorderType, FullBorderRadiusType } from 'common/types/styleTypes'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import FullAndSeparateSettingsContainerUi from '../ui/FullAndSeparateSettingsContainerUi'
import FullAndSeparateSettingsIconsContainerUi from '../ui/FullAndSeparateSettingsIconsContainerUi'
import FullAndSeparateSettingsInputUi from '../ui/FullAndSeparateSettingsInputUi'
import FullBorderRadiusTypeContainerUi from './ui/FullBorderRadiusTypeContainerUi'
import {
  findLargestRadius,
  isFullBorderRadius,
  isNumber,
  topLeftCornerRenderer,
  topRightCornerRenderer,
  bottomRightCornerRenderer,
  bottomLeftCornerRenderer,
  singleBorderRadiusContainerRenderer,
} from './utils'

type BorderRadiusProps = {
  border?: Partial<BorderType>
  update: (border: Partial<BorderType>) => void
  label?: string
  labelIcon?: React.ReactElement | null
}

enum borderRadiusEditingModes {
  SingleBorderRadius = 'SingleBorderRadius',
  FullBorderRadiusType = 'FullBorderRadiusType',
}
const fullBorderRadiusTypeCorners = {
  borderTopLeftRadius: topLeftCornerRenderer,
  borderTopRightRadius: topRightCornerRenderer,
  borderBottomRightRadius: bottomRightCornerRenderer,
  borderBottomLeftRadius: bottomLeftCornerRenderer,
}

function BorderRadius({
  border = {},
  update,
  label,
  labelIcon,
}: BorderRadiusProps) {
  const [borderRadiusEditingMode, setBorderRadiusEditingMode] = useState(
    isFullBorderRadius(border.radius)
      ? borderRadiusEditingModes.FullBorderRadiusType
      : borderRadiusEditingModes.SingleBorderRadius,
  )
  const [activeId, setActiveId] = useState<number | null>(null)

  function updateFullBorderRadius(corner: string) {
    return function (value: number | FullBorderRadiusType | undefined) {
      const previousBorderRadius = border.radius as FullBorderRadiusType
      update({
        ...border,
        radius: {
          ...previousBorderRadius,
          [corner]: value,
        },
      })
    }
  }

  function updateBorderRadius(value: number) {
    update({
      ...border,
      radius: value,
    })
  }

  const switchToSingleMode = () => {
    setBorderRadiusEditingMode(borderRadiusEditingModes.SingleBorderRadius)
    setActiveId(null)
    if (isFullBorderRadius(border.radius)) {
      update({
        ...border,
        radius: findLargestRadius(border.radius),
      })
    }
  }

  const switchToFullBorderRadiusTypeMode = () => {
    if (
      borderRadiusEditingMode === borderRadiusEditingModes.SingleBorderRadius
    ) {
      setBorderRadiusEditingMode(borderRadiusEditingModes.FullBorderRadiusType)
    }
    if (isNumber(border.radius)) {
      const newBorderRadius = {
        borderTopLeftRadius: border.radius,
        borderTopRightRadius: border.radius,
        borderBottomRightRadius: border.radius,
        borderBottomLeftRadius: border.radius,
      }
      update({
        ...border,
        radius: newBorderRadius,
      })
    }
  }

  return (
    <>
      <OptionWrapper labelText={label} labelIcon={labelIcon}>
        <FullAndSeparateSettingsContainerUi>
          <FullAndSeparateSettingsIconsContainerUi>
            {singleBorderRadiusContainerRenderer(
              borderRadiusEditingMode ===
                borderRadiusEditingModes.SingleBorderRadius,
              switchToSingleMode,
            )}
            <FullBorderRadiusTypeContainerUi
              onClick={switchToFullBorderRadiusTypeMode}
            >
              {Object.entries(fullBorderRadiusTypeCorners).map(
                ([corner, renderCorner], index) =>
                  renderCorner(index === activeId, index, corner),
              )}
            </FullBorderRadiusTypeContainerUi>
          </FullAndSeparateSettingsIconsContainerUi>
          {borderRadiusEditingMode ===
          borderRadiusEditingModes.SingleBorderRadius ? (
            <FullAndSeparateSettingsInputUi
              onChange={e => updateBorderRadius(Number(e.target.value))}
              type="number"
              min={0}
              max={250}
              value={isNumber(border.radius) ? border.radius.toString() : 0}
            />
          ) : (
            Object.keys(fullBorderRadiusTypeCorners).map((corner, index) => (
              <FullAndSeparateSettingsInputUi
                type="number"
                id={corner}
                key={index}
                min={0}
                max={250}
                value={
                  isFullBorderRadius(border.radius) &&
                  border.radius[corner as keyof FullBorderRadiusType]
                    ? border.radius[
                        corner as keyof FullBorderRadiusType
                      ].toString()
                    : 0
                }
                onChange={e =>
                  updateFullBorderRadius(corner)(Number(e.target.value))
                }
                onFocus={() => setActiveId(index)}
              />
            ))
          )}
        </FullAndSeparateSettingsContainerUi>
      </OptionWrapper>
    </>
  )
}

export default BorderRadius
