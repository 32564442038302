import React from 'react'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { useDateWithTimezone } from 'client/components/core/Sidebar/components/Settings/entities/Countdown/hooks/use-date-with-timezone'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DateTimePicker from 'client/components/core/Sidebar/components/Settings/options/DateTimePicker'

interface CountdownFixedProps {
  entity: CountdownInterface
}

const CountdownFixed = ({ entity }: CountdownFixedProps) => {
  const updateProp = useUpdateProp(entity)
  const { dateWithTimezone, onChangeWithTimezone } = useDateWithTimezone({
    date: new Date(entity[CountdownTypesEnum.fixed].date),
    onChange: date =>
      updateProp(CountdownTypesEnum.fixed)({ date: date.toISOString() }),
  })
  return (
    <DateTimePicker
      updateDate={onChangeWithTimezone}
      dateTime={dateWithTimezone}
    />
  )
}

export default CountdownFixed
