import request from 'client/utils/request'
import {
  createRequestInterceptor,
  createResponseInterceptor,
} from 'common/utils/registerAxiosInterceptors'

export function initialiseAxiosInterceptors() {
  createRequestInterceptor(request)
  createResponseInterceptor(request)
}
