import styled from 'styled-components'

const BlockShareDialogCodeUi = styled.div`
  margin: 16px 0;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 8px;
  background-color: #f5f5f5;
  font-size: 12px;
`

export default BlockShareDialogCodeUi
