import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { InternalError } from 'common/errors'
import * as blogApi from 'client/api/blogApi'
import useDynamicStore from 'client/hooks/useDynamicStore'
import { usePage } from 'client/store'
import * as blogPostActions from 'client/store/blogPost/blogPostActions'
import blogPostReducer from 'client/store/blogPost/blogPostReducer'
import { pageSelectors } from 'client/store/page/pageSelectors'
import useBlogData from '../../hooks/useBlogData'
import ActivePopup from '../components/ActivePopup'
import PageUi from '../ui/PageUi'

function BlogPost({ children }: { children: React.ReactChildren }) {
  useBlogData()
  useDynamicStore({ key: 'blogPost', reducer: blogPostReducer })
  const dispatch = useDispatch()
  const globalFontProperties = usePage(pageSelectors.getGlobalFontProperties)
  const pageId = usePage(pageSelectors.getPageId)

  useEffect(() => {
    async function fetchBlogPostData() {
      try {
        const blogPostData = await blogApi.getBlogPostData(pageId)
        dispatch(blogPostActions.fetchBlogPostData(blogPostData.data))
      } catch (error) {
        console.error(error)
        if (error instanceof InternalError) {
          ;(window as any).Rollbar.error(error)
        }
      }
    }

    fetchBlogPostData()
  }, [])

  return (
    <PageUi
      fontFamily={globalFontProperties.fontFamily}
      fontWeight={globalFontProperties.fontWeight}
      fontStyle={globalFontProperties.fontStyle}
    >
      {children}
      <ActivePopup />
    </PageUi>
  )
}

BlogPost.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlogPost
