import request from 'client/utils/request'

export const fetchPageData = (pageId, isTemplate) =>
  request.get(
    isTemplate ? `/page-template/${pageId}/edit` : `/page/${pageId}/edit`,
    {
      withCredentials: true,
    },
  )

export const previewPage = (
  { id, entities, seo, tracking, locale, globalSettings },
  isTemplate,
) =>
  request.post(
    isTemplate ? `/page-template/${id}/preview` : `/page/${id}/preview`,
    {
      editor_api_page_content: {
        content: JSON.stringify({
          entities,
          seo,
          tracking,
          globalSettings,
        }),
        locale,
      },
    },
  )

export const savePage = (page, isTemplate) => {
  return request.post(
    isTemplate ? `/page-template/${page.id}/save` : `/page/${page.id}/save`,
    {
      editor_api_page_content: {
        content: JSON.stringify({
          entities: page.entities,
          lastPopupNumber: page.lastPopupNumber,
          id: page.id,
          seo: page.seo,
          tracking: page.tracking,
          globalColor: page.globalColor,
          isTemplate: page.isTemplate,
          doubleOptIn: page.doubleOptIn,
          globalSettings: page.globalSettings,
        }),
        locale: page.locale,
      },
    },
  )
}

export const fetchPageAutomationRulesData = pageId => {
  return request.get(`/automation-rules-with-button-id-by-page-id/${pageId}`)
}

export const savePageAutomationRulesData = (pageId, payload) => {
  return request.post(`/update-automation-rules-with-page/${pageId}`, payload)
}
