import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getIsUnsaved } from 'client/reducers/pageReducer'

const useBeforeunload = (handler = () => {}) => {
  if (process.env.NODE_ENV !== 'production' && typeof handler !== 'function') {
    throw new TypeError(
      `Expected "handler" to be a function, not ${typeof handler}.`,
    )
  }

  const handlerRef = useRef(handler)
  const isUnsaved = useSelector(state => getIsUnsaved(state.page.present))

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const handleBeforeunload = event => {
      let returnValue

      if (typeof handlerRef.current === 'function') {
        returnValue = handlerRef.current(event)
      }

      if (event.defaultPrevented) {
        event.returnValue = ''
      }

      if (typeof returnValue === 'string') {
        event.returnValue = returnValue
        return returnValue
      }
    }

    if (isUnsaved) {
      window.addEventListener('beforeunload', handleBeforeunload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
  }, [isUnsaved])
}

export default useBeforeunload
