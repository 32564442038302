import React from 'react'
import { bulletListIcons } from 'common/constants/iconList'
import { BulletListInterface } from 'common/types/entities/BulletListInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { FontUpdate } from 'client/components/core/Sidebar/components/Settings/components/Font/BaseFont'
import HeadingFont from 'client/components/core/Sidebar/components/Settings/components/Font/HeadingFont'
import IconPicker from 'client/components/core/Sidebar/components/Settings/components/IconPicker'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range/Range'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DelayOption from 'client/components/core/Sidebar/components/Settings/options/DelayOption'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import { FontSize } from 'client/components/core/Sidebar/components/Settings/styles'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import TextLineHeight from 'client/components/core/Sidebar/components/Settings/styles/TextLineHeight'
import VerticalSpacing from 'client/components/core/Sidebar/components/Settings/styles/VerticalSpacing'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

export interface BulletListSettingsProps {
  entity: BulletListInterface
}

const NewBulletListSettings = ({ entity }: BulletListSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<BulletListInterface>()
  const { isMobile } = useDeviceModeContext()

  const fontFamily = isMobile
    ? entity.mobileFontFamily || entity.fontFamily
    : entity.fontFamily
  const fontStyle = isMobile
    ? entity.mobileFontStyle || entity.fontStyle
    : entity.fontStyle
  const fontWeight = isMobile
    ? entity.mobileFontWeight || entity.fontWeight
    : entity.fontWeight

  function handleFontChange(fontUpdate: FontUpdate) {
    if (isMobile) {
      if (fontUpdate.fontFileId) {
        delete entity.mobileFontStyle
        delete entity.mobileFontWeight
        updateEntity({
          ...entity,
          mobileFontFamily: fontUpdate.fontFamily,
          mobileFontFileId: fontUpdate.fontFileId,
        })
      } else {
        if (entity.mobileFontFileId) {
          delete entity.mobileFontFileId
        }
        updateEntity({
          ...entity,
          mobileFontFamily: fontUpdate.fontFamily,
          ...(fontUpdate.fontStyle
            ? { mobileFontStyle: fontUpdate.fontStyle }
            : {}),
          ...(fontUpdate.fontWeight
            ? { mobileFontWeight: fontUpdate.fontWeight }
            : {}),
        })
      }
    } else {
      if (fontUpdate.fontFileId) {
        delete entity.fontStyle
        delete entity.fontWeight
        updateEntity({
          ...entity,
          fontFamily: fontUpdate.fontFamily,
          fontFileId: fontUpdate.fontFileId,
        })
      } else {
        if (entity.fontFileId) {
          delete entity.fontFileId
        }
        updateEntity({
          ...entity,
          fontFamily: fontUpdate.fontFamily,
          ...(fontUpdate.fontStyle ? { fontStyle: fontUpdate.fontStyle } : {}),
          ...(fontUpdate.fontWeight
            ? { fontWeight: fontUpdate.fontWeight }
            : {}),
        })
      }
    }
  }

  return (
    <React.Fragment>
      <GroupTitle>typography</GroupTitle>
      <HeadingFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <TextLineHeight
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <VerticalSpacing
        change={updateProp(
          isMobile ? 'mobileVerticalSpacing' : 'verticalSpacing',
        )}
        verticalSpacing={
          isMobile ? entity.mobileVerticalSpacing : entity.verticalSpacing
        }
      />
      <Range
        label="entity_options.icon_size.title"
        value={entity.listItemIconSize}
        change={updateProp('listItemIconSize')}
        min={8}
      />
      <IconPicker
        labelText="entity_options.icon_picker.title"
        iconList={bulletListIcons}
        value={entity.listItemClassName}
        update={updateProp('listItemClassName')}
        searchPlaceholder="settings.components.icon_picker.placeholder"
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="bg color"
      />
      <ColorPicker
        update={updateProp('listItemIconColor')}
        mobileUpdate={updateProp('mobileListItemIconColor')}
        color={entity.listItemIconColor}
        mobileColor={entity.mobileListItemIconColor}
        label="icon color"
      />

      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity?.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <DelayOption update={updateProp('delay')} delay={entity.delay} />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </React.Fragment>
  )
}

export default NewBulletListSettings
