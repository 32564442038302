import styled from 'styled-components'

const PageUi = styled.div`
  overflow-y: scroll;
  height: 100%;
  scroll-behavior: smooth;
  font-family: initial;
  img {
    //width: 100%; /*raw html image case*/
  }
`

export default PageUi
