import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import GroupTitle from '../styleComponents/GroupTitle'
import MobileIconUi from '../styles/ui/MobileIconUi'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const marginTop = 'marginTop'
const marginBottom = 'marginBottom'
const marginLeft = 'marginLeft'
const marginRight = 'marginRight'

function MarginsStyle(props) {
  const { isMobile } = useDeviceModeContext()
  const getMargin = type =>
    parseInt(
      isMobile ? props[`mobile${capitalizeFirstLetter(type)}`] : props[type],
      0,
    )

  const update = style => value =>
    isMobile
      ? props.mobileUpdate(style, 'px')(value)
      : props.update(style, 'px')(value)

  return (
    <React.Fragment>
      <GroupTitle>settings_styles.margin.label</GroupTitle>
      <Range
        labelText="settings_styles.margin.top"
        labelIcon={isMobile && <MobileIconUi />}
        value={getMargin(marginTop)}
        update={update(marginTop)}
        min={0}
        max={200}
      />
      <Range
        labelText="settings_styles.margin.bottom"
        labelIcon={isMobile && <MobileIconUi />}
        value={getMargin(marginBottom)}
        update={update(marginBottom)}
        min={0}
        max={200}
      />
      <Range
        labelText="settings_styles.margin.left"
        labelIcon={isMobile && <MobileIconUi />}
        value={getMargin(marginLeft)}
        update={update(marginLeft)}
        min={0}
        max={200}
      />
      <Range
        labelText="settings_styles.margin.right"
        labelIcon={isMobile && <MobileIconUi />}
        value={getMargin(marginRight)}
        update={update(marginRight)}
        min={0}
        max={200}
      />
    </React.Fragment>
  )
}

MarginsStyle.propTypes = {
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileMarginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileMarginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileMarginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

MarginsStyle.defaultProps = {
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  mobileMarginTop: 0,
  mobileMarginBottom: 0,
  mobileMarginLeft: 0,
  mobileMarginRight: 0,
}

export default memo(MarginsStyle)
