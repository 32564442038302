import * as PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CoreButton from 'client/components/core/BlueButton'
import Tooltip from 'client/components/core/Tooltip'

const ButtonContainer = styled.div`
  padding: 10px 20px;
  position: relative;
  display: flex;
  align-items: center;
`

function Button(props) {
  const { children, helpMessage, ...rest } = props
  const { t } = useTranslation()
  return (
    <ButtonContainer>
      <CoreButton {...rest}>{t(children)}</CoreButton>
      {helpMessage && <Tooltip message={t(helpMessage)} />}
    </ButtonContainer>
  )
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  helpMessage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  small: PropTypes.bool,
}

export default Button
