import React from 'react'
import PropTypes from 'prop-types'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import usePresentPage from '../hooks/usePresentPage'
import { getGlobalFontFamily } from '../reducers/pageReducer'
import PopupFormUi from './ui/PopupFormUi'

function PopupForm({ children }) {
  const fontFamily = usePresentPage(getGlobalFontFamily)
  return (
    <PopupFormUi styles={{ fontFamily: wrapFontFamily(fontFamily) }}>
      {children}
    </PopupFormUi>
  )
}

PopupForm.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PopupForm
