import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TransformStyleEnum } from 'common/enums/TransformStyleEnum'
import { HoverTransformType } from 'common/types/styleTypes'
import { useDeviceModeContext } from '../../../../../../../context/DeviceModeContext'
import Range from '../../components/Range/Range'
import Select, {
  ChangeOptionValue,
} from '../../components/ReactSelect/ReactSelect'
import MobileIconUi from '../ui/MobileIconUi'
import { TransformStyleLocoKeys } from './constants/TransformLocoKeys'

interface HoverTransformStyleProps {
  transformHover?: HoverTransformType
  mobileTransformHover?: HoverTransformType
  update: (value: HoverTransformType) => void
  mobileUpdate: (value: HoverTransformType) => void
}

const getTransformStyleChoices = (t: TFunction) => [
  {
    label: t(TransformStyleLocoKeys.none),
    value: TransformStyleEnum.none,
  },
  {
    label: t(TransformStyleLocoKeys.scale),
    value: TransformStyleEnum.scale,
  },
  {
    label: t(TransformStyleLocoKeys.translateX),
    value: TransformStyleEnum.translateX,
  },
  {
    label: t(TransformStyleLocoKeys.translateY),
    value: TransformStyleEnum.translateY,
  },
]

function HoverTransformStyle({
  transformHover,
  mobileTransformHover,
  update,
  mobileUpdate,
}: HoverTransformStyleProps) {
  const { isMobile } = useDeviceModeContext()
  const { t } = useTranslation()

  const updateTransform = (option: ChangeOptionValue<TransformStyleEnum>) => {
    if (isMobile) {
      mobileUpdate({ ...mobileTransformHover, transform: option?.value })
    } else {
      update({
        ...transformHover,
        transform: option?.value,
      })
    }
  }

  const updateOffsetHover = (value: number) => {
    if (isMobile) {
      mobileUpdate({ ...mobileTransformHover, offset: value })
    } else {
      update({
        ...transformHover,
        offset: value,
      })
    }
  }

  const transformStyleChoices = getTransformStyleChoices(t)

  const value = isMobile
    ? mobileTransformHover?.transform
    : transformHover?.transform
  const selectedOption =
    transformStyleChoices.find(
      transformStyle => transformStyle.value === value,
    ) || transformStyleChoices[0]

  return (
    <>
      <Select<TransformStyleEnum>
        labelText="settings_styles.hover_transform.select_label"
        options={getTransformStyleChoices(t)}
        selectedOption={selectedOption}
        labelIcon={isMobile ? <MobileIconUi /> : undefined}
        update={updateTransform}
        isMulti={false}
      />
      {transformHover?.transform !== TransformStyleEnum.none && (
        <>
          <Range
            label={
              transformHover?.transform === TransformStyleEnum.scale
                ? t('settings_styles.hover_transform.input_zoom_label')
                : t('settings_styles.hover_transform.input_rate_label')
            }
            labelIcon={isMobile ? <MobileIconUi /> : null}
            value={
              isMobile ? mobileTransformHover?.offset : transformHover?.offset
            }
            change={updateOffsetHover}
            min={
              transformHover?.transform === TransformStyleEnum.scale ? 0 : -20
            }
            max={20}
          />
        </>
      )}
    </>
  )
}

export default HoverTransformStyle
