import { CountdownActionsEnum } from 'common/components/entities/Countdown/enums/CountdownActionsEnum'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { getDateIn10Days } from 'client/components/core/Sidebar/components/Settings/entities/Countdown/utils/date-time-utils'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createCountdown = (
  parentId: string,
  masterBlockId?: string,
): CountdownInterface => {
  return {
    ...generateBaseEntity(EntityTypeEnum.Countdown, parentId, masterBlockId),
    type: EntityTypeEnum.Countdown,
    countdownType: CountdownTypesEnum.fixed,
    [CountdownTypesEnum.fixed]: { date: getDateIn10Days() },
    [CountdownTypesEnum.delay]: {
      days: 0,
      hours: 1,
      minutes: 0,
      seconds: 0,
      createdAt: new Date().getTime(),
    },
    [CountdownTypesEnum.daily]: {
      hours: 23,
      minutes: 59,
      seconds: 59,
      createdAt: new Date().getTime(),
    },
    action: CountdownActionsEnum.nothing,
    redirectUrl: '',
    timeStyles: {
      color: 'rgba(208, 2, 27, 1)',
      fontSize: 20,
      mobileColor: 'rgba(208, 2, 27, 1)',
      mobileFontSize: 20,
    },
    labelStyles: {
      color: 'rgba(155, 155, 155, 1)',
      fontSize: 16,
      mobileColor: 'rgba(208, 2, 27, 1)',
      mobileFontSize: 16,
    },
  }
}
