import styled from 'styled-components'

const ToolboxItemTitleUi = styled.span`
  display: flex;
  align-items: center;
  color: var(--default-text-color);
  font-size: var(--default-font-size);
  flex-basis: 40%;
`

export default ToolboxItemTitleUi
