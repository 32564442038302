import { MenuTargetEnum } from 'common/components/entities/Menu/enums/MenuTargetEnum'
import MenuInterface, {
  BaseMenuItemInterface,
  MenuItemInterface,
} from 'common/components/entities/Menu/types/MenuInterface'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'

export const useUpdateMenuItem = ({ entity }: { entity: MenuInterface }) => {
  const updateProp = useUpdateProp(entity)
  const updateMenuItem = (index: number) => (item: MenuItemInterface) => {
    updateProp('menuItems')([
      ...entity.menuItems.slice(0, index),
      item,
      ...entity.menuItems.slice(index + 1),
    ])
  }
  const updateSubMenuItem =
    (indexOfMenuItem: number, indexOfSubMenuItem: number) =>
    (newItem: BaseMenuItemInterface) => {
      updateProp('menuItems')(
        entity.menuItems.map((item, index) => {
          if (index !== indexOfMenuItem) return item
          return {
            ...item,
            subMenuItems: item.subMenuItems?.map((subItem, subIndex) => {
              if (subIndex !== indexOfSubMenuItem) return subItem
              return newItem
            }),
          }
        }),
      )
    }

  const addMenuItem = () => {
    updateProp('menuItems')([
      ...entity.menuItems,
      {
        text: '',
        link: '',
        subMenuItems: [],
        target: MenuTargetEnum._self,
        subMenuOpen: false,
      },
    ])
  }

  const addSubMenuItem = (indexOfMenuItem: number) => {
    updateProp('menuItems')(
      entity.menuItems.map((item, index) => {
        if (index !== indexOfMenuItem) return item
        const newItem = { text: '', link: '', target: MenuTargetEnum._self }
        return {
          ...item,
          subMenuItems: item.subMenuItems
            ? [...item.subMenuItems, newItem]
            : [newItem],
        }
      }),
    )
  }

  const sortDown = (index: number) => () => {
    updateProp('menuItems')([
      ...entity.menuItems.slice(0, index),
      entity.menuItems[index + 1],
      entity.menuItems[index],
      ...entity.menuItems.slice(index + 2),
    ])
  }

  const sortUp = (index: number) => () => {
    updateProp('menuItems')([
      ...entity.menuItems.slice(0, index - 1),
      entity.menuItems[index],
      entity.menuItems[index - 1],
      ...entity.menuItems.slice(index + 1),
    ])
  }

  const removeMenuItem = (index: number) => {
    updateProp('menuItems')(entity.menuItems.filter((_, i) => i !== index))
  }

  const removeSubMenuItem = (
    indexOfMenuItem: number,
    indexOfSubMenuItem: number,
  ) => {
    updateProp('menuItems')(
      entity.menuItems.map((item, index) => {
        if (index !== indexOfMenuItem) return item
        return {
          ...item,
          subMenuItems: item.subMenuItems?.filter(
            (_, subIndex) => indexOfSubMenuItem !== subIndex,
          ),
        }
      }),
    )
  }

  return {
    updateMenuItem,
    sortDown,
    sortUp,
    removeMenuItem,
    addMenuItem,
    addSubMenuItem,
    updateSubMenuItem,
    removeSubMenuItem,
  }
}
