import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { RawHtmlInterface } from 'common/types/entities/RawHtmlInterface'
import * as managementActions from 'client/actions/actionsManagement'
import useManagement, {
  selectors as managementSelectors,
} from 'client/hooks/useManagement'
import { useDeviceModeContext } from '../../../../../../context/DeviceModeContext'
import { AsyncCodeEditor } from '../../../../CodeEditor'
import { Button } from '../components'
import { useUpdateProp } from '../hooks/useUpdateProps'
import DeviceAppearanceNew from '../options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from '../options/HtmlAttrId'
import MarginStyle from '../styles/MarginStyle'
import TextAlignStyle from '../styles/TextAlignStyle'

function NewRawHtmlSettings({ entity }: { entity: RawHtmlInterface }) {
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()

  const [AceEditor, setAceEditor] = useState<AsyncCodeEditor | null>(null)
  const isCodeDialogPortalAllowed = useManagement(
    managementSelectors.isCodeDialogPortalAllowed,
  )

  const dispatch = useDispatch()

  const loadCodeEditor = async () => {
    const { default: aceEditor } = await import(
      /* webpackChunkName: "code-editor" */ 'client/components/core/CodeEditor'
    )
    setAceEditor(() => aceEditor)
  }

  const openCodeEditor = async () => {
    if (!AceEditor) {
      await loadCodeEditor()
    }
    dispatch(managementActions.showCodeDialog())
  }

  return (
    <React.Fragment>
      <TextAlignStyle
        textAlign={entity.textAlign}
        mobileTextAlign={entity.mobileTextAlign || entity.textAlign}
        update={updateProp('textAlign')}
        mobileUpdate={updateProp('mobileTextAlign')}
      />
      <Button
        small
        onClick={openCodeEditor}
        helpMessage="entity_settings.raw_html.description"
      >
        entity_settings.raw_html.edit_label
      </Button>
      {isCodeDialogPortalAllowed && AceEditor && (
        <AceEditor
          label="entity_settings.raw_html.label"
          value={entity.html}
          handleChange={updateProp('html')}
        />
      )}
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </React.Fragment>
  )
}

export default NewRawHtmlSettings
