import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import OptionWrapper from '../styleComponents/OptionWrapper'

const StringUi = styled.div`
  font-size: 14px;
  font-style: italic;
`

const Icon = styled.i<{ highlighted: boolean }>`
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.1s linear;
  &:hover {
    transform: scale(1.2);
  }
  color: ${p => (p.highlighted ? 'var(--default-blue-color)' : 'inherited')};
`

type StringProps = {
  value: string
  labelText: string
  helpMessage: string
}

function String({ value, labelText, helpMessage }: StringProps) {
  const [highlighted, setHighlighted] = useState(false)

  async function copyTextToClipboard(text: string) {
    setHighlighted(true)
    await navigator.clipboard.writeText(text)
  }

  return (
    <OptionWrapper labelText={labelText} helpMessage={helpMessage}>
      <StringUi>
        {value}{' '}
        <Icon
          className="far fa-copy"
          highlighted={highlighted}
          onClick={() => copyTextToClipboard(value)}
        />
      </StringUi>
    </OptionWrapper>
  )
}

String.propTypes = {
  value: PropTypes.string.isRequired,
  helpMessage: PropTypes.string,
  labelText: PropTypes.string,
}

String.defaultProps = {
  helpMessage: '',
  labelText: '',
}

export default String
