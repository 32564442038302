import styled from 'styled-components'

type ColorStopUiProps = {
  color: string
}
const ColorStopUi = styled.div<ColorStopUiProps>`
  height: 8px;
  left: 2px;
  width: 7px;
  position: absolute;
  top: 8px;
  background-color: ${p => p.color};
`
export default ColorStopUi
