import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import WebinarRegistrationDateInterface from 'common/types/entities/WebinarRegistrationDateInterface'
import createCommonEntitySettings from '../createCommonEntitySettings'
import WebinarRegistrationDateSettings from './settings/WebinarRegistrationDateSettings'

function createSettingsForWebinarRegistration(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.WebinarRegistrationDate:
      return (
        <WebinarRegistrationDateSettings
          entity={entity as WebinarRegistrationDateInterface}
        />
      )
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForWebinarRegistration
