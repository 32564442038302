import PropTypes from 'prop-types'
import React, { memo } from 'react'
import * as types from 'common/types'
import Field from 'client/components/entities/Field'
import { useCustomLoginDescendantIds } from './CustomLogin'

function CustomLoginField({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  const entityIds = useCustomLoginDescendantIds()
  return (
    <Field
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      isRemoveAllowed={false}
      isSaveAllowed={false}
      isCopyAllowed={false}
      dropBoxAllowedEntityIds={entityIds}
    />
  )
}

CustomLoginField.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default memo(CustomLoginField)
