import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CoreButton from '../../../../BlueButton'

const PrimaryButtonUi = styled.div`
  padding: 20px;
`

function PrimaryButton({ children, onClick }) {
  const { t } = useTranslation()
  return (
    <PrimaryButtonUi>
      <CoreButton
        onClick={onClick}
        styles={{ width: '100%', textTransform: 'uppercase' }}
      >
        {t(children)}
      </CoreButton>
    </PrimaryButtonUi>
  )
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default PrimaryButton
