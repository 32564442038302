import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import MobileIconUi from '../styles/ui/MobileIconUi'

const browserDefaultLineHeight = 1.3

function LineHeight(props) {
  const { isMobile } = useDeviceModeContext()
  const lineHeight = isMobile ? props.mobileLineHeight : props.lineHeight
  const getFontSize = () =>
    parseInt(isMobile ? props.mobileFontSize : props.fontSize, 0)
  const computedLineHeight = Math.round(
    browserDefaultLineHeight * getFontSize(),
  )
  const calculatedLineHeight = lineHeight
    ? parseInt(lineHeight, 0)
    : computedLineHeight

  const update = value => {
    if (value < 8) {
      return
    }

    isMobile ? props.mobileUpdate(value) : props.update(value)
  }

  return (
    <Range
      labelText="settings_styles.line_height.label"
      labelIcon={isMobile && <MobileIconUi />}
      value={calculatedLineHeight}
      update={update}
      min={8}
    />
  )
}

LineHeight.propTypes = {
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileLineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

LineHeight.defaultProps = {
  fontSize: '18px',
  mobileFontSize: '18px',
  lineHeight: null,
  mobileLineHeight: null,
}

export default memo(LineHeight)
