import {
  convertFromRaw,
  Editor,
  EditorState,
  RichUtils,
  KeyBindingUtil,
} from 'draft-js'
import PropTypes from 'prop-types'
import React, { Component, createRef } from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { wrapFontFamilyByStyles } from 'common/components/entities/Text/utils'
import { parseToObject } from 'common/utils/colorHelpers'
import { toggleSettingsOverlay } from 'client/actions/actionsManagement'
import HeadersControls from 'client/components/core/TextEditor/menu/HeadersControls'
import InlineStyleControls from 'client/components/core/TextEditor/menu/InlineStyleControls'
import MenuButton from 'client/components/core/TextEditor/menu/MenuButton'
import * as textUtils from 'client/components/core/TextEditor/utils'
import Color from 'client/components/core/TextEditorNew/Menu/ColorPicker'
import Menu from '../TextEditorNew/Menu'
import { filterBlockTypes, filterInlineStyles } from '../TextEditorNew/utils'
import MenuLinkNew from './MenuNew/MenuLink'
import createLinkDecorator from './linkDecorator'
import LinkEditOverlayUi from './ui/LinkEditOverlayUi'
import TextEditorUi from './ui/TextEditorUi'

// @see FF bug https://bugzilla.mozilla.org/show_bug.cgi?id=800050
const toggleAllDraggable = val => {
  const draggableNodeList = document.querySelectorAll('[draggable]')
  for (let i = 0; i < draggableNodeList.length; i++) {
    const node = draggableNodeList[i]
    node.setAttribute('draggable', val)
  }
}

class TextEditor extends Component {
  constructor(props) {
    super(props)
    this.editor = createRef()
    const decorator = createLinkDecorator(this.handleLinkClick)
    let editorState
    try {
      editorState = props.rawContentState
        ? textUtils.createStateFromRaw(props.rawContentState, decorator)
        : EditorState.createEmpty(decorator)
    } catch (e) {
      editorState = EditorState.createEmpty(decorator)
    }

    this.state = {
      editorState,
      currentLink: null,
      showLinkSettings: false,
      linkPrevColor: null,
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.textWrapperProps.listItemClassName &&
      prevProps.textWrapperProps.listItemClassName !==
        this.props.textWrapperProps.listItemClassName
    ) {
      this.forceRender()
    }

    if (!prevProps.isEditing && this.props.isEditing) {
      this.focusEditor()
    }
    // clean up state for blur hack
    if (
      prevProps.isEditing &&
      !this.props.isEditing &&
      this.state.currentLink
    ) {
      this.removeLinkIfNoUrlAndPopup()
      this.setState({ currentLink: null })
    }
  }

  // @see https://github.com/facebook/draft-js/issues/458
  forceRender = () => {
    const editorState = this.state.editorState
    const content = editorState.getCurrentContent()
    const newEditorState = EditorState.createWithContent(
      content,
      createLinkDecorator(this.handleLinkClick),
    )
    this.setState({ editorState: newEditorState })
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.handleEditorChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  handleEditorChange = editorState => {
    let filteredState = editorState

    const shouldFilterPaste =
      editorState.getCurrentContent() !==
        this.state.editorState.getCurrentContent() &&
      editorState.getLastChangeType() === 'insert-fragment'

    if (shouldFilterPaste) {
      let filteredContentState = filterInlineStyles(
        ['BOLD', 'ITALIC', 'STRIKETHROUGH', 'UNDERLINE', '^rgba'],
        filteredState.getCurrentContent(),
      )
      filteredContentState = filterBlockTypes(
        ['unordered-list-item'],
        filteredContentState,
      )
      filteredState = EditorState.set(filteredState, {
        currentContent: filteredContentState,
      })
      this.setState({ editorState: filteredState })
    } else {
      const prevInlineStyles = this.state.editorState
        .getCurrentInlineStyle()
        .toJS()
      if (
        !editorState.getCurrentContent().hasText() &&
        prevInlineStyles.length > 0
      ) {
        this.setState({
          editorState: prevInlineStyles.reduce(
            RichUtils.toggleInlineStyle,
            editorState,
          ),
        })
      } else {
        this.setState({ editorState })
      }
    }
  }

  handleLinkClick = () => {
    const { editorState } = this.state
    const linkKey = textUtils.getSelectionEntityKeyOrNull(editorState)

    if (!linkKey) {
      return
    }

    const newEditorState = textUtils.selectAnchorWord(editorState)

    this.setState(
      {
        editorState: newEditorState,
        showLinkSettings: true,
        currentLink: linkKey,
      },
      this.editor.current.blur,
    )
  }

  handleOnBlur = () => {
    if (!this.state.showLinkSettings) {
      toggleAllDraggable(true)
      this.save()
    }
  }

  handleColorChange = color => {
    const editorState = textUtils.toggleColor(this.state.editorState, color)
    this.handleEditorChange(editorState)
    this.save(editorState)
  }

  removeColor = color => {
    let editorState = RichUtils.toggleInlineStyle(this.state.editorState, color)
    this.handleEditorChange(editorState)
    this.save(editorState)
  }

  save = editorState => {
    const newEditorState = editorState || this.state.editorState
    const changedContentState = newEditorState.getCurrentContent()

    let initialContentState
    try {
      initialContentState = convertFromRaw(
        JSON.parse(this.props.rawContentState),
      )
    } catch (e) {
      // attempt to catch https://rollbar.com/melnikoved_2/Editor/items/1066/occurrences/172486656837/
      Rollbar.error("Couldn't convert initial content state from raw", {
        rawContentState: this.props.rawContentState,
      })
      initialContentState = EditorState.createEmpty().getCurrentContent()
    }

    const equals = initialContentState
      .getBlockMap()
      .equals(changedContentState.getBlockMap())

    if (!equals) {
      this.props.update(textUtils.getRawContentState(newEditorState))
    }
  }

  handleCloseColorPicker = () => {
    this.save()
  }

  focusEditor = () => {
    toggleAllDraggable(false)
    this.editor.current.focus()
  }

  updateLink = data => {
    const newContentState = this.state.editorState
      .getCurrentContent()
      .mergeEntityData(this.state.currentLink, data)

    const newEditorState = EditorState.push(
      this.state.editorState,
      newContentState,
      'change-block-data',
    )
    this.handleEditorChange(newEditorState)
    // because mergeEntityData mutate editorState and isEqual check returns TRUE
    // this.forceUpdate()
    this.save()
  }

  toggleInlineStyle = inlineStyle => {
    this.handleEditorChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
    )
  }

  toggleHeaderType = (styles, mobileStyles, headerType) => {
    this.props.updateStyles(
      {
        ...this.props.styles,
        ...styles,
      },
      mobileStyles,
    )
    this.handleEditorChange(
      RichUtils.toggleBlockType(this.state.editorState, headerType),
    )
  }

  createLinkAndShowLinkSettings = state => e => {
    e.preventDefault()
    let targetEditorState = state
    targetEditorState = textUtils.selectCurrentWord(targetEditorState)
    const { linkColor } = this.props.textWrapperProps
    if (linkColor && parseToObject(linkColor).a > 0) {
      const color = textUtils.getCurrentColor(targetEditorState)
      if (color) {
        targetEditorState = RichUtils.toggleInlineStyle(
          targetEditorState,
          color,
        )
        this.setState({ linkPrevColor: color }) // we can restore this color if link will not be created
      }
    }

    const { linkKey, editorState } = textUtils.createLink(targetEditorState)
    this.setState({
      editorState,
      currentLink: linkKey,
      showLinkSettings: true,
    })
  }

  removeLinkIfNoUrlAndPopup() {
    if (this.state.currentLink) {
      const linkData = textUtils.getEntityData(
        this.state.editorState,
        this.state.currentLink,
      )
      if (!linkData.popupId && !linkData.url) {
        this.removeLink()
      }
    }
  }

  menuLinkClose = () => {
    this.setState({
      showLinkSettings: false,
    })
    // it will close overlay with a color picker
    this.props.toggleSettingsOverlay()
    this.removeLinkIfNoUrlAndPopup()
    this.save()
  }

  removeLink = () => {
    const { editorState, currentLink, linkPrevColor } = this.state
    let updatedEditorState = textUtils.removeLink(editorState, currentLink)
    if (updatedEditorState) {
      // restore prev color
      updatedEditorState = RichUtils.toggleInlineStyle(
        updatedEditorState,
        linkPrevColor,
      )
      this.setState({
        editorState: updatedEditorState,
        showLinkSettings: false,
      })
    }
  }

  getGlobalColorIfLink = editorState => {
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const endOffset = selection.getEndOffset()
    const currentContent = editorState.getCurrentContent()
    let isContainsLink = false
    const selectedBlock = currentContent.getBlockForKey(startKey)
    selectedBlock.findEntityRanges(
      ch =>
        ch.getEntity() &&
        currentContent.getEntity(ch.getEntity()).getType() === 'LINK',
      (start, end) => {
        if (start <= startOffset && endOffset <= end) {
          isContainsLink = true
        }
      },
    )
    return isContainsLink ? this.props.textWrapperProps.linkColor : null
  }

  getGlobalColorIfText = editorState => {
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const endOffset = selection.getEndOffset()
    const currentContent = editorState.getCurrentContent()
    let isText = true
    const selectedBlock = currentContent.getBlockForKey(startKey)
    selectedBlock.findEntityRanges(
      ch =>
        ch.getEntity() &&
        currentContent.getEntity(ch.getEntity()).getType() === 'LINK',
      (start, end) => {
        if (startOffset === start && endOffset === end) {
          isText = false
        }
      },
    )
    return isText ? this.props.textWrapperProps.textColor : null
  }

  handleReturn = event => {
    const { editorState } = this.state
    if (KeyBindingUtil.isSoftNewlineEvent(event)) {
      this.handleEditorChange(RichUtils.insertSoftNewline(editorState))
      return 'handled'
    }

    if (this.props.handleReturn) {
      const handledEditorState = this.props.handleReturn(editorState)
      if (handledEditorState) {
        this.handleEditorChange(handledEditorState)
        return 'handled'
      }
    }

    return 'not-handled'
  }

  render() {
    const {
      isEditing,
      styles,
      blockStyleFn,
      textWrapperProps,
      enableHeadings,
      blockRendererFn,
    } = this.props

    const { editorState, showLinkSettings, currentLink } = this.state
    let settingsContainer
    if (isEditing) {
      settingsContainer = document.getElementById('text-color-settings')
    }
    return (
      <React.Fragment>
        {isEditing && (
          <React.Fragment>
            {!showLinkSettings && (
              <Menu extended={enableHeadings}>
                <div>
                  {enableHeadings && (
                    <HeadersControls
                      onToggle={this.toggleHeaderType}
                      currentType={textUtils.getCurrentBlockType(editorState)}
                    />
                  )}
                  <InlineStyleControls
                    currentStyle={editorState.getCurrentInlineStyle()}
                    onToggle={this.toggleInlineStyle}
                  />
                </div>
                <Color
                  color={
                    textUtils.getCurrentColor(editorState) ||
                    this.getGlobalColorIfLink(editorState) ||
                    this.getGlobalColorIfText(editorState)
                  }
                  onChange={this.handleColorChange}
                />
                <MenuButton
                  onToggle={this.createLinkAndShowLinkSettings(editorState)}
                >
                  <span className="fas fa-link" />
                </MenuButton>
              </Menu>
            )}
            {showLinkSettings && (
              <Menu extended>
                <MenuLinkNew
                  remove={this.removeLink}
                  currentLink={textUtils.getEntityData(
                    editorState,
                    currentLink,
                  )}
                  update={this.updateLink}
                  close={this.menuLinkClose}
                />
              </Menu>
            )}
          </React.Fragment>
        )}
        <TextEditorUi
          isEditing={isEditing}
          styles={wrapFontFamilyByStyles(styles)}
          ref={this.props.addToggleableNode}
          onMouseDown={this.focusEditor}
          {...textWrapperProps}
          fontFamily={styles.fontFamily}
        >
          <Editor
            customStyleFn={textUtils.applyCustomStyle}
            onBlur={this.handleOnBlur}
            ref={this.editor}
            editorState={editorState}
            onChange={this.handleEditorChange}
            handleReturn={this.handleReturn}
            blockStyleFn={blockStyleFn}
            textAlignment={styles.textAlign}
            handleKeyCommand={this.handleKeyCommand}
            blockRendererFn={blockRendererFn}
            // stripPastedStyles // we should write down at least an example why we strip styles
          />
          {showLinkSettings && (
            <LinkEditOverlayUi onClick={this.menuLinkClose} />
          )}
        </TextEditorUi>
      </React.Fragment>
    )
  }
}

TextEditor.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  updateStyles: PropTypes.func,
  rawContentState: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  blockStyleFn: PropTypes.func,
  blockRendererFn: PropTypes.func,
  textWrapperProps: PropTypes.objectOf(PropTypes.any),
  enableHeadings: PropTypes.bool,
  addToggleableNode: PropTypes.func.isRequired,
  handleReturn: PropTypes.func,
}

TextEditor.defaultProps = {
  rawContentState: '',
  styles: {},
  textWrapperProps: {},
  blockStyleFn: () => {},
  enableHeadings: false,
  updateStyles: () => {},
  blockRendererFn: () => {},
}

export default connect(null, { toggleSettingsOverlay })(TextEditor)
