import { pageTypes } from 'common/constants/pageTypes'
import structureTypes from 'common/constants/structureTypes'
import { PageTypeEnum } from '../enums/PageTypeEnum'
import EntityTypeEnum from '../enums/entityTypeEnum'

const contactUsEntities = [
  EntityTypeEnum.ContactUs,
  EntityTypeEnum.Field,
  EntityTypeEnum.ContactUsField,
  EntityTypeEnum.TextArea,
  EntityTypeEnum.Attachments,
  EntityTypeEnum.Recaptcha,
  EntityTypeEnum.ContactUsRecaptcha,
  EntityTypeEnum.Button,
]

const common = [
  structureTypes.SECTION,
  EntityTypeEnum.Section,
  structureTypes.ROW,
  EntityTypeEnum.Row,
  structureTypes.RAW_HTML,
  structureTypes.BODY,
  structureTypes.ROW,
  structureTypes.COLUMN,
  EntityTypeEnum.Column,
  structureTypes.TEXT,
  structureTypes.BULLET_LIST,
  EntityTypeEnum.Headline,
  structureTypes.BUTTON,
  structureTypes.FORM_INPUT,
  structureTypes.IMAGE,
  structureTypes.POPUP,
  structureTypes.REMOTE_POPUP,
  structureTypes.INLINE,
  structureTypes.AUDIO,
  structureTypes.CONTENT_BOX,
  EntityTypeEnum.ContentBox,
  EntityTypeEnum.FacebookComments,
  structureTypes.RAW_HTML,
  structureTypes.EXPLICIT_CONSENT,
  structureTypes.COUNTDOWN,
  EntityTypeEnum.Countdown,
  EntityTypeEnum.Menu,
  EntityTypeEnum.HorizontalLine,
  structureTypes.LANGUAGE_SWITCHER,
  EntityTypeEnum.PricePlanCalculator,
  structureTypes.TWITTER_TWEET_BUTTON,
  EntityTypeEnum.TwitterTweetButton,
  structureTypes.SURVEY,
  EntityTypeEnum.Carousel,
  EntityTypeEnum.CarouselItem,
  EntityTypeEnum.Faq,
  EntityTypeEnum.FaqItem,
  EntityTypeEnum.Text,
  EntityTypeEnum.Image,
  EntityTypeEnum.Survey,
  EntityTypeEnum.Video,
  EntityTypeEnum.Audio,
  EntityTypeEnum.OptInRecaptcha,
  EntityTypeEnum.LanguageSwitcher,
  EntityTypeEnum.RawHtml,
  EntityTypeEnum.Checkbox,
  EntityTypeEnum.BulletList,
]

export function getPageTypeEntities(pageType) {
  let additionalEntities = []
  switch (pageType) {
    case pageTypes.offerInner:
    case pageTypes.offerPage:
      additionalEntities = [
        structureTypes.COUPON,
        structureTypes.PAYMENT_BUTTON,
        EntityTypeEnum.PaymentButton,
        structureTypes.CUSTOMER_TYPE,
        EntityTypeEnum.CustomerType,
        structureTypes.ORDER_BUMP,
        structureTypes.PHYSICAL_PRODUCT,
        structureTypes.OFFER_PRICE,
        EntityTypeEnum.OfferPrice,
        structureTypes.TWO_STEP_PAYMENT_FORM,
        structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN,
        structureTypes.TWO_STEP_PAYMENT_FORM_STEP_PAYMENT,
        structureTypes.PAYMENT_METHOD,
        EntityTypeEnum.PaymentMethod,
        structureTypes.AGREEMENT,
        EntityTypeEnum.Product,
        EntityTypeEnum.ShippingFees,
      ]
      break
    case pageTypes.salesPage:
      additionalEntities = [
        structureTypes.SALES_REDIRECTION_BUTTON,
        EntityTypeEnum.SalesRedirectionButton,
        structureTypes.PHYSICAL_PRODUCT,
        structureTypes.AGREEMENT,
        EntityTypeEnum.Product,
        EntityTypeEnum.ShippingFees,
        EntityTypeEnum.BookingCalendar,
      ]
      break
    case pageTypes.offerUpSellPage:
      additionalEntities = [
        EntityTypeEnum.UpsellAgreeButton,
        EntityTypeEnum.UpsellDisagreeButton,
        structureTypes.PHYSICAL_PRODUCT,
        structureTypes.OFFER_PRICE,
        EntityTypeEnum.OfferPrice,
        structureTypes.AGREEMENT,
        EntityTypeEnum.Product,
      ]
      break
    case pageTypes.offerThankYouPage:
      additionalEntities = [
        EntityTypeEnum.OrderSummary,
        structureTypes.AGREEMENT,
      ]
      break
    case pageTypes.customLogin:
      additionalEntities = [
        EntityTypeEnum.CustomLogin,
        EntityTypeEnum.CustomLoginForgotPassword,
      ]
      break
    case pageTypes.webinarRegistration:
      additionalEntities = [
        EntityTypeEnum.WebinarRegistrationDate,
        structureTypes.START_TIMER,
      ]
      break
    case pageTypes.webinarSession:
      additionalEntities = [
        structureTypes.WEBINAR_SESSION_CALL_TO_ACTION,
        structureTypes.WEBINAR_SESSION_VIDEO,
        EntityTypeEnum.WebinarSessionVideo,
        EntityTypeEnum.WebinarCallToAction,
      ]
      break
    case pageTypes.webinarThankYou:
      additionalEntities = [
        EntityTypeEnum.WebinarSessionLink,
        structureTypes.WEBINAR_SESSION_DATE_TIME,
        EntityTypeEnum.WebinarSessionDateTime,
      ]
      break
    case pageTypes.blogPostLayout:
      additionalEntities = [
        structureTypes.BLOG_POST_BODY,
        EntityTypeEnum.BlogPostContentPlaceholder,
        structureTypes.BLOG_POST_CONTENT_PLACEHOLDER,
        structureTypes.BLOG_POST_LAYOUT_BODY,
        structureTypes.BLOG_CONTENT_PLACEHOLDER,
        EntityTypeEnum.BlogContentPlaceholder,
        EntityTypeEnum.Breadcrumbs,
        EntityTypeEnum.BlogPostDate,
        EntityTypeEnum.ContentTable,
        EntityTypeEnum.ContactUs,
        EntityTypeEnum.BlogPostCategories,
        EntityTypeEnum.BlogPostTitle,
        EntityTypeEnum.BlogPostImage,
        EntityTypeEnum.LatestBlogPosts,
        EntityTypeEnum.LatestBlogPostsCarousel,
        ...contactUsEntities,
      ]
      break
    case pageTypes.blogHome:
    case pageTypes.blogStatic:
      additionalEntities = [
        EntityTypeEnum.BlogPostListing,
        EntityTypeEnum.LatestBlogPosts,
        structureTypes.BLOG_CONTENT_PLACEHOLDER,
        EntityTypeEnum.BlogContentPlaceholder,
        structureTypes.BLOG_PAGE_BODY,
        EntityTypeEnum.ContentTable,
        EntityTypeEnum.BlogCategoryTitle,
        EntityTypeEnum.BlogCategoryDescription,
        EntityTypeEnum.LatestBlogPostsCarousel,
        EntityTypeEnum.BookingCalendar,
        ...contactUsEntities,
      ]
      break
    case pageTypes.blogPostBody:
      additionalEntities = [
        EntityTypeEnum.ContentTable,
        structureTypes.BLOG_POST_BODY,
        EntityTypeEnum.BlogPostContentPlaceholder,
        structureTypes.BLOG_POST_CONTENT_PLACEHOLDER,
        structureTypes.BLOG_CONTENT_PLACEHOLDER,
        EntityTypeEnum.BlogContentPlaceholder,
        structureTypes.BLOG_POST_LAYOUT_BODY,
        EntityTypeEnum.Breadcrumbs,
        EntityTypeEnum.BlogPostDate,
        EntityTypeEnum.ContentTable,
        EntityTypeEnum.BlogPostCategories,
        EntityTypeEnum.BlogPostTitle,
        EntityTypeEnum.BlogPostImage,
        EntityTypeEnum.LatestBlogPosts,
        EntityTypeEnum.LatestBlogPostsCarousel,
        ...contactUsEntities,
      ]
      break
    case pageTypes.blogLayout:
      additionalEntities = [
        structureTypes.BLOG_CONTENT_PLACEHOLDER,
        EntityTypeEnum.BlogContentPlaceholder,
        ...contactUsEntities,
      ]
      break
    case PageTypeEnum.FunnelContactUs:
      additionalEntities = [
        EntityTypeEnum.ContactUs,
        EntityTypeEnum.Field,
        EntityTypeEnum.ContactUsField,
        EntityTypeEnum.TextArea,
        EntityTypeEnum.Attachments,
        EntityTypeEnum.Recaptcha,
        EntityTypeEnum.ContactUsRecaptcha,
        EntityTypeEnum.Button,
      ]
      break
    case PageTypeEnum.Lecture:
      additionalEntities = [
        EntityTypeEnum.ContentTable,
        EntityTypeEnum.TextArea,
        EntityTypeEnum.Button,
      ]
      break
    case PageTypeEnum.Inline:
      additionalEntities = [
        EntityTypeEnum.BookingCalendar,
      ]
      break
    case PageTypeEnum.Squeeze:
      additionalEntities = [
        EntityTypeEnum.BookingCalendar,
      ]
      break
    case PageTypeEnum.Popup:
      additionalEntities = [
        EntityTypeEnum.BookingCalendar,
      ]
      break
    default:
      additionalEntities = []
  }

  return common.concat(additionalEntities)
}
