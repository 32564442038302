import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonLoader from 'common/components/core/ButtonLoader'
import Checkbox from 'common/components/core/Checkbox'
import FormGroup from 'common/components/core/FormGroup'
import Input from 'common/components/core/Input'
import InputLabel from 'common/components/core/InputLabel'
import Select from 'common/components/core/Select'
import { BadRequest } from 'common/errors/BadRequest'
import { useActions } from 'common/hooks/useActions'
import { hideCreateBlockDialog } from 'client/actions/actionsManagement'
import * as blockActions from 'client/actions/blockActions'
import * as entityActions from 'client/actions/entityActions'
import * as blockApi from 'client/api/blockApi'
import BlueButton from 'client/components/core/BlueButton'
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from 'client/components/core/Dialog'
import { categoryNames } from 'client/components/core/Sidebar/components/Blocks/blockCategories'
import useFiles from 'client/hooks/useFiles'
import useManagement from 'client/hooks/useManagement'
import usePresentPage, {
  selectors as pageSelectors,
} from 'client/hooks/usePresentPage'
import { getFilesByIds } from 'client/reducers/filesReducer'
import {
  getCreateBlockDialogEntityId,
  isUserAdmin,
} from 'client/reducers/managementReducer'
import { findFileId } from 'client/reducers/pageReducer'
import { cloneStructure } from 'client/utils/cloneStructure'
import { localeNames } from '../../../../common/constants/localeTypes'
import { createPage } from '../../../pages'
import { toBase64 } from '../../../utils/filesHelper'
import Tooltip from '../Tooltip'
import CreateBlogDialogErrorMessageUi from './ui/CreateBlogDialogErrorMessageUi'
import CreateBlogDialogHeaderUi from './ui/CreateBlogDialogHeaderUi'
import CreateBlogDialogPreviewUi from './ui/CreateBlogDialogPreviewUi'
import CreateBlogDialogSuccessMessageUi from './ui/CreateBlogDialogSuccessMessageUi'

function cleanUpPossibleMasterBlockProperties(entity) {
  const entityToCleanUp = { ...entity }
  if (entityToCleanUp.isMasterBlockRoot) {
    delete entityToCleanUp.isMasterBlockRoot
  }

  if (entityToCleanUp.masterBlockId) {
    delete entityToCleanUp.masterBlockId
  }

  return entityToCleanUp
}

function CreateBlockDialog() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isCommonError, setIsCommonError] = useState(false)
  const [isCommon, setIsCommon] = useState(false)
  const [isMaster, setIsMaster] = useState(false)
  const [errors, setErrors] = useState([])
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('')
  const [desktopBlobFile, setDesktopBlobFile] = useState('')
  const [mobileBlobFile, setMobileBlobFile] = useState('')
  const desktopFileRef = useRef()
  const mobileFileRef = useRef()
  const [success, setSuccess] = useState(false)
  const addUserBlock = useActions(blockActions.addUserBlock)
  const addMasterBlock = useActions(blockActions.addMasterBlock)
  const addCommonBlock = useActions(blockActions.addCommonBlock)
  const addUserFile = useActions(blockActions.addUserBlockFile)
  const addCommonFile = useActions(blockActions.addCommonBlockFile)
  const addMasterFile = useActions(blockActions.addMasterBlockFile)
  const markAsMasterBlock = useActions(entityActions.markAsMasterBlock)
  const hide = useActions(hideCreateBlockDialog)
  const pageLocale = usePresentPage(pageSelectors.getPageLocale)
  const pageType = usePresentPage(pageSelectors.getPageType)
  const pageId = usePresentPage(pageSelectors.getPageId)
  const [locale, setLocale] = useState(pageLocale)
  const entityId = useManagement(getCreateBlockDialogEntityId)
  const isAdmin = useManagement(isUserAdmin)
  const entity = usePresentPage(page =>
    pageSelectors.getEntityById(page, entityId),
  )
  const descendants = usePresentPage(page =>
    pageSelectors.getAllDescendants(page, entityId),
  )
  const blockEntities = [...descendants, entity]
    .filter(Boolean)
    .map(cleanUpPossibleMasterBlockProperties)
  const filesIds = blockEntities.map(findFileId).filter(Boolean)
  const files = useFiles(files => getFilesByIds(files, filesIds))
  const categoryNamesTranslated = Object.keys(categoryNames).reduce(
    (acc, categoryKey) => ({
      ...acc,
      [categoryKey]: t(categoryNames[categoryKey]),
    }),
    {},
  )
  const [, createComponent] = createPage(pageType)

  useEffect(() => {
    if (!entity) {
      window.Rollbar.info(
        `Page id ${pageId} entity not found by id ${entity.id}`,
      )
    }
  }, [entity])

  const create = async () => {
    try {
      setIsLoading(true)
      setIsCommonError(false)
      if (isAdmin && isCommon) {
        const { data } = await blockApi.createCommonBlock(
          locale,
          blockEntities,
          desktopBlobFile,
          mobileBlobFile,
          title,
          category,
        )
        addCommonBlock(data.block)
        addCommonFile(data.desktopPreview)
        addCommonFile(data.mobilePreview)
      } else if (isMaster) {
        const { structure } = cloneStructure(
          entity,
          descendants.filter(Boolean),
        )
        const screenshotRes = await blockApi.screenshotCreate(
          blockEntities,
          entityId,
          files,
          locale,
        )
        const { data } = await blockApi.createMasterBlock(
          Object.values(structure),
          screenshotRes.data,
          title,
        )
        markAsMasterBlock(entity, data.block.id)
        addMasterBlock(data.block)
        addMasterFile(data.desktopPreview)
      } else {
        const screenshotRes = await blockApi.screenshotCreate(
          blockEntities,
          entityId,
          files,
          locale,
        )
        const { data } = await blockApi.createUserBlock(
          blockEntities,
          screenshotRes.data,
          title,
        )
        addUserBlock(data.block)
        addUserFile(data.desktopPreview)
      }
      setSuccess(true)
      setTimeout(() => hide(), 1000)
    } catch (e) {
      if (e instanceof BadRequest) {
        setErrors(e.response.data.errors)
      } else {
        setIsCommonError(true)
        // no need to send error, backend will send it
      }
    }
    setIsLoading(false)
  }

  async function handleDesktopInputFileChange() {
    const file = desktopFileRef.current.files[0]
    if (!file) {
      return
    }
    const base64 = await toBase64(file, true)
    setDesktopBlobFile(base64)
  }

  async function handleMobileInputFileChange() {
    const file = mobileFileRef.current.files[0]
    if (!file) {
      return
    }
    const base64 = await toBase64(file, true)
    setMobileBlobFile(base64)
  }

  return (
    <Dialog show close={hide} width={900}>
      <DialogHeader close={hide}>
        <CreateBlogDialogHeaderUi>
          {t('components.core.dialog.save_block.header')}
        </CreateBlogDialogHeaderUi>
      </DialogHeader>
      <DialogBody>
        <FormGroup>
          <InputLabel>
            {t('components.core.dialog.save_block.title')}
          </InputLabel>
          <Input value={title} onChange={e => setTitle(e.target.value)} />
          {errors.title &&
            errors.title.map(error => (
              <CreateBlogDialogErrorMessageUi key={error}>
                {error}
              </CreateBlogDialogErrorMessageUi>
            ))}
        </FormGroup>
        {!isCommon && (
          <FormGroup styles={{ flexDirection: 'row' }}>
            <InputLabel>
              {t('components.core.dialog.save_block.is_master.label')}{' '}
              <Checkbox
                onChange={e => setIsMaster(e.target.checked)}
                checked={isMaster}
              />
            </InputLabel>
            <Tooltip
              arrowCenter
              message={t(
                'components.core.dialog.save_block.is_master.help_message',
              )}
            />
          </FormGroup>
        )}
        {isAdmin && (
          <React.Fragment>
            {!isMaster && (
              <FormGroup>
                <InputLabel>
                  {t('components.core.dialog.save_block.is_common')}{' '}
                  <Checkbox
                    onChange={e => setIsCommon(e.target.checked)}
                    checked={isCommon}
                  />
                </InputLabel>
              </FormGroup>
            )}
            {isCommon && (
              <React.Fragment>
                <FormGroup>
                  <InputLabel>
                    {t('components.core.dialog.save_block.choose_language')}
                  </InputLabel>
                  <Select
                    value={locale}
                    handleChange={setLocale}
                    choices={localeNames}
                    needTranslate={true}
                  />
                  {errors.locale &&
                    errors.locale.map(error => (
                      <CreateBlogDialogErrorMessageUi key={error}>
                        {error}
                      </CreateBlogDialogErrorMessageUi>
                    ))}
                </FormGroup>
                <FormGroup>
                  <InputLabel>
                    {t('components.core.dialog.save_block.category')}
                  </InputLabel>
                  <Select
                    firstChoice="components.core.dialog.save_block.choose_category"
                    value={category}
                    handleChange={setCategory}
                    choices={categoryNamesTranslated}
                  />
                  {errors.category &&
                    errors.category.map(error => (
                      <CreateBlogDialogErrorMessageUi key={error}>
                        {error}
                      </CreateBlogDialogErrorMessageUi>
                    ))}
                </FormGroup>
                <FormGroup>
                  <InputLabel>
                    {t('components.core.dialog.save_block.desktop_preview')}
                  </InputLabel>
                  <input
                    type="file"
                    ref={desktopFileRef}
                    onChange={handleDesktopInputFileChange}
                  />
                  {errors.desktopPreview &&
                    errors.desktopPreview.map(error => (
                      <CreateBlogDialogErrorMessageUi key={error}>
                        {error}
                      </CreateBlogDialogErrorMessageUi>
                    ))}
                </FormGroup>
                <FormGroup>
                  <InputLabel>
                    {t('components.core.dialog.save_block.mobile_preview')}
                  </InputLabel>
                  <input
                    type="file"
                    ref={mobileFileRef}
                    onChange={handleMobileInputFileChange}
                  />
                  {errors.mobilePreview &&
                    errors.mobilePreview.map(error => (
                      <CreateBlogDialogErrorMessageUi key={error}>
                        {error}
                      </CreateBlogDialogErrorMessageUi>
                    ))}
                </FormGroup>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <FormGroup>
          <InputLabel>
            {t('components.core.dialog.save_block.preview')}
          </InputLabel>
        </FormGroup>
        <CreateBlogDialogPreviewUi id="create-block-preview">
          {createComponent(entity)}
        </CreateBlogDialogPreviewUi>
      </DialogBody>
      <DialogFooter>
        {success && (
          <CreateBlogDialogSuccessMessageUi>
            {t('components.core.dialog.save_block.success_message')}
          </CreateBlogDialogSuccessMessageUi>
        )}
        {isCommonError && (
          <CreateBlogDialogErrorMessageUi>
            {t('components.core.dialog.save_block.error')}
          </CreateBlogDialogErrorMessageUi>
        )}
        <BlueButton onClick={create} disabled={isLoading}>
          {isLoading && <ButtonLoader />}
          {t('components.core.dialog.save_block.submit')}
        </BlueButton>
      </DialogFooter>
    </Dialog>
  )
}

export default CreateBlockDialog
