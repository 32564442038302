import React from 'react'
import PropTypes from 'prop-types'
import { Translation } from 'react-i18next'
import OptionWrapper from '../../styleComponents/OptionWrapper/index'
import SelectUi from './ui/SelectUi'

const Select = ({
  value,
  update,
  labelText,
  labelIcon,
  choiceList,
  firstOption,
  dataTestElement,
  needTranslate,
  helpMessage,
}) => (
  <Translation ns={['client', 'common']}>
    {t => (
      <OptionWrapper
        labelText={labelText}
        labelIcon={labelIcon}
        helpMessage={helpMessage}
      >
        <SelectUi
          dataTestElement={dataTestElement}
          onChange={e => update(e.target.value)}
          value={value}
        >
          {firstOption && <option value="">{t(firstOption)}</option>}
          {Object.keys(choiceList).map(choiceKey => (
            <option key={choiceKey} value={choiceKey}>
              {needTranslate
                ? t([choiceList[choiceKey], `common:${choiceList[choiceKey]}`])
                : choiceList[choiceKey]}
            </option>
          ))}
        </SelectUi>
      </OptionWrapper>
    )}
  </Translation>
)

Select.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.string,
  labelText: PropTypes.string,
  labelIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  choiceList: PropTypes.objectOf(PropTypes.any).isRequired,
  firstOption: PropTypes.string,
  dataTestElement: PropTypes.string.isRequired,
  needTranslate: PropTypes.bool,
  helpMessage: PropTypes.string,
}

Select.defaultProps = {
  value: '',
  firstOption: '',
  labelText: null,
  dataTestElement: '',
  labelIcon: null,
  needTranslate: true,
  helpMessage: '',
}

export { Select as default }
