import styled from 'styled-components'

const ResetIcon = styled.div.attrs(() => ({
  className: 'fa fa-ban',
}))`
  margin-left: 10px;
  cursor: pointer;
`

export default ResetIcon
