import styled from 'styled-components'

interface FullAndSeparateSettingsContainerUiProps {
  column?: boolean
}
const FullAndSeparateSettingsContainerUi = styled.div<FullAndSeparateSettingsContainerUiProps>`
  display: flex;
  flex-direction: column;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`

export default FullAndSeparateSettingsContainerUi
