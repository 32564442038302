import PropTypes from 'prop-types'
import React from 'react'
import { wrapperStyles } from 'common/components/entities/Button'
import CommonTwitterTweetButtonOld from 'common/components/entities/TwitterTweetButtonOld'
import * as types from 'common/types'
import BaseEntity from '../core/BaseEntity'

function TwitterTweetButtonOld({ entity, isMoveDownAllowed, isMoveUpAllowed }) {
  return (
    <BaseEntity
      entity={entity}
      styles={wrapperStyles}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {emulatedEntity => (
        <CommonTwitterTweetButtonOld
          text={emulatedEntity.options.text}
          styles={emulatedEntity.styles}
          mobileStyles={emulatedEntity.mobileStyles}
        />
      )}
    </BaseEntity>
  )
}

TwitterTweetButtonOld.propTypes = {
  entity: types.entity.isRequired,
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
}

export default TwitterTweetButtonOld
