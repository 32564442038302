import styled from 'styled-components'

const ActionModalHeaderUi = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: rgba(20, 45, 99, 1);
  display: flex;
  justify-content: center;
`

export default ActionModalHeaderUi
