import styled from 'styled-components'

const PopupWrapper = styled.div`
  //position: fixed; /* relative to the browser viewport */
  position: absolute;
  //left: calc(50% + 150px); /* because we have fixed position we need to compensate sidebar */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 51; // need to be equal to the z-index of an overlay
  width: ${p => p.width}; // when no element inside popup, it shrinks to 0 width
`

export default PopupWrapper
