import { useDispatch } from 'react-redux'
import React from 'react'
import CommonCheckbox from 'common/components/entities/Checkbox'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import TextEditor from 'client/components/core/TextEditorNew'
import { TextEditorUpdatePayload } from 'client/components/core/TextEditorNew'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { updateEntity } from 'client/store/page/pageActions'
import pageSelectors from 'client/store/page/pageSelectors'
import { EntityProps } from 'client/types'
import { toggleExplicitConsent } from 'publisher/actionsManagement'

const Checkbox = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<CheckboxInterface>) => {
  const dispatch = useDispatch()
  const idEditing = useManagement(m =>
    managementSelectors.isEditing(m, entity.id),
  )
  const { isMobile } = useDeviceModeContext()
  const isConfirmed = useManagement(m => false)
  const globalFontSize = usePage(pageSelectors.getGlobalTextFontSize)
  const globalMobileFontSize = usePage(
    pageSelectors.getGlobalMobileTextFontSize,
  )
  const globalLinkColor = usePage(pageSelectors.getGlobalLinkColor)
  const globalLineHeight = usePage(pageSelectors.getGlobalTextLineHeight)
  const globalMobileLineHeight = usePage(
    pageSelectors.getGlobalMobileTextLineHeight,
  )
  const globalTextColor = usePage(pageSelectors.getGlobalTextColor)

  const toggleConfirmed = () => dispatch(toggleExplicitConsent(entity.id))

  const updateText = ({ rawContentState }: TextEditorUpdatePayload) => {
    dispatch(
      updateEntity({
        ...entity,
        rawContentState,
      }),
    )
  }

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonCheckbox
        entity={entity}
        checked={isConfirmed}
        onChangeHandler={toggleConfirmed}
      >
        <TextEditor
          textAlign={entity.textAlign}
          attrId={entity.htmlAttrId}
          editorTextAlign={
            isMobile
              ? entity.mobileTextAlign || entity.textAlign
              : entity.textAlign
          }
          isEditing={idEditing}
          rawContentState={entity.rawContentState}
          update={updateText}
          padding={entity.padding}
          textColor={entity.color || globalTextColor}
          linkColor={globalLinkColor}
          fontSize={entity.fontSize || globalFontSize}
          fontFamily={entity.fontFamily}
          mobileFontFamily={entity.mobileFontFamily}
          fontWeight={entity.fontWeight}
          lineHeight={entity.lineHeight || globalLineHeight}
          mobilePadding={entity.mobilePadding}
          mobileFontSize={entity.mobileFontSize || globalMobileFontSize}
          mobileLineHeight={entity.mobileLineHeight || globalMobileLineHeight}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontWeight={entity.mobileFontWeight}
        />
      </CommonCheckbox>
    </BaseEntityNew>
  )
}

export default Checkbox
