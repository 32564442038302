import styled from 'styled-components'

const EntityInnerItemsUi = styled.div`
  padding: 15px 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

export default EntityInnerItemsUi
