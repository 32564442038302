import styled from 'styled-components'

const Divider = styled.div`
  height: 1px;
  margin: 20px 0 0 20px;
  width: 260px;
  background: #ebedf0;
`

export default Divider
