import React from 'react'
import { useTranslation } from 'react-i18next'
import CarouselInterface from 'common/types/entities/CarouselInterface'
import { Appearance } from 'common/types/entities/EntityInterface'
import CoreButton from 'client/components/core/BlueButton'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { createCarouselItem } from 'client/components/entities/Carousel'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Checkbox from '../../components/Checkbox'
import ColorPicker from '../../components/ColorPicker'
import EntityInnerItems from '../../components/EntityInnerItems'
import { useUpdateEntity, useUpdateProp } from '../../hooks/useUpdateProps'
import DeviceAppearance from '../../options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../../options/HtmlAttrId'
import MarginStyle from '../../styles/MarginStyle'
import VerticalAlign from '../../styles/VerticalAlign'
import MobileIconUi from '../../styles/ui/MobileIconUi'

type CarouselSettingsProps = {
  entity: CarouselInterface
}

function CarouselSettings({ entity }: CarouselSettingsProps) {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<CarouselInterface>()
  const { isMobile } = useDeviceModeContext()
  const { t } = useTranslation()

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  return (
    <>
      <EntityInnerItems
        entity={entity}
        createEntityInnerItem={createCarouselItem}
        addItemButtonText="entity_settings.carousel.item.add"
        removeWarningText="entity_settings.carousel.remove_item_warning"
        renderInnerItem={(innerItem, switchToItem, isActive) => (
          <CoreButton
            onClick={switchToItem}
            // @ts-ignore
            styles={{
              marginRight: 'auto',
              ...(!isActive ? { background: '#bcbcbc' } : {}),
            }}
          >
            {t('entity_settings.carousel.item_number', {
              itemNumber: innerItem.creationItemNumber,
            })}
          </CoreButton>
        )}
      />
      <VerticalAlign
        label={'entity_settings.carousel.item_vertical_align.title'}
        align={
          isMobile ? entity.mobileVerticalAlignment : entity.verticalAlignment
        }
        update={updateProp(
          isMobile ? 'mobileVerticalAlignment' : 'verticalAlignment',
        )}
      />
      {/*@ts-ignore*/}
      <Checkbox
        labelText="entity_settings.carousel.show_arrows.label"
        labelIcon={isMobile && <MobileIconUi />}
        update={updateProp(isMobile ? 'mobileShowArrows' : 'showArrows')}
        value={
          isMobile
            ? typeof entity.mobileShowArrows !== 'undefined'
              ? entity.mobileShowArrows
              : entity.showArrows
            : entity.showArrows
        }
      />
      <ColorPicker
        update={updateProp('controlsColor')}
        color={entity.controlsColor}
        label="entity_settings.carousel.controls_color.label"
        singleColorMode
      />
      <ColorPicker
        update={updateProp('arrowsColor')}
        color={entity.arrowsColor}
        label="entity_settings.carousel.arrows_color.label"
        singleColorMode
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default CarouselSettings
