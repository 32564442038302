import styled from 'styled-components'

const AutomationViewActionContainerUi = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
`

export default AutomationViewActionContainerUi
