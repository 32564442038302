import styled from 'styled-components'

const ManagementItemIconUi = styled.span`
  font-size: 14px;
  color: #9ba9b3;
  cursor: pointer;
  margin-left: 15px;
  transition: all .1s linear;
  &:hover {
    transform: scale(1.2);
  }
`

export default ManagementItemIconUi
