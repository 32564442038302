import styled, { css } from 'styled-components'

interface AutomationActionContainerUiProps {
  withHover?: boolean
}
const AutomationActionContainerUi = styled.div<AutomationActionContainerUiProps>`
  display: flex;
  padding: 0px;
  gap: 0.75rem;
  outline-style: none;
  width: 100%;
  text-align: left;
  border: none;
  background-color: white;

  ${p =>
    p.withHover &&
    css`
      &:hover {
        cursor: pointer;
        svg {
          fill: #00a0ff;
        }
        span {
          color: #00a0ff;
        }
      }
      &:focus {
        svg {
          fill: #00a0ff;
        }
        span {
          color: #00a0ff;
        }
      }
    `};
`

export default AutomationActionContainerUi
