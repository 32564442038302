import { CustomLoginModeEnum } from '../../pages/custom-login/entities/CustomLogin'
import {
  CHANGE_CUSTOM_LOGIN_MODE,
  ChangeCustomLoginMode,
} from './customLogicActionTypes'

export function changeCustomLoginMode(
  mode: CustomLoginModeEnum,
): ChangeCustomLoginMode {
  return {
    type: CHANGE_CUSTOM_LOGIN_MODE,
    payload: mode,
  }
}

export type CustomLoginActions = ChangeCustomLoginMode
