import styled from 'styled-components'
import { editorZindex } from 'client/constants/editorSettings'

const IconPopoverUi = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  padding-bottom: 2px;
  text-align: left;
  width: 315px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 340px;
  z-index: ${editorZindex.colorPicker};
`
export default IconPopoverUi
