import React from 'react'

function SendEmailIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3629 16.1051C26.5967 15.8035 26.5673 15.3715 26.294 15.1058C26.0208 14.8401 25.5931 14.8272 25.3046 15.0756L12.077 26.4619L5.09197 23.7287C4.68457 23.5707 4.35275 23.2608 4.1654 22.8623C3.97766 22.4641 3.94857 22.0077 4.08413 21.5883C4.21968 21.1689 4.50965 20.8185 4.89361 20.6092L33.8925 4.17285C34.1976 4.0066 34.5525 3.95814 34.8903 4.03676C35.2285 4.11503 35.5266 4.31539 35.7296 4.59977C35.9326 4.88451 36.0263 5.23388 35.9936 5.58323L33.4499 32.4121C33.3981 32.9701 33.0893 33.4707 32.6166 33.7625C32.1436 34.0545 31.5626 34.1036 31.0484 33.8947L22.5474 30.5644L17.2104 35.6243C16.9463 35.8739 16.5961 36.0089 16.2348 35.9995C15.8732 35.9906 15.53 35.838 15.2788 35.5755C15.0272 35.3127 14.8881 34.9604 14.8906 34.5949V30.6212L26.3629 16.1051Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default SendEmailIcon
