import React from 'react'
import CommonPaymentMethod from 'common/components/entities/PaymentMethodNew'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { PaymentMethodInterface } from 'common/types/entities/PaymentMethodInterface'
import BaseEntity from 'client/components/core/BaseEntity/BaseEntityNew'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import { usePage, usePayment } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import paymentSelectors from 'client/store/payment/paymentSelectors'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

interface PaymentMethodProps {
  entity: PaymentMethodInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}
function PaymentMethod({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: PaymentMethodProps) {
  const paymentMethods = usePayment(paymentSelectors.getPaymentMethods)
  const textColor = usePage(pageSelectors.getGlobalTextColor)
  const isExample = paymentMethods.length === 0

  const calculatedMethods = isExample
    ? [PaymentMethodEnum.StripeCard, PaymentMethodEnum.PaypalSoap]
    : paymentMethods.filter(pm => Object.values(PaymentMethodEnum).includes(pm))

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <EntityExampleWrapper
        label="entities.payment_methods.example_warning"
        visible={isExample}
      >
        <CommonPaymentMethod
          fontSize={entity.fontSize}
          mobileFontSize={entity.mobileFontSize}
          lineHeight={entity.lineHeight}
          mobileLineHeight={entity.mobileLineHeight}
          fontFamily={entity.fontFamily}
          fontWeight={entity.fontWeight}
          fontStyle={entity.fontStyle}
          mobileFontStyle={entity.mobileFontStyle}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontWeight={entity.mobileFontWeight}
          color={entity.color || textColor}
          mobileColor={entity.mobileColor}
          activeMethod={calculatedMethods[0] as PaymentMethodEnum} // stripe first
          paymentMethods={calculatedMethods as PaymentMethodEnum[]}
          attrId={entity.htmlAttrId}
        />
      </EntityExampleWrapper>
    </BaseEntity>
  )
}

export function createPaymentMethod(
  parentId: string,
  masterBlockId?: string,
): PaymentMethodInterface {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.PaymentMethod,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.PaymentMethod,
    fontSize: 16,
  }
}

export default PaymentMethod
