import React from 'react'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { useDateWithTimezone } from 'client/components/core/Sidebar/components/Settings/entities/Countdown/hooks/use-date-with-timezone'
import { getDateFromTime } from 'client/components/core/Sidebar/components/Settings/entities/Countdown/utils/date-time-utils'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import { DateTimePicker } from 'client/components/core/Sidebar/components/Settings/options'

interface CountdownDailyProps {
  entity: CountdownInterface
}

const CountdownDaily = ({ entity }: CountdownDailyProps) => {
  const updateProp = useUpdateProp(entity)

  const { dateWithTimezone, onChangeWithTimezone } = useDateWithTimezone({
    date: getDateFromTime(entity[CountdownTypesEnum.daily]),
    onChange: date => {
      const newDate = new Date(date.getTime() - 1000 * 60)
      const hours = newDate.getHours()
      const minutes = newDate.getMinutes()
      const seconds = newDate.getSeconds()
      const createdAt = new Date().getTime()
      updateProp(CountdownTypesEnum.daily)({
        hours,
        minutes,
        seconds,
        createdAt,
      })
    },
  })

  return (
    <DateTimePicker
      updateDate={onChangeWithTimezone}
      dateTime={dateWithTimezone}
      showDate={false}
    />
  )
}

export default CountdownDaily
