import styled from 'styled-components'

const BreadcrumbsItemUi = styled.span`
  position: relative;
  padding: 15px 12px 15px 25px;
  line-height: 44px;
  cursor: pointer;
  background-color: ${p => (p.last ? '#149efc' : 'transparent')};
  &:hover {
    background-color: ${p => (p.last ? '#149efc' : '#acc9de')};
    color: hsla(206, 43%, 24%, 1);
  }
  &:last-child {
    padding-left: 35px;
    cursor: default;
  }
  &:first-child {
    padding-left: 20px;
  }
`

export default BreadcrumbsItemUi
