import * as entityUtils from 'tools/utils/entityUtils'
import structureTypes from 'common/constants/structureTypes'
import toolBoxTypes from 'common/constants/toolBoxTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import {
  EntityTypeEnum,
  getReadableRootEntityTypeByPageType,
} from 'common/enums/entityTypeEnum'
import {
  ADD_CHILD,
  CREATE_ENTITY,
  CREATE_STRUCTURE,
  MOVE_NODE_NEW,
  REMOVE_NODE,
  TOGGLE_NEED_SCROLL,
  UPDATE_ENTITY,
  CREATE_DESTINATION_STRUCTURE,
  ADD_STRUCTURE_ASCENDANT_AS_CHILD,
  REMOVE_POPUP,
  CREATE_POPUP_STRUCTURE,
  MARK_AS_MASTER_BLOCK,
  TOGGLE_SETTINGS,
  TOGGLE_INSTANT_UPLOAD,
  MOVE_DOWN,
  MOVE_UP,
} from 'client/actionTypes'
import { getBlockFilesByIds } from 'client/reducers/blocksReducer'
import { getActivePopupId } from 'client/reducers/managementReducer'
import {
  getAllDescendants,
  getEntityById,
  getPageLocale,
  getEntityPosition,
  findFileId,
  addMasterBlockId,
  removeMasterBlockId,
  removeIsMasterBlockRoot,
} from 'client/reducers/pageReducer'
import pageSelectors from 'client/store/page/pageSelectors'
import { cloneStructure } from 'client/utils/cloneStructure'
import createStructureNew, {
  addBlockEntitiesTo,
  createDestinationStructure,
} from 'client/utils/createStructureNew'
import { renewEntityHtmlAttrId } from 'client/utils/createStructureUtils'
import LibraryElementEnum from '../enums/LibraryElementEnum'
import { addFiles } from '../store/files/filesActions'
import createLibraryElementStructure from '../utils/createLibraryElements'
import { getEntitiesWithResolvedIdConflicts } from '../utils/get-entities-with-resolved-id-conflicts'

export const createAsLast = type => (dispatch, getState) => {
  const {
    page: { present: presentPage },
    management,
  } = getState()
  const activePopupId = getActivePopupId(management)
  const locale = getPageLocale(presentPage)
  let structure = null
  let rootEntity = null

  if (activePopupId) {
    rootEntity = getEntityById(presentPage, activePopupId)
  } else {
    const rootEntityType = getReadableRootEntityTypeByPageType(presentPage.type)
    rootEntity = pageSelectors.getFirstEntityByType(presentPage, rootEntityType)
  }

  const REMOTE_PAGE_TYPES = [structureTypes.POPUP, structureTypes.INLINE]

  if (
    type === toolBoxTypes.TOOLBOX_SECTION ||
    type === LibraryElementEnum.LibrarySection ||
    activePopupId ||
    REMOTE_PAGE_TYPES.includes(rootEntity.type) ||
    rootEntity.childIds.length === 0
  ) {
    structure = createStructureNew(
      locale,
      type,
      rootEntity,
      rootEntity.childIds.length,
    ).structure
  } else if (type in LibraryElementEnum) {
    const structure = createLibraryElementStructure(
      type,
      rootEntity,
      locale,
      rootEntity.childIds.length,
    )
    dispatch({
      type: CREATE_STRUCTURE,
      payload: structure,
    })
  } else {
    const lastRootChild = getEntityById(
      presentPage,
      rootEntity.childIds.slice(-1)[0],
    )
    structure = createStructureNew(
      locale,
      type,
      lastRootChild,
      lastRootChild.childIds.length,
      lastRootChild.masterBlockId,
    ).structure
  }

  dispatch({
    type: CREATE_STRUCTURE,
    payload: structure,
  })

  dispatch({
    type: TOGGLE_NEED_SCROLL,
    payload: true,
  })
  if (type === EntityTypeEnum.Image) {
    enableInstantUpload(structure, dispatch)
  }
}

export const createIn =
  (createType, containerEntityId, position) => (dispatch, getState) => {
    const {
      page: { present: presentPage },
    } = getState()
    const locale = getPageLocale(presentPage)
    const containerEntity = getEntityById(presentPage, containerEntityId)
    const masterBlockId = containerEntity && containerEntity.masterBlockId
    const structure = createStructureNew(
      locale,
      createType,
      containerEntity,
      position,
      masterBlockId,
    ).structure

    dispatch({
      type: CREATE_STRUCTURE,
      payload: structure,
    })

    if (createType === EntityTypeEnum.Image) {
      enableInstantUpload(structure, dispatch)
    }
  }

const enableInstantUpload = (structure, dispatch) => {
  const images = entityUtils.getEntitiesByType(structure, EntityTypeEnum.Image)
  if (Object.keys(images).length > 0) {
    dispatch({
      type: TOGGLE_INSTANT_UPLOAD,
      payload: true,
    })
    dispatch({
      type: TOGGLE_SETTINGS,
      payload: { id: Object.values(images)[0].id },
    })
  }
}

export const moveNew = (entity, toId, position) => (dispatch, getState) => {
  const {
    page: { present: presentPage },
  } = getState()
  const newParentEntity = getEntityById(presentPage, toId)
  const parentMasterBlockId = newParentEntity && newParentEntity.masterBlockId
  const locale = getPageLocale(presentPage)
  let movableEntity = entity
  let movableEntityDescendantsStructure = {}

  // we move into a master block entity
  if (parentMasterBlockId) {
    // if it is a master block, we should replace masterBlockId with parents masterBlockId
    if (entity.masterBlockId) {
      movableEntity = addMasterBlockId(parentMasterBlockId)(entity)
      const descendants = getAllDescendants(presentPage, entity.id)
      descendants.forEach(descendantEntity => {
        movableEntityDescendantsStructure[descendantEntity.id] =
          addMasterBlockId(parentMasterBlockId)(descendantEntity)
      })
      // if it is master block root we should remove this flag
      if (entity.isMasterBlockRoot) {
        movableEntity = removeIsMasterBlockRoot(movableEntity)
      }
      // we move non-master block entity, we should add masterBlockId
    } else {
      movableEntity = addMasterBlockId(parentMasterBlockId)(movableEntity)
    }
  } else {
    // we move into a non-master block entity
    // if we move a PART of a master block we should remove master block id
    if (entity.masterBlockId && !entity.isMasterBlockRoot) {
      const descendants = getAllDescendants(presentPage, entity.id)
      descendants.forEach(entity => {
        movableEntityDescendantsStructure[entity.id] =
          removeMasterBlockId(entity)
      })
      movableEntity = removeMasterBlockId(entity)
    }
    // we move WHOLE master block we should keep it as is
    if (entity.isMasterBlockRoot) {
      /* do nothing */
    }
  }

  // for example we drop TEXT into the BODY drop zone
  // we need to wrap it with SECTION -> ROW -> COLUMN_12
  const { structure, parentId, ascendantId, calculatedPosition } =
    createDestinationStructure(
      locale,
      movableEntity,
      newParentEntity,
      position,
      parentMasterBlockId,
    )

  if (Object.keys(structure).length > 0) {
    dispatch({
      type: CREATE_DESTINATION_STRUCTURE,
      payload: { ...structure, ...movableEntityDescendantsStructure },
    })

    // add SECTION as BODY child with position
    dispatch({
      type: ADD_STRUCTURE_ASCENDANT_AS_CHILD,
      payload: {
        id: ascendantId,
        toId,
        position,
      },
    })
  }

  // when structure SECTION -> ROW -> COLUMN_12 is created
  // we need to move TEXT id to the COLUMN_12 (new parent of text) with new calculated position
  dispatch({
    type: MOVE_NODE_NEW,
    payload: {
      entity: movableEntity,
      toId: parentId,
      position: calculatedPosition,
      movableEntityDescendantsStructure,
    },
  })
}

export const update = entity => dispatch => {
  dispatch({
    type: UPDATE_ENTITY,
    payload: entity,
  })
}

export const createBlockIn =
  (entities, containerEntityId, position, isMaster) => (dispatch, getState) => {
    const {
      page: { present: presentPage },
      blocks,
    } = getState()
    const blockEntitiesWithoutConflicts = getEntitiesWithResolvedIdConflicts(
      entities,
      presentPage.entities,
    )

    const filteredEntities = blockEntitiesWithoutConflicts.map(entity =>
      entity.childIds
        ? {
            ...entity,
            childIds: entity.childIds.filter(Boolean),
          }
        : entity,
    ) // filter nullable childIds
    const blockFileIds = filteredEntities.map(findFileId).filter(Boolean)
    const blockFiles = getBlockFilesByIds(blocks, blockFileIds)
    const containerEntity = getEntityById(presentPage, containerEntityId)
    const locale = getPageLocale(presentPage)
    const entitiesWithNewHtmlAttrIds = filteredEntities.map(
      renewEntityHtmlAttrId,
    )
    const structure = addBlockEntitiesTo(
      containerEntity.masterBlockId
        ? entitiesWithNewHtmlAttrIds.map(
            addMasterBlockId(containerEntity.masterBlockId),
          )
        : entitiesWithNewHtmlAttrIds,
      containerEntity,
      locale,
      position,
      isMaster,
    )

    dispatch({
      type: CREATE_STRUCTURE,
      payload: structure,
    })
    dispatch(addFiles(blockFiles))
  }

export const createBlockAtTheBottom = blockEntities => (dispatch, getState) => {
  const presentPage = getState().page.present
  const rootEntity = pageSelectors.getReadableRootEntity(presentPage)
  const isMaster = blockEntities[0]
    ? Boolean(blockEntities[0].masterBlockId)
    : false

  if (rootEntity.childIds.length === 0) {
    createBlockIn(blockEntities, rootEntity.id, 0, isMaster)(dispatch, getState)
  } else {
    const lastChildEntity = getEntityById(
      presentPage,
      rootEntity.childIds.slice(-1)[0],
    )
    const blockHasSections =
      blockEntities.filter(
        entity =>
          entity.type === structureTypes.SECTION ||
          entity.type === EntityTypeEnum.Section,
      ).length > 0
    if (blockHasSections || presentPage.type === PageTypeEnum.Inline) {
      createBlockIn(
        blockEntities,
        rootEntity.id,
        rootEntity.childIds.length,
        isMaster,
      )(dispatch, getState)
    } else {
      createBlockIn(
        blockEntities,
        lastChildEntity.id,
        lastChildEntity.childIds.length,
        isMaster,
      )(dispatch, getState)
    }
  }
  dispatch({
    type: TOGGLE_NEED_SCROLL,
    payload: true,
  })
}

export const copy = entity => (dispatch, getState) => {
  const presentPage = getState().page.present

  if (entity) {
    const descendants = getAllDescendants(presentPage, entity.id)
    const position = getEntityPosition(presentPage.entities, entity)
    const { parentId: id, structure } = cloneStructure(entity, descendants)

    dispatch({
      type: CREATE_ENTITY,
      payload: structure,
    })
    dispatch({
      type: ADD_CHILD,
      payload: { id, toId: entity.parentId, position: position + 1 },
    })
  }
}

export const moveDown = entity => dispatch => {
  dispatch({
    type: MOVE_DOWN,
    payload: entity,
  })
}

export const moveUp = entity => dispatch => {
  dispatch({
    type: MOVE_UP,
    payload: entity,
  })
}

export const remove = entity => dispatch => {
  dispatch({
    type: REMOVE_NODE,
    payload: { id: entity.id, parentId: entity.parentId },
  })
}

export const removePopup = id => dispatch => {
  dispatch({
    type: REMOVE_POPUP,
    payload: { id },
  })
}

export const createPopupStructure = structure => dispatch => {
  dispatch({
    type: CREATE_POPUP_STRUCTURE,
    payload: structure,
  })
}

export const markAsMasterBlock =
  (masterBlockRootEntity, masterBlockId) => dispatch => {
    dispatch({
      type: MARK_AS_MASTER_BLOCK,
      payload: { masterBlockRootEntity, masterBlockId },
    })
  }
