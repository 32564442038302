export const categories = {
  // title: 'title',
  // text: 'text',
  // about: 'about',
  // image: 'image',
  // directSpeech: 'directSpeech',
  // features: 'features',
  // columns: 'columns',
  // divider: 'divider',
  // menu: 'menu',
  // footer: 'footer',
  // video: 'video',
  // formAndButton: 'formAndButton',
  // store: 'store',
  // team: 'team',
  // reviews: 'reviews',
  // howItWorks: 'howItWorks',
  // contacts: 'contacts',
  // socialNetworks: 'socialNetworks',
  pageFooters: 'pageFooters',
  pageHeaders: 'pageHeaders',
  teamMembers: 'teamMembers',
  orderForms: 'orderForms',
  testimonials: 'testimonials',
  pricePlans: 'pricePlans',
  features: 'features',
  optInForms: 'optInForms',
  hero: 'hero',
  surveys: 'surveys',
}

export const categoryNames = {
  [categories.testimonials]: 'components.core.blocks.common.testimonials',
  [categories.pricePlans]: 'components.core.blocks.common.price_plans',
  [categories.features]: 'components.core.blocks.common.features',
  [categories.optInForms]: 'components.core.blocks.common.opt_in_forms',
  [categories.pageFooters]: 'components.core.blocks.common.page_footers',
  [categories.pageHeaders]: 'components.core.blocks.common.page_headers',
  [categories.teamMembers]: 'components.core.blocks.common.team_members',
  [categories.orderForms]: 'components.core.blocks.common.order_forms',
  [categories.hero]: 'components.core.blocks.common.hero',
  [categories.surveys]: 'components.core.blocks.common.surveys',
  // [categories.title]: 'components.core.blocks.common.title',
  // [categories.text]: 'components.core.blocks.common.text',
  // [categories.about]: 'components.core.blocks.common.about',
  // [categories.image]: 'components.core.blocks.common.image',
  // [categories.directSpeech]: 'components.core.blocks.common.image',
  // [categories.features]: 'components.core.blocks.common.features',
  // [categories.columns]: 'components.core.blocks.common.columns',
  // [categories.divider]: 'components.core.blocks.common.divider',
  // [categories.menu]: 'components.core.blocks.common.menu',
  // [categories.footer]: 'components.core.blocks.common.footer',
  // [categories.video]: 'components.core.blocks.common.video',
  // [categories.formAndButton]: 'components.core.blocks.common.formAndButton',
  // [categories.store]: 'components.core.blocks.common.store',
  // [categories.team]: 'components.core.blocks.common.team',
  // [categories.reviews]: 'components.core.blocks.common.reviews',
  // [categories.howItWorks]: 'components.core.blocks.common.howItWorks',
  // [categories.contacts]: 'components.core.blocks.common.contacts',
  // [categories.socialNetworks]: 'components.core.blocks.common.socialNetworks',
}
