import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BorderRadius from 'client/components/core/Sidebar/components/Settings/styles/BorderRadius'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { VideoInterface } from '../../../../common/types/entities/VideoInterface'
import { FileUpload } from '../../../components/core/Sidebar/components/Settings/components'
import Input from '../../../components/core/Sidebar/components/Settings/components/Input/Input'
import ReactSelect, {
  ChangeOptionWithStringValue,
} from '../../../components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearance from '../../../components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../../../components/core/Sidebar/components/Settings/options/HtmlAttrId'
import { ShadowStyle } from '../../../components/core/Sidebar/components/Settings/styles'
import MarginStyleNew from '../../../components/core/Sidebar/components/Settings/styles/MarginStyleNew'
import { mimeTypes } from '../../../constants/editorSettings'
import { useDeviceModeContext } from '../../../context/DeviceModeContext'

enum VideoType {
  File = 'file',
  Url = 'url',
}

const videoTypesNames = {
  [VideoType.File]: 'entity_settings.video.source_type.upload_file',
  [VideoType.Url]: 'entity_settings.video.source_type.direct_link',
}

function detectVideoType(entity: VideoInterface) {
  if (entity.fileId) {
    return VideoType.File
  }

  return VideoType.Url
}

function WebinarSessionVideoSettingsNew({
  entity,
}: {
  entity: VideoInterface
}) {
  const [videoType, setVideoType] = useState(detectVideoType(entity))
  const { t } = useTranslation()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<VideoInterface>()
  const updateNestedProp = useUpdateNestedProp(entity)
  const { isMobile } = useDeviceModeContext()

  function getOption(videoType: VideoType) {
    return {
      value: videoType,
      label: t(videoTypesNames[videoType]),
    }
  }

  function updateVideoType(option: ChangeOptionWithStringValue) {
    if (!option) {
      return
    }

    const updatedEntity = { ...entity }
    switch (option.value) {
      case VideoType.Url:
        delete updatedEntity.fileId
        delete updatedEntity.embedCode
        break
      case VideoType.File:
        delete updatedEntity.url
        delete updatedEntity.embedCode
        break
      default:
    }
    updateEntity(updatedEntity)
    setVideoType(option.value as VideoType)
  }

  return (
    <>
      <ReactSelect
        selectedOption={getOption(videoType)}
        update={updateVideoType}
        labelText="entity_settings.video.type.label"
        options={[getOption(VideoType.Url), getOption(VideoType.File)]}
        isMulti={false}
      />
      {videoType === VideoType.Url && (
        <Input
          label="entity_settings.video.type.url.label"
          value={entity.url}
          update={updateProp('url')}
        />
      )}
      {videoType === VideoType.File && (
        <FileUpload
          fileId={entity.fileId}
          mimeTypes={mimeTypes.video}
          afterUpload={(file: any) => updateProp('fileId')(file.id)}
          fileType="video"
          helpMessage="entity_settings.video.upload.help_message"
          label="video"
        />
      )}
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <BorderRadius
        label="settings_styles.border_radius.label"
        labelIcon={isMobile ? <MobileIconUi /> : null}
        border={isMobile ? entity.mobileBorder : entity.border}
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
      />
      <MarginStyleNew
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateNestedProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={appearance =>
          updateEntity({
            ...entity,
            appearance,
          })
        }
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default WebinarSessionVideoSettingsNew
