import React from 'react'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import { ShadowStyle } from 'client/components/core/Sidebar/components/Settings/styles'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { SettingsProps } from '../../../components/core/Sidebar/components/Settings/entities'
import {
  useUpdateEntity,
  useUpdateProp,
} from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import FlexAlignStyle from '../../../components/core/Sidebar/components/Settings/styles/FlexAlignStyle'
import FontSize from '../../../components/core/Sidebar/components/Settings/styles/FontSize'
import { useDeviceModeContext } from '../../../context/DeviceModeContext'
import { AttachmentsInterface } from '../entities/Attachments'

function AttachmentsSettings({ entity }: SettingsProps<AttachmentsInterface>) {
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<AttachmentsInterface>()

  return (
    <>
      <FlexAlignStyle
        align={entity.alignSelf}
        mobileAlign={entity.mobileAlignSelf}
        update={updateProp('alignSelf')}
        mobileUpdate={updateProp('mobileAlignSelf')}
      />
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <TextFontUpdater<AttachmentsInterface>
        entity={entity}
        update={updateEntity}
      />
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
      />
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="settings_styles.background_color.label"
      />
      <Border
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
        border={isMobile ? entity.mobileBorder : entity.border}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
    </>
  )
}

export default AttachmentsSettings
