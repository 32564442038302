import React from 'react'
import PropTypes from 'prop-types'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input'

const UrlRedirect = ({ update, urlRedirect, ...rest }) => (
  <Input
    labelText="Redirection url"
    update={update}
    value={urlRedirect}
    placeholder=""
    {...rest}
  />
)

UrlRedirect.propTypes = {
  update: PropTypes.func.isRequired,
  urlRedirect: PropTypes.string,
}

UrlRedirect.defaultProps = {
  urlRedirect: '',
}

export default UrlRedirect
