import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'

export enum AccessTypeEnum {
  fullAccess = 'full_access',
  partialAccess = 'partial_access',
  drippingContent = 'dripping_content',
}

export interface DefinedActionEmailEditInterface {
  type: AutomationRuleActionsEnum.send_email
  systemeEmail: { id: number; subject: string }
}

export interface ActionEmailEditInterface {
  type: AutomationRuleActionsEnum.send_email
  systemeEmail: { id?: number; subject?: string }
}

export interface DefinedActionSpecEmailEditInterface {
  type: AutomationRuleActionsEnum.send_email_to_specific_address
  emailMessageWithRecipient: {
    id: number
    mailing: { currentEmail: { subject: string; id?: number } }
  }
}

export interface ActionSpecEmailEditInterface {
  type: AutomationRuleActionsEnum.send_email_to_specific_address
  emailMessageWithRecipient: {
    id?: number
    mailing?: { currentEmail: { subject?: string; id?: number } }
  }
}

export interface ActionCreateUserEditInterface {
  type: AutomationRuleActionsEnum.create_user
  locale?: PageLocaleEnum
}

export interface DefinedActionCampaignEditInterface {
  type:
    | AutomationRuleActionsEnum.subscribe_to_campaign
    | AutomationRuleActionsEnum.unsubscribe_from_campaign
  campaign: { id: number; name: string }
}

export interface ActionCampaignEditInterface {
  type:
    | AutomationRuleActionsEnum.subscribe_to_campaign
    | AutomationRuleActionsEnum.unsubscribe_from_campaign
  campaign: { id?: number; name?: string }
}

export interface DefinedActionTagEditInterface {
  type: AutomationRuleActionsEnum.add_tag | AutomationRuleActionsEnum.remove_tag
  tag: { id: number; name: string }
}

export interface ActionTagEditInterface {
  type: AutomationRuleActionsEnum.add_tag | AutomationRuleActionsEnum.remove_tag
  tag: { id?: number; name?: string }
}

export interface ActionWebhookEditInterface {
  type: AutomationRuleActionsEnum.send_webhook
  webhookUrl?: string
}

export interface DefinedActionCourseEditInterface {
  type:
    | AutomationRuleActionsEnum.enroll_in_course
    | AutomationRuleActionsEnum.revoke_access_to_course
  courseAccessType: AccessTypeEnum
  course: { id: number; name: string }
}

export interface DefinedActionCourseBundleEditInterface {
  type:
    | AutomationRuleActionsEnum.enroll_in_course_bundle
    | AutomationRuleActionsEnum.revoke_access_to_course_bundle
  enrollmentAccessType: AccessTypeEnum
  courseBundle: { id: number; name: string }
}

export interface ActionCourseEditInterface {
  type:
    | AutomationRuleActionsEnum.enroll_in_course
    | AutomationRuleActionsEnum.revoke_access_to_course
  courseAccessType?: AccessTypeEnum
  course: { id?: number; name?: string }
}

export interface ActionCourseBundleEditInterface {
  type:
    | AutomationRuleActionsEnum.enroll_in_course_bundle
    | AutomationRuleActionsEnum.revoke_access_to_course_bundle
  enrollmentAccessType?: AccessTypeEnum
  courseBundle: { id?: number; name?: string }
}

export interface DefinedActionCommunityEditInterface {
  type:
    | AutomationRuleActionsEnum.grant_access_to_community
    | AutomationRuleActionsEnum.revoke_access_to_community
  community: { id: number; name: string }
}

export interface ActionCommunityEditInterface {
  type:
    | AutomationRuleActionsEnum.grant_access_to_community
    | AutomationRuleActionsEnum.revoke_access_to_community
  community: { id?: number; name?: string }
}

export type ActionEditType = { id: number } & (
  | ActionEmailEditInterface
  | ActionTagEditInterface
  | ActionWebhookEditInterface
  | ActionCourseEditInterface
  | ActionCourseBundleEditInterface
  | ActionCampaignEditInterface
  | ActionCreateUserEditInterface
  | ActionSpecEmailEditInterface
  | ActionCommunityEditInterface
)

export type DefinedActionEditType = { id: number } & (
  | DefinedActionEmailEditInterface
  | DefinedActionTagEditInterface
  | ActionWebhookEditInterface
  | DefinedActionCourseEditInterface
  | DefinedActionCourseBundleEditInterface
  | DefinedActionCampaignEditInterface
  | ActionCreateUserEditInterface
  | DefinedActionSpecEmailEditInterface
  | DefinedActionCommunityEditInterface
)

export type ActionSaveType = { id?: number; created?: boolean } & (
  | ActionEmailEditInterface
  | ActionTagEditInterface
  | ActionWebhookEditInterface
  | ActionCourseEditInterface
  | ActionCourseBundleEditInterface
  | ActionCampaignEditInterface
  | ActionCreateUserEditInterface
  | ActionSpecEmailEditInterface
  | ActionCommunityEditInterface
)
