import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { DeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import TextFont from '../components/Font/TextFont'
import { FontSize, DeviceAppearance, Attributes } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import {
  MarginsStyle,
  CornerStyle,
  BorderStyle,
  ShadowStyle,
  PaddingStyle,
} from '../styles'

class WebinarSessionDateTimeSettings extends PureComponent {
  static contextType = DeviceModeContext

  updateIconStyles = style => value => {
    const { updateOptions, entity } = this.props
    updateOptions({
      ...entity.options,
      iconStyles: {
        ...entity.options.iconStyles,
        [style]: `${value}px`,
      },
    })
  }

  render() {
    const {
      entity,
      toggleOverlay,
      overlayVisible,
      updateStyle,
      updateMobileStyle,
      updateOption,
      updateMobileOption,
      update,
    } = this.props

    const handleFontChange = styles => {
      this.context.isMobile
        ? update({
            ...entity,
            mobileStyles: {
              ...entity.mobileStyles,
              ...styles,
            },
          })
        : update({
            ...entity,
            styles: {
              ...entity.styles,
              ...styles,
            },
          })
    }

    const fontFamily = this.context.isMobile
      ? entity.mobileStyles.fontFamily
      : entity.styles.fontFamily
    const fontWeight = this.context.isMobile
      ? entity.mobileStyles.fontWeight
      : entity.styles.fontWeight
    const fontStyle = this.context.isMobile
      ? entity.mobileStyles.fontStyle
      : entity.styles.fontStyle

    return (
      <React.Fragment>
        <GroupTitle>typography</GroupTitle>
        <FontSize
          update={updateStyle('fontSize', 'px')}
          mobileUpdate={updateMobileStyle('fontSize', 'px')}
          fontSize={entity.styles.fontSize}
          mobileFontSize={entity.mobileStyles.fontSize}
        />
        <Range
          labelText="icon size"
          value={
            entity.options.iconStyles
              ? parseInt(entity.options.iconStyles.fontSize, 0)
              : 0
          }
          update={this.updateIconStyles('fontSize')}
        />
        <TextFont
          fontFamily={fontFamily}
          fontStyle={fontStyle}
          fontWeight={fontWeight}
          update={handleFontChange}
        />
        <GroupTitle>color</GroupTitle>
        <ColorPicker
          label="background color"
          update={updateStyle('backgroundColor')}
          mobileUpdate={updateMobileStyle('backgroundColor')}
          mobileColor={entity.mobileStyles.backgroundColor}
          color={entity.styles.backgroundColor}
        />
        <ColorPicker
          label="content color"
          update={updateStyle('color')}
          mobileUpdate={updateMobileStyle('color')}
          color={entity.styles.color}
          mobileColor={entity.mobileStyles.color}
        />
        <MarginsStyle
          marginTop={entity.styles.marginTop}
          marginRight={entity.styles.marginRight}
          marginBottom={entity.styles.marginBottom}
          marginLeft={entity.styles.marginLeft}
          mobileMarginTop={entity.mobileStyles.marginTop}
          mobileMarginRight={entity.mobileStyles.marginRight}
          mobileMarginBottom={entity.mobileStyles.marginBottom}
          mobileMarginLeft={entity.mobileStyles.marginLeft}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <PaddingStyle
          mobilePadding={entity.mobileStyles.padding}
          padding={entity.styles.padding}
          update={updateStyle('padding')}
          mobileUpdate={updateMobileStyle('padding')}
        />
        <BorderStyle
          updateOption={updateOption}
          updateMobileOption={updateMobileOption}
          updateStyle={updateStyle}
          updateMobileStyle={updateMobileStyle}
          type={entity.options.borderType}
          style={entity.styles.borderStyle}
          width={entity.styles.borderWidth}
          color={entity.styles.borderColor}
          mobileType={entity.mobileOptions.borderType}
          mobileStyle={entity.mobileStyles.borderStyle}
          mobileWidth={entity.mobileStyles.borderWidth}
          mobileColor={entity.mobileStyles.borderColor}
          toggleOverlay={toggleOverlay}
          overlayVisible={overlayVisible}
        />
        <ShadowStyle
          shadow={entity.styles.boxShadow}
          mobileShadow={entity.mobileStyles.boxShadow}
          update={updateStyle('boxShadow')}
          mobileUpdate={updateMobileStyle('boxShadow')}
        />
        <CornerStyle
          topLeft={entity.styles.borderTopLeftRadius}
          topRight={entity.styles.borderTopRightRadius}
          bottomRight={entity.styles.borderBottomRightRadius}
          bottomLeft={entity.styles.borderBottomLeftRadius}
          mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
          mobileTopRight={entity.mobileStyles.borderTopRightRadius}
          mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
          mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <DeviceAppearance update={update} entity={entity} />
        <Attributes update={updateOption} entity={entity} />
      </React.Fragment>
    )
  }
}

WebinarSessionDateTimeSettings.propTypes = {
  overlayVisible: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
}

export default WebinarSessionDateTimeSettings
