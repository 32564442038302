import PropTypes from 'prop-types'
import React from 'react'
import * as types from 'common/types'
import { useDeviceModeContext } from '../../../../../../context/DeviceModeContext'
import ColorPicker from '../components/ColorPicker'
import TextFont from '../components/Font/TextFont'
import { Attributes } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { MarginsStyle } from '../styles'
import TextFontSize from '../styles/TextFontSize'
import TextLineHeight from '../styles/TextLineHeight'

function PaymentMethodSettings({
  entity,
  updateStyle,
  updateOption,
  updateMobileStyle,
  removeEntityStyle,
  removeEntityMobileStyle,
  update,
}) {
  const { isMobile } = useDeviceModeContext()

  const handleFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        delete updatedEntity.mobileStyles.fontWeight
        delete updatedEntity.mobileStyles.fontStyle
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            fontFamily: fontProperties.fontFamily,
          },
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.fontFileId
        ) {
          delete updatedEntity.mobileOptions.fontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.mobileStyles.fontWeight
          delete updatedEntity.mobileStyles.fontStyle
        }
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            ...fontProperties,
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        delete updatedEntity.styles.fontWeight
        delete updatedEntity.styles.fontStyle
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            fontFamily: fontProperties.fontFamily,
          },
          options: {
            ...updatedEntity.options,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (updatedEntity.options.fontFileId) {
          delete updatedEntity.options.fontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.styles.fontWeight
          delete updatedEntity.styles.fontStyle
        }
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            ...fontProperties,
          },
        })
      }
    }
  }

  const fontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const fontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const fontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  return (
    <React.Fragment>
      <GroupTitle>typography</GroupTitle>
      <TextFontSize
        update={updateStyle('fontSize')}
        mobileUpdate={updateMobileStyle('fontSize')}
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
        removeEntityStyle={removeEntityStyle('fontSize')}
        removeEntityMobileStyle={removeEntityMobileStyle('fontSize')}
      />
      <TextLineHeight
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
        update={updateStyle('lineHeight', 'px')}
        mobileUpdate={updateMobileStyle('lineHeight', 'px')}
        lineHeight={entity.styles.lineHeight}
        mobileLineHeight={entity.mobileStyles.lineHeight}
        removeEntityStyle={removeEntityStyle('lineHeight')}
        removeEntityMobileStyle={removeEntityMobileStyle('lineHeight')}
      />
      <TextFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="text color"
        update={updateStyle('color')}
        mobileUpdate={updateMobileStyle('color')}
        color={entity.styles.color}
        mobileColor={entity.mobileStyles.color}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

PaymentMethodSettings.propTypes = {
  entity: types.entity.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  removeEntityMobileStyle: PropTypes.func.isRequired,
  removeEntityStyle: PropTypes.func.isRequired,
}

export default PaymentMethodSettings
