import styled from 'styled-components'

const CreateBlogDialogPreviewUi = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export default CreateBlogDialogPreviewUi
