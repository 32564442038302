import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { domNormalize } from 'common/utils/domNormalizeHack'
import { initialiseAxiosInterceptors } from 'client/utils/registerInterceptors'
import App from './App'
import './i18n'
import { configureStore } from './store/index'

// Start the mocking conditionally.
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start({
//     serviceWorker: {
//       url: '/assets/mockServiceWorker.js',
//     },
//   })
// }

domNormalize()
initialiseAxiosInterceptors()

const store = configureStore()
render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app'),
)
