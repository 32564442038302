import React, { memo } from 'react'
import { MarginType } from 'common/types/styleTypes'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Range from '../components/Range/Range'
import GroupTitle from '../styleComponents/GroupTitle'
import MobileIconUi from '../styles/ui/MobileIconUi'

type MarginKeyType = keyof MarginType

export type MarginUpdater = (key: MarginKeyType) => (value: number) => void

type MarginProps = {
  margin: Partial<MarginType>
  update: MarginUpdater
}

function MarginStyle({ margin, update }: MarginProps) {
  const { isMobile } = useDeviceModeContext()

  return (
    <React.Fragment>
      <GroupTitle>settings_styles.margin.label</GroupTitle>
      <Range
        label="settings_styles.margin.top"
        labelIcon={isMobile ? <MobileIconUi /> : null}
        value={margin.marginTop}
        change={update('marginTop')}
        max={200}
      />
      <Range
        label="settings_styles.margin.right"
        labelIcon={isMobile ? <MobileIconUi /> : null}
        value={margin.marginRight}
        change={update('marginRight')}
        max={200}
      />
      <Range
        label="settings_styles.margin.bottom"
        labelIcon={isMobile ? <MobileIconUi /> : null}
        value={margin.marginBottom}
        change={update('marginBottom')}
        max={200}
      />
      <Range
        label="settings_styles.margin.left"
        labelIcon={isMobile ? <MobileIconUi /> : null}
        value={margin.marginLeft}
        change={update('marginLeft')}
        max={200}
      />
    </React.Fragment>
  )
}

export default memo(MarginStyle)
