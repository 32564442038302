import { useCallback } from 'react'
import * as actions from 'client/actions/entityActions'

export default function useUpdateEntityMobileOption(dispatch, entity) {
  return useCallback(
    option => value =>
      dispatch(
        actions.update({
          ...entity,
          mobileOptions: {
            ...entity.mobileOptions,
            [option]: value,
          },
        }),
      ),
    [entity],
  )
}
