import { CountdownActionsEnum } from 'common/components/entities/Countdown/enums/CountdownActionsEnum'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'

export const countdownTypesLocoKeys: Record<CountdownTypesEnum, string> = {
  [CountdownTypesEnum.fixed]: 'components.countdown.types.date',
  [CountdownTypesEnum.delay]: 'components.countdown.types.minute',
  [CountdownTypesEnum.daily]: 'components.countdown.types.daily',
  [CountdownTypesEnum.deadline]: 'components.countdown.types.deadline',
}

export const countdownActionsLocoKeys: Record<CountdownActionsEnum, string> = {
  [CountdownActionsEnum.nothing]: 'entity_settings.countdown.actions.nothing',

  [CountdownActionsEnum.redirection]:
    'entity_settings.countdown.actions.redirection',
}
