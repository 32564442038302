import styled, { css } from 'styled-components'

type ThumbStyleProps = {
  greyThumb: boolean
}

const thumbStyle = css<ThumbStyleProps>`
   {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 2px solid #fff;
    margin-top: -7px;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background: ${p => (p.greyThumb ? '#ccc' : '#149efc')};
    cursor: pointer;
  }
`

const trackStyle = css`
   {
    animation: 0.2s;
    width: 100%;
    height: 3px;
    line-height: 40px;
    cursor: pointer;
    background: #ebedf0;
    border-radius: 2px;
    border: 0 solid #ccc;
  }
`

const RangeInputUi = styled.input.attrs(() => ({
  type: 'range',
}))`
  width: 230px !important;
  float: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  margin: 15px 0;
  &::-webkit-slider-thumb
    ${thumbStyle},
    &::-webkit-slider-runnable-track
    ${trackStyle},
    &::-moz-slider-thumb
    ${thumbStyle},
    &::-moz-range-thumb {
    ${thumbStyle};
    margin-top: 12px !important;
  }
  ,
  &::-moz-range-track
    ${trackStyle},
    &::-ms-thumb
    ${thumbStyle},
    &::-ms-track
    ${trackStyle},
    &::-moz-focus-outer {
    border: none;
  }
`

export default RangeInputUi
