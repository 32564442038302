import React from 'react'
import Range from '../components/Range/Range'
import MobileIconUi from './ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

type LetterSpacingProps = {
  letterSpacing?: number
  change: (val: number) => void
}

function LetterSpacing({ letterSpacing = 0, change }: LetterSpacingProps) {
  const { isMobile } = useDeviceModeContext()
  return (
    <Range
      labelIcon={isMobile ? <MobileIconUi /> : null}
      label="settings_styles.letter_spacing.label"
      value={letterSpacing}
      max={10}
      change={change}
    />
  )
}

export default LetterSpacing
