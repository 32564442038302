import React from 'react'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range/Range'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

interface FontSizeProps {
  label?: string
  fontSize?: number
  mobileFontSize?: number
  onChange: (value: number) => void
  onChangeMobile: (value: number) => void
}

const FontSize = ({
  label,
  fontSize,
  mobileFontSize,
  onChangeMobile,
  onChange,
}: FontSizeProps) => {
  const { isMobile } = useDeviceModeContext()
  const update = (value: number) =>
    isMobile ? onChangeMobile(value) : onChange(value)
  const currentFontSize = isMobile ? mobileFontSize || fontSize : fontSize

  return (
    <Range
      label={label}
      labelIcon={isMobile ? <MobileIconUi /> : undefined}
      value={currentFontSize}
      change={update}
      min={8}
    />
  )
}

export default FontSize
