import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { entity } from 'common/types'
import * as actions from 'client/actions'
import * as pageActions from 'client/store/page/pageActions'

export function useUpdateGlobalSetting() {
  const dispatch = useDispatch()

  return useCallback(
    (setting, sizing = null) =>
      value =>
        dispatch(
          actions.updateGlobalSetting(
            setting,
            sizing ? `${value}${sizing}` : value,
          ),
        ),
    [dispatch],
  )
}

export function useUpdateGlobalHeadingSettings() {
  const dispatch = useDispatch()
  return useCallback(
    styles => {
      if (styles.fontFileId) {
        return dispatch(
          pageActions.updateGlobalSettings({
            ...entity,
            headingFontFamily: styles.fontFamily,
            headingFontFileId: styles.fontFileId,
            headingFontWeight: undefined,
            headingFontStyle: undefined,
          }),
        )
      } else {
        return dispatch(
          pageActions.updateGlobalSettings({
            ...entity,
            headingFontFamily: styles.fontFamily,
            headingFontStyle: styles.fontStyle,
            headingFontWeight: styles.fontWeight,
            headingFontFileId: undefined,
          }),
        )
      }
    },
    [dispatch],
  )
}

export function useUpdateGlobalSettings() {
  const dispatch = useDispatch()

  return useCallback(
    styles => {
      if (styles.fontFileId) {
        return dispatch(pageActions.updateGlobalSettings(styles))
      } else {
        return dispatch(
          pageActions.updateGlobalSettings({
            ...entity,
            ...styles,
            fontFileId: undefined,
          }),
        )
      }
    },
    [dispatch],
  )
}
