import React from 'react'
import { useTranslation } from 'react-i18next'
import CommonBodyNew from 'common/components/entities/BodyNew'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BlogContentPlaceholderInterface } from 'common/types/entities/BlogContentPlaceholderInterface'
import Badge from 'client/components/core/Badge'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import BlogContentPlaceholderUi from '../../../components/entities/BlogContentPlaceholder/ui/BlogContentPlaceholderUi'

function BlogContentPlaceholderNew({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogContentPlaceholderInterface>) {
  const { t } = useTranslation()

  const renderChildren = () => {
    return entity.childIds.length > 0 ? (
      <ChildrenEntities
        id={entity.id}
        isReadOnly={entity.isReadOnly}
        childIds={entity.childIds}
        type={entity.type}
      />
    ) : (
      <>
        <BlogContentPlaceholderUi>
          {t('entities.blog_content_placeholder.placeholder')}
        </BlogContentPlaceholderUi>
        <Badge wide>
          {t('entities.blog_content_placeholder.warnings.do_not_remove')}
        </Badge>
      </>
    )
  }

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonBodyNew
        htmlAttrId={entity.htmlAttrId}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
      >
        {renderChildren()}
      </CommonBodyNew>
    </BaseEntityNew>
  )
}

export function createBlogContentPlaceholder(
  parentId: string,
  masterBlockId?: string,
) {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.BlogContentPlaceholder,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.BlogContentPlaceholder,
    padding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    isReadOnly: false,
    childIds: [],
  } as BlogContentPlaceholderInterface
}

export default BlogContentPlaceholderNew
