import styled from 'styled-components'

const SwitcherDescriptionUi = styled.div`
  margin-bottom: 7px;
  font-size: 13px;
  display: block;
  color: #6b7c93;
`

export default SwitcherDescriptionUi
