import styled from 'styled-components'

interface RadioInputUiProps {
  marginLeft?: number
}

const RadioInputUi = styled.input<RadioInputUiProps>`
  margin-left: ${p => p.marginLeft}px;
  margin-right: 5px;
`

export default RadioInputUi
