import { useEffect } from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import { TextInterface } from 'common/types/entities/TextInterface'
import {
  appendGoogleFonts,
  entitiesWithFontOptions,
  generateGoogleFontFacesString,
  getEntitiesGoogleFonts,
  getFilteredGoogleFonts,
  getInheritedFontsVariants,
  isGoogleFont,
  UsedGoogleFonts,
} from 'common/utils/fontsUtils'
import { usePage } from 'client/store'
import { pageSelectors } from 'client/store/page/pageSelectors'

function usePageFonts() {
  const globalFontProperties = usePage(page =>
    pageSelectors.getGlobalFontProperties(page),
  )

  const entities = usePage(page =>
    pageSelectors.getEntitiesByTypes(page, entitiesWithFontOptions),
  )
  const pageGoogleFonts = getEntitiesGoogleFonts(entities)

  const textEntities = entities.filter(
    entity => entity.type === EntityTypeEnum.Text,
  ) as TextInterface[]
  const headlineEntities = entities.filter(
    entity => entity.type === EntityTypeEnum.Headline,
  ) as HeadlineInterface[]

  //text
  if (
    globalFontProperties.fontFamily &&
    isGoogleFont(globalFontProperties.fontFamily) &&
    globalFontProperties.fontStyle &&
    globalFontProperties.fontWeight
  ) {
    //get font-variants for text and headline entities with inherited or empty font-family
    //add global fontWeight for inherited entities with italic font variant
    const inheritedTextFontVariants = getInheritedFontsVariants(
      textEntities,
      globalFontProperties.fontWeight,
    )
    const inheritedHeadlineFontVariants = getInheritedFontsVariants(
      headlineEntities,
      globalFontProperties.fontWeight,
    )
    let googleInheritedHeadlineFontVariants: UsedGoogleFonts = []

    const googleInheritedTextFontVariants: UsedGoogleFonts =
      inheritedTextFontVariants.map(fontVariant => ({
        ...fontVariant,
        fontFamily: globalFontProperties.fontFamily as string,
      }))

    //check if global headline is inherit -> add global text font properties
    if (
      !globalFontProperties.headingFontFamily ||
      globalFontProperties.headingFontFamily === 'inherit'
    ) {
      googleInheritedHeadlineFontVariants = inheritedHeadlineFontVariants.map(
        el => ({
          ...el,
          fontFamily: globalFontProperties.fontFamily as string,
        }),
      )
    }

    pageGoogleFonts.push(
      ...googleInheritedTextFontVariants,
      ...googleInheritedHeadlineFontVariants,
      {
        fontStyle: globalFontProperties.fontStyle,
        fontWeight: globalFontProperties.fontWeight,
        fontFamily: globalFontProperties.fontFamily,
      },
    )
  }
  //heading
  if (
    globalFontProperties.headingFontFamily &&
    isGoogleFont(globalFontProperties.headingFontFamily) &&
    globalFontProperties.headingFontStyle &&
    globalFontProperties.headingFontWeight
  ) {
    const inheritedHeadlineFontVariants = getInheritedFontsVariants(
      headlineEntities,
      globalFontProperties.headingFontWeight,
    )

    const googleInheritedHeadlineFontVariants: UsedGoogleFonts =
      inheritedHeadlineFontVariants.map(fontVariant => ({
        ...fontVariant,
        fontFamily: globalFontProperties.headingFontFamily as string,
      }))

    pageGoogleFonts.push(...googleInheritedHeadlineFontVariants, {
      fontStyle: globalFontProperties.headingFontStyle,
      fontWeight: globalFontProperties.headingFontWeight,
      fontFamily: globalFontProperties.headingFontFamily,
    })
  }

  const pageFontsStr = JSON.stringify(pageGoogleFonts)

  useEffect(() => {
    const filteredPageGoogleFonts = getFilteredGoogleFonts(pageGoogleFonts)
    const googleFontFacesString = generateGoogleFontFacesString(
      filteredPageGoogleFonts,
    )
    appendGoogleFonts(googleFontFacesString)
  }, [pageFontsStr, pageGoogleFonts])
}

export default usePageFonts
