import React from 'react'
import { MarginType } from 'common/types/styleTypes'
import MarginLabelUi from './ui/MarginLabelUi'

export function findLargestMargin(margin: MarginType): number {
  return Math.max(...Object.values(margin))
}

export function isSingleMargin(margin: Partial<MarginType>) {
  if (
    Number.isInteger(margin.marginTop) &&
    Number.isInteger(margin.marginRight) &&
    Number.isInteger(margin.marginBottom) &&
    Number.isInteger(margin.marginLeft)
  ) {
    return Object.values(margin).every(
      (value, index, array) => value === array[0],
    )
  }

  return false
}

export const rightSideRenderer = (isActive: boolean, corner: string) => (
  <MarginLabelUi
    key={corner}
    htmlFor={corner}
    style={{
      gridArea: 'rightSide',
      justifyContent: 'center',
      zIndex: 0,
    }}
  >
    <svg
      width="3"
      height="25"
      viewBox="0 0 26 448"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 435L13 13"
        stroke={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
        strokeWidth="65"
        strokeLinecap="square"
      />
    </svg>
  </MarginLabelUi>
)

export const bottomSideRenderer = (isActive: boolean, corner: string) => (
  <MarginLabelUi
    key={corner}
    htmlFor={corner}
    style={{
      gridArea: 'bottomSide',
      alignItems: 'center',
      zIndex: 1,
    }}
  >
    <svg
      width="25"
      height="15"
      viewBox="0 0 448 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 13H435"
        stroke={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
        strokeWidth="55"
        strokeLinecap="square"
      />
    </svg>
  </MarginLabelUi>
)

export const leftSideRenderer = (isActive: boolean, corner: string) => (
  <MarginLabelUi
    key={corner}
    htmlFor={corner}
    style={{
      gridArea: 'leftSide',
      justifyContent: 'center',
      zIndex: 0,
    }}
  >
    <svg
      width="3"
      height="25"
      viewBox="0 0 26 448"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 435L13 13"
        stroke={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
        strokeWidth="65"
        strokeLinecap="square"
      />
    </svg>
  </MarginLabelUi>
)

export const topSideRenderer = (isActive: boolean, corner: string) => (
  <MarginLabelUi
    key={corner}
    htmlFor={corner}
    style={{
      gridArea: 'topSide',
      alignItems: 'center',
      zIndex: 1,
    }}
  >
    <svg
      width="25"
      height="15"
      viewBox="0 0 448 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 13H435"
        stroke={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
        strokeWidth="55"
        strokeLinecap="square"
      />
    </svg>
  </MarginLabelUi>
)

export const singleContainerRenderer = (
  isActive: boolean,
  onClick: () => void,
) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 448 448"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ marginRight: '10px', cursor: 'pointer' }}
  >
    <path
      d="M435 13H13V435H435V13Z"
      stroke={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
      strokeWidth="80"
      strokeLinecap="square"
    />
  </svg>
)

export const marginStylesTranslations: Record<keyof MarginType, string> = {
  marginTop: 'settings_styles.margin.top',
  marginRight: 'settings_styles.margin.right',
  marginBottom: 'settings_styles.margin.bottom',
  marginLeft: 'settings_styles.margin.left',
}
