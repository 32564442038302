import { MenuLinkTypeEnum } from 'common/components/entities/Menu/enums/MenuLinkTypeEnum'
import { BaseMenuItemInterface } from 'common/components/entities/Menu/types/MenuInterface'

export function detectLinkType(
  item: BaseMenuItemInterface,
  isAllowedBlogLinks: boolean,
) {
  if (!isAllowedBlogLinks) return MenuLinkTypeEnum.link_type_custom_url

  if (item.link.includes('%BLOG_') || item.link === '') {
    return MenuLinkTypeEnum.link_type_blog_page
  }

  return MenuLinkTypeEnum.link_type_custom_url
}
