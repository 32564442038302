import styled from 'styled-components'

const EmptyListTextWrapperUi = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(20, 45, 99, 1);
`

export default EmptyListTextWrapperUi
