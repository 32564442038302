import React from 'react'
import { ButtonInterface } from 'common/types/entities/ButtonInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import ButtonIconPicker from '../../../components/core/Sidebar/components/Settings/components/ButtonIconPicker'
import HoverButtonSetting from '../../../components/core/Sidebar/components/Settings/components/HoverButton'
import Input from '../../../components/core/Sidebar/components/Settings/components/Input'
import { SettingsProps } from '../../../components/core/Sidebar/components/Settings/entities'
import {
  useUpdateEntity,
  useUpdateProp,
} from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import GroupTitle from '../../../components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import { ShadowStyle } from '../../../components/core/Sidebar/components/Settings/styles'
import Border from '../../../components/core/Sidebar/components/Settings/styles/Border'
import ButtonWidth from '../../../components/core/Sidebar/components/Settings/styles/ButtonWidth'
import FlexAlignStyle from '../../../components/core/Sidebar/components/Settings/styles/FlexAlignStyle'
import FontSize from '../../../components/core/Sidebar/components/Settings/styles/FontSize'
import { useDeviceModeContext } from '../../../context/DeviceModeContext'

function ButtonSettings({ entity }: SettingsProps<ButtonInterface>) {
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<ButtonInterface>()

  return (
    <>
      <GroupTitle>entity_settings.button.text.group_title</GroupTitle>
      <Input
        labelText="entity_settings.button.text.label"
        value={entity.text}
        update={updateProp('text')}
        placeholder=""
        type="text"
        helpMessage=""
      />
      <FontSize
        update={updateProp('textFontSize')}
        mobileUpdate={updateProp('mobileTextFontSize')}
        fontSize={entity.textFontSize}
        mobileFontSize={entity.mobileTextFontSize}
      />
      <TextFontUpdater<ButtonInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'textFontFamily'}
        fontWeightKey={'textFontWeight'}
        fontStyleKey={'textFontStyle'}
        mobileFontFamilyKey={'mobileTextFontFamily'}
        mobileFontWeightKey={'mobileTextFontWeight'}
        mobileFontStyleKey={'mobileTextFontStyle'}
        fontFileIdKey={'textFontFileId'}
        mobileFontFileIdKey={'mobileTextFontFileId'}
      />
      <ColorPicker
        update={updateProp('textColor')}
        mobileUpdate={updateProp('mobileTextColor')}
        color={entity.textColor}
        mobileColor={entity.mobileTextColor}
      />
      <GroupTitle>entity_settings.button.sub_text.group_title</GroupTitle>
      <Input
        labelText="entity_settings.button.sub_text.label"
        value={entity.subText}
        update={updateProp('subText')}
        placeholder=""
        type="text"
        helpMessage=""
      />
      <FontSize
        update={updateProp('subTextFontSize')}
        mobileUpdate={updateProp('mobileSubTextFontSize')}
        fontSize={entity.subTextFontSize}
        mobileFontSize={entity.mobileSubTextFontSize}
      />
      <TextFontUpdater<ButtonInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'subTextFontFamily'}
        fontWeightKey={'subTextFontWeight'}
        fontStyleKey={'subTextFontStyle'}
        mobileFontFamilyKey={'mobileSubTextFontFamily'}
        mobileFontWeightKey={'mobileSubTextFontWeight'}
        mobileFontStyleKey={'mobileSubTextFontStyle'}
        fontFileIdKey={'subTextFontFileId'}
        mobileFontFileIdKey={'mobileSubTextFontFileId'}
      />
      <ColorPicker
        update={updateProp('subTextColor')}
        mobileUpdate={updateProp('subTextColor')}
        color={entity.subTextColor}
        mobileColor={entity.mobileSubTextColor}
      />
      <GroupTitle>{''}</GroupTitle>
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="settings_styles.background_color.label"
      />
      <ButtonIconPicker
        valueBefore={entity.iconClassNameBefore}
        valueAfter={entity.iconClassNameAfter}
        updateBefore={updateProp('iconClassNameBefore')}
        updateAfter={updateProp('iconClassNameAfter')}
      />
      <FlexAlignStyle
        align={entity.alignSelf}
        mobileAlign={entity.mobileAlignSelf}
        update={updateProp('alignSelf')}
        mobileUpdate={updateProp('mobileAlignSelf')}
      />
      <ButtonWidth
        update={updateProp('width')}
        mobileUpdate={updateProp('mobileWidth')}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
      />
      <HoverButtonSetting
        update={updateProp('hover')}
        mobileUpdate={updateProp('mobileHover')}
        hover={entity?.hover}
        mobileHover={entity?.mobileHover}
        backgroundColor={entity?.backgroundColor}
        textColor={entity?.textColor}
        subTextColor={entity?.subTextColor}
      />
      <Border
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
        border={isMobile ? entity.mobileBorder : entity.border}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
    </>
  )
}

export default ButtonSettings
