import styled from 'styled-components'

type AnglePickerSpanUiProps = {
  angle: number
}
const AnglePickerSpanUi = styled.span<AnglePickerSpanUiProps>`
  transform: ${({ angle }) => `rotate(${angle}deg)`};
  height: 32px;
  width: 6px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`
export default AnglePickerSpanUi
