import React from 'react'

const CalendarIcon = () => {
  return (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="#ACC9DE" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M34.6675 5.01055H33.699V2.48422C33.699 1.09474 32.6043 0 31.2148 0C29.8253 0 28.7306 1.09474 28.7306 2.48422V5.01055H22.6253V2.48422C22.6253 1.09474 21.5306 0 20.1411 0C18.7516 0 17.6569 1.09474 17.6569 2.48422V5.01055H11.5516V2.48422C11.5516 1.09474 10.4568 0 9.06736 0C7.67788 0 6.58314 1.09474 6.58314 2.48422V5.01055H5.61472C2.62524 5.01055 0.183105 7.45266 0.183105 10.4421V34.5686C0.183105 37.5581 2.62522 40.0002 5.61472 40.0002H34.6675C37.657 40.0002 40.0991 37.5581 40.0991 34.5686V10.4421C40.0991 7.45266 37.657 5.01055 34.6675 5.01055H34.6675ZM34.6675 36.379H5.61476C4.60423 36.379 3.80422 35.579 3.80422 34.5685V16.1684H36.4781V34.5685C36.4781 35.579 35.6781 36.379 34.6675 36.379Z" fill="#ACC9DE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2776 19.7891H8.26706C7.88812 19.7891 7.55127 20.1259 7.55127 20.5048V23.7049C7.55127 24.0838 7.88812 24.4207 8.26706 24.4207H13.2776C13.6566 24.4207 13.9934 24.0838 13.9934 23.7049V20.5048C13.9934 20.1259 13.6566 19.7891 13.2776 19.7891Z" fill="#ACC9DE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.6674 19.7891H17.6147C17.2358 19.7891 16.8989 20.1259 16.8989 20.5048V23.7049C16.8989 24.0838 17.2358 24.4207 17.6147 24.4207H22.6674C23.0463 24.4207 23.3832 24.0838 23.3832 23.7049V20.5048C23.3832 20.1259 23.0463 19.7891 22.6674 19.7891Z" fill="#ACC9DE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.0575 19.7891H27.0049C26.6259 19.7891 26.2891 20.1259 26.2891 20.5048V23.7049C26.2891 24.0838 26.6259 24.4207 27.0049 24.4207H32.0154C32.3944 24.4207 32.7312 24.0838 32.7312 23.7049V20.5048C32.7733 20.1259 32.4365 19.7891 32.0576 19.7891H32.0575Z" fill="#ACC9DE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2776 28.0852H8.26706C7.88812 28.0852 7.55127 28.4221 7.55127 28.801V32.001C7.55127 32.38 7.88812 32.7168 8.26706 32.7168H13.2776C13.6566 32.7168 13.9934 32.38 13.9934 32.001V28.801C13.9934 28.4221 13.6566 28.0852 13.2776 28.0852Z" fill="#ACC9DE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22.6674 28.0852H17.6147C17.2358 28.0852 16.8989 28.4221 16.8989 28.801V32.001C16.8989 32.38 17.2358 32.7168 17.6147 32.7168H22.6674C23.0463 32.7168 23.3832 32.38 23.3832 32.001V28.801C23.3832 28.4221 23.0463 28.0852 22.6674 28.0852Z" fill="#ACC9DE"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32.0575 28.0852H27.0049C26.6259 28.0852 26.2891 28.4221 26.2891 28.801V32.001C26.2891 32.38 26.6259 32.7168 27.0049 32.7168H32.0154C32.3944 32.7168 32.7312 32.38 32.7312 32.001V28.801C32.7733 28.4221 32.4365 28.0852 32.0576 28.0852H32.0575Z" fill="#ACC9DE"/>
    </svg>
  )
}

export default CalendarIcon
