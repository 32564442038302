import styled from 'styled-components'

const BlockIsMasterUi = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: red;
  font-size: 10px;
  color: #fff;
  padding: 2px;
  border-radius: 2px;
  opacity: 0.8;
  ${({ styles = {} }) => styles}
`

export default BlockIsMasterUi
