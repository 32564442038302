import { useCallback } from 'react'
import * as actions from 'client/actions/entityActions'

export default function useUpdateEntityOption(dispatch, entity) {
  return useCallback(
    option => value =>
      dispatch(
        actions.update({
          ...entity,
          options: {
            ...entity.options,
            [option]: value,
          },
        }),
      ),
    [entity],
  )
}
