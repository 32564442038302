import React from 'react'
import { CustomerTypeInterface } from 'common/types/entities/CustomerTypeInteface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import { PaymentMethodInterface } from 'common/types/entities/PaymentMethodInterface'
import EntityTypeEnum from '../../../common/enums/entityTypeEnum'
import createCommonEntitySettings from '../createCommonEntitySettings'
import CustomerTypeSettings from './settings/CustomerTypeSettings'
import OfferPriceSettings from './settings/OfferPriceSettings'
import PaymentMethodSettings from './settings/PaymentMethodSettings'

function createSettingsForOfferPage(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.OfferPrice:
      return <OfferPriceSettings entity={entity as OfferPriceInterface} />
    case EntityTypeEnum.CustomerType:
      return <CustomerTypeSettings entity={entity as CustomerTypeInterface} />
    case EntityTypeEnum.PaymentMethod:
      return <PaymentMethodSettings entity={entity as PaymentMethodInterface} />
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForOfferPage
