import PropTypes from 'prop-types'
import React from 'react'
import QuestionListItemNumberUi from './ui/QuestionListItemNumberUi'
import QuestionListItemTitle from './ui/QuestionListItemTitle'
import QuestionListItemUi from './ui/QuestionListItemUi'

type QuestionListProps = {
  number: number
  title: string
  children: React.ReactChild
  select: () => void
}

function QuestionList({ number, title, children, select }: QuestionListProps) {
  return (
    <QuestionListItemUi>
      <QuestionListItemNumberUi>{number}.</QuestionListItemNumberUi>
      <QuestionListItemTitle onClick={select}>{title}</QuestionListItemTitle>
      {children}
    </QuestionListItemUi>
  )
}

QuestionList.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default QuestionList
