import PropTypes from 'prop-types'
import React, { memo } from 'react'
import * as types from 'common/types'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Checkbox, Range, Select } from '../../components'
import GroupTitle from '../../styleComponents/GroupTitle'
import MobileIconUi from '../../styles/ui/MobileIconUi'

function Sticky({ entity, ...restProps }) {
  const { isMobile } = useDeviceModeContext()
  const stylesKey = isMobile ? 'mobileStyles' : 'styles'
  const position = isMobile
    ? entity.mobileStyles.position
    : entity.styles.position
  const top = parseInt(
    isMobile ? entity.mobileStyles.top : entity.styles.top,
    0,
  )
  const bottom = parseInt(
    isMobile ? entity.mobileStyles.bottom : entity.styles.bottom,
    0,
  )

  const place = top >= 0 ? 'top' : bottom >= 0 ? 'bottom' : 'top'
  const indent = top >= 0 ? top : bottom >= 0 ? bottom : 0

  const updateSticky = isSticky => {
    const newStyles = isSticky
      ? { position: 'sticky', top: '0px', zIndex: 2 }
      : { position: 'static' }

    const updatedEntity = {
      ...entity,
      [stylesKey]: {
        ...entity[stylesKey],
        ...newStyles,
      },
    }
    restProps.update(updatedEntity)
  }

  const updatePlace = prevPlace => nextPlace => {
    const updatedEntity = {
      ...entity,
      [stylesKey]: {
        ...entity[stylesKey],
        [nextPlace]: entity[stylesKey][prevPlace],
        [prevPlace]: 'auto',
      },
    }
    restProps.update(updatedEntity)
  }

  const updatePosition = value => {
    const updatedEntity = {
      ...entity,
      [stylesKey]: {
        ...entity[stylesKey],
        [place]: `${value}px`,
      },
    }
    restProps.update(updatedEntity)
  }

  const isSticky = position === 'sticky'

  return (
    <React.Fragment>
      <GroupTitle>settings_options.sticky.label</GroupTitle>
      <Checkbox
        labelText="settings_options.sticky.description"
        labelIcon={isMobile && <MobileIconUi />}
        update={updateSticky}
        value={isSticky}
        helpMessage="settings_options.sticky.help_message"
        directionRow
      />
      {isSticky && (
        <React.Fragment>
          <Select
            labelText="settings_options.sticky.to"
            labelIcon={isMobile && <MobileIconUi />}
            update={updatePlace(place)}
            value={place}
            choiceList={{
              top: 'settings_options.sticky.choices.top',
              bottom: 'settings_options.sticky.choices.bottom',
            }}
          />
          <Range
            update={updatePosition}
            labelText="settings_options.sticky.indent"
            value={indent}
            labelIcon={isMobile && <MobileIconUi />}
            max={200}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

Sticky.propTypes = {
  update: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
}

Sticky.defaultrestProps = {
  value: 'static',
}

export default memo(Sticky)
