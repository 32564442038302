import PropTypes from 'prop-types'
import React from 'react'
import { useActions } from 'common/hooks/useActions'
import * as types from 'common/types'
import { update as updateAc } from 'client/actions/entityActions'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Checkbox } from '../components'
import { DelayOption, DeviceAppearance, Attributes } from '../options'
import { getBackgroundStyles } from '../pages/components/Background'
import GroupTitle from '../styleComponents/GroupTitle'
import {
  PaddingStyle,
  MarginsStyle,
  CornerStyle,
  ShadowStyle,
  BorderStyleNew,
  BackgroundPositionStyleNew,
} from '../styles'

const RowSettingsOld = props => {
  const {
    toggleOverlay,
    overlayVisible,
    entity,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateMobileOption,
    update,
  } = props
  const { isMobile } = useDeviceModeContext()
  const updateAction = useActions(updateAc)

  const handleUpdateStyles = styles => {
    const entityMobileStyles = entity.mobileStyles ? entity.mobileStyles : {}
    const entityStyles = entity.styles ? entity.styles : {}
    const updatedEntity = { ...entity }
    if (updatedEntity.options.backgroundPosition) {
      delete updatedEntity.options.backgroundPosition
    }
    if (
      updatedEntity.mobileOptions &&
      updatedEntity.mobileOptions.backgroundPosition
    ) {
      delete updatedEntity.mobileOptions.backgroundPosition
    }
    if (isMobile) {
      updateAction({
        ...updatedEntity,
        mobileStyles: {
          ...entityMobileStyles,
          ...styles,
        },
      })
    } else {
      updateAction({
        ...updatedEntity,
        styles: {
          ...entityStyles,
          ...styles,
        },
      })
    }
  }
  const backgroundStyles = getBackgroundStyles(entity, isMobile)

  return (
    <React.Fragment>
      {/*<GroupTitle>entity_settings.row.column_space</GroupTitle>*/}
      {/*<Range*/}
      {/*  update={updateStyle('columnGap', 'px')}*/}
      {/*  value={entity.styles.columnGap}*/}
      {/*/>*/}
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="bg color"
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        color={entity.styles.backgroundColor}
        mobileColor={entity.mobileStyles.backgroundColor}
      />
      <BackgroundPositionStyleNew
        fileId={
          isMobile
            ? entity.mobileOptions.backgroundFileId ||
              entity.options.backgroundFileId
            : entity.options.backgroundFileId
        }
        updateBackgroundFileId={
          isMobile
            ? updateMobileOption('backgroundFileId')
            : updateOption('backgroundFileId')
        }
        update={handleUpdateStyles}
        backgroundPosition={backgroundStyles}
      />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <BorderStyleNew entity={entity} />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <Checkbox
        helpMessage="entity_settings.row.disable_stacking.help_message"
        labelText="entity_settings.row.disable_stacking.label"
        value={entity.options.disableStacking}
        update={updateOption('disableStacking')}
      />
      <Checkbox
        labelText="entity_settings.row.reverse_columns.label"
        value={entity.options.reverseColumns}
        update={updateOption('reverseColumns')}
      />
      <DelayOption
        update={updateOption('delay')}
        delay={entity.options.delay}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes entity={entity} update={updateOption} />
    </React.Fragment>
  )
}

RowSettingsOld.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default RowSettingsOld
