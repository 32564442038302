import { useCallback } from 'react'
import * as actions from 'client/actions/entityActions'

export default function useUpdateEntityStyle(dispatch, entity) {
  return useCallback(
    (style, sizing = null) => value =>
      dispatch(
        actions.update({
          ...entity,
          styles: {
            ...entity.styles,
            [style]: sizing ? `${value}${sizing}` : value,
          },
        }),
      ),
    [entity],
  )
}
