import { TransformStyleEnum } from 'common/enums/TransformStyleEnum'

export const TransformStyleLocoKeys: Record<TransformStyleEnum, string> = {
  [TransformStyleEnum.none]: 'settings_styles.hover_transform.none_effect',
  [TransformStyleEnum.scale]: 'settings_styles.hover_transform.zoom_effect',
  [TransformStyleEnum.translateX]:
    'settings_styles.hover_transform.slide_effect',
  [TransformStyleEnum.translateY]:
    'settings_styles.hover_transform.float_effect',
}
