import styled from 'styled-components'

const ColorPreviewUi = styled.div`
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
  border-radius: 15px;
  position: relative;
  height: 31px;
  width: 31px;
`
export default ColorPreviewUi
