import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import * as actions from 'client/actions'

export default function useUpdateSeoProperty() {
  const dispatch = useDispatch()

  return useCallback(
    property => value => dispatch(actions.updateSeoProperty(property, value)),
    [],
  )
}
