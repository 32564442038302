import styled from 'styled-components'

const AnswerNumberUi = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 10px 0;
`

export default AnswerNumberUi
