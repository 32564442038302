import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ImageActionEnum } from 'common/components/entities/ImageNew'
import { ImageInterface } from 'common/types/entities/ImageInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { FullBorderRadiusType } from 'common/types/styleTypes'
import { mimeTypes } from 'client/constants/editorSettings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useManagement, usePage } from 'client/store'
import { DataFile } from 'client/store/files/filesReducer'
import { disableInstantUpload } from 'client/store/management/managementActions'
import managementSelectors from 'client/store/management/managementSelectors'
import pageSelectors from 'client/store/page/pageSelectors'
import { PageTypeEnum } from '../../../../../../../common/enums/PageTypeEnum'
import { Checkbox, FileUpload, Range } from '../components'
import ColorPickerWithGradient from '../components/ColorPickerWithGradient'
import Input from '../components/Input/Input'
import Select, {
  ChangeOptionWithStringValue,
} from '../components/ReactSelect/ReactSelect'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from '../hooks/useUpdateProps'
import { DelayOption } from '../options'
import DeviceAppearance from '../options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../options/HtmlAttrId'
import ImageSize from '../options/ImageSize'
import GroupTitle from '../styleComponents/GroupTitle'
import { FlexAlignStyle, ShadowStyle } from '../styles'
import Border from '../styles/Border'
import MarginStyle from '../styles/MarginStyle'

function detectAction(entity: ImageInterface) {
  if (entity.popupId) {
    return ImageActionEnum.ShowPopup
  }

  return ImageActionEnum.OpenUrl
}

const actionNames = {
  [ImageActionEnum.ShowPopup]: 'components.image.actions.show_popup',
  [ImageActionEnum.OpenUrl]: 'components.image.actions.open_url',
}

function transformRadiusToFullBorderRadiusType(
  radius: number | undefined | FullBorderRadiusType,
) {
  if (typeof radius === 'number') {
    return {
      borderTopLeftRadius: radius,
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      borderBottomLeftRadius: radius,
    }
  } else if (typeof radius === 'undefined') {
    return {}
  } else return radius
}

function ImageSettingsNew({ entity }: { entity: ImageInterface }) {
  const [action, setAction] = useState(detectAction(entity))
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const popups = usePage(pageSelectors.getPopupEntities)
  const isLecture = usePage(pageSelectors.getPageType) === PageTypeEnum.Lecture
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<ImageInterface>()
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)
  const isInstantUploadEnabled = useManagement(
    managementSelectors.isInstantUploadEnabled,
  )

  function getPopupOption(popup: OldEntityInterface) {
    return {
      value: popup.id,
      label: popup.options.title,
    }
  }
  const popupOptions = popups.map(getPopupOption)
  const selectedPopup = popups.find(popup => popup.id === entity.popupId)

  function getActionOption(action: ImageActionEnum) {
    return {
      label: t(actionNames[action]),
      value: action,
    }
  }

  function removePopupId() {
    const updatedEntity = { ...entity }
    delete updatedEntity.popupId
    updateEntity(updatedEntity)
  }

  function updatePopupId(option: ChangeOptionWithStringValue) {
    if (option) {
      updateProp('popupId')(option.value)
    } else {
      removePopupId()
    }
  }

  function updateImage(file: DataFile) {
    const img = new Image()
    img.src = file.path
    img.onload = function () {
      const imageWrapper = document.getElementById(entity.id)
      if (imageWrapper) {
        const maxWidth = imageWrapper.getBoundingClientRect().width
        let resultWidth = maxWidth
        // @ts-ignore
        if (maxWidth > this.width) {
          // @ts-ignore
          resultWidth = this.width
        }

        updateEntity({
          ...entity,
          fileId: file.id,
          width: Number(resultWidth.toFixed(0)),
          // @ts-ignore
          ratio: Number((this.width / this.height).toFixed(2)),
        })
      }
    }
  }

  function updateFileLink(file: DataFile) {
    updateProp('link')(file.path)
  }

  function removeLink() {
    const updatedEntity = { ...entity }
    delete updatedEntity.link
    updateEntity(updatedEntity)
  }

  function removeFileId() {
    const updatedEntity = { ...entity }
    delete updatedEntity.fileId
    delete updatedEntity.externalUrl
    delete updatedEntity.ratio
    updateEntity(updatedEntity)
  }

  function updateAction(option: ChangeOptionWithStringValue) {
    if (option) {
      setAction(option.value as ImageActionEnum)

      if (option.value === ImageActionEnum.OpenUrl && entity.popupId) {
        removePopupId()
      } else if (option.value === ImageActionEnum.ShowPopup && entity.link) {
        removeLink()
      }
    }
  }

  function getMaxWidth() {
    const imageWrapper = document.getElementById(entity.id)
    if (!imageWrapper) {
      return
    }

    return Number(imageWrapper.getBoundingClientRect().width.toFixed(0))
  }

  function getImageRatio() {
    const image = document.getElementById(entity.htmlAttrId)
    if (!image) {
      return
    }

    const { width, height } = image.getBoundingClientRect()
    return Number((width / height).toFixed(2))
  }

  function updateImageSize(value: number | string) {
    const updatedEntity = {
      ...entity,
      width: value,
    }

    if (!entity.ratio) {
      const ratio = getImageRatio()
      if (ratio) {
        updatedEntity.ratio = ratio
      }
    }

    updateEntity(updatedEntity)
  }

  function updateImageMobileSize(value: number | string) {
    const updatedEntity = {
      ...entity,
      mobileWidth: value,
    }

    if (!entity.ratio) {
      const ratio = getImageRatio()
      if (ratio) {
        updatedEntity.ratio = ratio
      }
    }

    updateEntity(updatedEntity)
  }

  function handleRoundedCornersChange(corner: string) {
    return function (cornerValue: number) {
      if (entity.border) {
        const entityBorderRadius = transformRadiusToFullBorderRadiusType(
          entity.border.radius,
        ) as FullBorderRadiusType
        updateEntity({
          ...entity,
          border: {
            ...entity.border,
            radius: {
              ...entityBorderRadius,
              [corner]: cornerValue,
            },
          },
        })
      } else {
        entity.border = {}
        entity.border.radius = {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }
        updateEntity({
          ...entity,
          border: {
            ...entity.border,
            radius: {
              ...entity.border.radius,
              [corner]: cornerValue,
            },
          },
        })
      }
    }
  }

  const optionActionImage = isLecture
    ? [getActionOption(ImageActionEnum.OpenUrl)]
    : [
        getActionOption(ImageActionEnum.OpenUrl),
        getActionOption(ImageActionEnum.ShowPopup),
      ]

  const removeBackgroundColorProperty = () => {
    const updatedEntity = { ...entity }
    isMobile
      ? delete updatedEntity.mobileBackground
      : delete updatedEntity.background

    updateEntity(updatedEntity)
  }
  function handleMobileRoundedCornersChange(corner: string) {
    return function (cornerValue: number) {
      if (entity.mobileBorder) {
        const entityMobileBorderRadius = transformRadiusToFullBorderRadiusType(
          entity.mobileBorder.radius,
        ) as FullBorderRadiusType
        updateEntity({
          ...entity,
          mobileBorder: {
            ...entity.mobileBorder,
            radius: {
              ...entityMobileBorderRadius,
              [corner]: cornerValue,
            },
          },
        })
      } else {
        entity.mobileBorder = {}
        entity.mobileBorder.radius = {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }
        updateEntity({
          ...entity,
          mobileBorder: {
            ...entity.mobileBorder,
            radius: {
              ...entity.mobileBorder.radius,
              [corner]: cornerValue,
            },
          },
        })
      }
    }
  }
  const borderType = entity.border
    ? (transformRadiusToFullBorderRadiusType(
        entity.border.radius,
      ) as FullBorderRadiusType)
    : undefined
  const mobileBorderType = entity.mobileBorder
    ? (transformRadiusToFullBorderRadiusType(
        entity.mobileBorder.radius,
      ) as FullBorderRadiusType)
    : undefined

  return (
    <>
      <FileUpload
        directLink={entity.externalUrl}
        changeLinkHandler={updateProp('externalUrl')}
        fileId={entity.fileId}
        afterUpload={updateImage}
        removeFile={removeFileId}
        mimeTypes={mimeTypes.image}
        fileType="image"
        readonly={false}
        label="image"
        instantUploadEnabled={isInstantUploadEnabled}
        onClose={() => dispatch(disableInstantUpload())}
      />
      <Select<string>
        selectedOption={getActionOption(action)}
        update={updateAction}
        labelText="entity_settings.image.choose_action"
        options={optionActionImage}
        isMulti={false}
      />
      {action === ImageActionEnum.OpenUrl && (
        <>
          {/*@ts-ignore*/}
          <FileUpload
            directLink={entity.link}
            changeLinkHandler={updateProp('link')}
            afterUpload={updateFileLink}
            removeFile={removeLink}
            mimeTypes={mimeTypes.all}
            label="link"
            readonly={false}
            placeholder={t('entity_settings.image.choose_file_or_write_url')}
          />
          {/* @ts-ignore */}
          <Checkbox
            labelText="entity_settings.image.target_label"
            update={updateProp('openNewTab')}
            value={entity.openNewTab}
          />
        </>
      )}
      {action === ImageActionEnum.ShowPopup && (
        <Select<string>
          labelText="entity_settings.image.popup.label"
          update={updatePopupId}
          options={popupOptions}
          placeholder={t('entity_settings.image.popup.first_option')}
          selectedOption={selectedPopup ? getPopupOption(selectedPopup) : null}
          isMulti={false}
        />
      )}
      <Input update={updateProp('alt')} label="alt" value={entity.alt} />
      <ImageSize
        attrId={entity.id}
        updateWidth={updateImageSize}
        updateMobileWidth={updateImageMobileSize}
        width={entity.width || getMaxWidth()}
        mobileWidth={entity.mobileWidth}
      />
      <FlexAlignStyle
        align={entity.alignSelf}
        mobileAlign={entity.mobileAlignSelf || entity.alignSelf}
        mobileUpdate={updateProp('mobileAlignSelf')}
        update={updateProp('alignSelf')}
      />
      <ColorPickerWithGradient
        label="overlay color"
        color={entity.background}
        mobileColor={entity.mobileBackground || entity.background}
        update={updateProp('background')}
        mobileUpdate={updateProp('mobileBackground')}
        removeBackgroundColorProperty={removeBackgroundColorProperty}
        fallbackOpacity={0.5}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <Border
        border={isMobile ? entity.mobileBorder : entity.border}
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
      />
      <GroupTitle>filters</GroupTitle>
      <Range
        // @ts-ignore
        labelText="blur"
        value={entity.blur}
        update={updateProp('blur')}
        max={10}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('boxShadow')}
      />
      <DelayOption update={updateProp('delay')} delay={entity.delay} />
      <DeviceAppearance
        update={appearance => updateEntity({ ...entity, appearance })}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default ImageSettingsNew
