import { useCallback } from 'react'
import * as actions from 'client/actions/entityActions'

export default function useRemoveEntityMobileStyle(dispatch, entity) {
  const updatedEntity = {
    ...entity,
    mobileStyles: {
      ...entity.mobileStyles,
    },
  }

  return useCallback(
    style => () => {
      delete updatedEntity.mobileStyles[style]
      dispatch(actions.update(updatedEntity))
    },
    [updatedEntity],
  )
}
