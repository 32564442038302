import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { TwitterTweetButtonInterface } from 'common/types/entities/TwitterTweetButtonInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createTwitterTweetButton = (
  parentId: string,
  masterBlockId?: string,
): TwitterTweetButtonInterface => {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.TwitterTweetButton,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.TwitterTweetButton,
    text: '',
    alignSelf: 'center',
  }
}
