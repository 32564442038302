import PropTypes from 'prop-types'
import React from 'react'
import {
  borderSizes,
  borderStyleValues,
  borderTypeNames,
  borderTypeKeys,
} from 'common/constants/settings'
import { useActions } from 'common/hooks/useActions'
import * as entityActions from 'client/actions/entityActions'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import {
  useOverlayActionsContext,
  useOverlayVisibilityContext,
} from '../SettingsOverlayContext'
import { Select } from '../components'
import GroupTitle from '../styleComponents/GroupTitle'
import MobileIconUi from './ui/MobileIconUi'

function BorderStyleNew({ entity }) {
  const { options, mobileOptions, styles, mobileStyles } = entity
  const { isMobile } = useDeviceModeContext()
  const isOverlayVisible = useOverlayVisibilityContext()
  const { toggle: toggleOverlay } = useOverlayActionsContext()
  const update = useActions(entityActions.update)
  const type =
    (isMobile ? mobileOptions.borderType : options.borderType) ||
    borderTypeKeys.none

  const style =
    (isMobile ? mobileStyles.borderStyle : styles.borderStyle) ||
    borderStyleValues.none
  const width = isMobile ? mobileStyles.borderWidth : styles.borderWidth
  const color = isMobile ? mobileStyles.borderColor : styles.borderColor

  const updateStyle = style => value => {
    update({
      ...entity,
      ...(isMobile
        ? { mobileStyles: { ...mobileStyles, [style]: value } }
        : { styles: { ...styles, [style]: value } }),
    })
  }

  const updateBorderStyle = value => {
    if (
      style === borderStyleValues.none &&
      [
        borderStyleValues.dashed,
        borderStyleValues.dotted,
        borderStyleValues.solid,
      ].includes(value)
    ) {
      const enabledBorderStyles = {
        borderWidth: '1px',
        borderStyle: borderStyleValues.solid,
        borderColor: 'rgba(0, 0, 0, 1)',
      }
      update({
        ...entity,
        ...(isMobile
          ? {
              mobileStyles: {
                ...mobileStyles,
                ...enabledBorderStyles,
              },
            }
          : {
              styles: {
                ...styles,
                ...enabledBorderStyles,
              },
            }),
      })
    } else {
      update({
        ...entity,
        ...(isMobile
          ? { mobileStyles: { ...mobileStyles, borderStyle: value } }
          : { styles: { ...styles, borderStyle: value } }),
      })
    }
  }

  const updateBorderType = value => {
    update({
      ...entity,
      ...(isMobile
        ? { mobileOptions: { ...mobileOptions, borderType: value } }
        : { options: { ...options, borderType: value } }),
    })
  }

  return (
    <React.Fragment>
      <GroupTitle>settings_styles.border.label</GroupTitle>
      <Select
        update={updateBorderStyle}
        value={style}
        choiceList={borderStyleValues}
        labelText="settings_styles.border_style.label"
        labelIcon={isMobile && <MobileIconUi />}
      />
      {style !== borderStyleValues.none && [
        <ColorPicker
          key="border-color"
          update={updateStyle('borderColor')}
          color={color}
          label="settings_styles.border.color"
          singleColorMode
        />,
        <Select
          key="border-type"
          update={updateBorderType}
          value={type}
          choiceList={borderTypeNames}
          labelText="settings_styles.border.type"
          labelIcon={isMobile && <MobileIconUi />}
        />,
        <Select
          key="border-width"
          update={updateStyle('borderWidth')}
          value={width}
          choiceList={borderSizes}
          labelText="settings_styles.border.size"
          labelIcon={isMobile && <MobileIconUi />}
        />,
      ]}
    </React.Fragment>
  )
}

const optionsPropTypes = PropTypes.shape({
  borderType: PropTypes.oneOf(Object.keys(borderTypeKeys)),
})

const stylesPropTypes = PropTypes.shape({
  borderStyle: PropTypes.oneOf(Object.keys(borderStyleValues)),
  borderWidth: PropTypes.oneOf(Object.keys(borderSizes)),
  borderColor: PropTypes.string,
})

BorderStyleNew.propTypes = {
  entity: PropTypes.shape({
    options: optionsPropTypes,
    mobileOptions: optionsPropTypes,
    styles: stylesPropTypes,
    mobileStyles: stylesPropTypes,
  }).isRequired,
}

export default BorderStyleNew
