import React, { memo } from 'react'
import PropTypes from 'prop-types'
import * as types from 'common/types'
import CommonPopup from 'common/components/entities/Popup'
import { borderTypeValues } from 'common/constants/settings'
import { useActions } from 'common/hooks/useActions'
import * as actions from 'client/actions/actionsManagement'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import { getEmulatedEntity } from 'client/components/core/BaseEntity/utils'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import useManagement from '../../../hooks/useManagement'
import { selectors } from '../../../reducers/managementReducer'
import emulationMobileStyles from './emulationMobileStyles'
import PopupEditButton from './PopupEditButton'
import PopupOverlayOverflowHiddenUi from './ui/PopupOverlayOverflowHiddenUi'
import PopupOverlayUi from './ui/PopupOverlayUi'

function Popup({ entity, hidePopupEditButton }) {
  const { isMobile } = useDeviceModeContext()
  const {
    id,
    childIds,
    options,
    styles,
    mobileStyles,
    type,
  } = getEmulatedEntity(entity, isMobile, emulationMobileStyles)
  const toggleSettings = useActions(actions.toggleSettings)
  const isSomethingEditing = useManagement(selectors.isSomethingEditing)
  const openSettings = () => toggleSettings(entity.id)
  const closeSettings = () => toggleSettings(null)

  return (
    <React.Fragment>
      {!hidePopupEditButton && <PopupEditButton clickHandler={openSettings} />}
      <PopupOverlayOverflowHiddenUi>
        <CommonPopup
          onCloseHandler={() => {}}
          styles={{
            ...styles,
            ...borderTypeValues[options.borderType],
          }}
          mobileStyles={mobileStyles}
          showClose={!!+options.showClose}
          maxHeight={isMobile ? '500px' : 'calc(100vh - 110px)'}
        >
          <ChildrenEntities
            id={id}
            type={type}
            childIds={childIds}
            isReadOnly={entity.isReadOnly}
          />
        </CommonPopup>
        <PopupOverlayUi
          zIndex={isSomethingEditing ? 1 : -1}
          onClick={closeSettings}
        />
      </PopupOverlayOverflowHiddenUi>
    </React.Fragment>
  )
}

Popup.propTypes = {
  entity: types.entity.isRequired,
  hidePopupEditButton: PropTypes.bool,
}

Popup.defaultProps = {
  showModal: false,
  hidePopupEditButton: false,
}

export default memo(Popup)
