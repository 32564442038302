import styled from 'styled-components'

const SidebarTogglerUi = styled.div`
  border-bottom: 10px solid #ddd;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  height: 0;
  width: 20px;
  position: absolute;
  top: 50%;
  left: calc(100% - 15px);
  box-sizing: content-box;
  transform: rotate(90deg);
  cursor: pointer;
`

export default SidebarTogglerUi
