import React, { useRef, useState } from 'react'
import { useDrag, useDragLayer } from 'react-dnd'
import { useDispatch } from 'react-redux'
import { useActions } from 'common/hooks/useActions'
import EntityInterface from 'common/types/entities/EntityInterface'
import { showCreateBlockDialog } from 'client/actions/actionsManagement'
import * as entityActions from 'client/actions/entityActions'
import { OptionalRefInterface } from 'client/components/entities/Row/components/ColumnAlignControls'
import { color } from 'client/constants/color'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useHighlightedEntityId } from 'client/context/HighlightedContext'
import { useIsRowResizing } from 'client/context/ResizingRowContext'
import { useSidebarVisibilityActionsContext } from 'client/context/SidebarVisibilityContext'
import { useManagement } from 'client/store/index'
import { usePage, pageSelectors } from 'client/store/index'
import { setEditingEntity } from 'client/store/management/managementActions'
import managementSelectors from 'client/store/management/managementSelectors'
import ActionsBox from './ActionsBox'
import DeviceWarning from './DeviceWarning'
import ReadOnlyEntity from './ReadOnlyEntity'
import BaseEntityNewUi from './ui/BaseEntityNewUi'
import * as utils from './utils'

export type BaseEntityProps = {
  entity: EntityInterface
  highlightedColor?: string
  hoverColor?: string
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
  isSaveAllowed?: boolean
  isRemoveAllowed?: boolean
  isCopyAllowed?: boolean
  children: React.ReactChild
  dropBoxAllowedEntityIds?: string[]
  flex?: boolean
  flexColumn?: boolean
  externalActionsRef?: React.RefObject<OptionalRefInterface>
}

// const warningColor: '#EB5447',

function BaseEntityNew({
  entity,
  highlightedColor = color.blue,
  hoverColor = color.orange,
  isMoveDownAllowed,
  isMoveUpAllowed,
  isSaveAllowed = true,
  isRemoveAllowed = true,
  isCopyAllowed = true,
  dropBoxAllowedEntityIds,
  children,
  flex,
  flexColumn,
  externalActionsRef,
}: BaseEntityProps) {
  const dispatch = useDispatch()
  const actionsBoxRef = useRef(null)
  const currentRef = externalActionsRef ? externalActionsRef : actionsBoxRef
  const { isMobile } = useDeviceModeContext()
  const [showMenu, setShowMenu] = useState(false)
  const { isSomethingDragging } = useDragLayer(monitor => ({
    isSomethingDragging: monitor.isDragging(),
  }))
  const highlightedId = useHighlightedEntityId()
  const isResizing = useIsRowResizing()
  const descendantIds = usePage(page =>
    pageSelectors.getAllDescendantIds(page, entity.id),
  )
  const isEditing = useManagement(state =>
    managementSelectors.isEditing(state, entity.id),
  )
  const isHighlighted = highlightedId === entity.id
  const [, drag] = useDrag({
    item: {
      entity,
      type: entity.type,
      descendantIds,
      allowedEntityIds: dropBoxAllowedEntityIds,
    },
    canDrag: () => {
      return !isEditing && !isResizing
    },
  })
  const { showSidebar } = useSidebarVisibilityActionsContext()
  const showCreateBlockDialogAction = useActions(showCreateBlockDialog)
  const copy = useActions(entityActions.copy)
  const remove = useActions(entityActions.remove)
  const moveDown = useActions(entityActions.moveDown)
  const moveUp = useActions(entityActions.moveUp)
  const deviceWarning = utils.getDeviceWarning(entity.appearance, isMobile)

  function handleToggleSettings(e: React.BaseSyntheticEvent) {
    e.stopPropagation()
    if (e.target.nodeName !== 'INPUT') {
      e.preventDefault()
    }

    if (!isResizing && utils.notContainActionButtons(e, currentRef)) {
      dispatch(setEditingEntity(entity.id))
      showSidebar()
    }
  }

  function hover(e: React.SyntheticEvent) {
    e.stopPropagation()
    if (isEditing) {
      return
    }
    setShowMenu(true)
  }

  function unHover(e: React.SyntheticEvent) {
    e.stopPropagation()
    if (isResizing) {
      return
    }

    if (utils.relatedTargetNotContainsActionButtons(e, currentRef)) {
      setShowMenu(false)
    }
  }

  function getStateColor() {
    if (isHighlighted || isEditing) {
      return highlightedColor
    }

    if (showMenu) {
      return hoverColor
    }

    if (deviceWarning) {
      return '#EB5447'
    }

    return null
  }

  function handleMoveDown(e: React.SyntheticEvent) {
    e.stopPropagation()
    moveDown(entity)
  }

  function handleMoveUp(e: React.SyntheticEvent) {
    e.stopPropagation()
    moveUp(entity)
  }

  if (entity.isReadOnly) {
    return (
      <ReadOnlyEntity
        margin={entity.margin}
        mobileMargin={entity.mobileMargin}
        showInMobileMode={entity.appearance.mobile}
        showInDesktopMode={entity.appearance.desktop}
        flex={flex}
        flexColumn={flexColumn}
      >
        {children}
      </ReadOnlyEntity>
    )
  }

  return (
    <BaseEntityNewUi
      id={entity.id}
      ref={drag}
      stateColor={getStateColor()}
      onClick={handleToggleSettings}
      isEditing={isEditing}
      isSomethingDragging={isSomethingDragging}
      onMouseOver={hover}
      onMouseOut={unHover}
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
      flex={flex}
      flexColumn={flexColumn}
    >
      {showMenu && (
        <ActionsBox
          ref={currentRef}
          type={entity.type}
          copy={entity.isMasterBlockRoot ? false : () => copy(entity)}
          toggleSettings={handleToggleSettings}
          toggleSaveDialog={() => showCreateBlockDialogAction(entity.id)}
          remove={() => remove(entity)}
          backgroundColor={hoverColor}
          isMasterBlock={Boolean(entity.masterBlockId)}
          isMoveDownAllowed={isMoveDownAllowed}
          isMoveUpAllowed={isMoveUpAllowed}
          isRemoveAllowed={isRemoveAllowed}
          isCopyAllowed={isCopyAllowed}
          isSaveAllowed={isSaveAllowed}
          moveDown={handleMoveDown}
          moveUp={handleMoveUp}
        />
      )}
      {deviceWarning && <DeviceWarning deviceWarning={deviceWarning} />}
      {children}
    </BaseEntityNewUi>
  )
}

export default BaseEntityNew
