import PropTypes from 'prop-types'
import React from 'react'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Input } from '../components'
import TextFont from '../components/Font/TextFont'
import LanguageCheckList from '../components/LanguageCheckList'
import { DeviceAppearance, FontSize } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { LineHeight, MarginsStyle } from '../styles'

function LanguageSwitcherSettings({
  entity,
  updateStyle,
  updateMobileStyle,
  updateOption,
  update,
  toggleOverlay,
  overlayVisible,
}) {
  const { isMobile } = useDeviceModeContext()
  const excludedLanguages = entity.options.excludedLanguages

  const handleExcludeLanguages = excludedLanguages => {
    update({
      ...entity,
      options: {
        ...entity.options,
        excludedLanguages: excludedLanguages,
      },
    })
  }
  const handleFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            fontFamily: fontProperties.fontFamily,
          },
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.fontFileId
        ) {
          delete updatedEntity.mobileOptions.fontFileId
        }
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            ...fontProperties,
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            fontFamily: fontProperties.fontFamily,
          },
          options: {
            ...updatedEntity.options,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (updatedEntity.options.fontFileId) {
          delete updatedEntity.options.fontFileId
        }
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            ...fontProperties,
          },
        })
      }
    }
  }

  const fontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const fontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const fontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  return (
    <React.Fragment>
      <Input
        labelText="entity_settings.language_switcher.redirect_path"
        value={entity.options.redirectPath}
        update={updateOption('redirectPath')}
      />
      <GroupTitle>
        entity_settings.language_switcher.available_languages
      </GroupTitle>
      <LanguageCheckList
        update={handleExcludeLanguages}
        excludeLanguagesList={excludedLanguages}
      />
      <GroupTitle>typography</GroupTitle>
      <FontSize
        update={updateStyle('fontSize', 'px')}
        mobileUpdate={updateMobileStyle('fontSize', 'px')}
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
      />
      <LineHeight
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
        update={updateStyle('lineHeight', 'px')}
        mobileUpdate={updateMobileStyle('lineHeight', 'px')}
        lineHeight={entity.styles.lineHeight}
        mobileLineHeight={entity.mobileStyles.lineHeight}
      />
      <TextFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <ColorPicker
        label="text color"
        update={updateStyle('color')}
        mobileUpdate={updateMobileStyle('color')}
        color={entity.styles.color}
        mobileColor={entity.mobileStyles.color}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <DeviceAppearance update={update} entity={entity} />
    </React.Fragment>
  )
}

LanguageSwitcherSettings.propTypes = {
  entity: types.entity.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default LanguageSwitcherSettings
