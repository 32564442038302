import React from 'react'
import { mimeTypes } from 'client/constants/editorSettings'
import usePresentPage from 'client/hooks/usePresentPage'
import { getSeo } from 'client/reducers/pageReducer'
import { Checkbox, FileUpload, Input, TextArea } from '../../components'
import useUpdateSeoProperty from '../../hooks/useUpdateSeoProperty'
import GroupTitle from '../../styleComponents/GroupTitle'

function Seo() {
  const seo = usePresentPage(getSeo)
  const updateSeoProperty = useUpdateSeoProperty()

  return (
    <React.Fragment>
      <GroupTitle>page_settings.seo.group_title</GroupTitle>
      <Input
        update={updateSeoProperty('title')}
        value={seo.title}
        labelText="page_settings.seo.title.label"
      />
      <TextArea
        update={updateSeoProperty('description')}
        value={seo.description}
        labelText="page_settings.seo.description.label"
      />
      <Input
        update={updateSeoProperty('keywords')}
        value={seo.keywords}
        labelText="page_settings.seo.keywords.label"
      />
      <Input
        update={updateSeoProperty('author')}
        value={seo.author}
        labelText="page_settings.seo.author.label"
      />
      <FileUpload
        mimeTypes={mimeTypes.image}
        fileId={seo.socialImage}
        afterUpload={file => updateSeoProperty('socialImage')(file.id)}
        removeFile={() => updateSeoProperty('socialImage')(null)}
        label="page_settings.seo.social_image.label"
      />
      <Checkbox
        labelText="page_settings.seo.search_engines.label"
        value={seo.index}
        update={updateSeoProperty('index')}
        inverse
      />
    </React.Fragment>
  )
}

export default Seo
