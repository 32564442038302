import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import Overlay from 'common/components/core/Overlay'
import * as types from 'common/types'
import {
  showCreateBlockDialog,
  toggleSettings,
} from 'client/actions/actionsManagement'
import { update } from 'client/actions/entityActions'
import { editorZindex } from 'client/constants/editorSettings'
import { getEntityById } from 'client/reducers/pageReducer'
import settingsFactory from './settingsFactory'
import SettingsBackUi from './ui/SettingsBackUi'
import SettingsContainerUi from './ui/SettingsContainerUi'
import SettingsTitelIconUi from './ui/SettingsTitleIconUi'
import SettingsTitleUi from './ui/SettingsTitleUi'

class Settings extends Component {
  toggleSettings = () => {
    this.props.toggleSettings(this.props.entity.id)
  }

  toggleSaveDialog = () => {
    this.props.showCreateBlockDialog(this.props.entity.id)
  }

  updateStyle =
    (style, sizing = null) =>
    value => {
      if (value === '') {
        value = 0
      }

      const updatedEntity = {
        ...this.props.entity,
        styles: {
          ...this.props.entity.styles,
          [style]: sizing ? `${value}${sizing}` : value,
        },
      }

      this.props.update(updatedEntity)
    }

  updateMobileStyle =
    (style, sizing = null) =>
    value => {
      if (value === '') {
        value = 0
      }

      const updatedEntity = {
        ...this.props.entity,
        mobileStyles: {
          ...this.props.entity.mobileStyles,
          [style]: sizing ? `${value}${sizing}` : value,
        },
      }

      this.props.update(updatedEntity)
    }

  updateOption = (option, unitOfMeasure) => value => {
    const updatedEntity = {
      ...this.props.entity,
      options: {
        ...this.props.entity.options,
        [option]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    }

    return this.props.update(updatedEntity)
  }

  updateMobileOption = (option, unitOfMeasure) => value => {
    const updatedEntity = {
      ...this.props.entity,
      mobileOptions: {
        ...this.props.entity.mobileOptions,
        [option]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    }

    return this.props.update(updatedEntity)
  }

  updateOptions = options => {
    const updatedEntity = {
      ...this.props.entity,
      options: {
        ...this.props.entity.options,
        ...options,
      },
    }

    this.props.update(updatedEntity)
  }

  updateMobileOptions = options => {
    const updatedEntity = {
      ...this.props.entity,
      mobileOptions: {
        ...this.props.entity.mobileOptions,
        ...options,
      },
    }
    this.props.update(updatedEntity)
  }

  render() {
    const { entity, isOverlayActive } = this.props

    return settingsFactory.create(entity.type, entity.id, {
      update: this.props.update,
      updateEntityStyle: this.props.updateEntityStyle,
      removeEntityStyle: this.props.removeEntityStyle,
      removeEntityMobileStyle: this.props.removeEntityMobileStyle,
      updateStyle: this.updateStyle,
      updateMobileStyle: this.updateMobileStyle,
      updateOption: this.updateOption,
      updateMobileOption: this.updateMobileOption,
      updateOptions: this.updateOptions,
      updateMobileOptions: this.updateMobileOptions,
      toggleOverlay: this.props.toggleSettingsOverlay,
      overlayVisible: isOverlayActive,
      toggleSettings: this.toggleSettings,
      entity:
        this.props.entityWithMobileStyles /* todo rename to emulatedEntity */,
      entityWithoutEmulation: this.props.entity /* todo rename to entity */,
    })
  }
}

const mapStateToProps = (
  { page: { present: presentPage }, management },
  { entity },
) => {
  let entityWithMobileStyles = {}

  if (!entity) {
    return null
  }

  if (entity) {
    entityWithMobileStyles = {
      ...entity,
      mobileStyles: {
        ...entity.mobileStyles,
      },
    }

    if (entity.options.headerStyles) {
      entityWithMobileStyles.mobileOptions = {
        ...entity.mobileOptions,
        headerStyles: {
          ...entity.options.headerStyles,
          ...entity.mobileOptions.headerStyles,
        },
      }
    }
  }

  return {
    entityWithMobileStyles,
  }
}

Settings.propTypes = {
  entity: types.entity.isRequired,
  entityWithMobileStyles: types.entity.isRequired,
  update: PropTypes.func.isRequired,
  toggleSettingsOverlay: PropTypes.func.isRequired,
  toggleSettings: PropTypes.func.isRequired,
  isOverlayActive: PropTypes.bool.isRequired,
  showCreateBlockDialog: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  update,
  toggleSettings,
  showCreateBlockDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
