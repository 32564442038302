import React from 'react'
import PropTypes from 'prop-types'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import usePresentPage from '../hooks/usePresentPage'
import { getGlobalFontFamily } from '../reducers/pageReducer'
import InlineFormUi from './ui/InlineFormUi'

function InlineForm({ children }) {
  const fontFamily = usePresentPage(getGlobalFontFamily)
  return (
    <InlineFormUi styles={{ fontFamily: wrapFontFamily(fontFamily) }}>
      {children}
    </InlineFormUi>
  )
}

InlineForm.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InlineForm
