import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Translation } from 'react-i18next'
import GreenButton from 'client/components/core/GreenButton'
import { getSettingsEditing } from 'client/reducers/managementReducer'
import { toggleSettings, undo, redo } from 'client/actions/actionsManagement'

class UndoRedo extends PureComponent {
  componentDidMount() {
    document.onkeydown = e => {
      if (e.keyCode === 90 && (e.ctrlKey || e.metaKey) && this.props.canUndo) {
        if (this.props.settingEditing.id) {
          this.props.toggleSettings(null)
        }
        this.props.onUndo()
      }
    }
  }

  undo = () => {
    if (this.props.settingEditing.id) {
      this.props.toggleSettings(null)
    }
    this.props.onUndo()
  }

  render() {
    const { canUndo, canRedo, onRedo } = this.props
    return (
      <Translation>
        {t => (
          <React.Fragment>
            <GreenButton
              onClick={this.undo}
              disabled={!canUndo}
              title={t('core.history.undo.title')}
            >
              <span className="fas fa-undo" />
            </GreenButton>
            <GreenButton
              onClick={onRedo}
              disabled={!canRedo}
              title={t('core.history.redo.title')}
            >
              <span className="fas fa-redo" />
            </GreenButton>
          </React.Fragment>
        )}
      </Translation>
    )
  }
}

UndoRedo.propTypes = {
  canUndo: PropTypes.bool.isRequired,
  canRedo: PropTypes.bool.isRequired,
  onUndo: PropTypes.func.isRequired,
  onRedo: PropTypes.func.isRequired,
  toggleSettings: PropTypes.func.isRequired,
  settingEditing: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = ({ page, management }) => ({
  settingEditing: getSettingsEditing(management),
  canUndo: page.past.length > 1, // xhr data is 0 index
  canRedo: page.future.length > 0,
})

const mapDispatchToProps = {
  onUndo: undo,
  onRedo: redo,
  toggleSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(UndoRedo)
