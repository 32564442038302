import React from 'react'
import { Appearance } from 'common/types/entities/EntityInterface'
import WebinarSessionLinkInterface from 'common/types/entities/WebinarSessionLinkInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Input from '../../../components/core/Sidebar/components/Settings/components/Input/Input'
import {
  useUpdateProp,
  useUpdateEntity,
} from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearance from '../../../components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../../../components/core/Sidebar/components/Settings/options/HtmlAttrId'
import FontSize from '../../../components/core/Sidebar/components/Settings/styles/FontSize'
import TextAlignStyle from '../../../components/core/Sidebar/components/Settings/styles/TextAlignStyle'

function WebinarSessionLinkSettingsNew({
  entity,
}: {
  entity: WebinarSessionLinkInterface
}) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<WebinarSessionLinkInterface>()

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  return (
    <>
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <Input
        label="link text"
        update={updateProp('text')}
        value={entity.text}
      />
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
      />
      <TextAlignStyle
        textAlign={entity.textAlign || 'left'}
        mobileTextAlign={entity.mobileTextAlign || entity.textAlign || 'left'}
        update={updateProp('textAlign')}
        mobileUpdate={updateProp('mobileTextAlign')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default WebinarSessionLinkSettingsNew
