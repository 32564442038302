import styled from 'styled-components'

const ManagementItemTitleUi = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: -3px;
  word-wrap: break-word;
  max-width: 190px;
`

export default ManagementItemTitleUi
