import React from 'react'
import { LanguageSwitcherInterface } from 'common/types/entities/LanguageSwitcherInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import TextFontUpdater from '../components/Font/TextFontUpdater'
import Input from '../components/Input/Input'
import LanguageCheckList from '../components/LanguageCheckList'
import { useUpdateEntity, useUpdateProp } from '../hooks/useUpdateProps'
import DeviceAppearanceNew from '../options/DeviceAppearance/DeviceAppearanceNew'
import GroupTitle from '../styleComponents/GroupTitle'
import MarginStyle from '../styles/MarginStyle'
import TextFontSize from '../styles/TextFontSize'
import TextLineHeight from '../styles/TextLineHeight'

function NewLanguageSwitcherSettings({
  entity,
}: {
  entity: LanguageSwitcherInterface
}) {
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<LanguageSwitcherInterface>()
  const updateProp = useUpdateProp(entity)

  const handleExcludeLanguages = (excludedLanguages: string[]) => {
    updateEntity({
      ...entity,
      excludedLanguages: excludedLanguages,
    })
  }

  return (
    <React.Fragment>
      <Input
        label="entity_settings.language_switcher.redirect_path"
        value={entity.redirectPath}
        update={updateProp('redirectPath')}
      />
      <GroupTitle>
        entity_settings.language_switcher.available_languages
      </GroupTitle>
      <LanguageCheckList
        update={handleExcludeLanguages}
        excludeLanguagesList={entity.excludedLanguages}
      />
      <GroupTitle>typography</GroupTitle>
      {/* @ts-ignore */}
      <TextFontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        removeEntityStyle={updateProp('fontSize')}
        removeEntityMobileStyle={updateProp('mobileFontSize')}
      />
      <TextLineHeight
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        removeEntityStyle={updateProp('lineHeight')}
        removeEntityMobileStyle={updateProp('mobileLineHeight')}
      />
      <TextFontUpdater<LanguageSwitcherInterface>
        entity={entity}
        update={updateEntity}
      />
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
        label="text color"
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
    </React.Fragment>
  )
}

export default NewLanguageSwitcherSettings
