import styled from 'styled-components'

const SwitcherButtonUi = styled.button`
  background: ${p => (p.active ? '#149efc' : '#bcbcbc')};
  padding: 7px 15px;
  font-size: 13px;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  width: 45%;
`

export default SwitcherButtonUi
