import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { pageTypes, blogPageTypes } from 'common/constants/pageTypes'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { AutomationRuleTriggersEnum } from 'client/enums/AutomationRulesEnum'
import { getPageType } from 'client/reducers/pageReducer'
import { usePage } from 'client/store'
import { useAutomationRules } from 'client/store'
import automationRulesSelectors from 'client/store/automationRules/automationRulesSelectors'
import pageSelectors from 'client/store/page/pageSelectors'
import { automationRuleTriggerEntityProperties } from 'client/types/AutomationRulesActionsInterface'
import { Input } from '../components'
import { Checkbox } from '../components'
import AutomationActionsList from '../components/AutomationRulesSettings/AutomationActionsList'
import AutomationRulesActionsModal from '../components/AutomationRulesSettings/AutomationRulesActionsModal'
import automationRulePageTypes from '../components/AutomationRulesSettings/utils/automationRulesPageTypes'
import TextFont from '../components/Font/TextFont'
import PrimaryButton from '../components/PrimaryButton'
import { DeviceAppearance, Attributes } from '../options'
import { MarginsStyle, PaddingStyle, AlignStyle } from '../styles'
import TextFontSize from '../styles/TextFontSize'
import TextLineHeight from '../styles/TextLineHeight'

const ExplicitConsentSettings = props => {
  const {
    entity,
    updateStyle,
    updateMobileStyle,
    updateOption,
    update,
    removeEntityStyle,
    removeEntityMobileStyle,
    entityWithoutEmulation,
    pageType,
  } = props
  const [isAutomationsModalOpen, setIsAutomationsModalOpen] = useState(false)
  const { t } = useTranslation()

  const { isMobile } = useDeviceModeContext()
  const isTemplate = usePage(pageSelectors.isPageTemplate)

  const editedExplicitConsentActions = useAutomationRules(state =>
    automationRulesSelectors.getEditedAutomationRulesByEntityId(
      state,
      entity.id,
      automationRuleTriggerEntityProperties.checkBoxId,
    ),
  )

  const handleFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            fontFamily: fontProperties.fontFamily,
          },
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.fontFileId
        ) {
          delete updatedEntity.mobileOptions.fontFileId
        }
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            ...fontProperties,
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            fontFamily: fontProperties.fontFamily,
          },
          options: {
            ...updatedEntity.options,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (updatedEntity.options.fontFileId) {
          delete updatedEntity.options.fontFileId
        }
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            ...fontProperties,
          },
        })
      }
    }
  }

  const fontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const fontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const fontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  const isLoginPage = pageType === pageTypes.customLogin

  const automationTriggerType = blogPageTypes.includes(pageType)
    ? AutomationRuleTriggersEnum.blog_page_form_subscribed
    : AutomationRuleTriggersEnum.form_subscribed

  const isAutomationRulesVisible =
    !isTemplate && automationRulePageTypes.includes(pageType)

  return (
    <React.Fragment>
      <Input
        update={updateOption('errorText')}
        value={entity.options.errorText}
        labelText="settings_options.checkbox_message"
        placeholder="need to check"
      />
      {!isLoginPage && (
        <Checkbox
          labelText="entity_settings.explicit_consent.optional"
          helpMessage="entity_settings.explicit_consent.optional_popover"
          value={entity.options.optional}
          update={updateOption('optional')}
        />
      )}
      {isAutomationRulesVisible && [
        editedExplicitConsentActions.length > 0 && (
          <AutomationActionsList actions={editedExplicitConsentActions} />
        ),
        <PrimaryButton
          onClick={() => {
            setIsAutomationsModalOpen(true)
          }}
          key={'automationSettingsButton'}
        >
          {t('entity_settings.automation_rule.edit.add_automation_rule')}
        </PrimaryButton>,
        <AutomationRulesActionsModal
          key="automationSettingsModal"
          opened={isAutomationsModalOpen}
          triggerType={automationTriggerType}
          onClose={() => setIsAutomationsModalOpen(false)}
          actions={editedExplicitConsentActions}
          entityId={entity.id}
          triggerKey={'checkBoxId'}
        />,
      ]}
      <GroupTitle>typography</GroupTitle>
      <TextFontSize
        update={updateStyle('fontSize')}
        mobileUpdate={updateMobileStyle('fontSize')}
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
        removeEntityStyle={removeEntityStyle('fontSize')}
        removeEntityMobileStyle={removeEntityMobileStyle('fontSize')}
      />
      <TextLineHeight
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
        update={updateStyle('lineHeight', 'px')}
        mobileUpdate={updateMobileStyle('lineHeight', 'px')}
        lineHeight={entityWithoutEmulation.styles.lineHeight}
        mobileLineHeight={entityWithoutEmulation.mobileStyles.lineHeight}
        removeEntityStyle={removeEntityStyle('lineHeight')}
        removeEntityMobileStyle={removeEntityMobileStyle('lineHeight')}
      />
      <TextFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <AlignStyle
        align={entity.styles.textAlign}
        update={updateStyle('textAlign')}
        isFlex={false}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateStyle('color')}
        mobileUpdate={updateMobileStyle('color')}
        color={entity.styles.color}
        mobileColor={entity.mobileStyles.color}
        label="text color"
      />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

ExplicitConsentSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  entityWithoutEmulation: types.entity.isRequired,
  removeEntityMobileStyle: PropTypes.func.isRequired,
  removeEntityStyle: PropTypes.func.isRequired,
}

const mapStateToProps = ({ page: { present: presentPage } }) => ({
  pageType: getPageType(presentPage),
})

export default connect(mapStateToProps, null)(ExplicitConsentSettings)
