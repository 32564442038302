import styled from 'styled-components'

interface AutomationRuleFlexContainerProps {
  flexDirection?: string
  gap?: number
  justifyContent?: string
}

const AutomationRuleFlexContainerUi = styled.div<AutomationRuleFlexContainerProps>`
  display: flex;
  flex-direction: ${p => (p.flexDirection ? p.flexDirection : 'column')};
  gap: ${p => p.gap}px;
  justify-content: ${p => p.justifyContent};
`

export default AutomationRuleFlexContainerUi
