import styled from 'styled-components'

const FullMarginTypeContainerUi = styled.div`
  display: grid;
  align-items: center;
  grid-template-rows: 5px 15px 5px;
  grid-template-columns: 15px 15px;
  grid-template-areas:
    'topSide topSide'
    'leftSide rightSide'
    'bottomSide bottomSide';
  cursor: pointer;
`

export default FullMarginTypeContainerUi
