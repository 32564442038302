import React, { useState, memo } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'
import ButtonLoader from 'common/components/core/ButtonLoader'
import { AccessDenied } from 'common/errors/AccessDenied'
import { BadRequest } from 'common/errors/BadRequest'
import { GatewayError } from 'common/errors/GatewayError'
import { NetworkError } from 'common/errors/NetworkError'
import { NotFound } from 'common/errors/NotFound'
import { PageValidationError } from 'common/errors/PageValidationError'
import { PageValidationErrorNew } from 'common/errors/PageValidationErrorNew'
import { ServiceUnavailable } from 'common/errors/ServiceUnavailable'
import { useActions } from 'common/hooks/useActions'
import * as pageActions from 'client/actions/pageActions'
import { savePageAutomationRulesData } from 'client/api/pageApi'
import BlueButton from 'client/components/core/BlueButton'
import { useUnsavedData } from 'client/hooks/useUnsavedData'
import { pageSelectors, useAutomationRules, usePage } from 'client/store'
import { fetchPageAutomationRules } from 'client/store/automationRules/automationRulesActions'
import automationRulesSelectors from 'client/store/automationRules/automationRulesSelectors'
import { getRuleApiData } from 'client/utils/getRuleApiData'

function SaveButton() {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const isTemplate = usePage(pageSelectors.isTemplate)
  const savePage = useActions(pageActions.savePage)
  const automationRules = useAutomationRules(
    automationRulesSelectors.getEditedPageAutomationRules,
  )
  const pageId = usePage(pageSelectors.getPageId)

  const { deleteDbRecord, PageUnsavedModal } = useUnsavedData({})

  async function handleSave() {
    setIsLoading(true)
    try {
      await savePage(isTemplate)
      dispatch(pageActions.savePageSuccess())
      await deleteDbRecord()
      if (!isTemplate) {
        try {
          const response = await savePageAutomationRulesData(
            pageId,
            getRuleApiData(automationRules),
          )
          dispatch(fetchPageAutomationRules(response.data.rules))
        } catch (e) {
          if (e instanceof BadRequest) {
            const commonErrors = e.response.data.errors?.common
            if (commonErrors.length !== 0) {
              toast.error(commonErrors.join(''))
            }
          } else {
            window.Rollbar.error('Save page automation rules error', e)
          }
        }
      }
    } catch (e) {
      if (e instanceof PageValidationError) {
        dispatch(pageActions.pageValidationFail(e.messages))
      } else if (e instanceof PageValidationErrorNew) {
        toast.error(e.message)
      } else if (e instanceof NotFound) {
        dispatch(pageActions.savePageFail('core.errors.page_not_found'))
      } else if (e instanceof AccessDenied) {
        // do nothing
      } else if (e instanceof NetworkError) {
        // just show warning about data loss
        dispatch(pageActions.savePageFail('core.errors.save'))
      } else if (e instanceof GatewayError) {
        dispatch(pageActions.savePageFail('core.errors.save'))
        window.Rollbar.error(e)
      } else if (e instanceof ServiceUnavailable) {
        dispatch(pageActions.savePageFail('core.errors.save'))
        window.Rollbar.error(e)
      } else if (e instanceof BadRequest) {
        dispatch(pageActions.pageValidationFail(e.response.data))
      } else {
        dispatch(pageActions.savePageFail('core.errors.save'))
      }
    }
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <BlueButton
        disabled={!i18n.language || isLoading}
        onClick={handleSave}
        title={t('core.header.save_changes')}
        data-test-element="button-save-changes"
      >
        {isLoading && <ButtonLoader dataTestElement="save-spinner" />}
        {i18n.language ? (
          t('core.header.save_changes')
        ) : (
          <Skeleton width={50} />
        )}
      </BlueButton>
      {PageUnsavedModal}
    </React.Fragment>
  )
}

export default memo(SaveButton)
