import PropTypes from 'prop-types'
import React, { memo } from 'react'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

const topRight = 'topRight'
const topLeft = 'topLeft'
const bottomLeft = 'bottomLeft'
const bottomRight = 'bottomRight'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function CornerStyle(props) {
  const { isMobile } = useDeviceModeContext()
  const getBorderRadius = type =>
    parseInt(
      isMobile ? props[`mobile${capitalizeFirstLetter(type)}`] : props[type],
      0,
    )

  const update = style => value =>
    isMobile
      ? props.mobileUpdate(style, 'px')(value)
      : props.update(style, 'px')(value)

  return (
    <div>
      <GroupTitle afterChildren={isMobile && <MobileIconUi />}>
        settings_styles.corner_size.label
      </GroupTitle>
      <Range
        labelText="settings_styles.corner_size.top_left"
        labelIcon={isMobile && <MobileIconUi />}
        value={getBorderRadius(topLeft)}
        update={update('borderTopLeftRadius')}
      />
      <Range
        labelText="settings_styles.corner_size.top_right"
        labelIcon={isMobile && <MobileIconUi />}
        value={getBorderRadius(topRight)}
        update={update('borderTopRightRadius')}
      />
      <Range
        labelText="settings_styles.corner_size.bottom_left"
        labelIcon={isMobile && <MobileIconUi />}
        value={getBorderRadius(bottomLeft)}
        update={update('borderBottomLeftRadius')}
      />
      <Range
        labelText="settings_styles.corner_size.bottom_right"
        labelIcon={isMobile && <MobileIconUi />}
        value={getBorderRadius(bottomRight)}
        update={update('borderBottomRightRadius')}
      />
    </div>
  )
}

CornerStyle.propTypes = {
  topLeft: PropTypes.string | PropTypes.number,
  topRight: PropTypes.string | PropTypes.number,
  bottomLeft: PropTypes.string | PropTypes.number,
  bottomRight: PropTypes.string | PropTypes.number,
  mobileTopLeft: PropTypes.string | PropTypes.number,
  mobileTopRight: PropTypes.string | PropTypes.number,
  mobileBottomLeft: PropTypes.string | PropTypes.number,
  mobileBottomRight: PropTypes.string | PropTypes.number,
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

CornerStyle.defaultProps = {
  topLeft: '0',
  topRight: '0',
  bottomLeft: '0',
  bottomRight: '0',
  mobileTopLeft: '0',
  mobileTopRight: '0',
  mobileBottomLeft: '0',
  mobileBottomRight: '0',
}

export default memo(CornerStyle)
