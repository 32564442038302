import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import { Input } from '../components'
import { DeviceAppearance, Attributes } from '../options'
import { MarginsStyle, FontSize, CornerStyle, BorderStyle } from '../styles'

class CouponSettings extends PureComponent {
  updateFieldStyles = (style, unitOfMeasure) => value => {
    this.props.updateOptions({
      ...this.props.entity.options,
      fieldStyles: {
        ...this.props.entity.options.fieldStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  updateFieldMobileStyles = (style, unitOfMeasure) => value => {
    this.props.updateMobileOptions({
      ...this.props.entity.mobileOptions,
      fieldStyles: {
        ...this.props.entity.mobileOptions.fieldStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  updateFieldOptions = option => value => {
    this.props.updateOptions({
      ...this.props.entity.options,
      fieldOptions: {
        ...this.props.entity.options.fieldOptions,
        [option]: value,
      },
    })
  }

  updateFieldMobileOptions = option => value => {
    this.props.updateMobileOptions({
      ...this.props.entity.mobileOptions,
      fieldOptions: {
        ...this.props.entity.mobileOptions.fieldOptions,
        [option]: value,
      },
    })
  }

  updateButtonStyles = (style, unitOfMeasure) => value => {
    this.props.updateOptions({
      ...this.props.entity.options,
      buttonStyles: {
        ...this.props.entity.options.buttonStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  updateButtonMobileStyles = (style, unitOfMeasure) => value => {
    this.props.updateMobileOptions({
      ...this.props.entity.mobileOptions,
      buttonStyles: {
        ...this.props.entity.mobileOptions.buttonStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  updateButtonOptions = option => value => {
    this.props.updateOptions({
      ...this.props.entity.options,
      buttonOptions: {
        ...this.props.entity.options.buttonOptions,
        [option]: value,
      },
    })
  }

  updateButtonMobileOptions = option => value => {
    this.props.updateMobileOptions({
      ...this.props.entity.mobileOptions,
      buttonOptions: {
        ...this.props.entity.mobileOptions.buttonOptions,
        [option]: value,
      },
    })
  }

  render() {
    const {
      entity,
      updateStyle,
      updateOption,
      updateMobileStyle,
      update,
      toggleOverlay,
      overlayVisible,
    } = this.props
    return (
      <React.Fragment>
        <GroupTitle>entity_settings.coupon.field.group_title</GroupTitle>
        <Input
          labelText="entity_settings.coupon.field.placeholder"
          value={entity.options.placeholder}
          update={updateOption('placeholder')}
        />
        <FontSize
          update={this.updateFieldStyles('fontSize', 'px')}
          mobileUpdate={this.updateFieldMobileStyles('fontSize', 'px')}
          fontSize={entity.options.fieldStyles.fontSize}
          mobileFontSize={entity.mobileOptions.fieldStyles.fontSize}
        />
        <ColorPicker
          label="entity_settings.coupon.field.text_color"
          update={this.updateFieldStyles('color')}
          mobileUpdate={this.updateFieldMobileStyles('color')}
          color={entity.options.fieldStyles.color}
          mobileColor={entity.mobileOptions.fieldStyles.color}
        />
        <CornerStyle
          topLeft={entity.options.fieldStyles.borderTopLeftRadius}
          topRight={entity.options.fieldStyles.borderTopRightRadius}
          bottomRight={entity.options.fieldStyles.borderBottomRightRadius}
          bottomLeft={entity.options.fieldStyles.borderBottomLeftRadius}
          mobileTopLeft={entity.mobileOptions.fieldStyles.borderTopLeftRadius}
          mobileTopRight={entity.mobileOptions.fieldStyles.borderTopRightRadius}
          mobileBottomLeft={
            entity.mobileOptions.fieldStyles.borderBottomLeftRadius
          }
          mobileBottomRight={
            entity.mobileOptions.fieldStyles.borderBottomRightRadius
          }
          update={this.updateFieldStyles}
          mobileUpdate={this.updateFieldMobileStyles}
        />
        <BorderStyle
          updateOption={this.updateFieldOptions}
          updateMobileOption={this.updateFieldMobileOptions}
          updateStyle={this.updateFieldStyles}
          updateMobileStyle={this.updateFieldMobileStyles}
          type={entity.options.fieldOptions.borderType}
          style={entity.options.fieldStyles.borderStyle}
          width={entity.options.fieldStyles.borderWidth}
          color={entity.options.fieldStyles.borderColor}
          mobileType={entity.mobileOptions.fieldOptions.borderType}
          mobileStyle={entity.mobileOptions.fieldStyles.borderStyle}
          mobileWidth={entity.mobileOptions.fieldStyles.borderWidth}
          mobileColor={entity.mobileOptions.fieldStyles.borderColor}
          toggleOverlay={toggleOverlay}
          overlayVisible={overlayVisible}
        />
        <GroupTitle>entity_settings.coupon.button.group_title</GroupTitle>
        <Input
          labelText="entity_settings.coupon.button.text"
          value={entity.options.buttonText}
          update={updateOption('buttonText')}
        />
        <FontSize
          update={this.updateButtonStyles('fontSize', 'px')}
          mobileUpdate={this.updateButtonMobileStyles('fontSize', 'px')}
          fontSize={entity.options.buttonStyles.fontSize}
          mobileFontSize={entity.mobileOptions.buttonStyles.fontSize}
        />
        <ColorPicker
          label="entity_settings.coupon.button.text_color"
          update={this.updateButtonStyles('color')}
          mobileUpdate={this.updateButtonMobileStyles('color')}
          color={entity.options.buttonStyles.color}
          mobileColor={entity.mobileOptions.buttonStyles.color}
        />
        <ColorPicker
          label="entity_settings.coupon.button.bg_color"
          update={this.updateButtonStyles('backgroundColor')}
          mobileUpdate={this.updateButtonMobileStyles('backgroundColor')}
          color={entity.options.buttonStyles.backgroundColor}
          mobileColor={entity.mobileOptions.buttonStyles.backgroundColor}
        />
        <CornerStyle
          topLeft={entity.options.buttonStyles.borderTopLeftRadius}
          topRight={entity.options.buttonStyles.borderTopRightRadius}
          bottomRight={entity.options.buttonStyles.borderBottomRightRadius}
          bottomLeft={entity.options.buttonStyles.borderBottomLeftRadius}
          mobileTopLeft={entity.mobileOptions.buttonStyles.borderTopLeftRadius}
          mobileTopRight={
            entity.mobileOptions.buttonStyles.borderTopRightRadius
          }
          mobileBottomLeft={
            entity.mobileOptions.buttonStyles.borderBottomLeftRadius
          }
          mobileBottomRight={
            entity.mobileOptions.buttonStyles.borderBottomRightRadius
          }
          update={this.updateButtonStyles}
          mobileUpdate={this.updateButtonMobileStyles}
        />
        <BorderStyle
          updateOption={this.updateButtonOptions}
          updateMobileOption={this.updateButtonMobileOptions}
          updateStyle={this.updateButtonStyles}
          updateMobileStyle={this.updateButtonMobileStyles}
          type={entity.options.buttonOptions.borderType}
          style={entity.options.buttonStyles.borderStyle}
          width={entity.options.buttonStyles.borderWidth}
          color={entity.options.buttonStyles.borderColor}
          mobileType={entity.mobileOptions.buttonOptions.borderType}
          mobileStyle={entity.mobileOptions.buttonStyles.borderStyle}
          mobileWidth={entity.mobileOptions.buttonStyles.borderWidth}
          mobileColor={entity.mobileOptions.buttonStyles.borderColor}
          toggleOverlay={toggleOverlay}
          overlayVisible={overlayVisible}
        />
        <MarginsStyle
          marginTop={entity.styles.marginTop}
          marginRight={entity.styles.marginRight}
          marginBottom={entity.styles.marginBottom}
          marginLeft={entity.styles.marginLeft}
          mobileMarginTop={entity.mobileStyles.marginTop}
          mobileMarginRight={entity.mobileStyles.marginRight}
          mobileMarginBottom={entity.mobileStyles.marginBottom}
          mobileMarginLeft={entity.mobileStyles.marginLeft}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <DeviceAppearance update={update} entity={entity} />
        <Attributes update={updateOption} entity={entity} />
      </React.Fragment>
    )
  }
}

CouponSettings.propTypes = {
  entity: types.entity.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateMobileOptions: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default CouponSettings
