import { PaddingType } from 'common/types/styleTypes'

export function findLargestPadding(padding: PaddingType): number {
  return Math.max(...Object.values(padding))
}

export function isSinglePadding(padding: Partial<PaddingType>) {
  if (
    Number.isInteger(padding.paddingTop) &&
    Number.isInteger(padding.paddingRight) &&
    Number.isInteger(padding.paddingBottom) &&
    Number.isInteger(padding.paddingLeft)
  ) {
    return Object.values(padding).every(
      (value, index, array) => value === array[0],
    )
  }
  return false
}

export const paddingStylesTranslations: Record<keyof PaddingType, string> = {
  paddingTop: 'settings_styles.padding.top',
  paddingRight: 'settings_styles.padding.right',
  paddingBottom: 'settings_styles.padding.bottom',
  paddingLeft: 'settings_styles.padding.left',
}
