import React from 'react'

function EmptyListIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="176"
      height="145"
      viewBox="0 0 176 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.9656 130.543C121.547 130.543 149.582 102.507 149.582 67.8025C149.582 33.0979 121.423 5.0625 86.9656 5.0625C52.3845 5.0625 24.3491 33.0979 24.3491 67.8025C24.3491 102.507 52.3845 130.543 86.9656 130.543Z"
        fill="#E5EDF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.917 6.91622C154.826 6.91622 156.375 5.36007 156.375 3.45811C156.375 1.5438 154.826 0 152.917 0C151.006 0 149.458 1.5438 149.458 3.45811C149.458 5.36007 151.006 6.91622 152.917 6.91622Z"
        fill="#E7EBF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6832 26.5529C29.5926 26.5529 31.1413 24.9968 31.1413 23.0948C31.1413 21.1805 29.5926 19.6367 27.6832 19.6367C25.7726 19.6367 24.2251 21.1805 24.2251 23.0948C24.2251 24.9968 25.7726 26.5529 27.6832 26.5529Z"
        fill="#E7EBF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56429 93.2467C10.1113 93.2467 12.9865 90.3691 12.9865 86.8245C12.9865 83.28 10.1113 80.4023 6.56429 80.4023C3.01726 80.4023 0.14209 83.28 0.14209 86.8245C0.14209 90.3691 3.01726 93.2467 6.56429 93.2467Z"
        fill="#E7EBF6"
      />
      <g filter="url(#filter0_d_15400_1793)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.631 34.8402V107.51C146.631 109.647 144.854 111.425 142.722 111.425H32.301C30.1681 111.511 28.3921 109.733 28.3921 107.596V34.8402C28.3921 32.6295 30.1681 30.9375 32.301 30.9375H142.722C144.943 30.9375 146.631 32.716 146.631 34.8402Z"
          fill="url(#paint0_linear_15400_1793)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.631 34.8402V40.4473H28.3921V34.8402C28.3921 32.6295 30.1681 30.9375 32.301 30.9375H142.722C144.943 30.9375 146.631 32.716 146.631 34.8402Z"
        fill="url(#paint1_linear_15400_1793)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.2098 68.6062C73.0253 68.6062 74.4975 67.1365 74.4975 65.321C74.4975 63.5055 73.0253 62.0234 71.2098 62.0234C69.3955 62.0234 67.9233 63.5055 67.9233 65.321C67.9233 67.1365 69.3955 68.6062 71.2098 68.6062Z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.901 68.6062C105.717 68.6062 107.188 67.1365 107.188 65.321C107.188 63.5055 105.717 62.0234 103.901 62.0234C102.086 62.0234 100.615 63.5055 100.615 65.321C100.615 67.1365 102.086 68.6062 103.901 68.6062Z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.8858 90.1946H82.5807C80.9825 90.1946 79.6499 88.8607 79.6499 87.2552C79.6499 85.662 80.9825 84.3281 82.5807 84.3281H92.7969C94.3963 84.3281 95.7289 85.662 95.7289 87.2552C95.8178 88.8607 94.4852 90.1946 92.8858 90.1946Z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.6338 37.3357C34.3686 37.3357 34.9651 36.7429 34.9651 36.0018C34.9651 35.2608 34.3686 34.668 33.6338 34.668C32.8977 34.668 32.3011 35.2608 32.3011 36.0018C32.3011 36.7429 32.8977 37.3357 33.6338 37.3357Z"
        fill="#F9FAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8087 37.3357C38.5448 37.3357 39.1413 36.7429 39.1413 36.0018C39.1413 35.2608 38.5448 34.668 37.8087 34.668C37.0726 34.668 36.4761 35.2608 36.4761 36.0018C36.4761 36.7429 37.0726 37.3357 37.8087 37.3357Z"
        fill="#F9FAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0724 37.3356C42.8084 37.3356 43.405 36.7428 43.405 36.0018C43.405 35.2608 42.8084 34.668 42.0724 34.668C41.3363 34.668 40.7397 35.2608 40.7397 36.0018C40.7397 36.7428 41.3363 37.3356 42.0724 37.3356Z"
        fill="#F9FAFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M141.834 21.5262C140.945 21.5262 140.235 20.8099 140.235 19.9206V2.06195C140.235 1.17272 140.945 0.46875 141.834 0.46875C142.722 0.46875 143.432 1.17272 143.432 2.06195V19.9206C143.432 20.8099 142.722 21.5262 141.834 21.5262Z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.804 35.1987C154.804 34.3094 155.516 33.6055 156.404 33.6055H174.26C175.148 33.6055 175.858 34.3094 175.858 35.1987C175.858 36.0879 175.148 36.8042 174.26 36.8042H156.404C155.516 36.8042 154.804 36.0879 154.804 35.1987Z"
        fill="#989FB0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.382 23.6495C152.761 23.032 152.761 21.9698 153.382 21.34L166.086 8.73022C166.708 8.10035 167.774 8.10035 168.396 8.73022C169.018 9.34773 169.018 10.4099 168.396 11.0397L155.781 23.6495C155.071 24.2794 154.005 24.2794 153.382 23.6495Z"
        fill="#989FB0"
      />
      <defs>
        <filter
          id="filter0_d_15400_1793"
          x="6.39209"
          y="19.9375"
          width="162.239"
          height="124.491"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.396078 0 0 0 0 0.478431 0 0 0 0 0.576471 0 0 0 0.270588 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15400_1793"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_15400_1793"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_15400_1793"
          x1="87.4742"
          y1="29.073"
          x2="87.4742"
          y2="112.294"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15400_1793"
          x1="29.5288"
          y1="35.6231"
          x2="147.336"
          y2="35.6231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="1" stopColor="#46BAFF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default EmptyListIcon
