import React from 'react'
import MenuInterface from 'common/components/entities/Menu/types/MenuInterface'
import { Checkbox } from 'client/components/core/Sidebar/components/Settings/components'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

type BurgerProps = {
  update: <K extends keyof MenuInterface>(
    prop: K,
  ) => (value: MenuInterface[K]) => void
  entity: MenuInterface
}
const MenuBurger = ({ update, entity }: BurgerProps) => {
  const { isMobile } = useDeviceModeContext()
  return isMobile ? (
    <>
      <Checkbox
        labelText="entity_settings.menu.item.show_burger_on_mobile"
        update={update('burger')}
        value={entity.burger}
      />
      {entity.burger && (
        <ColorPicker
          update={update('burgerIconColor')}
          color={entity.burgerIconColor}
          label="entity_settings.menu.item.burger_color"
          singleColorMode
        />
      )}
    </>
  ) : (
    <></>
  )
}

export default MenuBurger
