import React, { useEffect, useRef } from 'react'
import useDragging from './hooks/useDragging'
import AngleIconUi from './ui/AngleIconUi'
import AnglePickerContainer from './ui/AnglePickerContainerUi'
import AnglePickerSpanUi from './ui/AnglePickerSpanUi'
import { centerOffset, clampAngle, snapAngle, pointDegrees } from './utils'

type AnglePickerProps = {
  angle: number
  setAngle: (value: number) => void
  handleChangeAngle: (angle: number) => void
  snap?: number
}
const AnglePicker = ({
  angle,
  setAngle,
  handleChangeAngle,
  snap = 5,
}: AnglePickerProps) => {
  const pickerRef = useRef(null)

  const onAngleChange = (
    { clientX, clientY }: { clientX: number; clientY: number },
    useSnap = false,
  ) => {
    const center = centerOffset(pickerRef.current)
    const degrees = pointDegrees(clientX, clientY, center)

    const clamped = clampAngle(degrees)
    const angle = useSnap ? snapAngle(clamped, snap) : clamped

    setAngle(angle)
    return angle
  }

  const drag = useDragging({
    onDragStart: e => onAngleChange(e, true),
    onDrag: onAngleChange,
    onDragEnd: (coordinates, angle) => {
      if (!angle) return
      const snappedAngle = snapAngle(angle, snap)
      handleChangeAngle(angle)
      setAngle(snappedAngle)
    },
  })
  const handleClick = (e: React.MouseEvent) => {
    const newAngle = onAngleChange({ clientX: e.clientX, clientY: e.clientY })
    handleChangeAngle(newAngle)
  }
  return (
    <AnglePickerContainer
      ref={pickerRef}
      onMouseDown={drag}
      onClick={e => handleClick(e)}
    >
      <AnglePickerSpanUi angle={angle}>
        <AngleIconUi />
      </AnglePickerSpanUi>
    </AnglePickerContainer>
  )
}

export default AnglePicker
