import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Translation } from 'react-i18next'
import { Dialog, DialogHeader, DialogBody } from 'client/components/core/Dialog'
import { getValidationErrors } from 'client/reducers/pageReducer'
import { resetPageValidationError } from 'client/actions/pageActions'
import WarningIcon from 'client/components/core/ErrorsDialog/components/WarningIconUi'

const ValidationErrorsDialog = ({ errors, ...restProps }) => (
  <Dialog
    show={errors.length > 0}
    close={restProps.resetPageValidationError}
    small
  >
    <DialogHeader close={restProps.resetPageValidationError} />
    <DialogBody small>
      <Translation ns={['client']}>
        {(t, { i18n }) =>
          errors.map(error => (
            <h3 key={error}>
              <WarningIcon /> {i18n.exists(error) ? t(error) : error}
            </h3>
          ))
        }
      </Translation>
    </DialogBody>
  </Dialog>
)

ValidationErrorsDialog.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  resetPageValidationError: PropTypes.func.isRequired,
}

ValidationErrorsDialog.defaultProps = {
  errors: [],
}

const mapStateToProps = ({ page: { present: presentPage } }) => ({
  errors: getValidationErrors(presentPage),
})

const mapDispatchToProps = { resetPageValidationError }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ValidationErrorsDialog)
