import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IconPicker from '../IconPicker'

const IconRemoverUi = styled.i`
  font-size: 30px;
  transition: all 0.2s linear;
  text-shadow: 1px 1px 2px #ccc;
  opacity: 0.7;
  margin-top: 20px;
  &:hover {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.05);
  }
`

const IconPickerUi = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`

type ButtonIconPickerProps = {
  updateBefore: (option: string) => void
  updateAfter: (option: string) => void
  valueBefore?: string
  valueAfter?: string
  iconList?: string[]
}

function ButtonIconPicker({
  updateBefore,
  updateAfter,
  valueBefore,
  valueAfter,
  iconList = [],
}: ButtonIconPickerProps) {
  const { t } = useTranslation()

  return (
    <>
      <IconPickerUi>
        <IconPicker
          labelText="entity_options.icon_picker.title_before"
          iconList={iconList}
          value={valueBefore}
          update={updateBefore}
          searchPlaceholder="settings.components.icon_picker.placeholder"
        />

        <IconRemoverUi
          title={t('entity_options.icon_picker.remove_icon.title')}
          className="fa fa-ban"
          onClick={() => updateBefore('')}
        />
      </IconPickerUi>
      <IconPickerUi>
        <IconPicker
          labelText="entity_options.icon_picker.title_after"
          iconList={iconList}
          value={valueAfter}
          update={updateAfter}
          searchPlaceholder="settings.components.icon_picker.placeholder"
        />
        <IconRemoverUi
          title={t('entity_options.icon_picker.remove_icon.title')}
          className="fa fa-ban"
          onClick={() => updateAfter('')}
        />
      </IconPickerUi>
    </>
  )
}
export default ButtonIconPicker
