import React from 'react'
import CommonBlogPostTitle from 'common/components/entities/BlogPostTitle/BlogPostTItleNew'
import { BlogPostTitleInterface } from 'common/types/entities/BlogPostTitleInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { useBlogPost } from 'client/store'
import { blogPostSelectors } from 'client/store/blogPost/blogPostReducer'
import { EntityProps } from '../../../types'

function BlogPostTitleNew({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogPostTitleInterface>) {
  const blogPostTitle = useBlogPost(blogPostSelectors.getBlogPostTitle)

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonBlogPostTitle
        title={blogPostTitle}
        attrId={entity.htmlAttrId}
        fontSize={entity.fontSize}
        fontFamily={entity.fontFamily}
        fontStyle={entity.fontStyle}
        fontWeight={entity.fontWeight}
        mobileFontSize={entity.mobileFontSize}
        mobileFontFamily={entity.mobileFontFamily}
        mobileFontStyle={entity.mobileFontStyle}
        mobileFontWeight={entity.mobileFontWeight}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        color={entity.color}
        mobileColor={entity.mobileColor}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
      />
    </BaseEntityNew>
  )
}

export default BlogPostTitleNew
