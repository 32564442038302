import styled from 'styled-components'

const DialogTitleUi = styled.p`
  color: #414355;
  font-size: 20px;
  margin-bottom: 30px;
  margin-top: 10px;
`

export default DialogTitleUi
