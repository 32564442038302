import {
  ADD_FILE,
  ADD_FILES,
  AddFile,
  AddFiles,
  FETCH_PAGE_FILES,
  FETCH_SEARCH_USER_FILES,
  FETCH_USER_FILES,
  FetchPageFiles,
  FetchSearchUserFiles,
  FetchUserFiles,
  REMOVE_FILE,
  RemoveFile,
  SET_FILES,
  SetFiles,
} from './filesActionTypes'
import { DataFile, Files, FilesState } from './filesReducer'

export function setFiles(files: FilesState): SetFiles {
  return {
    type: SET_FILES,
    payload: files,
  }
}

export function fetchPageFiles(files: Files): FetchPageFiles {
  return {
    type: FETCH_PAGE_FILES,
    payload: files,
  }
}

export function fetchUserFiles(files: Files): FetchUserFiles {
  return {
    type: FETCH_USER_FILES,
    payload: files,
  }
}

export function removeFile(fileId: number): RemoveFile {
  return {
    type: REMOVE_FILE,
    payload: fileId,
  }
}

export function addFile(file: DataFile): AddFile {
  return {
    type: ADD_FILE,
    payload: file,
  }
}

export function addFiles(files: Files): AddFiles {
  return {
    type: ADD_FILES,
    payload: files,
  }
}

export function fetchSearchUserFiles(files: Files): FetchSearchUserFiles {
  return {
    type: FETCH_SEARCH_USER_FILES,
    payload: files,
  }
}
