import { gradientPaletteInterface } from '.'

export const mapPaletteToStops = ({
  palette,
  width,
}: {
  palette: gradientPaletteInterface[]
  width: number
}) =>
  palette.map(color => ({
    ...color,
    id: color.id,
    offset: width * color.offset - 5,
  }))

export const centerOffset = (el: HTMLDivElement | null) => {
  if (!el) return { y: 0, x: 0 }

  const { top, left, height, width } = el.getBoundingClientRect()

  return {
    y: top + height / 2,
    x: left + width / 2,
  }
}

export const clampAngle = (angle: number) => {
  if (angle < 0) {
    return 360 + angle
  }

  if (angle > 360) {
    return angle - 360
  }

  return angle
}

export const snapAngle = (angle: number, snap = 0) => {
  const module = angle % snap

  if (module === 0) {
    return angle
  }

  const snappingAddition = module > snap / 2 ? snap - module : -1 * module
  return angle + snappingAddition
}

export const pointDegrees = (
  pointX: number,
  pointY: number,
  center: {
    y: number
    x: number
  },
) => {
  const y = pointY - center.y
  const x = pointX - center.x

  const radians = Math.atan2(y, x)
  return Math.round(radians * (180 / Math.PI)) + 90
}

const gradientRegex = /gradient/

export const checkIsBackgroundColorGradient = (colorString: string) => {
  const isGradient = gradientRegex.exec(colorString)
  if (isGradient === null) {
    return false
  } else return true
}

export const getFirstGradientColor = (color: string) => {
  return `linear-gradient(90deg, ${color} 0%, rgba(255, 255, 255, 1) 100%)`
}

const degRegex = /\d{1,3}deg/g

export const getAngleFromGradientPalette = (angle: number, color: string) => {
  const newColor = color.replace(degRegex, `${angle}deg`)
  return newColor
}
