import {
  AutomationRuleActionsDescriptionEnum,
  AutomationRuleActionsEnum,
  AutomationRuleActionsTitleEnum,
} from 'client/enums/AutomationRulesEnum'
import {
  AccessTypeEnum,
  ActionEditType,
} from 'client/types/AutomationRulesActionsEditInterface'
import { getActionIcon } from './get-rule-icon'

interface ActionItem {
  slug: AutomationRuleActionsEnum
  title: string
  description: string
  icon: JSX.Element
}

export const actionItems: ActionItem[] = Object.keys(
  AutomationRuleActionsEnum,
).map(el => {
  const key = el as keyof typeof AutomationRuleActionsEnum
  return {
    slug: AutomationRuleActionsEnum[key],
    title: AutomationRuleActionsTitleEnum[key],
    description: AutomationRuleActionsDescriptionEnum[key],
    icon: getActionIcon(AutomationRuleActionsEnum[key]),
  }
})

export const defaultActionValues: Record<
  AutomationRuleActionsEnum,
  ActionEditType
> = {
  [AutomationRuleActionsEnum.send_email]: {
    type: AutomationRuleActionsEnum.send_email,
    systemeEmail: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.add_tag]: {
    type: AutomationRuleActionsEnum.add_tag,
    tag: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.remove_tag]: {
    type: AutomationRuleActionsEnum.remove_tag,
    tag: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.send_webhook]: {
    type: AutomationRuleActionsEnum.send_webhook,
    webhookUrl: '',
    id: 0,
  },
  [AutomationRuleActionsEnum.enroll_in_course]: {
    type: AutomationRuleActionsEnum.enroll_in_course,
    courseAccessType: AccessTypeEnum.fullAccess,
    course: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.revoke_access_to_course]: {
    type: AutomationRuleActionsEnum.revoke_access_to_course,
    course: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.revoke_access_to_course_bundle]: {
    type: AutomationRuleActionsEnum.revoke_access_to_course_bundle,
    courseBundle: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.enroll_in_course_bundle]: {
    type: AutomationRuleActionsEnum.enroll_in_course_bundle,
    enrollmentAccessType: AccessTypeEnum.fullAccess,
    courseBundle: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.subscribe_to_campaign]: {
    type: AutomationRuleActionsEnum.subscribe_to_campaign,
    campaign: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.unsubscribe_from_campaign]: {
    type: AutomationRuleActionsEnum.unsubscribe_from_campaign,
    campaign: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.send_email_to_specific_address]: {
    type: AutomationRuleActionsEnum.send_email_to_specific_address,
    emailMessageWithRecipient: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.grant_access_to_community]: {
    type: AutomationRuleActionsEnum.grant_access_to_community,
    community: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.revoke_access_to_community]: {
    type: AutomationRuleActionsEnum.revoke_access_to_community,
    community: { id: undefined },
    id: 0,
  },
  [AutomationRuleActionsEnum.create_user]: {
    type: AutomationRuleActionsEnum.create_user,
    locale: undefined,
    id: 0,
  },
}

export const getDefaultActionValue = (
  slug: AutomationRuleActionsEnum,
  maxActionId: React.MutableRefObject<number>,
) => {
  const defaultAction = { ...defaultActionValues[slug] }
  defaultAction.id = ++maxActionId.current
  return defaultAction
}

export const getShouldFetchActionsObj = (withTrue = false) =>
  Object.keys(AutomationRuleActionsEnum).reduce((obj, el) => {
    return { ...obj, [el]: withTrue }
  }, {} as Record<AutomationRuleActionsEnum, boolean>)
