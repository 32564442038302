import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import IconPicker from '../IconPicker'

const IconRemoverUi = styled.i`
  font-size: 30px;
  transition: all 0.2s linear;
  text-shadow: 1px 1px 2px #ccc;
  opacity: 0.7;
  margin-top: 20px;
  &:hover {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.05);
  }
`

const IconPickerUi = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

type IconPickerWithRemoveButtonProps = {
  update: (option: string) => void
  value?: string
  label?: string
  iconList?: string[]
}

function IconPickerWithRemoveButton({
  update,
  value,
  label = 'entity_options.icon_picker.title',
  iconList = [],
}: IconPickerWithRemoveButtonProps) {
  const { t } = useTranslation()

  return (
    <IconPickerUi>
      <IconPicker
        labelText={label}
        iconList={iconList}
        value={value}
        update={update}
        searchPlaceholder="settings.components.icon_picker.placeholder"
      />
      <IconRemoverUi
        title={t('entity_options.icon_picker.remove_icon.title')}
        className="fa fa-ban"
        onClick={() => update('')}
      />
    </IconPickerUi>
  )
}
export default IconPickerWithRemoveButton
