import styled, { css } from 'styled-components'

const FullAndSeparateSettingsInputUi = styled.input`
  border: none;
  width: 40px;
  height: 23px;
  font-size: 13px;
  font-weight: bold;
  color: rgba(92, 104, 126, 1);
  float: right;
  text-align: center;
  border-bottom: 1px solid rgba(92, 104, 126, 1);
  margin-right: 5px;
  margin-left: 10px;
  align-self: end;
  outline: none;
  &:focus {
    border-bottom: 1px solid rgba(20, 158, 252, 1);
  }
  &::-webkit-inner-spin-button ${css`
     {
      appearance: none;
    }
  `}
  &::-webkit-textfield-decoration-container ${css`
     {
      text-align: center;
      transition: all 0.25s ease;
    }
  `}
  &:hover {
      &::-webkit-textfield-decoration-container ${css`
         {
          margin-left: -10px;
          transition: all 0.25s ease;
        }
      `}
      
    &::-webkit-inner-spin-button ${css`
       {
        appearance: auto;
        height: 22px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
      }
    `}
  }
 
`

export default FullAndSeparateSettingsInputUi
