import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { PaddingType } from 'common/types/styleTypes'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Range from '../../components/Range/Range'
import GroupTitle from '../../styleComponents/GroupTitle'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import {
  bottomSideRenderer,
  leftSideRenderer,
  rightSideRenderer,
  singleContainerRenderer,
  topSideRenderer,
} from '../MarginStyle/utils'
import FullAndSeparateSettingsContainerUi from '../ui/FullAndSeparateSettingsContainerUi'
import FullAndSeparateSettingsIconsContainerUi from '../ui/FullAndSeparateSettingsIconsContainerUi'
import FullAndSeparateSettingsInputUi from '../ui/FullAndSeparateSettingsInputUi'
import MobileIconUi from '../ui/MobileIconUi'
import FullPaddingTypeContainerUi from './ui/FullPaddingTypeContainerUi'
import {
  findLargestPadding,
  isSinglePadding,
  paddingStylesTranslations,
} from './utils'

type PaddingStyleProps = {
  padding: PaddingType | undefined
  update: (padding: PaddingType) => void
  label?: string
  groupTitle?: boolean
  allowNegative?: boolean
}

enum paddingEditingModes {
  SinglePadding = 'SinglePadding',
  SeparatePaddingType = 'SeparatePaddingType',
}

const separatePaddingTypeSides = {
  paddingTop: topSideRenderer,
  paddingRight: rightSideRenderer,
  paddingBottom: bottomSideRenderer,
  paddingLeft: leftSideRenderer,
}
function PaddingStyle({
  padding = {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  update,
  label,
  groupTitle = false,
  allowNegative = true,
}: PaddingStyleProps) {
  const generateId = uuid()

  const [paddingEditingMode, setPaddingEditingMode] = useState(
    isSinglePadding(padding)
      ? paddingEditingModes.SinglePadding
      : paddingEditingModes.SeparatePaddingType,
  )
  const { isMobile } = useDeviceModeContext()

  function updateSeparatePadding(corner: string) {
    return function (value: number) {
      const calculatedValue = value < 0 ? 0 : value
      update({
        ...padding,
        [corner]: calculatedValue,
      })
    }
  }

  function updateSinglePadding(value: number) {
    const calculatedValue = value < 0 ? 0 : value
    update({
      paddingTop: calculatedValue,
      paddingRight: calculatedValue,
      paddingBottom: calculatedValue,
      paddingLeft: calculatedValue,
    })
  }

  const switchToSingleMode = () => {
    setPaddingEditingMode(paddingEditingModes.SinglePadding)
    const largestPadding = findLargestPadding(padding as PaddingType)
    update({
      paddingTop: largestPadding,
      paddingRight: largestPadding,
      paddingBottom: largestPadding,
      paddingLeft: largestPadding,
    })
  }

  const switchToSeparatePaddingTypeMode = () => {
    if (paddingEditingMode === paddingEditingModes.SinglePadding) {
      setPaddingEditingMode(paddingEditingModes.SeparatePaddingType)
    }
  }

  return (
    <>
      {groupTitle && <GroupTitle>settings_styles.padding.label</GroupTitle>}
      <OptionWrapper
        labelText={label}
        labelIcon={isMobile ? <MobileIconUi /> : null}
      >
        <FullAndSeparateSettingsContainerUi
          column={
            paddingEditingMode === paddingEditingModes.SeparatePaddingType
          }
        >
          <FullAndSeparateSettingsIconsContainerUi>
            {singleContainerRenderer(
              paddingEditingMode === paddingEditingModes.SinglePadding,
              switchToSingleMode,
            )}
            <FullPaddingTypeContainerUi
              onClick={switchToSeparatePaddingTypeMode}
            >
              {Object.entries(separatePaddingTypeSides).map(
                ([side, renderSide]) =>
                  renderSide(
                    paddingEditingMode ===
                      paddingEditingModes.SeparatePaddingType,
                    `${side}-${generateId}`,
                  ),
              )}
            </FullPaddingTypeContainerUi>
          </FullAndSeparateSettingsIconsContainerUi>
          {paddingEditingMode === paddingEditingModes.SinglePadding ? (
            <FullAndSeparateSettingsInputUi
              onChange={e => updateSinglePadding(Number(e.target.value))}
              type="number"
              value={padding.paddingTop ? padding.paddingTop.toString() : 0}
              {...(allowNegative ? {} : { min: 0, max: 250 })}
            />
          ) : (
            Object.keys(separatePaddingTypeSides).map((side, index) => (
              <>
                <Range
                  label={paddingStylesTranslations[side as keyof PaddingType]}
                  labelIcon={isMobile ? <MobileIconUi /> : null}
                  value={
                    padding[side as keyof PaddingType]
                      ? padding[side as keyof PaddingType]
                      : 0
                  }
                  change={updateSeparatePadding(side)}
                  max={200}
                  itemStyles={{ padding: '15px 0' }}
                />
              </>
            ))
          )}
        </FullAndSeparateSettingsContainerUi>
      </OptionWrapper>
    </>
  )
}

export default PaddingStyle
