import React from 'react'
import { Trans } from 'react-i18next'
import ManagementEmptyTitleUi from './ui/ManagementEmptyTitleUi'
import ManagementEmptyDescriptionUi from './ui/ManagementEmptyDescriptionUi'
import ManagementEmptyUi from './ui/ManagementEmptyUi'

const ManagementEmpty = () => (
  <ManagementEmptyUi>
    <ManagementEmptyTitleUi>
      Ooops!
    </ManagementEmptyTitleUi>
    <ManagementEmptyDescriptionUi>
      <Trans parent="span">popup_management.empty_list</Trans>
    </ManagementEmptyDescriptionUi>
  </ManagementEmptyUi>
)

export default ManagementEmpty
