import { batch } from 'react-redux'
import { NotFound } from 'common/errors/NotFound'
import * as api from 'client/api/blockApi'
import { actionTypes } from '../reducers/blocksReducer'

export const isFetched = value => dispatch => {
  dispatch({
    type: actionTypes.IS_FETCHED,
    payload: value,
  })
}

export const addUserBlock = block => dispatch => {
  dispatch({
    type: actionTypes.ADD_USER_BLOCK,
    payload: block,
  })
}

export const addMasterBlock = block => dispatch => {
  dispatch({
    type: actionTypes.ADD_MASTER_BLOCK,
    payload: block,
  })
}

export const addCommonBlock = block => dispatch => {
  dispatch({
    type: actionTypes.ADD_COMMON_BLOCK,
    payload: block,
  })
}

export const addUserBlockFile = file => dispatch => {
  dispatch({
    type: actionTypes.ADD_USER_BLOCK_FILE,
    payload: file,
  })
}

export const addCommonBlockFile = file => dispatch => {
  dispatch({
    type: actionTypes.ADD_COMMON_BLOCK_FILE,
    payload: file,
  })
}

export const addMasterBlockFile = file => dispatch => {
  dispatch({
    type: actionTypes.ADD_MASTER_BLOCK_FILE,
    payload: file,
  })
}

export const deleteUserBlock = id => async dispatch => {
  try {
    await api.deleteUserBlock(id)
    dispatch({
      type: actionTypes.DELETE_USER_BLOCK,
      payload: id,
    })
  } catch (e) {
    if (e instanceof NotFound) {
      dispatch({
        type: actionTypes.DELETE_USER_BLOCK,
        payload: id,
      })
    } else {
      window.Rollbar.error(e)
    }
  }
}

export const deleteCommonBlock = id => async dispatch => {
  try {
    await api.deleteCommonBlock(id)
    dispatch({
      type: actionTypes.DELETE_COMMON_BLOCK,
      payload: id,
    })
  } catch (e) {
    if (e instanceof NotFound) {
      dispatch({
        type: actionTypes.DELETE_USER_BLOCK,
        payload: id,
      })
    } else {
      window.Rollbar.error(e)
    }
  }
}

export const deleteMasterBlock = id => async dispatch => {
  try {
    await api.deleteMasterBlock(id)
    dispatch({
      type: actionTypes.DELETE_MASTER_BLOCK,
      payload: id,
    })
  } catch (e) {
    if (e instanceof NotFound) {
      dispatch({
        type: actionTypes.DELETE_MASTER_BLOCK,
        payload: id,
      })
    } else {
      window.Rollbar.error(e)
    }
  }
}

const fetchUserBlocksSuccess = blocks => ({
  type: actionTypes.FETCH_USER_BLOCKS,
  payload: blocks,
})

const fetchCommonBlocksSuccess = blocks => ({
  type: actionTypes.FETCH_COMMON_BLOCKS,
  payload: blocks,
})

const fetchUserBlockFilesSuccess = blocks => ({
  type: actionTypes.FETCH_USER_BLOCK_FILES,
  payload: blocks,
})

const fetchMasterBlocksSuccess = (blocks = []) => ({
  type: actionTypes.FETCH_MASTER_BLOCKS,
  payload: blocks,
})

const fetchCommonBlockFilesSuccess = blocks => ({
  type: actionTypes.FETCH_COMMON_BLOCK_FILES,
  payload: blocks,
})

const fetchMasterBlockFilesSuccess = blocks => ({
  type: actionTypes.FETCH_MASTER_BLOCK_FILES,
  payload: blocks,
})

export const fetchBlocksSuccess = data => async dispatch => {
  batch(() => {
    dispatch(fetchUserBlocksSuccess(data.userBlocks))
    dispatch(fetchCommonBlocksSuccess(data.commonBlocks))
    dispatch(fetchMasterBlocksSuccess(data.masterBlocks))
    dispatch(fetchUserBlockFilesSuccess(data.userBlockFiles))
    dispatch(fetchCommonBlockFilesSuccess(data.commonBlockFiles))
    dispatch(fetchMasterBlockFilesSuccess(data.masterBlocksFiles))
    dispatch(isFetched(true))
  })
}
