import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ProductImagesCarousel from 'common/components/entities/Product/ProductImagesCarousel'
import ProductQuantity from 'common/components/entities/Product/ProductQuantity'
import ProductStockLimit from 'common/components/entities/Product/ProductStockLimit'
import ProductCarouselImageContainerUi from 'common/components/entities/Product/ui/ProductCarouselImageContainerUi'
import ProductCarouselSlideUi from 'common/components/entities/Product/ui/ProductCarouselSlideUi'
import ProductContainerUi from 'common/components/entities/Product/ui/ProductContainerUi'
import ProductContentContainerUi from 'common/components/entities/Product/ui/ProductContentContainerUi'
import ProductDescriptionContainerUi from 'common/components/entities/Product/ui/ProductDescriptionContainerUi'
import ProductInfoContainerUi from 'common/components/entities/Product/ui/ProductInfoContainerUi'
import ProductNameContainerUi from 'common/components/entities/Product/ui/ProductNameContainerUi'
import ProductPriceContainerUi from 'common/components/entities/Product/ui/ProductPriceContainerUi'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { CurrencyEnum } from 'common/types/CurrencyType'
import ProductEntityInterface, {
  ImageFileInterface,
  ProductVariantInterface,
} from 'common/types/entities/ProductInterface'
import { getReadablePrice } from 'common/utils/priceCalculator'
import BaseEntity from 'client/components/core/BaseEntity/BaseEntityNew'
import EntityExampleWrapper from 'client/components/core/EntityExampleWrapper'
import { getLocale } from 'client/reducers/pageReducer'
import { typedPaymentSelectors, usePage, usePayment } from 'client/store'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import ProductOptions from './ProductOptions'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'

const defaultProductExample = {
  id: 28,
  name: 'Product name',
  tax: 2,
  sku: 'SHI-418',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.',
  images: [
    {
      id: 1,
      path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
    },
    {
      id: 2,
      path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
    },
  ],
  currency: CurrencyEnum.US_DOLLAR,
  price: 213,
  options: [
    {
      id: '01GFNGV0AJBFAT3RBJK8HB7SH3',
      name: 'Size',
      optionValues: [
        {
          id: '01GFNGV0AJBMYQCQGFAK7X3M7H',
          value: 'Small',
        },
        {
          id: '01GFNGV6BSWC7Z8ZZTQ81E61NK',
          value: 'Medium',
        },
        {
          id: '01GFNGV72GARQX9MTTCXYW1C15',
          value: 'Large',
        },
      ],
    },
    {
      id: '01GFNGVE6301RXNEWYCNPGSRE9',
      name: 'Color',
      optionValues: [
        {
          id: '01GFNGVE63D2A3DK50Q96BKA21',
          value: 'Blue',
        },
        {
          id: '01GFNGVHZN8NVYYZDPKGV5HA7P',
          value: 'Red',
        },
        {
          id: '01GFNGVM6A4YDQTA9N3CH127WH',
          value: 'Green',
        },
      ],
    },
  ],
  productVariants: [
    {
      id: '01GFNGVK30XXXR3TRBFYC45PFC',
      optionValueIds: [
        '01GFNGV6BSWC7Z8ZZTQ81E61NK',
        '01GFNGVHZN8NVYYZDPKGV5HA7P',
      ],
      image: {
        id: 123123123,
        path: 'https://d1yei2z3i6k35z.cloudfront.net/common/Product_placeholder_image.png',
      },
      productOptionValues: [
        {
          id: '01GFNGV6BSWC7Z8ZZTQ81E61NK',
          value: 'Medium',
        },
        {
          id: '01GFNGVHZN8NVYYZDPKGV5HA7P',
          value: 'Red',
        },
      ],
      sku: '',
      price: undefined,
    },
  ],
}

function Product({
  entity,
  isMoveUpAllowed,
  isMoveDownAllowed,
}: EntityProps<ProductEntityInterface>) {
  const { t } = useTranslation()
  const product = usePayment(typedPaymentSelectors.getProduct)
  const locale = usePage(getLocale)
  const currentViewProduct = product ? product : defaultProductExample
  const isExample = !product
  const [imageSlides, setImageSlides] = useState<ImageFileInterface[]>([])

  useEffect(() => {
    const carouselImages = [...currentViewProduct.images]
    if (currentViewProduct.productVariants[0]?.image) {
      carouselImages.unshift(currentViewProduct.productVariants[0]?.image)
    }
    setImageSlides(carouselImages)
  }, [currentViewProduct])

  const isImages =
    !!currentViewProduct.productVariants[0]?.image?.path ||
    !!currentViewProduct.images[0]?.path

  const activeVariant: ProductVariantInterface =
    currentViewProduct.productVariants[0] || {}
  const stockLimit = activeVariant.stock ?? product?.stock ?? 0

  const renderedProduct = () => (
    <ProductContainerUi
      padding={entity.padding}
      mobilePadding={entity.mobilePadding}
      fontFamily={entity.fontFamily}
      fontWeight={entity.fontWeight}
      fontStyle={entity.fontStyle}
      mobileFontFamily={entity.mobileFontFamily}
      mobileFontWeight={entity.mobileFontWeight}
      mobileFontStyle={entity.mobileFontStyle}
    >
      <ProductContentContainerUi>
        {isImages && (
          <ProductInfoContainerUi>
            <ProductImagesCarousel
              totalItems={imageSlides.length}
              activeSlideIndex={0}
            >
              {imageSlides.map(image => (
                <ProductCarouselSlideUi key={image.id}>
                  <ProductCarouselImageContainerUi src={image.path} />
                </ProductCarouselSlideUi>
              ))}
            </ProductImagesCarousel>
          </ProductInfoContainerUi>
        )}
        <ProductInfoContainerUi>
          <ProductNameContainerUi>
            {currentViewProduct.name}
          </ProductNameContainerUi>
          <ProductPriceContainerUi>
            <div>{t('entity.product.price_label')}:</div>
            <CurrencyBadgeUi style={{ marginRight: 0 }}>{currentViewProduct.currency}</CurrencyBadgeUi>
            {getReadablePrice(
              (currentViewProduct.productVariants[0]?.price &&
                currentViewProduct.productVariants[0]?.price / 100) ||
                currentViewProduct.price / 100,
              currentViewProduct.currency,
              locale,
            )}
          </ProductPriceContainerUi>
          <ProductStockLimit
            stockLimit={stockLimit}
            showStockLimit={entity.showStockLimit}
          />
          <hr style={{ border: '1px solid #E1E3E5', width: '100%' }} />
          <ProductDescriptionContainerUi>
            {currentViewProduct.description}
          </ProductDescriptionContainerUi>

          <ProductQuantity quantity={1} showQuantity={entity.showQuantity} />
          {currentViewProduct.productVariants.length > 0 && (
            <ProductOptions
              options={currentViewProduct.options}
              activeVariant={activeVariant}
            />
          )}
        </ProductInfoContainerUi>
      </ProductContentContainerUi>
    </ProductContainerUi>
  )

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      {isExample ? (
        <EntityExampleWrapper label="entities.physical_product.example_warning">
          {renderedProduct()}
        </EntityExampleWrapper>
      ) : (
        renderedProduct()
      )}
    </BaseEntity>
  )
}

export function createProduct(
  parentId: string,
  masterBlockId?: string,
): ProductEntityInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.Product, parentId, masterBlockId),
    type: EntityTypeEnum.Product,
    showQuantity: true,
    padding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
  }
}

export default Product
