import { useEffect } from 'react'
import {
  appendCustomFontFaces,
  entitiesWithFontOptions,
  getEntitiesCustomFonts,
} from 'common/utils/fontsUtils'
import { useFiles } from 'client/store'
import filesSelectors from 'client/store/files/filesSelectors'
import { usePage } from 'client/store/index'
import { pageSelectors } from 'client/store/page/pageSelectors'

function useCustomFontFaces() {
  const globalFontProperties = usePage(page =>
    pageSelectors.getGlobalFontProperties(page),
  )

  const entities = usePage(page =>
    pageSelectors.getEntitiesByTypes(page, entitiesWithFontOptions),
  )
  const pageFonts = getEntitiesCustomFonts(entities)

  //text
  if (globalFontProperties.fontFamily && globalFontProperties.fontFileId) {
    const globalFont = {
      fontFamily: globalFontProperties.fontFamily,
      fontFileId: globalFontProperties.fontFileId,
    }
    pageFonts[globalFontProperties.fontFileId] = globalFont
  }
  //heading
  if (
    globalFontProperties.headingFontFamily &&
    globalFontProperties.headingFontFileId
  ) {
    const globalFont = {
      fontFamily: globalFontProperties.headingFontFamily,
      fontFileId: globalFontProperties.headingFontFileId,
    }
    pageFonts[globalFontProperties.headingFontFileId] = globalFont
  }

  const fontFilesIds = Object.keys(pageFonts).map(el => parseInt(el))
  //get all files
  const fontFiles = useFiles(files =>
    filesSelectors.getFilesByIds(files, fontFilesIds),
  )
  //add file path
  fontFilesIds.forEach(el => {
    if (fontFiles) {
      pageFonts[el].filePath = fontFiles[el]?.path
    }
  })

  const fontFilesIdsStr = JSON.stringify(fontFilesIds)
  useEffect(() => {
    appendCustomFontFaces(pageFonts)
  }, [fontFilesIdsStr])
}

export default useCustomFontFaces
