import styled from 'styled-components'

const IconSearchInputUi = styled.input`
  margin: 0 0 2px 0;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  text-indent: 5px;
  font-size: 12px;
  height: 36px;
  box-shadow: inset 1px 1px 1px rgba(130, 137, 150, 0.23);
  border: 1px solid #b0baca;
  outline: none;
  color: #485b6e;
  font-weight: bold;
`

export default IconSearchInputUi
