import React from 'react'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range/Range'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'

interface CountdownDelayProps {
  entity: CountdownInterface
}

const CountdownDelay = ({ entity }: CountdownDelayProps) => {
  const updateProp = useUpdateProp(entity)

  const updateDelay =
    (delayKey: keyof CountdownInterface['delay']) => (value: number) => {
      return updateProp(CountdownTypesEnum.delay)({
        ...entity[CountdownTypesEnum.delay],
        [delayKey]: value,
        createdAt: new Date().getTime(),
      })
    }

  return (
    <>
      <Range
        label={'entity_settings.countdown.delay.days'}
        value={entity[CountdownTypesEnum.delay].days}
        change={updateDelay('days')}
        max={99}
      />
      <Range
        label="entity_settings.countdown.delay.hours"
        value={entity[CountdownTypesEnum.delay].hours}
        change={updateDelay('hours')}
        max={23}
      />
      <Range
        label="entity_settings.countdown.delay.minutes"
        value={entity[CountdownTypesEnum.delay].minutes}
        change={updateDelay('minutes')}
        min={0}
        max={59}
      />
      <Range
        label="entity_settings.countdown.delay.seconds"
        value={entity[CountdownTypesEnum.delay].seconds}
        change={updateDelay('seconds')}
        min={0}
        max={59}
      />
    </>
  )
}

export default CountdownDelay
