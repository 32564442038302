import React from 'react'
import Background from './components/Background'
import Seo from './components/Seo'
import Tracking from './components/Tracking'

function BlogPostSettings() {
  return (
    <React.Fragment>
      <Background />
      <Seo />
      <Tracking />
    </React.Fragment>
  )
}

export default BlogPostSettings
