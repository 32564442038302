import i18n from 'i18next'
import React from 'react'
import { CustomLoginForgotPasswordInterface } from 'common/types/entities/CustomLoginForgotPasswordInterface'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { typedPageSelectors, usePage } from 'client/store'
import ColorPicker from '../../../components/ColorPicker'
import TextFontUpdater from '../../../components/Font/TextFontUpdater'
import { useUpdateEntity, useUpdateProp } from '../../../hooks/useUpdateProps'
import DeviceAppearanceNew from '../../../options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from '../../../options/HtmlAttrId'
import GroupTitle from '../../../styleComponents/GroupTitle'
import { TextAlignStyle } from '../../../styles'
import MarginStyle from '../../../styles/MarginStyle'
import TextFontSize from '../../../styles/TextFontSize'
import TextLineHeight from '../../../styles/TextLineHeight'

interface ForgotPasswordSettingsProps {
  entity: CustomLoginForgotPasswordInterface
}

function ForgotPasswordSettings({ entity }: ForgotPasswordSettingsProps) {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<CustomLoginForgotPasswordInterface>()
  const { isMobile } = useDeviceModeContext()
  const locale = usePage(typedPageSelectors.getPageLocale)

  const fixedT = i18n.getFixedT(locale)
  const forgotPasswordString = fixedT(
    'entities.member_access.request.default_headline',
  )

  return (
    <>
      <GroupTitle>typography</GroupTitle>
      <Input
        update={updateProp('text')}
        value={entity.text}
        placeholder={'entity_settings.menu.item.text_placeholder'}
        onBlur={() => {
          if (!entity.text) {
            updateProp('text')(forgotPasswordString)
          }
        }}
      />
      {/* @ts-ignore */}
      <TextFontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        removeEntityStyle={updateProp('fontSize')}
        removeEntityMobileStyle={updateProp('mobileFontSize')}
      />
      <TextLineHeight
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <TextFontUpdater<CustomLoginForgotPasswordInterface>
        entity={entity}
        update={updateEntity}
      />
      <TextAlignStyle
        textAlign={entity.textAlign || 'center'}
        mobileTextAlign={entity.mobileTextAlign || 'center'}
        update={updateProp('textAlign')}
        mobileUpdate={updateProp('mobileTextAlign')}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
        label="entity_settings.text.header"
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default ForgotPasswordSettings
