import React from 'react'
import PricePlanCalculatorInterface from 'common/types/entities/PricePlanCalculatorInterface'
import { Select } from 'client/components/core/Sidebar/components/Settings/components'
import { usePage } from 'client/store/index'
import pageSelectors from 'client/store/page/pageSelectors'
import { useUpdateProp } from '../hooks/useUpdateProps'

type PricePlanCalculatorSettingsProps = {
  entity: PricePlanCalculatorInterface
}

function PricePlanCalculatorSettings({
  entity,
}: PricePlanCalculatorSettingsProps) {
  const popups = usePage(pageSelectors.getPopupEntities)

  const updateProp = useUpdateProp(entity)

  const popupChoiceList = popups.reduce(
    (acc, popup) => ({
      ...acc,
      [popup.id]: popup.options.title,
    }),
    {},
  )

  return (
    <>
      <Select
        labelText="Popup"
        update={updateProp('popupId')}
        choiceList={popupChoiceList}
        firstOption="entity_settings.button.choose_actions"
        value={entity.popupId}
        needTranslate={false}
      />
    </>
  )
}

export default PricePlanCalculatorSettings
