import PropTypes from 'prop-types'
import React from 'react'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import HeadingFont from '../components/Font/HeadingFont'
import { Attributes, DelayOption, DeviceAppearance } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { PaddingStyle, MarginsStyle, TextAlignStyle } from '../styles'
import LetterSpacing from '../styles/LetterSpacing'
import TextFontSize from '../styles/TextFontSize'
import TextLineHeight from '../styles/TextLineHeight'

const TextSettings = props => {
  const {
    toggleOverlay,
    overlayVisible,
    entity,
    updateStyle,
    updateMobileStyle,
    updateOption,
    update,
    removeEntityStyle,
    removeEntityMobileStyle,
    entityWithoutEmulation,
  } = props
  const { isMobile } = useDeviceModeContext()

  const fontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const fontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const fontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  function handleFontChange(styles) {
    if (isMobile) {
      update({
        ...entity,
        mobileStyles: {
          ...entity.mobileStyles,
          ...styles,
        },
      })
    } else {
      update({
        ...entity,
        styles: {
          ...entity.styles,
          ...styles,
        },
      })
    }
  }

  return (
    <React.Fragment>
      <GroupTitle>typography</GroupTitle>
      <TextFontSize
        update={updateStyle('fontSize', 'px')}
        mobileUpdate={updateMobileStyle('fontSize', 'px')}
        fontSize={entityWithoutEmulation.styles.fontSize}
        mobileFontSize={entityWithoutEmulation.mobileStyles.fontSize}
        removeEntityStyle={removeEntityStyle('fontSize')}
        removeEntityMobileStyle={removeEntityMobileStyle('fontSize')}
      />
      <TextLineHeight
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
        update={updateStyle('lineHeight', 'px')}
        mobileUpdate={updateMobileStyle('lineHeight', 'px')}
        lineHeight={entityWithoutEmulation.styles.lineHeight}
        mobileLineHeight={entityWithoutEmulation.mobileStyles.lineHeight}
        removeEntityStyle={removeEntityStyle('lineHeight')}
        removeEntityMobileStyle={removeEntityMobileStyle('lineHeight')}
      />
      <HeadingFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <LetterSpacing
        change={
          isMobile
            ? updateMobileStyle('letterSpacing')
            : updateStyle('letterSpacing')
        }
        letterSpacing={
          isMobile
            ? entity.mobileStyles.letterSpacing
            : entity.styles.letterSpacing
        }
      />
      {/*<InheritedFontFamily />*/}
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        color={entity.styles.backgroundColor}
        mobileColor={entity.mobileStyles.backgroundColor}
        label="bg color"
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={
          typeof entity.mobileStyles.marginTop === 'undefined'
            ? entity.styles.marginTop
            : entity.mobileStyles.marginTop
        }
        mobileMarginRight={
          typeof entity.mobileStyles.marginRight === 'undefined'
            ? entity.styles.marginRight
            : entity.mobileStyles.marginRight
        }
        mobileMarginBottom={
          typeof entity.mobileStyles.marginBottom === 'undefined'
            ? entity.styles.marginBottom
            : entity.mobileStyles.marginBottom
        }
        mobileMarginLeft={
          typeof entity.mobileStyles.marginLeft === 'undefined'
            ? entity.styles.marginLeft
            : entity.mobileStyles.marginLeft
        }
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <TextAlignStyle
        textAlign={entity.styles.textAlign}
        mobileTextAlign={entity.mobileStyles.textAlign}
        update={updateStyle('textAlign')}
        mobileUpdate={updateMobileStyle('textAlign')}
      />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <DelayOption
        update={updateOption('delay')}
        delay={entity.options.delay}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

TextSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  removeEntityMobileStyle: PropTypes.func.isRequired,
  removeEntityStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  entityWithoutEmulation: types.entity.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default TextSettings
