import { TFunction } from 'i18next'
import { SingleValue } from 'react-select'
import { localeNames } from 'common/constants/localeTypes'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import { getAutomationResources } from 'client/api/resourcesApi'
import { OptionType } from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'
import {
  AccessTypeEnum,
  ActionCampaignEditInterface,
  ActionCommunityEditInterface,
  ActionCourseBundleEditInterface,
  ActionCourseEditInterface,
  ActionCreateUserEditInterface,
  ActionEditType,
  ActionEmailEditInterface,
  ActionSaveType,
  ActionSpecEmailEditInterface,
  ActionTagEditInterface,
  ActionWebhookEditInterface,
  DefinedActionEditType,
} from 'client/types/AutomationRulesActionsEditInterface'
import {
  BaseLocaleOptionInterface,
  BaseNameInterface,
  BaseNumberOptionInterface,
  BaseSubjectInterface,
  UserResourcesInterface,
} from 'client/types/UserResourcesInterface'

const getBaseNameOptions = (data: BaseNameInterface[]) => {
  const options: BaseNumberOptionInterface[] = data.map(resource => {
    return {
      label: resource.name,
      value: resource.id,
    }
  })
  return options
}

const getBaseSubjectOptions = (data: BaseSubjectInterface[]) => {
  const options: BaseNumberOptionInterface[] = data.map(resource => {
    return {
      label: resource.subject,
      value: resource.id,
    }
  })
  return options
}

export const getBaseLocaleOptions = (t: TFunction) => {
  const options: BaseLocaleOptionInterface[] = Object.values(
    PageLocaleEnum,
  ).map(locale => ({
    value: locale,
    label: t(localeNames[locale]),
  }))

  return options
}

export async function fetchResource({
  setIsResourceLoading,
  setUserResources,
  actionKey,
  resourceKey,
  isBaseNumberOption,
}: {
  setIsResourceLoading: React.Dispatch<
    React.SetStateAction<Record<AutomationRuleActionsEnum, boolean>>
  >
  setUserResources: React.Dispatch<React.SetStateAction<UserResourcesInterface>>
  actionKey: AutomationRuleActionsEnum
  resourceKey: keyof UserResourcesInterface
  isBaseNumberOption: boolean
}) {
  try {
    setIsResourceLoading(prev => ({ ...prev, [actionKey]: true }))
    const response = await getAutomationResources(getResourceApiUrl(actionKey))
    setUserResources(prev => {
      return {
        ...prev,
        [resourceKey]: isBaseNumberOption
          ? getBaseNameOptions(response.data)
          : getBaseSubjectOptions(response.data),
      }
    })
    setIsResourceLoading(prev => ({ ...prev, [actionKey]: false }))
  } catch (e) {
    setIsResourceLoading(prev => ({ ...prev, [actionKey]: false }))
    // do nothing
  }
}

const getResourceApiUrl = (actionKey: AutomationRuleActionsEnum) => {
  switch (actionKey) {
    case AutomationRuleActionsEnum.add_tag:
    case AutomationRuleActionsEnum.remove_tag: {
      return `/automation/resources/tags`
    }
    case AutomationRuleActionsEnum.revoke_access_to_course:
    case AutomationRuleActionsEnum.enroll_in_course: {
      return `/automation/resources/courses`
    }
    case AutomationRuleActionsEnum.send_email: {
      return `/automation/resources/emails`
    }
    case AutomationRuleActionsEnum.send_email_to_specific_address: {
      return `/automation/resources/specific-emails`
    }
    case AutomationRuleActionsEnum.subscribe_to_campaign:
    case AutomationRuleActionsEnum.unsubscribe_from_campaign: {
      return `/automation/resources/campaigns`
    }
    case AutomationRuleActionsEnum.revoke_access_to_community:
    case AutomationRuleActionsEnum.grant_access_to_community: {
      return `/automation/resources/communities`
    }
    case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
    case AutomationRuleActionsEnum.enroll_in_course_bundle: {
      return `/automation/resources/course-bundles`
    }
    default:
      return ''
  }
}

export const getActionDefaultOption = (action: DefinedActionEditType) => {
  switch (action.type) {
    case AutomationRuleActionsEnum.add_tag:
    case AutomationRuleActionsEnum.remove_tag: {
      return {
        value: action.tag.id,
        label: action.tag.name,
      }
    }
    case AutomationRuleActionsEnum.revoke_access_to_course:
    case AutomationRuleActionsEnum.enroll_in_course: {
      return {
        value: action.course.id,
        label: action.course.name,
      }
    }
    case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
    case AutomationRuleActionsEnum.enroll_in_course_bundle: {
      return {
        value: action.courseBundle.id,
        label: action.courseBundle.name,
      }
    }
    case AutomationRuleActionsEnum.send_email: {
      return {
        value: action.systemeEmail.id,
        label: action.systemeEmail.subject,
      }
    }
    case AutomationRuleActionsEnum.send_email_to_specific_address: {
      return {
        value: action.emailMessageWithRecipient.id,
        label: action.emailMessageWithRecipient.mailing?.currentEmail?.subject,
      }
    }
    case AutomationRuleActionsEnum.subscribe_to_campaign:
    case AutomationRuleActionsEnum.unsubscribe_from_campaign: {
      return {
        value: action.campaign.id,
        label: action.campaign.name,
      }
    }
    case AutomationRuleActionsEnum.send_webhook: {
      return {
        value: action.id,
        label: action.webhookUrl || '',
      }
    }
    case AutomationRuleActionsEnum.revoke_access_to_community:
    case AutomationRuleActionsEnum.grant_access_to_community: {
      return {
        value: action.community.id,
        label: action.community.name,
      }
    }
    default:
      return null
  }
}

export const getResourceSelectedOption = (
  action: ActionEditType,
  userResources: BaseNumberOptionInterface[],
  resourceId?: number,
) => {
  return resourceId
    ? getOptionFromResourcesList(userResources, resourceId) ||
        getActionDefaultOption(action as DefinedActionEditType)
    : null
}

export function getOptionFromResourcesList(
  userResources: BaseNumberOptionInterface[],
  resourceId: number,
) {
  const resourceOption = userResources.find(item => item.value === resourceId)

  return resourceOption
    ? {
        value: resourceOption.value,
        label: resourceOption.label,
      }
    : undefined
}

const isExistingAction = (id: number, existingActions: ActionEditType[]) =>
  !!existingActions.find(action => action.id === id)

export const getActionProperties = (
  action: ActionEditType,
  option?: OptionType<number> | OptionType<PageLocaleEnum> | null,
  value?: string,
  accessType?: AccessTypeEnum,
) => {
  switch (action.type) {
    case AutomationRuleActionsEnum.add_tag:
    case AutomationRuleActionsEnum.remove_tag: {
      if (option) {
        const castedOption = option as OptionType<number>
        return {
          tag: {
            id: castedOption.value,
            name: castedOption.label,
          },
        }
      }
      break
    }
    case AutomationRuleActionsEnum.revoke_access_to_course:
    case AutomationRuleActionsEnum.enroll_in_course: {
      const castedOption = option as OptionType<number>
      return {
        course: castedOption
          ? {
              id: castedOption.value,
              name: castedOption.label,
            }
          : action.course,
        courseAccessType: accessType || action.courseAccessType,
      }
    }
    case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
    case AutomationRuleActionsEnum.enroll_in_course_bundle: {
      const castedOption = option as OptionType<number>
      return {
        courseBundle: castedOption
          ? {
              id: castedOption.value,
              name: castedOption.label,
            }
          : action.courseBundle,
        enrollmentAccessType: accessType || action.enrollmentAccessType,
      }
    }
    case AutomationRuleActionsEnum.send_email: {
      if (option) {
        const castedOption = option as OptionType<number>
        return {
          systemeEmail: {
            id: castedOption.value,
            subject: castedOption.label,
          },
        }
      }
      break
    }
    case AutomationRuleActionsEnum.send_email_to_specific_address: {
      if (option) {
        const castedOption = option as OptionType<number>
        return {
          emailMessageWithRecipient: {
            id: castedOption.value,
            mailing: {
              currentEmail: {
                subject: castedOption.label,
              },
            },
          },
        }
      }
      break
    }
    case AutomationRuleActionsEnum.subscribe_to_campaign:
    case AutomationRuleActionsEnum.unsubscribe_from_campaign: {
      if (option) {
        const castedOption = option as OptionType<number>
        return {
          campaign: {
            id: castedOption.value,
            name: castedOption.label,
          },
        }
      }
      break
    }
    case AutomationRuleActionsEnum.send_webhook: {
      return {
        webhookUrl: value,
      }
    }
    case AutomationRuleActionsEnum.revoke_access_to_community:
    case AutomationRuleActionsEnum.grant_access_to_community: {
      if (option) {
        const castedOption = option as OptionType<number>
        return {
          community: {
            id: castedOption.value,
            name: castedOption.label,
          },
        }
      }
      break
    }
    case AutomationRuleActionsEnum.create_user: {
      if (option) {
        const castedOption = option as OptionType<PageLocaleEnum>
        return {
          locale: castedOption.value,
        }
      }
      break
    }
    default:
      return null
  }
}

export const changeResourcesState = ({
  action,
  option,
  existingActions,
  setExistingActions,
  setTempActions,
  value,
  accessType,
}: {
  action: ActionEditType
  option?:
    | SingleValue<OptionType<number>>
    | SingleValue<OptionType<PageLocaleEnum>>
  existingActions: ActionEditType[]
  setExistingActions: (value: React.SetStateAction<ActionEditType[]>) => void
  setTempActions: (value: React.SetStateAction<ActionEditType[]>) => void
  value?: string
  accessType?: AccessTypeEnum
}) => {
  if (isExistingAction(action.id, existingActions)) {
    setExistingActions(prev => {
      return prev.map(el => {
        if (action.id === el.id) {
          return {
            ...el,
            ...(getActionProperties(action, option, value, accessType) || {}),
          }
        } else {
          return el
        }
      })
    })
  } else {
    setTempActions(prev => {
      return prev.map(el => {
        if (action.id === el.id) {
          return {
            ...el,
            ...(getActionProperties(action, option, value, accessType) || {}),
          }
        } else {
          return el
        }
      })
    })
  }
}

const isTagEmpty = (action: ActionEditType) =>
  (action.type === AutomationRuleActionsEnum.add_tag ||
    action.type === AutomationRuleActionsEnum.remove_tag) &&
  !action.tag.id

const isCampaignEmpty = (action: ActionEditType) =>
  (action.type === AutomationRuleActionsEnum.subscribe_to_campaign ||
    action.type === AutomationRuleActionsEnum.unsubscribe_from_campaign) &&
  !action.campaign.id

const isCourseEmpty = (action: ActionEditType) =>
  (action.type === AutomationRuleActionsEnum.enroll_in_course ||
    action.type === AutomationRuleActionsEnum.revoke_access_to_course) &&
  !action.course.id

const isCourseBundleEmpty = (action: ActionEditType) =>
  (action.type === AutomationRuleActionsEnum.enroll_in_course_bundle ||
    action.type === AutomationRuleActionsEnum.revoke_access_to_course_bundle) &&
  !action.courseBundle.id

const isEmailEmpty = (action: ActionEditType) =>
  action.type === AutomationRuleActionsEnum.send_email &&
  !action.systemeEmail.id

const isSpecificEmailEmpty = (action: ActionEditType) =>
  action.type === AutomationRuleActionsEnum.send_email_to_specific_address &&
  !action.emailMessageWithRecipient.id

const isCommunityEmpty = (action: ActionEditType) =>
  (action.type === AutomationRuleActionsEnum.grant_access_to_community ||
    action.type === AutomationRuleActionsEnum.revoke_access_to_community) &&
  !action.community.id

const isWebhookEmpty = (action: ActionEditType) =>
  action.type === AutomationRuleActionsEnum.send_webhook &&
  (!action.webhookUrl || (action.webhookUrl && action.webhookUrl.length === 0))

const isLocaleEmpty = (action: ActionEditType) =>
  action.type === AutomationRuleActionsEnum.create_user && !action.locale

export const validateAutomationResources = (
  actions: ActionEditType[],
  t: TFunction,
) => {
  const errors: Record<number, string> = {}
  let haveEmptyFields = false

  actions.forEach(action => {
    if (
      isTagEmpty(action) ||
      isCampaignEmpty(action) ||
      isCourseEmpty(action) ||
      isCourseBundleEmpty(action) ||
      isEmailEmpty(action) ||
      isSpecificEmailEmpty(action) ||
      isWebhookEmpty(action) ||
      isCommunityEmpty(action) ||
      isLocaleEmpty(action)
    ) {
      haveEmptyFields = true
      errors[action.id] = t('validation.errors.form_field_should_not_be_empty')
    }
  })

  return { errors, haveEmptyFields }
}

export const findDeletedAction = (
  deletedActions: ActionSaveType[],
  createdAction: ActionEditType,
) => {
  const deletedTagAction = deletedActions.find(deletedAction => {
    if (createdAction.type !== deletedAction.type) return

    switch (deletedAction.type) {
      case AutomationRuleActionsEnum.add_tag:
      case AutomationRuleActionsEnum.remove_tag: {
        if (
          deletedAction.tag.id ===
          (createdAction as ActionTagEditInterface).tag.id
        ) {
          return deletedAction
        }
        break
      }
      case AutomationRuleActionsEnum.subscribe_to_campaign:
      case AutomationRuleActionsEnum.unsubscribe_from_campaign: {
        if (
          deletedAction.campaign.id ===
          (createdAction as ActionCampaignEditInterface).campaign.id
        ) {
          return deletedAction
        }
        break
      }
      case AutomationRuleActionsEnum.send_email: {
        if (
          deletedAction.systemeEmail.id ===
          (createdAction as ActionEmailEditInterface).systemeEmail.id
        ) {
          return deletedAction
        }
        break
      }
      case AutomationRuleActionsEnum.send_email_to_specific_address: {
        if (
          deletedAction.emailMessageWithRecipient.id ===
          (createdAction as ActionSpecEmailEditInterface)
            .emailMessageWithRecipient.id
        ) {
          return deletedAction
        }
        break
      }
      case AutomationRuleActionsEnum.revoke_access_to_course:
      case AutomationRuleActionsEnum.enroll_in_course: {
        if (
          deletedAction.course.id ===
            (createdAction as ActionCourseEditInterface).course.id &&
          deletedAction.courseAccessType ===
            (createdAction as ActionCourseEditInterface).courseAccessType
        ) {
          return deletedAction
        }
        break
      }
      case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
      case AutomationRuleActionsEnum.enroll_in_course_bundle: {
        if (
          deletedAction.courseBundle.id ===
            (createdAction as ActionCourseBundleEditInterface).courseBundle
              .id &&
          deletedAction.enrollmentAccessType ===
            (createdAction as ActionCourseBundleEditInterface)
              .enrollmentAccessType
        ) {
          return deletedAction
        }
        break
      }
      case AutomationRuleActionsEnum.send_webhook: {
        if (
          deletedAction.webhookUrl ===
          (createdAction as ActionWebhookEditInterface).webhookUrl
        ) {
          return deletedAction
        }
        break
      }
      case AutomationRuleActionsEnum.revoke_access_to_community:
      case AutomationRuleActionsEnum.grant_access_to_community: {
        if (
          deletedAction.community.id ===
          (createdAction as ActionCommunityEditInterface).community.id
        ) {
          return deletedAction
        }
        break
      }
      case AutomationRuleActionsEnum.create_user: {
        if (
          deletedAction.locale ===
          (createdAction as ActionCreateUserEditInterface).locale
        ) {
          return deletedAction
        }
        break
      }
      default:
        break
    }
  })

  return deletedTagAction
}
