import PropTypes from 'prop-types'
import React from 'react'
import { widthNames } from 'common/constants/settings'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { useActions } from 'common/hooks/useActions'
import * as types from 'common/types'
import { update as updateAc } from 'client/actions/entityActions'
import {
  Select,
  Checkbox,
} from 'client/components/core/Sidebar/components/Settings/components'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import {
  DelayOption,
  DeviceAppearance,
  Attributes,
} from 'client/components/core/Sidebar/components/Settings/options'
import Sticky from 'client/components/core/Sidebar/components/Settings/options/Sticky'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  PaddingStyle,
  MarginsStyle,
  BackgroundPositionStyleNew,
} from 'client/components/core/Sidebar/components/Settings/styles'
import ShadowStyle from 'client/components/core/Sidebar/components/Settings/styles/ShadowStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { pageSelectors, usePage } from 'client/store'
import { getBackgroundStyles } from '../../pages/components/Background'

const SectionSettingsOld = props => {
  const {
    entity,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateMobileOption,
    update,
  } = props
  const { isMobile } = useDeviceModeContext()
  const updateAction = useActions(updateAc)
  const pageType = usePage(pageSelectors.getPageType)

  const handleUpdateStyles = styles => {
    const entityMobileStyles = entity.mobileStyles ? entity.mobileStyles : {}
    const entityStyles = entity.styles ? entity.styles : {}
    const updatedEntity = { ...entity }
    if (updatedEntity.options.backgroundPosition) {
      delete updatedEntity.options.backgroundPosition
    }
    if (
      updatedEntity.mobileOptions &&
      updatedEntity.mobileOptions.backgroundPosition
    ) {
      delete updatedEntity.mobileOptions.backgroundPosition
    }
    if (isMobile) {
      updateAction({
        ...updatedEntity,
        mobileStyles: {
          ...entityMobileStyles,
          ...styles,
        },
      })
    } else {
      updateAction({
        ...updatedEntity,
        styles: {
          ...entityStyles,
          ...styles,
        },
      })
    }
  }
  const backgroundStyles = getBackgroundStyles(entity, isMobile)

  const showStickySettings = pageType !== PageTypeEnum.Lecture

  return (
    <div>
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="bg color"
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        color={entity.styles.backgroundColor}
        mobileColor={entity.mobileStyles.backgroundColor}
      />
      <ColorPicker
        label="inner bg color"
        update={updateOption('containerBackgroundColor')}
        mobileUpdate={updateMobileOption('containerBackgroundColor')}
        color={entity.options.containerBackgroundColor}
        mobileColor={entity.mobileOptions.containerBackgroundColor}
      />
      <BackgroundPositionStyleNew
        fileId={
          isMobile
            ? entity.mobileOptions.backgroundFileId ||
              entity.options.backgroundFileId
            : entity.options.backgroundFileId
        }
        updateBackgroundFileId={
          isMobile
            ? updateMobileOption('backgroundFileId')
            : updateOption('backgroundFileId')
        }
        update={handleUpdateStyles}
        backgroundPosition={backgroundStyles}
      />
      <GroupTitle>size & position</GroupTitle>
      <Select
        value={entity.options.width || widthNames.medium}
        update={updateOption('width')}
        labelText="entity_settings.section.content_width.label"
        helpMessage="entity_settings.section.content_width.help_message"
        choiceList={widthNames}
        needTranslate={true}
      />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <div style={{ display: 'none' }}>
        <GroupTitle>entity_settings.section.stretch.group</GroupTitle>
        <Checkbox
          labelText="entity_settings.section.stretch.label"
          update={updateOption('stretch')}
          value={entity.options.stretch}
          helpMessage="entity_settings.section.stretch.description"
        />
      </div>
      <DelayOption
        update={updateOption('delay')}
        delay={entity.options.delay}
      />
      <DeviceAppearance entity={entity} update={update} />
      {showStickySettings && <Sticky update={update} entity={entity} />}
      <Attributes entity={entity} update={updateOption} />
    </div>
  )
}

SectionSettingsOld.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default SectionSettingsOld
