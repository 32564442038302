import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import GreenButton from '../../GreenButton'

function ToggleButton(props) {
  const { toggle, title, label, iconClassName, active } = props
  const { t, i18n } = useTranslation()
  const loading = !i18n.language
  const renderedLabel = loading ? <Skeleton width={50} /> : t(label)
  return (
    <GreenButton
      onClick={toggle}
      title={t(title)}
      active={active}
      disabled={loading}
    >
      {renderedLabel}
      {iconClassName && (
        <span
          className={iconClassName}
          style={{ marginLeft: label ? '3px' : '0px', top: '3px' }}
        />
      )}
    </GreenButton>
  )
}

ToggleButton.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  iconClassName: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

ToggleButton.defaultProps = {
  iconClassName: '',
  label: '',
  active: false,
}

export default ToggleButton
