import { STEP_OPT_IN } from 'common/components/entities/TwoStepPaymentForm'
import {
  TOGGLE_POPUP_MANAGEMENT,
  TOGGLE_SETTINGS,
  TOGGLE_ACTIVE_POPUP,
  TOGGLE_NEED_SCROLL,
  TOGGLE_CREATE_BLOCK_DIALOG,
  FETCH_EXIT_URL,
  TOGGLE_SETTINGS_OVERLAY,
  FETCH_USER,
  SUCCESS,
  TOGGLE_PAGE_SETTINGS,
  CHANGE_TWO_STEP_PAYMENT_FORM_STEP_TYPE,
  HAS_LOST_ENTITIES,
  HAS_MASTER_BLOCK_ROOT_WITHOUT_MASTER_BLOCK_ID,
  HAS_MASTER_BLOCK_IDS_WITHOUT_MASTER_ROOT,
  TOGGLE_CODE_DIALOG,
  ALLOW_CODE_DIALOG_PORTAL,
  TOGGLE_SIDE_MENU_OPENED,
  TOGGLE_DROP_DOWN_MENU_OPENED,
  CLOSE_CODE_DIALOG,
  TOGGLE_INSTANT_UPLOAD,
  SHOW_SURVEY_RESULT,
  FETCH_COLORS,
  FETCH_HAS_FACEBOOK_CONVERSION_PIXEL,
} from '../actionTypes'
import {
  SET_EDITING_ENTITY,
  SWITCH_ENTITY_INNER_ITEM_ACTIVE_INDEX,
  SET_USER_COLORS,
  NEED_SCROLL,
} from '../store/management/managementActionTypes'

const defaultReducerState = {
  isPopupManagement: false,
  isCodeDialogOpened: false,
  isCodeDialogPortalAllowed: false,
  activePopupId: null,
  settingsEditing: {
    id: null,
    needOverlay: true,
  },
  editingEntityId: null,
  needScroll: false,
  createBlockDialogEntityId: null,
  isSettingsOverlayVisible: false,
  isPageEditing: false,
  exitUrl: null,
  user: {},
  twoStepPaymentFormStepType: STEP_OPT_IN,
  hasLostEntities: false,
  hasMasterBlockRootWithoutMasterBlockId: false,
  hasMasterBlockIdsWithoutMasterRoot: false,
  sideMenuOpened: false,
  dropDownMenuOpened: false,
  instantUploadEnabled: false,
  isSurveyResult: false,
  entityInnerItemActiveIndex: {},
  customColors: [],
}

export default function (state = defaultReducerState, action) {
  const { type, payload } = action

  switch (type) {
    case SET_EDITING_ENTITY:
      return {
        ...state,
        settingsEditing: { id: payload },
        editingEntityId: payload,
      }
    case CHANGE_TWO_STEP_PAYMENT_FORM_STEP_TYPE:
      return {
        ...state,
        twoStepPaymentFormStepType: payload,
      }
    case SWITCH_ENTITY_INNER_ITEM_ACTIVE_INDEX:
      return {
        ...state,
        entityInnerItemActiveIndex: {
          ...state.entityInnerItemActiveIndex,
          [payload.entityId]: payload.entityInnerItemActiveIndex,
        },
      }
    case TOGGLE_POPUP_MANAGEMENT:
      return {
        ...state,
        isPopupManagement: payload,
      }
    case TOGGLE_CODE_DIALOG:
      return {
        ...state,
        isCodeDialogOpened: payload,
      }
    case CLOSE_CODE_DIALOG:
      return {
        ...state,
        isCodeDialogOpened: false,
        isCodeDialogPortalAllowed: false,
      }
    case ALLOW_CODE_DIALOG_PORTAL:
      return {
        ...state,
        isCodeDialogPortalAllowed: payload,
      }
    case TOGGLE_ACTIVE_POPUP:
      return {
        ...state,
        activePopupId: payload.id,
        isPopupManagement: false,
      }
    case TOGGLE_SETTINGS:
      return {
        ...state,
        settingsEditing: {
          id: payload.id,
        },
        editingEntityId: payload.id,
        isPageEditing: false,
      }
    case TOGGLE_INSTANT_UPLOAD:
      return {
        ...state,
        instantUploadEnabled: payload,
      }
    case TOGGLE_SIDE_MENU_OPENED:
      return {
        ...state,
        sideMenuOpened: !state.sideMenuOpened,
      }
    case TOGGLE_DROP_DOWN_MENU_OPENED:
      return {
        ...state,
        dropDownMenuOpened: !state.dropDownMenuOpened,
      }
    case TOGGLE_PAGE_SETTINGS:
      return {
        ...state,
        isPageEditing: payload,
      }
    case TOGGLE_SETTINGS_OVERLAY:
      return {
        ...state,
        isSettingsOverlayVisible: payload,
      }
    case TOGGLE_NEED_SCROLL:
    case NEED_SCROLL:
      return {
        ...state,
        needScroll: payload,
      }
    case TOGGLE_CREATE_BLOCK_DIALOG:
      return {
        ...state,
        createBlockDialogEntityId: payload,
      }
    case FETCH_EXIT_URL:
      return {
        ...state,
        exitUrl: payload,
      }
    case FETCH_USER + SUCCESS:
      return {
        ...state,
        user: payload,
      }
    case FETCH_COLORS + SUCCESS:
      return {
        ...state,
        customColors: payload,
      }
    case FETCH_HAS_FACEBOOK_CONVERSION_PIXEL + SUCCESS:
      return {
        ...state,
        hasFacebookConversionPixel: payload,
      }
    case HAS_LOST_ENTITIES:
      return {
        ...state,
        hasLostEntities: payload,
      }
    case HAS_MASTER_BLOCK_ROOT_WITHOUT_MASTER_BLOCK_ID:
      return {
        ...state,
        hasMasterBlockRootWithoutMasterBlockId: payload,
      }
    case HAS_MASTER_BLOCK_IDS_WITHOUT_MASTER_ROOT:
      return { ...state, hasMasterBlockIdsWithoutMasterRoot: payload }
    case SHOW_SURVEY_RESULT:
      return { ...state, isSurveyResult: payload }
    case SET_USER_COLORS:
      return { ...state, customColors: payload }
    default:
      return state
  }
}

export const isPopupManagement = ({ isPopupManagement }) => isPopupManagement

export const isPageEditing = ({ isPageEditing }) => isPageEditing

export const getSettingsEditing = ({ settingsEditing }) => settingsEditing

export const isEditing = ({ settingsEditing }, id) => settingsEditing.id === id

export const getIsEditing = ({ settingsEditing }, id) =>
  settingsEditing.id === id

export const getEditingId = ({ settingsEditing }) => settingsEditing.id

export const isSomethingEditing = ({ settingsEditing: { id } }) => id !== null

export const isPopupActive = management => management.activePopupId !== null

export const getActivePopupId = ({ activePopupId }) => activePopupId

export const isPopupEditing = ({ activePopupId }) => activePopupId !== null

export const isCreateBlockDialogActive = ({ createBlockDialogEntityId }) =>
  Boolean(createBlockDialogEntityId)

export const isBlockSaveDialogOpened = (
  { createBlockDialogEntityId },
  entityId,
) => createBlockDialogEntityId === entityId

export const getCreateBlockDialogEntityId = ({ createBlockDialogEntityId }) =>
  createBlockDialogEntityId

export const getNeedScroll = ({ needScroll }) => needScroll

export const getExitUrl = ({ exitUrl }) => exitUrl

export const isUserAdmin = ({ user }) => user && user.isAdmin

export const isSettingsOverlayVisible = ({ isSettingsOverlayVisible }) =>
  isSettingsOverlayVisible

export const getTwoStepPaymentFormStepType = ({ twoStepPaymentFormStepType }) =>
  twoStepPaymentFormStepType

export const hasLostEntities = ({ hasLostEntities }) => hasLostEntities

export const hasMasterBlockRootWithoutMasterBlockId = ({
  hasMasterBlockRootWithoutMasterBlockId,
}) => hasMasterBlockRootWithoutMasterBlockId

export const hasMasterBlockIdsWithoutMasterRoot = ({
  hasMasterBlockIdsWithoutMasterRoot,
}) => hasMasterBlockIdsWithoutMasterRoot

export const isSideMenuOpened = state => state.sideMenuOpened

export const isDropDownMenuOpened = state => state.dropDownMenuOpened

export const isCodeDialogOpened = state => state.isCodeDialogOpened

export const isCodeDialogPortalAllowed = state =>
  state.isCodeDialogPortalAllowed

export const isInstantUploadEnabled = state => state.instantUploadEnabled

export const isSurveyResult = state => state.isSurveyResult

export const getEntityInnerItemActiveIndex = (state, entityId) =>
  state.entityInnerItemActiveIndex[entityId] || 0

export const getCustomColors = ({ customColors }) => customColors

export const selectors = {
  getEntityInnerItemActiveIndex,
  isPopupManagement,
  isEditing,
  isPageEditing,
  isPopupActive,
  getEditingId,
  getActivePopupId,
  getExitUrl,
  isSomethingEditing,
  isUserAdmin,
  getTwoStepPaymentFormStepType,
  hasLostEntities,
  hasMasterBlockRootWithoutMasterBlockId,
  hasMasterBlockIdsWithoutMasterRoot,
  isCodeDialogOpened,
  isCodeDialogPortalAllowed,
  isSideMenuOpened,
  isDropDownMenuOpened,
  isInstantUploadEnabled,
  isSurveyResult,
  getCustomColors,
}
