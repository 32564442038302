import styled from 'styled-components'

const LinkUi = styled.a`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    color: #fff;
    background-color: #0074c7;
  }
`
export default LinkUi
