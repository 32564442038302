import React, { useEffect, useMemo } from 'react'
import { MobileMenuTypesEnum } from 'common/components/entities/Menu/enums/MobileMenuTypesEnum'
import MenuInterface from 'common/components/entities/Menu/types/MenuInterface'
import { hasSubMenu } from 'common/components/entities/Menu/utils/hasSubMenu'
import { useActions } from 'common/hooks/useActions'
import { Appearance } from 'common/types/entities/EntityInterface'
import * as managementActions from 'client/actions/actionsManagement'
import {
  Button,
  Select,
  Switcher,
} from 'client/components/core/Sidebar/components/Settings/components'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import MenuBurger from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuBurger'
import MenuItem from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem'
import MenuSpacing from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuSpacing'
import { allowedBlogLinksPageTypes } from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/constants/AllowedBlogLinksPageTypes'
import { mobileMenuTypesLocoKeys } from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/constants/MobileMenuTypesLocoKeys'
import { useUpdateMenuItem } from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/hooks/useUpdateMenuItem'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import { FontSize } from 'client/components/core/Sidebar/components/Settings/options'
import DeviceAppearance from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import Divider from 'client/components/core/Sidebar/components/Settings/styleComponents/Divider'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  FlexAlignStyle,
  LineHeight,
} from 'client/components/core/Sidebar/components/Settings/styles'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import useManagement, {
  selectors as managementSelectors,
} from 'client/hooks/useManagement'
import { usePage } from 'client/store'
import { pageSelectors } from 'client/store/page/pageSelectors'

const MenuSettings = ({ entity }: { entity: MenuInterface }) => {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<MenuInterface>()
  const { isMobile } = useDeviceModeContext()
  const pageType = usePage(pageSelectors.getPageType)
  const isSideMenuOpened = useManagement(managementSelectors.isSideMenuOpened)
  const isDropDownMenuOpened = useManagement(
    managementSelectors.isDropDownMenuOpened,
  )
  const [toggleSideMenuOpened, toggleDropDownMenuOpened] = useActions([
    managementActions.toggleSideMenuOpened,
    managementActions.toggleDropDownMenuOpened,
  ])

  const { addMenuItem } = useUpdateMenuItem({ entity })

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }
  useEffect(() => {
    if (!allowedBlogLinksPageTypes.includes(pageType)) {
      if (entity.menuItems.some(item => item.link.includes('%BLOG_'))) {
        const updatedMenuItems = entity.menuItems.map(item => {
          if (item.link.includes('%BLOG_')) {
            item.link = ''
            if (
              item.subMenuItems?.some(subItem =>
                subItem.link.includes('%BLOG_'),
              )
            ) {
              item.subMenuItems = item.subMenuItems?.map(subItem => {
                if (subItem.link.includes('%BLOG_')) {
                  subItem.link = ''
                }
                return subItem
              })
            }
          }
          return item
        })
        updateProp('menuItems')(updatedMenuItems)
      }
    }
  }, [entity.menuItems, pageType, updateProp])

  const entityHasSubMenu = useMemo(
    () => entity.menuItems.some(item => hasSubMenu(item)),
    [entity.menuItems],
  )

  useEffect(() => {
    if (entityHasSubMenu) {
      if (entity.subMenuSpacing === undefined) {
        updateProp('subMenuSpacing')(15)
      }
      if (entity.mobileSubMenuSpacing === undefined) {
        updateProp('mobileSubMenuSpacing')(15)
      }
      if (!entity.subMenuColor) {
        updateProp('subMenuColor')('rgba(74, 74, 74, 1)')
      }
      if (!entity.activeSubmenuColor) {
        updateProp('activeSubmenuColor')('rgba(74, 74, 74, 1)')
      }
    }
  }, [
    entity.activeSubmenuColor,
    entity.mobileSubMenuSpacing,
    entity.subMenuColor,
    entity.subMenuSpacing,
    entityHasSubMenu,
    updateProp,
  ])

  return (
    <div>
      <MenuBurger update={updateProp} entity={entity} />
      {isMobile && entity.burger && (
        <React.Fragment>
          <FlexAlignStyle
            label="entity_settings.menu.item_align"
            mobileAlign={entity.burgerAlignSelf || 'flex-end'}
            mobileUpdate={updateProp('burgerAlignSelf')}
          />
          <Select
            choiceList={mobileMenuTypesLocoKeys}
            update={updateProp('mobileMenuType')}
            value={entity.mobileMenuType}
          />
          {entity.mobileMenuType === MobileMenuTypesEnum.side && (
            <ColorPicker
              update={updateProp('sideMenuBackgroundColor')}
              color={entity.sideMenuBackgroundColor}
              label="entity_settings.menu.item.side_menu_background_color"
              singleColorMode
            />
          )}
          {entity.mobileMenuType === MobileMenuTypesEnum.side && (
            <Switcher
              firstLabel="entity_settings.menu.item.emulate_side_menu_opened"
              secondLabel="entity_settings.menu.item.emulate_side_menu_closed"
              handleFirstButton={toggleSideMenuOpened}
              handleSecondButton={toggleSideMenuOpened}
              isFirstActive={isSideMenuOpened}
              isSecondActive={!isSideMenuOpened}
              description="entity_settings.menu.item.emulate_side_menu_description"
            />
          )}
          {entity.mobileMenuType === MobileMenuTypesEnum.dropDown && (
            <Switcher
              firstLabel="entity_settings.menu.item.emulate_drop_down_menu_opened"
              secondLabel="entity_settings.menu.item.emulate_drop_down_menu_closed"
              handleFirstButton={toggleDropDownMenuOpened}
              handleSecondButton={toggleDropDownMenuOpened}
              isFirstActive={isDropDownMenuOpened}
              isSecondActive={!isDropDownMenuOpened}
              description="entity_settings.menu.item.emulate_drop_down_menu_description"
            />
          )}
        </React.Fragment>
      )}
      <GroupTitle>entity_settings.menu.group.items</GroupTitle>
      {entity.menuItems.map((item, index, arr) => (
        <React.Fragment key={index}>
          <MenuItem
            index={index}
            item={item}
            entity={entity}
            itemsLength={arr.length}
            isAllowedBlogLinks={allowedBlogLinksPageTypes.includes(pageType)}
          />
          {index + 1 !== arr.length && <Divider />}
        </React.Fragment>
      ))}
      <Button onClick={addMenuItem}>entity_settings.menu.item.add</Button>
      <FlexAlignStyle
        label="entity_settings.menu.item_align"
        mobileAlign={entity.mobileAlignSelf}
        align={entity.alignSelf}
        update={updateProp('alignSelf')}
        mobileUpdate={updateProp('mobileAlignSelf')}
      />
      <MenuSpacing
        spacing={entity.spacing}
        mobileSpacing={entity.mobileSpacing}
        update={updateProp('spacing')}
        updateMobile={updateProp('mobileSpacing')}
        subMenuSpacing={entity.subMenuSpacing}
        mobileSubMenuSpacing={entity.mobileSubMenuSpacing}
        updateSubMenu={updateProp('subMenuSpacing')}
        updateMobileSubMenu={updateProp('mobileSubMenuSpacing')}
        hasSubMenu={entityHasSubMenu}
      />
      <GroupTitle>typography</GroupTitle>
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <LineHeight
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
      />
      <TextFontUpdater<MenuInterface> entity={entity} update={updateEntity} />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
        label="entity_settings.menu.item.color"
      />
      <ColorPicker
        update={updateProp('activeColor')}
        mobileUpdate={updateProp('mobileActiveColor')}
        color={entity.activeColor}
        mobileColor={entity.mobileActiveColor}
        label="entity_settings.menu.item.active"
      />
      {entityHasSubMenu && !isMobile && (
        <>
          <ColorPicker
            update={updateProp('subMenuColor')}
            color={entity.subMenuColor}
            label="entity_settings.menu.item.sub_item_color"
            singleColorMode
          />
          <ColorPicker
            update={updateProp('activeSubmenuColor')}
            color={entity.activeSubmenuColor}
            label="entity_settings.menu.item.sub_item_active_color"
            singleColorMode
          />
        </>
      )}
      <ColorPicker
        update={updateProp('itemUnderlineColor')}
        mobileUpdate={updateProp('mobileItemUnderlineColor')}
        color={entity.itemUnderlineColor}
        mobileColor={entity.mobileItemUnderlineColor}
        label="entity_settings.menu.item_underline.active_color"
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </div>
  )
}

export default MenuSettings
