import React from 'react'
import CaptchaPlaceHolderUi from 'common/components/entities/Recaptcha/ui/CaptchaPlaceholderUi'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { RecaptchaInterface } from 'common/types/entities/RecaptchaInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'

export default function Recaptcha({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<RecaptchaInterface>) {
  return (
    <>
      <BaseEntityNew
        entity={entity}
        isMoveDownAllowed={isMoveDownAllowed}
        isMoveUpAllowed={isMoveUpAllowed}
        flex
        flexColumn
      >
        <CaptchaPlaceHolderUi
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
          src={
            entity.theme === 'light'
              ? 'https://developers.google.com/recaptcha/images/light.png'
              : 'https://developers.google.com/recaptcha/images/dark.png'
          }
        />
      </BaseEntityNew>
    </>
  )
}

export function createRecaptcha(
  type: EntityTypeEnum.OptInRecaptcha | EntityTypeEnum.ContactUsRecaptcha,
  parentId: string,
  masterBlockId?: string,
) {
  const recaptcha: RecaptchaInterface = {
    id: generateId(),
    type: type,
    parentId: parentId,
    masterBlockId: masterBlockId,
    slug: FieldSlugEnum.Recaptcha,
    theme: 'light',
    alignSelf: 'flex-start',
    appearance: {
      desktop: true,
      mobile: true,
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    htmlAttrId: generateHtmlAttrId(type),
  }

  return recaptcha
}
