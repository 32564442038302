import { Table } from 'dexie'
import { PresentPage } from 'client/store'
import { FilesState } from 'client/store/files/filesReducer'

export interface PagesInterface {
  id?: number
  page_id: number
  page: Pick<
    PresentPage,
    | 'entities'
    | 'seo'
    | 'globalSettings'
    | 'parentGlobalSettings'
    | 'lastPopupNumber'
    | 'lastFormNumber'
  >
  files: FilesState
}

export type PagesTableType = {
  pages: Table<PagesInterface>
}

export const pagesSchema = {
  pages: '++id, page_id, page, files',
}
