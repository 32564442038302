import React from 'react'
import EmptyListIcon from './icons/EmptyListIcon'
import EmptyListTextWrapperUi from './ui/EmptyListTextWrapperUi'
import EmptyListTitleUi from './ui/EmptyListTitleUi'
import EmptyListWrapperUi from './ui/EmptyListWrapperUi'

interface EmptyListProps {
  title?: string
  subTitle: string
}

function EmptyList({ title, subTitle }: EmptyListProps) {
  return (
    <EmptyListWrapperUi>
      <EmptyListIcon />
      <EmptyListTextWrapperUi>
        {title && <EmptyListTitleUi>{title}</EmptyListTitleUi>}
        {subTitle && <span>{subTitle}</span>}
      </EmptyListTextWrapperUi>
    </EmptyListWrapperUi>
  )
}
export default EmptyList
