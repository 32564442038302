import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import FileManager from 'client/components/FileManager/FileManager'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'
import { getFileById } from 'client/reducers/filesReducer'

const StyledDiv = styled.div`
  position: relative;
  height: 42px;
`

const StyledButton = styled.button`
  float: right;
  width: 40px;
  height: 42px;
  color: #9ba9b3;
  position: absolute;
  right: 0;
  border: solid 1px #ebedf0;
  border-radius: 0 4px 4px 0;
  outline: none;
  cursor: pointer;
`

const StyledIcon = styled.i`
  font-size: 16px;
`

const StyleInputText = styled.input`
  width: 100%;
  position: absolute;
  left: 0;
  padding-right: 70px;
  outline: none;
  float: left;
  height: 42px;
  font-size: 14px;
  border-radius: 3px;
  color: #5c687e;
  border: solid 1px #ebedf0;
  text-indent: 10px;
  text-overflow: ellipsis;
`

const RemoveFileIcon = styled.i`
  position: absolute;
  right: 50px;
  top: 13px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff;
`

class FileUpload extends Component {
  state = {
    showFileManager: this.props.instantUploadEnabled,
  }

  componentWillUnmount() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  openFileManager = () => {
    this.setState({ showFileManager: true })
  }

  closeFileManager = () => {
    this.setState({ showFileManager: false })
  }

  render() {
    const {
      removeFile,
      label,
      currentFile,
      directLink,
      afterUpload,
      mimeTypes,
      helpMessage,
      placeholder,
    } = this.props
    let fileName = ''
    // eslint-disable-next-line no-useless-escape
    const regexp = /^.*[\/]/

    if (currentFile) {
      fileName = currentFile.path
        .replace(regexp, '')
        .split('_')
        .slice(1)
        .join('_')
    } else if (directLink) {
      fileName = directLink
    }

    return (
      <OptionWrapper labelText={label} helpMessage={helpMessage}>
        <StyledDiv>
          <StyleInputText
            value={fileName}
            onChange={e => this.props.changeLinkHandler(e.target.value)}
            readOnly={this.props.readonly}
            placeholder={placeholder}
          />
          {fileName && removeFile && (
            <RemoveFileIcon className="fa fa-times" onClick={removeFile} />
          )}
          <StyledButton onClick={this.openFileManager}>
            <StyledIcon
              className="fas fa-cloud-upload-alt"
              aria-hidden="true"
            />
          </StyledButton>
        </StyledDiv>
        {this.state.showFileManager && (
          <FileManager
            closeFileManager={this.closeFileManager}
            mimeTypes={mimeTypes}
            fileType={this.props.fileType}
            onInsert={afterUpload}
          />
        )}
      </OptionWrapper>
    )
  }
}

FileUpload.propTypes = {
  directLink: PropTypes.string,
  fileId: PropTypes.objectOf(PropTypes.any),
  currentFile: PropTypes.objectOf(PropTypes.any),
  mimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  afterUpload: PropTypes.func.isRequired,
  removeFile: PropTypes.func,
  label: PropTypes.string,
  readonly: PropTypes.bool,
  changeLinkHandler: PropTypes.func,
  fileType: PropTypes.string,
  helpMessage: PropTypes.string,
  instantUploadEnabled: PropTypes.bool,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
}

FileUpload.defaultProps = {
  directLink: '',
  currentFile: null,
  fileId: null,
  label: 'File',
  removeFile: null,
  readonly: true,
  changeLinkHandler: null,
  fileType: null,
  helpMessage: '',
  instantUploadEnabled: false,
  onClose: null,
  placeholder: '',
}

const mapStateToProps = ({ files }, { fileId }) => ({
  currentFile: getFileById(files, fileId),
})

export default connect(mapStateToProps)(FileUpload)
