import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  STEP_OPT_IN,
  STEP_PAYMENT,
} from 'common/components/entities/TwoStepPaymentForm'
import { useActions } from 'common/hooks/useActions'
import { changeTwoStepPaymentFormStepType } from 'client/actions/actionsManagement'
import useManagement, {
  selectors as managementSelectors,
} from 'client/hooks/useManagement'
import usePresentPage, {
  selectors as pageSelectors,
} from 'client/hooks/usePresentPage'
import Input from '../../components/Input/Input'
import Switcher from '../../components/Switcher'
import useUpdateEntityOption from '../../hooks/useUpdateEntityOption'

function EditStepSwitcher({ optInStepEntityId, paymentStepEntityId }) {
  const stepType = useManagement(
    managementSelectors.getTwoStepPaymentFormStepType,
  )
  const changePreviewStep = useActions(changeTwoStepPaymentFormStepType)
  const stepOptIn = usePresentPage(page =>
    pageSelectors.getEntityById(page, optInStepEntityId),
  )
  const stepPayment = usePresentPage(page =>
    pageSelectors.getEntityById(page, paymentStepEntityId),
  )
  const dispatch = useDispatch()
  const updateOptInEntityOption = useUpdateEntityOption(dispatch, stepOptIn)
  const updatePaymentEntityOption = useUpdateEntityOption(dispatch, stepPayment)
  const isStepOptIn = stepType === STEP_OPT_IN
  const isStepPayment = stepType === STEP_PAYMENT
  return (
    <React.Fragment>
      <Switcher
        handleFirstButton={() => changePreviewStep(STEP_OPT_IN)}
        isFirstActive={isStepOptIn}
        firstLabel="entity_settings.two_step_payment_form.switch_opt_in_step"
        handleSecondButton={() => changePreviewStep(STEP_PAYMENT)}
        isSecondActive={isStepPayment}
        secondLabel="entity_settings.two_step_payment_form.switch_payment_step"
      />
      {isStepOptIn && (
        <React.Fragment>
          <Input
            value={stepOptIn.options.headline}
            update={updateOptInEntityOption('headline')}
            label="entity_settings.two_step_payment_form.headline"
          />
          <Input
            value={stepOptIn.options.subHeadline}
            update={updateOptInEntityOption('subHeadline')}
            label="entity_settings.two_step_payment_form.sub_headline"
          />
          <Input
            value={stepOptIn.options.buttonText}
            update={updateOptInEntityOption('buttonText')}
            label="entity_settings.two_step_payment_form.step_button_text"
          />
          <Input
            value={stepOptIn.options.buttonSubText}
            update={updateOptInEntityOption('buttonSubText')}
            label="entity_settings.two_step_payment_form.step_button_sub_text"
          />
          <Input
            value={stepOptIn.options.footerText}
            update={updateOptInEntityOption('footerText')}
            label="entity_settings.two_step_payment_form.footer_text"
          />
        </React.Fragment>
      )}
      {isStepPayment && (
        <React.Fragment>
          <Input
            value={stepPayment.options.headline}
            update={updatePaymentEntityOption('headline')}
            label="entity_settings.two_step_payment_form.headline"
          />
          <Input
            value={stepPayment.options.subHeadline}
            update={updatePaymentEntityOption('subHeadline')}
            label="entity_settings.two_step_payment_form.sub_headline"
          />
          <Input
            value={stepPayment.options.buttonText}
            update={updatePaymentEntityOption('buttonText')}
            label="entity_settings.two_step_payment_form.step_button_text"
          />
          <Input
            value={stepPayment.options.buttonSubText}
            update={updatePaymentEntityOption('buttonSubText')}
            label="entity_settings.two_step_payment_form.step_button_sub_text"
          />
          <Input
            value={stepPayment.options.footerText}
            update={updatePaymentEntityOption('footerText')}
            label="entity_settings.two_step_payment_form.footer_text"
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

EditStepSwitcher.propTypes = {
  optInStepEntityId: PropTypes.string.isRequired,
  paymentStepEntityId: PropTypes.string.isRequired,
}

export default EditStepSwitcher
