import PropTypes from 'prop-types'
import React, { memo, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useActions } from 'common/hooks/useActions'
import { showCreateBlockDialog } from 'client/actions/actionsManagement'
import * as entityActions from 'client/actions/entityActions'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useSidebarVisibilityActionsContext } from 'client/context/SidebarVisibilityContext'
import {
  getIsEditing,
  isBlockSaveDialogOpened,
} from 'client/reducers/managementReducer'
import { getAllDescendantIds } from 'client/reducers/pageReducer'
import { useHighlightedEntityId } from '../../../context/HighlightedContext'
import { useIsRowResizing } from '../../../context/ResizingRowContext'
import { setEditingEntity } from '../../../store/management/managementActions'
import ActionsBox from './ActionsBox'
import DeviceWarning from './DeviceWarning'
import ReadOnlyEntity from './ReadOnlyEntity'
import BaseEntityUi from './ui/BaseEntityUi'
import * as utils from './utils'

export const color = {
  blue: '#149EFC',
  green: '#56C098',
  orange: '#F78828',
  gray: '#5A6E73',
}

function BaseEntity({
  entity,
  hoverColor,
  children,
  outline,
  highlightedColor,
  warningColor,
  mobileEmulationStyles,
  isSaveAllowed,
  isRemoveAllowed,
  isCopyAllowed,
  styles,
  isMoveDownAllowed,
  isMoveUpAllowed,
  dropBoxAllowedEntityIds,
  externalActionsRef,
}) {
  const dispatch = useDispatch()
  const toggleableNodes = []
  const actionsBoxRef = useRef(null)
  const [showMenu, setShowMenu] = useState(false)
  const highlightedId = useHighlightedEntityId()
  const isResizing = useIsRowResizing()
  const { isMobile } = useDeviceModeContext()
  const { showSidebar } = useSidebarVisibilityActionsContext()
  const isHighlighted = highlightedId === entity.id
  const deviceWarning = utils.getDeviceWarning(
    entity.options.appearance,
    isMobile,
  )
  const currentRef = externalActionsRef ? externalActionsRef : actionsBoxRef
  const {
    styles: {
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      display,
      ...restStyles
    },
    ...emulatedEntity
  } = utils.getEmulatedEntity(entity, isMobile, mobileEmulationStyles)
  const isEditing = useSelector(
    state => getIsEditing(state.management, entity.id),
    shallowEqual,
  )
  const isCreateBlockDialogActive = useSelector(
    state => isBlockSaveDialogOpened(state.management, entity.id),
    shallowEqual,
  )
  const descendantIds = useSelector(
    state => getAllDescendantIds(state.page.present, entity.id),
    shallowEqual,
  )
  const [, drag] = useDrag({
    item: {
      entity,
      type: entity.type,
      descendantIds,
      allowedEntityIds: dropBoxAllowedEntityIds,
    },
    canDrag: () => {
      return !isEditing && !isResizing
    },
  })
  // const toggleSettingsAction = useActions(toggleSettings)
  const showCreateBlockDialogAction = useActions(showCreateBlockDialog)
  const copy = useActions(entityActions.copy)
  const remove = useActions(entityActions.remove)
  const moveDown = useActions(entityActions.moveDown)
  const moveUp = useActions(entityActions.moveUp)

  const handleToggleSettings = e => {
    e.stopPropagation()
    if (e.target.nodeName !== 'INPUT') {
      e.preventDefault()
    }
    if (
      toggleableNodes.length > 0 &&
      utils.notContainsSettingsButton(e, currentRef)
    ) {
      if (toggleableNodes.indexOf(e.target) > -1) {
        dispatch(setEditingEntity(entity.id))
        showSidebar()
      }
    } else {
      if (!isResizing && utils.notContainActionButtons(e, currentRef)) {
        dispatch(setEditingEntity(entity.id))
        showSidebar()
      }
    }
  }

  const addToggleableNode = node => toggleableNodes.push(node)

  const hover = e => {
    e.stopPropagation()
    if (isResizing) {
      return
    }
    setShowMenu(true)
  }

  const unHover = e => {
    e.stopPropagation()
    if (isResizing) {
      return
    }

    if (utils.relatedTargetNotContainsActionButtons(e, currentRef)) {
      setShowMenu(false)
    }
  }

  const getColor = () => {
    if (isHighlighted || isEditing) {
      return highlightedColor
    }

    if (showMenu) {
      return hoverColor
    }

    if (deviceWarning) {
      return warningColor
    }

    return null
  }

  function handleMoveDown(e) {
    e.stopPropagation()
    moveDown(entity)
  }

  function handleMoveUp(e) {
    e.stopPropagation()
    moveUp(entity)
  }

  if (entity.isReadOnly) {
    const margin = {
      marginTop: marginTop ? parseInt(marginTop) : 0,
      marginRight: marginRight ? parseInt(marginRight) : 0,
      marginBottom: marginBottom ? parseInt(marginBottom) : 0,
      marginLeft: marginLeft ? parseInt(marginLeft) : 0,
    }
    const { appearance = {} } = entity.options
    return typeof children === 'function' ? (
      <ReadOnlyEntity
        margin={margin}
        mobileMargin={margin}
        styles={styles}
        showInMobileMode={appearance.mobile}
        showInDesktopMode={appearance.desktop}
      >
        {children(
          { ...emulatedEntity, styles: restStyles },
          addToggleableNode,
          isMobile,
        )}
      </ReadOnlyEntity>
    ) : (
      <ReadOnlyEntity
        margin={margin}
        mobileMargin={margin}
        styles={styles}
        showInMobileMode={appearance.mobile}
        showInDesktopMode={appearance.desktop}
      >
        {children}
      </ReadOnlyEntity>
    )
  }

  return (
    <BaseEntityUi
      id={entity.id}
      color={getColor()}
      onClick={handleToggleSettings}
      onMouseOver={hover}
      onMouseOut={unHover}
      ref={drag}
      outline={outline}
      mode={utils.getMode(isEditing, showMenu, isHighlighted, deviceWarning)}
      resrtictPointerEvents={isCreateBlockDialogActive}
      styles={{
        ...styles,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        ...(display ? { display } : {}), // it can override display from styles
      }}
      isEditing={isEditing}
    >
      {showMenu && (
        <ActionsBox
          ref={currentRef}
          type={entity.type}
          copy={entity.isMasterBlockRoot ? false : () => copy(entity)}
          toggleSettings={handleToggleSettings}
          toggleSaveDialog={() => showCreateBlockDialogAction(entity.id)}
          remove={() => remove(entity)}
          backgroundColor={hoverColor}
          isSaveAllowed={isSaveAllowed}
          isMasterBlock={Boolean(entity.masterBlockId)}
          isMoveDownAllowed={isMoveDownAllowed}
          isMoveUpAllowed={isMoveUpAllowed}
          isRemoveAllowed={isRemoveAllowed}
          isCopyAllowed={isCopyAllowed}
          moveDown={handleMoveDown}
          moveUp={handleMoveUp}
        />
      )}
      {deviceWarning && <DeviceWarning deviceWarning={deviceWarning} />}
      {typeof children === 'function'
        ? children(
            { ...emulatedEntity, styles: restStyles },
            addToggleableNode,
            isMobile,
          )
        : children}
    </BaseEntityUi>
  )
}

BaseEntity.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    isReadOnly: PropTypes.bool,
    options: PropTypes.object,
    styles: PropTypes.object,
    masterBlockId: PropTypes.number,
    isMasterBlockRoot: PropTypes.bool,
  }).isRequired,
  hoverColor: PropTypes.string,
  highlightedColor: PropTypes.string,
  warningColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  outline: PropTypes.bool,
  isSaveAllowed: PropTypes.bool,
  mobileEmulationStyles: PropTypes.objectOf(PropTypes.any),
  mobileEmulationOptions: PropTypes.objectOf(PropTypes.any),
  styles: PropTypes.objectOf(PropTypes.any),
  isMoveUpAllowed: PropTypes.bool.isRequired,
  isMoveDownAllowed: PropTypes.bool.isRequired,
  isRemoveAllowed: PropTypes.bool,
  isCopyAllowed: PropTypes.bool,
  dropBoxAllowedEntityIds: PropTypes.arrayOf(PropTypes.string),
  externalActionsRef: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
}

BaseEntity.defaultProps = {
  highlightedColor: color.blue,
  hoverColor: color.orange,
  warningColor: '#EB5447',
  outline: false,
  isSaveAllowed: true,
  mobileEmulationStyles: {},
  mobileEmulationOptions: {},
  styles: {},
  isRemoveAllowed: true,
  isCopyAllowed: true,
}

export default memo(BaseEntity)
