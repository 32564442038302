import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import structureTypes from 'common/constants/structureTypes'
import * as types from 'common/types/index'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { getByType } from 'client/reducers/pageReducer'
import TextFont from '../components/Font/TextFont'
import { Input } from '../components/index'
import { Attributes, DelayOption, DeviceAppearance, FontSize } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { BorderStyle, CornerStyle, MarginsStyle, PaddingStyle } from '../styles'
import ButtonWidth from '../styles/ButtonWidth'
import FlexAlignStyle from '../styles/FlexAlignStyle'

function WebinarCallToActionSettings(props) {
  const {
    entity,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateMobileOption,
    toggleOverlay,
    overlayVisible,
    update,
  } = props

  const { isMobile } = useDeviceModeContext()

  const handleFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            fontFamily: fontProperties.fontFamily,
          },
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.fontFileId
        ) {
          delete updatedEntity.mobileOptions.fontFileId
        }
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            ...fontProperties,
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            fontFamily: fontProperties.fontFamily,
          },
          options: {
            ...updatedEntity.options,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (updatedEntity.options.fontFileId) {
          delete updatedEntity.options.fontFileId
        }
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            ...fontProperties,
          },
        })
      }
    }
  }

  const fontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const fontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const fontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  return (
    <React.Fragment>
      <GroupTitle>typography</GroupTitle>
      <FlexAlignStyle
        align={entity.styles.alignSelf}
        mobileAlign={entity.mobileStyles.alignSelf}
        update={updateStyle('alignSelf')}
        mobileUpdate={updateMobileStyle('alignSelf')}
      />
      <ButtonWidth
        update={updateStyle('width')}
        mobileUpdate={updateMobileStyle('width')}
        width={entity.styles.width}
        mobileWidth={entity.mobileStyles.width}
      />
      <FontSize
        update={updateStyle('fontSize', 'px')}
        mobileUpdate={updateMobileStyle('fontSize', 'px')}
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
      />
      <TextFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <Input
        labelText="button text"
        update={updateOption('text')}
        value={entity.options.text}
      />
      <Input
        labelText="url"
        update={updateOption('url')}
        placeholder="entity_settings.webinar_session_call_to_action.url_input.placeholder"
        value={entity.options.url}
      />
      <DelayOption
        update={updateOption('delay')}
        delay={entity.options.delay}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="background color"
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        color={entity.styles.backgroundColor}
        mobileColor={entity.mobileStyles.backgroundColor}
      />
      <ColorPicker
        label="text color"
        update={updateStyle('color')}
        mobileUpdate={updateMobileStyle('color')}
        color={entity.styles.color}
        mobileColor={entity.mobileStyles.color}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <BorderStyle
        updateOption={updateOption}
        updateMobileOption={updateMobileOption}
        updateStyle={updateStyle}
        updateMobileStyle={updateMobileStyle}
        type={entity.options.borderType}
        style={entity.styles.borderStyle}
        width={entity.styles.borderWidth}
        color={entity.styles.borderColor}
        mobileType={entity.mobileOptions.borderType}
        mobileStyle={entity.mobileStyles.borderStyle}
        mobileWidth={entity.mobileStyles.borderWidth}
        mobileColor={entity.mobileStyles.borderColor}
        toggleOverlay={toggleOverlay}
        overlayVisible={overlayVisible}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

WebinarCallToActionSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ page: { present: presentPage } }) => ({
  popups: getByType(presentPage, structureTypes.POPUP),
})

export default connect(mapStateToProps)(WebinarCallToActionSettings)
