import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { InternalError, NetworkError } from 'common/errors'
import saveUserColors from 'client/api/userApi'
import useManagement from 'client/hooks/useManagement'
import { getCustomColors } from 'client/reducers/managementReducer'
import { setUserColors } from 'client/store/management/managementActions'
import Swatch from './Swatch'
import { HSLColorType, RGBColorType, HSVColorType } from './index'
import ColorsWrapperUi from './ui/ColorsWrapperUi'
import SwatchWrapperUi from './ui/SwatchWrapperUi'

type customPresetColorsPropsType = {
  onClick: (
    color: string | HSLColorType | RGBColorType | HSVColorType,
    event: React.MouseEvent<HTMLElement>,
  ) => void
  activeColor: string
}

function CustomPresetColors({
  onClick,
  activeColor,
}: customPresetColorsPropsType) {
  const dispatch = useDispatch()
  const userColors = useManagement(getCustomColors)
  const initialUserPalette = [
    ...userColors,
    ...Array(8 - userColors.length).fill('transparent'),
  ]

  const [userPallete, setUserPallete] = useState<string[]>(initialUserPalette)
  const [activeColorIndex, setActiveColorIndex] = useState<null | number>(null)
  const refColors = useRef(userPallete)

  function handleClick(index: number) {
    return (rgba: string, e: React.MouseEvent<HTMLElement>) => {
      if (rgba === 'transparent') {
        if (index === activeColorIndex) {
          setActiveColorIndex(null)
          return
        }
        const newPallete = [...userPallete]
        newPallete[index] = activeColor
        setUserPallete(newPallete)
        setActiveColorIndex(index)
        return
      }
      setActiveColorIndex(index)
      if (index === activeColorIndex) {
        setActiveColorIndex(null)
        return
      }
      onClick(rgba, e)
    }
  }

  useEffect(() => {
    refColors.current = userPallete
  }, [userPallete])

  useEffect(() => {
    return () => {
      dispatch(setUserColors(refColors.current))
      ;(async () => {
        if (initialUserPalette !== refColors.current) {
          try {
            await saveUserColors(refColors.current)
          } catch (error) {
            if (error instanceof NetworkError) {
              // do nothing
            } else if (error instanceof InternalError) {
              ;(window as any).Rollbar.error(error)
            }
          }
        }
      })()
    }
  }, [])

  return (
    <ColorsWrapperUi className="flexbox-fix">
      {userPallete.map((colorObjOrString, index) => {
        const c =
          typeof colorObjOrString === 'string'
            ? { color: colorObjOrString }
            : colorObjOrString
        return (
          <SwatchWrapperUi key={index}>
            <Swatch
              {...c}
              onClick={handleClick(index)}
              activeColor={activeColor}
              activeColorIndex={activeColorIndex}
              userPallete={userPallete}
              setUserPallete={setUserPallete}
              title={c.color}
              index={index}
            />
          </SwatchWrapperUi>
        )
      })}
    </ColorsWrapperUi>
  )
}

export default CustomPresetColors
