import styled from 'styled-components'

const Input = styled.input<{ flexBasis?: number }>`
  width: 100%;
  font-size: 14px;
  padding: 12px 0;
  outline: none;
  transition: all 0.3s ease;
  color: var(--default-text-color);
  border: solid 1px #ebedf0;
  border-radius: 3px;
  /* stylelint-disable */
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* stylelint-disable */
  box-shadow: inset 0 1px 2px rgba(130, 137, 150, 0.23),
    0 1px 0 rgba(255, 255, 255, 0.95);
  text-indent: 10px;
  &::placeholder {
    color: #cccccc;
  }
  &:focus {
    border-color: #149efc;
  }

  ${p => p.flexBasis && `flex-basis: ${p.flexBasis}px`}
`

export default Input
