import styled from 'styled-components'

const BreadcrumbsDelimiterUi = styled.i`
  position: absolute;
  background-color: transparent;
  width: 0;
  height: 0;
  right: -15px;
  top: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  border-left: 15px solid #142d63;;
  border-right: 0 solid #ccc;
  z-index: 9;
  &:after {
    content: '';
    position: absolute;
    left: -15px;
    top: -22px;
    width: 0;
    height: 0;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 15px solid #142d63;;
    border-right: 0 solid #acc9de;
    cursor: ${p => (p.last ? 'default' : 'pointer')};
    border-left-color: ${p => (p.last ? '#149efc' : 'transparent')};
  }
  span:hover > &:after {
    border-left-color: ${p => (p.last ? '#149efc' : '#acc9de')};
  }
`

export default BreadcrumbsDelimiterUi
