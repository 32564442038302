import React from 'react'
import EntityInterface from 'common/types/entities/EntityInterface'
import { Appearance } from 'common/types/entities/EntityInterface'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from '../hooks/useUpdateProps'
import DeviceAppearance from '../options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../options/HtmlAttrId'
import MarginStyle from '../styles/MarginStyle'

type OfferThankYouOrderSummarySettingsNewProps = {
  entity: EntityInterface
}

function OfferThankYouOrderSummarySettingsNew({
  entity,
}: OfferThankYouOrderSummarySettingsNewProps) {
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<EntityInterface>()
  const updateProp = useUpdateProp(entity)

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }
  return (
    <>
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default OfferThankYouOrderSummarySettingsNew
