import React from 'react'

function AddTagIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="43"
      height="36"
      viewBox="0 0 43 36"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        stroke={'none'}
        d="M19.8215 35.0926L1.23971 16.2129C0.965302 15.8379 0.820312 15.3926 0.820312 14.9249V2.66362C0.820312 2.08549 1.04176 1.5421 1.44386 1.13356C1.84682 0.725 2.38163 0.5 2.94977 0.5H15.0176C15.6891 0.50088 16.3284 0.82857 16.7276 1.37656L27.2326 12.0505C25.0306 13.9778 23.7678 16.7746 23.7678 19.7251C23.7678 23.7767 26.1355 27.4293 29.8 29.0305C29.6392 29.2038 29.494 29.3552 29.3566 29.4948C26.7197 32.1732 23.4226 34.1089 19.822 35.0925L19.8215 35.0926ZM10.6579 6.44148C9.59225 6.44236 8.59058 6.86431 7.83736 7.6296C7.08415 8.3949 6.66886 9.41263 6.66799 10.4953C6.66799 11.5776 7.08267 12.596 7.83565 13.3628C8.57766 14.1175 9.60538 14.5509 10.6553 14.5518C11.7235 14.5518 12.7273 14.1302 13.4818 13.3645C14.2361 12.5971 14.6511 11.5773 14.6503 10.4927C14.6494 9.42597 14.2229 8.38176 13.48 7.62787C12.7262 6.86282 11.7239 6.44149 10.6578 6.44149L10.6579 6.44148Z"
      />
      <path
        fillRule="evenodd"
        stroke={'none'}
        clipRule="evenodd"
        d="M34.3106 11.0938C29.5693 11.0938 25.8008 14.8622 25.8008 19.6036C25.8008 24.345 29.5693 28.1135 34.3106 28.1135C39.0521 28.1135 42.8205 24.345 42.8205 19.6036C42.8205 14.8622 39.0521 11.0938 34.3106 11.0938ZM39.1734 20.8193H35.5264V24.4664H33.0949V20.8193H29.4479V18.388H33.0949V14.7409H35.5264V18.388H39.1734V20.8193Z"
      />
    </svg>
  )
}

export default AddTagIcon
