import styled from 'styled-components'

const SelectUi = styled.select.attrs(props => ({
  'data-test-element': props.dataTestElement || '',
}))`
  width: 100%;
  height: 40px;
  padding: 10px;
  font-size: 13px;
  background-color: #fff;
  border: solid 1px #ebedf0;
  border-radius: 3px;
  color: #5c687e;
  -moz-appearance: none;
`

export default SelectUi
