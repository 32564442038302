import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { StylesConfig } from 'react-select'
import FormGroup from 'common/components/core/FormGroup'
import Input from 'common/components/core/Input'
import InputLabel from 'common/components/core/InputLabel'
import { QuestionPropType } from 'common/components/entities/Survey'
import {
  SurveyAnswer,
  SurveyQuestionType,
} from 'common/types/entities/SurveyInterface'
import { useUserResources } from 'client/context/UserResourcesContext'
import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'
import { SurveyTagOptionType } from 'client/types/UserResourcesInterface'
import { Checkbox } from '../../components'
import ReactSelect, {
  ChangeOptionValue,
} from '../../components/ReactSelect/ReactSelect'
import { inputAnswerSize, inputOpenAnswerSize } from './constants/constants'
import AnswerGroupTitleUi from './ui/AnswerGroupTitelUi'
import AnswerGroupUi from './ui/AnswerGroupUi'
import AnswerInputGroupUi from './ui/AnswerInputGroupUi'
import AnswerNumberUi from './ui/AnswerNumberUi'
import AnswerRemoveUi from './ui/AnswerRemoveUi'

type QuestionProps = {
  question: SurveyQuestionType
  update: (question: SurveyQuestionType) => void
}

const colorPickerSelectModeStyles: StylesConfig<SurveyTagOptionType> = {
  valueContainer: inheritedStyles => ({
    ...inheritedStyles,
    padding: '7px 8px 8px 8px',
  }),
}

function Question({ question, update }: QuestionProps) {
  const { t } = useTranslation()
  const { setShouldFetch, userResources, isResourceLoading } =
    useUserResources()
  function updateQuestionTitle(e: React.ChangeEvent<HTMLInputElement>) {
    update({
      ...question,
      title: e.target.value,
    })
  }
  const userTagsOptions: SurveyTagOptionType[] = [
    {
      label: t('entity_settings.common.select.no_tag_option'),
      value: null,
    },
    ...userResources.userTags,
  ]

  function getSelectedOption(answer: SurveyAnswer) {
    if (answer.tagId) {
      const tag = userTagsOptions.find(item => item.value === answer.tagId)
      if (tag) {
        return {
          value: tag.value,
          label: tag.label,
        }
      } else {
        return {
          value: null,
          label: t(
            'entity_settings.survey.question_edit.answers_select.no_tag',
          ),
        }
      }
    } else {
      return {
        value: null,
        label: t('entity_settings.survey.question_edit.answers_select.no_tag'),
      }
    }
  }

  function updateQuestionSubTitle(e: React.ChangeEvent<HTMLInputElement>) {
    update({
      ...question,
      subTitle: e.target.value,
    })
  }

  function updateAnswerTitle(index: number) {
    return function (e: React.ChangeEvent<HTMLInputElement>) {
      update({
        ...question,
        answers: [
          ...question.answers.slice(0, index),
          { ...question.answers[index], title: e.target.value },
          ...question.answers.slice(index + 1),
        ],
      })
    }
  }

  function updateOpenAnswer(index: number) {
    return function (value: boolean) {
      update({
        ...question,
        answers: question.answers.map((answer, indexAnswer) => {
          if (index === indexAnswer && value) {
            return {
              id: answer.id,
              title: answer.title,
              isOpen: value,
            }
          } else {
            return {
              id: answer.id,
              title: answer.title,
            }
          }
        }),
      })
    }
  }

  function updateAnswerTag(index: number) {
    return function (data: ChangeOptionValue<number | null, false>) {
      if (!data) return
      if (data.value !== null) {
        update({
          ...question,
          answers: [
            ...question.answers.slice(0, index),
            { ...question.answers[index], tagId: data.value },
            ...question.answers.slice(index + 1),
          ],
        })
      } else {
        update({
          ...question,
          answers: [
            ...question.answers.slice(0, index),
            {
              id: question.answers[index].id,
              title: question.answers[index].title,
            },
            ...question.answers.slice(index + 1),
          ],
        })
      }
    }
  }

  function removeAnswer(index: number) {
    return function () {
      update({
        ...question,
        answers: [
          ...question.answers.slice(0, index),
          ...question.answers.slice(index + 1),
        ],
      })
    }
  }

  useEffect(() => {
    setShouldFetch(prev => ({
      ...prev,
      [AutomationRuleActionsEnum.add_tag]: true,
    }))
  }, [])

  return (
    <React.Fragment>
      <FormGroup>
        <InputLabel>
          {t('entity_settings.survey.question_edit.title.label')}
        </InputLabel>
        <Input
          value={question.title}
          onChange={updateQuestionTitle}
          placeholder={t(
            'entity_settings.survey.question_edit.title_placeholder',
          )}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel>
          {t('entity_settings.survey.question_edit.sub_title.label')}
        </InputLabel>
        <Input
          value={question.subTitle}
          onChange={updateQuestionSubTitle}
          placeholder={t(
            'entity_settings.survey.question_edit.sub_title_placeholder',
          )}
        />
      </FormGroup>
      <AnswerGroupTitleUi>
        <InputLabel>
          {t('entity_settings.survey.question_edit.answers_header')}
        </InputLabel>
        <InputLabel>
          {t('entity_settings.survey.question_edit.answers_header_tags')}
        </InputLabel>
      </AnswerGroupTitleUi>
      {question.answers.map((answer, index) => (
        <AnswerGroupUi key={answer.id}>
          <AnswerNumberUi>{index + 1}.</AnswerNumberUi>
          <AnswerInputGroupUi
            flexBasis={answer.isOpen ? inputOpenAnswerSize : inputAnswerSize}
          >
            <Checkbox
              width={20}
              itemStyles={{
                marginBottom: '0px',
                padding: '0px',
                justifyContent: 'start',
                flexDirection: 'row-reverse',
              }}
              labelText={t('entity_settings.survey.question_edit.open_answer')}
              value={answer.isOpen === undefined ? false : answer.isOpen}
              update={updateOpenAnswer(index)}
            />
            <Input
              value={answer.title}
              onChange={updateAnswerTitle(index)}
              placeholder={t(
                'entity_settings.survey.question_edit.answer_placeholder',
              )}
            />
          </AnswerInputGroupUi>
          {!answer.isOpen && (
            <ReactSelect<number | null, false>
              selectedOption={getSelectedOption(answer)}
              update={updateAnswerTag(index)}
              styles={colorPickerSelectModeStyles}
              itemStyles={{
                flexBasis: '30%',
                marginBottom: '0',
                padding: `${
                  index !== 0 ? '0px 0px 0px 15px' : '0px 5px 0px 15px'
                }`,
              }}
              options={userTagsOptions}
              isLoading={isResourceLoading.add_tag}
              isMulti={false}
              menuPortalTarget={document.body}
            />
          )}
          {index !== 0 && (
            <AnswerRemoveUi
              className="far fa-trash-alt"
              onClick={removeAnswer(index)}
            />
          )}
        </AnswerGroupUi>
      ))}
    </React.Fragment>
  )
}

Question.propTypes = {
  question: QuestionPropType,
  number: PropTypes.number,
  update: PropTypes.func.isRequired,
}

export default Question
