import styled from 'styled-components'

const InlineFormUi = styled.div`
  min-height: 100%;
  height: min-content; /* @see why https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-contain;*/
  background-color: #8e9091;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ styles = {} }) => styles}
`

export default InlineFormUi
