import axios from 'axios'

export const optIn = (
  fields,
  datetime,
  timeZone,
  popupId,
  isDesktop,
  surveysResults,
  entityId,
) => {
  return axios.post(location.href, {
    optin: {
      fields,
      datetime,
      timeZone,
      popupId,
      isDesktop,
      surveysResults,
    },
  })
}
export const fetchIcons = async () => {
  return axios.get(
    `${process.env.CLOUDFRONT_PUBLISHER_ASSETS_HOST}/assets/data/fontAwesomeProIcons(5.11.0).json`,
  )
}
