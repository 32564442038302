import { useManagement } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { setDateWithTimezone } from 'client/utils/date-time-format'

interface UseDateWithTimezoneProps {
  date: Date
  onChange: (date: Date) => void
}

export const useDateWithTimezone = ({
  date,
  onChange,
}: UseDateWithTimezoneProps) => {
  const userTimeZone = useManagement(managementSelectors.getUserTimezone)
  const dateWithTimezone = setDateWithTimezone({
    date,
    timeZone: userTimeZone,
  })

  const onChangeWithTimezone = (date: Date) =>
    onChange(
      setDateWithTimezone({
        date,
        timeZone: userTimeZone,
        subtract: true,
      }),
    )

  return { dateWithTimezone, onChangeWithTimezone }
}
