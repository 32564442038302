import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import {
  Font,
  getFontDataWithActualFontLabel,
  getFontPropertiesFromFontFiles,
  validateRawCustomFontFile,
} from 'common/utils/fontsUtils'
import FileManager from 'client/components/FileManager/FileManager'
import { mimeTypes } from 'client/constants/editorSettings'
import { useCustomFonts } from 'client/context/CustomFontsContext'
import { DataFile } from 'client/store/files/filesReducer'
import ReactSelect, {
  ChangeOptionValue,
} from '../../components/ReactSelect/ReactSelect'
import CustomFontPickerContainerUi from './ui/CustomFontPickerContainerUi'
import CustomFontPickerSelectorContainerUi from './ui/CustomFontPickerSelectorContainerUi'
import CustomFontPickerUploadButtonUi from './ui/CustomFontPickerUploadButtonUi'
import CustomFontPickerUploadContainerUi from './ui/CustomFontPickerUploadContainerUi'
import CustomFontPickerUploadIconUi from './ui/CustomFontPickerUploadIconUi'

export const getFontFamilyOption = (font: Font) => ({
  value: font.id,
  label: font.fontLabel,
})

function CustomFontPicker({
  update,
  fontFamily,
}: {
  update: (customFontFamily: ChangeOptionValue<number>) => void
  fontFamily?: string
}) {
  const {
    userFonts,
    setUserFonts,
    setUserFontFiles,
    userFontFiles,
    isFetching,
  } = useCustomFonts()
  const [fileManagerOpen, setFileManagerOpen] = useState(false)
  const { t } = useTranslation()
  const getCustomFontFamilyOption = (fontFamily?: string) => {
    if (fontFamily) {
      const activeFontFamily = userFonts.find(
        el => wrapFontFamily(el.fullName) === fontFamily,
      )
      if (activeFontFamily) {
        return getFontFamilyOption(activeFontFamily)
      }
    }
    return null
  }

  function afterRemove(fileId: number) {
    const updatedUserFontsList = userFonts.filter(
      userFont => userFont.id !== fileId,
    )
    setUserFonts(updatedUserFontsList)
  }

  function updateFontFile(file: DataFile) {
    const userFontFile = userFonts.find(userFont => userFont.id === file.id)
    if (userFontFile) {
      update(getFontFamilyOption(userFontFile))
    }
  }

  async function afterFileUpload(file: DataFile) {
    const fontFileMeta = await getFontPropertiesFromFontFiles([file])
    const fontData = getFontDataWithActualFontLabel(fontFileMeta[0], userFonts)
    const updatedUserFontsList = [...userFonts, fontData]
    const uploadedUserFontFiles = {
      ...userFontFiles,
      [file.id]: file,
    }
    setUserFonts(updatedUserFontsList)
    setUserFontFiles(uploadedUserFontFiles)
  }

  function getCustomFileName(file: DataFile) {
    const userFontFile = userFonts.find(userFont => userFont.id === file.id)
    if (userFontFile) {
      return userFontFile.fontLabel
    }
    return ''
  }

  const validateUploadFile = async (file: File, mimeType: string) => {
    const validationResult = await validateRawCustomFontFile(file, mimeType)
    if (!validationResult) {
      toast.error(t('components.custom_font_picker.singe_file_parse_failed'))
    }
    return validationResult
  }

  return (
    <>
      <CustomFontPickerContainerUi>
        <CustomFontPickerSelectorContainerUi>
          <ReactSelect<number>
            update={update}
            selectedOption={getCustomFontFamilyOption(fontFamily)}
            options={
              userFonts
                ? userFonts.map((el: Font) => getFontFamilyOption(el))
                : []
            }
            isMulti={false}
            isLoading={isFetching}
          />
        </CustomFontPickerSelectorContainerUi>
        <CustomFontPickerUploadContainerUi>
          <CustomFontPickerUploadButtonUi
            onClick={() => setFileManagerOpen(true)}
            disabled={isFetching}
          >
            <CustomFontPickerUploadIconUi
              className="fas fa-cloud-upload-alt"
              aria-hidden="true"
            />
          </CustomFontPickerUploadButtonUi>
          {fileManagerOpen && (
            <FileManager
              closeFileManager={() => setFileManagerOpen(false)}
              mimeTypes={mimeTypes.font}
              fileType={'font'}
              onInsert={updateFontFile}
              getCustomFileName={getCustomFileName}
              afterRemove={afterRemove}
              validateUploadFile={validateUploadFile}
              afterFileUpload={afterFileUpload}
            />
          )}
        </CustomFontPickerUploadContainerUi>
      </CustomFontPickerContainerUi>
    </>
  )
}

export default CustomFontPicker
