import React from 'react'
import CommonButton from 'common/components/entities/Button/Button'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { ButtonInterface } from 'common/types/entities/ButtonInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'
import { useContactUsDescendantIds } from './ContactUs'

function Button({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<ButtonInterface>) {
  const entityIds = useContactUsDescendantIds()

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      isRemoveAllowed={false}
      isSaveAllowed={false}
      isCopyAllowed={false}
      dropBoxAllowedEntityIds={entityIds}
      flex
      flexColumn
    >
      <CommonButton
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
        text={entity.text}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        subText={entity.subText}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        alignSelf={entity.alignSelf}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        attrId={entity.htmlAttrId}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        textColor={entity.textColor}
        subTextColor={entity.subTextColor}
        mobileTextColor={entity.mobileTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        textFontFamily={entity.textFontFamily}
        textFontWeight={entity.textFontWeight}
        textFontStyle={entity.textFontStyle}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontWeight={entity.subTextFontWeight}
        subTextFontStyle={entity.subTextFontStyle}
        mobileTextFontFamily={entity.mobileTextFontFamily}
        mobileTextFontWeight={entity.mobileTextFontWeight}
        mobileTextFontStyle={entity.mobileTextFontStyle}
        mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
        mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
        mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
      />
    </BaseEntityNew>
  )
}

export function createContactUsButton(
  parentId: string,
  text: string,
  masterBlockId?: string,
) {
  const button: ButtonInterface = {
    id: generateId(),
    type: EntityTypeEnum.Button,
    parentId: parentId,
    textFontSize: 20,
    subTextFontSize: 14,
    textColor: 'rgba(255, 255, 255, 1)',
    backgroundColor: 'rgba(1, 160, 255, 1)',
    alignSelf: 'center',
    text,
    border: {
      radius: 5,
      style: 'none',
    },
    padding: {
      paddingTop: 15,
      paddingRight: 90,
      paddingBottom: 15,
      paddingLeft: 90,
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.Button),
  }

  if (masterBlockId) {
    button.masterBlockId = masterBlockId
  }

  return button
}

export function getButtonFontProperties(entity: ButtonInterface) {
  const fontProperties = []

  if (entity.textFontFamily) {
    fontProperties.push({
      fontFamily: entity.textFontFamily,
      fontStyle: entity.textFontStyle,
      fontWeight: entity.textFontWeight,
    })
  }

  if (entity.mobileTextFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileTextFontFamily,
      fontStyle: entity.mobileTextFontStyle,
      fontWeight: entity.mobileTextFontWeight,
    })
  }

  if (entity.subTextFontFamily) {
    fontProperties.push({
      fontFamily: entity.subTextFontFamily,
      fontStyle: entity.subTextFontStyle,
      fontWeight: entity.subTextFontWeight,
    })
  }

  if (entity.mobileSubTextFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileSubTextFontFamily,
      fontStyle: entity.mobileSubTextFontStyle,
      fontWeight: entity.mobileSubTextFontWeight,
    })
  }

  return fontProperties
}

export function getNewButtonCustomFontProperties(entity: ButtonInterface) {
  const fontProperties = []

  if (entity.textFontFamily) {
    fontProperties.push({
      fontFamily: entity.textFontFamily,
      fontFileId: entity.textFontFileId,
    })
  }

  if (entity.mobileTextFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileTextFontFamily,
      fontFileId: entity.mobileTextFontFileId,
    })
  }

  if (entity.subTextFontFamily) {
    fontProperties.push({
      fontFamily: entity.subTextFontFamily,
      fontFileId: entity.subTextFontFileId,
    })
  }

  if (entity.mobileSubTextFontFamily) {
    fontProperties.push({
      fontFamily: entity.mobileSubTextFontFamily,
      fontFileId: entity.mobileSubTextFontFileId,
    })
  }

  return fontProperties
}

export default Button
