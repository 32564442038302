import React from 'react'
import { FacebookCommentsInterface } from 'common/types/entities/FacebookCommentsInterface'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Input from '../components/Input/Input'
import Range from '../components/Range/Range'
import { DelayOption } from '../options'

interface FacebookCommentsSettingsProps {
  entity: FacebookCommentsInterface
}

const FacebookCommentsSettings = ({
  entity,
}: FacebookCommentsSettingsProps) => {
  const updateProp = useUpdateProp(entity)

  const { isMobile } = useDeviceModeContext()

  return (
    <>
      <Range
        change={updateProp('numPosts')}
        value={entity.numPosts}
        label={'entity_settings.fb_comments.number_of_posts.label'}
      />
      <Input
        placeholder=""
        type={'text'}
        update={updateProp('href')}
        value={entity.href}
        label={'entity_settings.fb_comments.comment_url.label'}
        helpMessage={'entity_settings.fb_comments.help_message'}
      />
      <DelayOption update={updateProp('delay')} delay={entity.delay || 0} />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default FacebookCommentsSettings
