import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { blogPageTypes } from 'common/constants/pageTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { CheckboxInterface } from 'common/types/entities/CheckboxInterface'
import { PrimaryButton } from 'client/components/core/Sidebar/components/Settings/components'
import AutomationActionsList from 'client/components/core/Sidebar/components/Settings/components/AutomationRulesSettings/AutomationActionsList'
import AutomationRulesActionsModal from 'client/components/core/Sidebar/components/Settings/components/AutomationRulesSettings/AutomationRulesActionsModal'
import automationRulePageTypes from 'client/components/core/Sidebar/components/Settings/components/AutomationRulesSettings/utils/automationRulesPageTypes'
import Checkbox from 'client/components/core/Sidebar/components/Settings/components/Checkbox'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import AlignStyle from 'client/components/core/Sidebar/components/Settings/styles/AlignStyle'
import FontSize from 'client/components/core/Sidebar/components/Settings/styles/FontSize'
import LineHeight from 'client/components/core/Sidebar/components/Settings/styles/LineHeight'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { AutomationRuleTriggersEnum } from 'client/enums/AutomationRulesEnum'
import { pageSelectors, useAutomationRules, usePage } from 'client/store'
import automationRulesSelectors from 'client/store/automationRules/automationRulesSelectors'
import { ActionEditType } from 'client/types/AutomationRulesActionsEditInterface'
import { automationRuleTriggerEntityProperties } from 'client/types/AutomationRulesActionsInterface'

export interface CheckboxSettingsProps {
  entity: CheckboxInterface
}

const CheckboxSettings = ({ entity }: CheckboxSettingsProps) => {
  const { t } = useTranslation()
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<CheckboxInterface>()

  const pageType = usePage(pageSelectors.getPageType)
  const isTemplate = usePage(pageSelectors.isTemplate)
  const isLoginPage = pageType === PageTypeEnum.CustomLogin

  const isAutomationRulesVisible =
    !isTemplate && automationRulePageTypes.includes(pageType)
  const automationTriggerType = blogPageTypes.includes(pageType)
    ? AutomationRuleTriggersEnum.blog_page_form_subscribed
    : AutomationRuleTriggersEnum.form_subscribed
  const [isAutomationsModalOpen, setIsAutomationsModalOpen] = useState(false)
  const editedCheckboxActions = useAutomationRules(state =>
    automationRulesSelectors.getEditedAutomationRulesByEntityId(
      state,
      entity.id,
      automationRuleTriggerEntityProperties.checkBoxId,
    ),
  )

  return (
    <React.Fragment>
      <Input
        update={updateProp('errorText')}
        value={entity.errorText}
        label="settings_options.checkbox_message"
        disabled={entity.optional}
      />
      {!isLoginPage && (
        <Checkbox
          labelText="entity_settings.explicit_consent.optional"
          helpMessage="entity_settings.explicit_consent.optional_popover"
          value={entity.optional}
          update={updateProp('optional')}
        />
      )}
      {isAutomationRulesVisible && [
        editedCheckboxActions.length > 0 && (
          <AutomationActionsList actions={editedCheckboxActions} />
        ),
        <PrimaryButton
          onClick={() => {
            setIsAutomationsModalOpen(true)
          }}
          key={'automationSettingsButton'}
        >
          {t('entity_settings.automation_rule.edit.add_automation_rule')}
        </PrimaryButton>,
        <AutomationRulesActionsModal
          key="automationSettingsModal"
          opened={isAutomationsModalOpen}
          triggerType={automationTriggerType}
          onClose={() => setIsAutomationsModalOpen(false)}
          actions={editedCheckboxActions as ActionEditType[]}
          entityId={entity.id}
          triggerKey={'checkBoxId'}
        />,
      ]}
      <GroupTitle>typography</GroupTitle>
      <FontSize
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <LineHeight
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <TextFontUpdater<CheckboxInterface>
        entity={entity}
        update={updateEntity}
      />
      <AlignStyle
        align={isMobile ? entity.mobileTextAlign : entity.textAlign}
        update={
          isMobile ? updateProp('mobileTextAlign') : updateProp('textAlign')
        }
        isFlex={false}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
        label="text color"
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity?.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </React.Fragment>
  )
}

export default CheckboxSettings
