import React, { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { MarginType } from 'common/types/styleTypes'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Range from '../../components/Range/Range'
import GroupTitle from '../../styleComponents/GroupTitle'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import FullAndSeparateSettingsContainerUi from '../ui/FullAndSeparateSettingsContainerUi'
import FullAndSeparateSettingsIconsContainerUi from '../ui/FullAndSeparateSettingsIconsContainerUi'
import FullAndSeparateSettingsInputUi from '../ui/FullAndSeparateSettingsInputUi'
import MobileIconUi from '../ui/MobileIconUi'
import FullMarginTypeContainerUi from './ui/FullMarginTypeContainerUi'
import {
  bottomSideRenderer,
  findLargestMargin,
  isSingleMargin,
  leftSideRenderer,
  marginStylesTranslations,
  rightSideRenderer,
  singleContainerRenderer,
  topSideRenderer,
} from './utils'

type MarginStyleProps = {
  margin: Partial<MarginType> | MarginType | undefined
  update: (margin: Partial<MarginType>) => void
  label?: string
  groupTitle?: boolean
  allowNegative?: boolean
}

enum marginEditingModes {
  SingleMargin = 'SingleMargin',
  SeparateMarginType = 'SeparateMarginType',
}

const separateMarginTypeSides = {
  marginTop: topSideRenderer,
  marginRight: rightSideRenderer,
  marginBottom: bottomSideRenderer,
  marginLeft: leftSideRenderer,
}
function MarginStyle({
  margin = { marginTop: 0, marginRight: 0, marginBottom: 0, marginLeft: 0 },
  update,
  label,
  groupTitle = false,
  allowNegative = true,
}: MarginStyleProps) {
  const [marginEditingMode, setMarginEditingMode] = useState(
    isSingleMargin(margin)
      ? marginEditingModes.SingleMargin
      : marginEditingModes.SeparateMarginType,
  )
  const { isMobile } = useDeviceModeContext()
  const generateId = uuid()

  function updateSeparateMargin(corner: string) {
    return function (value: number) {
      update({
        ...margin,
        [corner]: value,
      })
    }
  }

  function updateSingleMargin(value: number) {
    update({
      marginTop: value,
      marginRight: value,
      marginBottom: value,
      marginLeft: value,
    })
  }

  const switchToSingleMode = () => {
    setMarginEditingMode(marginEditingModes.SingleMargin)
    const largestMargin = findLargestMargin(margin as MarginType)
    update({
      marginTop: largestMargin,
      marginRight: largestMargin,
      marginBottom: largestMargin,
      marginLeft: largestMargin,
    })
  }

  const switchToSeparateMarginTypeMode = () => {
    if (marginEditingMode === marginEditingModes.SingleMargin) {
      setMarginEditingMode(marginEditingModes.SeparateMarginType)
    }
  }

  return (
    <>
      {groupTitle && <GroupTitle>settings_styles.margin.label</GroupTitle>}
      <OptionWrapper
        labelText={label}
        labelIcon={isMobile ? <MobileIconUi /> : null}
      >
        <FullAndSeparateSettingsContainerUi
          column={marginEditingMode === marginEditingModes.SeparateMarginType}
        >
          <FullAndSeparateSettingsIconsContainerUi>
            {singleContainerRenderer(
              marginEditingMode === marginEditingModes.SingleMargin,
              switchToSingleMode,
            )}
            <FullMarginTypeContainerUi onClick={switchToSeparateMarginTypeMode}>
              {Object.entries(separateMarginTypeSides).map(
                ([side, renderSide]) =>
                  renderSide(
                    marginEditingMode === marginEditingModes.SeparateMarginType,
                    `${side}-${generateId}`,
                  ),
              )}
            </FullMarginTypeContainerUi>
          </FullAndSeparateSettingsIconsContainerUi>
          {marginEditingMode === marginEditingModes.SingleMargin ? (
            <FullAndSeparateSettingsInputUi
              onChange={e => updateSingleMargin(Number(e.target.value))}
              type="number"
              value={margin.marginTop ? margin.marginTop.toString() : 0}
              {...(allowNegative ? {} : { min: 0, max: 250 })}
            />
          ) : (
            Object.keys(separateMarginTypeSides).map((side, index) => (
              <>
                <Range
                  label={marginStylesTranslations[side as keyof MarginType]}
                  labelIcon={isMobile ? <MobileIconUi /> : null}
                  value={
                    margin[side as keyof MarginType]
                      ? margin[side as keyof MarginType]
                      : 0
                  }
                  change={updateSeparateMargin(side)}
                  max={200}
                  itemStyles={{ padding: '15px 0' }}
                />
              </>
            ))
          )}
        </FullAndSeparateSettingsContainerUi>
      </OptionWrapper>
    </>
  )
}

export default MarginStyle
