import { useDispatch } from 'react-redux'
import EntityInterface, {
  BaseEntityInterface,
} from 'common/types/entities/EntityInterface'
import * as pageActions from 'client/store/page/pageActions'
import { FontProps } from '../components/Font/BaseFont'

export function useUpdateEntity<T extends EntityInterface>() {
  const dispatch = useDispatch()
  return function (updatedEntity: T) {
    dispatch(pageActions.updateEntity<T>(updatedEntity))
  }
}

export function useUpdateProp<T extends BaseEntityInterface>(entity: T) {
  const dispatch = useDispatch()
  return function <K extends keyof T>(prop: K) {
    return function (value: T[K]) {
      dispatch(
        pageActions.updateEntity<T>({
          ...entity,
          [prop]: value,
        }),
      )
    }
  }
}

export function useUpdateProps<T extends EntityInterface>(entity: T) {
  const dispatch = useDispatch()
  return function (styles: FontProps) {
    dispatch(
      pageActions.updateEntity<T>({
        ...entity,
        titleFontFamily: styles.fontFamily,
        titleFontStyle: styles.fontStyle,
        titleFontWeight: styles.fontWeight,
      }),
    )
  }
}

export function useUpdateNestedProp<T extends EntityInterface>(obj: T) {
  const dispatch = useDispatch()
  return function <K1 extends keyof T>(firstLevelPropKey: K1) {
    return function <K2 extends keyof T[K1]>(secondLevelPropKey: K2) {
      return function (value: T[K1][K2]) {
        dispatch(
          pageActions.updateEntity<T>({
            ...obj,
            [firstLevelPropKey]: {
              ...obj[firstLevelPropKey],
              [secondLevelPropKey]: value,
            },
          }),
        )
      }
    }
  }
}
