import React from 'react'

const CourseUnenrollIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="#CBD5E1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_28602_6922)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.005 67.3016C160.006 67.4815 159.95 67.6571 159.847 67.8037C159.744 67.9502 159.597 68.0603 159.429 68.1184L80.2103 95.0747C80.1214 95.102 80.029 95.116 79.936 95.1163C79.8426 95.1171 79.7498 95.1014 79.6617 95.0701L27.6115 77.0746V97.6633C29.2566 98.4071 30.6537 99.6152 31.6342 101.142C32.6148 102.669 33.1372 104.449 33.1383 106.269C33.1372 108.088 32.6148 109.869 31.6342 111.396C30.6537 112.922 29.2566 114.131 27.6115 114.874L31.8583 148.005H14.8617L19.9589 114.874C18.3149 114.129 16.9192 112.921 15.9395 111.394C14.9598 109.868 14.4379 108.088 14.4366 106.269C14.4368 104.45 14.9582 102.669 15.938 101.143C16.9178 99.6156 18.3142 98.4073 19.9589 97.6633V74.426L0.571459 67.7215C0.403251 67.6619 0.257738 67.5506 0.155276 67.4034C0.0528133 67.2561 -0.0014712 67.0801 3.03298e-05 66.9002C0.00200043 66.7202 0.0600201 66.5453 0.165851 66.4004C0.271681 66.2555 0.419981 66.1479 0.589763 66.0927L41.7783 52.8452C43.4158 59.9957 47.0542 66.5207 52.2606 71.6436C59.6742 78.9495 69.6438 83.0085 80.0046 82.9393C90.3638 83.0073 100.332 78.9484 107.744 71.6436C112.925 66.5495 116.553 60.0648 118.199 52.956L159.419 66.4895C159.59 66.5451 159.738 66.6536 159.843 66.7995C159.948 66.9454 160.005 67.1212 160.005 67.3016Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9223 99.4074C79.3625 99.4059 78.8067 99.3123 78.2766 99.1305L36.8092 84.7895V115.437C36.8092 124.038 56.3978 131.015 80.5623 131.015C104.727 131.015 124.315 124.038 124.315 115.437V84.5911L81.5635 99.1351C81.0352 99.3172 80.4806 99.4092 79.9223 99.4074Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0045 12C61.9748 12 47.648 26.1749 47.648 44.009C47.6467 46.2685 47.8812 48.5219 48.3474 50.7319C51.4194 65.276 64.3108 76.0179 80.0045 76.0179C95.6983 76.0179 108.544 65.3129 111.648 50.8057C112.123 48.572 112.362 46.2936 112.361 44.009C112.357 26.1749 98.0297 12 80.0045 12ZM98.496 48.5817H61.5131V39.4362H98.496V48.5817Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_28602_6922">
        <rect
          width="160"
          height="136"
          fill="white"
          transform="translate(0 12)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default CourseUnenrollIcon
