import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import Checkbox from 'client/components/core/Sidebar/components/Settings/components/Checkbox'

class DeviceAppearance extends PureComponent {
  toggleDesktop = () => {
    const { entity } = this.props
    const { options: { appearance = {} } } = entity
    const nextValue = !appearance.desktop
    const updatedEntity = {
      ...entity,
      options: {
        ...entity.options,
        appearance: {
          ...appearance,
          desktop: nextValue,
          mobile: (!appearance.mobile && !nextValue) || appearance.mobile,
        },
      },
    }

    this.props.update(updatedEntity)
  }

  toggleMobile = () => {
    const { entity } = this.props
    const { options: { appearance = {} } } = entity
    const nextValue = !appearance.mobile
    const updatedEntity = {
      ...entity,
      options: {
        ...entity.options,
        appearance: {
          ...appearance,
          desktop: (!appearance.desktop && !nextValue) || appearance.desktop,
          mobile: nextValue,
        },
      },
    }

    this.props.update(updatedEntity)
  }

  render() {
    const { entity } = this.props
    const { options: { appearance = {} } } = entity
    const { mobile = true, desktop = true } = appearance

    return (
      <React.Fragment>
        <GroupTitle>settings_options.device_appearance.label</GroupTitle>
        <Checkbox
          labelText="settings_options.device_appearance.desktop"
          update={this.toggleDesktop}
          value={desktop}
          directionRow
        />
        <Checkbox
          labelText="settings_options.device_appearance.mobile"
          update={this.toggleMobile}
          value={mobile}
          directionRow
        />
      </React.Fragment>
    )
  }
}

DeviceAppearance.propTypes = {
  update: PropTypes.func.isRequired,
  entity: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default DeviceAppearance
