const buttonWidthKeys = {
  fluid: 'fluid',
  fill: 'fill',
}

export const buttonWidthNames = {
  [buttonWidthKeys.fluid]: 'entity_settings.button.width_type.fluid',
  [buttonWidthKeys.fill]: 'entity_settings.button.width_type.fill',
}

export const buttonWidthValues = {
  [buttonWidthKeys.fluid]: 'auto',
  [buttonWidthKeys.fill]: '100%',
}
