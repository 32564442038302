import { EntitiesStructure } from 'common/types/Page'
import { OldEntityWithChildIds } from 'common/types/entities/OldEntityInterface'
import { createLatestBlogPostsCarousel } from 'client/pages/blog-page/entities/LatestBlogPostsCarousel'
import { createProduct } from 'client/pages/offer/entities/Product'
import { ColumnTypeEnum } from '../../common/components/entities/Column/enums/ColumnTypeEnum'
import LibraryElementEnum from '../enums/LibraryElementEnum'
import { createOfferPrice } from '../pages/offer/entities/OfferPrice'
import {
  createAudioFromLibrary,
  createBlogCategoryDescriptionFromLibrary,
  createBlogCategoryTitleFromLibrary,
  createBlogLatestPostsFromLibrary,
  createBlogPostCategoriesFromLibrary,
  createBlogPostContentPlaceholderFromLibrary,
  createBlogPostDateFromLibrary,
  createBlogPostImageFromLibrary,
  createBlogPostListingFromLibrary,
  createBlogPostTitleFromLibrary,
  createBreadcrumbsFromLibrary,
  createBulletListFromLibrary,
  createCarouselFromLibrary,
  createContactUsFromLibrary,
  createContentTableFromLibrary,
  createCountdownLibrary,
  createCustomLoginFromLibrary,
  createDefaultEntityStructure,
  createFacebookCommentsLibrary,
  createFaqFromLibrary,
  createHeadlineFromLibrary,
  createHorizontalLineLibrary,
  createImageFromLibrary,
  createMenuFromLibrary,
  createNewBlogContentPlaceholderFromLibrary,
  createOptInRecaptchaFromLibrary,
  createOrderSummaryFromLibrary,
  createPricePlanCalculatorFromLibrary,
  createRowFromLibrary,
  createSalesRedirectionButtonFromLibrary,
  createSectionFromLibrary,
  createSurveyFromLibrary,
  createTextFromLibrary,
  createTwitterTweetButtonLibrary,
  createUpsellAgreeButtonFromLibrary,
  createUpsellDisagreeButtonFromLibrary,
  createVideoFromLibrary,
  createWebinarCallToActionFromLibrary,
  createWebinarRegistrationDateFromLibrary,
  createWebinarSessionDateFromLibrary,
  createWebinarSessionLinkFromLibrary,
  createWebinarSessionVideoFromLibrary,
  createLanguageSwitcherLibrary,
  createCustomerTypeFromLibrary,
  createContentBoxFromLibrary,
  createPaymentMethodFromLibrary,
  createRawHtmlLibrary,
  createCheckboxFromLibrary,
  createShippingFeesFromLibrary,
  createPaymentButtonFromLibrary,
  createCalendarFromLibrary,
} from './createStructureUtils'

export default function createLibraryElementStructure(
  libraryElementType: LibraryElementEnum,
  container: OldEntityWithChildIds,
  locale: string,
  position = 0,
) {
  let structure: EntitiesStructure
  switch (libraryElementType) {
    case LibraryElementEnum.LibraryImage:
      structure = createImageFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryFaq:
      structure = createFaqFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryPricePlanCalculator:
      structure = createPricePlanCalculatorFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryWebinarRegistrationDate:
      structure = createWebinarRegistrationDateFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryOrderSummary:
      structure = createOrderSummaryFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryText:
      structure = createTextFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryHeadline:
      structure = createHeadlineFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryCustomLogin:
      structure = createCustomLoginFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryBlogPostImage:
      structure = createBlogPostImageFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryContentTable:
      structure = createContentTableFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryContactUs:
      structure = createContactUsFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryBreadcrumbs:
      structure = createBreadcrumbsFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryCarousel:
      structure = createCarouselFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryBlogPostDate:
      structure = createBlogPostDateFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryBlogPostListing:
      structure = createBlogPostListingFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryBlogLatestPosts:
      structure = createBlogLatestPostsFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryBlogPostTitle:
      structure = createBlogPostTitleFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibrarySurvey:
      structure = createSurveyFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryMenu:
      structure = createMenuFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryUpsellAgreeButton:
      structure = createUpsellAgreeButtonFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryUpsellDisagreeButton:
      structure = createUpsellDisagreeButtonFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryBlogPostCategories:
      structure = createBlogPostCategoriesFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryBlogContentPlaceholder:
      structure = createNewBlogContentPlaceholderFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryBlogPostBody:
      structure = createBlogPostContentPlaceholderFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryVideo:
      structure = createVideoFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryWebinarSessionVideo:
      structure = createWebinarSessionVideoFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryWebinarCallToAction:
      structure = createWebinarCallToActionFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryHorizontalLine:
      structure = createHorizontalLineLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibraryBlogCategoryTitle:
      structure = createBlogCategoryTitleFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryBlogCategoryDescription:
      structure = createBlogCategoryDescriptionFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryWebinarSessionLink:
      structure = createWebinarSessionLinkFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryWebinarSessionDateTime:
      structure = createWebinarSessionDateFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryOfferPrice:
      structure = createDefaultEntityStructure(
        createOfferPrice,
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryProduct:
      structure = createDefaultEntityStructure(
        createProduct,
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryLatestBlogPostsCarousel:
      structure = createDefaultEntityStructure(
        createLatestBlogPostsCarousel,
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryAudio:
      structure = createAudioFromLibrary(container, locale, position)
      break
    case LibraryElementEnum.LibrarySalesRedirectionButton:
      structure = createSalesRedirectionButtonFromLibrary(
        container,
        locale,
        position,
      )
      break
    case LibraryElementEnum.LibraryFacebookComments: {
      structure = createFacebookCommentsLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryOptInRecaptcha: {
      structure = createOptInRecaptchaFromLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryTwitterTweetButton: {
      structure = createTwitterTweetButtonLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryCountdown: {
      structure = createCountdownLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryLanguageSwitcher: {
      structure = createLanguageSwitcherLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryCustomerType: {
      structure = createCustomerTypeFromLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryContentBox: {
      structure = createContentBoxFromLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryPaymentMethod: {
      structure = createPaymentMethodFromLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryRow: {
      structure = createRowFromLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryRawHTML: {
      structure = createRawHtmlLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryCheckbox: {
      structure = createCheckboxFromLibrary(container, locale, position)
      break
    }

    case LibraryElementEnum.LibraryBulletList: {
      structure = createBulletListFromLibrary(container, locale, position)
      break
    }

    case LibraryElementEnum.LibraryCalendar: {
      structure = createCalendarFromLibrary(container, locale, position)
      break
    }

    case LibraryElementEnum.LibraryColumn6: {
      structure = createRowFromLibrary(
        container,
        locale,
        position,
        ColumnTypeEnum.COLUMN_6,
      )
      break
    }

    case LibraryElementEnum.LibraryColumn4: {
      structure = createRowFromLibrary(
        container,
        locale,
        position,
        ColumnTypeEnum.COLUMN_4,
      )
      break
    }

    case LibraryElementEnum.LibraryColumn3: {
      structure = createRowFromLibrary(
        container,
        locale,
        position,
        ColumnTypeEnum.COLUMN_3,
      )
      break
    }
    case LibraryElementEnum.LibrarySection: {
      structure = createSectionFromLibrary(container, position)
      break
    }
    case LibraryElementEnum.LibraryShippingFees: {
      structure = createShippingFeesFromLibrary(container, locale, position)
      break
    }
    case LibraryElementEnum.LibraryPaymentButton: {
      structure = createPaymentButtonFromLibrary(container, locale, position)
      break
    }
    default:
      throw new Error('unknown library type')
  }
  return structure
}
