import PropTypes from 'prop-types'
import React from 'react'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Input } from '../components'
import ButtonIconPicker from '../components/ButtonIconPicker'
import TextFont from '../components/Font/BaseFont'
import { Attributes, FontSize } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import {
  BorderStyle,
  CornerStyle,
  MarginsStyle,
  PaddingStyle,
  ShadowStyle,
} from '../styles'
import ButtonWidth from '../styles/ButtonWidth'
import FlexAlignStyle from '../styles/FlexAlignStyle'
import LineHeight from '../styles/LineHeight'

function SalesRedirectionButtonSettings(props) {
  const {
    entity,
    update,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateMobileOption,
    updateOptions,
    updateMobileOptions,
    toggleOverlay,
    overlayVisible,
  } = props

  const { isMobile } = useDeviceModeContext()

  const handleTextFontChange = fontProperties => {
    if (isMobile) {
      if (fontProperties.fontFileId) {
        update({
          ...entity,
          mobileStyles: {
            ...entity.mobileStyles,
            fontFamily: fontProperties.fontFamily,
          },
          mobileOptions: {
            ...entity.mobileOptions,
            textFontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        update({
          ...entity,
          mobileStyles: {
            ...entity.mobileStyles,
            ...fontProperties,
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        update({
          ...entity,
          styles: {
            ...entity.styles,
            fontFamily: fontProperties.fontFamily,
          },
          options: {
            ...entity.options,
            textFontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        update({
          ...entity,
          styles: {
            ...entity.styles,
            ...fontProperties,
          },
        })
      }
    }
  }
  const handleSubTextFontChange = fontProperties => {
    if (isMobile) {
      if (fontProperties.fontFileId) {
        update({
          ...entity,
          mobileOptions: {
            ...entity.mobileOptions,
            subTextFontFileId: fontProperties.fontFileId,
            subTextFontFamily: fontProperties.fontFamily,
          },
        })
      } else {
        updateMobileOptions({
          ...entity.options,
          subTextFontFamily: fontProperties.fontFamily,
          subTextFontStyle: fontProperties.fontStyle,
          subTextFontWeight: fontProperties.fontWeight,
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        update({
          ...entity,
          options: {
            ...entity.options,
            subTextFontFileId: fontProperties.fontFileId,
            subTextFontFamily: fontProperties.fontFamily,
          },
        })
      } else {
        updateOptions({
          ...entity.options,
          subTextFontFamily: fontProperties.fontFamily,
          subTextFontStyle: fontProperties.fontStyle,
          subTextFontWeight: fontProperties.fontWeight,
        })
      }
    }
  }

  const textFontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const textFontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const textFontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  const subTextFontFamily = isMobile
    ? entity.mobileOptions.subTextFontFamily
    : entity.options.subTextFontFamily
  const subTextFontWeight = isMobile
    ? entity.mobileOptions.subTextFontWeight
    : entity.options.subTextFontWeight
  const subTextFontStyle = isMobile
    ? entity.mobileOptions.subTextFontStyle
    : entity.options.subTextFontStyle

  return (
    <React.Fragment>
      <FlexAlignStyle
        align={entity.styles.alignSelf}
        mobileAlign={entity.mobileStyles.alignSelf}
        update={updateStyle('alignSelf')}
        mobileUpdate={updateMobileStyle('alignSelf')}
      />
      <ButtonWidth
        update={updateStyle('width')}
        mobileUpdate={updateMobileStyle('width')}
        width={entity.styles.width}
        mobileWidth={entity.mobileStyles.width}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <GroupTitle>typography</GroupTitle>
      <Input
        labelText="button text"
        value={entity.options.text}
        update={updateOption('text')}
      />
      <FontSize
        update={updateStyle('fontSize', 'px')}
        mobileUpdate={updateMobileStyle('fontSize', 'px')}
        fontSize={entity.styles.fontSize}
        mobileFontSize={entity.mobileStyles.fontSize}
      />
      <LineHeight
        lineHeight={entity.styles.lineHeight}
        mobileLineHeight={entity.mobileStyles.lineHeight}
        update={updateStyle('lineHeight', 'px')}
        mobileUpdate={updateMobileStyle('lineHeight', 'px')}
      />
      <TextFont
        fontFamily={textFontFamily}
        fontStyle={textFontStyle}
        fontWeight={textFontWeight}
        update={handleTextFontChange}
      />
      <Input
        labelText="entity_settings.button.sub_text.label"
        update={updateOption('subText')}
        value={entity.options.subText}
      />
      {entity.options.subText && (
        <FontSize
          labelText="entity_settings.button.sub_text.font_size.label"
          update={updateOption('subTextFontSize', 'px')}
          mobileUpdate={updateMobileOption('subTextFontSize', 'px')}
          fontSize={entity.options.subTextFontSize}
          mobileFontSize={entity.mobileOptions.subTextFontSize}
        />
      )}
      {entity.options.subText && (
        <TextFont
          labelText="entity_settings.button.sub_text.font_family.label"
          fontFamily={subTextFontFamily}
          fontStyle={subTextFontStyle}
          fontWeight={subTextFontWeight}
          update={handleSubTextFontChange}
        />
      )}
      <ButtonIconPicker
        valueBefore={entity.options.iconClassNameBefore}
        valueAfter={entity.options.iconClassNameAfter}
        updateBefore={updateOption('iconClassNameBefore')}
        updateAfter={updateOption('iconClassNameAfter')}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="background color"
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        mobileColor={entity.mobileStyles.backgroundColor}
        color={entity.styles.backgroundColor}
      />
      <ColorPicker
        label="text color"
        update={updateStyle('color')}
        mobileUpdate={updateMobileStyle('color')}
        color={entity.styles.color}
        mobileColor={entity.mobileStyles.color}
      />
      {entity.options.subText && (
        <ColorPicker
          label="entity_settings.button.sub_text.color.label"
          update={updateOption('subTextColor')}
          mobileUpdate={updateMobileOption('subTextColor')}
          color={entity.options.subTextColor}
          mobileColor={entity.mobileOptions.subTextColor}
        />
      )}
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <BorderStyle
        updateOption={updateOption}
        updateMobileOption={updateMobileOption}
        updateStyle={updateStyle}
        updateMobileStyle={updateMobileStyle}
        type={entity.options.borderType}
        style={entity.styles.borderStyle}
        width={entity.styles.borderWidth}
        color={entity.styles.borderColor}
        mobileType={entity.mobileOptions.borderType}
        mobileStyle={entity.mobileStyles.borderStyle}
        mobileWidth={entity.mobileStyles.borderWidth}
        mobileColor={entity.mobileStyles.borderColor}
        toggleOverlay={toggleOverlay}
        overlayVisible={overlayVisible}
      />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

SalesRedirectionButtonSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
  entity: types.entity.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
}

export default SalesRedirectionButtonSettings
