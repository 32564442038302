import React from 'react'
import CommonImage from 'common/components/entities/ImageNew'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { ImageInterface } from 'common/types/entities/ImageInterface'
import { useFiles } from 'client/store'
import filesSelectors from 'client/store/files/filesSelectors'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import BaseEntityNew from '../../core/BaseEntity/BaseEntityNew'

const demoImageSrc = `${process.env.EDITOR_ASSETS_HOST}/assets/images/image_placeholder.png`

function Image({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<ImageInterface>) {
  const file = useFiles(state =>
    filesSelectors.getFileById(state, entity.fileId),
  )
  return (
    <BaseEntityNew
      entity={entity}
      isSaveAllowed={Boolean(file)}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      flex
      flexColumn
    >
      <CommonImage
        background={entity.background}
        mobileBackground={entity.mobileBackground}
        attrId={entity.htmlAttrId}
        src={
          file
            ? file.path
            : entity.externalUrl
            ? entity.externalUrl
            : demoImageSrc
        }
        alt={entity.alt}
        alignSelf={entity.alignSelf}
        mobileAlignSelf={entity.mobileAlignSelf}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        delay={entity.delay}
        blur={entity.blur}
        ratio={entity.ratio}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
      />
    </BaseEntityNew>
  )
}

export function createImage(
  parentId: string,
  masterBlockId?: string,
): ImageInterface {
  return {
    ...generateBaseEntity(EntityTypeEnum.Image, parentId, masterBlockId),
    type: EntityTypeEnum.Image,
    alignSelf: 'center',
  }
}

export default Image
