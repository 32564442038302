import styled from 'styled-components'

const FormGroupUi = styled.div<{ styles?: any }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 25px;
  ${({ styles = {} }) => styles}
`

export default FormGroupUi
