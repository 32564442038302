import structureTypes from 'common/constants/structureTypes'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import EntityTypeEnum, {
  getReadableRootEntityTypeByPageType,
} from 'common/enums/entityTypeEnum'
import { OldEntityWithChildIds } from 'common/types/entities/OldEntityInterface'
import { usePage, typedPageSelectors, useManagement } from 'client/store/index'
import managementSelectors from 'client/store/management/managementSelectors'

function useCurrentRootEntity() {
  const activePopupId = useManagement(managementSelectors.getActivePopupId)
  const rootPopup = usePage(page =>
    typedPageSelectors.getEntityById(page, activePopupId),
  ) as OldEntityWithChildIds

  const rootEntityType = usePage(page =>
    getReadableRootEntityTypeByPageType(page.type),
  )
  const rootEntity = usePage(page =>
    typedPageSelectors.getFirstEntityByType(
      page,
      rootEntityType as EntityTypeEnum | keyof typeof structureTypes,
    ),
  ) as OldEntityWithChildIds

  const lastRootChild = usePage(page =>
    typedPageSelectors.getEntityById(page, rootEntity.childIds.slice(-1)[0]),
  ) as OldEntityWithChildIds

  const pageType = usePage(page => typedPageSelectors.getPageType(page))
  const isInlinePage = pageType === PageTypeEnum.Inline
  //check if there popup(root == popup) then if page is inline (root == root) and then if there lastRoot child (root == lastRootChild)
  return rootPopup
    ? rootPopup
    : isInlinePage
    ? rootEntity
    : lastRootChild
    ? lastRootChild
    : rootEntity
}

export default useCurrentRootEntity
