import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import {
  getBlogCategoryReplacement,
  getBlogPageReplacement,
  getBlogPostReplacement,
  isBlogPageUrlPlaceholder,
  isBlogPostUrlPlaceholder,
} from 'common/utils/blogReplacementsUtils'
import { blogSelectors, useBlog } from 'client/store/index'
import OptionWrapper from '../styleComponents/OptionWrapper'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
}

const formatGroupLabel = (data: GroupType) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    {/*@ts-ignore*/}
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

type OptionType = {
  value: string
  label: string
}

// type OptionValueType = ValueType<, false>

// type GroupType = GroupTypeBase<OptionType>

type GroupType = {
  label: string
  options: OptionType[]
}

type BlogLinkProps = {
  update: (val: string) => void
  blogLink: string
  needOptionWrapper?: boolean
}

function BlogLink({
  update,
  blogLink,
  needOptionWrapper = false,
}: BlogLinkProps) {
  const { t } = useTranslation()
  const blogPages = useBlog(blogSelectors.getBlogPages)
  const blogPosts = useBlog(blogSelectors.getBlogPosts)
  const blogCategories = useBlog(blogSelectors.getBlogCategories)

  const groupedOptions: GroupType[] = [
    {
      label: t('entity_settings.button.blog_link.pages_group_title'),
      options: blogPages.map(page => ({
        label: page.name,
        value: getBlogPageReplacement(page.id),
      })),
    },
    {
      label: t('entity_settings.button.blog_link.categories_group_title'),
      options: blogCategories.map(category => ({
        label: category.name,
        value: getBlogCategoryReplacement(category.id),
      })),
    },
    {
      label: t('entity_settings.button.blog_link.posts_group_title'),
      options: blogPosts.map(postPage => ({
        label: postPage.name,
        value: getBlogPostReplacement(postPage.id),
      })),
    },
  ]

  let valueOption
  if (blogLink) {
    const urlPlaceholder = blogLink.replace(/\d+/g, ' ')

    if (isBlogPageUrlPlaceholder(urlPlaceholder)) {
      const page = blogPages.find(
        page => getBlogPageReplacement(page.id) === blogLink,
      )
      if (page) {
        valueOption = {
          label: page.name,
          value: getBlogPageReplacement(page.id),
        }
      }
    } else if (isBlogPostUrlPlaceholder(urlPlaceholder)) {
      const postPage = blogPosts.find(
        postPage => getBlogPostReplacement(postPage.id) === blogLink,
      )
      if (postPage) {
        valueOption = {
          label: postPage.name,
          value: getBlogPostReplacement(postPage.id),
        }
      }
    } else {
      const category = blogCategories.find(
        category => getBlogCategoryReplacement(category.id) === blogLink,
      )
      if (category) {
        valueOption = {
          label: category.name,
          value: getBlogCategoryReplacement(category.id),
        }
      }
    }
  }

  function setBlogLink(option: OptionType | null) {
    if (option) {
      update(option.value)
    }
  }

  const selectElement = (
    <Select
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      onChange={setBlogLink}
      value={valueOption}
    />
  )

  return needOptionWrapper ? (
    <OptionWrapper labelText="entity_settings.button.blog_link.label">
      {/*@ts-ignore*/}
      {selectElement}
    </OptionWrapper>
  ) : (
    selectElement
  )
}

export default BlogLink
