import React from 'react'
import { PaymentButtonInterface } from 'common/types/entities/PaymentButtonInterface'
import ButtonIconPicker from 'client/components/core/Sidebar/components/Settings/components/ButtonIconPicker'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import HoverButtonSetting from 'client/components/core/Sidebar/components/Settings/components/HoverButton'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import { ShadowStyle } from 'client/components/core/Sidebar/components/Settings/styles'
import BorderStyle from 'client/components/core/Sidebar/components/Settings/styles/Border'
import ButtonWidth from 'client/components/core/Sidebar/components/Settings/styles/ButtonWidth'
import FlexAlignStyle from 'client/components/core/Sidebar/components/Settings/styles/FlexAlignStyle'
import FontSize from 'client/components/core/Sidebar/components/Settings/styles/FontSize'
import LineHeight from 'client/components/core/Sidebar/components/Settings/styles/LineHeight'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

interface PaymentButtonSettingsProps {
  entity: PaymentButtonInterface
}

const PaymentButtonSettingsNew = ({ entity }: PaymentButtonSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<PaymentButtonInterface>()
  const { isMobile } = useDeviceModeContext()

  return (
    <div>
      <FlexAlignStyle
        align={entity.alignSelf}
        mobileAlign={entity.mobileAlignSelf}
        update={updateProp('alignSelf')}
        mobileUpdate={updateProp('mobileAlignSelf')}
      />
      <ButtonWidth
        update={updateProp('width')}
        mobileUpdate={updateProp('mobileWidth')}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
      />
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="settings_styles.background_color.label"
      />
      <ButtonIconPicker
        valueBefore={entity.iconClassNameBefore}
        valueAfter={entity.iconClassNameAfter}
        updateBefore={updateProp('iconClassNameBefore')}
        updateAfter={updateProp('iconClassNameAfter')}
      />
      <GroupTitle>entity_settings.button.text.group_title</GroupTitle>
      <Input
        update={updateProp('loadingText')}
        value={entity.loadingText}
        label="loading text"
      />
      <Input
        update={updateProp('text')}
        value={entity.text}
        label="button text"
      />
      <TextFontUpdater<PaymentButtonInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'textFontFamily'}
        fontWeightKey={'textFontWeight'}
        fontStyleKey={'textFontStyle'}
        mobileFontFamilyKey={'mobileTextFontFamily'}
        mobileFontWeightKey={'mobileTextFontWeight'}
        mobileFontStyleKey={'mobileTextFontStyle'}
        fontFileIdKey={'textFontFileId'}
        mobileFontFileIdKey={'mobileTextFontFileId'}
      />
      <FontSize
        update={updateProp('textFontSize')}
        mobileUpdate={updateProp('mobileTextFontSize')}
        fontSize={entity.textFontSize}
        mobileFontSize={entity.mobileTextFontSize}
      />
      <LineHeight
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        fontSize={entity.textFontSize}
        mobileFontSize={entity.mobileTextFontSize}
      />
      <ColorPicker
        update={updateProp('textColor')}
        mobileUpdate={updateProp('mobileTextColor')}
        color={entity.textColor}
        mobileColor={entity.mobileTextColor}
        label="text color"
      />

      <GroupTitle>entity_settings.button.sub_text.group_title</GroupTitle>
      <Input
        update={updateProp('subText')}
        value={entity.subText}
        label="entity_settings.button.sub_text.label"
      />
      <FontSize
        update={updateProp('subTextFontSize')}
        mobileUpdate={updateProp('mobileSubTextFontSize')}
        fontSize={entity.subTextFontSize}
        mobileFontSize={entity.mobileSubTextFontSize}
      />
      <TextFontUpdater<PaymentButtonInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'subTextFontFamily'}
        fontWeightKey={'subTextFontWeight'}
        fontStyleKey={'subTextFontStyle'}
        mobileFontFamilyKey={'mobileSubTextFontFamily'}
        mobileFontWeightKey={'mobileSubTextFontWeight'}
        mobileFontStyleKey={'mobileSubTextFontStyle'}
        fontFileIdKey={'subTextFontFileId'}
        mobileFontFileIdKey={'mobileSubTextFontFileId'}
      />
      <ColorPicker
        update={updateProp('subTextColor')}
        mobileUpdate={updateProp('subTextColor')}
        color={entity.subTextColor}
        mobileColor={entity.mobileSubTextColor}
      />

      <ColorPicker
        label="background color"
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        mobileColor={entity.mobileBackgroundColor}
        color={entity.backgroundColor}
      />
      <HoverButtonSetting
        update={updateProp('hover')}
        mobileUpdate={updateProp('mobileHover')}
        hover={entity?.hover}
        mobileHover={entity?.mobileHover}
        backgroundColor={entity?.backgroundColor}
        textColor={entity?.textColor}
        subTextColor={entity?.subTextColor}
      />
      <BorderStyle
        border={isMobile ? entity?.mobileBorder : entity.border}
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity?.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </div>
  )
}

export default PaymentButtonSettingsNew
