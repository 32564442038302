import React from 'react'
import styled from 'styled-components'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'

const TextAreaUi = styled.textarea`
  width: 100%;
  height: 80px;
  border-radius: 4px;
  color: #485b6e;
  border: solid 1px #ebedf0;
  padding: 10px;
  font-size: 13px;
  font-family: var(--font-family);
  outline: none;
`

type TextAreaProps = {
  value?: string
  labelText: string
  update: (value: string) => void
  directionColumn?: boolean
  placeholder?: string
  helpMessage?: string
}

const TextArea = ({
  value,
  update,
  labelText,
  placeholder,
  directionColumn,
  helpMessage,
}: TextAreaProps) => (
  <OptionWrapper
    labelText={labelText}
    // @ts-ignore
    directionColumn={directionColumn}
    helpMessage={helpMessage}
  >
    <TextAreaUi
      onChange={e => update(e.target.value)}
      value={value}
      placeholder={placeholder}
    />
  </OptionWrapper>
)

export default TextArea
