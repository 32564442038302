import React from 'react'
import PropTypes from 'prop-types'
import * as types from 'common/types'
import { PaddingStyle } from 'client/components/core/Sidebar/components/Settings/styles'
import { Attributes } from '../options'

const BlogPostContentPlaceholderSettings = props => {
  const { entity, updateStyle, updateOption, updateMobileStyle } = props

  return (
    <React.Fragment>
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

BlogPostContentPlaceholderSettings.propTypes = {
  updateStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
}

export default BlogPostContentPlaceholderSettings
