import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { useActions } from 'common/hooks/useActions'
import * as actions from 'client/actions/actionsManagement'
import { useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import pageSelectors from 'client/store/page/pageSelectors'
import { useSetEntityHighlighted } from '../../../context/HighlightedContext'
import BreadcrumbsDelimiterUi from './ui/BreadcrumbsDelimiterUi'
import BreadcrumbsItemUi from './ui/BreadcrumbsItemUi'
import BreadcrumbsWrapperUi from './ui/BreadcrumbsWrapperUi'

const averageBreadcrumbWidth = 120

const omitEntityTypes = entity =>
  [
    structureTypes.TWO_STEP_PAYMENT_FORM_STEP_PAYMENT,
    structureTypes.TWO_STEP_PAYMENT_FORM_STEP_OPT_IN,
    structureTypes.COLUMN,
    EntityTypeEnum.Column,
    EntityTypeEnum.FaqItem,
    EntityTypeEnum.CarouselItem,
    structureTypes.CAROUSEL_ITEM,
  ].includes(entity.type) === false

function Breadcrumbs() {
  const ref = useRef()
  const [allowedItemsCount, setAllowedItemsCount] = useState(null)
  const editingId = useManagement(managementSelectors.getEditingId)
  const breadcrumbs = usePage(page =>
    pageSelectors.getBreadcrumbs(page, editingId),
  )
  const setHighlightedId = useSetEntityHighlighted()
  const toggleSettingsAction = useActions(actions.toggleSettings)
  const { t } = useTranslation()
  const highlight = id => () => setHighlightedId(id)
  const toggleSettings = id => () => toggleSettingsAction(id)

  const setRef = useCallback(node => {
    if (node !== null) {
      if (node && node.parentElement) {
        setAllowedItemsCount(
          Math.floor(
            (node.parentElement.offsetWidth - 300 - 220) /
              averageBreadcrumbWidth,
          ),
        )
      }
    }
    // Save a reference to the node
    ref.current = node
  }, [])

  return (
    <BreadcrumbsWrapperUi ref={setRef}>
      {Object.values(breadcrumbs)
        .filter(omitEntityTypes)
        .slice(0, allowedItemsCount || breadcrumbs.length)
        .reverse()
        .map((b, i, arr) => (
          <React.Fragment key={b.id}>
            {arr.length > i + 1 ? (
              <BreadcrumbsItemUi
                onClick={toggleSettings(b.id)}
                onMouseOver={highlight(b.id)}
                onMouseOut={highlight(null)}
              >
                {t(`entity_settings.${b.type}.header`.toLowerCase())}
                <BreadcrumbsDelimiterUi onClick={toggleSettings(b.id)} />
              </BreadcrumbsItemUi>
            ) : (
              <BreadcrumbsItemUi
                // when you click on a breadcrumb it becomes editable and we need to disable highlight
                onMouseOver={highlight(b.id)}
                onMouseOut={highlight(null)}
                last
              >
                {t(`entity_settings.${b.type}.header`.toLowerCase())}
                <BreadcrumbsDelimiterUi last />
              </BreadcrumbsItemUi>
            )}
          </React.Fragment>
        ))}
    </BreadcrumbsWrapperUi>
  )
}

export default Breadcrumbs
