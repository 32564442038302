import React from 'react'
import CommonBlogPostImage from 'common/components/entities/BlogPostImage'
import { BlogPostImageInterface } from 'common/types/entities/BlogPostImageInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { useBlogPost } from 'client/store'
import { blogPostSelectors } from 'client/store/blogPost/blogPostReducer'
import { EntityProps } from 'client/types'

function BlogPostImage({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<BlogPostImageInterface>) {
  const blogPostImageUrl = useBlogPost(blogPostSelectors.getBlogPostImageUrl)

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonBlogPostImage
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        src={blogPostImageUrl}
        background={entity.background}
        mobileBackground={entity.mobileBackground}
      />
    </BaseEntityNew>
  )
}

export default BlogPostImage
