import axios from 'axios'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import styled from 'styled-components'
import { NetworkError } from 'common/errors/NetworkError'
import FileDetails from './FileDetails'
import FileIcon from './FileIcon'
import FileRemoveIconUi from './ui/FileRemoveIconUi'

const StyledImage = styled.div`
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 115px;
  width: 115px;
  background-image: ${({ backgroundImage }) =>
    backgroundImage ? `url(${backgroundImage})` : 'none'};
`

const StyledLibrary = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
`

const StyledFileList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 412px;
  height: 380px;
  overflow-y: scroll;
`

const StyledFileListItem = styled.div`
  position: relative;
  transition: all 0.2s linear;
  cursor: pointer;
  margin: 2px;
  height: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  &:hover .fas.fa-times {
    display: block;
    opacity: 1;
  }
  transform: ${({ active }) =>
    active ? 'translate3d(0, 0, 0) scale(1)' : 'none'};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
`

const StyledShortName = styled.span`
  font-size: 12px;
`

const FileSearchInputUi = styled.input`
  margin: 5px 0 10px 0;
  width: 55%;
  border-radius: 5px;
  padding: 5px;
  text-indent: 5px;
  font-size: 12px;
  height: 36px;
  box-shadow: inset 1px 1px 1px rgba(130, 137, 150, 0.23);
  border: 1px solid #b0baca;
  outline: none;
  color: #485b6e;
  font-weight: bold;
  &::-webkit-search-cancel-button {
    cursor: pointer;
  }
`

const getShortName = name =>
  name.length > 20 ? `${name.slice(0, 10)}...${name.slice(-8)}` : name

const FileImage = ({
  imagePath,
  removingAllowed,
  fileId,
  removingFileId,
  removeFile,
}) => {
  return (
    <>
      <StyledImage backgroundImage={imagePath} />
      {removingAllowed && removingFileId !== fileId && (
        <FileRemoveIconUi
          className="fas fa-times"
          onClick={removeFile(fileId)}
        />
      )}
    </>
  )
}

class FileLibrary extends Component {
  state = {
    increase: 1,
    isFetching: false,
    removingFileId: null,
    searchValue: '',
    cancelTokenSource: undefined,
  }

  componentDidMount() {
    this.fileList.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    this.onSearchChange('')
  }

  onScroll = async () => {
    const { increase, isFetching } = this.state
    if (isFetching) {
      return
    }
    const compareHeight = this.fileList.getBoundingClientRect().height

    if (this.fileList.scrollTop > compareHeight * increase) {
      try {
        this.setState({ isFetching: true })
        await this.props.fetchData(
          this.props.fileType,
          this.getLastId(),
          10,
          this.state.searchValue,
        )
        this.setState({ increase: increase + 1, isFetching: false })
      } catch (e) {
        if (e instanceof NetworkError) {
          // do nothing
        } else {
          window.Rollbar.error(e)
        }
        this.setState({ isFetching: false })
      }
    }
  }

  getLastId = () => Object.keys(this.props.files)[0]

  removeFile = fileId => e => {
    this.setState({ removingFileId: fileId })
    this.props.removeFile(fileId)(e)
  }

  onSearchChange = async searchValue => {
    this.setState({ searchValue: searchValue })
    const cancelTokenSource = axios.CancelToken.source()
    if (this.state.cancelTokenSource) {
      this.state.cancelTokenSource.cancel()
    }
    this.setState({ cancelTokenSource: cancelTokenSource })
    try {
      await this.props.fetchData(
        this.props.fileType,
        null,
        30,
        searchValue,
        cancelTokenSource.token,
      )
    } catch (error) {
      console.log('--e--', error)
    }
  }

  render() {
    const {
      files,
      detailedFile,
      onClick,
      show,
      removingAllowed,
      getCustomFileName,
    } = this.props

    return (
      <>
        {show && (
          <Translation>
            {t => (
              <FileSearchInputUi
                type="search"
                placeholder={t('components.core.files.search.placeholder')}
                onChange={e => this.onSearchChange(e.target.value)}
              />
            )}
          </Translation>
        )}
        <StyledLibrary show={show}>
          <StyledFileList
            ref={instance => {
              this.fileList = instance
            }}
          >
            {Object.keys(files)
              .reverse()
              .map(fileId => {
                const file = files[fileId]
                const isImage = file.type === 'image'
                const isThumbnail = !!file.thumbnail
                return (
                  <StyledFileListItem
                    key={fileId}
                    onClick={onClick(file)}
                    active={detailedFile && detailedFile.id === +fileId}
                  >
                    {isThumbnail && (
                      <FileImage
                        imagePath={file.thumbnail}
                        removingAllowed={removingAllowed}
                        fileId={fileId}
                        removingFileId={this.state.removingFileId}
                        removeFile={this.removeFile}
                      />
                    )}
                    {isImage && !isThumbnail && (
                      <FileImage
                        imagePath={file.path}
                        removingAllowed={removingAllowed}
                        fileId={fileId}
                        removingFileId={this.state.removingFileId}
                        removeFile={this.removeFile}
                      />
                    )}
                    {!isImage && !isThumbnail && (
                      <FileIcon
                        fileName={file.filenameWithoutHash || file.filename}
                        remove={
                          removingAllowed &&
                          this.state.removingFileId !== fileId
                            ? this.removeFile(fileId)
                            : null
                        }
                      />
                    )}
                    <StyledShortName>
                      {getShortName(file.filenameWithoutHash || file.filename)}
                    </StyledShortName>
                  </StyledFileListItem>
                )
              })}
          </StyledFileList>
          {detailedFile && (
            <FileDetails
              file={detailedFile}
              getCustomFileName={getCustomFileName}
            />
          )}
        </StyledLibrary>
      </>
    )
  }
}

FileLibrary.propTypes = {
  files: PropTypes.objectOf(PropTypes.object),
  detailedFile: PropTypes.objectOf(PropTypes.any),
  onClick: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  fileType: PropTypes.string.isRequired,
  removeFile: PropTypes.func,
  removingAllowed: PropTypes.bool.isRequired,
  getCustomFileName: PropTypes.func,
}

FileLibrary.defaultProps = {
  detailedFile: null,
  files: {},
}

FileImage.propTypes = {
  imagePath: PropTypes.string.isRequired,
  removeFile: PropTypes.func,
  removingAllowed: PropTypes.bool.isRequired,
  fileId: PropTypes.string.isRequired,
  removingFileId: PropTypes.string.isRequired,
}

export default FileLibrary
