import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { bulletListIcons } from 'common/constants/iconList'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { DeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import TextFont from '../components/Font/TextFont'
import IconPicker from '../components/IconPicker'
import { Attributes, DelayOption, DeviceAppearance } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { MarginsStyle, PaddingStyle } from '../styles'
import TextFontSize from '../styles/TextFontSize'
import TextLineHeight from '../styles/TextLineHeight'
import VerticalSpacing from '../styles/VerticalSpacing'

class BulletListSettings extends PureComponent {
  static contextType = DeviceModeContext

  render() {
    const {
      toggleOverlay,
      overlayVisible,
      entity,
      updateStyle,
      updateMobileStyle,
      updateOption,
      updateMobileOption,
      update,
      removeEntityStyle,
      removeEntityMobileStyle,
      entityWithoutEmulation,
    } = this.props

    const handleFontChange = fontProperties => {
      const updatedEntity = { ...entity }
      if (this.context.isMobile) {
        if (fontProperties.fontFileId) {
          update({
            ...updatedEntity,
            mobileStyles: {
              ...updatedEntity.mobileStyles,
              fontFamily: fontProperties.fontFamily,
            },
            mobileOptions: {
              ...updatedEntity.mobileOptions,
              fontFileId: fontProperties.fontFileId,
            },
          })
        } else {
          if (
            updatedEntity.mobileOptions &&
            updatedEntity.mobileOptions.fontFileId
          ) {
            delete updatedEntity.mobileOptions.fontFileId
          }
          update({
            ...updatedEntity,
            mobileStyles: {
              ...updatedEntity.mobileStyles,
              ...fontProperties,
            },
          })
        }
      } else {
        if (fontProperties.fontFileId) {
          update({
            ...updatedEntity,
            styles: {
              ...updatedEntity.styles,
              fontFamily: fontProperties.fontFamily,
            },
            options: {
              ...updatedEntity.options,
              fontFileId: fontProperties.fontFileId,
            },
          })
        } else {
          if (updatedEntity.options.fontFileId) {
            delete updatedEntity.options.fontFileId
          }
          update({
            ...updatedEntity,
            styles: {
              ...updatedEntity.styles,
              ...fontProperties,
            },
          })
        }
      }
    }

    const fontFamily = this.context.isMobile
      ? entity.mobileStyles.fontFamily || entity.styles.fontFamily
      : entity.styles.fontFamily
    const fontWeight = this.context.isMobile
      ? entity.mobileStyles.fontWeight || entity.styles.fontWeight
      : entity.styles.fontWeight
    const fontStyle = this.context.isMobile
      ? entity.mobileStyles.fontStyle || entity.styles.fontStyle
      : entity.styles.fontStyle

    return (
      <React.Fragment>
        <GroupTitle>typography</GroupTitle>
        <TextFont
          fontFamily={fontFamily}
          fontStyle={fontStyle}
          fontWeight={fontWeight}
          update={handleFontChange}
        />
        <TextFontSize
          update={updateStyle('fontSize')}
          mobileUpdate={updateMobileStyle('fontSize')}
          fontSize={entityWithoutEmulation.styles.fontSize}
          mobileFontSize={entityWithoutEmulation.mobileStyles.fontSize}
          removeEntityStyle={removeEntityStyle('fontSize')}
          removeEntityMobileStyle={removeEntityMobileStyle('fontSize')}
        />
        <TextLineHeight
          fontSize={entity.styles.fontSize}
          mobileFontSize={entity.mobileStyles.fontSize}
          update={updateStyle('lineHeight', 'px')}
          mobileUpdate={updateMobileStyle('lineHeight', 'px')}
          lineHeight={entityWithoutEmulation.styles.lineHeight}
          mobileLineHeight={entityWithoutEmulation.mobileStyles.lineHeight}
          removeEntityStyle={removeEntityStyle('lineHeight')}
          removeEntityMobileStyle={removeEntityMobileStyle('lineHeight')}
        />
        <VerticalSpacing
          change={
            this.context.isMobile
              ? updateMobileOption('verticalSpacing')
              : updateOption('verticalSpacing')
          }
          verticalSpacing={
            this.context.isMobile
              ? entity.mobileOptions.verticalSpacing
              : entity.options.verticalSpacing
          }
        />
        <Range
          labelText="icon size"
          value={parseInt(entity.options.listItemIconSize, 0)}
          update={updateOption('listItemIconSize', 'px')}
          min={8}
        />
        <IconPicker
          labelText="entity_options.icon_picker.title"
          iconList={bulletListIcons}
          value={entity.options.listItemClassName}
          update={updateOption('listItemClassName')}
          searchPlaceholder="settings.components.icon_picker.placeholder"
        />
        <GroupTitle>color</GroupTitle>
        <ColorPicker
          update={updateStyle('backgroundColor')}
          mobileUpdate={updateMobileStyle('backgroundColor')}
          color={entity.styles.backgroundColor}
          mobileColor={entity.mobileStyles.backgroundColor}
          label="bg color"
        />
        <ColorPicker
          update={updateOption('listItemIconColor')}
          mobileUpdate={updateMobileOption('listItemIconColor')}
          color={entity.options.listItemIconColor}
          mobileColor={entity.mobileOptions.listItemIconColor}
          label="icon color"
        />
        <MarginsStyle
          marginTop={entity.styles.marginTop}
          marginRight={entity.styles.marginRight}
          marginBottom={entity.styles.marginBottom}
          marginLeft={entity.styles.marginLeft}
          mobileMarginTop={entity.mobileStyles.marginTop}
          mobileMarginRight={entity.mobileStyles.marginRight}
          mobileMarginBottom={entity.mobileStyles.marginBottom}
          mobileMarginLeft={entity.mobileStyles.marginLeft}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <PaddingStyle
          mobilePadding={entity.mobileStyles.padding}
          padding={entity.styles.padding}
          update={updateStyle('padding')}
          mobileUpdate={updateMobileStyle('padding')}
        />
        <DelayOption
          update={updateOption('delay')}
          delay={entity.options.delay}
        />
        <DeviceAppearance update={update} entity={entity} />
        <Attributes update={updateOption} entity={entity} />
      </React.Fragment>
    )
  }
}

BulletListSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  removeEntityMobileStyle: PropTypes.func.isRequired,
  removeEntityStyle: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  entityWithoutEmulation: types.entity.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default BulletListSettings
