import styled from 'styled-components'
import { editorZindex } from 'client/constants/editorSettings'

const SidebarUi = styled.aside`
  height: calc(100vh - 44px);
  transition: flex-basis 0.2s linear;
  background-color: #fff;
  box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.3);
  color: #414355;
  z-index: ${editorZindex.sidebar};
  position: relative; /* hiding and positioning overlay */
`

export default SidebarUi
