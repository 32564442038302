import styled from 'styled-components'

const QuestionListOrderUi = styled.div`
  display: flex;
  justify-content: space-between;
  width: 35px;
  margin-left: 10px;
`

export default QuestionListOrderUi
