import React from 'react'
import { Trans } from 'react-i18next'
import { pageTypes } from 'common/constants/pageTypes'
import toolBoxTypes from 'common/constants/toolBoxTypes'
import { useActions } from 'common/hooks/useActions'
import * as managementActions from 'client/actions/actionsManagement'
import * as entityActions from 'client/actions/entityActions'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { createStructure } from 'client/utils/createStructureNew'
import BlueButton from '../../../BlueButton'
import ManagementEmpty from './ManagementEmpty'
import ManagementItem from './ManagementItem'
import ManagementAddItemUi from './ui/ManagementAddItemUi'

function PopupManagement() {
  const popups = usePage(pageSelectors.getPopupEntities)
  const pageType = usePresentPage(selectors.getPageType)
  const pageLocale = usePresentPage(selectors.getPageLocale)
  const nextPopupNumber = usePresentPage(selectors.getNextPopupNumber)
  const showPopup = useActions(managementActions.toggleActivePopup)
  const removePopup = useActions(entityActions.removePopup)
  const createPopupStructure = useActions(entityActions.createPopupStructure)

  const createPopup = () => {
    let popupToolBoxType = null
    if (pageType === pageTypes.offerPage || pageType === pageTypes.offerInner) {
      popupToolBoxType = toolBoxTypes.TOOLBOX_POPUP_AGREEMENT
    } else if (pageType === pageTypes.webinarRegistration) {
      popupToolBoxType = toolBoxTypes.TOOLBOX_POPUP_WEBINAR_REGISTRATION
    } else {
      popupToolBoxType = toolBoxTypes.TOOLBOX_POPUP
    }

    const { ascendantId, structure } = createStructure(
      pageLocale,
      popupToolBoxType,
    )
    structure[ascendantId].options.title = `Popup №${nextPopupNumber}`
    createPopupStructure(structure)
    showPopup(ascendantId)
  }

  return (
    <React.Fragment>
      {popups.map(popupEntity => (
        <ManagementItem
          id={popupEntity.id}
          title={popupEntity.options.title}
          display={showPopup}
          remove={removePopup}
          key={popupEntity.id}
        />
      ))}
      {popups.length === 0 && <ManagementEmpty createPopup={createPopup} />}
      <ManagementAddItemUi>
        <BlueButton onClick={createPopup}>
          <Trans parent="span">popup_management.add_popup</Trans>
        </BlueButton>
      </ManagementAddItemUi>
    </React.Fragment>
  )
}

export default PopupManagement
