import styled from 'styled-components'

const AngleHolderUi = styled.div`
  margin: 0 -10px;
  padding: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`

export default AngleHolderUi
