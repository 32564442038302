import React from 'react'
import CommonPricePlanCalculator from 'common/components/entities/PricePlanCalculator'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import PricePlanCalculatorInterface from 'common/types/entities/PricePlanCalculatorInterface'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'
import BaseEntity from 'client/components/core/BaseEntity/BaseEntityNew'
import {
  generateHtmlAttrId,
  generateId,
} from '../../utils/createStructureUtils'

type PricePlanCalculatorProps = {
  entity: PricePlanCalculatorInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

function PricePlanCalculator({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: PricePlanCalculatorProps) {
  const locale = usePresentPage(selectors.getPageLocale)
  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonPricePlanCalculator locale={locale} openPopup={() => undefined} />
    </BaseEntity>
  )
}

export function createPricePlanCalculator(
  parentId: string,
  masterBlockId?: string,
): PricePlanCalculatorInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.PricePlanCalculator,
    parentId: parentId,
    masterBlockId: masterBlockId,
    popupId: null,
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.PricePlanCalculator),
  }
}

export default PricePlanCalculator
