import { useTranslation } from 'react-i18next'
import React from 'react'
import ErrorUi from 'client/components/core/Error/ui/ErrorUi'
import InputUi from 'client/components/core/Sidebar/components/Settings/components/Input/ui/InputUi'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'

interface InputProps {
  label?: string
  helpMessage?: string
  update: (value: string) => void
  value?: string | number
  placeholder?: string
  type?: string
  onBlur?: () => void
  itemStyles?: React.CSSProperties
  error?: string
  disabled?: boolean
}

const Input = ({
  label,
  helpMessage,
  update,
  value,
  placeholder,
  type,
  onBlur,
  itemStyles,
  error,
  disabled = false,
}: InputProps) => {
  const { t } = useTranslation()
  return (
    <OptionWrapper
      labelText={label}
      helpMessage={helpMessage}
      itemStyles={itemStyles}
    >
      <InputUi
        onChange={e => update(e.target.value)}
        value={value}
        placeholder={placeholder ? t(placeholder) : ''}
        type={type}
        onBlur={onBlur}
        disabled={disabled}
      />
      {error ? <ErrorUi dangerouslySetInnerHTML={{ __html: error }} /> : null}
    </OptionWrapper>
  )
}

export default Input
