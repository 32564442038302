import styled from 'styled-components'

const AutomationViewActionWrapperContainerUi = styled.div`
  display: flex;
  width: 100%;
  border: solid 1px #ebedf0;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px 12px;
  background-color: white;
  gap: 12px;
`

export default AutomationViewActionWrapperContainerUi
