import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import copyToClipboard from 'common/utils/copyToClipboard'
import BlueButton from 'client/components/core/BlueButton'
import { Dialog, DialogBody, DialogHeader } from 'client/components/core/Dialog'
import BlockShareDialogCodeUi from './ui/BlockShareDialogCodeUi'

export default function BlockShareDialog({ block, close }) {
  const { t } = useTranslation()
  const [isCopied, setCopied] = useState(false)
  const shareUrl = `${process.env.SYSTEME_IO_HOST}/${
    block.isMaster ? 'master-block' : 'user-block'
  }/share/${block.hash}`

  const handleCopy = () => {
    copyToClipboard(shareUrl)
    setCopied(true)
    setTimeout(close, 1500)
  }

  return (
    <Dialog show close={close} small>
      <DialogHeader close={close} center grayHeader={false}>
        <h2>{t('components.core.blocks_library.share_dialog.title')}</h2>
      </DialogHeader>
      <DialogBody small center>
        <p>{t('components.core.blocks_library.share_dialog.description')}</p>
        <BlockShareDialogCodeUi>{shareUrl}</BlockShareDialogCodeUi>
        <BlueButton onClick={handleCopy}>
          {t('components.core.blocks_library.share_dialog.copy_button.text')}
        </BlueButton>
        {isCopied && (
          <p>
            <small style={{ color: '#56c098' }}>
              {t('components.core.blocks_library.share_dialog.is_copied')}
            </small>
          </p>
        )}
      </DialogBody>
    </Dialog>
  )
}

BlockShareDialog.propTypes = {
  close: PropTypes.func.isRequired,
  block: PropTypes.shape({
    isMaster: PropTypes.bool,
    hash: PropTypes.string.isRequired,
  }),
}
