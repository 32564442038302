import styled from 'styled-components'

const AngleInputContainer = styled.div`
  border-radius: 4px;
  background: #f2f2f2;
  display: flex;
  flex: 1;
  margin: 0 20px;
  justify-content: space-around;
  align-items: center;
`

export default AngleInputContainer
