import React from 'react'
import { useDispatch } from 'react-redux'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import usePresentPage from '../../../../../../hooks/usePresentPage'
import { getRootEntity } from '../../../../../../reducers/pageReducer'
import {
  useOverlayActionsContext,
  useOverlayVisibilityContext,
} from '../SettingsOverlayContext'
import useUpdateEntityMobileOption from '../hooks/useUpdateEntityMobileOption'
import useUpdateEntityMobileStyle from '../hooks/useUpdateEntityMobileStyle'
import useUpdateEntityOption from '../hooks/useUpdateEntityOption'
import useUpdateEntityStyle from '../hooks/useUpdateEntityStyle'
import { LanguageSelector, Width } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { BorderStyle, PaddingStyle, ShadowStyle } from '../styles'
import Background from './components/Background'
import Typography from './components/Typography'

function InlineFormSettings() {
  const entity = usePresentPage(getRootEntity)
  const dispatch = useDispatch()
  const updateStyle = useUpdateEntityStyle(dispatch, entity)
  const updateMobileStyle = useUpdateEntityMobileStyle(dispatch, entity)
  const updateOption = useUpdateEntityOption(dispatch, entity)
  const updateMobileOption = useUpdateEntityMobileOption(dispatch, entity)
  const isOverlayVisible = useOverlayVisibilityContext()
  const { toggle } = useOverlayActionsContext()

  return (
    <React.Fragment>
      <Width
        width={parseInt(entity.styles.maxWidth, 0)}
        update={updateStyle('maxWidth', 'px')}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="bg color"
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        color={entity.styles.backgroundColor}
        mobileColor={entity.mobileStyles.backgroundColor}
      />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <BorderStyle
        updateOption={updateOption}
        updateMobileOption={updateMobileOption}
        updateStyle={updateStyle}
        updateMobileStyle={updateMobileStyle}
        type={entity.options.borderType}
        style={entity.styles.borderStyle}
        width={entity.styles.borderWidth}
        color={entity.styles.borderColor}
        mobileType={entity.mobileOptions.borderType}
        mobileStyle={entity.mobileStyles.borderStyle}
        mobileWidth={entity.mobileStyles.borderWidth}
        mobileColor={entity.mobileStyles.borderColor}
        toggleOverlay={toggle}
        overlayVisible={isOverlayVisible}
      />
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <Typography />
      <LanguageSelector />
      <Background />
    </React.Fragment>
  )
}

export default InlineFormSettings
