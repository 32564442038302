import styled from 'styled-components'

const MenuItemLabelUi = styled.label`
  display: flex;
  align-items: center;
  > input {
    margin-right: 2px;
  }
`

export default MenuItemLabelUi
