import PropTypes from 'prop-types'
import React from 'react'
import videoTypes, { videoTypesNames } from 'common/constants/videoTypes'
import {
  ShadowStyle,
  CornerStyle,
} from 'client/components/core/Sidebar/components/Settings/styles'
import { mimeTypes } from 'client/constants/editorSettings'
import { Select, Input, FileUpload } from '../components'
import { DeviceAppearance, Attributes } from '../options'
import { MarginsStyle } from '../styles'

const webinarVideoTypesNames = { ...videoTypesNames }
delete webinarVideoTypesNames[videoTypes.CUSTOM]
delete webinarVideoTypesNames[videoTypes.YOUTUBE]

const WebinarSessionVideoSettings = props => {
  const { entity, updateStyle, updateMobileStyle, updateOption, update } = props

  return (
    <React.Fragment>
      <Select
        labelText="video type"
        value={entity.options.type}
        update={updateOption('type')}
        choiceList={webinarVideoTypesNames}
      />
      {entity.options.type === videoTypes.FILE && (
        <FileUpload
          fileId={entity.options.srcFileId}
          mimeTypes={mimeTypes.video}
          afterUpload={file => updateOption('srcFileId')(file.id)}
          label="video"
        />
      )}
      {entity.options.type === videoTypes.URL && (
        <Input
          labelText="url"
          value={entity.options.url}
          update={updateOption('url')}
        />
      )}
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

WebinarSessionVideoSettings.propTypes = {
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object,
    styles: PropTypes.object,
    mobileStyles: PropTypes.object,
  }).isRequired,
}

export default WebinarSessionVideoSettings
