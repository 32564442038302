import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as types from 'common/types'
import { MarginsStyle } from '../styles'
import { DeviceAppearance, Attributes } from '../options'
import { Input } from '../components'

class OfferPricePlanSettings extends PureComponent {
  render() {
    const {
      entity,
      updateStyle,
      updateMobileStyle,
      update,
      updateOption,
    } = this.props
    return (
      <React.Fragment>
        <Input
          labelText="entity_settings.offer_price.custom_price_description"
          value={entity.options.customPriceDescription}
          update={updateOption('customPriceDescription')}
        />
        <MarginsStyle
          marginTop={entity.styles.marginTop}
          marginRight={entity.styles.marginRight}
          marginBottom={entity.styles.marginBottom}
          marginLeft={entity.styles.marginLeft}
          mobileMarginTop={entity.mobileStyles.marginTop}
          mobileMarginRight={entity.mobileStyles.marginRight}
          mobileMarginBottom={entity.mobileStyles.marginBottom}
          mobileMarginLeft={entity.mobileStyles.marginLeft}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <DeviceAppearance update={update} entity={entity} />
        <Attributes update={updateOption} entity={entity} />
      </React.Fragment>
    )
  }
}

OfferPricePlanSettings.propTypes = {
  entity: types.entity.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
}

export default OfferPricePlanSettings
