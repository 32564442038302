import styled from 'styled-components'

const BlockWithSectionUi = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  background-color: #56c098;
  font-size: 10px;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  opacity: 0.8;
`

export default BlockWithSectionUi
