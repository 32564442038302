import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import WebinarSessionDateTimeInterface from 'common/types/entities/WebinarSessionDateTimeInterface'
import WebinarSessionLinkInterface from 'common/types/entities/WebinarSessionLinkInterface'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import WebinarSessionDateTime from './entities/WebinarSessionDateTime'
import WebinarSessionLink from './entities/WebinarSessionLink'

export default function createEntityElementForWebinarThankYou(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.WebinarSessionLink:
      return (
        <WebinarSessionLink
          entity={entity as WebinarSessionLinkInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.WebinarSessionDateTime:
      return (
        <WebinarSessionDateTime
          entity={entity as WebinarSessionDateTimeInterface}
          {...additionalProps}
        />
      )
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
