import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { actionsNames, actions } from 'common/components/entities/Survey'
import { useActions } from 'common/hooks/useActions'
import * as managementActions from 'client/actions/actionsManagement'
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  DialogCancel,
  DialogSubmit,
} from 'client/components/core/Dialog'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { SurveyEntityPropTypes } from 'client/components/entities/Survey'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import useManagement, { selectors } from 'client/hooks/useManagement'
import { selectors as pageSelectors } from 'client/hooks/usePresentPage'
import { usePage } from 'client/store'
import typedPageSelectors from 'client/store/page/pageSelectors'
import { PrimaryButton, Input, Select, Switcher } from '../../components'
import TextFont from '../../components/Font/TextFont'
import { DeviceAppearance, FontSize } from '../../options'
import GroupTitle from '../../styleComponents/GroupTitle'
import { MarginsStyle, PaddingStyle, CornerStyleAll } from '../../styles'
import Question from './Question'
import QuestionList from './QuestionList'
import QuestionChangeOrderUi from './ui/QuestionChangeOrderUi'
import QuestionEditHeaderUi from './ui/QuestionEditHeaderUi'
import QuestionListItemDeleteUi from './ui/QuestionListItemDeleteUi'
import QuestionListOrderUi from './ui/QuestionListOrderUi'
import QuestionListUi from './ui/QuestionListUi'

function SurveySettings({
  entity,
  updateStyle,
  update,
  updateMobileStyle,
  updateOption,
  updateMobileOption,
}) {
  const { t, i18n } = useTranslation()
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const popups = usePage(typedPageSelectors.getPopupEntities)
  const { questions } = entity.options
  const [dialogOpened, setDialogOpened] = useState()
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null)
  const showSurveyResult = useActions(managementActions.showSurveyResult)
  const isSurveyResult = useManagement(selectors.isSurveyResult)
  const selectedQuestion = questions[selectedQuestionIndex]
  const { isMobile } = useDeviceModeContext()
  const fixedT = i18n.getFixedT(pageLocale)

  const updateQuestion = index => question => {
    updateOption('questions')([
      ...questions.slice(0, index),
      question,
      ...questions.slice(index + 1),
    ])
  }

  const addQuestion = () => {
    updateOption('questions')([
      ...questions,
      {
        id: uuid(),
        title: fixedT('entity_settings.survey.default_question_title', {
          num: questions.length + 1,
        }),
        subTitle: ``,
        answers: [createAnswer()],
      },
    ])
  }

  const createAnswer = () => ({
    id: uuid(),
    title: fixedT('entity_settings.survey.default_answer_title'),
  })

  const addAnswer = () => {
    const updatedQuestion = {
      ...selectedQuestion,
      answers: [...selectedQuestion.answers, createAnswer()],
    }
    updateQuestion(selectedQuestionIndex)(updatedQuestion)
  }

  const moveDown = index => () => {
    updateOption('questions')([
      ...questions.slice(0, index),
      questions[index + 1],
      questions[index],
      ...questions.slice(index + 2),
    ])
  }

  const moveUp = index => () => {
    updateOption('questions')([
      ...questions.slice(0, index - 1),
      questions[index],
      questions[index - 1],
      ...questions.slice(index + 1),
    ])
  }

  const removeQuestion = index => () => {
    confirm(t('entity_settings.survey.remove_question_warning')) &&
      updateOption('questions')([
        ...questions.slice(0, index),
        ...questions.slice(index + 1),
      ])
  }

  const updateAction = action => {
    let updatedOptions
    switch (action) {
      case actions.openPopup:
        updatedOptions = {
          ...entity.options,
          redirectUrl: '',
          action,
        }
        break
      case actions.redirect:
        updatedOptions = {
          ...entity.options,
          popupId: null,
          action,
        }
        break
      case actions.nothing:
      default:
        updatedOptions = {
          ...entity.options,
          popupId: null,
          redirectUrl: '',
          action,
        }
    }

    update({
      ...entity,
      options: updatedOptions,
    })
  }

  const updateBorder = value => {
    update({
      ...entity,
      options: {
        ...entity.options,
        answerBorderRadius: {
          borderTopLeftRadius: value,
          borderTopRightRadius: value,
          borderBottomRightRadius: value,
          borderBottomLeftRadius: value,
        },
      },
    })
  }

  const updateMobileBorder = value => {
    update({
      ...entity,
      mobileOptions: {
        ...entity.mobileOptions,
        answerBorderRadius: {
          borderTopLeftRadius: value,
          borderTopRightRadius: value,
          borderBottomRightRadius: value,
          borderBottomLeftRadius: value,
        },
      },
    })
  }

  const textFontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const textFontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const textFontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  const handleTextFontChange = styles => {
    isMobile
      ? update({
          ...entity,
          mobileStyles: {
            ...entity.mobileStyles,
            ...styles,
          },
        })
      : update({
          ...entity,
          styles: {
            ...entity.styles,
            ...styles,
          },
        })
  }

  const hideDialog = () => setDialogOpened(false)
  const backToList = () => setSelectedQuestionIndex(null)
  const selectQuestion = index => () => setSelectedQuestionIndex(index)

  return (
    <React.Fragment>
      <Switcher
        firstLabel="entity_settings.survey.mode.show_survey"
        isFirstActive={!isSurveyResult}
        handleFirstButton={() => showSurveyResult(false)}
        secondLabel="entity_settings.survey.mode.show_result"
        isSecondActive={isSurveyResult}
        handleSecondButton={() => showSurveyResult(true)}
      />
      <PrimaryButton onClick={() => setDialogOpened(true)}>
        entity_settings.survey.edit_label
      </PrimaryButton>
      <Select
        update={updateAction}
        choiceList={actionsNames}
        labelText="entity_settings.survey.choose_action"
        value={entity.options.action}
      />
      {entity.options.action === actions.redirect && (
        <Input
          labelText="entity_settings.survey.redirect_url.label"
          update={updateOption('redirectUrl')}
          placeholder="entity_settings.survey.redirect_url.placeholder"
          value={entity.options.redirectUrl}
        />
      )}
      {entity.options.action === actions.openPopup && (
        <Select
          update={updateOption('popupId')}
          choiceList={popups.reduce(
            (acc, popup) => ({ ...acc, [popup.id]: popup.options.title }),
            {},
          )}
          firstOption="entity_settings.survey.choose_popup"
          value={entity.options.popupId}
          needTranslate={false}
        />
      )}
      <Dialog width={900} show={dialogOpened}>
        <DialogHeader close={hideDialog}>
          <QuestionEditHeaderUi>
            {t('entity_settings.survey.edit_header')}
          </QuestionEditHeaderUi>
        </DialogHeader>
        <DialogBody>
          {selectedQuestionIndex !== null ? (
            <Question
              key={selectedQuestion.id}
              question={selectedQuestion}
              update={updateQuestion(selectedQuestionIndex)}
              questionsToRedirect={questions.slice(selectedQuestion)}
            />
          ) : (
            <QuestionListUi>
              {questions.map((question, index, arr) => (
                <QuestionList
                  number={index + 1}
                  key={question.id}
                  title={question.title}
                  select={selectQuestion(index)}
                >
                  <React.Fragment>
                    {arr.length > 1 && (
                      <QuestionListItemDeleteUi
                        className="far fa-trash-alt"
                        onClick={removeQuestion(index)}
                      />
                    )}
                    <QuestionListOrderUi>
                      {index !== 0 && (
                        <QuestionChangeOrderUi
                          className="fas fa-arrow-up"
                          key="up"
                          onClick={moveUp(index)}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      {index + 1 !== arr.length && (
                        <QuestionChangeOrderUi
                          className="fas fa-arrow-down"
                          key="down"
                          onClick={moveDown(index)}
                        />
                      )}
                    </QuestionListOrderUi>
                  </React.Fragment>
                </QuestionList>
              ))}
            </QuestionListUi>
          )}
        </DialogBody>
        <DialogFooter>
          {selectedQuestionIndex === null ? (
            <DialogSubmit onClick={addQuestion}>
              {t('entity_settings.survey.add_question')}
            </DialogSubmit>
          ) : (
            <React.Fragment>
              <DialogCancel onClick={backToList}>
                {t('entity_settings.survey.back_to_list')}
              </DialogCancel>
              <DialogSubmit onClick={addAnswer}>
                {t('entity_settings.survey.add_answer')}
              </DialogSubmit>
            </React.Fragment>
          )}
        </DialogFooter>
      </Dialog>
      {!isSurveyResult && (
        <React.Fragment>
          <GroupTitle>typography</GroupTitle>
          <TextFont
            labelText="entity_settings.survey.font_family"
            fontFamily={textFontFamily}
            fontStyle={textFontStyle}
            fontWeight={textFontWeight}
            update={handleTextFontChange}
          />
          <FontSize
            labelText="entity_settings.survey.question_title_font_size"
            update={updateOption('questionTitleFontSize', 'px')}
            mobileUpdate={updateMobileOption('questionTitleFontSize', 'px')}
            fontSize={entity.options.questionTitleFontSize}
            mobileFontSize={
              entity.mobileOptions.questionTitleFontSize ||
              entity.options.questionTitleFontSize
            }
          />
          <FontSize
            labelText="entity_settings.survey.question_sub_title_font_size"
            update={updateOption('questionSubTitleFontSize', 'px')}
            mobileUpdate={updateMobileOption('questionSubTitleFontSize', 'px')}
            fontSize={entity.options.questionSubTitleFontSize}
            mobileFontSize={
              entity.mobileOptions.questionSubTitleFontSize ||
              entity.options.questionSubTitleFontSize
            }
          />
          <FontSize
            labelText="entity_settings.survey.answer_font_size"
            update={updateOption('answerFontSize', 'px')}
            mobileUpdate={updateMobileOption('answerFontSize', 'px')}
            fontSize={entity.options.answerFontSize}
            mobileFontSize={
              entity.mobileOptions.answerFontSize ||
              entity.options.answerFontSize
            }
          />
          <ColorPicker
            update={updateOption('questionTitleColor')}
            color={entity.questionTitleColor}
            label="entity_settings.survey.question_title_color"
            singleColorMode
          />
          <ColorPicker
            update={updateOption('questionSubTitleColor')}
            color={entity.questionSubTitleColor}
            label="entity_settings.survey.question_sub_title_color"
            singleColorMode
          />
          <ColorPicker
            update={updateOption('answerColor')}
            color={entity.answerColor}
            label="entity_settings.survey.answer_color"
            singleColorMode
          />
          <ColorPicker
            update={updateOption('answerBackgroundColor')}
            color={entity.answerBackgroundColor}
            label="entity_settings.survey.answer_background_color"
            singleColorMode
          />
          <ColorPicker
            update={updateOption('answerOutlineColor')}
            color={entity.answerOutlineColor}
            label="entity_settings.survey.answer_outline_color"
            singleColorMode
          />
        </React.Fragment>
      )}
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <CornerStyleAll
        topRight={entity.options.answerBorderRadius.borderTopRightRadius}
        mobileTopRight={
          entity.mobileOptions.answerBorderRadius.borderTopRightRadius ||
          entity.options.answerBorderRadius.borderTopRightRadius
        }
        update={updateBorder}
        mobileUpdate={updateMobileBorder}
      />
      <DeviceAppearance update={update} entity={entity} />
    </React.Fragment>
  )
}

SurveySettings.propTypes = {
  entity: SurveyEntityPropTypes,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default SurveySettings
