import styled from 'styled-components'

const DialogWrapperUi = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: ${p => p.height || '250px'};
  flex-direction: column;
  width: 100%;
  padding: 30px 50px;
`

export default DialogWrapperUi
