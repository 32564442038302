import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import ButtonLoader from 'common/components/core/ButtonLoader'
import { BadRequest, PageValidationError } from 'common/errors'
import { AccessDenied } from 'common/errors/AccessDenied'
import { GatewayError } from 'common/errors/GatewayError'
import { NetworkError } from 'common/errors/NetworkError'
import { NotFound } from 'common/errors/NotFound'
import { useActions } from 'common/hooks/useActions'
import * as pageActions from 'client/actions/pageActions'
import GreenButton from 'client/components/core/GreenButton'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'

function PreviewButton() {
  const { t, i18n } = useTranslation()
  const isLocaleInitialised = i18n.language
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const isTemplate = usePage(pageSelectors.isPageTemplate)
  const previewPage = useActions(pageActions.previewPage)

  async function handlePreview() {
    setIsLoading(true)
    try {
      const previewWindow = window.open('', 'preview')
      const { data } = await previewPage(isTemplate)
      setIsLoading(false)
      previewWindow.location.href = data
    } catch (error) {
      if (error instanceof PageValidationError) {
        dispatch(pageActions.pageValidationFail(error.messages))
      } else if (error instanceof NotFound) {
        window.Rollbar.error(error)
      } else if (error instanceof AccessDenied) {
        dispatch(pageActions.previewPageFail('core.errors.login_required'))
      } else if (error instanceof NetworkError) {
        dispatch(pageActions.previewPageFail('core.errors.no_connection'))
      } else if (error instanceof GatewayError) {
        dispatch(pageActions.previewPageFail('core.errors.preview'))
        window.Rollbar.error(error)
      } else if (error instanceof BadRequest) {
        dispatch(pageActions.pageValidationFail(error.response.data))
      } else {
        dispatch(pageActions.previewPageFail('core.errors.preview'))
      }
    }

    setIsLoading(false)
  }

  return (
    <GreenButton
      onClick={handlePreview}
      title={t('core.header.preview')}
      disabled={!isLocaleInitialised || isLoading}
    >
      {isLoading && <ButtonLoader color="rgb(107, 124, 147)" />}
      <span className="far fa-eye" />
    </GreenButton>
  )
}

export default PreviewButton
