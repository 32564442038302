import styled from 'styled-components'

const SettingsTitleIconUi = styled.div`
  margin-left: 8px;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.9;
`

export default SettingsTitleIconUi
