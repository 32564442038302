import styled, { css } from 'styled-components'

const SwitcherUi = styled.div`
  ${p =>
    p.styles ||
    css`
      padding: 0 20px;
      margin-top: 20px;
    `}
`

export default SwitcherUi
