import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccessTypeEnum } from 'client/types/AutomationRulesActionsEditInterface'
import { AutomationRuleContentTypesEnum } from 'client/types/AutomationRulesActionsInterface'
import RadioInputUi from './ui/RadioInputUi'
import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'

interface CourseAccessSelectorProps {
  changeAccessType: (value: AccessTypeEnum) => void
  type: AutomationRuleActionsEnum.enroll_in_course_bundle | AutomationRuleActionsEnum.enroll_in_course
  value: AccessTypeEnum
}

function CourseAccessSelector({
  changeAccessType,
  type,
  value,
}: CourseAccessSelectorProps) {
  const { t } = useTranslation()

  return (
    <div>
      <label htmlFor={`${type}-full-access-type`}>
        <RadioInputUi
          type="radio"
          id={`${type}-full-access-type`}
          onChange={() => changeAccessType(AccessTypeEnum.fullAccess)}
          checked={value === AccessTypeEnum.fullAccess}
        />
        {t(AutomationRuleContentTypesEnum.full_access)}
      </label>
      <label htmlFor={`${type}-dripping-content-access-type`}>
        <RadioInputUi
          type="radio"
          id={`${type}-dripping-content-access-type`}
          onChange={() => changeAccessType(AccessTypeEnum.drippingContent)}
          checked={value === AccessTypeEnum.drippingContent}
          marginLeft={10}
        />
        {t(AutomationRuleContentTypesEnum.dripping_content)}
      </label>
    </div>
  )
}

export default CourseAccessSelector
