import {
  REMOVE_NODE,
  TOGGLE_HIGHLIGHT,
  CHANGE_PAGE_LOCALE,
  EXIT,
  UPDATE_GLOBAL_SETTING,
  UPDATE_SEO_PROPERTY,
  UPDATE_TRACKING_PROPERTY,
} from './actionTypes'

export const remove = (id, parentId) => dispatch => {
  dispatch({
    type: REMOVE_NODE,
    payload: { id, parentId },
  })
}

export const toggleHighlight = id => dispatch =>
  dispatch({
    type: TOGGLE_HIGHLIGHT,
    payload: { id },
  })

export const exit = exitUrl => dispatch => {
  dispatch({
    type: EXIT,
  })

  window.location.href = exitUrl
}

export const changeLocale = locale => dispatch => {
  dispatch({
    type: CHANGE_PAGE_LOCALE,
    payload: locale,
  })
}

export const updateSeoProperty = (property, value) => ({
  type: UPDATE_SEO_PROPERTY,
  payload: { property, value },
})

export const updateGlobalSetting = (setting, value) => ({
  type: UPDATE_GLOBAL_SETTING,
  payload: { setting, value },
})

export const updateTrackingProperty = (property, value) => ({
  type: UPDATE_TRACKING_PROPERTY,
  payload: {
    property,
    value,
  },
})
