import React from 'react'

function NewUserIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="40"
      height="44"
      viewBox="0 0 40 44"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.7534 10.8353C28.7534 16.3019 24.3217 20.7339 18.8548 20.7339C13.3885 20.7339 8.95703 16.3022 8.95703 10.8353C8.95703 5.36877 13.3888 0.9375 18.8548 0.9375C24.3214 0.9375 28.7534 5.36892 28.7534 10.8353Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.0426 43.63H0.90625C0.90625 33.7153 8.94069 25.6816 18.8546 25.6816C21.994 25.6816 24.9453 26.4901 27.5123 27.9106C24.8301 29.5717 23.0448 32.5395 23.0448 35.9266C23.0448 39.1103 24.6238 41.9262 27.0422 43.6302L27.0426 43.63Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4844 28.9473H34.4601V42.9371H30.4844V28.9473Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4688 33.9492H39.4586V37.925H25.4688V33.9492Z"
      />
    </svg>
  )
}

export default NewUserIcon
