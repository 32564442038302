import React from 'react'
import CommonButton from 'common/components/entities/Button/Button'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { SalesRedirectionButtonInterface } from 'common/types/entities/ButtonInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { generateHtmlAttrId, generateId } from 'client/utils/createStructureNew'
import { EntityProps } from '../../../types'

function SalesRedirectionButton({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<SalesRedirectionButtonInterface>) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      flex
      flexColumn
    >
      <CommonButton
        iconClassNameBefore={entity.iconClassNameBefore}
        iconClassNameAfter={entity.iconClassNameAfter}
        text={entity.text}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        subText={entity.subText}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        alignSelf={entity.alignSelf}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        attrId={entity.htmlAttrId}
        width={entity.width}
        mobileWidth={entity.mobileWidth}
        textColor={entity.textColor}
        mobileTextColor={entity.mobileTextColor}
        mobileSubTextColor={entity.mobileSubTextColor}
        subTextColor={entity.subTextColor}
        textFontSize={entity.textFontSize}
        mobileTextFontSize={entity.mobileTextFontSize}
        subTextFontSize={entity.subTextFontSize}
        mobileSubTextFontSize={entity.mobileSubTextFontSize}
        textFontFamily={entity.textFontFamily}
        textFontWeight={entity.textFontWeight}
        textFontStyle={entity.textFontStyle}
        subTextFontFamily={entity.subTextFontFamily}
        subTextFontWeight={entity.subTextFontWeight}
        subTextFontStyle={entity.subTextFontStyle}
        mobileTextFontFamily={entity.mobileTextFontFamily}
        mobileTextFontWeight={entity.mobileTextFontWeight}
        mobileTextFontStyle={entity.mobileTextFontStyle}
        mobileSubTextFontFamily={entity.mobileSubTextFontFamily}
        mobileSubTextFontWeight={entity.mobileSubTextFontWeight}
        mobileSubTextFontStyle={entity.mobileSubTextFontStyle}
        boxShadow={entity.boxShadow}
        mobileBoxShadow={entity.mobileBoxShadow}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        hover={entity.hover}
        mobileHover={entity.mobileHover}
      />
    </BaseEntityNew>
  )
}

export function createSalesRedirectionButton(
  parentId: string,
  masterBlockId?: string,
): SalesRedirectionButtonInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.SalesRedirectionButton,
    parentId: parentId,
    masterBlockId: masterBlockId,
    text: '',
    textFontSize: 20,
    subTextFontSize: 14,
    lineHeight: 23,
    textColor: 'rgba(255, 255, 255, 1)',
    subTextColor: 'rgba(255,255,255,0.8)',
    backgroundColor: 'rgba(1, 116, 199, 1)',
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2)',
    mobileBoxShadow: 'none',
    width: 'auto',
    mobileWidth: '100%',
    alignSelf: 'center',
    border: {
      width: 1,
      style: 'none',
      radius: {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
      },
    },
    margin: {
      marginTop: 25,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 9,
      paddingRight: 25,
      paddingBottom: 9,
      paddingLeft: 25,
    },
    mobilePadding: {
      paddingTop: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingLeft: 5,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.SalesRedirectionButton),
    childIds: [],
  }
}

export default SalesRedirectionButton
