import pathToRegexp from 'path-to-regexp'

export default function usePageRouteDetector() {
  const checkPattern = uri =>
    pathToRegexp(uri, []).exec(window.location.pathname)
  const pageMatch = checkPattern('/page/:id/edit')
  let pageId
  let isTemplate = false
  if (pageMatch && pageMatch[1] && Number.isInteger(+pageMatch[1])) {
    pageId = pageMatch[1]
  } else {
    const templateMatch = checkPattern('/page/template/:id/edit')
    if (templateMatch && templateMatch[1]) {
      pageId = templateMatch[1]
      isTemplate = true
    }
  }

  return { pageId, isTemplate }
}
