import React, { memo, useCallback, useRef, useState } from 'react'
import CommonRow from 'common/components/entities/Row'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import { color } from 'client/components/core/BaseEntity'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { OptionalRefInterface } from 'client/components/entities/Row/components/ColumnAlignControls'
import RowChildren from 'client/components/entities/Row/components/RowChildren'
import { useRowRestrictions } from 'client/components/entities/Row/hooks/useRowRestrictions'
import { useBackgroundImage } from 'client/hooks/useBackgroundImage'
import { EntityProps } from 'client/types'
import {
  getMinColumnWidth,
  getPaddingLeft,
  getWidthWithoutPadding,
} from './utils/columnResizeUtils'

const Row = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<RowInterface>) => {
  const externalActionsRef = useRef<OptionalRefInterface>(null)
  const ref = useRef(null)
  const { isReadOnly, dropBoxAllowedEntityIds } = useRowRestrictions(entity)
  const [width, setWidth] = useState(0)
  const [paddingLeft, setPaddingLeft] = useState(0)
  const [isHovered, setIsHovered] = useState(false)
  const setRef = useCallback(node => {
    if (node !== null) {
      setWidth(getWidthWithoutPadding(node))
      setPaddingLeft(getPaddingLeft(node))
    }
    // Save a reference to the node
    ref.current = node
  }, [])
  const enableHover = () => setIsHovered(true)
  const disableHover = () => setIsHovered(false)

  const { backgroundImage, mobileBackgroundImage } = useBackgroundImage({
    backgroundFileId: entity.backgroundFileId,
    mobileBackgroundFileId: entity.mobileBackgroundFileId,
  })

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      hoverColor={color.blue}
      isSaveAllowed={!isReadOnly}
      isRemoveAllowed={!isReadOnly}
      isCopyAllowed={!isReadOnly}
      dropBoxAllowedEntityIds={dropBoxAllowedEntityIds}
      externalActionsRef={externalActionsRef}
    >
      <CommonRow
        forwardedRef={setRef}
        disableColumnGap={entity.childIds.length === 1}
        {...entity}
        backgroundImage={backgroundImage}
        mobileBackgroundImage={mobileBackgroundImage}
        onMouseOver={enableHover}
        onMouseOut={disableHover}
      >
        <RowChildren
          entity={entity}
          minColumnWidth={getMinColumnWidth(width)}
          isHovered={isHovered}
          paddingLeft={paddingLeft}
          externalActionsRef={externalActionsRef}
        />
      </CommonRow>
    </BaseEntityNew>
  )
}

export default memo(Row)
