import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  groups,
  groupsTitles,
} from 'client/components/core/Sidebar/components/Toolbox/toolboxGroups'
import toolboxItems from 'client/components/core/Sidebar/components/Toolbox/toolboxItems'
import ToolboxGroupTitleUi from 'client/components/core/Sidebar/components/Toolbox/ui/ToolboxGroupTitleUi'
import ToolboxGroupUi from 'client/components/core/Sidebar/components/Toolbox/ui/ToolboxGroupUi'
import ToolboxItemListUi from 'client/components/core/Sidebar/components/Toolbox/ui/ToolboxItemListUi'
import LibraryElementEnum from 'client/enums/LibraryElementEnum'
import useManagement from 'client/hooks/useManagement'
import pageToolboxMap from 'client/pages/pageToolboxMap'
import managementSelectors from 'client/store/management/managementSelectors'
import LibraryElement from '../Toolbox/LibraryElement'

const FRONT_DEVS_IDS = [8614689, 1408, 7435594, 6970073, 8417501]
const CALENDAR_TESTERS_IDS = [...FRONT_DEVS_IDS, 161, 534656, 2251] // Aurelien, Oussama, Test accounts

function Elements({ pageType }) {
  const isUserAdmin = useManagement(managementSelectors.isUserAdmin)
  const { t } = useTranslation()
  const userId = useManagement(managementSelectors.getUserId)

  const libraryElements = { ...pageToolboxMap[pageType] }

  return (
    <React.Fragment>
      {Object.keys(libraryElements).map(groupItemsKey => (
        <ToolboxGroupUi key={groupItemsKey}>
          <ToolboxGroupTitleUi>
            <Trans parent={'span'}>{groupsTitles[groupItemsKey]}</Trans>
          </ToolboxGroupTitleUi>
          <ToolboxItemListUi>
            {libraryElements[groupItemsKey].map(libraryElementType => {
              if (
                libraryElementType === LibraryElementEnum.LibraryCalendar &&
                !CALENDAR_TESTERS_IDS.includes(userId)
              ) {
                return
              }
              return (
                <LibraryElement
                  key={libraryElementType}
                  type={libraryElementType}
                  className={toolboxItems[libraryElementType].className}
                  iconRenderer={toolboxItems[libraryElementType].iconRenderer}
                  title={t(toolboxItems[libraryElementType].title)}
                  isElementRemoved={t(
                    toolboxItems[libraryElementType].isElementRemoved,
                  )}
                  tooltipText={t(toolboxItems[libraryElementType].tooltipText)}
                />
              )
            })}
          </ToolboxItemListUi>
        </ToolboxGroupUi>
      ))}
      {isUserAdmin && (
        <ToolboxGroupUi key={groups.sio}>
          <ToolboxGroupTitleUi>
            <Trans parent={'span'}>{groupsTitles[groups.sio]}</Trans>
          </ToolboxGroupTitleUi>
          <ToolboxItemListUi>
            <LibraryElement
              key={LibraryElementEnum.LibraryLanguageSwitcher}
              type={LibraryElementEnum.LibraryLanguageSwitcher}
              className={
                toolboxItems[LibraryElementEnum.LibraryLanguageSwitcher]
                  .className
              }
              title={t(
                toolboxItems[LibraryElementEnum.LibraryLanguageSwitcher].title,
              )}
            />
            <LibraryElement
              key={LibraryElementEnum.LibraryPricePlanCalculator}
              type={LibraryElementEnum.LibraryPricePlanCalculator}
              className={
                toolboxItems[LibraryElementEnum.LibraryPricePlanCalculator]
                  .className
              }
              title={t(
                toolboxItems[LibraryElementEnum.LibraryPricePlanCalculator]
                  .title,
              )}
            />
          </ToolboxItemListUi>
        </ToolboxGroupUi>
      )}
    </React.Fragment>
  )
}

export default Elements
