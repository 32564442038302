import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  countdownActionNames,
  countdownActions,
  countdownTypeNames,
  countdownTypes,
} from 'common/constants/countdownTypes'
import { pageTypes } from 'common/constants/pageTypes'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import DatePicker from 'client/components/core/Sidebar/components/Settings/options/DateTimePicker/DatePicketOld'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'
import { useManagement } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import { setDateWithTimezone } from 'client/utils/date-time-format'
import { Input, Range, Select } from '../components'
import TextFont from '../components/Font/TextFont'
import { Attributes, DeviceAppearance } from '../options'
import { FontSize, MarginsStyle } from '../styles'

const getDate = (hours, minutes, seconds) => {
  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)
  date.setSeconds(seconds)

  return date
}

function CountdownSettings(props) {
  const { t } = useTranslation()

  const pageType = usePresentPage(selectors.getPageType)

  const {
    entity,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateOptions,
    updateMobileOptions,
    toggleOverlay,
    overlayVisible,
    update,
  } = props

  const {
    options: {
      dailyOptions,
      dateOptions,
      minuteOptions,
      timeStyles,
      labelStyles,
    },
    mobileOptions: {
      timeStyles: mobileTimeStyles = {},
      labelStyles: mobileLabelStyles = {},
    },
  } = entity

  const userTimeZone = useManagement(managementSelectors.getUserTimezone)

  const updateLabelStyles = (style, unitOfMeasure) => value => {
    updateOptions({
      ...entity.options,
      labelStyles: {
        ...labelStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const updateTimeStyles = (style, unitOfMeasure) => value => {
    updateOptions({
      ...entity.options,
      timeStyles: {
        ...timeStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const updateMobileLabelStyles = (style, unitOfMeasure) => value => {
    updateMobileOptions({
      ...entity.mobileOptions,
      labelStyles: {
        ...mobileLabelStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const updateMobileTimeStyles = (style, unitOfMeasure) => value => {
    updateMobileOptions({
      ...entity.mobileOptions,
      timeStyles: {
        ...mobileTimeStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const updateDateOptions = option => value => {
    updateOptions({
      ...entity.options,
      dateOptions: {
        ...dateOptions,
        [option]: value,
      },
    })
  }

  const updateMinuteOptions = option => value => {
    let createdTime = null
    if (minuteOptions[option] !== value) {
      createdTime = new Date().getTime()
    }
    updateOptions({
      ...entity.options,
      minuteOptions: {
        ...minuteOptions,
        [option]: value,
        createdTime,
      },
    })
  }

  const generateDate = time => {
    const date = new Date(entity.options.dateOptions.date)
    if (time.includes('PM')) {
      const hours = time.split(':')
      if (time.includes('12')) {
        hours[0] = '00'
      }
      date.setHours(parseInt(hours[0]) + 12, parseInt(hours[1]))
    } else if (time.includes('AM')) {
      const hours = time.split(':')
      if (time.includes('12')) {
        hours[0] = '00'
      }
      date.setHours(parseInt(hours[0]), parseInt(hours[1]))
    } else {
      const hours = time.split(':')
      date.setHours(parseInt(hours[0]), parseInt(hours[1]))
    }
    return date
  }

  const updateDailyOptions = time => {
    const date = setDateWithTimezone({
      date: generateDate(time),
      timeZone: userTimeZone,
      subtract: true,
    })
    const { hours, minutes, seconds } = entity.options.dailyOptions
    let createdTime = null
    if (
      hours !== date.getHours() ||
      minutes !== date.getMinutes() ||
      seconds !== date.getSeconds()
    ) {
      createdTime = new Date().getTime()
    }
    updateOptions({
      ...entity.options,
      dailyOptions: {
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
        createdTime,
      },
    })
  }

  let calculatedCountdownTypeNames = { ...countdownTypeNames }

  if (pageType !== pageTypes.salesPage && pageType !== pageTypes.offerPage) {
    delete calculatedCountdownTypeNames[countdownTypes.deadline]
  }

  const { isMobile } = useDeviceModeContext()

  const handleFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        delete updatedEntity.mobileStyles.fontWeight
        delete updatedEntity.mobileStyles.fontStyle
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            fontFamily: fontProperties.fontFamily,
          },
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.fontFileId
        ) {
          delete updatedEntity.mobileOptions.fontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.mobileStyles.fontWeight
          delete updatedEntity.mobileStyles.fontStyle
        }
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            ...fontProperties,
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        delete updatedEntity.styles.fontWeight
        delete updatedEntity.styles.fontStyle
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            fontFamily: fontProperties.fontFamily,
          },
          options: {
            ...updatedEntity.options,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (updatedEntity.options.fontFileId) {
          delete updatedEntity.options.fontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.styles.fontWeight
          delete updatedEntity.styles.fontStyle
        }
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            ...fontProperties,
          },
        })
      }
    }
  }

  const handleTimeChange = time => {
    const date = setDateWithTimezone({
      date: generateDate(time),
      timeZone: userTimeZone,
      subtract: true,
    })
    updateDateOptions('date')(date)
  }

  const fontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const fontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const fontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  return (
    <React.Fragment>
      <Select
        update={updateOption('type')}
        choiceList={calculatedCountdownTypeNames}
        labelText={`entity_settings.countdown.type`}
        helpMessage={
          (countdownTypes.date === entity.options.type ||
            countdownTypes.daily === entity.options.type) &&
          t('entity_settings.countdown.timezone_label', {
            timezoneValue: userTimeZone,
          })
        }
        value={entity.options.type}
      />
      {countdownTypes.date === entity.options.type && (
        <DatePicker
          update={(date, rawValue) => {
            const dateWithTimezone = setDateWithTimezone({
              date,
              timeZone: userTimeZone,
              subtract: true,
            })
            updateDateOptions('date')(dateWithTimezone, rawValue)
          }}
          updateTime={handleTimeChange}
          datetime={
            dateOptions.date &&
            setDateWithTimezone({
              date: new Date(dateOptions.date),
              timeZone: userTimeZone,
            })
          }
        />
      )}
      {countdownTypes.minute === entity.options.type && (
        <React.Fragment>
          <Range
            labelText="entity_settings.countdown.delay.days"
            value={minuteOptions.days}
            update={updateMinuteOptions('days')}
            max={99}
          />
          <Range
            labelText="entity_settings.countdown.delay.hours"
            value={minuteOptions.hours}
            update={updateMinuteOptions('hours')}
            max={23}
          />
          <Range
            labelText="entity_settings.countdown.delay.minutes"
            value={minuteOptions.minutes}
            update={updateMinuteOptions('minutes')}
            min={0}
            max={59}
          />
          <Range
            labelText="entity_settings.countdown.delay.seconds"
            value={minuteOptions.seconds}
            update={updateMinuteOptions('seconds')}
            min={0}
            max={59}
          />
        </React.Fragment>
      )}
      {countdownTypes.daily === entity.options.type && (
        <React.Fragment>
          <DatePicker
            updateTime={updateDailyOptions}
            datetime={setDateWithTimezone({
              date: getDate(
                dailyOptions.hours,
                dailyOptions.minutes,
                dailyOptions.seconds,
              ),
              timeZone: userTimeZone,
            })}
            showDate={false}
          />
        </React.Fragment>
      )}
      {countdownTypes.deadline !== entity.options.type && (
        <Select
          update={updateOption('action')}
          choiceList={countdownActionNames}
          labelText="entity_settings.countdown.action"
          value={entity.options.action}
        />
      )}
      {countdownTypes.deadline !== entity.options.type &&
        countdownActions.redirection === entity.options.action && (
          <Input
            labelText="entity_settings.countdown.redirection_url"
            update={updateOption('redirectUrl')}
            placeholder="entity_settings.countdown.placeholder"
            value={entity.options.redirectUrl}
          />
        )}
      <FontSize
        labelText="settings_styles.countdown.time_font_size.label"
        update={updateTimeStyles('fontSize', 'px')}
        mobileUpdate={updateMobileTimeStyles('fontSize', 'px')}
        fontSize={timeStyles.fontSize}
        mobileFontSize={mobileTimeStyles ? mobileTimeStyles.fontSize : '20px'}
      />
      <FontSize
        labelText="settings_styles.countdown.text_font_size.label"
        update={updateLabelStyles('fontSize', 'px')}
        mobileUpdate={updateMobileLabelStyles('fontSize', 'px')}
        fontSize={labelStyles.fontSize}
        mobileFontSize={mobileLabelStyles ? mobileLabelStyles.fontSize : '14px'}
      />
      <TextFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateTimeStyles('color')}
        mobileUpdate={updateMobileTimeStyles('color')}
        color={timeStyles.color}
        mobileColor={mobileTimeStyles.color}
        label="entity_settings.start_timer.time_color"
      />
      <ColorPicker
        update={updateLabelStyles('color')}
        mobileUpdate={updateMobileLabelStyles('color')}
        color={labelStyles.color}
        mobileColor={mobileLabelStyles.color}
        label="entity_settings.start_timer.label_color"
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

CountdownSettings.propTypes = {
  overlayVisible: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  updateMobileOptions: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object,
    styles: PropTypes.object,
    mobileStyles: PropTypes.object,
  }).isRequired,
}

export default CountdownSettings
