import React, { useState } from 'react'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import LayoutContainerUi from './ui/LayoutContainerUi'
import LayoutsContainerUi from './ui/LayoutsContainerUi'
import {
  firstLayoutRenderer,
  secondLayoutRenderer,
  thirdLayoutRenderer,
  fourthLayoutRenderer,
  fifthLayoutRenderer,
  LayoutType,
  addLeftPaddingToPostCard,
  clearPostCardPaddings,
} from './utils'

const layouts = [
  { render: firstLayoutRenderer, type: LayoutType.Grid3x3 },
  { render: secondLayoutRenderer, type: LayoutType.GridLongShort3ShortLong },
  { render: thirdLayoutRenderer, type: LayoutType.Grid1Big3Under },
  { render: fourthLayoutRenderer, type: LayoutType.GridRows3x2x3 },
  { render: fifthLayoutRenderer, type: LayoutType.Grid1Big3Side },
]

type PostLayoutSettingProps<T> = {
  updateEntity: (value: T) => void
  entity: T
  showGroupTitle?: boolean
  groupTitle?: string
}

function PostListingLayoutSetting<T extends BlogPostListingInterface>({
  updateEntity,
  entity,
  showGroupTitle = true,
  groupTitle = 'entity_settings.blog_post_listing.post_listing.title',
}: PostLayoutSettingProps<T>) {
  const [activeLayout, setActiveLayout] = useState(entity.layoutType)

  const updateLayout = (
    event: React.MouseEvent<HTMLDivElement>,
    layoutType: LayoutType,
  ) => {
    if (layoutType === activeLayout) {
      const updatedEntity = { ...entity }
      delete updatedEntity.layoutType
      updatedEntity.maxPosts = 6
      updatedEntity.displayHorizontally = false
      updateEntity(updatedEntity)
      setActiveLayout(undefined)
    } else {
      const updatedEntity = { ...entity }
      setActiveLayout(layoutType)

      switch (layoutType) {
        case LayoutType.Grid3x3: // grid 3x3
          updatedEntity.maxPosts = 9
          updatedEntity.maxPostsPerRow = 3
          updatedEntity.displayHorizontally = true
          updatedEntity.layoutType = layoutType
          clearPostCardPaddings(updatedEntity)
          updateEntity(updatedEntity)
          break
        case LayoutType.GridLongShort3ShortLong: //rows 2-1 3 1-2
          updatedEntity.maxPosts = 7
          updatedEntity.layoutType = layoutType
          updatedEntity.displayHorizontally = true
          addLeftPaddingToPostCard(updatedEntity, 10)
          updateEntity(updatedEntity)
          break
        case LayoutType.Grid1Big3Under: // 1up-3under
          updatedEntity.maxPosts = 4
          updatedEntity.layoutType = layoutType
          updatedEntity.displayHorizontally = true
          addLeftPaddingToPostCard(updatedEntity, 10)
          updateEntity(updatedEntity)
          break
        case LayoutType.GridRows3x2x3: //rows 3-2-3
          updatedEntity.maxPosts = 8
          updatedEntity.layoutType = layoutType
          updatedEntity.displayHorizontally = true
          addLeftPaddingToPostCard(updatedEntity, 10)
          updateEntity(updatedEntity)
          break
        case LayoutType.Grid1Big3Side: // 1left-3side
          updatedEntity.maxPosts = 4
          updatedEntity.layoutType = layoutType
          updatedEntity.displayHorizontally = true
          addLeftPaddingToPostCard(updatedEntity, 10)
          updateEntity(updatedEntity)
          break
        default:
          updateEntity(updatedEntity)
          break
      }
    }
  }

  return (
    <>
      {showGroupTitle && <GroupTitle>{groupTitle}</GroupTitle>}
      <OptionWrapper labelText="entity_settings.blog_post_listing.post_listing.layout.title">
        <LayoutsContainerUi>
          {layouts.map((layout, index) => (
            <LayoutContainerUi
              key={index}
              onClick={e => updateLayout(e, layout.type)}
            >
              {layout.render(activeLayout === layout.type)}
            </LayoutContainerUi>
          ))}
        </LayoutsContainerUi>
      </OptionWrapper>
    </>
  )
}

export default PostListingLayoutSetting
