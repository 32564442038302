import styled from 'styled-components'

const AnswerRemoveUi = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  cursor: pointer;
  margin: 0 0 16px 8px;
`

export default AnswerRemoveUi
