import React from 'react'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Range from '../components/Range/Range'
import MobileIconUi from './ui/MobileIconUi'

type VerticalSpacingProps = {
  verticalSpacing?: number
  change: (val: number) => void
  max?: number
  label?: string
}

function VerticalSpacing({
  verticalSpacing = 0,
  change,
  label = 'settings_styles.vertical_spacing.label',
  max = 100,
}: VerticalSpacingProps) {
  const { isMobile } = useDeviceModeContext()

  return (
    <Range
      labelIcon={isMobile ? <MobileIconUi /> : null}
      label={label}
      value={verticalSpacing}
      max={max}
      change={change}
    />
  )
}

export default VerticalSpacing
