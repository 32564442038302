import styled from 'styled-components'

const IconPickerTitleUi = styled.div`
  padding: 14px;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f0f2f5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`
export default IconPickerTitleUi
