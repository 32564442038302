import React from 'react'
import { FullBorderRadiusType } from 'common/types/styleTypes'
import BorderRadiusLabelUi from './ui/BorderRadiusLabelUi'

export function isNumber(x: any): x is number {
  return typeof x === 'number'
}
export function isFullBorderRadius(x: any): x is FullBorderRadiusType {
  return typeof x === 'object'
}
export function findLargestRadius(radius: FullBorderRadiusType): number {
  return Math.max(...Object.values(radius))
}

export const bottomRightCornerRenderer = (
  isActive: boolean,
  index: number,
  corner: string,
) => (
  <BorderRadiusLabelUi
    key={index}
    htmlFor={corner}
    style={{
      gridColumnStart: 2,
    }}
  >
    <svg
      width="10"
      height="10"
      viewBox="0 0 163 163"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.75 43.468C124.75 88.287 92.582 124.749 53.031 124.749H0.437012V162.999H53.031C113.667 162.999 163 109.382 163 43.468V0.437012H124.75V43.468Z"
        fill={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
      />
    </svg>
  </BorderRadiusLabelUi>
)

export const bottomLeftCornerRenderer = (
  isActive: boolean,
  index: number,
  corner: string,
) => (
  <BorderRadiusLabelUi
    key={index}
    htmlFor={corner}
    style={{
      gridColumnStart: 1,
      gridRowStart: 2,
    }}
  >
    <svg
      width="10"
      height="10"
      viewBox="0 0 173 163"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.25 43.468V0.437012H0V43.468C0 107.145 55.854 162.999 119.531 162.999H172.125V124.749H119.531C76.988 124.75 38.25 86.002 38.25 43.468Z"
        fill={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
      />
    </svg>
  </BorderRadiusLabelUi>
)

export const topLeftCornerRenderer = (
  isActive: boolean,
  index: number,
  corner: string,
) => (
  <BorderRadiusLabelUi key={index} htmlFor={corner}>
    <svg
      width="10"
      height="10"
      viewBox="0 0 173 163"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 109.969V162.563H38.25V109.969C38.25 70.419 74.712 38.25 119.531 38.25H172.125V0H119.531C53.617 0 0 49.333 0 109.969Z"
        fill={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
      />
    </svg>
  </BorderRadiusLabelUi>
)

export const topRightCornerRenderer = (
  isActive: boolean,
  index: number,
  corner: string,
) => (
  <BorderRadiusLabelUi key={index} htmlFor={corner}>
    <svg
      width="10"
      height="10"
      viewBox="0 0 163 163"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.031 0H0.437012V38.25H53.031C95.259 38.25 124.75 67.741 124.75 109.969V162.563H163V109.969C163 46.254 116.755 0 53.031 0Z"
        fill={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
      />
    </svg>
  </BorderRadiusLabelUi>
)

export const singleBorderRadiusContainerRenderer = (
  isActive: boolean,
  onClick: () => void,
) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 449 449"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    style={{ marginRight: '10px', cursor: 'pointer' }}
  >
    <path
      d="M339 19.5H110C60.0182 19.5 19.5 60.0182 19.5 110V339C19.5 388.982 60.0182 429.5 110 429.5H339C388.982 429.5 429.5 388.982 429.5 339V110C429.5 60.0182 388.982 19.5 339 19.5Z"
      stroke={isActive ? 'rgba(20, 158, 252, 1)' : 'rgba(92, 104, 126, 1)'}
      strokeWidth="45"
    />
  </svg>
)
