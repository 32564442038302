import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import usePresentPage from 'client/hooks/usePresentPage'
import { getPageType } from 'client/reducers/pageReducer'
import Blocks from '../Blocks'
import Elements from '../Elements'
import ToolboxSwitcherTextUi from './ui/ToolboxSwitcherTextUi'
import ToolboxSwitcherUi from './ui/ToolboxSwitcherUi'
import ToolboxUi from './ui/ToolboxUi'

const MODE_ELEMENTS = 'elements'
const MODE_BLOCKS = 'blocks'

function Toolbox() {
  const { t } = useTranslation()
  const [mode, setMode] = useState(MODE_ELEMENTS)
  const pageType = usePresentPage(getPageType)

  const toggleMode = newMode => () => {
    setMode(newMode)
  }

  return (
    <React.Fragment>
      <ToolboxSwitcherUi>
        <ToolboxSwitcherTextUi
          onClick={toggleMode(MODE_ELEMENTS)}
          active={mode === MODE_ELEMENTS}
          styles={{ borderBottomRightRadius: '5px' }}
        >
          {t('components.core.toolbox.types.elements')}
        </ToolboxSwitcherTextUi>
        <ToolboxSwitcherTextUi
          onClick={toggleMode(MODE_BLOCKS)}
          active={mode === MODE_BLOCKS}
          styles={{ borderBottomLeftRadius: '5px' }}
        >
          {t('components.core.toolbox.types.blocks')}
        </ToolboxSwitcherTextUi>
      </ToolboxSwitcherUi>
      <ToolboxUi>
        {mode === MODE_ELEMENTS ? (
          <Elements pageType={pageType} />
        ) : (
          <Blocks pageType={pageType} />
        )}
      </ToolboxUi>
    </React.Fragment>
  )
}

export default Toolbox
