import React, { ChangeEvent } from 'react'
import OptionWrapper from 'client/components/core/Sidebar/components/Settings/styleComponents/OptionWrapper'

interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  value: boolean | undefined
  update: (value: boolean) => void
  labelText?: string
  labelIcon?: JSX.Element | boolean | null
  directionRow?: boolean
  helpMessage?: string
  needTranslate?: boolean
  inverse?: boolean
  itemStyles?: React.CSSProperties
  width?: number
}

const Checkbox = ({
  value,
  update,
  labelText,
  labelIcon,
  directionRow,
  helpMessage,
  needTranslate,
  inverse,
  itemStyles,
  width,
  ...restProps
}: CheckboxProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    update(inverse ? !e.target.checked : e.target.checked)
  }

  return (
    <OptionWrapper
      labelText={labelText}
      labelIcon={labelIcon}
      directionRow={directionRow}
      helpMessage={helpMessage}
      needTranslate={needTranslate}
      itemStyles={itemStyles}
      width={width}
    >
      <input
        onChange={handleChange}
        checked={inverse ? !value : value}
        type="checkbox"
        {...restProps}
      />
    </OptionWrapper>
  )
}

export default Checkbox
