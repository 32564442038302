import styled from 'styled-components'

const AngleIconUi = styled.i`
  width: 6px;
  height: 6px;
  background-color: #4374ad;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 4px;
  margin: auto;
  cursor: pointer;
`

export default AngleIconUi
