import styled from 'styled-components'

const UploadButtonUi = styled.button`
  float: right;
  width: 40px;
  height: 42px;
  color: #9ba9b3;
  position: absolute;
  right: 0;
  border: solid 1px #ebedf0;
  border-radius: 0 4px 4px 0;
  outline: none;
  cursor: pointer;
`

export default UploadButtonUi
