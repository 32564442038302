import React from 'react'

function CourseIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="40"
      height="27"
      viewBox="0 0 40 27"
      fill="#CBD5E1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8534 6.43081L20.1161 0.0105646C20.0742 -0.00352153 20.0281 -0.00352153 19.9859 0.0105646L0.147852 6.33292C0.0604963 6.36107 0.000970775 6.44172 1.19135e-05 6.53324C-0.000954558 6.62508 0.0569652 6.70669 0.143362 6.73645L4.99015 8.39724V14.1519C4.17639 14.5173 3.60905 15.333 3.60905 16.2828C3.60905 17.2326 4.17705 18.049 4.99015 18.4138L3.71587 26.6198H7.96365L6.90188 18.4138C7.71565 18.0486 8.28298 17.2326 8.28298 16.2828C8.28298 15.333 7.71561 14.5173 6.90188 14.1519V9.05196L19.9142 13.5106C19.9363 13.5183 19.9597 13.5221 19.9827 13.5221C20.0058 13.5221 20.0291 13.5186 20.0512 13.5109L39.8557 6.83435C39.9427 6.8049 40.0006 6.72329 40 6.6321C39.999 6.53994 39.9402 6.45929 39.8534 6.43081H39.8534Z"
        fill="#CBD5E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9806 14.5849C19.8401 14.5849 19.7019 14.5612 19.5691 14.5158L9.20279 10.9641V18.5502C9.20279 20.6811 14.0998 22.4085 20.1409 22.4085C26.1819 22.4085 31.079 20.6811 31.079 18.5502L31.0787 10.9146L20.3904 14.5178C20.2589 14.5629 20.1204 14.585 19.9805 14.585L19.9806 14.5849Z"
        fill="#CBD5E1"
      />
    </svg>
  )
}

export default CourseIcon
