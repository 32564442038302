import PropTypes from 'prop-types'
import React from 'react'
import { Translation } from 'react-i18next'
import { Attributes, DeviceAppearance } from '../options/index'
import { MarginsStyle } from '../styles/index'

const getLink = linkText => `<a
          href="${process.env.SYSTEME_IO_HOST}/dashboard/profile/payment-settings"
          target="_blank"
        >${linkText}</a>`

const AgreementSettings = ({
  entity,
  updateStyle,
  updateOption,
  updateMobileStyle,
  update,
}) => (
  <Translation ns={['client']}>
    {t => (
      <React.Fragment>
        <div
          style={{ padding: '10px 20px' }}
          dangerouslySetInnerHTML={{
            __html: t('entity_settings.agreement.read_only.text', {
              link: getLink(t('entity_settings.agreement.read_only.link_text')),
            }),
          }}
        />
        <MarginsStyle
          marginTop={entity.styles.marginTop}
          marginRight={entity.styles.marginRight}
          marginBottom={entity.styles.marginBottom}
          marginLeft={entity.styles.marginLeft}
          mobileMarginTop={entity.mobileStyles.marginTop}
          mobileMarginRight={entity.mobileStyles.marginRight}
          mobileMarginBottom={entity.mobileStyles.marginBottom}
          mobileMarginLeft={entity.mobileStyles.marginLeft}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <DeviceAppearance update={update} entity={entity} />
        <Attributes update={updateOption} entity={entity} />
      </React.Fragment>
    )}
  </Translation>
)

AgreementSettings.propTypes = {
  updateStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object,
    styles: PropTypes.object,
  }).isRequired,
}

export default AgreementSettings
