import PropTypes from 'prop-types'
import React from 'react'
import {
  buttonWidthNames,
  buttonWidthValues,
} from 'common/constants/buttonWidthTypes'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Select } from '../components'
import MobileIconUi from './ui/MobileIconUi'

function getButtonWidthByValueKey(width) {
  return Object.keys(buttonWidthValues).find(
    key => buttonWidthValues[key] === width,
  )
}

function ButtonWidth({ width, mobileWidth, update, mobileUpdate }) {
  const { isMobile } = useDeviceModeContext()

  const setValue = buttonWidthKey => {
    const value = buttonWidthValues[buttonWidthKey]
    isMobile ? mobileUpdate(value) : update(value)
  }

  const value = getButtonWidthByValueKey(isMobile ? mobileWidth : width)

  return (
    <Select
      labelText="settings_options.button.width"
      labelIcon={isMobile && <MobileIconUi />}
      update={setValue}
      choiceList={buttonWidthNames}
      value={value}
    />
  )
}

ButtonWidth.propTypes = {
  width: PropTypes.string,
  mobileWidth: PropTypes.string,
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
}

ButtonWidth.defaultProps = {
  width: 'auto',
  mobileWidth: '100%',
}

export default ButtonWidth
