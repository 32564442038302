import styled, { css } from 'styled-components'

const TogglerTextUi = styled.div`
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 50%;
  padding: 10px;
  ${p =>
    p.active
      ? css`
          color: rgba(20, 158, 252, 1);
        `
      : css`
          background-color: #fafafa;
          cursor: pointer;
          color: #6b7c93;
        `};
  &:first-of-type {
    box-shadow: ${p => (!p.active ? 'inset -2px -2px 4px -2px #ccc' : 'none')};
  }
  &:last-of-type {
    box-shadow: ${p => (!p.active ? 'inset 2px -2px 4px -2px #ccc' : 'none')};
  }
  ${({ styles = {} }) => styles}
`

export default TogglerTextUi
