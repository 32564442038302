import React from 'react'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range/Range'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

type SpacingNewProps = {
  spacing: number
  subMenuSpacing?: number
  mobileSubMenuSpacing?: number
  mobileSpacing?: number
  update: (value: number) => void
  updateSubMenu: (value: number) => void
  updateMobileSubMenu: (value: number) => void
  updateMobile: (value: number) => void
  hasSubMenu: boolean
}
function MenuSpacing({
  spacing,
  mobileSpacing,
  update,
  updateMobile,
  subMenuSpacing,
  mobileSubMenuSpacing,
  updateMobileSubMenu,
  updateSubMenu,
  hasSubMenu,
}: SpacingNewProps) {
  const { isMobile } = useDeviceModeContext()

  return (
    <div>
      <GroupTitle afterChildren={isMobile && <MobileIconUi />}>
        settings_styles.margin.label
      </GroupTitle>
      <Range
        value={isMobile ? mobileSpacing : spacing}
        label={'entity_settings.menu.item.spacing'}
        change={isMobile ? updateMobile : update}
      />
      {hasSubMenu && (
        <Range
          value={isMobile ? mobileSubMenuSpacing : subMenuSpacing}
          label={'entity_settings.menu.item.submenu_spacing'}
          change={isMobile ? updateMobileSubMenu : updateSubMenu}
        />
      )}
    </div>
  )
}

export default MenuSpacing
