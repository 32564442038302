import { convertFromRaw } from 'draft-js'
import React from 'react'
import CommonContentTable from 'common/components/entities/ContentTable'
import { HeadingTypeEnum } from 'common/enums/HeadingTypeEnum'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import { ContentTableInterface } from 'common/types/entities/ContentTableInterface'
import { HeadlineInterface } from 'common/types/entities/HeadlineInterface'
import { OldTextEntityInterface } from 'common/types/entities/OldEntityInterface'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { EntityProps } from 'client/types'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import BaseEntityNew from '../core/BaseEntity/BaseEntityNew'

function ContentTable({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<ContentTableInterface>) {
  const { isMobile } = useDeviceModeContext()
  const headings = usePage(page =>
    pageSelectors.getOrderedByPositionHeadings(page, isMobile),
  )

  const processedHeadlines = headings
    .map((heading: HeadlineInterface | OldTextEntityInterface) => {
      const contentState = convertFromRaw(
        JSON.parse(
          'rawContentState' in heading
            ? heading.rawContentState
            : heading.options.rawContentState,
        ),
      )

      const headlineType = contentState
        .getFirstBlock()
        .getType() as HeadingTypeEnum

      return {
        headline: heading,
        headlineType,
        content: contentState.getPlainText(),
      }
    })
    .filter(processedHeadline =>
      entity.headersTypes.includes(processedHeadline.headlineType),
    )

  const defaultState =
    typeof entity.isOpenedByDefault === 'boolean'
      ? !entity.isOpenedByDefault
      : false

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    >
      <CommonContentTable
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        iconClassName={entity.iconClassName}
        closedIconClassName={entity.closedIconClassName}
        processedHeadlines={processedHeadlines}
        defaultState={defaultState}
        iconColor={entity.iconColor}
        backgroundColor={entity.backgroundColor}
        mobileBackgroundColor={entity.mobileBackgroundColor}
        contentFontFamily={entity.contentFontFamily}
        contentFontStyle={entity.contentFontStyle}
        contentFontWeight={entity.contentFontWeight}
        contentColor={entity.contentColor}
        contentFontSize={entity.contentFontSize}
        contentItemGap={entity.contentItemGap}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
        headerTitle={entity.headerTitle}
        headerFontSize={entity.headerFontSize}
        headerColor={entity.headerColor}
        headerFontFamily={entity.headerFontFamily}
        headerFontStyle={entity.headerFontStyle}
        headerFontWeight={entity.headerFontWeight}
      />
    </BaseEntityNew>
  )
}

export function createBlogPostContentTable(
  parentId: string,
  masterBlockId?: string,
): ContentTableInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.ContentTable,
    parentId: parentId,
    masterBlockId: masterBlockId,
    isOpenedByDefault: true,
    backgroundColor: 'rgba(248, 248, 249, 0.6)',
    headersTypes: [HeadingTypeEnum.HeaderTwo, HeadingTypeEnum.HeaderThree],
    content: [],
    iconClassName: 'fas fa-chevron-circle-up',
    iconColor: 'rgba(27, 160, 255, 1)',
    headerTitle: '',
    headerFontSize: 32,
    headerColor: 'rgba(0, 0, 0, 1)',
    contentColor: 'rgba(27, 160, 255, 1)',
    contentFontSize: 24,
    appearance: {
      desktop: true,
      mobile: true,
    },
    contentItemGap: 10,
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    border: {
      radius: 3,
      width: 1,
      style: 'solid',
      color: 'rgba(215, 215, 228, 1)',
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    padding: {
      paddingTop: 30,
      paddingRight: 70,
      paddingBottom: 30,
      paddingLeft: 70,
    },
    mobilePadding: {
      paddingTop: 10,
      paddingRight: 20,
      paddingBottom: 10,
      paddingLeft: 20,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.ContentTable),
  }
}

export function getContentTableFontProperties(entity: ContentTableInterface) {
  const fontProperties = []

  if (entity.headerFontFamily) {
    fontProperties.push({
      fontFamily: entity.headerFontFamily,
      fontStyle: entity.headerFontStyle,
      fontWeight: entity.headerFontWeight,
    })
  }

  if (entity.contentFontFamily) {
    fontProperties.push({
      fontFamily: entity.contentFontFamily,
      fontStyle: entity.contentFontStyle,
      fontWeight: entity.contentFontWeight,
    })
  }

  return fontProperties
}

export function getContentTableCustomFontProperties(
  entity: ContentTableInterface,
) {
  const fontProperties = []

  if (entity.headerFontFamily) {
    fontProperties.push({
      fontFamily: entity.headerFontFamily,
      fontFileId: entity.headerFontFileId,
    })
  }

  if (entity.contentFontFamily) {
    fontProperties.push({
      fontFamily: entity.contentFontFamily,
      fontFileId: entity.contentFontFileId,
    })
  }

  return fontProperties
}

export default ContentTable
