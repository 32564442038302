import React from 'react'
import { BlogPostImageInterface } from 'common/types/entities/BlogPostImageInterface'
import { Appearance } from 'common/types/entities/EntityInterface'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import ColorPickerWithGradient from '../components/ColorPickerWithGradient'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from '../hooks/useUpdateProps'
import DeviceAppearance from '../options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../options/HtmlAttrId'
import Border from '../styles/Border'
import MarginStyle from '../styles/MarginStyle'
import ShadowStyle from '../styles/ShadowStyle'

function BlogPostImageSettings({ entity }: { entity: BlogPostImageInterface }) {
  const updateEntity = useUpdateEntity<BlogPostImageInterface>()
  const updateNestedProp = useUpdateNestedProp(entity)
  const updateProp = useUpdateProp(entity)

  const { isMobile } = useDeviceModeContext()

  const removeBackgroundColorProperty = () => {
    if (isMobile) {
      const updatedEntity = { ...entity }
      delete updatedEntity.background
      updateEntity(updatedEntity)
    } else {
      const updatedEntity = { ...entity }
      delete updatedEntity.mobileBackground
      updateEntity(updatedEntity)
    }
  }

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  return (
    <>
      <Border
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
        border={isMobile ? entity.mobileBorder : entity.border}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <ColorPickerWithGradient
        label="background color"
        color={entity.background}
        mobileColor={entity.mobileBackground || entity.background}
        update={updateProp('background')}
        mobileUpdate={updateProp('background')}
        removeBackgroundColorProperty={removeBackgroundColorProperty}
        fallbackOpacity={0.5}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default BlogPostImageSettings
