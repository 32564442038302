import styled from 'styled-components'

const CustomFontPickerUploadButtonUi = styled.button`
  width: 40px;
  height: 38px;
  color: #9ba9b3;
  border: solid 1px #ebedf0;
  border-radius: 4px 4px 4px 4px;
  outline: none;
  cursor: pointer;
`

export default CustomFontPickerUploadButtonUi
