import styled from 'styled-components'

const RemoveFileIconUi = styled.i`
  position: absolute;
  right: 50px;
  top: 13px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff;
`

export default RemoveFileIconUi
