import styled from 'styled-components'

const FaqItemInputUi = styled.input`
  width: 90%;
  height: 30px;
  border: solid 1px #ebedf0;
  border-radius: 3px;
  color: #5c687e;
  text-indent: 10px;
  font-size: 12px;
  font-family: AvertaPE, Helvetica, Arial, sans-serif;
  outline: none;
  margin-bottom: 5px;
  &::placeholder {
    color: #9ba9b4;
  }
`

export default FaqItemInputUi
