import React, { useEffect, useState } from 'react'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Checkbox } from '../components'
import Range from '../components/Range/Range'
import MobileIconUi from '../styles/ui/MobileIconUi'

type ImageSizeProps = {
  width?: number | string
  mobileWidth?: number | string
  updateWidth: (width: number | string, unit?: string) => void
  updateMobileWidth: (width: number | string, unit?: string) => void
  attrId: string
}

function ImageSize({
  width = 100,
  mobileWidth,
  updateWidth,
  updateMobileWidth,
  attrId,
}: ImageSizeProps) {
  const { isMobile } = useDeviceModeContext()
  const [maxSize, setMaxSize] = useState(0)

  useEffect(() => {
    const imageWrapper = document.getElementById(attrId)
    setMaxSize(
      imageWrapper
        ? parseFloat(imageWrapper.getBoundingClientRect().width.toFixed(0))
        : 0,
    )
  }, [isMobile, attrId])

  const isWidthResponsive = isMobile ? mobileWidth === '100%' : width === '100%'

  if (isWidthResponsive) {
    return (
      <Checkbox
        labelText="entity_settings.image.responsive.label"
        update={() => isMobile
          ? updateMobileWidth(Number(width) < maxSize ? width : maxSize, 'px')
          : updateWidth(maxSize, 'px')
        }
        value={true}
      />
    )
  }

  return (
    <>
      <Checkbox
        labelText="entity_settings.image.responsive.label"
        update={() => isMobile ? updateMobileWidth('100%') : updateWidth('100%')}
        value={false}
      />
      {isMobile ? (
        <Range
          label="entity_settings.image.size"
          labelIcon={isMobile ? <MobileIconUi /> : null}
          value={
            mobileWidth
              ? typeof mobileWidth === 'string'
                ? parseInt(mobileWidth)
                : mobileWidth
              : Number(width)
          }
          change={val => updateMobileWidth(val, 'px')}
          max={maxSize}
        />
      ) : (
        <Range
          label="entity_settings.image.size"
          value={Number(width)}
          change={val => updateWidth(val, 'px')}
          max={maxSize}
        />
      )}
    </>
  )
}

export default ImageSize
