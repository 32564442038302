import PropTypes from 'prop-types'
import React from 'react'
import audioTypes from 'common/constants/audioTypes'
import {
  Select,
  Input,
  FileUpload,
} from 'client/components/core/Sidebar/components/Settings/components'
import { DeviceAppearance } from 'client/components/core/Sidebar/components/Settings/options'
import { MarginsStyle } from 'client/components/core/Sidebar/components/Settings/styles'
import { mimeTypes } from 'client/constants/editorSettings'
import Attributes from '../options/Attributes'

export const audioTypesNames = {
  [audioTypes.FILE]: 'entity_settings.audio.source_type.upload',
  [audioTypes.URL]: 'entity_settings.audio.source_type.direct_link',
}

const AudioSettings = ({
  entity,
  updateStyle,
  updateMobileStyle,
  update,
  updateOption,
}) => (
  <React.Fragment>
    <Select
      labelText="audio type"
      value={entity.options.type}
      update={updateOption('type')}
      firstOption="entity_settings.audio.source_type.choose_source"
      choiceList={audioTypesNames}
    />
    {entity.options.type === audioTypes.FILE && (
      <FileUpload
        fileId={entity.options.srcFileId}
        mimeTypes={mimeTypes.audio}
        afterUpload={file => updateOption('srcFileId')(file.id)}
        label="audio"
        fileType="audio"
      />
    )}
    {entity.options.type === audioTypes.URL && (
      <Input
        labelText="url"
        value={entity.options.url}
        update={updateOption('url')}
      />
    )}
    <MarginsStyle
      marginTop={entity.styles.marginTop}
      marginRight={entity.styles.marginRight}
      marginBottom={entity.styles.marginBottom}
      marginLeft={entity.styles.marginLeft}
      mobileMarginTop={entity.mobileStyles.marginTop}
      mobileMarginRight={entity.mobileStyles.marginRight}
      mobileMarginBottom={entity.mobileStyles.marginBottom}
      mobileMarginLeft={entity.mobileStyles.marginLeft}
      update={updateStyle}
      mobileUpdate={updateMobileStyle}
    />
    <DeviceAppearance update={update} entity={entity} />
    <Attributes update={updateOption} entity={entity} />
  </React.Fragment>
)

AudioSettings.propTypes = {
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object,
    styles: PropTypes.object,
  }).isRequired,
}

export default AudioSettings
