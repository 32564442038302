import styled from 'styled-components'

const ToolboxItemListUi = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  &:after {
    content: '';
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 92px;
  }
`

export default ToolboxItemListUi
