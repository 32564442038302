import React from 'react'
import { ContentBoxInterface } from 'common/types/entities/ContentBoxInterface'
import { Switcher } from 'client/components/core/Sidebar/components/Settings/components'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import FontSize from 'client/components/core/Sidebar/components/Settings/components/Font/FontSize'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import { DelayOption } from 'client/components/core/Sidebar/components/Settings/options'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  ShadowStyle,
  TextAlignStyle,
} from 'client/components/core/Sidebar/components/Settings/styles'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

interface ContentBoxSettingsProps {
  entity: ContentBoxInterface
}

type HeaderProp = ContentBoxInterface['header']

const ContentBoxSettings = ({ entity }: ContentBoxSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<ContentBoxInterface>()
  const updateHeaderEntity = (
    updatedHeaderEntity: ContentBoxInterface['header'],
  ) => updateEntity({ ...entity, header: updatedHeaderEntity })
  const updateHeaderProp =
    (key: keyof HeaderProp) => (value: HeaderProp[keyof HeaderProp]) =>
      updateProp('header')({ ...entity.header, [key]: value })

  const { isMobile } = useDeviceModeContext()

  return (
    <>
      <GroupTitle>header options</GroupTitle>
      <Switcher
        description={'header visibility'}
        firstLabel={'off'}
        isFirstActive={!entity.header.visibility}
        isSecondActive={entity.header.visibility}
        handleFirstButton={() => updateHeaderProp('visibility')(false)}
        handleSecondButton={() => updateHeaderProp('visibility')(true)}
        secondLabel={'on'}
      />
      {entity.header.visibility && (
        <Input
          type={'text'}
          update={updateHeaderProp('title')}
          value={entity.header.title || ''}
          label={'header title'}
        />
      )}
      <FontSize
        label={'settings_styles.font_size.label'}
        fontSize={entity.header.fontSize}
        mobileFontSize={entity.header.mobileFontSize}
        onChange={value => updateHeaderProp('fontSize')(value)}
        onChangeMobile={value => updateHeaderProp('mobileFontSize')(value)}
      />
      <TextFontUpdater<ContentBoxInterface['header']>
        entity={entity.header}
        update={updateHeaderEntity}
        fontFamilyKey={'fontFamily'}
        fontWeightKey={'fontWeight'}
        fontStyleKey={'fontStyle'}
        mobileFontFamilyKey={'mobileFontFamily'}
        mobileFontWeightKey={'mobileFontWeight'}
        mobileFontStyleKey={'mobileFontStyle'}
        fontFileIdKey={'fontFileId'}
        mobileFontFileIdKey={'mobileFontFileId'}
      />
      <ColorPicker
        label={'settings_styles.background_color.label'}
        color={entity.header.backgroundColor}
        mobileColor={entity.header.mobileBackgroundColor}
        update={bgColor => updateHeaderProp('backgroundColor')(bgColor)}
        mobileUpdate={mobileBgColor =>
          updateHeaderProp('mobileBackgroundColor')(mobileBgColor)
        }
      />
      <ColorPicker
        color={entity.header.color}
        mobileColor={entity.header.mobileColor}
        update={color => updateHeaderProp('color')(color)}
        mobileUpdate={mobileColor =>
          updateHeaderProp('mobileColor')(mobileColor)
        }
      />
      <TextAlignStyle
        mobileTextAlign={entity.header.mobileTextAlign || 'left'}
        textAlign={entity.header.textAlign || 'left'}
        update={updateHeaderProp('textAlign')}
        mobileUpdate={updateHeaderProp('mobileTextAlign')}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label={'settings_styles.background_color.label'}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        update={bgColor => updateProp('backgroundColor')(bgColor)}
        mobileUpdate={mobileBgColor =>
          updateProp('mobileBackgroundColor')(mobileBgColor)
        }
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <Border
        border={isMobile ? entity.mobileBorder : entity.border}
        update={isMobile ? updateProp('mobileBorder') : updateProp('border')}
      />
      <DelayOption update={updateProp('delay')} delay={entity.delay} />
      <PaddingStyle
        groupTitle
        padding={
          isMobile ? entity.mobilePadding || entity.padding : entity.padding
        }
        update={isMobile ? updateProp('mobilePadding') : updateProp('padding')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default ContentBoxSettings
