import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useActions } from 'common/hooks/useActions'
import { resetPageError } from 'client/actions/pageActions'
import { Dialog, DialogBody, DialogHeader } from 'client/components/core/Dialog'
import WarningIcon from 'client/components/core/ErrorsDialog/components/WarningIconUi'
import usePresentPage from 'client/hooks/usePresentPage'
import { getError } from 'client/reducers/pageReducer'

function CommonErrorsDialog() {
  const { t, i18n } = useTranslation()
  const translationsInitialized = i18n.language // page locale loaded
  const error = usePresentPage(getError)
  const resetError = useActions(resetPageError)
  const resetErrorSafe = translationsInitialized ? resetError : null
  return (
    <Dialog show={Boolean(error)} close={resetErrorSafe} small>
      <DialogHeader close={resetErrorSafe} />
      <DialogBody small>
        <h3 key={error}>
          <WarningIcon />{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: translationsInitialized ? t(error) : error,
            }}
          />
        </h3>
      </DialogBody>
    </Dialog>
  )
}

export default memo(CommonErrorsDialog)
