import React from 'react'
import { RowInterface } from 'common/components/entities/Row/types/RowInterface'
import { getMobileBackgroundFileId } from 'common/utils/getMobileBackgroundFileId'
import Checkbox from 'client/components/core/Sidebar/components/Settings/components/Checkbox'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DelayOption from 'client/components/core/Sidebar/components/Settings/options/DelayOption'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import BackgroundPositionStyleNew from 'client/components/core/Sidebar/components/Settings/styles/BackgroundImageNew/BackgroundPositionStyleNew'
import Border from 'client/components/core/Sidebar/components/Settings/styles/Border'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import ShadowStyle from 'client/components/core/Sidebar/components/Settings/styles/ShadowStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

const RowSettings = ({ entity }: { entity: RowInterface }) => {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)

  return (
    <div>
      <MarginStyle
        groupTitle={true}
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle={true}
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <GroupTitle>page_settings.body.background.group_title</GroupTitle>
      <ColorPicker
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
      />
      <BackgroundPositionStyleNew
        backgroundPosition={entity.background}
        fileId={
          isMobile
            ? getMobileBackgroundFileId(
                entity.backgroundFileId,
                entity.mobileBackgroundFileId,
              )
            : entity.backgroundFileId
        }
        updateBackgroundFileId={
          isMobile
            ? updateProp('mobileBackgroundFileId')
            : updateProp('backgroundFileId')
        }
        update={updateProp('background')}
      />
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      <Border
        border={isMobile ? entity.mobileBorder : entity.border}
        update={updateProp(isMobile ? 'mobileBorder' : 'border')}
      />
      <DelayOption delay={entity.delay} update={updateProp('delay')} />
      <Checkbox
        labelText={'entity_settings.row.disable_stacking.label'}
        helpMessage={'entity_settings.row.disable_stacking.help_message'}
        value={entity.disableStacking}
        update={updateProp('disableStacking')}
      />
      <Checkbox
        labelText={'entity_settings.row.reverse_columns.label'}
        value={entity.reverseColumns}
        update={updateProp('reverseColumns')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </div>
  )
}

export default RowSettings
