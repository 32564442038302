import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { pageTypes } from 'common/constants/pageTypes'
import { useActions } from 'common/hooks/useActions'
import {
  deleteUserBlock,
  deleteCommonBlock,
  deleteMasterBlock,
} from 'client/actions/blockActions'
import { createBlockAtTheBottom } from 'client/actions/entityActions'
import {
  categories,
  categoryNames,
} from 'client/components/core/Sidebar/components/Blocks/blockCategories'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import useBlocks, { selectors as blocksSelectors } from 'client/hooks/useBlocks'
import useManagement from 'client/hooks/useManagement'
import usePresentPage, {
  selectors as pageSelectors,
} from 'client/hooks/usePresentPage'
import { isUserAdmin } from 'client/reducers/managementReducer'
import Block from './Block'
import BlockSearchInput from './BlockSearchInput'
import BackToBlockGroupsUi from './ui/BackToBlockGroupsUi'
import BlockGroupItemsUi from './ui/BlockGroupItemsUi'
import BlockGroupUi from './ui/BlockGroupUi'
import BlockGroupsUi from './ui/BlockGroupsUi'
import BlocksUi from './ui/BlocksUi'

function Blocks({ pageType }) {
  const { t } = useTranslation()
  const { isMobile } = useDeviceModeContext()
  const usedMasterBlockIds = usePresentPage(pageSelectors.getUsedMasterBlockIds)
  const isTemplate = usePresentPage(pageSelectors.isTemplate)
  const userBlocks = useBlocks(blocksSelectors.getUserBlocks)
  const masterBlocks = useBlocks(blocksSelectors.getMasterBlocks)
  const commonBlocksByCategories = useBlocks(
    blocksSelectors.getCommonBlocksByCategories,
  )
  const deleteUserBlockAction = useActions(deleteUserBlock)
  const deleteMasterBlockAction = useActions(deleteMasterBlock)
  const addToTheBottom = useActions(createBlockAtTheBottom)
  const removeCommonBlock = useActions(deleteCommonBlock)
  const [activeCategory, setActiveCategory] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const isAdmin = useManagement(isUserAdmin)

  const isMasterBlockInUse = block =>
    block.isMaster && usedMasterBlockIds.includes(block.id)

  const isOfferPageOrNotOrderFormCategory = category =>
    pageType === pageTypes.offerPage || category !== categories.orderForms

  const allowedCommonCategories = Object.keys(commonBlocksByCategories)
    .filter(isOfferPageOrNotOrderFormCategory)
    .map(category => ({
      name: t(categoryNames[category]),
      type: category,
    }))

  function filterBySearchValue(searchValue) {
    return filterable => {
      return !searchValue.length || filterable.title.indexOf(searchValue) > -1
    }
  }

  const userBlocksFilteredList = userBlocks.filter(
    filterBySearchValue(searchValue),
  )

  const masterBlockFilteredList = masterBlocks.filter(
    filterBySearchValue(searchValue),
  )

  return (
    <BlocksUi>
      {!activeCategory ? (
        <BlockGroupsUi>
          {!isTemplate && masterBlocks.length > 0 && (
            <BlockGroupUi onClick={() => setActiveCategory('user_master')}>
              {t('components.core.blocks.groups.user_blocks.master')}
            </BlockGroupUi>
          )}
          {userBlocks.length > 0 && (
            <BlockGroupUi onClick={() => setActiveCategory('user_simple')}>
              {t('components.core.blocks.groups.user_blocks.simple')}
            </BlockGroupUi>
          )}
          {allowedCommonCategories.map(category => (
            <BlockGroupUi
              key={category.type}
              onClick={() => setActiveCategory(category.type)}
            >
              {category.name}
            </BlockGroupUi>
          ))}
        </BlockGroupsUi>
      ) : (
        <BackToBlockGroupsUi onClick={() => setActiveCategory(null)}>
          <i className="far fa-arrow-alt-circle-left" />{' '}
          {t('components.core.blocks.back_to_groups')}
        </BackToBlockGroupsUi>
      )}
      {activeCategory === 'user_simple' && (
        <>
          <BlockSearchInput setSearchValue={setSearchValue} />
          <BlockGroupItemsUi>
            {userBlocksFilteredList.map(block => {
              return (
                <Block
                  key={block.id}
                  block={block}
                  remove={() =>
                    block.isMaster
                      ? deleteMasterBlockAction(block.id)
                      : deleteUserBlockAction(block.id)
                  }
                  addToTheBottom={addToTheBottom}
                  isMaster={block.isMaster}
                  isRemoveAllowed
                  isSharingAllowed
                  isMobile={isMobile}
                />
              )
            })}
          </BlockGroupItemsUi>
        </>
      )}
      {!isTemplate && activeCategory === 'user_master' && (
        <>
          <BlockSearchInput setSearchValue={setSearchValue} />
          <BlockGroupItemsUi>
            {masterBlockFilteredList.map(block => {
              return (
                <Block
                  key={block.id}
                  block={block}
                  remove={() =>
                    block.isMaster
                      ? deleteMasterBlockAction(block.id)
                      : deleteUserBlockAction(block.id)
                  }
                  isMasterBlockInUse={isMasterBlockInUse(block)}
                  addToTheBottom={addToTheBottom}
                  isMaster={block.isMaster}
                  isRemoveAllowed
                  isSharingAllowed
                  isMobile={isMobile}
                />
              )
            })}
          </BlockGroupItemsUi>
        </>
      )}
      {activeCategory && activeCategory !== 'user' && (
        <BlockGroupItemsUi>
          {commonBlocksByCategories[activeCategory] &&
            commonBlocksByCategories[activeCategory].map(block => (
              <Block
                key={block.id}
                block={block}
                remove={() => removeCommonBlock(block.id)}
                addToTheBottom={addToTheBottom}
                isRemoveAllowed={isAdmin}
                isMobile={isMobile}
              />
            ))}
        </BlockGroupItemsUi>
      )}
    </BlocksUi>
  )
}

export default Blocks
