import React from 'react'

function CloseCircleIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexGrow: 1, flexShrink: 0 }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1719 0C4.92404 0 0.671875 4.25216 0.671875 9.5C0.671875 14.7478 4.92404 19 10.1719 19C15.4197 19 19.6719 14.7478 19.6719 9.5C19.6719 4.25216 15.4197 0 10.1719 0ZM12.5788 12.9392L10.1719 10.5322L7.7649 12.9392C7.48173 13.2224 7.01586 13.2224 6.7327 12.9392C6.5911 12.7976 6.51803 12.6103 6.51803 12.4231C6.51803 12.2358 6.59111 12.0486 6.7327 11.907L9.13966 9.5L6.7327 7.09303C6.5911 6.95144 6.51803 6.76418 6.51803 6.57692C6.51803 6.38966 6.59111 6.2024 6.7327 6.06081C7.01586 5.77764 7.48173 5.77764 7.7649 6.06081L10.1719 8.46778L12.5788 6.06081C12.862 5.77764 13.3279 5.77764 13.6111 6.06081C13.8942 6.34398 13.8942 6.80985 13.6111 7.09302L11.2041 9.49999L13.6111 11.907C13.8942 12.1901 13.8942 12.656 13.6111 12.9392C13.3279 13.2269 12.862 13.2269 12.5788 12.9392V12.9392Z"
      />
    </svg>
  )
}

export default CloseCircleIcon
