import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CountdownActionsEnum } from 'common/components/entities/Countdown/enums/CountdownActionsEnum'
import { CountdownTypesEnum } from 'common/components/entities/Countdown/enums/CountdownTypesEnum'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import { Select } from 'client/components/core/Sidebar/components/Settings/components'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import ReactSelect, {
  OptionType,
} from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import CountdownDaily from 'client/components/core/Sidebar/components/Settings/entities/Countdown/components/CountdownOptions/CountdownDaily'
import CountdownDelay from 'client/components/core/Sidebar/components/Settings/entities/Countdown/components/CountdownOptions/CountdownDelay'
import CountdownFixed from 'client/components/core/Sidebar/components/Settings/entities/Countdown/components/CountdownOptions/CountdownFixed'
import {
  countdownActionsLocoKeys,
  countdownTypesLocoKeys,
} from 'client/components/core/Sidebar/components/Settings/entities/Countdown/constants/countdownLocoKeys'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import { useManagement, usePage } from 'client/store'
import managementSelectors from 'client/store/management/managementSelectors'
import pageSelectors from 'client/store/page/pageSelectors'

interface CountdownOptionsProps {
  entity: CountdownInterface
}

const CountdownOptions = ({ entity }: CountdownOptionsProps) => {
  const { t } = useTranslation(['client'])

  const updateProp = useUpdateProp(entity)

  const [countdownTypeOptions, setCountdownTypeOptions] = useState<
    OptionType<CountdownTypesEnum>[]
  >(
    Object.values(CountdownTypesEnum).map(el => ({
      value: el,
      label: t(countdownTypesLocoKeys[el]),
    })),
  )

  const pageType = usePage(pageSelectors.getPageType)
  const userTimeZone = useManagement(managementSelectors.getUserTimezone)

  useEffect(() => {
    if (
      pageType !== PageTypeEnum.SalesPage &&
      pageType !== PageTypeEnum.OfferPage
    ) {
      setCountdownTypeOptions(prev =>
        prev.filter(type => type.value !== CountdownTypesEnum.deadline),
      )
    }
  }, [pageType])

  return (
    <>
      <ReactSelect<CountdownTypesEnum>
        labelText={t(`entity_settings.countdown.type`)}
        update={type => type && updateProp('countdownType')(type.value)}
        selectedOption={{
          value: entity.countdownType,
          label: t(countdownTypesLocoKeys[entity.countdownType]),
        }}
        options={countdownTypeOptions}
        isMulti={false}
        helpMessage={
          CountdownTypesEnum.fixed === entity.countdownType ||
          CountdownTypesEnum.daily === entity.countdownType
            ? t('entity_settings.countdown.timezone_label', {
                timezoneValue: userTimeZone,
              })
            : undefined
        }
        needTranslate={false}
      />
      {entity.countdownType === CountdownTypesEnum.fixed && (
        <CountdownFixed entity={entity} />
      )}
      {entity.countdownType === CountdownTypesEnum.delay && (
        <CountdownDelay entity={entity} />
      )}
      {entity.countdownType == CountdownTypesEnum.daily && (
        <CountdownDaily entity={entity} />
      )}
      {entity.countdownType !== CountdownTypesEnum.deadline && (
        <>
          <Select
            update={updateProp('action')}
            choiceList={countdownActionsLocoKeys}
            labelText="entity_settings.countdown.action"
            value={entity.action}
          />
          {entity.action === CountdownActionsEnum.redirection && (
            <Input
              update={updateProp('redirectUrl')}
              value={entity.redirectUrl}
              label={'entity_settings.countdown.redirection_url'}
              placeholder={'entity_settings.countdown.placeholder'}
            />
          )}
        </>
      )}
    </>
  )
}

export default CountdownOptions
