import React from 'react'
import { Toaster } from 'react-hot-toast'
import CheckCircleIcon from './CheckCircleIcon'
import CloseCircleIcon from './CloseCircleIcon'

const Toast = () => {
  return (
    <Toaster
      position="top-center"
      containerStyle={{
        top: 80,
        right: 20,
      }}
      toastOptions={{
        success: {
          icon: <CheckCircleIcon />,
          style: { color: 'currentColor', border: 'solid 1px green' },
          duration: 15000,
        },
        error: {
          icon: <CloseCircleIcon />,
          style: {
            color: 'currentColor',
            border: 'solid 1px red',
            maxWidth: '500px',
          },
          duration: 15000,
        },
      }}
    />
  )
}

export default Toast
