import React from 'react'
import HorizontalLineUi from 'common/components/entities/HorizontalLine/HorizontalLineUi'
import { borderTypeKeys } from 'common/constants/settings'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { HorizontalLineInterface } from 'common/types/entities/HorizontalLineInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'
import BaseEntityNew from '../core/BaseEntity/BaseEntityNew'

type HorizontalLineProps = {
  entity: HorizontalLineInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

function NewHorizontalLine({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: HorizontalLineProps) {
  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <HorizontalLineUi
        id={entity.htmlAttrId}
        border={entity.border}
        mobileBorder={entity.mobileBorder}
      />
    </BaseEntityNew>
  )
}
export default NewHorizontalLine

export function createHorizontalLine(
  parentId: string,
  masterBlockId?: string,
): HorizontalLineInterface {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.HorizontalLine,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.HorizontalLine,
    parentId: parentId,
    border: {
      width: 1,
      style: 'solid',
      radius: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
      color: 'rgba(68, 68, 68, 1)',
      type: borderTypeKeys.fullBorder,
    },
  }
}
