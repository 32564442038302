import React, { useEffect, memo } from 'react'
import { useDispatch } from 'react-redux'
import * as actions from 'client/actions/actionsManagement'
import useManagement, { selectors } from 'client/hooks/useManagement'
import { Dialog } from '../Dialog'
import CodeDialogBody from './ui/CodeDialogBody'

function CodeDialog() {
  const isCodeDialogOpened = useManagement(selectors.isCodeDialogOpened)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isCodeDialogOpened) {
      dispatch(actions.allowCodeDialogPortal())
    }
  }, [isCodeDialogOpened])

  return (
    <Dialog show={isCodeDialogOpened} width={800}>
      <CodeDialogBody id="code-dialog" />
    </Dialog>
  )
}

export default memo(CodeDialog)
