import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FileManager from 'client/components/FileManager/FileManager'
import LinkSourceInput from 'client/components/core/TextEditorNew/Menu/Link/LinkSourceInput'
import LinkSourcePopup from 'client/components/core/TextEditorNew/Menu/Link/LinkSourcePopup'
import MenuConfirmButtonHaveChangesUi from 'client/components/core/TextEditorNew/Menu/Link/ui/MenuConfirmButtonHaveChangesUi'
import MenuConfirmButtonUi from 'client/components/core/TextEditorNew/Menu/Link/ui/MenuConfirmButtonUi'
import MenuInputGroupUi from 'client/components/core/TextEditorNew/Menu/Link/ui/MenuInputGroupUi'
import MenuLinkUi from 'client/components/core/TextEditorNew/Menu/Link/ui/MenuLinkUi'
import MenuButtonUi from 'client/components/core/TextEditorNew/Menu/ui/MenuButtonUi'
import { mimeTypes } from 'client/constants/editorSettings'
import LinkSourceBlog from './LinkSourceBlog'

const REL_NO_FOLLOW = 'nofollow'
const TARGET_BLANK = '_blank'

type LinkAttributes = {
  rel?: typeof REL_NO_FOLLOW | ''
  target?: typeof TARGET_BLANK | ''
}

type LinkUrl = {
  url?: string
}

type LinkPopupId = {
  popupId?: string
}

export type LinkType = LinkUrl & LinkPopupId & LinkAttributes

type LinkProps = {
  currentLink: LinkType
  update: (link: LinkType) => void
  remove: () => void
  close: () => void
}

enum LinkSourceTypeEnum {
  Popup = 'Popup',
  Blog = 'Blog',
  Input = 'Input',
}

function detectLinkSource(link: LinkType) {
  if (link.popupId) {
    return LinkSourceTypeEnum.Popup
  }

  if (link.url && link.url.startsWith('%BLOG')) {
    return LinkSourceTypeEnum.Blog
  }

  return LinkSourceTypeEnum.Input
}

function BlogLink({ currentLink, update, remove, close }: LinkProps) {
  const { t } = useTranslation()
  const [link, setLink] = useState(currentLink)
  const [showFileManager, setShowFileManager] = useState(false)
  const [linkSource, setLinkSource] = useState(detectLinkSource(link))

  const isLinkReadyForSave =
    currentLink.url !== link.url || currentLink.popupId !== link.popupId

  useEffect(() => {
    setLink(currentLink)
  }, [currentLink])

  function afterUpload(file: any) {
    setLink({
      url: file.path,
    })
  }

  function toggleNoFollow() {
    update({
      ...link,
      rel: link.rel === REL_NO_FOLLOW ? '' : REL_NO_FOLLOW,
    })
  }

  const toggleMakeOpenInNew = () => {
    update({
      ...link,
      target: link.target === TARGET_BLANK ? '' : TARGET_BLANK,
    })
  }

  function save() {
    update(link)
    setTimeout(close, 500)
  }

  function toggleShowFileManager() {
    setShowFileManager(!showFileManager)
  }

  function updateURL(url: string) {
    setLink({
      url,
      rel: link.rel,
      target: link.target,
    })
  }

  function updatePopupId(popupId: string) {
    setLink({
      popupId,
      rel: link.rel,
      target: link.target,
    })
  }

  function togglePopupLinkSource() {
    setLinkSource(linkSource =>
      linkSource === LinkSourceTypeEnum.Popup
        ? LinkSourceTypeEnum.Input
        : LinkSourceTypeEnum.Popup,
    )
  }

  function toggleBlogLinkSource() {
    if (linkSource === LinkSourceTypeEnum.Blog) {
      setLinkSource(LinkSourceTypeEnum.Input)
      setLink(link => ({ ...link, url: '' })) // otherwise we will see blog post/page placeholders
    } else {
      setLinkSource(LinkSourceTypeEnum.Blog)
    }
  }

  return (
    <MenuLinkUi>
      <MenuInputGroupUi>
        {linkSource === LinkSourceTypeEnum.Input && (
          <LinkSourceInput url={link.url} update={updateURL} close={close} />
        )}
        {linkSource === LinkSourceTypeEnum.Popup && (
          <LinkSourcePopup popupId={link.popupId} update={updatePopupId} />
        )}
        {linkSource === LinkSourceTypeEnum.Blog && (
          <LinkSourceBlog url={link.url} update={updateURL} />
        )}
        <MenuConfirmButtonUi
          onClick={save}
          title={t('core.text_editor.link_menu.confirm')}
          disabled={!isLinkReadyForSave}
        >
          <span className="fas fa-save" />
          {isLinkReadyForSave && <MenuConfirmButtonHaveChangesUi />}
        </MenuConfirmButtonUi>
      </MenuInputGroupUi>
      <MenuButtonUi
        onClick={toggleBlogLinkSource}
        active={linkSource === LinkSourceTypeEnum.Blog}
        title={t('components.core.text_editor.link_menu.blog_link')}
        style={{ position: 'relative' }}
      >
        <span className="far fa-browser" style={{ fontSize: 20 }} />
        <span
          className="far fa-link"
          style={{ position: 'absolute', left: 13, top: 11, fontSize: 9 }}
        />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={togglePopupLinkSource}
        active={linkSource === LinkSourceTypeEnum.Popup}
        title={t('components.core.text_editor.link_menu.open_popup')}
      >
        <span className="far fa-window-restore" />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={toggleShowFileManager}
        title={t('components.core.text_editor.link_menu.use_file_as_link')}
        style={{ marginRight: 10 }}
      >
        <span className="fa fa-download" />
      </MenuButtonUi>
      {showFileManager && (
        <FileManager
          // @ts-ignore
          closeFileManager={toggleShowFileManager}
          mimeTypes={mimeTypes.all}
          onInsert={afterUpload}
        />
      )}
      <MenuButtonUi
        onClick={toggleMakeOpenInNew}
        active={link.target === TARGET_BLANK}
        title={t('components.core.text_editor.link_menu.open_in_new_window')}
      >
        <span className="fas fa-external-link-alt" />
      </MenuButtonUi>
      <span style={{ color: '#fff', borderRight: '1xp solid #fff' }} />
      <MenuButtonUi
        onClick={toggleNoFollow}
        active={link.rel === REL_NO_FOLLOW}
        title={t('components.core.text_editor.link_menu.no_follow')}
      >
        <span className="fa fa-ban" />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={remove}
        title={t('components.core.text_editor.link_menu.unlink')}
      >
        <span className="fas fa-unlink" />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={close}
        marginLeft="auto"
        title={t('components.core.text_editor.link_menu.close_menu')}
      >
        <span className="fa fa-times" />
      </MenuButtonUi>
    </MenuLinkUi>
  )
}

export default BlogLink
