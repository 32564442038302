import React from 'react'
import styled from 'styled-components'

const IconUi = styled.i`
  margin-left: 10px;
`

const MobileIconUi = () => <IconUi className="fas fa-mobile-alt" />

export default MobileIconUi

