import React from 'react'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import ChoiceLabelUi from './ui/ChoiceLabelUi'

type ChoiceValue = number | string

type Choice<T extends ChoiceValue> = {
  label: string
  value: T
}

type ChoicesProps<T extends ChoiceValue> = {
  values: T[]
  choices: Choice<T>[]
  label?: string
  labelIcon?: React.ReactElement | null
  update: (values: T[]) => void
  helpMessage?: string
}

function Choices<T extends ChoiceValue>({
  values,
  update,
  choices,
  label,
  labelIcon,
  helpMessage,
}: ChoicesProps<T>) {
  function handleUpdate(updatedValue: T) {
    return function (e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.checked) {
        update([...values, updatedValue])
      } else {
        update(values.filter(value => updatedValue !== value))
      }
    }
  }

  return (
    <OptionWrapper
      labelText={label}
      labelIcon={labelIcon}
      helpMessage={helpMessage}
    >
      {choices.map(choice => (
        <ChoiceLabelUi htmlFor={`choice-${choice.value}`} key={choice.value}>
          <input
            id={`choice-${choice.value}`}
            onChange={handleUpdate(choice.value)}
            checked={values.includes(choice.value)}
            type="checkbox"
            style={{ marginRight: 5 }}
          />
          {choice.label}
        </ChoiceLabelUi>
      ))}
    </OptionWrapper>
  )
}

export default Choices
