import React from 'react'
import { PaymentMethodInterface } from 'common/types/entities/PaymentMethodInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import FontSize from 'client/components/core/Sidebar/components/Settings/components/Font/FontSize'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import TextFontUpdater from '../../../components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import GroupTitle from '../../../components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import LineHeight from '../../../components/core/Sidebar/components/Settings/styles/LineHeight'

interface PaymentMethodSettingsProps {
  entity: PaymentMethodInterface
}

function PaymentMethodSettings({ entity }: PaymentMethodSettingsProps) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const textColor = usePage(pageSelectors.getGlobalTextColor)
  const updateEntity = useUpdateEntity<PaymentMethodInterface>()
  return (
    <>
      <GroupTitle>page_settings.typography.text_font_size.label</GroupTitle>
      <FontSize
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        onChange={updateProp('fontSize')}
        onChangeMobile={updateProp('mobileFontSize')}
        label={'settings_styles.font_size.label'}
      />
      <LineHeight
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
      />
      <TextFontUpdater<PaymentMethodInterface>
        entity={entity}
        update={updateEntity}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="global_settings.text_color.label"
        color={entity.color || textColor}
        mobileColor={entity.mobileColor}
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default PaymentMethodSettings
