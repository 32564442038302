import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AudioInterface } from 'common/types/entities/AudioInterface'
import { Appearance } from 'common/types/entities/EntityInterface'
import { SettingsProps } from 'client/components/core/Sidebar/components/Settings/entities'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import { mimeTypes } from 'client/constants/editorSettings'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { FileUpload } from '../components'
import Input from '../components/Input/Input'
import ReactSelect, {
  ChangeOptionWithStringValue,
} from '../components/ReactSelect/ReactSelect'
import { useUpdateEntity, useUpdateProp } from '../hooks/useUpdateProps'
import DeviceAppearance from '../options/DeviceAppearance/DeviceAppearance'
import MarginStyle from '../styles/MarginStyle'

enum AudioType {
  File = 'file',
  Url = 'url',
}

const audioTypesNames = {
  [AudioType.File]: 'entity_settings.audio.source_type.upload',
  [AudioType.Url]: 'entity_settings.audio.source_type.direct_link',
}

function detectAudioType(entity: AudioInterface) {
  if (entity.fileId) {
    return AudioType.File
  }
  return AudioType.Url
}

function AudioSettingsNew({ entity }: SettingsProps<AudioInterface>) {
  const [audioType, setAudioType] = useState(detectAudioType(entity))
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  const updateEntity = useUpdateEntity<AudioInterface>()
  const { t } = useTranslation()

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  function getOption(audioType: AudioType) {
    return {
      value: audioType,
      label: t(audioTypesNames[audioType]),
    }
  }

  function updateAudioType(option: ChangeOptionWithStringValue) {
    if (!option) {
      return
    }

    const updatedEntity = { ...entity }
    switch (option.value) {
      case AudioType.Url:
        delete updatedEntity.fileId
        break
      case AudioType.File:
        delete updatedEntity.url
        break
      default:
    }
    updateEntity(updatedEntity)
    setAudioType(option.value as AudioType)
  }

  return (
    <React.Fragment>
      <ReactSelect<string>
        selectedOption={getOption(audioType)}
        update={updateAudioType}
        labelText="entity_settings.audio.source_type.choose_source"
        options={[getOption(AudioType.Url), getOption(AudioType.File)]}
        isMulti={false}
      />
      {audioType === AudioType.Url && (
        <Input label="url" value={entity.url} update={updateProp('url')} />
      )}
      {audioType === AudioType.File && (
        <FileUpload
          fileId={entity.fileId}
          mimeTypes={mimeTypes.audio}
          afterUpload={(file: any) => updateProp('fileId')(file.id)}
          label="audio"
          fileType="audio"
        />
      )}
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </React.Fragment>
  )
}

export default AudioSettingsNew
