import React from 'react'
import { BlogContentPlaceholderInterface } from 'common/types/entities/BlogContentPlaceholderInterface'
import { SettingsProps } from 'client/components/core/Sidebar/components/Settings/entities'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import HtmlAttrId from '../../../components/core/Sidebar/components/Settings/options/HtmlAttrId'

function BlogContentPlaceholderSettings({
  entity,
}: SettingsProps<BlogContentPlaceholderInterface>) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)

  return (
    <>
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default BlogContentPlaceholderSettings
