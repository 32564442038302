import styled, { css } from 'styled-components'

type PageUiProps = {
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
}

const PageUi = styled.div<PageUiProps>`
  display: grid; /* child will stretch the width */
  min-height: 100%; /* min-height works with an auto scroll and with a stretched section */
  overflow-x: hidden; /* hide horizontal scrolling in mobile mode */
  overflow-y: hidden; /* hide vertical scroll in mobile mode in Windows */
  ${p =>
    p.fontFamily &&
    css`
      font-family: ${p.fontFamily};
    `};
  ${p =>
    p.fontWeight &&
    css`
      font-weight: ${p.fontWeight};
    `};
  ${p =>
    p.fontStyle &&
    css`
      font-style: ${p.fontStyle};
    `};
`

export default PageUi
