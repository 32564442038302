import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import { WebinarCallToActionInterface } from 'common/types/entities/WebinarCallToActionInterface'
import createCommonEntitySettings from '../createCommonEntitySettings'
import WebinarCallToActionSettings from './settings/WebinarCallToActionSettingsNew'
import WebinarSessionVideoSettingsNew from './settings/WebinarSessionVideoSettingsNew'

function createSettingsForWebinarSession(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.WebinarSessionVideo:
      return (
        <WebinarSessionVideoSettingsNew entity={entity as VideoInterface} />
      )
    case EntityTypeEnum.WebinarCallToAction:
      return (
        <WebinarCallToActionSettings
          entity={entity as WebinarCallToActionInterface}
        />
      )
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForWebinarSession
