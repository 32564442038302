import React from 'react'
import Affiliate from './components/Affiliate'
import Background from './components/Background'
import Tracking from './components/Tracking'
import Typography from './components/Typography'

function BlogLayoutSettings() {
  return (
    <React.Fragment>
      <Typography />
      <Background />
      <Tracking />
      <Affiliate />
    </React.Fragment>
  )
}

export default BlogLayoutSettings
