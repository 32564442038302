import React from 'react'
import PropTypes from 'prop-types'
import * as types from 'common/types/index'
import PricePlanItemUi from './ui/PricePlanItem'
import PricePlanValue from './PricePlanValue'

const getPlanWithTax = (pricePlan, tax, quantity) => {
  const pricePlanWithTax = { ...pricePlan }

  if (pricePlan.subscriptionPlan) {
    pricePlanWithTax.subscriptionPlan = {
      ...pricePlan.subscriptionPlan,
      amount: tax * quantity,
    }
  } else {
    pricePlanWithTax.directChargeAmount = tax * quantity
  }

  return pricePlanWithTax
}

const Vat = ({ plan, tax, quantity, translate, language }) => (
  <PricePlanItemUi description={translate('components.offer_price.vat')}>
    <PricePlanValue
      plan={getPlanWithTax(plan, tax, quantity)}
      translate={translate}
      language={language}
    />
  </PricePlanItemUi>
)

Vat.propTypes = {
  plan: types.offerPricePlan.isRequired,
  tax: PropTypes.number,
  translate: PropTypes.func.isRequired,
  quantity: PropTypes.number,
  language: PropTypes.string.isRequired,
}

Vat.defaultProps = {
  tax: 0,
  quantity: 1,
}

export default Vat
