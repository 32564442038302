import React from 'react'
import FaqSettings from './FaqSettings'
import FaqInterface, {
  FaqItemInterface,
} from 'common/types/entities/FaqInterface'
import { usePage, pageSelectors } from 'client/store/index'

type FaqItemSettingsProps = {
  entity: FaqItemInterface
}

function FaqItemSettings({ entity }: FaqItemSettingsProps) {
  const faqEntity = usePage(page =>
    pageSelectors.getEntityById(page, entity.parentId),
  ) as FaqInterface
  return (
    <>
      <FaqSettings entity={faqEntity} />
    </>
  )
}

export default FaqItemSettings
