import styled from 'styled-components'

const IconUi = styled.i`
  font-size: 16px !important;
  &:hover {
    color: #0074c7;
    cursor: pointer;
  }
`
export default IconUi
