import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import {
  AutomationRuleActionsEnum,
  AutomationRuleTriggersEnum,
} from 'client/enums/AutomationRulesEnum'

export interface AutomationRuleTriggerInterface {
  buttonId?: string
  checkBoxId?: string
  type: AutomationRuleTriggersEnum
  id: number
}

export interface AutomationRuleSaveTriggerInterface {
  buttonId?: string
  checkBoxId?: string
  type: AutomationRuleTriggersEnum
}

export const automationRuleTriggerEntityProperties: Record<
  string,
  keyof AutomationRuleSaveTriggerInterface
> = {
  buttonId: 'buttonId',
  checkBoxId: 'checkBoxId',
}

export type AutomationRulesActionsType = { id: number } & (
  | ActionTagInterface
  | ActionCampaignInterface
  | ActionUserInterface
  | ActionSpecificEmailInterface
  | ActionEmailInterface
  | ActionCourseInterface
  | ActionCourseBundleInterface
  | ActionWebhookInterface
  | ActionCommunityInterface
)

interface ActionCampaignInterface {
  type:
    | AutomationRuleActionsEnum.subscribe_to_campaign
    | AutomationRuleActionsEnum.unsubscribe_from_campaign
  campaign: { id: number; name: string }
}

export interface ActionTagInterface {
  type: AutomationRuleActionsEnum.add_tag | AutomationRuleActionsEnum.remove_tag
  tag: {
    id: number
    name: string
  }
}

interface ActionUserInterface {
  type: AutomationRuleActionsEnum.create_user
  locale: PageLocaleEnum
}

interface ActionSpecificEmailInterface {
  type: AutomationRuleActionsEnum.send_email_to_specific_address
  emailMessageWithRecipient: {
    id: number
    mailing: { currentEmail: { subject: string } }
  }
}

interface ActionEmailInterface {
  type: AutomationRuleActionsEnum.send_email
  systemeEmail: { id: number; subject: string }
}

export interface ActionCourseInterface {
  type:
    | AutomationRuleActionsEnum.enroll_in_course
    | AutomationRuleActionsEnum.revoke_access_to_course
  accessType: keyof typeof AutomationRuleContentTypesEnum
  course: { id: number; name: string }
}

export interface ActionCourseBundleInterface {
  type:
    | AutomationRuleActionsEnum.enroll_in_course_bundle
    | AutomationRuleActionsEnum.revoke_access_to_course_bundle
  enrollmentAccessType: keyof typeof AutomationRuleContentTypesEnum
  courseBundle: { id: number; name: string }
}
interface ActionWebhookInterface {
  type: AutomationRuleActionsEnum.send_webhook
  webhookUrl: string
}

interface ActionCommunityInterface {
  type:
    | AutomationRuleActionsEnum.grant_access_to_community
    | AutomationRuleActionsEnum.revoke_access_to_community
  community: { id: number; name: string }
}

export enum AutomationRuleContentTypesEnum {
  full_access = 'entity_settings.course.access_type.full_access',
  dripping_content = 'entity_settings.course.access_type.drip_content',
}
