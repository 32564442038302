import styled from 'styled-components'

const InputAddonSpanUi = styled.span`
  background: #f0f2f5;
  width: 50px;
  height: 40px;
  float: left;
  border: solid 1px #ebedf0;
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
`
export default InputAddonSpanUi
