import React from 'react'
import CommonInline from 'common/components/entities/Inline'
import * as types from 'common/types'
import { getEmulatedEntity } from 'client/components/core/BaseEntity/utils'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import emulationMobileStyles from 'client/components/entities/Popup/emulationMobileStyles'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

function Inline({ entity }) {
  const { isMobile } = useDeviceModeContext()
  const {
    id,
    childIds,
    options,
    styles,
    mobileStyles,
    type,
  } = getEmulatedEntity(entity, isMobile, emulationMobileStyles)
  return (
    <CommonInline styles={styles} options={options} mobileStyles={mobileStyles}>
      <ChildrenEntities id={id} type={type} childIds={childIds} />
    </CommonInline>
  )
}

Inline.propTypes = {
  entity: types.entity.isRequired,
}

export default Inline
