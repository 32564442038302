import React from 'react'
import { localeNames } from 'common/components/entities/LanguageSwitcher'
import Checkbox from 'client/components/core/Sidebar/components/Settings/components/Checkbox'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'

type LanguageCheckBoxProps = {
  update: (excludeLanguagesList: string[]) => void
  excludeLanguagesList: string[]
}

const languageList = Object.keys(localeNames)

function LanguageCheckList({
  update,
  excludeLanguagesList = [],
}: LanguageCheckBoxProps) {
  const locale = usePresentPage(selectors.getPageLocale)

  const currentLocale: string = locale === 'pt' ? 'br' : locale

  const handleLanguageExclude = (language: string) => (isExcluded: boolean) => {
    if (isExcluded) {
      update(
        excludeLanguagesList.filter(
          excludedLanguage => excludedLanguage !== language,
        ),
      )
    } else {
      update([...excludeLanguagesList, language])
    }
  }
  return (
    <>
      {languageList.map((language, key) => {
        return (
          <Checkbox
            key={key}
            labelText={localeNames[language]}
            needTranslate={false}
            update={handleLanguageExclude(language)}
            value={!excludeLanguagesList.includes(language)}
            labelIcon={null}
            helpMessage={''}
            inverse={false}
            disabled={language === currentLocale}
            directionRow
          />
        )
      })}
    </>
  )
}

export default LanguageCheckList
