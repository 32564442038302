import React from 'react'
import { usePage } from 'client/store'
import { pageSelectors } from 'client/store/page/pageSelectors'
import BaseFont, { FontProps, getInheritedFontTypeLabel } from './BaseFont'

function HeadingFont({
  fontFamily,
  fontStyle,
  fontWeight,
  labelText,
  update,
}: FontProps) {
  const globalHeadingFontFamily = usePage(
    pageSelectors.getGlobalHeadingFontFamily,
  )
  const globalTextFontFamily = usePage(pageSelectors.getGlobalFontFamily)
  const calculatedFontFamily =
    globalHeadingFontFamily === 'inherit' || !globalHeadingFontFamily
      ? globalTextFontFamily
      : globalHeadingFontFamily

  const pageType = usePage(pageSelectors.getPageType)
  const parentGlobalSettings = usePage(pageSelectors.getParentGlobalSettings)

  let inheritedFontTypeLabel = getInheritedFontTypeLabel(pageType)
  if (parentGlobalSettings && Object.keys(parentGlobalSettings).length == 0) {
    inheritedFontTypeLabel = 'settings_styles.font_family.inherited'
  }
  return (
    <BaseFont
      labelText={labelText}
      fontFamily={fontFamily}
      fontStyle={fontStyle}
      fontWeight={fontWeight}
      update={update}
      globalFontFamily={calculatedFontFamily}
      inheritedFontTypeLabel={inheritedFontTypeLabel}
    />
  )
}

export default HeadingFont
