import React from 'react'
import { HoverType } from '../../../../../../../../common/types/styleTypes'
import GroupTitle from '../../styleComponents/GroupTitle'
import HoverColorStyle from '../../styles/HoverColorStyle'
import HoverTransformStyle from '../../styles/HoverTransformStyle'

interface HoverButtonSettingProps {
  textColor: string
  backgroundColor: string
  subTextColor?: string
  hover?: HoverType
  mobileHover?: HoverType
  update: (hover: HoverType) => void
  mobileUpdate: (hover: HoverType) => void
}

function HoverButtonSetting({
  hover,
  mobileHover,
  update,
  mobileUpdate,
  textColor,
  backgroundColor,
  subTextColor,
}: HoverButtonSettingProps) {
  return (
    <>
      <GroupTitle>entity_settings.button.hover_effect.group_title</GroupTitle>
      <HoverColorStyle
        update={update}
        mobileUpdate={mobileUpdate}
        hover={hover}
        mobileHover={mobileHover}
        backgroundColor={backgroundColor}
        textColor={textColor}
        subTextColor={subTextColor}
      />
      <HoverTransformStyle
        update={update}
        mobileUpdate={mobileUpdate}
        transformHover={hover}
        mobileTransformHover={mobileHover}
      />
    </>
  )
}

export default HoverButtonSetting
