import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'
import { AutomationRuleSaveInterface } from 'client/store/automationRules/automationRulesReducer'
import {
  ActionEditType,
  ActionSaveType,
} from 'client/types/AutomationRulesActionsEditInterface'
import { findDeletedAction } from './userResourcesUtils'

export const getRuleApiData = (automation: AutomationRuleSaveInterface[]) => {
  return {
    rules: automation.map(el => {
      return {
        ...el,
        automationActions: el.automationActions.map(action =>
          getRuleActionApiData(action),
        ),
      }
    }),
  }
}

export const getSaveRuleData = (
  existingActions: ActionEditType[],
  createdActions: ActionEditType[],
  deletedActions: ActionSaveType[],
): ActionSaveType[] => {
  return [
    ...existingActions,
    ...createdActions.map(el => {
      const deletedTagAction = findDeletedAction(deletedActions, el)
      if (deletedTagAction) {
        return deletedTagAction
      } else {
        const temp = { ...el, created: true } as ActionSaveType
        return temp
      }
    }),
  ]
}

const getRuleActionApiData = (action: ActionSaveType) => {
  const cleanApiAction = { ...action } as ActionSaveType
  if (cleanApiAction.created) {
    delete cleanApiAction.created
    delete cleanApiAction.id
  }

  switch (cleanApiAction.type) {
    case AutomationRuleActionsEnum.add_tag:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        tag: cleanApiAction.tag.id,
      }
    case AutomationRuleActionsEnum.remove_tag:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        tag: cleanApiAction.tag.id,
      }
    case AutomationRuleActionsEnum.subscribe_to_campaign:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        campaign: cleanApiAction.campaign.id,
      }
    case AutomationRuleActionsEnum.unsubscribe_from_campaign:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        campaign: cleanApiAction.campaign.id,
      }
    case AutomationRuleActionsEnum.send_email:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        systemeEmail: cleanApiAction.systemeEmail.id,
      }
    case AutomationRuleActionsEnum.send_email_to_specific_address:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        emailMessageWithRecipient: cleanApiAction.emailMessageWithRecipient.id,
      }
    case AutomationRuleActionsEnum.enroll_in_course:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        course: cleanApiAction.course.id,
        courseAccessType: cleanApiAction.courseAccessType,
      }
    case AutomationRuleActionsEnum.revoke_access_to_course:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        course: cleanApiAction.course.id,
      }
    case AutomationRuleActionsEnum.revoke_access_to_course_bundle:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        courseBundle: cleanApiAction.courseBundle.id,
      }
    case AutomationRuleActionsEnum.enroll_in_course_bundle:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        courseBundle: cleanApiAction.courseBundle.id,
        enrollmentAccessType: cleanApiAction.enrollmentAccessType,
      }
    case AutomationRuleActionsEnum.create_user:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        locale: cleanApiAction.locale,
      }
    case AutomationRuleActionsEnum.send_webhook:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        webhookUrl: cleanApiAction.webhookUrl,
      }
    case AutomationRuleActionsEnum.grant_access_to_community:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        community: cleanApiAction.community?.id,
      }
    case AutomationRuleActionsEnum.revoke_access_to_community:
      return {
        ...cleanApiAction,
        type: cleanApiAction.type,
        community: cleanApiAction.community?.id,
      }
  }
}
