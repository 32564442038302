import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { InternalError } from 'common/errors'
import * as blogApi from '../api/blogApi'
import { usePage } from '../store'
import * as blogActions from '../store/blog/blogActions'
import blogReducer from '../store/blog/blogReducer'
import { pageSelectors } from '../store/page/pageSelectors'
import useDynamicStore from './useDynamicStore'

export default function useBlogData() {
  useDynamicStore({ key: 'blog', reducer: blogReducer })
  const dispatch = useDispatch()
  const pageId = usePage(pageSelectors.getPageId)

  useEffect(() => {
    async function fetchBlogData() {
      try {
        const blogData = await blogApi.getBlogData(pageId)
        dispatch(blogActions.fetchBlogData(blogData.data))
      } catch (error) {
        console.error(error)
        if (error instanceof InternalError) {
          ;(window as any).Rollbar.error(error)
        }
      }
    }
    fetchBlogData()
  }, [])
}
