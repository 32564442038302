import React from 'react'
import { BlogPostListingInterface } from 'common/types/entities/BlogPostListingInterface'
import { Checkbox } from 'client/components/core/Sidebar/components/Settings/components'
import BlogPostCardSettings from 'client/components/core/Sidebar/components/Settings/components/BlogPostCardSettings'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import PostListingLayoutSetting from 'client/components/core/Sidebar/components/Settings/components/PostListingLayoutSetting'
import Range from 'client/components/core/Sidebar/components/Settings/components/Range/Range'
import Select, {
  ChangeOptionValue,
} from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import {
  AlignStyle,
  FontSize,
  ShadowStyle,
} from 'client/components/core/Sidebar/components/Settings/styles'
import BorderRadius from 'client/components/core/Sidebar/components/Settings/styles/BorderRadius'
import LetterSpacing from 'client/components/core/Sidebar/components/Settings/styles/LetterSpacing'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import TextLineHeight from 'client/components/core/Sidebar/components/Settings/styles/TextLineHeight'
import MobileIconUi from 'client/components/core/Sidebar/components/Settings/styles/ui/MobileIconUi'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { blogSelectors, useBlog } from 'client/store'

function BlogPostListingSettings({
  entity,
}: {
  entity: BlogPostListingInterface
}) {
  const updateEntity = useUpdateEntity<BlogPostListingInterface>()
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)
  const categories = useBlog(blogSelectors.getBlogCategories)
  const { isMobile } = useDeviceModeContext()

  const categoriesChoice = categories.map(category => ({
    label: category.name,
    value: `${category.id}`,
  }))
  categoriesChoice.unshift({ label: 'All categories', value: '' })

  function updateCategories(state: ChangeOptionValue<string>) {
    if (state) {
      const updatedState = state.value
      updateProp('category')(updatedState)
    }
  }

  const getDefaultCategoriesOption = (category: string) => {
    const currentDefaultState = categoriesChoice.find(
      state => state.value === category,
    )
    if (currentDefaultState) {
      return currentDefaultState
    } else return categoriesChoice[0]
  }

  return (
    <>
      <Range
        label="entity_settings.blog_post_listing.maximum_posts_per_page"
        change={updateProp('maxPosts')}
        value={entity.maxPosts}
        allowNegative={false}
      />
      <Range
        label="entity_settings.blog_post_listing.rows_gap"
        change={updateProp(isMobile ? 'mobileRowsGap' : 'rowsGap')}
        value={isMobile ? entity.mobileRowsGap : entity.rowsGap}
        allowNegative={false}
      />
      {!entity.layoutType && (
        <>
          {!isMobile && (
            <Checkbox
              labelText="entity_settings.blog_post_listing.display_horizontally.label"
              labelIcon={isMobile && <MobileIconUi />}
              update={updateProp('displayHorizontally')}
              value={entity.displayHorizontally}
              directionRow={false}
              helpMessage={''}
              needTranslate={true}
              inverse={false}
            />
          )}
          {entity.displayHorizontally && !isMobile && (
            <>
              <Range
                label="entity_settings.blog_post_listing.columns_gap"
                change={updateProp('columnsGap')}
                value={entity.columnsGap}
                min={1}
                max={50}
                allowNegative={false}
              />
              <Range
                label="entity_settings.blog_post_listing.maximum_posts_per_row"
                change={updateProp('maxPostsPerRow')}
                value={entity.maxPostsPerRow}
                min={1}
                max={5}
                allowNegative={false}
              />
            </>
          )}
        </>
      )}
      <Select<string>
        labelText="entity_settings.blog_post_listing.category"
        selectedOption={getDefaultCategoriesOption(entity.category)}
        update={updateCategories}
        options={categoriesChoice}
        isMulti={false}
      />
      <PostListingLayoutSetting<BlogPostListingInterface>
        updateEntity={updateEntity}
        entity={entity}
      />
      <BlogPostCardSettings<BlogPostListingInterface>
        entity={entity}
        updateNestedProp={updateNestedProp}
        // @ts-ignore
        updateProp={updateProp}
        isMobile={isMobile}
      />
      <GroupTitle>entity_settings.image.header</GroupTitle>
      <ShadowStyle
        shadow={entity.imageBoxShadow}
        mobileShadow={entity.mobileImageBoxShadow}
        update={updateProp('imageBoxShadow')}
        mobileUpdate={updateProp('mobileImageBoxShadow')}
        isTitleNeeded={false}
      />
      <BorderRadius
        label="settings_styles.border_radius.label"
        labelIcon={isMobile ? <MobileIconUi /> : null}
        border={isMobile ? entity.mobileImageBorder : entity.imageBorder}
        update={updateProp(isMobile ? 'mobileImageBorder' : 'imageBorder')}
      />
      <MarginStyle
        label="settings_styles.margin.label"
        margin={isMobile ? entity.mobileImageMargin : entity.imageMargin}
        update={updateProp(isMobile ? 'mobileImageMargin' : 'imageMargin')}
      />
      <GroupTitle>entity_settings.blog_post_listing.title</GroupTitle>
      <FontSize
        update={updateProp('titleFontSize')}
        mobileUpdate={updateProp('mobileTitleFontSize')}
        fontSize={entity.titleFontSize}
        mobileFontSize={entity.mobileTitleFontSize}
      />
      <TextLineHeight
        fontSize={entity.titleFontSize}
        mobileFontSize={entity.mobileTitleFontSize}
        update={updateProp('titleLineHeight')}
        mobileUpdate={updateProp('mobileTitleLineHeight')}
        lineHeight={entity.titleLineHeight}
        mobileLineHeight={entity.mobileTitleLineHeight}
      />
      <TextFontUpdater<BlogPostListingInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'titleFontFamily'}
        fontWeightKey={'titleFontWeight'}
        fontStyleKey={'titleFontStyle'}
        mobileFontFamilyKey={'mobileTitleFontFamily'}
        mobileFontWeightKey={'mobileTitleFontWeight'}
        mobileFontStyleKey={'mobileTitleFontStyle'}
        fontFileIdKey={'titleFontFileId'}
        mobileFontFileIdKey={'mobileTitleFontFileId'}
      />
      <LetterSpacing
        change={
          isMobile
            ? updateProp('mobileTitleLetterSpacing')
            : updateProp('titleLetterSpacing')
        }
        letterSpacing={
          isMobile ? entity.mobileTitleLetterSpacing : entity.titleLetterSpacing
        }
      />
      <AlignStyle
        align={isMobile ? entity.mobileTitleAlign : entity.titleAlign}
        update={
          isMobile ? updateProp('mobileTitleAlign') : updateProp('titleAlign')
        }
        isFlex={false}
      />
      <ColorPicker
        update={updateProp('titleColor')}
        mobileUpdate={updateProp('mobileTitleColor')}
        color={entity.titleColor}
        mobileColor={entity.mobileTitleColor}
      />
      <MarginStyle
        label="settings_styles.margin.label"
        margin={isMobile ? entity.mobileTitleMargin : entity.titleMargin}
        update={updateProp(isMobile ? 'mobileTitleMargin' : 'titleMargin')}
      />
      <GroupTitle>entity_settings.blog_post_listing.description</GroupTitle>
      <FontSize
        update={updateProp('descriptionFontSize')}
        mobileUpdate={updateProp('mobileDescriptionFontSize')}
        fontSize={entity.descriptionFontSize}
        mobileFontSize={entity.mobileDescriptionFontSize}
      />
      <TextLineHeight
        fontSize={entity.descriptionFontSize}
        mobileFontSize={entity.mobileDescriptionFontSize}
        update={updateProp('descriptionLineHeight')}
        mobileUpdate={updateProp('mobileDescriptionLineHeight')}
        lineHeight={entity.descriptionLineHeight}
        mobileLineHeight={entity.mobileDescriptionLineHeight}
      />
      <TextFontUpdater<BlogPostListingInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'descriptionFontFamily'}
        fontWeightKey={'descriptionFontWeight'}
        fontStyleKey={'descriptionFontStyle'}
        mobileFontFamilyKey={'mobileDescriptionFontFamily'}
        mobileFontWeightKey={'mobileDescriptionFontWeight'}
        mobileFontStyleKey={'mobileDescriptionFontStyle'}
        fontFileIdKey={'descriptionFontFileId'}
        mobileFontFileIdKey={'mobileDescriptionFontFileId'}
      />
      <AlignStyle
        align={
          isMobile ? entity.mobileDescriptionAlign : entity.descriptionAlign
        }
        update={
          isMobile
            ? updateProp('mobileDescriptionAlign')
            : updateProp('descriptionAlign')
        }
        isFlex={false}
      />
      <ColorPicker
        update={updateProp('descriptionColor')}
        mobileUpdate={updateProp('mobileDescriptionColor')}
        color={entity.descriptionColor}
        mobileColor={entity.mobileDescriptionColor}
      />
      <MarginStyle
        label="settings_styles.margin.label"
        margin={
          isMobile ? entity.mobileDescriptionMargin : entity.descriptionMargin
        }
        update={updateProp(
          isMobile ? 'mobileDescriptionMargin' : 'descriptionMargin',
        )}
      />
      <GroupTitle>entity_settings.blog_post_listing.categories</GroupTitle>
      <FontSize
        update={updateProp('categoriesFontSize')}
        mobileUpdate={updateProp('mobileCategoriesFontSize')}
        fontSize={entity.categoriesFontSize}
        mobileFontSize={entity.mobileCategoriesFontSize}
      />
      <TextFontUpdater<BlogPostListingInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'categoriesFontFamily'}
        fontWeightKey={'categoriesFontWeight'}
        fontStyleKey={'categoriesFontStyle'}
        mobileFontFamilyKey={'mobileCategoriesFontFamily'}
        mobileFontWeightKey={'mobileCategoriesFontWeight'}
        mobileFontStyleKey={'mobileCategoriesFontStyle'}
        fontFileIdKey={'categoriesFontFileId'}
        mobileFontFileIdKey={'mobileCategoriesFontFileId'}
      />
      <AlignStyle
        align={isMobile ? entity.mobileCategoriesAlign : entity.categoriesAlign}
        update={
          isMobile
            ? updateProp('mobileCategoriesAlign')
            : updateProp('categoriesAlign')
        }
        isFlex={false}
      />
      <ColorPicker
        update={updateProp('categoriesColor')}
        mobileUpdate={updateProp('mobileCategoriesColor')}
        color={entity.categoriesColor}
        mobileColor={entity.mobileCategoriesColor}
      />
      <MarginStyle
        label="settings_styles.margin.label"
        margin={
          isMobile ? entity.mobileCategoriesMargin : entity.categoriesMargin
        }
        update={updateProp(
          isMobile ? 'mobileCategoriesMargin' : 'categoriesMargin',
        )}
      />
      <GroupTitle>entity_settings.blog_post_listing.date</GroupTitle>
      <Checkbox
        labelText="entity_settings.blog_post_listing.show_date.label"
        labelIcon={isMobile && <MobileIconUi />}
        update={updateProp('showDate')}
        value={entity.showDate}
        directionRow={false}
        helpMessage={''}
        needTranslate={true}
        inverse={false}
      />
      {entity.showDate && (
        <>
          <FontSize
            update={updateProp('dateFontSize')}
            mobileUpdate={updateProp('mobileDateFontSize')}
            fontSize={entity.dateFontSize}
            mobileFontSize={entity.mobileDateFontSize}
          />
          <TextFontUpdater<BlogPostListingInterface>
            entity={entity}
            update={updateEntity}
            fontFamilyKey={'dateFontFamily'}
            fontWeightKey={'dateFontWeight'}
            fontStyleKey={'dateFontStyle'}
            mobileFontFamilyKey={'mobileDateFontFamily'}
            mobileFontWeightKey={'mobileDateFontWeight'}
            mobileFontStyleKey={'mobileDateFontStyle'}
            fontFileIdKey={'dateFontFileId'}
            mobileFontFileIdKey={'mobileDateFontFileId'}
          />
          <AlignStyle
            align={isMobile ? entity.mobileDateAlign : entity.dateAlign}
            update={
              isMobile ? updateProp('mobileDateAlign') : updateProp('dateAlign')
            }
            isFlex={false}
          />
          <ColorPicker
            update={updateProp('dateColor')}
            mobileUpdate={updateProp('mobileDateColor')}
            color={entity.dateColor}
            mobileColor={entity.mobileDateColor}
          />
          <MarginStyle
            label="settings_styles.margin.label"
            margin={isMobile ? entity.mobileDateMargin : entity.dateMargin}
            update={updateProp(isMobile ? 'mobileDateMargin' : 'dateMargin')}
          />
        </>
      )}
      <GroupTitle>
        entity_settings.blog_post_listing.pagination_label
      </GroupTitle>
      <ColorPicker
        update={updateProp('paginationActiveColor')}
        mobileUpdate={updateProp('mobilePaginationActiveColor')}
        color={entity.paginationActiveColor}
        mobileColor={entity.mobilePaginationActiveColor}
        label="entity_settings.blog_post_listing.pagination.active_color"
      />
      <ColorPicker
        update={updateProp('paginationActiveBackgroundColor')}
        mobileUpdate={updateProp('mobilePaginationActiveBackgroundColor')}
        color={entity.paginationActiveBackgroundColor}
        mobileColor={entity.mobilePaginationActiveBackgroundColor}
        label="entity_settings.blog_post_listing.pagination.active_background_color"
      />
      <ColorPicker
        update={updateProp('paginationInactiveColor')}
        mobileUpdate={updateProp('mobilePaginationInactiveColor')}
        color={entity.paginationInactiveColor}
        mobileColor={entity.mobilePaginationInactiveColor}
        label="entity_settings.blog_post_listing.pagination.non_active_color"
      />
      <ColorPicker
        update={updateProp('paginationInactiveBackgroundColor')}
        mobileUpdate={updateProp('mobilePaginationInactiveBackgroundColor')}
        color={entity.paginationInactiveBackgroundColor}
        mobileColor={entity.mobilePaginationInactiveBackgroundColor}
        label="entity_settings.blog_post_listing.pagination.non_active_background_color"
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default BlogPostListingSettings
