import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import CommonPage from './CommonPage'
import InlineForm from './InlineForm'
import PaymentPage from './PaymentPage'
import PopupForm from './PopupForm'
import SalesPage from './SalesPage'
import createEntityElementForBlogPage from './blog-page/createEntityElementForBlogPage'
import createSettingsForBlogPage from './blog-page/createSettingsForBlogPage'
import BlogPostLayoutPage from './blog-post-layout/BlogPostLayoutPage'
import BlogPage from './blog/BlogPage'
import createEntityElementForBlog from './blog/createEntityElementForBlog'
import createSettingsForBlog from './blog/createSettingsForBlog'
import BlogPost from './blogPost/BlogPost'
import createEntityElementForBlogPost from './blogPost/createEntityElementForBlogPost'
import createEntityElementForContactUs from './contact-us/createEntityElementForContactUs'
import createSettingsForContactUs from './contact-us/createSettingsForContactUs'
import createCommonEntityElement from './createCommonEntityElement'
import createCommonEntitySettings from './createCommonEntitySettings'
import CustomLoginPage from './custom-login/CustomLoginPage'
import createEntityElementForCustomLogin from './custom-login/createEntityElementForCustomLogin'
import createSettingsForOfferPage from './offer/createSettingsForOfferPage'
import createEntityElementForSales from './sales/createEntityElementForSales'
import createSettingsForSales from './sales/createSettingsForSales'
import createEntityElementForUpsell from './upsell/createEntityElementForUpsell'
import createSettingsForUpsell from './upsell/createSettingsForUpsell'
import createEntityElementForWebinarRegistration from './webinar-registration-page/createEntityElementForWebinarRegistration'
import createSettingsForWebinarRegistration from './webinar-registration-page/createSettingsForWebinarRegistration'
import createEntityElementForWebinarSession from './webinar-session/createEntityElementForWebinarSession'
import createSettingsForWebinarSession from './webinar-session/createSettingsForWebinarSession'
import createEntityElementForWebinarThankYou from './webinar-thank-you/createEntityElementForWebinarThankYou'
import createSettingsForWebinarThankYou from './webinar-thank-you/createSettingsForWebinarThankYou'

export function createPage(type: PageTypeEnum) {
  let PageComponent
  let createComponent = createCommonEntityElement
  let createEntitySettings = createCommonEntitySettings
  switch (type) {
    case PageTypeEnum.OfferPage:
    case PageTypeEnum.OfferInner:
      createComponent = createEntityElementForUpsell
      createEntitySettings = createSettingsForOfferPage
      PageComponent = PaymentPage
      break
    case PageTypeEnum.OfferDownSellPage:
    case PageTypeEnum.OfferUpSellPage:
      createComponent = createEntityElementForUpsell
      createEntitySettings = createSettingsForUpsell
      PageComponent = PaymentPage
      break
    case PageTypeEnum.SalesPage:
      createComponent = createEntityElementForSales
      createEntitySettings = createSettingsForSales
      PageComponent = SalesPage
      break
    case PageTypeEnum.BlogHome:
    case PageTypeEnum.BlogLayout:
      PageComponent = BlogPage
      createComponent = createEntityElementForBlog
      createEntitySettings = createSettingsForBlog
      break
    case PageTypeEnum.BlogStatic:
      PageComponent = BlogPage
      createComponent = createEntityElementForBlogPage
      createEntitySettings = createSettingsForBlogPage
      break
    case PageTypeEnum.BlogPostBody:
      PageComponent = BlogPost
      createComponent = createEntityElementForBlogPost
      break
    case PageTypeEnum.Inline:
      PageComponent = InlineForm
      break
    case PageTypeEnum.Popup:
      PageComponent = PopupForm
      break
    case PageTypeEnum.CustomLogin:
      PageComponent = CustomLoginPage
      createComponent = createEntityElementForCustomLogin
      break
    case PageTypeEnum.FunnelContactUs:
      PageComponent = CommonPage
      createComponent = createEntityElementForContactUs
      createEntitySettings = createSettingsForContactUs
      break
    case PageTypeEnum.BlogPostLayout:
      PageComponent = BlogPostLayoutPage
      createComponent = createEntityElementForBlog
      createEntitySettings = createSettingsForBlog
      break
    case PageTypeEnum.WebinarThankYou:
      PageComponent = CommonPage
      createComponent = createEntityElementForWebinarThankYou
      createEntitySettings = createSettingsForWebinarThankYou
      break
    case PageTypeEnum.WebinarSession:
      PageComponent = CommonPage
      createComponent = createEntityElementForWebinarSession
      createEntitySettings = createSettingsForWebinarSession
      break
    case PageTypeEnum.WebinarRegistration:
      PageComponent = CommonPage
      createComponent = createEntityElementForWebinarRegistration
      createEntitySettings = createSettingsForWebinarRegistration
      break
    default:
      PageComponent = CommonPage
  }

  return [PageComponent, createComponent, createEntitySettings]
}
