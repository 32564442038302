import { useCallback } from 'react'
import * as actions from 'client/actions/entityActions'

export default function useUpdateEntityMobileStyle(dispatch, entity, sizing = null) {
  return useCallback(
    mobileStyle => value =>
      dispatch(
        actions.update({
          ...entity,
          mobileStyles: {
            ...entity.mobileStyles,
            [mobileStyle]: sizing ? `${value}${sizing}` : value,
          },
        }),
      ),
    [entity],
  )
}
