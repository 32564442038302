import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

const StyledGroupTitle = styled.div`
  color: rgba(52, 73, 94, 0.59);
  margin-top: 30px;
  color: #414355;
  font-size: 14px;
  background-color: #f7f9fa;
  border-top: 1px solid #ebedf0;
  border-bottom: 1px solid #ebedf0;
  padding: 5px 20px;
  &:first-child {
    margin-top: 10px;
  }
`

interface GroupTitleProps {
  afterChildren?: React.ReactNode
  children: React.ReactNode
}

const GroupTitle = ({ children, afterChildren }: GroupTitleProps) => (
  <StyledGroupTitle>
    <Trans parent="span">{children}</Trans>
    {afterChildren}
  </StyledGroupTitle>
)

export default GroupTitle
