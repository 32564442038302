import React from 'react'
import { BlogPostCategoriesInterface } from 'common/types/entities/BlogPostCategoriesInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import TextFontUpdater from '../components/Font/TextFontUpdater'
import {
  useUpdateEntity,
  useUpdateNestedProp,
  useUpdateProp,
} from '../hooks/useUpdateProps'
import HtmlAttrId from '../options/HtmlAttrId'
import { AlignStyle, FontSize, LineHeight } from '../styles'
import MarginStyle from '../styles/MarginStyle'

function BlogPostCategoriesSettingsNew({
  entity,
}: {
  entity: BlogPostCategoriesInterface
}) {
  const updateEntity = useUpdateEntity<BlogPostCategoriesInterface>()
  const updateProp = useUpdateProp(entity)
  const updateNestedProp = useUpdateNestedProp(entity)

  const { isMobile } = useDeviceModeContext()

  return (
    <>
      <AlignStyle
        align={isMobile ? entity.mobileAlignSelf : entity.alignSelf}
        update={
          isMobile ? updateProp('mobileAlignSelf') : updateProp('alignSelf')
        }
        isFlex={false}
      />
      <LineHeight
        lineHeight={entity.lineHeight}
        mobileLineHeight={entity.mobileLineHeight}
        update={updateProp('lineHeight')}
        mobileUpdate={updateProp('mobileLineHeight')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <FontSize
        labelText="entity_settings.content_table.header_font_size"
        update={updateProp('fontSize')}
        mobileUpdate={updateProp('mobileFontSize')}
        fontSize={entity.fontSize}
        mobileFontSize={entity.mobileFontSize}
      />
      <TextFontUpdater<BlogPostCategoriesInterface>
        entity={entity}
        update={updateEntity}
      />
      <ColorPicker
        update={updateProp('color')}
        mobileUpdate={updateProp('mobileColor')}
        color={entity.color}
        mobileColor={entity.mobileColor}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default BlogPostCategoriesSettingsNew
