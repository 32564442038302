import React from 'react'
import CommonBlogLatestPostsCarousel from 'common/components/entities/BlogLatestPostsCarousel'
import {
  clientBlogPostTs,
  clientImagePlaceholderSrc,
  rangePosts,
} from 'common/components/entities/BlogPostsNew/utils'
import CommonCarousel, {
  CarouselSlideUi,
} from 'common/components/entities/Carousel'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import {
  ImagePlacementVariants,
  LatestBlogPostsCarouselInterface,
} from 'common/types/entities/LatestBlogPostsCarouselInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { typedPageSelectors, usePage } from 'client/store'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const LATEST_BLOG_POSTS_PLACEHOLDER = '%BLOG_LATEST_POSTS%'

type LatestBlogPostsCarouselProps = {
  entity: LatestBlogPostsCarouselInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}
function LatestBlogPostsCarousel({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: LatestBlogPostsCarouselProps) {
  const locale = usePage(page => typedPageSelectors.getPageLocale(page))
  const { isMobile } = useDeviceModeContext()
  const calculatedRowSize = isMobile ? 1 : entity.maxPostsPerRow

  return (
    <BaseEntityNew
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonCarousel
        arrowsColor={entity.arrowsColor}
        controlsColor={entity.controlsColor}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        totalItems={entity.maxPosts}
        htmlAttrId={entity.htmlAttrId}
        activeSlideIndex={0}
        showArrows={entity.showArrows}
        mobileShowArrows={entity.mobileShowArrows}
        disableOverflow
      >
        <CarouselSlideUi active>
          <CommonBlogLatestPostsCarousel
            imagePlacement={entity.imagePlacement}
            mobileImagePlacement={entity.mobileImagePlacement}
            maxPosts={entity.maxPosts}
            htmlAttrId={entity.htmlAttrId}
            locale={locale as PageLocaleEnum}
            posts={[
              rangePosts(
                calculatedRowSize,
                clientBlogPostTs,
                clientImagePlaceholderSrc,
              ),
            ]}
            maxPostsPerRow={calculatedRowSize}
            showDate={entity.showDate}
            padding={entity.padding}
            imageMargin={entity.imageMargin}
            mobileImageMargin={entity.mobileImageMargin}
            titleMargin={entity.titleMargin}
            mobileTitleMargin={entity.mobileTitleMargin}
            categoriesMargin={entity.categoriesMargin}
            mobileCategoriesMargin={entity.mobileCategoriesMargin}
            descriptionMargin={entity.descriptionMargin}
            mobileDescriptionMargin={entity.mobileDescriptionMargin}
            dateMargin={entity.dateMargin}
            mobileDateMargin={entity.mobileDateMargin}
            mobilePadding={entity.mobilePadding}
            imageBorder={entity.imageBorder}
            mobileImageBorder={entity.mobileImageBorder}
            imageBoxShadow={entity.imageBoxShadow}
            mobileImageBoxShadow={entity.mobileImageBoxShadow}
            titleFontSize={entity.titleFontSize}
            mobileTitleFontSize={entity.mobileTitleFontSize}
            titleLineHeight={entity.titleLineHeight}
            mobileTitleLineHeight={entity.mobileTitleLineHeight}
            titleFontFamily={entity.titleFontFamily}
            titleFontWeight={entity.titleFontWeight}
            titleFontStyle={entity.titleFontStyle}
            mobileTitleFontFamily={entity.mobileTitleFontFamily}
            mobileTitleFontWeight={entity.mobileTitleFontWeight}
            mobileTitleFontStyle={entity.mobileTitleFontStyle}
            titleLetterSpacing={entity.titleLetterSpacing}
            mobileTitleLetterSpacing={entity.mobileTitleLetterSpacing}
            titleAlign={entity.titleAlign}
            mobileTitleAlign={entity.mobileTitleAlign}
            titleColor={entity.titleColor}
            mobileTitleColor={entity.mobileTitleColor}
            descriptionFontSize={entity.descriptionFontSize}
            mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
            descriptionLineHeight={entity.descriptionLineHeight}
            mobileDescriptionLineHeight={entity.mobileDescriptionLineHeight}
            descriptionFontFamily={entity.descriptionFontFamily}
            descriptionFontWeight={entity.descriptionFontWeight}
            descriptionFontStyle={entity.descriptionFontStyle}
            mobileDescriptionFontFamily={entity.mobileDescriptionFontFamily}
            mobileDescriptionFontWeight={entity.mobileDescriptionFontWeight}
            mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
            descriptionAlign={entity.descriptionAlign}
            mobileDescriptionAlign={entity.mobileDescriptionAlign}
            descriptionColor={entity.descriptionColor}
            mobileDescriptionColor={entity.mobileDescriptionColor}
            categoriesFontSize={entity.categoriesFontSize}
            mobileCategoriesFontSize={entity.mobileCategoriesFontSize}
            categoriesFontFamily={entity.categoriesFontFamily}
            categoriesFontWeight={entity.categoriesFontWeight}
            categoriesFontStyle={entity.categoriesFontStyle}
            mobileCategoriesFontFamily={entity.mobileCategoriesFontFamily}
            mobileCategoriesFontWeight={entity.mobileCategoriesFontWeight}
            mobileCategoriesFontStyle={entity.mobileCategoriesFontStyle}
            categoriesAlign={entity.categoriesAlign}
            mobileCategoriesAlign={entity.mobileCategoriesAlign}
            categoriesColor={entity.categoriesColor}
            mobileCategoriesColor={entity.mobileCategoriesColor}
            dateFontSize={entity.dateFontSize}
            mobileDateFontSize={entity.mobileDateFontSize}
            dateFontFamily={entity.dateFontFamily}
            dateFontWeight={entity.dateFontWeight}
            dateFontStyle={entity.dateFontStyle}
            mobileDateFontFamily={entity.mobileDateFontFamily}
            mobileDateFontWeight={entity.mobileDateFontWeight}
            mobileDateFontStyle={entity.mobileDateFontStyle}
            dateAlign={entity.dateAlign}
            mobileDateAlign={entity.mobileDateAlign}
            dateColor={entity.dateColor}
            mobileDateColor={entity.mobileDateColor}
            columnsGap={entity.columnsGap}
            postBackgroundColor={entity.postBackgroundColor}
            mobilePostBackgroundColor={entity.mobilePostBackgroundColor}
          />
        </CarouselSlideUi>
      </CommonCarousel>
    </BaseEntityNew>
  )
}

export default LatestBlogPostsCarousel

export function createLatestBlogPostsCarousel(
  parentId: string,
  masterBlockId?: string,
): LatestBlogPostsCarouselInterface {
  return {
    ...generateBaseEntity(
      EntityTypeEnum.LatestBlogPostsCarousel,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.LatestBlogPostsCarousel,
    imagePlacement: ImagePlacementVariants.Left,
    columnsGap: 30,
    maxPostsPerRow: 1,
    maxPosts: 5,
    showArrows: true,
    controlsColor: 'rgba(0, 0, 0, 1)',
    arrowsColor: 'rgba(0, 0, 0, 1)',
    showDate: false,
    titleFontSize: 26,
    mobileTitleFontSize: 22,
    titleColor: 'rgba(20, 45, 99, 1)',
    titleAlign: 'left',
    categoriesFontSize: 18,
    categoriesColor: 'rgba(27, 160, 255, 1)',
    categoriesAlign: 'left',
    descriptionFontSize: 22,
    mobileDescriptionFontSize: 18,
    descriptionColor: 'rgba(100, 101, 126, 1)',
    descriptionAlign: 'left',
    dateFontSize: 18,
    dateColor: 'rgba(20, 45, 99, 1)',
    dateAlign: 'left',
    padding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    postsReplacement: LATEST_BLOG_POSTS_PLACEHOLDER,
    imageMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    titleMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 10,
    },
    categoriesMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 10,
    },
    descriptionMargin: {
      marginTop: 5,
      marginRight: 0,
      marginBottom: 5,
      marginLeft: 10,
    },
    dateMargin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 10,
      marginLeft: 10,
    },
    mobileImageMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileTitleMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 5,
    },
    mobileCategoriesMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 5,
    },
    mobileDescriptionMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 5,
    },
    mobileDateMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 5,
    },
  }
}
