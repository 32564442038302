import { ActionCreators as UndoActionCreators } from 'redux-undo'
import {
  TOGGLE_POPUP_MANAGEMENT,
  TOGGLE_SETTINGS,
  TOGGLE_ACTIVE_POPUP,
  TOGGLE_NEED_SCROLL,
  TOGGLE_SETTINGS_OVERLAY,
  TOGGLE_CREATE_BLOCK_DIALOG,
  TOGGLE_PAGE_SETTINGS,
  CHANGE_TWO_STEP_PAYMENT_FORM_STEP_TYPE,
  HAS_LOST_ENTITIES,
  HAS_MASTER_BLOCK_ROOT_WITHOUT_MASTER_BLOCK_ID,
  HAS_MASTER_BLOCK_IDS_WITHOUT_MASTER_ROOT,
  TOGGLE_CODE_DIALOG,
  ALLOW_CODE_DIALOG_PORTAL,
  TOGGLE_SIDE_MENU_OPENED,
  TOGGLE_DROP_DOWN_MENU_OPENED,
  CLOSE_CODE_DIALOG,
  TOGGLE_INSTANT_UPLOAD,
  SHOW_SURVEY_RESULT,
} from '../actionTypes'

export const undo = () => dispatch => dispatch(UndoActionCreators.undo())

export const redo = () => dispatch => dispatch(UndoActionCreators.redo())

export const togglePopupManagement = value => dispatch => {
  dispatch({
    type: TOGGLE_POPUP_MANAGEMENT,
    payload: value,
  })
}

export const disableInstantUpload = () => ({
  type: TOGGLE_INSTANT_UPLOAD,
  payload: false,
})

export const showCodeDialog = () => ({
  type: TOGGLE_CODE_DIALOG,
  payload: true,
})

export const closeCodeDialog = () => ({
  type: CLOSE_CODE_DIALOG,
})

export const allowCodeDialogPortal = () => ({
  type: ALLOW_CODE_DIALOG_PORTAL,
  payload: true,
})

export const toggleSettings = id => dispatch => {
  dispatch({
    type: TOGGLE_SETTINGS,
    payload: { id },
  })
}

export const closePageSettings = () => dispatch => {
  dispatch({
    type: TOGGLE_PAGE_SETTINGS,
    payload: false,
  })
}

export const showPageSettings = () => dispatch => {
  dispatch({
    type: TOGGLE_PAGE_SETTINGS,
    payload: true,
  })
}

export const toggleActivePopup = id => dispatch => {
  dispatch({
    type: TOGGLE_ACTIVE_POPUP,
    payload: { id },
  })
}

export const toggleNeedScroll = value => dispatch => {
  dispatch({
    type: TOGGLE_NEED_SCROLL,
    payload: value,
  })
}

export const hideCreateBlockDialog = () => dispatch => {
  dispatch({
    type: TOGGLE_CREATE_BLOCK_DIALOG,
    payload: null,
  })
}

export const showCreateBlockDialog = entityId => dispatch => {
  dispatch({
    type: TOGGLE_CREATE_BLOCK_DIALOG,
    payload: entityId,
  })
}

export const toggleSettingsOverlay = value => dispatch => {
  dispatch({
    type: TOGGLE_SETTINGS_OVERLAY,
    payload: value,
  })
}

export const toggleSideMenuOpened = value => dispatch => {
  dispatch({
    type: TOGGLE_SIDE_MENU_OPENED,
    payload: value,
  })
}

export const toggleDropDownMenuOpened = value => dispatch => {
  dispatch({
    type: TOGGLE_DROP_DOWN_MENU_OPENED,
    payload: value,
  })
}

export const changeTwoStepPaymentFormStepType = value => dispatch => {
  dispatch({
    type: CHANGE_TWO_STEP_PAYMENT_FORM_STEP_TYPE,
    payload: value,
  })
}

export const setHasLostEntities = value => dispatch => {
  dispatch({
    type: HAS_LOST_ENTITIES,
    payload: value,
  })
}

export const setHasMasterBlockRootWithoutMasterBlockId = value => dispatch => {
  dispatch({
    type: HAS_MASTER_BLOCK_ROOT_WITHOUT_MASTER_BLOCK_ID,
    payload: value,
  })
}

export const setHasMasterBlockIdsWithoutMasterRoot = value => dispatch => {
  dispatch({
    type: HAS_MASTER_BLOCK_IDS_WITHOUT_MASTER_ROOT,
    payload: value,
  })
}

export const showSurveyResult = value => dispatch => {
  dispatch({
    type: SHOW_SURVEY_RESULT,
    payload: value,
  })
}
