import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuLinkTypeEnum } from 'common/components/entities/Menu/enums/MenuLinkTypeEnum'
import { MenuTargetEnum } from 'common/components/entities/Menu/enums/MenuTargetEnum'
import { BaseMenuItemInterface } from 'common/components/entities/Menu/types/MenuInterface'
import BlogLink from 'client/components/core/Sidebar/components/Settings/components/BlogLink'
import Select, {
  buildOption,
  ChangeOptionWithStringValue,
} from 'client/components/core/Sidebar/components/Settings/components/ReactSelect/ReactSelect'
import MenuItemTitle from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/MenuItemTitle'
import MenuItemDetailsUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemDetailsUi'
import MenuItemInputUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemInputUi'
import MenuItemLabelUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemLabelUi'
import { menuLinkTypeLocoKeys } from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/constants/MenuLinkTypeLocoKeys'
import { detectLinkType } from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/utils/detectLinkType'

interface BaseMenuItemProps {
  item: BaseMenuItemInterface
  number: number
  updateText: (e: React.ChangeEvent<HTMLInputElement>) => void
  updateLink: (link: string) => void
  updateTarget: (e: React.ChangeEvent<HTMLInputElement>) => void
  remove: () => void
  isAllowedBlogLinks: boolean
}

const BaseMenuItem = ({
  item,
  number,
  updateText,
  updateLink,
  remove,
  isAllowedBlogLinks,
  updateTarget,
}: BaseMenuItemProps) => {
  const { t } = useTranslation()
  const { text, link, target } = item

  const [selectedLinkType, setSelectedLinkType] = useState(
    detectLinkType(item, isAllowedBlogLinks),
  )

  const setBlogLink = (
    option: ChangeOptionWithStringValue<MenuLinkTypeEnum>,
  ) => {
    if (option) {
      if (
        item.link.includes('%BLOG_') &&
        option.value === MenuLinkTypeEnum.link_type_custom_url
      ) {
        updateLink('')
      }
      setSelectedLinkType(option.value)
    }
  }
  return (
    <MenuItemDetailsUi>
      <MenuItemTitle
        number={number}
        text={text}
        updateText={updateText}
        remove={remove}
      />
      {isAllowedBlogLinks && (
        <Select<MenuLinkTypeEnum>
          withoutWrapper
          update={setBlogLink}
          options={[
            buildOption(
              MenuLinkTypeEnum.link_type_blog_page,
              t(menuLinkTypeLocoKeys[MenuLinkTypeEnum.link_type_blog_page]),
            ),
            buildOption(
              MenuLinkTypeEnum.link_type_custom_url,
              t(menuLinkTypeLocoKeys[MenuLinkTypeEnum.link_type_custom_url]),
            ),
          ]}
          selectedOption={buildOption(
            selectedLinkType,
            t(menuLinkTypeLocoKeys[selectedLinkType]),
          )}
          isMulti={false}
        />
      )}
      {selectedLinkType === MenuLinkTypeEnum.link_type_blog_page ? (
        <BlogLink blogLink={link} update={updateLink} />
      ) : (
        <MenuItemInputUi
          value={link}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateLink(e.target.value)
          }
          placeholder={t('entity_settings.menu.item.link_placeholder')}
        />
      )}
      <MenuItemLabelUi>
        <input
          onChange={updateTarget}
          checked={target === MenuTargetEnum._blank}
          type="checkbox"
        />
        {t('entity_settings.menu.item.target_label')}
      </MenuItemLabelUi>
    </MenuItemDetailsUi>
  )
}

export default BaseMenuItem
