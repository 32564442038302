import React, { useRef } from 'react'
import { gradientPaletteInterface } from '.'
import useGradientColorPickerDragging from './hooks/useGradientColorPickerDragging'
import ColorStopContainerUi from './ui/ColorStopContainerUi'
import ColorStopUi from './ui/ColorStopUi'

type ColorStopProps = {
  stop: gradientPaletteInterface
  limits: { min: number; max: number; drop: number }
  onPosChange: ({ id, offset }: { id: number; offset: number }) => void
  onDragStart: (id: number) => void
  onDragEnd: ({ id, offset }: { id: number; offset: number }) => void
}
const ColorStop = ({
  stop,
  limits,
  onPosChange,
  onDragStart,
  onDragEnd,
}: ColorStopProps) => {
  const colorStopRef = useRef<HTMLDivElement>(null)
  const drag = useGradientColorPickerDragging({
    stop,
    limits,
    onPosChange,
    onDragStart,
    onDragEnd,
  })

  return (
    <ColorStopContainerUi
      ref={colorStopRef}
      left={stop.offset}
      onMouseDown={drag}
    >
      <ColorStopUi color={stop.color} />
    </ColorStopContainerUi>
  )
}

export default ColorStop
