import PropTypes from 'prop-types'
import React from 'react'
import { borderTypeKeys } from 'common/constants/settings'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { Attributes } from '../../options'
import GroupTitle from '../../styleComponents/GroupTitle'
import {
  BorderStyle,
  CornerStyle,
  MarginsStyle,
  PaddingStyle,
} from '../../styles'
import EditStepSwitcher from './EditStepSwitcher'

function TwoStepPaymentFormSettings({
  entity,
  updateOption,
  updateStyle,
  toggleOverlay,
  overlayVisible,
  updateMobileStyle,
  updateMobileOption,
}) {
  return (
    <React.Fragment>
      <EditStepSwitcher
        optInStepEntityId={entity.childIds[0]}
        paymentStepEntityId={entity.childIds[1]}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="entity_settings.two_step_payment_form.background_color.label"
        update={updateStyle('backgroundColor')}
        mobileUpdate={updateMobileStyle('backgroundColor')}
        color={entity.styles.backgroundColor}
        mobileColor={entity.mobileStyles.backgroundColor}
      />
      <ColorPicker
        label="entity_settings.two_step_payment_form.header_background_color.label"
        update={updateOption('headerBackgroundColor')}
        mobileUpdate={updateMobileOption('headerBackgroundColor')}
        color={entity.options.headerBackgroundColor}
        mobileColor={entity.mobileOptions.headerBackgroundColor}
      />
      <ColorPicker
        label="entity_settings.two_step_payment_form.header_text_color.label"
        update={updateOption('headerTextColor')}
        mobileUpdate={updateMobileOption('headerTextColor')}
        color={entity.options.headerTextColor}
        mobileColor={entity.mobileOptions.headerTextColor}
      />
      <ColorPicker
        label="entity_settings.two_step_payment_form.header_underline_color.label"
        update={updateOption('headerUnderlineColor')}
        mobileUpdate={updateMobileOption('headerUnderlineColor')}
        color={entity.options.headerUnderlineColor}
        mobileColor={entity.mobileOptions.headerUnderlineColor}
      />
      <ColorPicker
        label="entity_settings.two_step_payment_form.button_background_color.label"
        update={updateOption('buttonBackgroundColor')}
        mobileUpdate={updateMobileOption('buttonBackgroundColor')}
        color={entity.options.buttonBackgroundColor}
        mobileColor={entity.mobileOptions.buttonBackgroundColor}
      />
      <ColorPicker
        label="entity_settings.two_step_payment_form.button_color.label"
        update={updateOption('buttonTextColor')}
        mobileUpdate={updateMobileOption('buttonTextColor')}
        color={entity.options.buttonTextColor}
        mobileColor={entity.mobileOptions.buttonTextColor}
      />
      <BorderStyle
        updateOption={updateOption}
        updateMobileOption={updateMobileOption}
        updateStyle={updateStyle}
        updateMobileStyle={updateMobileStyle}
        type={entity.options.borderType}
        style={entity.styles.borderStyle}
        width={entity.styles.borderWidth}
        color={entity.styles.borderColor}
        mobileType={entity.mobileOptions.borderType}
        mobileStyle={entity.mobileStyles.borderStyle}
        mobileWidth={entity.mobileStyles.borderWidth}
        mobileColor={entity.mobileStyles.borderColor}
        toggleOverlay={toggleOverlay}
        overlayVisible={overlayVisible}
      />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <PaddingStyle
        mobilePadding={entity.mobileOptions.contentPadding}
        padding={entity.options.contentPadding}
        update={updateOption('contentPadding')}
        mobileUpdate={updateMobileOption('contentPadding')}
      />
      <Attributes entity={entity} update={updateOption} />
    </React.Fragment>
  )
}

TwoStepPaymentFormSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    childIds: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.shape({
      borderType: PropTypes.oneOf(Object.values(borderTypeKeys)),
      headerUnderlineColor: PropTypes.string.isRequired,
      buttonBackgroundColor: PropTypes.string.isRequired,
      buttonTextColor: PropTypes.string.isRequired,
      headerBackgroundColor: PropTypes.string.isRequired,
      headerTextColor: PropTypes.string.isRequired,
      contentPadding: PropTypes.string.isRequired,
    }).isRequired,
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.oneOf(Object.values(borderTypeKeys)),
      headerUnderlineColor: PropTypes.string,
      buttonBackgroundColor: PropTypes.string,
      buttonTextColor: PropTypes.string,
      headerBackgroundColor: PropTypes.string,
      headerTextColor: PropTypes.string,
      contentPadding: PropTypes.string,
    }).isRequired,
    styles: PropTypes.shape({
      marginTop: PropTypes.string.isRequired,
      marginRight: PropTypes.string.isRequired,
      marginBottom: PropTypes.string.isRequired,
      marginLeft: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      borderTopLeftRadius: PropTypes.string.isRequired,
      borderTopRightRadius: PropTypes.string.isRequired,
      borderBottomRightRadius: PropTypes.string.isRequired,
      borderBottomLeftRadius: PropTypes.string.isRequired,
      borderStyle: PropTypes.string.isRequired,
      borderWidth: PropTypes.string.isRequired,
      borderColor: PropTypes.string.isRequired,
    }).isRequired,
    mobileStyles: PropTypes.shape({
      marginTop: PropTypes.string.isRequired,
      marginRight: PropTypes.string.isRequired,
      marginBottom: PropTypes.string.isRequired,
      marginLeft: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      borderTopLeftRadius: PropTypes.string.isRequired,
      borderTopRightRadius: PropTypes.string.isRequired,
      borderBottomRightRadius: PropTypes.string.isRequired,
      borderBottomLeftRadius: PropTypes.string.isRequired,
      borderStyle: PropTypes.string.isRequired,
      borderWidth: PropTypes.string.isRequired,
      borderColor: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default TwoStepPaymentFormSettings
