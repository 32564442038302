import PropTypes from 'prop-types'
import React, { useEffect, memo } from 'react'
import { useDispatch } from 'react-redux'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import { NetworkError } from 'common/errors/NetworkError'
import * as paymentActions from 'client/actions/paymentActions'
import * as paymentApi from 'client/api/paymentApi'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import usePresentPage, { selectors } from '../hooks/usePresentPage'
import ActivePopup from './components/ActivePopup'
import PageUi from './ui/PageUi'

function SalesPage({ children }) {
  const dispatch = useDispatch()
  const isTemplate = usePresentPage(selectors.isTemplate)
  const pageId = usePresentPage(selectors.getPageId)
  const globalFontProperties = usePage(pageSelectors.getGlobalFontProperties)

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const { data } = await paymentApi.fetchPaymentData(pageId)
        dispatch(paymentActions.fetchPaymentDataSuccess(data))
      } catch (e) {
        if (e instanceof NetworkError) {
          // do nothing, we have stub data
        } else {
          window.Rollbar.error(
            'Fetch product data failed: Request failed with status code 500',
            e,
          )
        }
      }
    }
    if (!isTemplate) {
      fetchProductData()
    }
  }, [])

  return (
    <PageUi
      fontFamily={wrapFontFamily(globalFontProperties.fontFamily)}
      fontWeight={globalFontProperties.fontWeight}
      fontStyle={globalFontProperties.fontStyle}
    >
      {children}
      <ActivePopup />
    </PageUi>
  )
}

SalesPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(SalesPage)
