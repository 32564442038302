import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as types from 'common/types/index'
import centsToEuro from 'common/utils/centsToEuro'
import { getNetPrice } from 'common/utils/priceCalculator'
import Coupon from './Coupon'
import OneShotsSummary from './OneShotsSummary'
import Tariff from './Tariff'
import Total from './Total'
import Vat from './Vat'
import Divider from './ui/Divider'

const isOneShot = plan => plan && !plan.subscriptionPlan

const OfferPrice = props => {
  const {
    i18n: { language },
  } = useTranslation()
  const {
    htmlAttrId,
    pricePlans,
    bumpPlan,
    toggle,
    activeOfferPricePlanId,
    customPriceDescription,
    quantity,
    coupon,
    isVatNotChargeable,
    t,
    tax,
    bumpTax,
  } = props

  if (pricePlans.length === 0) {
    return <div />
  }

  const activePlan = pricePlans.find(plan => plan.id === activeOfferPricePlanId)
  const bumpAmount = bumpPlan ? bumpPlan.directChargeAmount : 0
  const allPlans = pricePlans.concat([bumpPlan]).filter(Boolean)

  const areAllPlansOneShot =
    allPlans.length > 1 ? allPlans.every(isOneShot) : false

  const activePlanAndBumpPlanOneShots =
    bumpPlan &&
    isOneShot(bumpPlan) &&
    activePlan &&
    isOneShot(activePlan) &&
    !areAllPlansOneShot

  const currency = activePlan.currency
  const totalTaxNum = tax * quantity + bumpTax
  const totalTax = centsToEuro(totalTaxNum)
  const netPrice = getNetPrice(activePlan.directChargeAmount, quantity, coupon)
  const totalAmountWithoutTaxAndDiscount = centsToEuro(
    activePlan.directChargeAmount * quantity + bumpAmount,
  )
  const totalAmount = centsToEuro(netPrice + bumpAmount + totalTaxNum)
  return (
    <div id={htmlAttrId}>
      {areAllPlansOneShot ? (
        <React.Fragment>
          {pricePlans.map(plan => (
            <Tariff
              key={`plan-${plan.id}`}
              plan={plan}
              quantity={quantity}
              description={customPriceDescription}
              translate={t}
              language={language}
              toggle={() => toggle(plan.id)}
              active={plan.id === activeOfferPricePlanId}
            />
          ))}
          {bumpPlan && (
            <React.Fragment>
              <Divider />
              <Tariff plan={bumpPlan} translate={t} language={language} />
            </React.Fragment>
          )}
          <Divider />
          <OneShotsSummary
            isVatNotChargeable={isVatNotChargeable}
            totalTax={totalTax}
            totalAmountWithoutTax={totalAmountWithoutTaxAndDiscount}
            totalAmount={totalAmount}
            translate={t}
            coupon={coupon}
            currency={currency}
            language={language}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {pricePlans.map(plan => (
            <>
              <Tariff
                key={`plan-${plan.id}`}
                plan={plan}
                description={customPriceDescription}
                translate={t}
                language={language}
                quantity={quantity}
                toggle={() => toggle(plan.id)}
                active={plan.id === activeOfferPricePlanId}
                // coupon={coupon} /* see https://systeme-team.slack.com/archives/C5ARLL5CP/p1574716217009900 */
              />
              {plan.id === activeOfferPricePlanId && (
                <>
                  {tax > 0 && (
                    <Vat
                      plan={activePlan}
                      tax={tax}
                      translate={t}
                      language={language}
                      quantity={quantity}
                    />
                  )}
                  {coupon && (
                    <Coupon coupon={coupon} translate={t} language={language} />
                  )}
                  {(tax || coupon) && (
                    <Total
                      plan={activePlan}
                      tax={tax}
                      quantity={quantity}
                      coupon={coupon}
                      translate={t}
                      language={language}
                    />
                  )}
                </>
              )}
            </>
          ))}
          {bumpPlan && (
            <React.Fragment>
              <Divider />
              <Tariff plan={bumpPlan} translate={t} language={language} />
              {bumpTax > 0 && (
                <React.Fragment>
                  <Vat
                    plan={bumpPlan}
                    tax={bumpTax}
                    translate={t}
                    language={language}
                  />
                  <Total
                    plan={bumpPlan}
                    tax={bumpTax}
                    translate={t}
                    language={language}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {activePlanAndBumpPlanOneShots && (
        <>
          <Divider />
          <OneShotsSummary
            isVatNotChargeable={isVatNotChargeable}
            totalTax={totalTax}
            totalAmountWithoutTax={totalAmountWithoutTaxAndDiscount}
            totalAmount={totalAmount}
            translate={t}
            coupon={coupon}
            currency={currency}
            language={language}
          />
        </>
      )}
    </div>
  )
}

OfferPrice.propTypes = {
  pricePlans: PropTypes.arrayOf(types.offerPricePlan).isRequired,
  bumpPlan: types.offerPricePlan,
  toggle: PropTypes.func,
  htmlAttrId: PropTypes.string,
  activeOfferPricePlanId: PropTypes.number.isRequired,
  customPriceDescription: PropTypes.string,
  quantity: PropTypes.number,
  isVatNotChargeable: PropTypes.bool.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    discountAmount: PropTypes.string.isRequired,
    discountType: PropTypes.string.isRequired,
  }),
  bumpTax: PropTypes.number,
  tax: PropTypes.number,
  t: PropTypes.func.isRequired,
}

OfferPrice.defaultProps = {
  tax: 0,
  bumpTax: 0,
  toggle: () => {},
  bumpPlan: null,
  customPriceDescription: '',
  quantity: 1,
  coupon: null,
}

export default OfferPrice
