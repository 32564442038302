import styled from 'styled-components'

const BackToBlockGroupsUi = styled.div`
  cursor: pointer;
  font-size: 17px;
  text-align: left;
  margin-top: -10px;
  color: #6b7c93;
`

export default BackToBlockGroupsUi
