import React from 'react'
import CommonCarousel from 'common/components/entities/Carousel'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import CarouselInterface, {
  CarouselItemInterface,
} from 'common/types/entities/CarouselInterface'
import ChildrenEntities from 'client/components/core/ChildrenEntities'
import {
  useManagement,
  managementSelectors,
  pageSelectors,
  usePage,
} from 'client/store/index'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import BaseEntity from '../../core/BaseEntity/BaseEntityNew'
import CarouselSlide from './CarouselSlide'

type CarouselProps = {
  entity: CarouselInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

function Carousel({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: CarouselProps) {
  const carouselActiveSlideNumber = useManagement(management =>
    managementSelectors.getEntityInnerItemActiveIndex(management, entity.id),
  )
  const carouselItems = usePage(page =>
    pageSelectors.getChildrenEntities(page, entity.childIds),
  )

  return (
    <BaseEntity
      entity={entity}
      isMoveUpAllowed={isMoveUpAllowed}
      isMoveDownAllowed={isMoveDownAllowed}
    >
      <CommonCarousel
        arrowsColor={entity.arrowsColor}
        controlsColor={entity.controlsColor}
        padding={entity.padding}
        mobilePadding={entity.mobilePadding}
        totalItems={entity.childIds.length}
        htmlAttrId={entity.htmlAttrId}
        activeSlideIndex={carouselActiveSlideNumber}
        showArrows={entity.showArrows}
        mobileShowArrows={entity.mobileShowArrows}
      >
        {carouselItems.map(
          (carouselSlideEntity: CarouselItemInterface, index: number) => (
            <CarouselSlide
              key={carouselSlideEntity.id}
              activeSlideIndex={carouselActiveSlideNumber}
              alignSelf={entity.verticalAlignment}
              mobileAlignSelf={entity.mobileVerticalAlignment}
              slideIndex={index}
            >
              <ChildrenEntities
                id={carouselSlideEntity.id}
                isReadOnly={carouselSlideEntity.isReadOnly}
                type={carouselSlideEntity.type}
                childIds={carouselSlideEntity.childIds}
                placeholderText="entities.carousel.empty_dropbox_text"
              />
            </CarouselSlide>
          ),
        )}
      </CommonCarousel>
    </BaseEntity>
  )
}

export function createCarousel(
  parentId: string,
  masterBlockId?: string,
): CarouselInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.Carousel,
    childIds: [],
    parentId: parentId,
    masterBlockId: masterBlockId,
    controlsColor: 'rgba(255, 255, 255, 1)',
    arrowsColor: 'rgba(255, 255, 255, 1)',
    padding: {
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    showArrows: true,
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.Carousel),
  }
}

export function createCarouselItem(
  parentId: string,
  masterBlockId?: string,
  creationItemNumber = 1,
): CarouselItemInterface {
  return {
    id: generateId(),
    type: EntityTypeEnum.CarouselItem,
    creationItemNumber: creationItemNumber,
    parentId: parentId,
    masterBlockId: masterBlockId,
    childIds: [],
  }
}

export default Carousel
