import React from 'react'
import { TwitterTweetButtonInterface } from 'common/types/entities/TwitterTweetButtonInterface'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { SettingsProps } from '.'
import Input from '../components/Input/Input'
import { useUpdateProp } from '../hooks/useUpdateProps'
import DeviceAppearanceNew from '../options/DeviceAppearance/DeviceAppearanceNew'
import { FlexAlignStyle } from '../styles'
import MarginStyle from '../styles/MarginStyle'

function TwitterTweetButtonSettings({
  entity,
}: SettingsProps<TwitterTweetButtonInterface>) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  return (
    <React.Fragment>
      <Input
        label="entity_settings.twitter_tweet_button.text.label"
        value={entity.text}
        update={updateProp('text')}
      />
      <FlexAlignStyle
        align={entity.alignSelf}
        mobileAlign={entity.mobileAlignSelf || entity.alignSelf}
        mobileUpdate={updateProp('mobileAlignSelf')}
        update={updateProp('alignSelf')}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
    </React.Fragment>
  )
}

export default TwitterTweetButtonSettings
