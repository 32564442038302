import React from 'react'
import { useTranslation } from 'react-i18next'
import MenuItemInputUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemInputUi'
import MenuItemRemoveUi from 'client/components/core/Sidebar/components/Settings/entities/MenuSettings/components/MenuItem/ui/MenuItemRemoveUi'

interface MenuItemTitleProps {
  number: number
  remove: () => void
  text: string
  updateText: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const MenuItemTitle = ({
  number,
  remove,
  updateText,
  text,
}: MenuItemTitleProps) => {
  const { t } = useTranslation()
  return (
    <>
      <div>
        #{number}
        <MenuItemRemoveUi className="far fa-trash-alt" onClick={remove} />
      </div>
      <MenuItemInputUi
        value={text}
        onChange={updateText}
        placeholder={t('entity_settings.menu.item.text_placeholder')}
      />
    </>
  )
}

export default MenuItemTitle
