import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import NewCheckbox from 'client/components/core/Sidebar/components/Settings/components/NewCheckbox'
import usePresentPage from 'client/hooks/usePresentPage'
import { getRootEntity } from 'client/reducers/pageReducer'
import { updateEntity } from 'client/store/page/pageActions'
import {
  useOverlayActionsContext,
  useOverlayVisibilityContext,
} from '../SettingsOverlayContext'
import { Boolean, Checkbox, Range } from '../components'
import ColorPicker from '../components/ColorPicker'
import useUpdateEntityMobileOption from '../hooks/useUpdateEntityMobileOption'
import useUpdateEntityMobileStyle from '../hooks/useUpdateEntityMobileStyle'
import useUpdateEntityOption from '../hooks/useUpdateEntityOption'
import useUpdateEntityStyle from '../hooks/useUpdateEntityStyle'
import { LanguageSelector } from '../options'
import { BorderStyle, CornerStyle, PaddingStyle, ShadowStyle } from '../styles'
import Background from './components/Background'
import Typography from './components/Typography'

function PopupFormSettings() {
  const { t } = useTranslation()
  const entity = usePresentPage(getRootEntity)
  const dispatch = useDispatch()
  const updateStyle = useUpdateEntityStyle(dispatch, entity)
  const updateMobileStyle = useUpdateEntityMobileStyle(dispatch, entity)
  const updateOption = useUpdateEntityOption(dispatch, entity)
  const updateMobileOption = useUpdateEntityMobileOption(dispatch, entity)
  const isOverlayVisible = useOverlayVisibilityContext()
  const { toggle } = useOverlayActionsContext()

  const toggleOpenOnExit = value => {
    let options = { ...entity.options }
    if (value === '0') {
      options.openOnMobileAutomatically = value
      delete options.mobileDelay
    }
    options.openOnExit = value
    dispatch(
      updateEntity({
        ...entity,
        options,
      }),
    )
  }

  const toggleMobileOpenAutomatically = value => {
    let convertedValue
    if (!value) {
      convertedValue = '0'
    } else {
      convertedValue = '1'
    }
    updateOption('openOnMobileAutomatically')(convertedValue)
  }

  const toggleDelayOption = value => {
    let options = { ...entity.options }
    if (value) {
      options.closedDays = 1
    } else {
      delete options.closedDays
    }
    dispatch(
      updateEntity({
        ...entity,
        options,
      }),
    )
  }

  return (
    <React.Fragment>
      <Boolean
        update={updateOption('showClose')}
        labelText="show close button"
        value={entity.options.showClose}
      />
      {entity.options.showClose && (
        <ColorPicker
          label={t('entity_settings.popup.color_button_close')}
          update={updateStyle('color')}
          mobileUpdate={updateMobileStyle('color')}
          color={entity.styles.color}
          mobileColor={entity.mobileStyles.color}
        />
      )}
      {entity.options.showClose === '1' && (
        <NewCheckbox
          checked={!!entity.options.closedDays}
          update={toggleDelayOption}
          labelText="entity_settings.popup.delay.label"
          helpMessage="entity_settings.popup.delay.help_message"
        />
      )}
      {!!entity.options.closedDays && (
        <Range
          labelText={t('entity_settings.popup.reopen_in_x_days', {
            days: entity.options.closedDays || 0,
          })}
          update={updateOption('closedDays')}
          value={entity.options.closedDays}
          min={1}
          max={365}
          allowNegative={false}
          needTranslate={false}
        />
      )}
      <Boolean
        value={entity.options.openAutomatically}
        update={updateOption('openAutomatically')}
        labelText="entity_settings.popup.open_automatically"
      />
      {entity.options.openAutomatically === '1' && (
        <Range
          labelText={t('entity_settings.popup.open_in_x_seconds', {
            seconds: entity.options.delay,
          })}
          value={entity.options.delay}
          update={updateOption('delay')}
          min={0}
          max={120}
          needTranslate={false}
        />
      )}
      <Boolean
        value={entity.options.openOnExit}
        update={toggleOpenOnExit}
        labelText="entity_settings.popup.open_on_exit"
      />
      {entity.options.openOnExit === '1' && (
        <Checkbox
          labelText="entity_settings.popup.mobile_open_on_exit"
          value={entity.options.openOnMobileAutomatically === '1'}
          update={toggleMobileOpenAutomatically}
        />
      )}
      {entity.options.openOnMobileAutomatically === '1' && (
        <Range
          labelText={t('entity_settings.popup.open_in_x_seconds', {
            seconds: entity.options.mobileDelay,
          })}
          value={entity.options.mobileDelay}
          update={updateOption('mobileDelay')}
          min={0}
          max={120}
          needTranslate={false}
        />
      )}
      <Typography />
      <Background />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <BorderStyle
        updateOption={updateOption}
        updateMobileOption={updateMobileOption}
        updateStyle={updateStyle}
        updateMobileStyle={updateMobileStyle}
        type={entity.options.borderType}
        style={entity.styles.borderStyle}
        width={entity.styles.borderWidth}
        color={entity.styles.borderColor}
        mobileType={entity.mobileOptions.borderType}
        mobileStyle={entity.mobileStyles.borderStyle}
        mobileWidth={entity.mobileStyles.borderWidth}
        mobileColor={entity.mobileStyles.borderColor}
        toggleOverlay={toggle}
        overlayVisible={isOverlayVisible}
      />
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <LanguageSelector />
    </React.Fragment>
  )
}

export default PopupFormSettings
