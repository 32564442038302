import styled from 'styled-components'

const EntityInnerItemSortIconUi = styled.i`
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s linear;
  padding: 5px 0;
  &:hover {
    transform: scale(1.2);
  }
`

export default EntityInnerItemSortIconUi
