import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import ConfirmationDialog from 'client/components/core/ConfirmationDialog'
import { PagesInterface } from 'client/db/tables/pages'
import { setFiles } from 'client/store/files/filesActions'
import { updatePageFromDb } from 'client/store/page/pageActions'
import pageSelectors from 'client/store/page/pageSelectors'
import database from '../db'
import { useFiles, usePage } from '../store'
import { deepCompare } from '../utils/deepCompare'

export const useUnsavedData = ({ notExecute }: { notExecute?: boolean }) => {
  const pageId = usePage(pageSelectors.getPageId)
  const pageRedux = usePage(pageState => pageState)
  const page: PagesInterface['page'] = {
    entities: pageRedux.entities,
    seo: pageRedux.seo,
    globalSettings: pageRedux.globalSettings,
    parentGlobalSettings: pageRedux.parentGlobalSettings,
    lastPopupNumber: pageRedux.lastPopupNumber,
    lastFormNumber: pageRedux.lastFormNumber,
  }

  //globalSettings contain isRequired func and it's redundant
  // @ts-ignore
  delete page.globalSettings.isRequired

  const files = useFiles(filesState => filesState)
  const timerRef = useRef<number | null>(null)
  const [needToShowModal, setNeedToShowModal] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const [pageDb, setPageDb] = useState<PagesInterface['page']>()
  const [filesDb, setFilesDb] = useState<PagesInterface['files']>()
  const [isStateReady, setIsStateReady] = useState(false)

  useEffect(() => {
    if (isStateReady || !pageId || notExecute) return
    if (!deepCompare(page, pageDb)) {
      setPageDb(page)
    }
    if (!deepCompare(files, filesDb)) {
      setFilesDb(files)
    }
    setIsStateReady(true)
  }, [page, files, isStateReady, pageId, notExecute, pageDb, filesDb])

  useEffect(() => {
    if (!pageId || !needToShowModal || notExecute) return
    const checkDbRecord = async () => {
      const existingRecord = await database.pages
        .where('page_id')
        .equals(pageId)
        .first()
      if (!existingRecord) return
      const state = { page, files }
      const stateFromDb = {
        page: existingRecord.page,
        files: existingRecord.files,
      }
      if (!deepCompare(state, stateFromDb)) {
        setPageDb(existingRecord.page)
        setFilesDb(existingRecord.files)
        setIsOpen(true)
      }
    }
    checkDbRecord()
    setNeedToShowModal(false)
  }, [files, needToShowModal, notExecute, page, pageId])

  useEffect(() => {
    if (!pageId || isOpen || !isStateReady || notExecute) return
    if (deepCompare(page, pageDb) && deepCompare(files, filesDb)) return
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = window.setTimeout(async () => {
      const existingRecord = await database.pages
        .where('page_id')
        .equals(pageId)
        .first()
      const addRecord = async () => {
        if (existingRecord) {
          await database.pages.put({
            ...existingRecord,
            page,
            files,
          })
        } else {
          const newRecord: PagesInterface = {
            page_id: pageId,
            page,
            files,
          }
          await database.pages.add(newRecord)
        }
      }
      try {
        await addRecord()
      } catch {
        // No space in indexedDB
        const firstRecord = database.pages.offset(0)
        if (firstRecord) {
          await firstRecord.delete()
          await addRecord()
        }
      }
    }, 3000)
    return () => clearTimeout(timerRef.current || 0)
  }, [files, filesDb, isOpen, isStateReady, notExecute, page, pageDb, pageId])

  const onPastePage = () => {
    if (pageDb) {
      dispatch(
        updatePageFromDb({
          entities: pageDb.entities,
          seo: pageDb.seo,
          globalSettings: pageDb.globalSettings,
          parentGlobalSettings: pageDb.parentGlobalSettings,
          lastPopupNumber: pageDb.lastPopupNumber,
          lastFormNumber: pageDb.lastFormNumber,
        }),
      )
    }
    if (filesDb) {
      dispatch(setFiles(filesDb))
    }
    setIsOpen(false)
  }

  const deleteDbRecord = async () => {
    const existingRecord = database.pages.where('page_id').equals(pageId)
    if (existingRecord) {
      await existingRecord.delete()
    }
    setPageDb(page)
    setFilesDb(files)
  }

  const onClose = () => {
    deleteDbRecord()
    setIsOpen(false)
  }

  const PageUnsavedModal = isOpen ? (
    <ConfirmationDialog
      title={'core.unsaved_changes.confirmation'}
      close={onClose}
      exit={onClose}
      save={onPastePage}
    />
  ) : null

  return { PageUnsavedModal, deleteDbRecord }
}
