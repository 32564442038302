export const groups = {
  sio: 'sio',
  text: 'text',
  media: 'media',
  layout: 'layout',
  payment: 'payment',
  form: 'form',
  webinar: 'webinar',
  social: 'social',
  blog: 'blog',
  other: 'other',
}

export const groupsTitles = {
  [groups.sio]: 'toolbox.groups.systeme_io',
  [groups.text]: 'toolbox.groups.text',
  [groups.media]: 'toolbox.groups.media',
  [groups.layout]: 'toolbox.groups.layout',
  [groups.payment]: 'toolbox.groups.payment',
  [groups.form]: 'toolbox.groups.form',
  [groups.webinar]: 'toolbox.groups.webinar',
  [groups.social]: 'toolbox.groups.social',
  [groups.other]: 'toolbox.groups.other',
  [groups.blog]: 'toolbox.groups.blog',
}
