import React, { useState, useEffect } from 'react'

type UseDraggingProps = {
  onDragEnd: (
    coordinates: {
      clientX: number
      clientY: number
    },
    angle?: number | void,
  ) => void
  onDragStart: (value: any) => void
  onDrag: (
    {
      clientX,
      clientY,
    }: {
      clientX: number
      clientY: number
    },
    useSnap?: boolean,
    angle?: number,
  ) => number | undefined | void
}

const useDragging = ({ onDragStart, onDrag, onDragEnd }: UseDraggingProps) => {
  const [dragging, setDragging] = useState(false)
  let angleChange: number | undefined | void

  const dragHandler = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (!e.button) {
      setDragging(true)
      onDragStart({ clientX: e.clientX, clientY: e.clientY })
    }
  }

  const deactivate = (e: MouseEvent) => {
    if (!dragging) return
    onDragEnd({ clientX: e.clientX, clientY: e.clientY }, angleChange)
    setDragging(false)
  }

  const handleDrag = (e: MouseEvent) => {
    if (!dragging) return
    angleChange = onDrag({ clientX: e.clientX, clientY: e.clientY })
  }

  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleDrag)
      document.addEventListener('mouseup', deactivate)
    }

    return () => {
      document.removeEventListener('mousemove', handleDrag)
      document.removeEventListener('mouseup', deactivate)
    }
  }, [dragging])

  return dragHandler
}

export default useDragging
