import {
  useDeviceModeContext,
  DeviceModeEnum,
} from 'client/context/DeviceModeContext'
import React from 'react'
import DeviceSwitcherUi from './ui/DeviceSwitcherUi'
import DeviceButtonUi from './ui/DeviceButtonUi'

function DeviceSwitcher() {
  const { deviceMode, setDeviceMode } = useDeviceModeContext()

  return (
    <DeviceSwitcherUi>
      <DeviceButtonUi
        active={deviceMode === DeviceModeEnum.Desktop}
        onClick={() => setDeviceMode(DeviceModeEnum.Desktop)}
      >
        <i className="fas fa-desktop" />
      </DeviceButtonUi>
      <DeviceButtonUi
        active={deviceMode === DeviceModeEnum.Mobile}
        onClick={() => setDeviceMode(DeviceModeEnum.Mobile)}
      >
        <i className="fas fa-mobile-alt" />
      </DeviceButtonUi>
    </DeviceSwitcherUi>
  )
}

export default DeviceSwitcher
