import styled from 'styled-components'

const BlockGroupUi = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 16px;
  color: #6b7c93;
  padding: 10px 0;
  transition: all 0.3s cubic-bezier(0, 0, 0.8, 1);
  &:hover {
    text-decoration: underline;
  }
`

export default BlockGroupUi
