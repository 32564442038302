import React from 'react'
import { useTranslation } from 'react-i18next'
import { faqListIcons } from 'common/constants/iconList'
import { HeadingTypeEnum } from 'common/enums/HeadingTypeEnum'
import { ContentTableInterface } from 'common/types/entities/ContentTableInterface'
import { Appearance } from 'common/types/entities/EntityInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import Choices from '../components/Choices'
import TextFontUpdater from '../components/Font/TextFontUpdater'
import IconPicker from '../components/IconPicker'
import IconPickerWithRemoveButton from '../components/IconPickerWithRemoveButton'
import Input from '../components/Input/Input'
import Range from '../components/Range/Range'
import Select, {
  ChangeOptionWithBooleanValue,
} from '../components/ReactSelect/ReactSelect'
import { useUpdateEntity, useUpdateProp } from '../hooks/useUpdateProps'
import DeviceAppearance from '../options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from '../options/HtmlAttrId'
import GroupTitle from '../styleComponents/GroupTitle'
import Border from '../styles/Border'
import FontSize from '../styles/FontSize'
import MarginStyle from '../styles/MarginStyle'

const choices = [
  {
    label: 'H1',
    value: HeadingTypeEnum.HeaderOne,
  },
  {
    label: 'H2',
    value: HeadingTypeEnum.HeaderTwo,
  },
  {
    label: 'H3',
    value: HeadingTypeEnum.HeaderThree,
  },
  {
    label: 'H4',
    value: HeadingTypeEnum.HeaderFour,
  },
  {
    label: 'H5',
    value: HeadingTypeEnum.HeaderFive,
  },
  {
    label: 'H6',
    value: HeadingTypeEnum.HeaderSix,
  },
]

function ContentTableSettings({ entity }: { entity: ContentTableInterface }) {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<ContentTableInterface>()
  const { isMobile } = useDeviceModeContext()
  const { t } = useTranslation()

  const defaultStateOptions = [
    {
      label: t('entity_settings.default_state.closed'),
      value: false,
    },
    {
      label: t('entity_settings.default_state.opened'),
      value: true,
    },
  ]

  const getDefaultStateOption = (isOpen: boolean) => {
    const currentDefaultState = defaultStateOptions.find(
      state => state.value === isOpen,
    )
    if (currentDefaultState) {
      return currentDefaultState
    } else return defaultStateOptions[0]
  }

  function updateDefaultState(state: ChangeOptionWithBooleanValue) {
    if (state) {
      const updatedState = state.value
      updateProp('isOpenedByDefault')(updatedState)
    }
  }

  function updateAppearance(appearance: Appearance) {
    updateEntity({
      ...entity,
      appearance,
    })
  }

  return (
    <>
      <GroupTitle>entity_settings.content_table.header.group_title</GroupTitle>
      <Input
        update={updateProp('headerTitle')}
        value={entity.headerTitle}
        label="entity_settings.content_table.header.text.label"
      />
      <FontSize
        labelText="entity_settings.content_table.header_font_size"
        update={updateProp('headerFontSize')}
        mobileUpdate={updateProp('headerFontSize')}
        fontSize={entity.headerFontSize}
        mobileFontSize={entity.headerFontSize}
      />
      <TextFontUpdater<ContentTableInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'headerFontFamily'}
        fontWeightKey={'headerFontWeight'}
        fontStyleKey={'headerFontStyle'}
        mobileFontFamilyKey={'headerFontFamily'}
        mobileFontWeightKey={'headerFontWeight'}
        mobileFontStyleKey={'headerFontStyle'}
        fontFileIdKey={'headerFontFileId'}
        mobileFontFileIdKey={'headerFontFileId'}
      />
      <ColorPicker
        update={updateProp('headerColor')}
        mobileUpdate={updateProp('headerColor')}
        color={entity.headerColor}
        mobileColor={entity.headerColor}
        label="entity_settings.content_table.header_color"
      />
      <IconPicker
        labelText="entity_options.icon_picker.title_collapsible_opened"
        iconList={faqListIcons}
        value={entity.iconClassName}
        update={updateProp('iconClassName')}
        searchPlaceholder="settings.components.icon_picker.placeholder"
      />
      <IconPickerWithRemoveButton
        label="entity_options.icon_picker.title_collapsible_closed"
        iconList={faqListIcons}
        value={entity.closedIconClassName}
        update={updateProp('closedIconClassName')}
      />
      <Select<boolean>
        labelText="entity_options.collapsible.default_state_title"
        selectedOption={
          typeof entity.isOpenedByDefault === 'boolean'
            ? getDefaultStateOption(entity.isOpenedByDefault)
            : defaultStateOptions[1]
        }
        update={updateDefaultState}
        options={defaultStateOptions}
        isMulti={false}
      />
      <ColorPicker
        update={updateProp('iconColor')}
        mobileUpdate={updateProp('iconColor')}
        color={entity.iconColor}
        mobileColor={entity.iconColor}
        label="entity_settings.content_table.icon_color"
      />
      <GroupTitle>entity_settings.content_table.content.group_title</GroupTitle>
      <Choices<HeadingTypeEnum>
        values={entity.headersTypes}
        choices={choices}
        update={updateProp('headersTypes')}
        label="entity_settings.content_table.headings_choices.label"
      />
      <FontSize
        labelText="entity_settings.content_table.content_font_size"
        update={updateProp('contentFontSize')}
        mobileUpdate={updateProp('contentFontSize')}
        fontSize={entity.contentFontSize}
        mobileFontSize={entity.contentFontSize}
      />
      <TextFontUpdater<ContentTableInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'contentFontFamily'}
        fontWeightKey={'contentFontWeight'}
        fontStyleKey={'contentFontStyle'}
        mobileFontFamilyKey={'contentFontFamily'}
        mobileFontWeightKey={'contentFontWeight'}
        mobileFontStyleKey={'contentFontStyle'}
        fontFileIdKey={'contentFontFileId'}
        mobileFontFileIdKey={'contentFontFileId'}
      />
      <Range
        label="entity_settings.content_table.space_between_content_items"
        change={updateProp('contentItemGap')}
        value={entity.contentItemGap}
        max={20}
      />
      <ColorPicker
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        label="entity_settings.content_table.background_color"
      />
      <ColorPicker
        update={updateProp('contentColor')}
        mobileUpdate={updateProp('contentColor')}
        color={entity.contentColor}
        mobileColor={entity.contentColor}
        label="entity_settings.content_table.content_color"
      />
      <Border border={entity.border} update={updateProp('border')} />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <DeviceAppearance
        update={updateAppearance}
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default ContentTableSettings
