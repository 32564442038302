import PropTypes from 'prop-types'
import React from 'react'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import TextFont from '../components/Font/TextFont'
import { DeviceAppearance, Attributes } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import { MarginsStyle, FontSize } from '../styles'

const TimerSettings = props => {
  const {
    entity,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateOptions,
    updateMobileOptions,
    toggleOverlay,
    overlayVisible,
    update,
  } = props

  const {
    options: { timeStyles, labelStyles },
    mobileOptions: {
      timeStyles: mobileTimeStyles = {},
      labelStyles: mobileLabelStyles = {},
    },
  } = entity

  const { isMobile } = useDeviceModeContext()

  const updateLabelStyles = (style, unitOfMeasure) => value => {
    updateOptions({
      ...entity.options,
      labelStyles: {
        ...entity.options.labelStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const updateTimeStyles = (style, unitOfMeasure) => value => {
    updateOptions({
      ...entity.options,
      timeStyles: {
        ...entity.options.timeStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const updateMobileLabelStyles = (style, unitOfMeasure) => value => {
    updateMobileOptions({
      ...entity.mobileOptions,
      labelStyles: {
        ...mobileLabelStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const updateMobileTimeStyles = (style, unitOfMeasure) => value => {
    updateMobileOptions({
      ...entity.mobileOptions,
      timeStyles: {
        ...mobileTimeStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const handleFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        delete updatedEntity.mobileStyles.fontWeight
        delete updatedEntity.mobileStyles.fontStyle
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            fontFamily: fontProperties.fontFamily,
          },
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.fontFileId
        ) {
          delete updatedEntity.mobileOptions.fontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.mobileStyles.fontWeight
          delete updatedEntity.mobileStyles.fontStyle
        }
        update({
          ...updatedEntity,
          mobileStyles: {
            ...updatedEntity.mobileStyles,
            ...fontProperties,
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        delete updatedEntity.styles.fontWeight
        delete updatedEntity.styles.fontStyle
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            fontFamily: fontProperties.fontFamily,
          },
          options: {
            ...updatedEntity.options,
            fontFileId: fontProperties.fontFileId,
          },
        })
      } else {
        if (updatedEntity.options.fontFileId) {
          delete updatedEntity.options.fontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.styles.fontWeight
          delete updatedEntity.styles.fontStyle
        }
        update({
          ...updatedEntity,
          styles: {
            ...updatedEntity.styles,
            ...fontProperties,
          },
        })
      }
    }
  }

  const fontFamily = isMobile
    ? entity.mobileStyles.fontFamily
    : entity.styles.fontFamily
  const fontWeight = isMobile
    ? entity.mobileStyles.fontWeight
    : entity.styles.fontWeight
  const fontStyle = isMobile
    ? entity.mobileStyles.fontStyle
    : entity.styles.fontStyle

  return (
    <React.Fragment>
      <Range
        labelText="entity_settings.countdown.delay.hours"
        value={entity.options.hours}
        update={updateOption('hours')}
        max={99}
      />
      <Range
        labelText="entity_settings.countdown.delay.minutes"
        value={entity.options.minutes}
        update={updateOption('minutes')}
        min={0}
        max={59}
      />
      <Range
        labelText="entity_settings.countdown.delay.seconds"
        value={entity.options.seconds}
        update={updateOption('seconds')}
        min={0}
        max={59}
      />
      <FontSize
        labelText={'entity_settings.start_timer.time_font_size'}
        update={updateTimeStyles('fontSize', 'px')}
        mobileUpdate={updateMobileTimeStyles('fontSize', 'px')}
        fontSize={timeStyles.fontSize}
        mobileFontSize={mobileTimeStyles ? mobileTimeStyles.fontSize : '20px'}
      />
      <FontSize
        labelText={'entity_settings.start_timer.label_font_size'}
        update={updateLabelStyles('fontSize', 'px')}
        mobileUpdate={updateMobileLabelStyles('fontSize', 'px')}
        fontSize={labelStyles.fontSize}
        mobileFontSize={mobileLabelStyles ? mobileLabelStyles.fontSize : '14px'}
      />
      <TextFont
        fontFamily={fontFamily}
        fontStyle={fontStyle}
        fontWeight={fontWeight}
        update={handleFontChange}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={updateTimeStyles('color')}
        mobileUpdate={updateMobileTimeStyles('color')}
        color={timeStyles.color}
        mobileColor={mobileTimeStyles.color}
        label="entity_settings.start_timer.time_color"
      />
      <ColorPicker
        update={updateLabelStyles('color')}
        mobileUpdate={updateMobileLabelStyles('color')}
        color={labelStyles.color}
        mobileColor={mobileLabelStyles.color}
        label="entity_settings.start_timer.label_color"
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

TimerSettings.propTypes = {
  overlayVisible: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  updateMobileOptions: PropTypes.func.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.object,
    styles: PropTypes.object,
  }).isRequired,
}

export default TimerSettings
