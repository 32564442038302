import styled from 'styled-components'

const AnglePickerContainerUi = styled.div`
  height: 32px;
  width: 32px;
  flex: none;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d2d5dc;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  cursor: pointer;
`

export default AnglePickerContainerUi
