import styled from 'styled-components'

const AutomationViewActionDescriptionContainerUi = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`

export default AutomationViewActionDescriptionContainerUi
