import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { BookingCalendarInterface } from 'common/types/entities/BookingCalendarInterface'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

export const createBookingCalendar = (
  parentId: string,
  masterBlockId?: string,
): BookingCalendarInterface => {
  const entity: BookingCalendarInterface = {
    ...generateBaseEntity(EntityTypeEnum.BookingCalendar, parentId, masterBlockId),
    type: EntityTypeEnum.BookingCalendar,

    padding: {
      paddingTop: 30,
      paddingRight: 30,
      paddingBottom: 30,
      paddingLeft: 30,
    },
    mobilePadding: {
      paddingTop: 0,
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: 0,
    },
    margin: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },

    eventId: undefined,
    availableSlotBackgroundColor: 'rgba(241, 244, 248, 1)',
    selectedSlotBackgroundColor: 'rgba(0, 160, 255, 1)',
    titlesColor: 'rgba(20, 45, 99, 1)',
    mobileTitlesColor: 'rgba(20, 45, 99, 1)',
    color: 'rgba(112, 112, 112, 1)',
    mobileColor: 'rgba(112, 112, 112, 1)',
  }

  return entity
}
