import React from 'react'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import FontSize from 'client/components/core/Sidebar/components/Settings/components/Font/FontSize'
import TextFontUpdater from 'client/components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'

interface CountdownFontsProps {
  entity: CountdownInterface
}

const CountdownFonts = ({ entity }: CountdownFontsProps) => {
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<CountdownInterface>()
  return (
    <>
      <GroupTitle>typography</GroupTitle>
      <FontSize
        fontSize={entity.timeStyles.fontSize}
        mobileFontSize={entity.timeStyles.mobileFontSize}
        onChange={value =>
          updateProp('timeStyles')({ ...entity.timeStyles, fontSize: value })
        }
        onChangeMobile={value =>
          updateProp('timeStyles')({
            ...entity.timeStyles,
            mobileFontSize: value,
          })
        }
        label={'settings_styles.countdown.time_font_size.label'}
      />
      <FontSize
        fontSize={entity.labelStyles.fontSize}
        mobileFontSize={entity.labelStyles.mobileFontSize}
        onChange={value =>
          updateProp('labelStyles')({ ...entity.labelStyles, fontSize: value })
        }
        onChangeMobile={value =>
          updateProp('labelStyles')({
            ...entity.labelStyles,
            mobileFontSize: value,
          })
        }
        label={'settings_styles.countdown.text_font_size.label'}
      />
      <TextFontUpdater<CountdownInterface>
        entity={entity}
        update={updateEntity}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        update={color =>
          updateProp('timeStyles')({ ...entity.timeStyles, color })
        }
        mobileUpdate={color =>
          updateProp('timeStyles')({ ...entity.timeStyles, mobileColor: color })
        }
        color={entity.timeStyles.color}
        mobileColor={entity.timeStyles.mobileColor}
        label="entity_settings.start_timer.time_color"
      />
      <ColorPicker
        update={color =>
          updateProp('labelStyles')({ ...entity.labelStyles, color })
        }
        mobileUpdate={color =>
          updateProp('labelStyles')({
            ...entity.labelStyles,
            mobileColor: color,
          })
        }
        color={entity.labelStyles.color}
        mobileColor={entity.labelStyles.mobileColor}
        label="entity_settings.start_timer.label_color"
      />
    </>
  )
}

export default CountdownFonts
