import PropTypes from 'prop-types'
import React, { memo } from 'react'
import {
  borderSizes,
  borderStyleValues,
  borderTypeKeys,
  borderTypeNames,
} from 'common/constants/settings'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Select } from '../components'
import GroupTitle from '../styleComponents/GroupTitle'
import MobileIconUi from '../styles/ui/MobileIconUi'

function BorderStyle(props) {
  const { toggleOverlay, overlayVisible } = props
  const { isMobile } = useDeviceModeContext()
  const style = isMobile ? props.mobileStyle : props.style
  const width = isMobile ? props.mobileWidth : props.width
  const type = isMobile ? props.mobileType : props.type
  const color = isMobile ? props.mobileColor : props.color

  const updateStyle = style => value =>
    isMobile
      ? props.updateMobileStyle(style)(value)
      : props.updateStyle(style)(value)

  const updateOption = option => value =>
    isMobile
      ? props.updateMobileOption(option)(value)
      : props.updateOption(option)(value)

  return (
    <div>
      <GroupTitle>settings_styles.border.label</GroupTitle>
      <Select
        update={updateStyle('borderStyle')}
        value={style}
        choiceList={borderStyleValues}
        labelText="settings_styles.border_style.label"
        labelIcon={isMobile && <MobileIconUi />}
      />
      {style !== borderStyleValues.none && [
        <ColorPicker
          key="border-color"
          update={updateStyle('borderColor')}
          color={color}
          label="settings_styles.border.color"
          singleColorMode
        />,
        props.isBorderTypeVisible && (
          <Select
            key="border-type"
            update={updateOption('borderType')}
            value={type}
            choiceList={borderTypeNames}
            labelText="settings_styles.border.type"
            labelIcon={isMobile && <MobileIconUi />}
          />
        ),
        <Select
          key="border-width"
          update={updateStyle('borderWidth')}
          value={width}
          choiceList={borderSizes}
          labelText="settings_styles.border.size"
          labelIcon={isMobile && <MobileIconUi />}
        />,
      ]}
    </div>
  )
}

BorderStyle.propTypes = {
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
  type: PropTypes.string,
  style: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
  mobileType: PropTypes.string,
  mobileStyle: PropTypes.string,
  mobileWidth: PropTypes.string,
  mobileColor: PropTypes.string,
  isBorderTypeVisible: PropTypes.bool,
}

BorderStyle.defaultProps = {
  type: borderTypeKeys.none,
  style: borderStyleValues.none,
  width: '1px',
  color: 'rgba(0, 0, 0, 0)',
  mobileType: borderTypeKeys.none,
  mobileStyle: borderStyleValues.none,
  mobileWidth: '1px',
  mobileColor: 'rgba(0, 0, 0, 0)',
  isBorderTypeVisible: true,
}

export default memo(BorderStyle)
