import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getShouldFetchActionsObj } from 'client/components/core/Sidebar/components/Settings/components/AutomationRulesSettings/utils/automationRuleUtils'
import { AutomationRuleActionsEnum } from 'client/enums/AutomationRulesEnum'
import { UserResourcesInterface } from 'client/types/UserResourcesInterface'
import { fetchResource } from 'client/utils/userResourcesUtils'

type UserResourcesContextType = {
  userResources: UserResourcesInterface
  isResourceLoading: Record<AutomationRuleActionsEnum, boolean>
  setShouldFetch: React.Dispatch<
    React.SetStateAction<Record<AutomationRuleActionsEnum, boolean>>
  >
}

const UserResourcesContext = createContext<UserResourcesContextType>({
  userResources: {
    userTags: [],
    userCampaigns: [],
    userEmails: [],
    userSpecificEmails: [],
    userCourses: [],
    userCommunities: [],
    userCourseBundles: [],
  },
  isResourceLoading: getShouldFetchActionsObj(),
  setShouldFetch: () => {},
})

export const useUserResources = () => useContext(UserResourcesContext)

function UserResourcesProvider(
  props: React.PropsWithChildren<React.ReactNode>,
) {
  const [shouldFetch, setShouldFetch] = useState<
    Record<AutomationRuleActionsEnum, boolean>
  >(getShouldFetchActionsObj())

  const [userResources, setUserResources] = useState<UserResourcesInterface>({
    userTags: [],
    userCampaigns: [],
    userEmails: [],
    userSpecificEmails: [],
    userCourses: [],
    userCommunities: [],
    userCourseBundles: [],
  })

  const [isResourceLoading, setIsResourceLoading] = useState<
    Record<AutomationRuleActionsEnum, boolean>
  >(getShouldFetchActionsObj())

  const { t } = useTranslation()

  useEffect(() => {
    if (shouldFetch.add_tag) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.add_tag,
        resourceKey: 'userTags',
        isBaseNumberOption: true,
      })
    }
  }, [shouldFetch.add_tag])

  useEffect(() => {
    if (shouldFetch.subscribe_to_campaign) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.subscribe_to_campaign,
        resourceKey: 'userCampaigns',
        isBaseNumberOption: true,
      })
    }
  }, [shouldFetch.subscribe_to_campaign])

  useEffect(() => {
    if (shouldFetch.send_email) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.send_email,
        resourceKey: 'userEmails',
        isBaseNumberOption: false,
      })
    }
  }, [shouldFetch.send_email])

  useEffect(() => {
    if (shouldFetch.send_email_to_specific_address) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.send_email_to_specific_address,
        resourceKey: 'userSpecificEmails',
        isBaseNumberOption: false,
      })
    }
  }, [shouldFetch.send_email_to_specific_address])

  useEffect(() => {
    if (shouldFetch.enroll_in_course) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.enroll_in_course,
        resourceKey: 'userCourses',
        isBaseNumberOption: true,
      })
    }
  }, [shouldFetch.enroll_in_course])

  useEffect(() => {
    if (shouldFetch.revoke_access_to_course) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.revoke_access_to_course,
        resourceKey: 'userCourses',
        isBaseNumberOption: true,
      })
    }
  }, [shouldFetch.revoke_access_to_course])

  useEffect(() => {
    if (shouldFetch.revoke_access_to_course_bundle) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.revoke_access_to_course_bundle,
        resourceKey: 'userCourseBundles',
        isBaseNumberOption: true,
      })
    }
  }, [shouldFetch.revoke_access_to_course_bundle])

  useEffect(() => {
    if (shouldFetch.enroll_in_course_bundle) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.enroll_in_course_bundle,
        resourceKey: 'userCourseBundles',
        isBaseNumberOption: true,
      })
    }
  }, [shouldFetch.enroll_in_course_bundle])

  useEffect(() => {
    if (shouldFetch.grant_access_to_community) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.grant_access_to_community,
        resourceKey: 'userCommunities',
        isBaseNumberOption: true,
      })
    }
  }, [shouldFetch.grant_access_to_community])

  useEffect(() => {
    if (shouldFetch.revoke_access_to_community) {
      fetchResource({
        setIsResourceLoading,
        setUserResources,
        actionKey: AutomationRuleActionsEnum.revoke_access_to_community,
        resourceKey: 'userCommunities',
        isBaseNumberOption: true,
      })
    }
  }, [shouldFetch.revoke_access_to_community])

  return (
    <UserResourcesContext.Provider
      value={{
        setShouldFetch,
        userResources,
        isResourceLoading,
      }}
    >
      {props.children}
    </UserResourcesContext.Provider>
  )
}

export default UserResourcesProvider
