import styled, { css } from 'styled-components'

const DeviceWorkAreaUi = styled.div`
  ${p =>
    p.isMobile
      ? css`
          width: 355px;
          height: 580px;
          scroll-behavior: smooth;
        `
      : css`
          /* it shouldn't be scrollable */
          height: calc(100vh - 44px);
          position: relative; /* for popups */
          /* allow child scroll */
          display: flex;
          flex-direction: column;
          overflow: hidden;
          /* end allow child scroll*/
        `}
`

export default DeviceWorkAreaUi
