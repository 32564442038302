import React from 'react'
import structureTypes from 'common/constants/structureTypes'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { UpsellButtonInterface } from 'common/types/entities/ButtonInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import UpsellButton from './entities/UpsellButton'

export default function createEntityElementForUpsell(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.UpsellAgreeButton:
    case EntityTypeEnum.UpsellDisagreeButton:
      return (
        <UpsellButton
          entity={entity as UpsellButtonInterface}
          {...additionalProps}
        />
      )
    case structureTypes.PHYSICAL_PRODUCT:
      return <div />
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}
