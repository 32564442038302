import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { facebookConversionEvents } from 'common/constants/FacebookConversionEvents'
import * as managementActions from 'client/actions/actionsManagement'
import useManagement, {
  selectors as managementSelectors,
} from 'client/hooks/useManagement'
import usePresentPage, {
  selectors as pageSelectors,
} from 'client/hooks/usePresentPage'
import { useManagement as useTypedManagement } from 'client/store/index'
import typedManagementSelectors from 'client/store/management/managementSelectors'
import { Button } from '../../components'
import ReactSelect from '../../components/ReactSelect/ReactSelect'
import useUpdateTrackingProperty from '../../hooks/useUpdateTrackingProperty'
import GroupTitle from '../../styleComponents/GroupTitle'

function Tracking() {
  const [AceEditor, setAceEditor] = useState(null)
  const [isHeaderCode, setHeaderCode] = useState(false)
  const [isFooterCode, setFooterCode] = useState(false)
  const tracking = usePresentPage(pageSelectors.getTracking)
  const updateTrackingProperty = useUpdateTrackingProperty()
  const isCodeDialogPortalAllowed = useManagement(
    managementSelectors.isCodeDialogPortalAllowed,
  )
  const hasFacebookConversionPixel = useTypedManagement(
    typedManagementSelectors.hasFacebookConversionPixel,
  )
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const loadCodeEditor = async () => {
    const { default: aceEditor } = await import(
      /* webpackChunkName: "code-editor" */ 'client/components/core/CodeEditor'
    )
    setAceEditor(() => aceEditor)
  }

  const openHeaderCodeEditor = async () => {
    if (!AceEditor) {
      loadCodeEditor()
    }
    setFooterCode(false)
    setHeaderCode(true)
    dispatch(managementActions.showCodeDialog())
  }

  const openFooterCodeEditor = async () => {
    if (!AceEditor) {
      loadCodeEditor()
    }
    setHeaderCode(false)
    setFooterCode(true)
    dispatch(managementActions.showCodeDialog())
  }

  function updateFbEvent(option) {
    if (!option) {
      return
    }

    return updateTrackingProperty('facebookConversionEvent')(option.value)
  }

  const facebookConversionEventsOptions = [
    {
      value: null,
      label: t('page_settings.tracking_code.default'),
    },
    {
      value: facebookConversionEvents.viewContent,
      label: t('page_settings.tracking_code.facebook_view_content_event'),
    },
    {
      value: facebookConversionEvents.purchase,
      label: t('page_settings.tracking_code.facebook_purchase_event'),
    },
    {
      value: facebookConversionEvents.lead,
      label: t('page_settings.tracking_code.facebook_lead_event'),
    },
    {
      value: facebookConversionEvents.schedule,
      label: t('page_settings.tracking_code.facebook_schedule_event'),
    },
  ]

  function getSelectedFacebookConversionEvent(event, t) {
    const selectedEvent = facebookConversionEventsOptions.find(
      option => option.value === event,
    )
    if (selectedEvent) {
      return selectedEvent
    }
    return facebookConversionEventsOptions[0]
  }

  return (
    <React.Fragment>
      <GroupTitle>page_settings.tracking.title</GroupTitle>
      {hasFacebookConversionPixel && (
        <ReactSelect
          update={updateFbEvent}
          options={facebookConversionEventsOptions}
          labelText="page_settings.tracking_code.select_facebook_event.label"
          selectedOption={getSelectedFacebookConversionEvent(
            tracking.facebookConversionEvent,
          )}
          isMulti={false}
        />
      )}
      <Button
        small
        onClick={openHeaderCodeEditor}
        helpMessage="page_settings.header_code.help_message"
      >
        page_settings.header_code_edit.label
      </Button>
      {isCodeDialogPortalAllowed && AceEditor && isHeaderCode && (
        <AceEditor
          label="page_settings.header_code_edit.label"
          value={tracking.headerCode}
          handleChange={updateTrackingProperty('headerCode')}
        />
      )}
      <Button
        onClick={openFooterCodeEditor}
        small
        helpMessage="page_settings.footer_code.help_message"
      >
        page_settings.footer_code_edit.label
      </Button>
      {isCodeDialogPortalAllowed && AceEditor && isFooterCode && (
        <AceEditor
          label="page_settings.footer_code_edit.label"
          value={tracking.footerCode}
          handleChange={updateTrackingProperty('footerCode')}
        />
      )}
    </React.Fragment>
  )
}

export default Tracking
