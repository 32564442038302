import React from 'react'
import styled from 'styled-components'
import { VerticalAlignmentVariants } from 'common/types/entities/BlogPostListingInterface'
import OptionWrapper from '../../styleComponents/OptionWrapper'

type VerticalAlignProps = {
  align?: VerticalAlignmentVariants
  update: (value: VerticalAlignmentVariants) => void
}
function VerticalAlign({ align, update }: VerticalAlignProps) {
  return (
    <OptionWrapper labelText="Align">
      <AlignButton
        onClick={() => update(VerticalAlignmentVariants.Top)}
        active={align === VerticalAlignmentVariants.Top}
      >
        <span className="fas fa-objects-align-top" />
      </AlignButton>
      <AlignButton
        onClick={() => update(VerticalAlignmentVariants.Center)}
        active={align === VerticalAlignmentVariants.Center}
      >
        <span className="fas fa-objects-align-center-vertical" />
      </AlignButton>
      <AlignButton
        onClick={() => update(VerticalAlignmentVariants.Bottom)}
        active={align === VerticalAlignmentVariants.Bottom}
      >
        <span className="fas fa-objects-align-bottom" />
      </AlignButton>
    </OptionWrapper>
  )
}

type AlignButtonProps = {
  active: boolean
}
const AlignButton = styled.button<AlignButtonProps>`
  width: 33.3%;
  float: left;
  margin-bottom: 0;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 0.2s ease;
  color: ${({ active }) => (active ? '#707e8d' : '#777')};
  background-color: ${({ active }) => (active ? '#ebeef1' : '#f9f9f9')};
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  &:hover {
    background-color: #ebeef1;
    color: #707e8d;
  }
`

export default VerticalAlign
