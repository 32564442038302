import React from 'react'
import { CountdownInterface } from 'common/types/entities/CountdownInterface'
import CountdownFonts from 'client/components/core/Sidebar/components/Settings/entities/Countdown/components/CountdownFonts'
import CountdownOptions from 'client/components/core/Sidebar/components/Settings/entities/Countdown/components/CountdownOptions'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'

interface CountdownSettingsProps {
  entity: CountdownInterface
}

const CountdownSettings = ({ entity }: CountdownSettingsProps) => {
  const updateProp = useUpdateProp(entity)
  const { isMobile } = useDeviceModeContext()
  return (
    <>
      <CountdownOptions entity={entity} />
      <CountdownFonts entity={entity} />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default CountdownSettings
