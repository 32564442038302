import PropTypes from 'prop-types'
import React from 'react'
import ProgressBarUi from './ui/ProgressBarUi'

const ProgressBar = ({ loaded, total }) => {
  const progress = Math.round((loaded * 100) / total)
  return (
    <React.Fragment>
      <ProgressBarUi
        isComplete={progress === 100}
        value={progress}
        max="100"
        data-label={`${progress} % complete`}
      >
        {progress} %
      </ProgressBarUi>
    </React.Fragment>
  )
}

ProgressBar.propTypes = {
  loaded: PropTypes.number,
  total: PropTypes.number,
  styles: PropTypes.objectOf(PropTypes.string),
}

ProgressBar.defaultProps = {
  loaded: 40,
  total: 100,
  styles: {},
}

export default ProgressBar
