import styled from 'styled-components'

const BorderRadiusLabelUi = styled.label`
  cursor: pointer;
  display: flex;
  width: 15px;
  height: 15px;
`

export default BorderRadiusLabelUi
