import React from 'react'
import usePresentPage, { selectors } from 'client/hooks/usePresentPage'
import Background from './components/Background'
import Seo from './components/Seo'
import Tracking from './components/Tracking'
import Typography from './components/Typography'

function BlogPageSettings() {
  const parentGlobalSettings = usePresentPage(selectors.getParentGlobalSettings)
  return (
    <React.Fragment>
      {/* no parent settings - blog page without layout */}
      {Object.keys(parentGlobalSettings).length === 0 && <Typography />}
      <Background />
      <Seo />
      <Tracking />
    </React.Fragment>
  )
}

export default BlogPageSettings
