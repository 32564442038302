import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import OptionWrapper from '../../styleComponents/OptionWrapper/index'
import InputUi from './ui/InputUi'

const Input = ({
  value,
  update,
  labelText,
  placeholder,
  type,
  helpMessage,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <OptionWrapper labelText={labelText} helpMessage={helpMessage}>
      <InputUi
        onChange={e => update(e.target.value)}
        value={value}
        placeholder={t(placeholder)}
        type={type}
        {...rest}
      />
    </OptionWrapper>
  )
}

Input.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  helpMessage: PropTypes.string,
}

Input.defaultProps = {
  value: '',
  labelText: null,
  placeholder: '',
  helpMessage: null,
  type: 'text',
}

export default Input

export { InputUi }
