import React from 'react'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import Input from 'client/components/core/Sidebar/components/Settings/components/Input/Input'
import {
  useUpdateEntity,
  useUpdateProp,
} from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DeviceAppearance from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearance'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import TextFontUpdater from '../../../components/core/Sidebar/components/Settings/components/Font/TextFontUpdater'
import GroupTitle from '../../../components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import FontSize from '../../../components/core/Sidebar/components/Settings/styles/FontSize'
import LineHeight from '../../../components/core/Sidebar/components/Settings/styles/LineHeight'

function OfferPriceSettings({ entity }: { entity: OfferPriceInterface }) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)
  const updateEntity = useUpdateEntity<OfferPriceInterface>()
  return (
    <>
      <Input
        label="entity_settings.offer_price.custom_price_description"
        value={entity.customDescription}
        update={updateProp('customDescription')}
      />
      <GroupTitle>entity_settings.price_plan.name_title</GroupTitle>
      <FontSize
        update={updateProp('nameFontSize')}
        mobileUpdate={updateProp('mobileNameFontSize')}
        fontSize={entity.nameFontSize}
        mobileFontSize={entity.mobileNameFontSize}
      />
      <LineHeight
        fontSize={entity.nameFontSize}
        mobileFontSize={entity.mobileNameFontSize}
        update={updateProp('nameLineHeight')}
        mobileUpdate={updateProp('mobileNameLineHeight')}
        lineHeight={entity.nameLineHeight}
        mobileLineHeight={entity.mobileNameLineHeight}
      />
      <TextFontUpdater<OfferPriceInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'nameFontFamily'}
        fontWeightKey={'nameFontWeight'}
        fontStyleKey={'nameFontStyle'}
        mobileFontFamilyKey={'mobileNameFontFamily'}
        mobileFontWeightKey={'mobileNameFontWeight'}
        mobileFontStyleKey={'mobileNameFontStyle'}
      />
      <ColorPicker
        label="global_settings.text_color.label"
        update={updateProp('nameColor')}
        color={entity.nameColor}
        mobileUpdate={updateProp('mobileNameColor')}
        mobileColor={entity.mobileNameColor}
      />
      <GroupTitle>entity_settings.price_plan.amount_title</GroupTitle>
      <FontSize
        update={updateProp('amountFontSize')}
        mobileUpdate={updateProp('mobileAmountFontSize')}
        fontSize={entity.amountFontSize}
        mobileFontSize={entity.mobileAmountFontSize}
      />
      <LineHeight
        fontSize={entity.amountFontSize}
        mobileFontSize={entity.mobileAmountFontSize}
        update={updateProp('amountLineHeight')}
        mobileUpdate={updateProp('mobileAmountLineHeight')}
        lineHeight={entity.amountLineHeight}
        mobileLineHeight={entity.mobileAmountLineHeight}
      />
      <TextFontUpdater<OfferPriceInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'amountFontFamily'}
        fontWeightKey={'amountFontWeight'}
        fontStyleKey={'amountFontStyle'}
        mobileFontFamilyKey={'mobileAmountFontFamily'}
        mobileFontWeightKey={'mobileAmountFontWeight'}
        mobileFontStyleKey={'mobileAmountFontStyle'}
      />
      <ColorPicker
        label="global_settings.text_color.label"
        update={updateProp('amountColor')}
        color={entity.amountColor}
        mobileUpdate={updateProp('mobileAmountColor')}
        mobileColor={entity.mobileAmountColor}
      />
      <GroupTitle>entity_settings.price_plan.description_title</GroupTitle>
      <FontSize
        update={updateProp('descriptionFontSize')}
        mobileUpdate={updateProp('mobileDescriptionFontSize')}
        fontSize={entity.descriptionFontSize}
        mobileFontSize={entity.mobileDescriptionFontSize}
      />
      <LineHeight
        fontSize={entity.descriptionFontSize}
        mobileFontSize={entity.mobileDescriptionFontSize}
        update={updateProp('descriptionLineHeight')}
        mobileUpdate={updateProp('mobileDescriptionLineHeight')}
        lineHeight={entity.descriptionLineHeight}
        mobileLineHeight={entity.mobileDescriptionLineHeight}
      />
      <TextFontUpdater<OfferPriceInterface>
        entity={entity}
        update={updateEntity}
        fontFamilyKey={'descriptionFontFamily'}
        fontWeightKey={'descriptionFontWeight'}
        fontStyleKey={'descriptionFontStyle'}
        mobileFontFamilyKey={'mobileDescriptionFontFamily'}
        mobileFontWeightKey={'mobileDescriptionFontWeight'}
        mobileFontStyleKey={'mobileDescriptionFontStyle'}
      />
      <ColorPicker
        label="global_settings.text_color.label"
        update={updateProp('descriptionColor')}
        color={entity.descriptionColor}
        mobileUpdate={updateProp('mobileDescriptionColor')}
        mobileColor={entity.mobileDescriptionColor}
      />
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance
        update={appearance =>
          updateEntity({
            ...entity,
            appearance,
          })
        }
        desktop={entity.appearance.desktop}
        mobile={entity.appearance.mobile}
      />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </>
  )
}

export default OfferPriceSettings
