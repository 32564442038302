import styled, { css } from 'styled-components'

type EntityInnerItemShowIconUiProps = {
  active: boolean
}

const EntityInnerItemShowIconUi = styled.i.attrs(() => ({
  className: 'far fa-eye',
}))<EntityInnerItemShowIconUiProps>`
  ${p =>
    p.active
      ? css`
          opacity: 0.5;
        `
      : css`
          cursor: pointer;
        `};
`

export default EntityInnerItemShowIconUi
