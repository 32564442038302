import request from 'client/utils/request'
import { BlogState } from '../store/blog/blogReducer'
import { BlogPostState } from '../store/blogPost/blogPostReducer'

export function getBlogData(pageId: number) {
  return request.get<BlogState>(`/pages/${pageId}/blog/data`)
}

export function getBlogPostData(pageId: number) {
  return request.get<BlogPostState>(`/pages/${pageId}/blog-post/data`)
}
