import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  isSomethingEditing,
  isPopupManagement,
  isPopupEditing,
  isPageEditing,
} from 'client/reducers/managementReducer'
import {
  toggleActivePopup,
  togglePopupManagement,
  toggleSettings,
} from 'client/actions/actionsManagement'
import { closePageSettings } from '../../../actions/actionsManagement'
import OverlayUi from './ui/OverlayUi'
import PointlessUi from './ui/PointlessUi'

export class Overlay extends PureComponent {
  hide = e => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.isSomethingEditing) {
      this.props.toggleSettings(null)
    } else if (this.props.isPopupManagement) {
      this.props.togglePopupManagement(false)
    } else if (this.props.isPageEditing) {
      this.props.closePageSettings()
    } else {
      this.props.toggleActivePopup(null)
      this.props.togglePopupManagement(true)
    }
  }

  render() {
    const enabled =
      this.props.isSomethingEditing ||
      this.props.isPopupManagement ||
      this.props.isPageEditing ||
      this.props.isPopupEditing
    const dark = this.props.isPopupManagement
    const needLiftUp =
      this.props.isPopupEditing && this.props.isSomethingEditing

    return (
      enabled && (
        <OverlayUi onClick={this.hide} dark={dark} needLiftUp={needLiftUp}>
          <PointlessUi />
        </OverlayUi>
      )
    )
  }
}

Overlay.propTypes = {
  isSomethingEditing: PropTypes.bool.isRequired,
  isPopupManagement: PropTypes.bool.isRequired,
  toggleSettings: PropTypes.func.isRequired,
  togglePopupManagement: PropTypes.func.isRequired,
  toggleActivePopup: PropTypes.func.isRequired,
  closePageSettings: PropTypes.func.isRequired,
  isPopupEditing: PropTypes.bool.isRequired,
  isPageEditing: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ management }) => ({
  isSomethingEditing: isSomethingEditing(management),
  isPopupManagement: isPopupManagement(management),
  isPopupEditing: isPopupEditing(management),
  isPageEditing: isPageEditing(management),
})

const mapDispatchToProps = {
  toggleSettings,
  togglePopupManagement,
  toggleActivePopup,
  closePageSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(Overlay)
