import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { UpsellButtonInterface } from 'common/types/entities/ButtonInterface'
import EntityInterface from 'common/types/entities/EntityInterface'
import createCommonEntitySettings from '../createCommonEntitySettings'
import UpsellButtonSettings from './settings/UpsellButtonSettings'

function createSettingsForUpsell(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.UpsellAgreeButton:
    case EntityTypeEnum.UpsellDisagreeButton:
      return <UpsellButtonSettings entity={entity as UpsellButtonInterface} />
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForUpsell
