import React, { useRef, memo } from 'react'
import { useActions } from 'common/hooks/useActions'
import * as actions from 'client/actions/actionsManagement'
import Overlay from 'client/components/core/Overlay'
import Page from 'client/components/core/Page/index'
import { WORK_AREA_ID } from 'client/constants/editorIds'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import useManagement, { selectors } from 'client/hooks/useManagement'
import ErrorBoundary from './ErrorBoundary'
import SkeletonContent from './SkeletonContent'
import ContentUi from './ui/ContentUi'
import DeviceWorkAreaUi from './ui/DeviceWorkAreaUi'
import MobilePhoneUi from './ui/MobilePhoneUi'

function Content() {
  const contentRef = useRef()
  const { isMobile } = useDeviceModeContext()
  const isSomethingEditing = useManagement(selectors.isSomethingEditing)
  const toggleSettings = useActions(actions.toggleSettings)

  const disableMobileSettings = e => {
    if (isMobile && isSomethingEditing && contentRef.current == e.target) {
      toggleSettings(null)
    }
  }

  return (
    <ErrorBoundary>
      <ContentUi onClick={disableMobileSettings} ref={contentRef}>
        <MobilePhoneUi isVisible={isMobile}>
          <DeviceWorkAreaUi isMobile={isMobile} id={WORK_AREA_ID}>
            <Page />
            <Overlay />
          </DeviceWorkAreaUi>
        </MobilePhoneUi>
      </ContentUi>
    </ErrorBoundary>
  )
}

export const Loading = SkeletonContent

export default memo(Content)
