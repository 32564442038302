import React from 'react'
import { SectionInterface } from 'common/components/entities/Section/types/SectionInterface'
import { commonWidthLocoKeys } from 'common/constants/commonWidth'
import { PageTypeEnum } from 'common/enums/PageTypeEnum'
import { Select } from 'client/components/core/Sidebar/components/Settings/components'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { StickySetting } from 'client/components/core/Sidebar/components/Settings/entities/SectionSettings/components/StickySetting'
import { useUpdateProp } from 'client/components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import DelayOption from 'client/components/core/Sidebar/components/Settings/options/DelayOption'
import DeviceAppearanceNew from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import GroupTitle from 'client/components/core/Sidebar/components/Settings/styleComponents/GroupTitle'
import BackgroundPositionStyleNew from 'client/components/core/Sidebar/components/Settings/styles/BackgroundImageNew/BackgroundPositionStyleNew'
import MarginStyle from 'client/components/core/Sidebar/components/Settings/styles/MarginStyle'
import ShadowStyle from 'client/components/core/Sidebar/components/Settings/styles/ShadowStyle'
import PaddingStyle from 'client/components/core/Sidebar/components/Settings/styles/SplitPaddingStyle'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { pageSelectors, usePage } from 'client/store'

interface SectionSettingsProps {
  entity: SectionInterface
}

const renderStickySettings = (
  isMobile: boolean,
  entity: SectionInterface,
  updateProp: <K extends keyof SectionInterface>(
    prop: K,
  ) => (value: SectionInterface[K]) => void,
) =>
  isMobile ? (
    <StickySetting
      entityHtmlAttrId={entity.htmlAttrId}
      sticky={entity.mobileSticky}
      update={updateProp('mobileSticky')}
    />
  ) : (
    <StickySetting
      entityHtmlAttrId={entity.htmlAttrId}
      sticky={entity.sticky}
      update={updateProp('sticky')}
    />
  )

const SectionSettings = ({ entity }: SectionSettingsProps) => {
  const { isMobile } = useDeviceModeContext()
  const pageType = usePage(pageSelectors.getPageType)
  const updateProp = useUpdateProp(entity)

  const showStickySettings = pageType !== PageTypeEnum.Lecture

  return (
    <div>
      <MarginStyle
        groupTitle={true}
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <PaddingStyle
        groupTitle={true}
        padding={isMobile ? entity.mobilePadding : entity.padding}
        update={updateProp(isMobile ? 'mobilePadding' : 'padding')}
      />
      <GroupTitle>page_settings.body.background.group_title</GroupTitle>
      <ColorPicker
        label="bg color"
        color={entity.backgroundColor}
        mobileColor={entity.mobileBackgroundColor}
        update={updateProp('backgroundColor')}
        mobileUpdate={updateProp('mobileBackgroundColor')}
      />
      <ColorPicker
        label="inner bg color"
        color={entity.containerBackgroundColor}
        mobileColor={entity.mobileContainerBackgroundColor}
        update={updateProp('containerBackgroundColor')}
        mobileUpdate={updateProp('mobileContainerBackgroundColor')}
      />
      <BackgroundPositionStyleNew
        backgroundPosition={entity.background}
        fileId={
          isMobile ? entity.mobileBackgroundFileId : entity.backgroundFileId
        }
        updateBackgroundFileId={
          isMobile
            ? updateProp('mobileBackgroundFileId')
            : updateProp('backgroundFileId')
        }
        update={updateProp('background')}
      />
      {!isMobile && (
        <>
          <GroupTitle>size & position</GroupTitle>
          <Select
            value={entity.contentWidth}
            update={updateProp('contentWidth')}
            labelText="entity_settings.section.content_width.label"
            helpMessage="entity_settings.section.content_width.help_message"
            choiceList={commonWidthLocoKeys}
            needTranslate={true}
          />
        </>
      )}
      <ShadowStyle
        shadow={entity.boxShadow}
        mobileShadow={entity.mobileBoxShadow}
        update={updateProp('boxShadow')}
        mobileUpdate={updateProp('mobileBoxShadow')}
      />
      {showStickySettings && renderStickySettings(isMobile, entity, updateProp)}
      <DelayOption delay={entity.delay} update={updateProp('delay')} />
      <DeviceAppearanceNew entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </div>
  )
}

export default SectionSettings
