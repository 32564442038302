import styled from 'styled-components'

const ColorRemoverUi = styled.i`
  font-size: 30px;
  transition: all 0.2s linear;
  text-shadow: 1px 1px 2px #ccc;
  opacity: 0.7;
  &:hover {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1.05);
  }
`
export default ColorRemoverUi
