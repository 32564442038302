import styled from 'styled-components'

const AnswerGroupUi = styled.div`
  display: flex;
  flex-basis: 5%;
  justify-content: start;
  align-items: flex-end;
  margin-bottom: 10px;
`

export default AnswerGroupUi
