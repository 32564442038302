import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { DeviceModeContext } from 'client/context/DeviceModeContext'
import { Input, Boolean } from '../components'
import TextFont from '../components/Font/TextFont'
import { Attributes, DelayOption, DeviceAppearance } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import {
  PaddingStyle,
  ShadowStyle,
  BorderStyle,
  MarginsStyle,
  CornerStyle,
  FontSize,
} from '../styles'
import FlexAlignStyle from '../styles/FlexAlignStyle'

class ContentBoxSettingsOld extends PureComponent {
  static contextType = DeviceModeContext

  render() {
    const {
      toggleOverlay,
      overlayVisible,
      entity,
      entityWithoutEmulation,
      update,
      updateOption,
      updateOptions,
      updateMobileOption,
      updateMobileOptions,
    } = this.props

    const updateHeaderStyles = (style, unitOfMeasure) => value => {
      updateOptions({
        ...entityWithoutEmulation.options,
        headerStyles: {
          ...entityWithoutEmulation.options.headerStyles,
          [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
        },
      })
    }

    const updateMobileHeaderStyles = (style, unitOfMeasure) => value => {
      updateMobileOptions({
        ...entityWithoutEmulation.mobileOptions,
        headerStyles: {
          ...entityWithoutEmulation.mobileOptions.headerStyles,
          [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
        },
      })
    }

    const updateStyle = (style, unit) => value => {
      const updatedEntity = {
        ...entityWithoutEmulation,
        options: {
          ...entityWithoutEmulation.options,
        },
        styles: {
          ...entityWithoutEmulation.styles,
          [style]: unit ? `${value}${unit}` : value,
        },
      }

      if (style === 'borderTopLeftRadius') {
        updatedEntity.options.headerStyles.borderTopLeftRadius = value
      }

      if (style === 'borderTopRightRadius') {
        updatedEntity.options.headerStyles.borderTopRightRadius = value
      }

      this.props.update(updatedEntity)
    }

    const updateMobileStyle = (style, unit) => value => {
      const updatedEntity = {
        ...entityWithoutEmulation,
        mobileOptions: {
          ...entityWithoutEmulation.mobileOptions,
        },
        mobileStyles: {
          ...entityWithoutEmulation.mobileStyles,
          [style]: unit ? `${value}${unit}` : value,
        },
      }

      if (style === 'borderTopLeftRadius') {
        if (!updatedEntity.mobileOptions.headerStyles) {
          updatedEntity.mobileOptions.headerStyles = {}
        }
        updatedEntity.mobileOptions.headerStyles.borderTopLeftRadius = value
      }

      if (style === 'borderTopRightRadius') {
        if (!updatedEntity.mobileOptions.headerStyles) {
          updatedEntity.mobileOptions.headerStyles = {}
        }
        updatedEntity.mobileOptions.headerStyles.borderTopRightRadius = value
      }

      this.props.update(updatedEntity)
    }

    const handleTextFontChange = fontProperties => {
      const updatedEntity = { ...entity }
      if (this.context.isMobile) {
        if (fontProperties.fontFileId) {
          delete updatedEntity.mobileOptions.headerStyles.fontWeight
          delete updatedEntity.mobileOptions.headerStyles.fontStyle
          updateMobileOptions({
            ...updatedEntity.mobileOptions,
            fontFileId: fontProperties.fontFileId,
            headerStyles: {
              ...updatedEntity.mobileOptions.headerStyles,
              fontFamily: fontProperties.fontFamily,
            },
          })
        } else {
          if (
            updatedEntity.mobileOptions &&
            updatedEntity.mobileOptions.fontFileId
          ) {
            delete updatedEntity.mobileOptions.fontFileId
          }
          if (fontProperties.fontFamily === 'inherit') {
            delete updatedEntity.mobileOptions.headerStyles.fontWeight
            delete updatedEntity.mobileOptions.headerStyles.fontStyle
          }
          update({
            ...updatedEntity,
            mobileOptions: {
              ...updatedEntity.mobileOptions,
              headerStyles: {
                ...updatedEntity.mobileOptions.headerStyles,
                ...fontProperties,
              },
            },
          })
        }
      } else {
        if (fontProperties.fontFileId) {
          delete updatedEntity.options.headerStyles.fontWeight
          delete updatedEntity.options.headerStyles.fontStyle
          updateOptions({
            ...updatedEntity.options,
            fontFileId: fontProperties.fontFileId,
            headerStyles: {
              ...updatedEntity.options.headerStyles,
              fontFamily: fontProperties.fontFamily,
            },
          })
        } else {
          if (updatedEntity.options.fontFileId) {
            delete updatedEntity.options.fontFileId
          }
          if (fontProperties.fontFamily === 'inherit') {
            delete updatedEntity.options.headerStyles.fontWeight
            delete updatedEntity.options.headerStyles.fontStyle
          }
          updateOptions({
            ...updatedEntity.options,
            headerStyles: {
              ...updatedEntity.options.headerStyles,
              ...fontProperties,
            },
          })
        }
      }
    }

    const textFontFamily = this.context.isMobile
      ? entity.mobileOptions.headerStyles.fontFamily
      : entity.options.headerStyles.fontFamily
    const textFontWeight = this.context.isMobile
      ? entity.mobileOptions.headerStyles.fontWeight
      : entity.options.headerStyles.fontWeight
    const textFontStyle = this.context.isMobile
      ? entity.mobileOptions.headerStyles.fontStyle
      : entity.options.headerStyles.fontStyle

    return (
      <div>
        <MarginsStyle
          marginTop={entity.styles.marginTop}
          marginRight={entity.styles.marginRight}
          marginBottom={entity.styles.marginBottom}
          marginLeft={entity.styles.marginLeft}
          mobileMarginTop={entity.mobileStyles.marginTop}
          mobileMarginRight={entity.mobileStyles.marginRight}
          mobileMarginBottom={entity.mobileStyles.marginBottom}
          mobileMarginLeft={entity.mobileStyles.marginLeft}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <Boolean
          labelText="header visibility"
          value={entity.options.headerVisibility}
          update={updateOption('headerVisibility')}
        />
        {entity.options.headerVisibility === '1' && [
          <GroupTitle key="group-title-header-options">
            header options
          </GroupTitle>,
          <Input
            key="header-title"
            labelText="header title"
            update={updateOption('headerTitle')}
            value={entity.options.headerTitle}
          />,
          <FontSize
            key="text-size"
            update={updateHeaderStyles('fontSize', 'px')}
            mobileUpdate={updateMobileHeaderStyles('fontSize', 'px')}
            fontSize={entity.options.headerStyles.fontSize}
            mobileFontSize={entity.mobileOptions.headerStyles.fontSize}
          />,
          <TextFont
            key="text-font"
            fontFamily={textFontFamily}
            fontStyle={textFontStyle}
            fontWeight={textFontWeight}
            update={handleTextFontChange}
          />,
          <ColorPicker
            key="header-background-color"
            update={updateHeaderStyles('backgroundColor')}
            mobileUpdate={updateMobileHeaderStyles('backgroundColor')}
            color={entity.options.headerStyles.backgroundColor}
            mobileColor={entity.mobileOptions.headerStyles.backgroundColor}
            label="background color"
          />,
          <ColorPicker
            key="header-text-color"
            update={updateHeaderStyles('color')}
            mobileUpdate={updateMobileHeaderStyles('color')}
            color={entity.options.headerStyles.color}
            mobileColor={entity.mobileOptions.headerStyles.color}
            label="text color"
          />,
          <FlexAlignStyle
            key="header-title-align"
            align={entity.options.headerStyles.justifyContent || 'flex-start'} // by default "justifyContent" has normal position and the text is left aligned
            mobileAlign={
              entity.mobileOptions.headerStyles.justifyContent ||
              entity.options.headerStyles.justifyContent
            }
            update={updateHeaderStyles('justifyContent')}
            mobileUpdate={updateMobileHeaderStyles('justifyContent')}
          />,
        ]}
        <GroupTitle>color</GroupTitle>
        <ColorPicker
          update={updateStyle('backgroundColor')}
          mobileUpdate={updateMobileStyle('backgroundColor')}
          label="background color"
          color={entity.styles.backgroundColor}
          mobileColor={entity.mobileStyles.backgroundColor}
        />
        <PaddingStyle
          mobilePadding={entity.mobileStyles.padding}
          padding={entity.styles.padding}
          update={updateStyle('padding')}
          mobileUpdate={updateMobileStyle('padding')}
        />
        <ShadowStyle
          shadow={entity.styles.boxShadow}
          mobileShadow={entity.mobileStyles.boxShadow}
          update={updateStyle('boxShadow')}
          mobileUpdate={updateMobileStyle('boxShadow')}
        />
        <BorderStyle
          updateOption={updateOption}
          updateMobileOption={updateMobileOption}
          updateStyle={updateStyle}
          updateMobileStyle={updateMobileStyle}
          type={entity.options.borderType}
          style={entity.styles.borderStyle}
          width={entity.styles.borderWidth}
          color={entity.styles.borderColor}
          mobileType={entity.mobileOptions.borderType}
          mobileStyle={entity.mobileStyles.borderStyle}
          mobileWidth={entity.mobileStyles.borderWidth}
          mobileColor={entity.mobileStyles.borderColor}
          toggleOverlay={toggleOverlay}
          overlayVisible={overlayVisible}
        />
        <CornerStyle
          topLeft={entity.styles.borderTopLeftRadius}
          topRight={entity.styles.borderTopRightRadius}
          bottomRight={entity.styles.borderBottomRightRadius}
          bottomLeft={entity.styles.borderBottomLeftRadius}
          mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
          mobileTopRight={entity.mobileStyles.borderTopRightRadius}
          mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
          mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
          update={updateStyle}
          mobileUpdate={updateMobileStyle}
        />
        <DelayOption
          update={updateOption('delay')}
          delay={entity.options.delay}
        />
        <DeviceAppearance entity={entity} update={update} />
        <Attributes update={updateOption} entity={entity} />
      </div>
    )
  }
}

ContentBoxSettingsOld.propTypes = {
  overlayVisible: PropTypes.bool.isRequired,
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  updateMobileOptions: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  entityWithoutEmulation: types.entity.isRequired,
}

export default ContentBoxSettingsOld
