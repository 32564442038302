import usePresentPage from 'client/hooks/usePresentPage'
import { getPageType } from 'client/reducers/pageReducer'
import Overlay from 'common/components/core/Overlay'
import React from 'react'
import { useActions } from 'common/hooks/useActions'
import * as actions from 'client/actions/actionsManagement'
import { useTranslation } from 'react-i18next'
import SettingsTitleUi from './ui/SettingsTitleUi'
import SettingsContainerUi from './ui/SettingsContainerUi'
import SettingsBackUi from './ui/SettingsBackUi'
import {
  useOverlayVisibilityContext,
  useOverlayActionsContext,
} from './SettingsOverlayContext'
import { createPageSettings } from './pages'

function PageSettings() {
  const { t } = useTranslation()
  const closePageSettings = useActions(actions.closePageSettings)
  const pageType = usePresentPage(getPageType)
  const isOverlayVisible = useOverlayVisibilityContext()
  const { close } = useOverlayActionsContext()

  return (
    <React.Fragment>
      <SettingsTitleUi>
        <SettingsBackUi onClick={closePageSettings}>←</SettingsBackUi>
        {t('core.header.page_settings.title')}
      </SettingsTitleUi>
      <SettingsContainerUi>
        {createPageSettings(pageType)}
        {isOverlayVisible && <Overlay hide={close} zIndex={30} dark />}
      </SettingsContainerUi>
    </React.Fragment>
  )
}

export default PageSettings
