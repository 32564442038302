import React, { PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'
import Badge from 'client/components/core/Badge'
import EntityExampleWrapperUi from 'client/components/core/EntityExampleWrapper/ui/EntityExampleWrapperUi'

export interface EntityExampleWrapperProps {
  label: string
  warning?: boolean
  visible?: boolean
  needTranslate?: boolean
}

const EntityExampleWrapperNew = ({
  children,
  label,
  warning = true,
  visible = true,
  needTranslate = true,
}: PropsWithChildren<EntityExampleWrapperProps>) => {
  if (!visible) {
    return <>{children}</>
  }

  return (
    <EntityExampleWrapperUi>
      {children}
      <Badge warning={warning}>
        {needTranslate ? <Trans parent="span">{label}</Trans> : label}
      </Badge>
    </EntityExampleWrapperUi>
  )
}

export default EntityExampleWrapperNew
