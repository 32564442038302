import styled from 'styled-components'

export const AutomationEditActionDescriptionContainerUi = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 15px;
  color: rgba(20, 45, 99, 1);
`

export default AutomationEditActionDescriptionContainerUi
