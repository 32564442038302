import React from 'react'
import PropTypes from 'prop-types'
import Select from './Select'

const Boolean = ({ choiceList, ...restProps }) => (
  <Select {...restProps} choiceList={choiceList} />
)

Boolean.propTypes = {
  update: PropTypes.func.isRequired,
  value: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  choiceList: PropTypes.objectOf(PropTypes.any),
}

Boolean.defaultProps = {
  value: 'OFF',
  choiceList: { 1: 'ON', 0: 'OFF' },
}

export default Boolean
