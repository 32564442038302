import React from 'react'
import { PageLocaleEnum } from 'common/enums/PageLocaleEnum'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import WebinarSessionVideoInterface from 'common/types/entities/WebinarSessionVideoInterface'
import Video from 'client/components/entities/VideoNew'
import { EntityProps } from 'client/types'
import { generateBaseEntity } from 'client/utils/createStructureUtils'

function WebinarSessionVideo({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<VideoInterface>) {
  return (
    <Video
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
    />
  )
}

export default WebinarSessionVideo

export function createWebinarSessionVideo(
  locale: string,
  parentId: string,
  masterBlockId?: string,
): WebinarSessionVideoInterface {
  const videoPlaceholderUrl =
    locale === PageLocaleEnum.FRENCH
      ? 'https://youtu.be/4hoeL-l-b_4'
      : 'https://youtu.be/f48bbvvDJFQ'
  return {
    ...generateBaseEntity(
      EntityTypeEnum.WebinarSessionVideo,
      parentId,
      masterBlockId,
    ),
    type: EntityTypeEnum.WebinarSessionVideo,
    autoplay: true,
    controls: false,
    url: videoPlaceholderUrl,
    border: {
      radius: 8,
    }
  }
}
