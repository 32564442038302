import React from 'react'
import IconUi from '../ui/IconUi'

const WarningIcon = props => (
  <IconUi
    className="fas fa-exclamation-triangle"
    {...props}
  />
)

export default WarningIcon
