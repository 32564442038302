import Dexie from 'dexie'
import { pagesSchema, PagesTableType } from 'client/db/tables/pages'

type DexieTablesType = PagesTableType

type DexieType<T extends any = DexieTablesType> = Dexie & T

const database = new Dexie('database') as DexieType

const schema = { ...pagesSchema }

database.version(1).stores(schema)

export default database
