import PropTypes from 'prop-types'
import React, { memo } from 'react'
import usePresentPage from 'client/hooks/usePresentPage'
import {
  getGlobalMobileTextFontSize,
  getGlobalTextFontSize,
} from 'client/reducers/pageReducer'
import { useTranslation } from 'react-i18next'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Range } from '../components'
import MobileIconUi from '../styles/ui/MobileIconUi'
import ResetIcon from './ui/ResetIcon'

function TextFontSize({ fontSize, mobileFontSize, labelText, ...rest }) {
  const { t } = useTranslation()
  const { isMobile } = useDeviceModeContext()
  const globalTextFontSize = usePresentPage(getGlobalTextFontSize)
  const globalMobileTextFontSize = usePresentPage(getGlobalMobileTextFontSize)
  const currentGlobalFontSize = isMobile
    ? globalMobileTextFontSize
    : globalTextFontSize
  const currentFontSize = isMobile ? mobileFontSize : fontSize
  const isGlobal = !currentFontSize

  const update = value =>
    isMobile ? rest.mobileUpdate(value) : rest.update(value)

  const reset = () =>
    isMobile ? rest.removeEntityMobileStyle() : rest.removeEntityStyle()

  const calculatedFontSize = currentFontSize || currentGlobalFontSize
  return (
    <Range
      labelText={labelText}
      labelIcon={
        <React.Fragment>
          {isMobile && <MobileIconUi />}
          {!isGlobal && (
            <ResetIcon
              onClick={reset}
              title={t('settings_styles.font_size.inherit')}
            />
          )}
        </React.Fragment>
      }
      greyThumb={isGlobal}
      value={parseInt(calculatedFontSize, 0)}
      update={update}
      min={8}
    />
  )
}

TextFontSize.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileFontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  update: PropTypes.func.isRequired,
  mobileUpdate: PropTypes.func.isRequired,
  removeEntityMobileStyle: PropTypes.func.isRequired,
  removeEntityStyle: PropTypes.func.isRequired,
  labelText: PropTypes.string,
}

TextFontSize.defaultProps = {
  labelText: 'settings_styles.font_size.label',
  fontSize: null,
  mobileFontSize: null,
}

export default memo(TextFontSize)
