import styled from 'styled-components'

const ColorPickerUi = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`

export default ColorPickerUi
