import PropTypes from 'prop-types'
import React from 'react'
import * as types from 'common/types'
import ColorPicker from 'client/components/core/Sidebar/components/Settings/components/ColorPicker'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { Input, Select, Boolean } from '../components'
import TextFont from '../components/Font/TextFont'
import { DelayOption, FontSize, DeviceAppearance, Attributes } from '../options'
import GroupTitle from '../styleComponents/GroupTitle'
import {
  MarginsStyle,
  PaddingStyle,
  ShadowStyle,
  BorderStyle,
  CornerStyle,
} from '../styles'

const OrderBumpSettings = props => {
  const {
    toggleOverlay,
    overlayVisible,
    entity,
    updateStyle,
    updateMobileStyle,
    updateOption,
    updateMobileOptions,
    updateOptions,
    updateMobileOption,
    update,
  } = props

  const { isMobile } = useDeviceModeContext()

  const updateHeaderStyles = (style, unitOfMeasure) => value => {
    updateOptions({
      ...entity.options,
      headerStyles: {
        ...entity.options.headerStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const updateMobileHeaderStyles = (style, unitOfMeasure) => value => {
    updateMobileOptions({
      ...entity.mobileOptions,
      headerStyles: {
        ...entity.mobileOptions.headerStyles,
        [style]: unitOfMeasure ? `${value}${unitOfMeasure}` : value,
      },
    })
  }

  const handleTextFontChange = fontProperties => {
    const updatedEntity = { ...entity }
    if (isMobile) {
      if (fontProperties.fontFileId) {
        delete updatedEntity.mobileOptions.headerStyles.fontWeight
        delete updatedEntity.mobileOptions.headerStyles.fontStyle
        updateMobileOptions({
          ...updatedEntity.mobileOptions,
          fontFileId: fontProperties.fontFileId,
          headerStyles: {
            ...updatedEntity.mobileOptions.headerStyles,
            fontFamily: fontProperties.fontFamily,
          },
        })
      } else {
        if (
          updatedEntity.mobileOptions &&
          updatedEntity.mobileOptions.fontFileId
        ) {
          delete updatedEntity.mobileOptions.fontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.mobileOptions.headerStyles.fontWeight
          delete updatedEntity.mobileOptions.headerStyles.fontStyle
        }
        update({
          ...updatedEntity,
          mobileOptions: {
            ...updatedEntity.mobileOptions,
            headerStyles: {
              ...updatedEntity.mobileOptions.headerStyles,
              ...fontProperties,
            },
          },
        })
      }
    } else {
      if (fontProperties.fontFileId) {
        delete updatedEntity.options.headerStyles.fontWeight
        delete updatedEntity.options.headerStyles.fontStyle
        updateOptions({
          ...updatedEntity.options,
          fontFileId: fontProperties.fontFileId,
          headerStyles: {
            ...updatedEntity.options.headerStyles,
            fontFamily: fontProperties.fontFamily,
          },
        })
      } else {
        if (updatedEntity.options.fontFileId) {
          delete updatedEntity.options.fontFileId
        }
        if (fontProperties.fontFamily === 'inherit') {
          delete updatedEntity.options.headerStyles.fontWeight
          delete updatedEntity.options.headerStyles.fontStyle
        }
        updateOptions({
          ...updatedEntity.options,
          headerStyles: {
            ...updatedEntity.options.headerStyles,
            ...fontProperties,
          },
        })
      }
    }
  }

  const textFontFamily = isMobile
    ? entity.mobileOptions.headerStyles.fontFamily
    : entity.options.headerStyles.fontFamily
  const textFontWeight = isMobile
    ? entity.mobileOptions.headerStyles.fontWeight
    : entity.options.headerStyles.fontWeight
  const textFontStyle = isMobile
    ? entity.mobileOptions.headerStyles.fontStyle
    : entity.options.headerStyles.fontStyle

  return (
    <React.Fragment>
      <Input
        labelText="bump name"
        update={updateOption('title')}
        value={entity.options.title}
      />
      <Select
        choiceList={{
          'flex-start': 'left',
          // eslint-disable-next-line quote-props
          center: 'center',
          'flex-end': 'right',
        }}
        labelText="title align"
        value={entity.options.headerStyles.justifyContent}
        update={updateHeaderStyles('justifyContent')}
      />
      <GroupTitle>typography</GroupTitle>
      <FontSize
        update={updateHeaderStyles('fontSize', 'px')}
        mobileUpdate={updateMobileHeaderStyles('fontSize', 'px')}
        fontSize={entity.options.headerStyles.fontSize}
        mobileFontSize={
          entity.mobileOptions.headerStyles &&
          entity.mobileOptions.headerStyles.fontSize
        }
      />
      <TextFont
        fontFamily={textFontFamily}
        fontStyle={textFontStyle}
        fontWeight={textFontWeight}
        update={handleTextFontChange}
      />
      <GroupTitle>color</GroupTitle>
      <ColorPicker
        label="background color"
        update={updateHeaderStyles('backgroundColor')}
        mobileUpdate={updateMobileHeaderStyles('backgroundColor')}
        color={entity.options.headerStyles.backgroundColor}
        mobileColor={
          entity.mobileOptions.headerStyles &&
          entity.mobileOptions.headerStyles.backgroundColor
        }
      />
      <ColorPicker
        update={updateHeaderStyles('color')}
        mobileUpdate={updateMobileHeaderStyles('color')}
        mobileColor={
          entity.mobileOptions.headerStyles &&
          entity.mobileOptions.headerStyles.color
        }
        color={entity.options.headerStyles.color}
        label="text color"
      />
      <Boolean
        update={updateOption('showArrow')}
        labelText="show arrow"
        value={+entity.options.showArrow}
      />
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entity.mobileStyles.marginTop}
        mobileMarginRight={entity.mobileStyles.marginRight}
        mobileMarginBottom={entity.mobileStyles.marginBottom}
        mobileMarginLeft={entity.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <PaddingStyle
        mobilePadding={entity.mobileStyles.padding}
        padding={entity.styles.padding}
        update={updateStyle('padding')}
        mobileUpdate={updateMobileStyle('padding')}
      />
      <ShadowStyle
        shadow={entity.styles.boxShadow}
        mobileShadow={entity.mobileStyles.boxShadow}
        update={updateStyle('boxShadow')}
        mobileUpdate={updateMobileStyle('boxShadow')}
      />
      <BorderStyle
        updateOption={updateOption}
        updateMobileOption={updateMobileOption}
        updateStyle={updateStyle}
        updateMobileStyle={updateMobileStyle}
        type={entity.options.borderType}
        style={entity.styles.borderStyle}
        width={entity.styles.borderWidth}
        color={entity.styles.borderColor}
        mobileType={entity.mobileOptions.borderType}
        mobileStyle={entity.mobileStyles.borderStyle}
        mobileWidth={entity.mobileStyles.borderWidth}
        mobileColor={entity.mobileStyles.borderColor}
        toggleOverlay={toggleOverlay}
        overlayVisible={overlayVisible}
      />
      <CornerStyle
        topLeft={entity.styles.borderTopLeftRadius}
        topRight={entity.styles.borderTopRightRadius}
        bottomRight={entity.styles.borderBottomRightRadius}
        bottomLeft={entity.styles.borderBottomLeftRadius}
        mobileTopLeft={entity.mobileStyles.borderTopLeftRadius}
        mobileTopRight={entity.mobileStyles.borderTopRightRadius}
        mobileBottomLeft={entity.mobileStyles.borderBottomLeftRadius}
        mobileBottomRight={entity.mobileStyles.borderBottomRightRadius}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <DelayOption
        update={updateOption('delay')}
        delay={entity.options.delay}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

OrderBumpSettings.propTypes = {
  toggleOverlay: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
  updateMobileOptions: PropTypes.func.isRequired,
  updateMobileOption: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  entity: types.entity.isRequired,
  overlayVisible: PropTypes.bool.isRequired,
}

export default OrderBumpSettings
