import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ManagementItemUi from './ui/ManagementItemUi'
import ManagementItemTitleUi from './ui/ManagementItemTitleUi'
import ManagementItemIconUi from './ui/ManagementItemIconUi'

class ManagementItem extends Component {
  onDisplayClick = () => {
    this.props.display(this.props.id)
  }

  onRemoveClick = () => {
    this.props.remove(this.props.id)
  }

  render() {
    const { title } = this.props
    return (
      <ManagementItemUi>
        <ManagementItemTitleUi>{title}</ManagementItemTitleUi>
        <div>
          <ManagementItemIconUi
            className="fa fa-eye"
            onClick={this.onDisplayClick}
          />
          <ManagementItemIconUi
            className="fa fa-trash"
            onClick={this.onRemoveClick}
          />
        </div>
      </ManagementItemUi>
    )
  }
}

ManagementItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  display: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
}

export default ManagementItem
