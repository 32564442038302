import mimeTypeDetector from 'mime'
import PropTypes from 'prop-types'
import React, { memo, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { BadRequest } from 'common/errors/BadRequest'
import { Cancelled } from 'common/errors/Cancelled'
import { GatewayError } from 'common/errors/GatewayError'
import { MissingContentLengthError } from 'common/errors/MissingContentLengthError'
import { NetworkError } from 'common/errors/NetworkError'
import { NotFound } from 'common/errors/NotFound'
import { ServiceUnavailable } from 'common/errors/ServiceUnavailable'
import * as fileActions from 'client/actions/fileActions'
import * as fileApi from 'client/api/fileApi'
import ProgressBar from 'client/components/core/ProgressBar'
import { mimeTypes as globalMimeTypes } from 'client/constants/editorSettings'
import { usePage } from 'client/store'
import pageSelectors from 'client/store/page/pageSelectors'
import { getHumanReadableSize } from 'client/utils/filesHelper'
import Tooltip from '../core/Tooltip'
import FileUploadButtonUi from './ui/FileUploadButtonUi'
import FileUploadCancelUi from './ui/FileUploadCancelUi'
import FileUploadContainerUi from './ui/FileUploadContainerUi'
import FileUploadDescriptionUi from './ui/FileUploadDescriptionUi'
import FileUploadDropZoneUi from './ui/FileUploadDropZoneUi'
import FileUploadErrorUi from './ui/FileUploadErrorUi'
import FileUploadOptimizeTitleUi from './ui/FileUploadOptimizeTitleUi'
import FileUploadOptimizeUi from './ui/FileUploadOptimizeUi'
import FileUploadTitleUi from './ui/FileUploadTitleUi'

const allowedOtherSize = 2147483648
const allowedImageSize = 5242880

export const isImage = mimeType => {
  const imageMimeTypeReg = /image\/.*/
  return imageMimeTypeReg.test(mimeType)
}

const getMimeType = fileName => {
  const matches = fileName.match(/\.[a-zA-Z]{1,4}$/i)
  const fileExtension = matches && matches[0]

  switch (fileExtension) {
    case '.docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case '.doc':
      return 'application/msword'
    case '.ttf':
      return 'font/ttf'
  }
}

function FileUpload({ afterUpload, mimeTypes, show, validateUploadFile }) {
  const [{ canDrop }, connectDrop] = useDrop({
    accept: '__NATIVE_FILE__',
    collect: monitor => ({
      canDrop: monitor.isOver({ shallow: true }) && monitor.canDrop(),
    }),
    drop: async (_, monitor) => {
      await handleFileDrop(monitor)
    },
  })
  const isTemplate = usePage(pageSelectors.isPageTemplate)
  const dispatch = useDispatch()
  const { t } = useTranslation(['client', 'common'])
  const inputRef = React.createRef()
  const [optimize, setOptimize] = useState(true)
  const [errors, setErrors] = useState([])
  const [total, setTotal] = useState(1)
  const [loaded, setLoaded] = useState(1)
  const [uploading, setUploading] = useState(false)
  const [uploadingFileId, setUploadingFileId] = useState(null)
  const [cancelTokenSource, setCancelTokenSource] = useState(null)

  const handleFiles = async () => {
    const file = inputRef.current.files[0]
    if (!validate(file)) {
      return
    }

    await handleUpload(file)
  }

  const progressHandler = ({ total, loaded }) => {
    // pause progress on 99% until activate would be performed
    const progress = Math.round((loaded * 100) / total)
    if (progress === 100) {
      setUploading(false)
    }

    setTotal(total)
    setLoaded(loaded)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel()
      resetProgress()
      fileApi.removeDraftFile(uploadingFileId)
      setUploading(false)
      setUploadingFileId(null)
    }
  }

  const resetProgress = () => {
    setLoaded(1)
    setTotal(1)
  }

  const handleUpload = async file => {
    try {
      setErrors([])
      resetProgress()
      //hack for doc, docx and font files
      const mimeType = getMimeType(file.name)

      if (validateUploadFile) {
        const validationResult = await validateUploadFile(file, mimeType)
        if (!validationResult) {
          return
        }
      }

      const {
        data: { uploadOptions, id },
      } = await fileApi.validateAndGetUploadOptions(file, isTemplate, mimeType)

      setUploading(true)
      setUploadingFileId(id)
      const cancelTokenSource = axios.CancelToken.source()
      setCancelTokenSource(cancelTokenSource)

      await fileApi.uploadFile(
        file,
        uploadOptions,
        progressHandler,
        cancelTokenSource.token,
      )

      const { data } = await fileApi.activateFile(id, optimize)
      dispatch(fileActions.uploadFileSuccess(data))
      const uploadedFile = data[Object.keys(data)[0]]
      afterUpload(uploadedFile)
      resetProgress()
    } catch (e) {
      if (uploadingFileId) {
        fileApi.removeDraftFile(uploadingFileId)
      } else if (e instanceof NotFound) {
        // do nothing, draft file was removed
      } else if (e instanceof NetworkError) {
        setErrors([t('core.errors.no_connection')])
        // do nothing
      } else if (e instanceof BadRequest) {
        if (e.response.data.errors) {
          if (e.response.data.errors.fileName) {
            setErrors(Object.values(e.response.data.errors))
          }
          if (e.response.data.errors.mimeType) {
            setErrors([t('core.file_manager.upload.not_supported_file_type')])
          }
        } else if (typeof e.response.data === 'string') {
          setErrors([t('core.file_manager.upload.upload_failed_by_timeout')])
        } else {
          setErrors([t('core.file_manager.upload.server_error')])
        }
      } else if (e instanceof MissingContentLengthError) {
        if (e.response.data) {
          window.Rollbar.error('Upload to S3 failed 411', {
            data: e.response.data,
          })
          setErrors([t('core.file_manager.upload.blocked')])
        }
      } else if (e instanceof GatewayError) {
        setErrors([t('core.file_manager.upload.server_error')])
        window.Rollbar.error(e)
      } else if (e instanceof ServiceUnavailable) {
        window.Rollbar.error(e)
        setErrors([t('core.file_manager.upload.server_error')])
      } else if (e instanceof Cancelled) {
        // do nothing
      } else {
        setErrors([t('core.file_manager.upload.server_error')])
      }
    }
    setUploadingFileId(null)
    setUploading(false)
  }

  const handleFileDrop = async monitor => {
    const droppedFiles = monitor.getItem().files
    const file = droppedFiles[0]
    if (!validate(file)) {
      return
    }

    await handleUpload(file)
  }

  const validate = file => {
    if (!file) {
      return false
    }

    const allowedSize = isImage(file.type) ? allowedImageSize : allowedOtherSize
    // 256 - (user_id/hash)
    if (file.name.length > 234) {
      alert(t('core.file_manager.upload_long_file_name_warning'))
      return false
    }

    if (file.size > allowedSize) {
      alert(
        t('core.file_manager.upload_max_size_warning', {
          size: getHumanReadableSize(allowedSize),
        }),
      )
      return false
    }

    const testType = type => mimeType => RegExp(mimeType).test(type)
    const mimeType = mimeTypeDetector.getType(file.name)

    if (mimeTypes.some(testType(mimeType)) === false) {
      alert(t('core.file_manager.file_not_supported'))
      return false
    }

    return true
  }

  const openFileSelection = () => {
    inputRef.current.click()
  }

  let readableMimeTypes
  if (mimeTypes === globalMimeTypes.all) {
    readableMimeTypes = null
  } else {
    readableMimeTypes = mimeTypes
      .map(type => type.split('/')[1])
      .filter(type => type !== '*')
      .join(', ')
  }

  return (
    <>
      <FileUploadContainerUi show={show}>
        <ProgressBar total={total} loaded={loaded} />
        <FileUploadDropZoneUi
          canDrop={canDrop && !uploading}
          ref={connectDrop}
          afterDrop={handleFileDrop}
        >
          {!uploading && (
            <React.Fragment>
              <FileUploadTitleUi>
                {t('core.file_manager.upload_call_to_action')}
              </FileUploadTitleUi>
              {readableMimeTypes ? (
                <FileUploadDescriptionUi>
                  {t('core.file_manager.allowed_types')}
                  {`: ${readableMimeTypes}`}
                </FileUploadDescriptionUi>
              ) : (
                <FileUploadDescriptionUi>
                  {t('core.file_manager.all_types_allowed')}
                </FileUploadDescriptionUi>
              )}
              <input
                style={{ display: 'none' }}
                type="file"
                accept={mimeTypes.join(' ')}
                ref={inputRef}
                onChange={handleFiles}
              />
            </React.Fragment>
          )}
          {uploading && loaded > 1 && (
            <FileUploadCancelUi onClick={cancelUpload}>
              {t('core.file_manager.upload.cancel')}
            </FileUploadCancelUi>
          )}
          {!uploading && (
            <FileUploadButtonUi onClick={openFileSelection}>
              {t('core.file_manager.upload.select_file')}
            </FileUploadButtonUi>
          )}
        </FileUploadDropZoneUi>
        {errors.length > 0 &&
          errors.map(error => (
            <FileUploadErrorUi key={error.slice(10)}>{error}</FileUploadErrorUi>
          ))}
      </FileUploadContainerUi>
      {!uploading && show && mimeTypes === globalMimeTypes.image && (
        <FileUploadOptimizeUi>
          <FileUploadOptimizeTitleUi>
            <input
              type="checkbox"
              onChange={() => setOptimize(!optimize)}
              checked={optimize}
            />
            {t('core.file_manager.upload.optimize.label')}
          </FileUploadOptimizeTitleUi>
          <Tooltip
            message={t('core.file_manager.upload.optimize.help_message')}
          />
        </FileUploadOptimizeUi>
      )}
    </>
  )
}

FileUpload.propTypes = {
  show: PropTypes.bool.isRequired,
  mimeTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  afterUpload: PropTypes.func.isRequired,
  validateUploadFile: PropTypes.func,
}

export default memo(FileUpload)
