import React from 'react'
import GroupTitle from '../styleComponents/GroupTitle'
import String from '../components/String'

function HtmlAttrId({ attrId }: { attrId: string }) {
  return (
    <>
      <GroupTitle>settings_options.attributes.label</GroupTitle>
      <String
        value={attrId}
        helpMessage="settings_options.attributes.id.help_message"
        labelText="settings_options.attributes.id.label"
      />
    </>
  )
}

export default HtmlAttrId
