import PropTypes from 'prop-types'
import React from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import structureTypes from 'common/constants/structureTypes'
import ChildlessPlaceholder from 'client/components/core/ChildlessPlaceholder'
import { usePage, pageSelectors } from 'client/store/index'
import { addStructure } from 'client/store/page/pageActions'
import EntityTypeEnum from '../../../../common/enums/entityTypeEnum'
import LibraryElementEnum from '../../../enums/LibraryElementEnum'
import createLibraryElementStructure from '../../../utils/createLibraryElements'
import DropBoxUi from './ui/DropBoxUi'
import DropBoxWithTextUi from './ui/DropBoxWithTextUi'

function DropBoxEmpty({
  placeholderText,
  parentId,
  allowedTypes,
  createEntity,
  createBlock,
  moveEntity,
}) {
  const { t } = useTranslation()
  const pageLocale = usePage(pageSelectors.getPageLocale)
  const dispatch = useDispatch()
  const parent = usePage(page => pageSelectors.getEntityById(page, parentId))
  const [{ canDrop }, connectDrop] = useDrop({
    accept: allowedTypes,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return
      }
      if (monitor.getItemType().startsWith('TOOLBOX_')) {
        createEntity(monitor.getItemType(), parentId, 0)
      } else if (monitor.getItemType() in LibraryElementEnum) {
        const structure = createLibraryElementStructure(
          monitor.getItemType(),
          parent,
          pageLocale,
          0,
        )
        dispatch(addStructure(structure))
      } else if (item.type === 'BLOCK') {
        createBlock(item.entities, parentId, 0, item.isMaster)
      } else {
        moveEntity(item.entity, parentId, 0)
      }
    },
    collect: monitor => ({
      canDrop: monitor.isOver() && monitor.canDrop(),
    }),
    canDrop: (draggable, monitor) => {
      if (draggable.type === 'BLOCK') {
        if (
          monitor.isOver({ shallow: true }) &&
          draggable.entities.find(
            ent =>
              ent.type === structureTypes.SECTION ||
              ent.type === EntityTypeEnum.Section,
          ) &&
          !allowedTypes.includes(structureTypes.SECTION) &&
          !allowedTypes.includes(EntityTypeEnum.Section)
        ) {
          return false
        }

        return true
      }
      // checking for library elements
      if (
        draggable.type.startsWith('TOOLBOX_') ||
        draggable.type.startsWith('Library')
      ) {
        return true
      }

      // check if draggable item under the child dropBox
      if (draggable.entity.id === parentId) {
        return false
      }
      // checking descendant dropBoxes
      if (
        draggable.descendantIds &&
        draggable.descendantIds.includes(parentId)
      ) {
        return false
      }

      if (
        draggable.allowedEntityIds &&
        !draggable.allowedEntityIds.includes(parentId)
      ) {
        return false
      }

      return true
    },
  })

  return (
    <DropBoxUi
      ref={connectDrop}
      canDrop={canDrop}
      dropCenter={!placeholderText}
      styles={{ position: 'relative', zIndex: 1 }} /* overlap bodyUI */
    >
      {placeholderText ? (
        <DropBoxWithTextUi canDrop={canDrop}>
          {t(placeholderText)}
        </DropBoxWithTextUi>
      ) : (
        <ChildlessPlaceholder />
      )}
    </DropBoxUi>
  )
}

DropBoxEmpty.propTypes = {
  placeholderText: PropTypes.string,
  parentId: PropTypes.string.isRequired,
  createEntity: PropTypes.func.isRequired,
  createBlock: PropTypes.func.isRequired,
  moveEntity: PropTypes.func.isRequired,
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
}

DropBoxEmpty.defaultProps = {
  allowedTypes: [],
  placeholderText: null,
}

export default DropBoxEmpty
