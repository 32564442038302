import styled from 'styled-components'
import { InputUi } from 'client/components/core/Sidebar/components/Settings/components/Input'

const InputBeforeAddonUi = styled(InputUi)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  cursor: pointer;
  width: 150px;
  float: left;
  text-overflow: ellipsis;
`
export default InputBeforeAddonUi
