import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import WebinarSessionDateTimeInterface from 'common/types/entities/WebinarSessionDateTimeInterface'
import WebinarSessionLinkInterface from 'common/types/entities/WebinarSessionLinkInterface'
import createCommonEntitySettings from '../createCommonEntitySettings'
import WebinarSessionDateTimeSettings from './settings/WebinarSessionDateTimeSettings'
import WebinarSessionLinkSettingsNew from './settings/WebinarSessionLinkSettingsNew'

function createSettingsForWebinarThankYou(entity: EntityInterface) {
  switch (entity.type) {
    case EntityTypeEnum.WebinarSessionLink:
      return (
        <WebinarSessionLinkSettingsNew
          entity={entity as WebinarSessionLinkInterface}
        />
      )
    case EntityTypeEnum.WebinarSessionDateTime:
      return (
        <WebinarSessionDateTimeSettings
          entity={entity as WebinarSessionDateTimeInterface}
        />
      )
    default:
      return createCommonEntitySettings(entity)
  }
}

export default createSettingsForWebinarThankYou
