import styled from 'styled-components'

export const AutomationActionIconContainerUi = styled.div`
  width: 40px;
  svg {
    width: 40px;
    height: 40px;
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    transition-timing-function: linear;
  }
`

export default AutomationActionIconContainerUi
