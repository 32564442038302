import PropTypes from 'prop-types'
import React, { useMemo, memo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { isVisible } from 'tools/utils/entityUtils'
import { useActions } from 'common/hooks/useActions'
import * as entityActions from 'client/actions/entityActions'
import { createEmptyEntityElement } from 'client/pages/createCommonEntityElement'
import { getChildrenEntities } from 'client/reducers/pageReducer'
import { getAllowedTypes } from 'client/utils/validators'
import { useCreateEntityElement } from '../../../context/CreateEntityContext'
import { useDeviceModeContext } from '../../../context/DeviceModeContext'
import DropBoxEmpty from './DropBoxEmpty'
import DropBoxEntity from './DropBoxEntity'

function ChildrenEntities({
  id,
  type,
  childIds = [],
  placeholderText,
  expandedDropZone,
  isReadOnly,
}) {
  const { isDesktop } = useDeviceModeContext()
  const { createEntityElement, excludedEntitiesIds } = useCreateEntityElement()
  const entities = useSelector(
    state => getChildrenEntities(state.page.present, childIds),
    shallowEqual,
  )
  const [createEntity, moveEntity, createBlock] = useActions([
    entityActions.createIn,
    entityActions.moveNew,
    entityActions.createBlockIn,
  ])
  const allowedTypes = useMemo(() => getAllowedTypes(type), [type])
  const noVisibleEntities = useMemo(
    () => entities.every(entity => !isVisible(entity, isDesktop)),
    [isDesktop, entities],
  )

  if (isReadOnly) {
    return entities.map(entity =>
      excludedEntitiesIds.includes(entity.id)
        ? createEmptyEntityElement(entity)
        : createEntityElement(entity),
    )
  }

  if (noVisibleEntities) {
    return (
      <DropBoxEmpty
        placeholderText={placeholderText}
        parentId={id}
        allowedTypes={allowedTypes}
        createEntity={createEntity}
        createBlock={createBlock}
        moveEntity={moveEntity}
      />
    )
  }

  return entities.map((entity, index) => {
    if (excludedEntitiesIds.includes(entity.id)) {
      return <div />
    }
    return (
      <DropBoxEntity
        key={entity.id}
        position={index}
        parentId={id}
        allowedTypes={allowedTypes}
        createEntity={createEntity}
        createBlock={createBlock}
        moveEntity={moveEntity}
        expanded={expandedDropZone}
      >
        {createEntityElement(entity, {
          isMoveDownAllowed:
            entities.length > 1 && entities.length !== index + 1,
          isMoveUpAllowed: entities.length > 1 && index !== 0,
        })}
      </DropBoxEntity>
    )
  })
}

ChildrenEntities.propTypes = {
  childIds: PropTypes.arrayOf(PropTypes.string),
  placeholderText: PropTypes.string,
  expandedDropZone: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
}

ChildrenEntities.defaultProps = {
  placeholderText: null,
  expandedDropZone: false,
}

export default memo(ChildrenEntities)
