import React from 'react'
import { CustomerTypeInterface } from 'common/types/entities/CustomerTypeInteface'
import { SettingsProps } from 'client/components/core/Sidebar/components/Settings/entities'
import DeviceAppearance from 'client/components/core/Sidebar/components/Settings/options/DeviceAppearance/DeviceAppearanceNew'
import HtmlAttrId from 'client/components/core/Sidebar/components/Settings/options/HtmlAttrId'
import { useDeviceModeContext } from 'client/context/DeviceModeContext'
import { useUpdateProp } from '../../../components/core/Sidebar/components/Settings/hooks/useUpdateProps'
import MarginStyle from '../../../components/core/Sidebar/components/Settings/styles/MarginStyle'

function CustomerTypeSettings({
  entity,
}: SettingsProps<CustomerTypeInterface>) {
  const { isMobile } = useDeviceModeContext()
  const updateProp = useUpdateProp(entity)

  return (
    <React.Fragment>
      <MarginStyle
        groupTitle
        margin={isMobile ? entity.mobileMargin : entity.margin}
        update={updateProp(isMobile ? 'mobileMargin' : 'margin')}
      />
      <DeviceAppearance entity={entity} />
      <HtmlAttrId attrId={entity.htmlAttrId} />
    </React.Fragment>
  )
}

export default CustomerTypeSettings
