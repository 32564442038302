import React from 'react'
import Select from 'react-select'
import { FixedSizeList as List } from 'react-window'
import { wrapFontFamily } from 'common/components/entities/Text/utils'
import fonts from 'common/constants/fonts'
import { googleFontsList } from 'client/components/core/Sidebar/components/Settings/options/FontPicker/googleFontsList'
import OptionWrapper from '../../styleComponents/OptionWrapper'
import { GoogleFontInterface, Variant } from './googleFontsTypes'

function MenuList(props: React.PropsWithChildren<any>) {
  const children = React.Children.toArray(props.children)

  return (
    <List
      height={250}
      itemCount={props.children.length}
      itemSize={30}
      width="100%"
    >
      {({ index, style }) => <div style={style as any}>{children[index]}</div>}
    </List>
  )
}

const downloadedFonts: string[] = []
function getFontFamilySelectStyles(currentFontFamily?: string) {
  return {
    singleValue: (provided: any) => {
      if (currentFontFamily) {
        provided.fontFamily = wrapFontFamily(currentFontFamily)
      }

      return provided
    },
    option: (provided: any, state: any) => {
      if (!state.data) {
        return provided
      }
      if (!downloadedFonts.includes(state.data.value)) {
        const font = googleFontsList.find(el => el.family === state.data.value)
        downloadedFonts.push(state.data.value)
        const style = document.createElement('style')
        const fontWeight = font?.variants.find(el => el === 'regular')
          ? 400
          : font?.variants[0]
        style.id = state.data.value
        style.textContent = `
          @font-face {
            font-family: "${state.data.value}";
            font-style: normal;
            font-weight: ${fontWeight};
            src: url(${
              process.env.CLOUDFRONT_SHARED
            }/fonts/google-fonts/${state.data.value
          .replace(/\s/g, '')
          .toLowerCase()}/preview/preview.woff2) format('woff2');
          }
        `
        document.head.appendChild(style)
      }

      return { ...provided, fontFamily: wrapFontFamily(state.data.value) }
    },
    menu: (provided: any) => ({ ...provided, zIndex: 2 }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 3 }),
  }
}

function getVariantStyles(fontVariant: string) {
  if (fontVariant === 'regular') {
    return {
      fontStyle: 'normal',
      fontWeight: '400',
    }
  } else if (fontVariant === 'italic') {
    return {
      fontStyle: 'italic',
      fontWeight: '400',
    }
  } else if (fontVariant.includes('italic')) {
    return {
      fontStyle: 'italic',
      fontWeight: `${parseInt(fontVariant)}`,
    }
  } else {
    return {
      fontWeight: `${parseInt(fontVariant)}`,
      fontStyle: 'normal',
    }
  }
}

const fontVariantsLabels: Record<Variant, string> = {
  '100': 'Thin 100',
  '100italic': 'Thin 100 italic',
  '200': 'Extra-light 200',
  '200italic': 'Extra-light 200 italic',
  '300': 'Light 300',
  '300italic': 'Light 300 italic',
  regular: 'Regular',
  // @ts-ignore, Variant seems to be incomplete
  '400': 'Regular',
  '400italic': 'Regular 400 italic',
  italic: 'Regular 400 italic',
  '500': 'Medium 500',
  '500italic': 'Medium 500 italic',
  '600': 'Semi-bold 600',
  '600italic': 'Semi-bold 600 italic',
  '700': 'Bold 700',
  '700italic': 'Bold 700 italic',
  '800': 'Extra-bold 800',
  '800italic': 'Extra-bold 800 italic',
  '900': 'Black 900',
  '900italic': 'Black 900 italic',
}

function detectFontVariant(fontStyle?: string, fontWeight?: string) {
  let fontVariant = ''
  if (fontWeight) {
    fontVariant += fontWeight
  }
  if (fontStyle && fontStyle !== 'normal') {
    fontVariant += fontStyle
  }

  return fontVariant
    ? { value: fontVariant, label: fontVariantsLabels[fontVariant as Variant] }
    : { value: '400', label: fontVariantsLabels.regular }
}

function generateFontOptions(fonts: GoogleFontInterface[]): Option<string>[] {
  return fonts.map(font => ({
    label: font.family,
    value: font.family,
    variants: font.variants,
  }))
}

function generateVariantOptions(variants: Variant[]): VariantOption[] {
  return variants.map(variant => ({
    label: fontVariantsLabels[variant],
    value: variant,
  }))
}

type OptionValue = string

export type Option<T extends OptionValue> = {
  value: T
  label: string
  variants: Variant[]
}

export type VariantOption = {
  value: string
  label: string
}

export type UpdateFontPayload = {
  fontStyle: string
  fontWeight: string
  fontFamily: string
}

type FontPickerProps = {
  activeFontFamily?: string
  activeFontWeight?: string
  activeFontStyle?: string
  onChange: (fontPayload: UpdateFontPayload) => void
}

const filteredFonts: GoogleFontInterface[] = fonts.google.map(el => {
  const font = googleFontsList.find(f => f.family === el)
  if (font) {
    return { family: font.family, variants: font.variants }
  } else return { family: '', variants: [] }
})

function FontPicker({
  activeFontFamily,
  activeFontWeight,
  activeFontStyle,
  onChange,
}: FontPickerProps) {
  const selectedFont = activeFontFamily
    ? googleFontsList.find(el => el.family === activeFontFamily)
    : undefined

  const selectedVariant = detectFontVariant(activeFontStyle, activeFontWeight)

  function handleFontChange(option: Option<string> | null) {
    if (!option) {
      return
    }

    onChange({
      fontFamily: option.value,
      ...getVariantStyles('regular'),
    })
  }

  function handleVariantChange(variant: VariantOption | null) {
    if (!variant || !activeFontFamily) {
      return
    }

    onChange({
      fontFamily: activeFontFamily,
      ...getVariantStyles(variant.value),
    })
  }

  return (
    <>
      <OptionWrapper>
        <Select
          components={{ MenuList }}
          styles={getFontFamilySelectStyles(activeFontFamily)}
          value={
            selectedFont
              ? {
                  label: activeFontFamily || '',
                  value: activeFontFamily || '',
                  variants: selectedFont.variants,
                }
              : null
          }
          onChange={handleFontChange}
          options={generateFontOptions(filteredFonts)}
        />
      </OptionWrapper>
      {selectedFont && (
        <OptionWrapper labelText="settings_styles.font_style.label">
          <Select
            value={selectedVariant}
            onChange={handleVariantChange}
            options={generateVariantOptions(selectedFont.variants)}
            styles={{
              menu: (provided: any) => ({ ...provided, zIndex: 2 }),
              menuPortal: (provided: any) => ({ ...provided, zIndex: 3 }),
            }}
          />
        </OptionWrapper>
      )}
    </>
  )
}

export default FontPicker
