import React from 'react'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { OldEntityInterface } from 'common/types/entities/OldEntityInterface'
import { VideoInterface } from 'common/types/entities/VideoInterface'
import { WebinarCallToActionInterface } from 'common/types/entities/WebinarCallToActionInterface'
import createCommonEntityElement, {
  AdditionalProps,
} from '../createCommonEntityElement'
import WebinarCallToAction from './entities/WebinarCallToAction'
import WebinarSessionVideo from './entities/WebinarSessionVideo'

function createEntityElementForWebinarSession(
  entity: EntityInterface | OldEntityInterface,
  additionalProps: AdditionalProps,
) {
  switch (entity.type) {
    case EntityTypeEnum.WebinarSessionVideo:
      return (
        <WebinarSessionVideo
          entity={entity as VideoInterface}
          {...additionalProps}
        />
      )
    case EntityTypeEnum.WebinarCallToAction:
      return (
        <WebinarCallToAction
          entity={entity as WebinarCallToActionInterface}
          {...additionalProps}
        />
      )
    default:
      return createCommonEntityElement(entity, additionalProps)
  }
}

export default createEntityElementForWebinarSession
