import { FETCH_BLOG_POST_DATA } from './blogPostActionTypes'
import { BlogPostActions } from './blogPostActions'

export interface BlogPostState {
  name: string
  createdAt: string | null
  image: {
    path: string
  } | null
  categories: Category[]
}

type Category = {
  id: number
  name: string
}

const defaultState = {
  name: '',
  createdAt: null,
  image: {
    path: '',
  },
  categories: [],
}

export default function blogPostReducer(
  state: BlogPostState = defaultState,
  action: BlogPostActions,
) {
  switch (action.type) {
    case FETCH_BLOG_POST_DATA:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export function getBlogPostImageUrl(state: BlogPostState) {
  return state.image ? state.image.path : ''
}

export function getBlogPostTitle(state: BlogPostState) {
  return state.name
}

export function getBlogPostCreatedAt(state: BlogPostState) {
  return state.createdAt ? new Date(state.createdAt) : null
}

export function getBlogPostCategories(state: BlogPostState) {
  return state.categories.map(category => category.name)
}

export const blogPostSelectors = {
  getBlogPostImageUrl,
  getBlogPostTitle,
  getBlogPostCreatedAt,
  getBlogPostCategories,
}
