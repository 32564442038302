import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FileManager from 'client/components/FileManager/FileManager'
import { mimeTypes } from 'client/constants/editorSettings'
import MenuButtonUi from '../ui/MenuButtonUi'
import MenuCloseButtonUi from '../ui/MenuCloseButtonUi'
import LinkSourceInput from './LinkSourceInput'
import LinkSourcePopup from './LinkSourcePopup'
import MenuConfirmButtonHaveChangesUi from './ui/MenuConfirmButtonHaveChangesUi'
import MenuConfirmButtonUi from './ui/MenuConfirmButtonUi'
import MenuInputGroupUi from './ui/MenuInputGroupUi'
import MenuLinkUi from './ui/MenuLinkUi'

const REL_NO_FOLLOW = 'nofollow'
const TARGET_BLANK = '_blank'

export type LinkType = {
  url?: string
  popupId?: string
  rel?: typeof REL_NO_FOLLOW | ''
  target?: typeof TARGET_BLANK | ''
}

type LinkProps = {
  currentLink: LinkType
  update: (link: LinkType) => void
  remove: () => void
  close: () => void
}

function Link({ currentLink, update, remove, close }: LinkProps) {
  const { t } = useTranslation()
  const [link, setLink] = useState(currentLink)
  const [showFileManager, setShowFileManager] = useState(false)
  const [showPopup, setShowPopup] = useState(Boolean(link.popupId))
  const isLinkReadyForSave = currentLink.url !== link.url

  useEffect(() => {
    setLink(currentLink)
  }, [currentLink])

  function afterUpload(file: any) {
    setLink({
      url: file.path,
    })
    setShowPopup(false)
  }

  function updateURL(url: string) {
    setLink({
      url,
      rel: link.rel,
      target: link.target,
    })
  }

  function updatePopupId(popupId: string) {
    setLink({
      popupId,
      rel: link.rel,
      target: link.target,
    })
  }

  function toggleNoFollow() {
    update({
      ...link,
      rel: link.rel === REL_NO_FOLLOW ? '' : REL_NO_FOLLOW,
    })
  }

  const toggleMakeOpenInNew = () => {
    update({
      ...link,
      target: link.target === TARGET_BLANK ? '' : TARGET_BLANK,
    })
  }

  function save() {
    update(link)
    setTimeout(close, 500)
  }

  function toggleShowFileManager() {
    setShowFileManager(!showFileManager)
  }

  function togglePopup() {
    setShowPopup(!showPopup)
  }

  const ref = useRef<HTMLDivElement>(null)

  return (
    <MenuLinkUi ref={ref}>
      <MenuInputGroupUi>
        {showPopup ? (
          <LinkSourcePopup popupId={link.popupId} update={updatePopupId} />
        ) : (
          <LinkSourceInput url={link.url} update={updateURL} close={close} />
        )}
        <MenuConfirmButtonUi
          onClick={save}
          title={t('core.text_editor.link_menu.confirm')}
          disabled={!isLinkReadyForSave}
        >
          <span className="fas fa-save" />
          {isLinkReadyForSave && <MenuConfirmButtonHaveChangesUi />}
        </MenuConfirmButtonUi>
      </MenuInputGroupUi>
      <MenuButtonUi
        onClick={toggleShowFileManager}
        title={t('components.core.text_editor.link_menu.use_file_as_link')}
      >
        <span className="fa fa-download" />
      </MenuButtonUi>
      {showFileManager && (
        <FileManager
          // @ts-ignore
          closeFileManager={toggleShowFileManager}
          mimeTypes={mimeTypes.all}
          onInsert={afterUpload}
        />
      )}
      <span style={{ color: '#fff', borderRight: '1xp solid #fff' }} />
      <MenuButtonUi
        onClick={togglePopup}
        active={showPopup}
        title={t('components.core.text_editor.link_menu.open_popup')}
      >
        <span className="far fa-window-restore" />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={toggleMakeOpenInNew}
        active={link.target === TARGET_BLANK}
        title={t('components.core.text_editor.link_menu.open_in_new_window')}
      >
        <span className="fas fa-external-link-alt" />
      </MenuButtonUi>
      <span style={{ color: '#fff', borderRight: '1xp solid #fff' }} />
      <MenuButtonUi
        onClick={toggleNoFollow}
        active={link.rel === REL_NO_FOLLOW}
        title={t('components.core.text_editor.link_menu.no_follow')}
      >
        <span className="fa fa-ban" />
      </MenuButtonUi>
      <MenuButtonUi
        onClick={remove}
        title={t('components.core.text_editor.link_menu.unlink')}
      >
        <span className="fas fa-unlink" />
      </MenuButtonUi>
      <MenuButtonUi
        style={{ backgroundColor: 'transparent', cursor: 'initial' }}
      />
      <MenuCloseButtonUi
        onClick={close}
        marginLeft="auto"
        title={t('components.core.text_editor.link_menu.close_menu')}
      >
        <span className="fa fa-times" />
      </MenuCloseButtonUi>
    </MenuLinkUi>
  )
}

export default Link
