import React from 'react'
import CommonAttachments from 'common/components/entities/Attachments'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { EntityTypeEnum } from 'common/enums/entityTypeEnum'
import EntityInterface from 'common/types/entities/EntityInterface'
import { PaddingType, AlignSelfType, BorderType } from 'common/types/styleTypes'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import {
  generateHtmlAttrId,
  generateId,
} from 'client/utils/createStructureUtils'
import { EntityProps } from '../../../types'
import { useContactUsDescendantIds } from './ContactUs'

export interface AttachmentsInterface extends EntityInterface {
  id: string
  type: EntityTypeEnum.Attachments
  parentId: string
  slug: string
  masterBlockId: string | undefined
  alignSelf: AlignSelfType
  border: Partial<BorderType>
  mobileBorder?: Partial<BorderType>
  boxShadow?: string
  mobileBoxShadow?: string
  mobileAlignSelf?: AlignSelfType
  padding: PaddingType
  mobilePadding?: PaddingType
  show: boolean
  fontSize: number
  mobileFontSize?: number
  fontFamily?: string
  fontWeight?: string
  fontStyle?: string
  mobileFontFamily?: string
  mobileFontWeight?: string
  mobileFontStyle?: string
  color: string
  mobileColor?: string
  backgroundColor: string
  mobileBackgroundColor?: string
  iconClassName: string
  fontFileId?: number
  mobileFontFileId?: number
}

export default function Attachments({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: EntityProps<AttachmentsInterface>) {
  const entityIds = useContactUsDescendantIds()

  if (!entity.show) {
    return <div />
  }

  return (
    <>
      <BaseEntityNew
        entity={entity}
        isMoveDownAllowed={isMoveDownAllowed}
        isMoveUpAllowed={isMoveUpAllowed}
        isRemoveAllowed={false}
        isSaveAllowed={false}
        isCopyAllowed={false}
        dropBoxAllowedEntityIds={entityIds}
        flex
        flexColumn
      >
        <CommonAttachments
          padding={entity.padding}
          mobilePadding={entity.mobilePadding}
          alignSelf={entity.alignSelf}
          mobileAlignSelf={entity.mobileAlignSelf}
          backgroundColor={entity.backgroundColor}
          mobileBackgroundColor={entity.mobileBackgroundColor}
          attrId={entity.htmlAttrId}
          color={entity.color}
          fontSize={entity.fontSize}
          mobileColor={entity.mobileColor}
          mobileFontSize={entity.mobileFontSize}
          fontFamily={entity.fontFamily}
          fontWeight={entity.fontWeight}
          fontStyle={entity.fontStyle}
          mobileFontFamily={entity.mobileFontFamily}
          mobileFontWeight={entity.mobileFontWeight}
          mobileFontStyle={entity.mobileFontStyle}
          iconClassName={entity.iconClassName}
          boxShadow={entity.boxShadow}
          mobileBoxShadow={entity.mobileBoxShadow}
          border={entity.border}
          mobileBorder={entity.mobileBorder}
          disabled
        />
      </BaseEntityNew>
    </>
  )
}

export function createAttachments(parentId: string, masterBlockId?: string) {
  const attachments: AttachmentsInterface = {
    id: generateId(),
    type: EntityTypeEnum.Attachments,
    parentId: parentId,
    masterBlockId: masterBlockId,
    show: false,
    alignSelf: 'flex-start',
    iconClassName: 'far fa-paperclip',
    fontSize: 20,
    color: 'rgba(255, 255, 255, 1)',
    backgroundColor: 'rgba(1, 160, 255, 1)',
    slug: FieldSlugEnum.Attachments,
    border: {
      radius: 5,
      style: 'none',
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    padding: {
      paddingTop: 10,
      paddingRight: 10,
      paddingBottom: 10,
      paddingLeft: 10,
    },
    margin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 15,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.Attachments),
  }

  return attachments
}
