import PropTypes from 'prop-types'
import React from 'react'
import { Translation } from 'react-i18next'
import DateTimePicker from 'react-widgets/DatePicker'
import DropdownList from 'react-widgets/DropdownList'
import { DateLocalizer } from 'react-widgets/IntlLocalizer'
import Localization from 'react-widgets/Localization'
import 'react-widgets/styles.css'
import 'client/components/core/Sidebar/components/Settings/options/DateTimePicker/styles.css'
import OptionWrapper from '../../styleComponents/OptionWrapper/index'
import { timeOptions, getDefaultTime } from './utils'

const DatePicker = ({ update, datetime, updateTime, showDate }) => (
  <Translation>
    {(t, { i18n }) => (
      <OptionWrapper>
        <Localization
          date={
            new DateLocalizer({
              culture: i18n.language || i18n.options.fallbackLng[0],
              firstOfWeek: 1,
            })
          }
        >
          <div style={{ display: 'flex' }}>
            {showDate && (
              <div style={{ marginRight: '5px' }}>
                <DateTimePicker
                  culture={i18n.language || i18n.options.fallbackLng[0]}
                  calendarProps={{ view: 'month' }}
                  defaultValue={datetime ? datetime : new Date()}
                  onChange={update}
                />
              </div>
            )}
            <div style={showDate ? { width: '50%' } : { width: '100%' }}>
              <DropdownList
                data={timeOptions(i18n.language || i18n.options.fallbackLng[0])}
                onChange={updateTime}
                defaultValue={getDefaultTime(
                  datetime,
                  i18n.language || i18n.options.fallbackLng[0],
                )}
              />
            </div>
          </div>
        </Localization>
      </OptionWrapper>
    )}
  </Translation>
)

DatePicker.propTypes = {
  datetime: PropTypes.instanceOf(Date),
  update: PropTypes.func,
  updateTime: PropTypes.func,
  hoursTime: PropTypes.string,
  showDate: PropTypes.bool,
}

DatePicker.defaultProps = {
  datetime: new Date(),
  showDate: true,
}

export default DatePicker
