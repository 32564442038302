import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import * as types from 'common/types'
import useManagement, {
  selectors as managementSelectors,
} from 'client/hooks/useManagement'
import * as managementActions from 'client/actions/actionsManagement'
import { Button } from '../components'
import { Attributes, DeviceAppearance } from '../options'
import { MarginsStyle, TextAlignStyle } from '../styles'

function RawHtmlSettings(props) {
  const {
    entity,
    update,
    updateStyle,
    updateMobileStyle,
    updateOption,
    entityWithoutEmulation,
  } = props
  const [AceEditor, setAceEditor] = useState(null)
  const isCodeDialogPortalAllowed = useManagement(
    managementSelectors.isCodeDialogPortalAllowed,
  )
  const dispatch = useDispatch()

  const loadCodeEditor = async () => {
    const { default: aceEditor } = await import(
      /* webpackChunkName: "code-editor" */ 'client/components/core/CodeEditor'
    )
    setAceEditor(() => aceEditor)
  }

  const openCodeEditor = async () => {
    if (!AceEditor) {
      await loadCodeEditor()
    }
    dispatch(managementActions.showCodeDialog())
  }

  return (
    <React.Fragment>
      <TextAlignStyle
        textAlign={entity.styles.textAlign}
        mobileTextAlign={entity.mobileStyles.textAlign}
        update={updateStyle('textAlign')}
        mobileUpdate={updateMobileStyle('textAlign')}
      />
      <Button
        small
        onClick={openCodeEditor}
        helpMessage="entity_settings.raw_html.description"
      >
        entity_settings.raw_html.edit_label
      </Button>
      {isCodeDialogPortalAllowed && AceEditor && (
        <AceEditor
          label="entity_settings.raw_html.label"
          value={entity.options.html}
          handleChange={updateOption('html')}
        />
      )}
      <MarginsStyle
        marginTop={entity.styles.marginTop}
        marginRight={entity.styles.marginRight}
        marginBottom={entity.styles.marginBottom}
        marginLeft={entity.styles.marginLeft}
        mobileMarginTop={entityWithoutEmulation.mobileStyles.marginTop}
        mobileMarginRight={entityWithoutEmulation.mobileStyles.marginRight}
        mobileMarginBottom={entityWithoutEmulation.mobileStyles.marginBottom}
        mobileMarginLeft={entityWithoutEmulation.mobileStyles.marginLeft}
        update={updateStyle}
        mobileUpdate={updateMobileStyle}
      />
      <DeviceAppearance update={update} entity={entity} />
      <Attributes update={updateOption} entity={entity} />
    </React.Fragment>
  )
}

RawHtmlSettings.propTypes = {
  entity: types.entity.isRequired,
  entityWithoutEmulation: types.entity.isRequired,
  update: PropTypes.func.isRequired,
  updateStyle: PropTypes.func.isRequired,
  updateMobileStyle: PropTypes.func.isRequired,
  updateOption: PropTypes.func.isRequired,
}

export default RawHtmlSettings
