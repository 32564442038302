import React, { useMemo, useState } from 'react'
import { gradientPaletteInterface } from '.'
import AnglePicker from './AnglePicker'
import ColorStopsHolder from './ColorStopsHolder'
import AngleHolder from './ui/AngleHolderUi'
import AngleInputContainer from './ui/AngleInputContainer'
import AngleInput from './ui/AngleInputUi'
import AngleSpan from './ui/AngleSpanUi'
import { mapPaletteToStops } from './utils'

type GradientSettingsProps = {
  palette: gradientPaletteInterface[]
  width: number
  onPaletteChange: (updatedPalette: gradientPaletteInterface[]) => void
  onColorStopSelect: (color: string) => void
  setActiveColorId: (id: number) => void
  activeColorId: number
  handleChangeGradient: (updatedPalette: gradientPaletteInterface[]) => void
  handleChangeAngle: (angle: number) => void
}

const GradientSettings = ({
  palette,
  width,
  onPaletteChange,
  onColorStopSelect,
  setActiveColorId,
  activeColorId,
  handleChangeGradient,
  handleChangeAngle,
}: GradientSettingsProps) => {
  const [angle, setAngle] = useState(90)
  const limits = useMemo(() => {
    const min = -5
    const max = width - 5
    return { min, max, drop: 50 }
  }, [width])

  const handleStopPosChange = ({
    id,
    offset,
  }: {
    id: number
    offset: number
  }) => {
    const updatedPalette = palette.map(gradientColor =>
      id === gradientColor.id
        ? { ...gradientColor, offset: (offset + 5) / width }
        : gradientColor,
    )
    onPaletteChange(updatedPalette)
  }

  const onStopDragStart = (id: number) => {
    if (id !== activeColorId) {
      setActiveColorId(id)
      const gradientColor = palette.find(
        color => color.id === id,
      ) as gradientPaletteInterface
      onColorStopSelect(gradientColor.color)
    }
  }

  const onStopDragEnd = ({ id, offset }: { id: number; offset: number }) => {
    const updatedPalette = palette.map(c =>
      id === c.id ? { ...c, offset: (offset + 5) / width } : c,
    )
    onPaletteChange(updatedPalette)
    handleChangeGradient(updatedPalette)
  }

  const onAngleInputChange = (angle: number) => {
    angle = angle > 360 ? angle - 360 : angle
    angle = angle < 0 ? angle + 360 : angle
    handleChangeAngle(angle)
    setAngle(angle)
  }

  return (
    <>
      <div
        className="palette"
        style={{
          width,
          height: '30px',
        }}
      >
        <svg width="100%" height="100%">
          <defs>
            <linearGradient id="gradientId" x1="0" y1="0.5" x2="1" y2="0.5">
              {palette.map(({ id, offset, color, opacity = 1 }) => (
                <stop
                  key={id}
                  offset={offset}
                  style={{ stopColor: color, stopOpacity: opacity }}
                />
              ))}
            </linearGradient>
          </defs>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill={'url(#gradientId)'}
          />
        </svg>
      </div>
      <ColorStopsHolder
        width={width}
        stops={mapPaletteToStops({
          palette,
          width: width,
        })}
        limits={limits}
        onPosChange={handleStopPosChange}
        onDragStart={onStopDragStart}
        onDragEnd={onStopDragEnd}
      />
      <AngleHolder>
        <AnglePicker
          angle={angle}
          setAngle={setAngle}
          handleChangeAngle={handleChangeAngle}
        />
        <AngleInputContainer>
          <AngleSpan onClick={() => onAngleInputChange(angle - 1)}>
            &#8722;
          </AngleSpan>
          <AngleInput value={`${angle}°`} disabled />
          <AngleSpan onClick={() => onAngleInputChange(angle + 1)}>
            &#43;
          </AngleSpan>
        </AngleInputContainer>
      </AngleHolder>
    </>
  )
}

export default GradientSettings
