import i18n from 'i18next'
import React from 'react'
import {
  ForgotPasswordUi,
  ForgotPasswordWrapperUi,
} from 'common/components/entities/CustomLogin'
import EntityTypeEnum from 'common/enums/entityTypeEnum'
import { CustomLoginForgotPasswordInterface } from 'common/types/entities/CustomLoginForgotPasswordInterface'
import BaseEntityNew from 'client/components/core/BaseEntity/BaseEntityNew'
import { typedPageSelectors, usePage } from 'client/store'
import { generateHtmlAttrId, generateId } from 'client/utils/createStructureNew'
import { useCustomLoginDescendantIds } from './CustomLogin'

interface ForgotPasswordProps {
  entity: CustomLoginForgotPasswordInterface
  isMoveDownAllowed: boolean
  isMoveUpAllowed: boolean
}

export const ForgotPassword = ({
  entity,
  isMoveDownAllowed,
  isMoveUpAllowed,
}: ForgotPasswordProps) => {
  const entityIds = useCustomLoginDescendantIds()

  const locale = usePage(typedPageSelectors.getPageLocale)

  return (
    <BaseEntityNew
      entity={entity}
      isMoveDownAllowed={isMoveDownAllowed}
      isMoveUpAllowed={isMoveUpAllowed}
      dropBoxAllowedEntityIds={entityIds}
      isCopyAllowed={false}
      isRemoveAllowed={false}
      isSaveAllowed={false}
    >
      <ForgotPasswordWrapperUi
        align={entity.textAlign || 'center'}
        mobileAlign={entity.mobileTextAlign || 'center'}
      >
        <ForgotPasswordUi
          {...entity}
          href={`/dashboard/${locale}/resetting/request`}
        >
          {entity.text}
        </ForgotPasswordUi>
      </ForgotPasswordWrapperUi>
    </BaseEntityNew>
  )
}

export function createForgotPassword({
  locale,
  parentId,
  masterBlockId,
}: {
  parentId: string
  masterBlockId?: string
  locale: string
}): CustomLoginForgotPasswordInterface {
  const fixedT = i18n.getFixedT(locale)
  const forgotPasswordString = fixedT(
    'entities.member_access.request.default_headline',
  )
  return {
    id: generateId(),
    type: EntityTypeEnum.CustomLoginForgotPassword,
    parentId: parentId,
    masterBlockId: masterBlockId,
    fontSize: 16,
    mobileFontSize: 16,
    text: forgotPasswordString,
    color: 'rgba(0, 160, 225, 100)',
    mobileColor: 'rgba(0, 160, 225, 100)',
    margin: {
      marginTop: 10,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    mobileMargin: {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
    },
    appearance: {
      desktop: true,
      mobile: true,
    },
    htmlAttrId: generateHtmlAttrId(EntityTypeEnum.CustomLoginForgotPassword),
  }
}
